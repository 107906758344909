import {Injectable} from '@angular/core';
import {Globals} from '@app/shared/globals';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    public globals: Globals,
    public translate: TranslateService,

  ) { }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.globals.currentLanguage = language;
    localStorage.setItem('wita-lang', language);
  }

  b64toBlob(b64Data, contentType, sliceSize?): Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

  insert = (arr: any[], i: number, newItem: any) =>  [
    ...arr.slice(0, i),
    newItem,
    ...arr.slice(i)
  ]
}
