import { Component, OnInit, ViewChild, Input, OnChanges } from "@angular/core";
import { IonSlides } from "@ionic/angular";
import { Store, select } from "@ngrx/store";
import * as RootStoreState from "@app/root-store/state";
import * as featureActions from "@app/root-store/category/actions";
import { CategoryApiService } from "../category-api.service";
import { Category } from "@app/modules/category/category";
import { Globals } from "../../../shared/globals";

@Component({
  selector: "app-category-slider-coverflow",
  templateUrl: "./category-slider.component.html",
  styleUrls: ["./category-slider.component.scss"]
})
export class CategorySliderCoverflowComponent implements OnInit, OnChanges {
  @Input() categories: any;
  @Input() selectCategoryId: any;
  @Input() config: any;

  @ViewChild(IonSlides, { static: true }) slider: IonSlides;

  isInitialized = false;
  slideDivDynamicStyles = {
    width: "110px",
    fontSize: "16px",
    height: "110px",
    minHeight: "100%"
  };

  rootSliderOptions = {
    slidesPerView: "auto",
    effect: "coverflow",
    centeredSlides: true,
    coverflowEffect: {
      stretch: 0,
      rotate: 0,
      modifier: 3,
      depth: 200,
      slideShadows: false
    },
    grabCursor: true,
    on: {
      beforeInit() {
        const swiper = this;

        swiper.classNames.push(
          `${swiper.params.containerModifierClass}coverflow`
        );
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

        swiper.params.watchSlidesProgress = true;
        swiper.originalParams.watchSlidesProgress = true;
      },
      setTranslate() {
        const swiper = this;
        const {
          width: swiperWidth,
          height: swiperHeight,
          slides,
          $wrapperEl,
          slidesSizesGrid,
          $
        } = swiper;

        const params = swiper.params.coverflowEffect;
        const isHorizontal = swiper.isHorizontal();
        const transform$$1 = swiper.translate;
        const center = isHorizontal
          ? -transform$$1 + swiperWidth / 2
          : -transform$$1 + swiperHeight / 2;
        const rotate = isHorizontal ? params.rotate : -params.rotate;
        const translate = params.depth;
        // Each slide offset from center
        for (let i = 0, length = slides.length; i < length; i += 1) {
          const $slideEl = slides.eq(i);
          const slideSize = slidesSizesGrid[i];
          const slideOffset = $slideEl[0].swiperSlideOffset;
          const offsetMultiplier =
            ((center - slideOffset - slideSize / 2) / slideSize) *
            params.modifier;

          let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
          let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
          // var rotateZ = 0
          let translateZ = -translate * Math.abs(offsetMultiplier);

          let translateY = isHorizontal ? 0 : params.stretch * offsetMultiplier;
          let translateX = isHorizontal ? params.stretch * offsetMultiplier : 0;

          // Fix for ultra small values
          if (Math.abs(translateX) < 0.001) {
            translateX = 0;
          }
          if (Math.abs(translateY) < 0.001) {
            translateY = 0;
          }
          if (Math.abs(translateZ) < 0.001) {
            translateZ = 0;
          }
          if (Math.abs(rotateY) < 0.001) {
            rotateY = 0;
          }
          if (Math.abs(rotateX) < 0.001) {
            rotateX = 0;
          }

          const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

          $slideEl.transform(slideTransform);
          $slideEl[0].style.zIndex =
            -Math.abs(Math.round(offsetMultiplier)) + 1;
          $slideEl[0].style.borderRadius = "15px";
        }

        // Set correct perspective for IE10
        if (
          swiper.support.pointerEvents ||
          swiper.support.prefixedPointerEvents
        ) {
          const ws = $wrapperEl[0].style;
          ws.perspectiveOrigin = `${center}px 50%`;
        }
      }
    }
  };
  selectCategoryIndex: number;

  constructor(
    private store$: Store<RootStoreState.State>,
    private categoryService: CategoryApiService,
    private globals: Globals
  ) {}

  ngOnInit() {
    this.slider.ionSlidesDidLoad.subscribe(data => {
      this.isInitialized = true;
    });

    this.slider.ionSlideTransitionEnd.subscribe((data: any) => {
      this.slider.getActiveIndex().then((active: any) => {
        this.slider.getSwiper().then(swiper => {
          this.store$.dispatch(
            new featureActions.LoadCategoryByIdAction({
              categoryId: this.categoryService.getCategoryIdByIndex(
                this.categories,
                swiper.realIndex
              )
            })
          );
        });
      });
    });
  }

  ngOnChanges(): void {
    this.selectCategoryIndex = this.categoryService.getCategoryIndex(
      this.categories,
      this.selectCategoryId
    );
    this.slider.slideTo(this.selectCategoryIndex).then(() => {});
  }

  getSliderTextClass() {
    return (
      this.categories &&
      this.categories[0] &&
      this.categories[0].parentCategory.id !== null
    );
  }

  getTranslationForTitle(category: any): string {
    return this.categoryService.getTranslationForTitle(category);
  }

  onClickHandler(category: Category, index: number): void {
    this.slider.slideTo(index, undefined, false).then(() => {
      this.store$.dispatch(
        new featureActions.LoadCategoryByIdAction({
          categoryId: category.id
        })
      );

      this.store$.dispatch(
        new featureActions.AddNavigateLevelAction({
          level: {
            id: category.id,
            scope: category.scope,
            active: true,
            categories: this.categories,
            childs: null
          }
        })
      );
    });
  }
}
