export class Tokens {
  jwtToken: string;
  refreshToken: string;
  liveTimeSec: number;
  createdDate: Date;
  ssoId: string;

  constructor(data) {
    Object.assign(this, data);
  }
}
