<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh()">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>


  <div class="categories-container">
    <div class="main-categories-block">
      <div class="category-row">
        <app-category-slider-coverflow [categories]="rootCategories" [selectCategoryId]="categoryIdParam"
          [config]="{showIconsInSlider: true}">
        </app-category-slider-coverflow>
      </div>
    </div>

    <div class="categories__txt-slider">
      <div *ngFor="let level of levels$ | async">
        <p *ngIf="!level.active">list</p>
        <app-category-slider-coverflow *ngIf="level.active" [categories]="level.categories"
          [selectCategoryId]="level.id" [config]="{showIconsInSlider: false}">
        </app-category-slider-coverflow>
      </div>
    </div>
  </div>

</ion-content>
