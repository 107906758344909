import {Component, OnInit} from '@angular/core';
import {Globals} from '../../shared/globals';
import {SiteUser} from '../site-user/site-user';
import {UserRelationService} from '../../shared/services/user-relation.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-friends-requests',
  templateUrl: './friends-requests.component.html',
  styleUrls: [
    './friends-requests.component.css',
    '../chat/chat.component.css'
  ]
})
export class FriendsRequestsComponent implements OnInit {

  showRequest = true;

  constructor(
    public globals: Globals,
    public userRelationService: UserRelationService,
    public router: Router) {
  }

  ngOnInit() {
    this.userRelationService.getFriendRequests();
  }

  acceptFriendship(friend: SiteUser) {
    this.userRelationService.editUserRelation(friend, 2, () => {
      friend.relationType = 2;
      this.globals.people.push(friend);
      this.userRelationService.refreshRequestsList();
    });
  }

  declineFriendship(siteUser: SiteUser) {
    this.userRelationService.deleteUserRelation(siteUser, () => {
      this.userRelationService.refreshRequestsList();
    });
  }

  toFriend(id) {
    this.globals.noteList = null;
    this.globals.allCategories = [];
    this.globals.needPageRefresh = true;
    this.router.navigate(['/users/' + id + '/notes']);
  }

  capitalizeFirstLetter(uuId: string) {
    return uuId.charAt(0).toUpperCase() + uuId.slice(1);
  }
}
