var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable, of as observableOf } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as featureActions from "./actions";
import { CategoryApiService } from "../../modules/category-ngrx/category-api.service";
import * as i0 from "@angular/core";
import * as i1 from "../../modules/category-ngrx/category-api.service";
import * as i2 from "@ngrx/effects";
export class CategoryStoreEffects {
    constructor(catService, actions$) {
        this.catService = catService;
        this.actions$ = actions$;
        this.loadAllCategories$ = this.actions$.pipe(ofType(featureActions.ActionTypes.LOAD_CATEGORIES_REQUEST), switchMap(action => this.catService.getAllCategories(action.payload.userId).pipe(map(categories => new featureActions.LoadSuccessAction({
            categories: this.catService.assignCategoriesParents(categories)
        })), catchError(error => observableOf(new featureActions.LoadFailureAction({ error }))))));
        this.getCategoryById$ = this.actions$.pipe(ofType(featureActions.ActionTypes.LOAD_CATEGORY), switchMap(action => this.catService.getCategoryById(action.payload.categoryId).pipe(map((category) => new featureActions.LoadCategoryByIdSuccessAction({
            currentSelectCategory: category
        })), catchError(error => observableOf(new featureActions.LoadFailureAction({ error }))))));
        this.getChildByCategoryId$ = this.actions$.pipe(ofType(featureActions.ActionTypes.GET_CHILD_CATEGORIES_BY_PARENT_ID), switchMap(action => this.catService
            .getChildCategoriesByParentId(action.payload.categoryId)
            .pipe(catchError(error => observableOf(new featureActions.LoadFailureAction({ error }))))));
    }
}
CategoryStoreEffects.ɵfac = function CategoryStoreEffects_Factory(t) { return new (t || CategoryStoreEffects)(i0.ɵɵinject(i1.CategoryApiService), i0.ɵɵinject(i2.Actions)); };
CategoryStoreEffects.ɵprov = i0.ɵɵdefineInjectable({ token: CategoryStoreEffects, factory: CategoryStoreEffects.ɵfac });
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], CategoryStoreEffects.prototype, "loadAllCategories$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CategoryStoreEffects.prototype, "getCategoryById$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CategoryStoreEffects.prototype, "getChildByCategoryId$", void 0);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CategoryStoreEffects, [{
        type: Injectable
    }], function () { return [{ type: i1.CategoryApiService }, { type: i2.Actions }]; }, { loadAllCategories$: [], getCategoryById$: [], getChildByCategoryId$: [] }); })();
