import { Injectable } from '@angular/core';
import {MenuController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  readonly MAIN_MENU = 'main-menu';

  constructor(
    private menu: MenuController
  ) { }


  async disableMainMenu(): Promise<void> {
    await this.menu.enable(false, this.MAIN_MENU);
  }

  async activeMainMenu(): Promise<void> {
    await this.menu.enable(true, this.MAIN_MENU);
  }

  async toggleMenu() {
    await this.menu.toggle(this.MAIN_MENU);
  }
}
