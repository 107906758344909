<ion-content [scrollEvents]="true">
  <div class="bdc-wrapper" [bdcWalkTriggerFor]="welcomeGuideTask0">
    <ion-refresher slot="fixed" (ionRefresh)="doRefreshContent($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <bdc-walk-popup class="custom-popup" #welcomeGuideTask0 name="welcomeGuideTask0"
                    [showButton]="true" [buttonText]="'Let\'s start!' | translate" [closeOnClick]="true"
                    xPosition="before" horizontal="false" yPosition="below"
                    [mustCompleted]="{welcomeGuideShowWalkthrough: true}">
      <div translate>
        <h1 translate>
          Welcome!
        </h1>
        This is the World feed, and you can scroll it
        <h1>
          <mat-icon [inline]="true">expand_less</mat-icon>
          <br>
          <mat-icon [inline]="true">expand_more</mat-icon>
        </h1>
        To try 2 main features, click the button below
        <br><br>
      </div>
    </bdc-walk-popup>
  </div>

  <div class="reset-filters" *ngIf="!globals.activeFilters.isEmpty()">
    <ion-button class="reset-filters-button" fill="outline" color="primary" (click)="clearFilters()">
      <p class="reset-filters-title" translate>сlear all filters</p>
    </ion-button>

  </div>

  <ion-slides
    pager
    tappable
    mode="ios"
    [options]="slideOpts">
    <ion-slide *ngFor="let feed of feeds | async;">
      <div class="backdrop">
        <div style="display: block">

          <app-profile-header #profileHeader style="width: 100%"></app-profile-header>


          <ion-img *ngIf="worldFeed.isFeedEnabled" class="backdrop__image"
                   [src]="'assets/icons/design_v4/worldFeedHeader.jpg'"
                   style="width: 100%"></ion-img>

          <ion-img *ngIf="friendFeed.isFeedEnabled" class="backdrop__image"
                   [src]="'assets/icons/design_v4/friendFeedHeader.jpg'"
                   style="width: 100%"></ion-img>
          <ion-spinner
            *ngIf="!areNotesLoaded"
            class="main-loader"
            name="crescent">
          </ion-spinner>

          <bdc-walk-popup class="custom-popup" #welcomeGuideTask1 name="welcomeGuideTask1" [alignCenter]="true"
                          [mustCompleted]="{welcomeGuideShowWalkthrough: true, welcomeGuideTask0: true}"
                          [closeOnClick]="true" [showCloseButton]="false">
            <span translate>Click on any post to open it<br></span>
          </bdc-walk-popup>
          <div [bdcWalkTriggerFor]="welcomeGuideTask1">
            <app-note-list
              *ngIf="areNotesLoaded"
              style="width: 100%"
              [paginator]="feed.paginator"
            ></app-note-list>
          </div>

        </div>
      </div>
    </ion-slide>
  </ion-slides>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<div>
  <div >
  <ion-button *ngIf="homeFeed.isFeedEnabled || worldFeed.isFeedEnabled" fill="clear"
              class="slide-btn next-slide-btn" (click)="nextSlide()"
              [bdcWalkTriggerFor]="addNoteGuideTask12">

  </ion-button>
  </div>
    <ion-button *ngIf="worldFeed.isFeedEnabled || friendFeed.isFeedEnabled"
                fill="clear"
                class="slide-btn prev-slide-btn" (click)="prevSlide()">

    </ion-button>

</div>

<ion-fab *ngIf="globals.selectedUser.id === globals.owner.id" style="bottom: 30px;" vertical="bottom"
         horizontal="center" slot="fixed">
  <ion-fab-button appButtonTouch id="new-note-button" color="light" (click)="showCategoriesPage()">
<!--    <div class="bdc-wrapper" [bdcWalkTriggerFor]="welcomeGuideTask6">-->
    <div class="bdc-wrapper" >
      <img [src]="'assets/icons/design_v4/plus_btn.svg'">
    </div>
  </ion-fab-button>
</ion-fab>

<bdc-walk-popup class="wide-popup" #welcomeGuideTask6 name="welcomeGuideTask6" [alignCenter]="true" yPosition="above"
                [mustCompleted]="{welcomeGuideShowWalkthrough: true, welcomeGuideTask5: true}"
                (click)="globals.isNewUser = false; {welcomeGuideShowWalkthrough: false}"
                [showCloseButton]="false" [closeOnClick]="true" [showButton]="true" [buttonText]="'Ok'">
  <span translate>

    <b translate> Great! </b><mat-icon>thumb_up</mat-icon><br><br>
    <b translate>The first part is over</b><br><br>
    Now you see notes just from the certain category.<br><br>
    Let's try to make a new note.<br> Click the plus!<br></span>
</bdc-walk-popup>

<bdc-walk-popup class="wide-popup" #addNoteGuideTask12 name="addNoteGuideTask12" horizontal="false" xPosition="before" yPosition="above"
                [mustCompleted]="{addNoteGuideShowWalkthrough: true, addNoteGuideTask11: true}"
                [showCloseButton]="false" [closeOnClick]="true">
  <span translate>

    <b translate> Awesome! </b>
    <mat-icon>thumb_up</mat-icon>
    <br><br>
    To get back to the World feed, use menu <mat-icon>menu</mat-icon><br><br>
    Or click the arrow <mat-icon>arrow_forward_ios</mat-icon><br> </span>
</bdc-walk-popup>
