<div class="note-wrap">
  <div class="note-header">
    <img class="color-tag" [src]="getTagIcon(noteEntity.weight)"/>
  </div>
  <div class="note-block" (click)="openNote(noteEntity.id)">
    <div class="left-column">
      <div class="avatar">
        <img [src]="noteEntity.owner.userPhotoName"/>
      </div>
      <div class="counter-blocks">
        <div class="views-counter">
          <div class="icon-square view-count">
            <i class="far fa-angry"></i>
          </div>
          <div class="count">{{noteEntity.views}}</div>
        </div>
        <div class="comments-counter">
          <div class="icon-square comment-count">
            <i class="far fa-angry"></i>
          </div>
          <div class="count">{{commentService.getCommentCounts(noteEntity)}}</div>
        </div>
      </div>
    </div>
    <div class="main-column">
      <div class="author-name">{{ noteEntity.owner | userName }}</div>
      <div class="author-additional-data">
        <div class="category">{{categoryService.getTranslationForTitle(noteEntity.parentCategory)}}</div>
        <div class="date">{{noteEntity.creationDate | date: 'd.M.yyyy'}}</div>
      </div>
      <div>
        <quill-view-html class="quill-view-html" [content]="chopNote(noteEntity.content)"></quill-view-html>
        <span class="explain-button" translate>more...</span>
      </div>
    </div>
    <div class=cut-corner-column></div>
  </div>
</div>
