<ul #ulContainer
    class="editors-category__item">
    <li *ngFor="let item of truncatedItems;"
        class="editors-category__link">
        <span [class.truncate]="item === '...'">{{ item }}</span>
    </li>
</ul>

<p (click)="presentModal()"
   class="editors-category__discussion-button"></p>

<p class="editors-category__lang-txt" *ngIf="langs.size > 0" translate>add translation</p>

<div class="editors-category__lang-list">
    <div *ngFor="let lang of langs.keys()">
        <span (click)="changeSelectedLang(lang);"
              *ngIf="lansInObj[lang]"
              [class.editors-category__lang--active]="lang === selectedLang"
              class="editors-category__lang">{{ lang }}</span>

        <div *ngIf="lang === selectedLang" class="editors-category__translation">
            <input type="text"
                   placeholder="{{categoryService.getTranslationForTitleByLang(this.category)}}"
                   appCustomAutofocus
                   (input)="translateLang($event)">

            <button type="button"
                    class="btn btn--close"
                    (click)="hideTranslate(lang)">
                <img class="icon-close" [src]="'assets/icons/design_v4/close.png'" alt="">
            </button>
            <button type="button"
                    class="btn btn--apply"
                    (click)="applyTranslate(lang)">
                <img [src]="'assets/icons/design_v4/check-aqua.svg'" alt="">
            </button>
        </div>
    </div>
</div>
