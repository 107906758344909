import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../globals';
import {Languages} from '../../core/languages';
import {CategoryTranslation} from '../../core/categoryTranslation';
import {Observable} from 'rxjs';
import {Category} from '@app/modules/category/category';
import {ADD_NEW_NOT_ACTION, EDIT_NOTE_ACTION} from '@app/shared/actions';
import {CategoryFilter} from '@app/modules/note-filters/filters/category-filter';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(public http: HttpClient, public globals: Globals, public router: Router, public modalController: ModalController) {
  }

  getAllCategories(userId: number, categoryId: number): Observable<Category[]> {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get<Category[]>(this.globals.apiUrl + '/categories/' + categoryId + '/users/' + userId, {
      params
    });
  }

  getRootCategories(userId: number): Observable<Category[]> {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get<Category[]>(this.globals.apiUrl + '/categories/flower/users/' + userId, {
      params
    });
  }

  getChildren(catId: number, userId: number): Observable<Category[]> {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get<Category[]>(this.globals.apiUrl + '/categories/' + catId + '/children/users/' + userId, {
      params
    });
  }

  uploadCategoryFlower(userId: number, callback) {
    this.getRootCategories(userId).subscribe((response: Category[]) => {
      this.setGlobalsAllCategories(response);
      return callback && callback();
    });
  }


  insertNewCat(old: Category[], newCat: Category[]): Category [] {

    console.log("old " + old.length);
    let counter = 0;

    for (const n of newCat) {
      for (const o of old) {
      }
    }
    for (const n of newCat) {
      for (const o of old) {
        if (n.id === o.id) {
          counter = 0;
          break;
        }
        counter++;
      }
      if (counter > 0) {
        old.push(n);
        counter = 0;
      }
    }
    console.log("new " + old.length);
    return old;
  }

  setGlobalsAllCategories(categories: Category[]) {
    this.globals.allCategories = null;
    this.globals.allCategories = this.assignCategoriesParents(categories);
  }

  getUserRecentCategories() {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get(this.globals.apiUrl + '/categories/recent-categories', {
      params
    }).subscribe((recentCategoriesResponse: Category[]) => {
      this.globals.recentCategories = [];
      for (const categories of recentCategoriesResponse) {
        const category = this.getParentForCategory(categories, this.globals.allCategories);
        this.globals.recentCategories.push(category);
      }
    });
  }

  getCategoryById(categoryId: number) {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get(this.globals.apiUrl + '/categories/' + categoryId, {
      params
    });
  }

  deleteCategory(id: number, callback, error?: CallableFunction) {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.delete(this.globals.apiUrl + '/categories/' + id, {
      params
    }).subscribe(response => {
        return callback && callback(response);
      },
      e => {
        return error && error(e);
      });
  }

  updateCategory(category: Category, callback) {
    this.http.put(this.globals.apiUrl + '/categories', this.toJson(category)).subscribe(() => {
      return callback && callback();
    });
  }

  addCategory(newCat: Category, callback: CallableFunction, error?: CallableFunction) {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    this.http.post(this.globals.apiUrl + '/categories', this.toJson(newCat), {
      params
    }).subscribe(response => {
        return callback && callback(response);
      },
      e => {
        return error && error(e);
      });
  }

  getTitleForTranslation(titleLang, newCategoryTitle, i: CategoryTranslation) {
    switch (titleLang) {
      case Languages.AR :
        i.ar = newCategoryTitle;
        break;
      case Languages.BN :
        i.bn = newCategoryTitle;
        break;
      case Languages.DE :
        i.de = newCategoryTitle;
        break;
      case Languages.EN :
        i.en = newCategoryTitle;
        break;
      case Languages.ES :
        i.es = newCategoryTitle;
        break;
      case Languages.FR :
        i.fr = newCategoryTitle;
        break;
      case Languages.HI :
        i.hi = newCategoryTitle;
        break;
      case Languages.ID :
        i.id = newCategoryTitle;
        break;
      case Languages.IT :
        i.it = newCategoryTitle;
        break;
      case Languages.JA :
        i.ja = newCategoryTitle;
        break;
      case Languages.PT :
        i.pt = newCategoryTitle;
        break;
      case Languages.RU :
        i.ru = newCategoryTitle;
        break;
      case Languages.ZH :
        i.zh = newCategoryTitle;
        break;
    }
    return i;
  }

  getTranslationForTitle(category: Category) {
    return this.translationSwitcher(this.globals.currentLanguage, category);
  }

  getTranslationForTitleByLang(category: Category) {
    if (!category) {
      return '';
    }
    return this.translationSwitcher(category.nativeLanguage, category);
  }

  private translationSwitcher(lang: string, category: Category) {
    let i = '';
    if (category && category.translation != null) {
      switch (lang) {
        case Languages.AR :
          i = category.translation.ar;
          break;
        case Languages.BN :
          i = category.translation.bn;
          break;
        case Languages.DE :
          i = category.translation.de;
          break;
        case Languages.EN :
          i = category.translation.en;
          break;
        case Languages.ES :
          i = category.translation.es;
          break;
        case Languages.FR :
          i = category.translation.fr;
          break;
        case Languages.HI :
          i = category.translation.hi;
          break;
        case Languages.ID :
          i = category.translation.id;
          break;
        case Languages.IT :
          i = category.translation.it;
          break;
        case Languages.JA :
          i = category.translation.ja;
          break;
        case Languages.PT :
          i = category.translation.pt;
          break;
        case Languages.RU :
          i = category.translation.ru;
          break;
        case Languages.ZH :
          i = category.translation.zh;
          break;
      }
    }
    return i;
  }

  moveCategory(categoryToMoveId: number, newParentCategoryId: number, callback, error?: CallableFunction) {
    const params = new HttpParams().append('newParentId', newParentCategoryId.toString());
    this.http.patch(this.globals.apiUrl + '/categories/' + categoryToMoveId, null, {
      params
    }).subscribe(
      () => {
        return callback && callback();
      },
      e => {
        return error && error(e);
      });
  }

  uploadCategoryTree(userId: number, categoryId: number, callback) {
    this.getAllCategories(userId,categoryId).subscribe((response) => {
      this.setGlobalsAllCategories(response);
      return callback && callback();
    });
  }

  assignCategoriesParents(categories: Category[]) {
    const catList = new Array<Category>();
    for (const category of categories) {
      const parId = category.parentCategoryId;
      let tmp = categories.filter((item: Category) => {
        return parId === item.id;
      })[0];
      if (tmp === undefined) {
        tmp = new Category();
      }
      category.parentCategory = tmp;
      catList.push(category);
    }
    return catList;
  }

  getParentForCategory(category: Category, existingCategories: Category[]) {
    const parId = category.parentCategoryId;
    let tmp = existingCategories.filter((item: Category) => {
      return parId === item.id;
    })[0];
    if (tmp === undefined) {
      tmp = new Category();
    }
    category.parentCategory = tmp;
    return category;
  }

  findCategoryChildesByParentId(parentId: number): Category[] {
    if (this.globals.allCategories && this.globals.allCategories.length > 0) {
      return this.globals.allCategories.filter((item) => {
        return item.parentCategory.id === parentId && item.id !== 100;
      });
    }
    return [];
  }

  findCategoryById(id: number): Category | null {
    if (this.globals.allCategories && this.globals.allCategories.length > 0) {
      return this.globals.allCategories.find(item => item.id === id);
    }
    return null;
  }

  initializeFlowerCategories() {
    this.setTopCategories(this.globals.allCategories);
  }

  setTopCategories(categories: Category[]) {
    const topCategories = [];
    if (!categories) {
      return;
    }
    this.globals.topCategoryList = [];
    for (const category of categories) {
      if (category.parentCategory.id === 100) {
        topCategories.push(category);
      }
    }
    const health = topCategories.filter((item) => {
      return item.id === 125;
    });
    this.globals.topCategoryList.push(health[0]);
    const spirLife = topCategories.filter((item) => {
      return item.id === 126;
    });
    this.globals.topCategoryList.push(spirLife[0]);
    const home = topCategories.filter((item) => {
      return item.id === 102;
    });
    this.globals.topCategoryList.push(home[0]);
    const myPersonality = topCategories.filter((item) => {
      return item.id === 127;
    });
    this.globals.topCategoryList.push(myPersonality[0]);
    const socLife = topCategories.filter((item) => {
      return item.id === 104;
    });
    this.globals.topCategoryList.push(socLife[0]);
    const work = topCategories.filter((item) => {
      return item.id === 101;
    });
    this.globals.topCategoryList.push(work[0]);
    const edu = topCategories.filter((item) => {
      return item.id === 103;
    });
    this.globals.topCategoryList.push(edu[0]);
  }

  getParentPath(category: Category) {
    const parents = [];
    for (const parentTitle of category.parentTitles) {
      parents.push(parentTitle.id);
    }
    return parents;
  }

  findCategories(title: string) {
    const params = new HttpParams().append('title', title).append('currentLang', this.globals.currentLanguage);
    return this.http.get(this.globals.apiUrl + '/categories/search', {params});
  }

  getTranslationForTitleById(id) {
    const params = new HttpParams().append('titlesId', id);
    return this.http.get(this.globals.apiUrl + '/categories/translation', {params});
  }

  getCurrentTitleTranslate(res: CategoryTranslation) {
    let i;
    switch (this.globals.currentLanguage) {
      case Languages.AR :
        i = res.ar;
        break;
      case Languages.BN :
        i = res.bn;
        break;
      case Languages.DE :
        i = res.de;
        break;
      case Languages.EN :
        i = res.en;
        break;
      case Languages.ES :
        i = res.es;
        break;
      case Languages.FR :
        i = res.fr;
        break;
      case Languages.HI :
        i = res.hi;
        break;
      case Languages.ID :
        i = res.id;
        break;
      case Languages.IT :
        i = res.it;
        break;
      case Languages.JA :
        i = res.ja;
        break;
      case Languages.PT :
        i = res.pt;
        break;
      case Languages.RU :
        i = res.ru;
        break;
      case Languages.ZH :
        i = res.zh;
        break;
    }
    return i;
  }

  private toJson(category: Category) {
    const {parentCategory, parentTitles, creationDate, numberOfNotes, children, ...mainProps} = category;
    return mainProps;
  }

  filterByCategory(categoryId) {
    this.globals.currentCategoryId = categoryId;
    if (this.globals.action === ADD_NEW_NOT_ACTION) {
      this.globals.action = null;
      this.router.navigateByUrl(`/categories/${categoryId}/new-note`).then(e => console.log(e));
      return;
    }
    if (this.globals.action === EDIT_NOTE_ACTION) {
      this.modalController.dismiss({
        currentCategory: categoryId,
      }).then(r => {
        this.globals.action = null;
      });
      return;
    }
    this.globals.activeFilters.addFilter(new CategoryFilter(categoryId));
    this.globals.needPageRefresh = true;

    if (this.globals.owner.id === this.globals.selectedUser.id) {
      this.router.navigateByUrl('/', {
        queryParams: {
          feed: true
        }
      }).then(e => console.log(e));
    } else {
      this.router.navigateByUrl('/users/' + this.globals.selectedUser.id + '/notes').then(e => console.log(e));
    }
  }
}

