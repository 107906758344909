<div class="breadcrumbs">
  <div class="breadcrumb-block" *ngFor="let breadcrumb of breadcrumbs, let last = last">
    <p
      class="breadcrumb-item"
      [class.active]="last"
      (click)="redirectToCategoryTreeById(breadcrumb.id)"
    >
      {{breadcrumb.title}}
    </p>
    <i *ngIf="!last" class="breadcrumb-separator"></i>
  </div>
</div>
