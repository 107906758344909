import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of as observableOf } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import * as featureActions from "./actions";
import { CategoryApiService } from "../../modules/category-ngrx/category-api.service";
import {Category} from '@app/modules/category/category';

@Injectable()
export class CategoryStoreEffects {
  constructor(
    private catService: CategoryApiService,
    private actions$: Actions
  ) {}

  @Effect()
  loadAllCategories$: Observable<Action> = this.actions$.pipe(
    ofType<featureActions.LoadRequestAction>(
      featureActions.ActionTypes.LOAD_CATEGORIES_REQUEST
    ),
    switchMap(action =>
      this.catService.getAllCategories(action.payload.userId).pipe(
        map(
          categories =>
            new featureActions.LoadSuccessAction({
              categories: this.catService.assignCategoriesParents(
                categories
              )
            })
        ),
        catchError(error =>
          observableOf(new featureActions.LoadFailureAction({ error }))
        )
      )
    )
  );

  @Effect()
  getCategoryById$ = this.actions$.pipe(
    ofType<featureActions.LoadCategoryByIdAction>(
      featureActions.ActionTypes.LOAD_CATEGORY
    ),
    switchMap(action =>
      this.catService.getCategoryById(action.payload.categoryId).pipe(
        map(
          (category: Category) =>
            new featureActions.LoadCategoryByIdSuccessAction({
              currentSelectCategory: category
            })
        ),
        catchError(error =>
          observableOf(new featureActions.LoadFailureAction({ error }))
        )
      )
    )
  );

  @Effect()
  getChildByCategoryId$ = this.actions$.pipe(
    ofType<featureActions.GetChildsCategoriesAction>(
      featureActions.ActionTypes.GET_CHILD_CATEGORIES_BY_PARENT_ID
    ),
    switchMap(action =>
      this.catService
        .getChildCategoriesByParentId(action.payload.categoryId)
        .pipe(
          catchError(error =>
            observableOf(new featureActions.LoadFailureAction({ error }))
          )
        )
    )
  );
}
