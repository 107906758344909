import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-editors-terms',
  templateUrl: './editors-terms.component.html',
  styleUrls: ['./editors-terms.component.scss']
})
export class EditorsTermsComponent implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {
  }

  dismiss() {
      this.modalController.dismiss();
  }

}
