<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons class="left-actions ml-3"
                 slot="start">
      <ion-button shape="round"
                  (click)="dismiss()">
        <img [src]="'assets/icons/design_v4/close_icon.svg'"/>
      </ion-button>
    </ion-buttons>

    <ion-title class="ion-text-center">Terms</ion-title>

  </ion-toolbar>
</ion-header>

<ion-content>
  <ul>
    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?</li>
    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?</li>
    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?</li>
    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?</li>
    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?</li>
    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?</li>
    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?</li>
    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?</li>
  </ul>
</ion-content>
