import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
export class ProfileService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    editUserPhoto(uploadData) {
        return this.http.post(this.globals.apiUrl + '/users/photo', uploadData, {
            reportProgress: true
        });
    }
    editSiteUserName() {
        this.http.put(this.globals.apiUrl + '/users', this.globals.owner).subscribe(() => {
        });
    }
    getUserInfo(user) {
        return this.http.get(this.globals.apiUrl + '/users/' + user.id + '/info');
    }
    getUserInfoById(userId) {
        return this.http.get(this.globals.apiUrl + '/users/' + userId + '/info');
    }
    getReferralCounter(user) {
        return this.http.get(this.globals.apiUrl + '/users/' + user.id + '/info/counters/referrals');
    }
    getAvailableLanguages() {
        return this.http.get(this.globals.apiUrl + '/users/languages');
    }
    getProficiencyList() {
        return this.http.get(this.globals.apiUrl + '/users/proficiencies');
    }
    editUserProfileInfo(userInfo, callback) {
        this.http.put(this.globals.apiUrl + '/users/info', userInfo).subscribe(() => {
            return callback && callback();
        });
    }
    editUserBanner(uploadData) {
        return this.http.post(this.globals.apiUrl + '/users/banner', uploadData, {
            reportProgress: true
        });
    }
    getUserLanguages(userId) {
        return this.http.get(this.globals.apiUrl + '/users/' + userId + '/languages');
    }
    setReferrer(selectedUser, referrer) {
        if (referrer != null && referrer !== "") {
            referrer = referrer.toLowerCase();
            console.log("Service referrer is " + referrer);
            this.http.put(this.globals.apiUrl + '/users/' + selectedUser.id + '/info/referrer', referrer).subscribe(() => {
            });
        }
    }
}
ProfileService.ɵfac = function ProfileService_Factory(t) { return new (t || ProfileService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
ProfileService.ɵprov = i0.ɵɵdefineInjectable({ token: ProfileService, factory: ProfileService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ProfileService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
