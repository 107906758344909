import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LevelData} from '@app/modules/category-level/LevelData';
import {IonSlides, ModalController} from '@ionic/angular';
import {CategoryService} from '@app/shared/services/category.service';
import {Category} from '@app/modules/category/category';
import {CategoryChanged} from '@app/modules/category-slider/CategoryChanged';
import {Globals} from '@app/shared/globals';
import {PlaceComponent} from "@app/modules/place/place.component";
import {Place} from "@app/modules/place/place";
import {Label} from "@app/modules/label/label";
import {GeoService} from "@app/shared/services/geo.service";
import {LabelService} from "@app/shared/services/label.service";
import {FeedService} from "@app/shared/services/feed.service";

@Component({
  selector: 'app-category-slider',
  templateUrl: './category-slider.component.html',
  styleUrls: ['./category-slider.component.scss'],
})
export class CategorySliderComponent implements OnInit, AfterViewInit {
  @Input() levelData: LevelData;

  @ViewChild(IonSlides, {static: true}) slider: IonSlides;

  @Output() categoryChanged = new EventEmitter<CategoryChanged>();

  private isInitialized = false;
  public selectedPlace;

  readonly rootSliderOptions = {
    slidesPerView: 3,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 100,
      modifier: 5,
      slideShadows: false,
    },
    on: {
      beforeInit() {
        const swiper = this;

        swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

        swiper.params.watchSlidesProgress = true;
        swiper.originalParams.watchSlidesProgress = true;
      },
      setTranslate() {
        const swiper = this;
        const {
          width: swiperWidth, height: swiperHeight, slides, $wrapperEl, slidesSizesGrid, $
        } = swiper;
        const params = swiper.params.coverflowEffect;
        const isHorizontal = swiper.isHorizontal();
        const transform$$1 = swiper.translate;
        const center = isHorizontal ? -transform$$1 + (swiperWidth / 2) : -transform$$1 + (swiperHeight / 2);
        const rotate = isHorizontal ? params.rotate : -params.rotate;
        const translate = params.depth;
        // Each slide offset from center
        for (let i = 0, length = slides.length; i < length; i += 1) {
          const $slideEl = slides.eq(i);
          const slideSize = slidesSizesGrid[i];
          const slideOffset = $slideEl[0].swiperSlideOffset;
          const offsetMultiplier = ((center - slideOffset - (slideSize / 2)) / slideSize) * params.modifier;

          let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
          let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
          // var rotateZ = 0
          let translateZ = -translate * Math.abs(offsetMultiplier);

          let translateY = isHorizontal ? 0 : params.stretch * (offsetMultiplier);
          let translateX = isHorizontal ? params.stretch * (offsetMultiplier) : 0;

          // Fix for ultra small values
          if (Math.abs(translateX) < 0.001) translateX = 0;
          if (Math.abs(translateY) < 0.001) translateY = 0;
          if (Math.abs(translateZ) < 0.001) translateZ = 0;
          if (Math.abs(rotateY) < 0.001) rotateY = 0;
          if (Math.abs(rotateX) < 0.001) rotateX = 0;

          const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

          $slideEl.transform(slideTransform);
          $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
          if (params.slideShadows) {
            // Set shadows
            let $shadowBeforeEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
            let $shadowAfterEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
            if ($shadowBeforeEl.length === 0) {
              $shadowBeforeEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'left' : 'top'}"></div>`);
              $slideEl.append($shadowBeforeEl);
            }
            if ($shadowAfterEl.length === 0) {
              $shadowAfterEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'right' : 'bottom'}"></div>`);
              $slideEl.append($shadowAfterEl);
            }
            if ($shadowBeforeEl.length) $shadowBeforeEl[0].style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0;
            if ($shadowAfterEl.length) $shadowAfterEl[0].style.opacity = (-offsetMultiplier) > 0 ? -offsetMultiplier : 0;
          }
        }

        // Set correct perspective for IE10
        if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
          const ws = $wrapperEl[0].style;
          ws.perspectiveOrigin = `${center}px 50%`;
        }
      },
      setTransition(duration) {
        const swiper = this;
        swiper.slides
          .transition(duration)
          .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
          .transition(duration);
      }
    }
  };
  @Output() selectedCategory = new EventEmitter<number>();

  constructor(
    public categoryService: CategoryService,
    public globals: Globals,
    public modalController: ModalController,
    public geoService: GeoService,
    public labelService: LabelService,
    public feedService: FeedService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    const index = this.levelData.categories.findIndex((category: Category) => category.id === this.levelData.activeCategoryId);
    this.geoService.selectedPlace.subscribe((place: Place) => {
      this.selectedPlace = place;
    });
    this.slider.ionSlideDidChange.subscribe(() => {
      this.slider.getSwiper().then((swiper) => {
        if (this.isInitialized) {
          this.selectHandler(this.levelData.categories[swiper.realIndex]);
        }

        this.isInitialized = true;
      });
    });

    this.slider.slideTo(index).then(() => {
      console.log('Slider was initialized: ', this.levelData.activeCategoryId);

      if (index <= 0) {
        this.isInitialized = true;
      }
    });
  }

  selectHandler(category: Category) {
    if (category.label && this.geoService.isPlaceEmpty(this.selectedPlace)) {
      this.modalController.create({component: PlaceComponent}).then((modalElement) => {
        modalElement.present();
      });
    } else if (!category.label) {
      this.geoService.changeSelectedPlace(new Place());
      this.labelService.changeSelectedLabel(new Label());
    }
    this.categoryChanged.emit({
      previousActiveId: this.levelData.activeCategoryId,
      currentActiveId: category.id
    });
    this.levelData.activeCategoryId = category.id;
  }

  slideSwipe(categoryId: number) {
    const index = this.levelData.categories.findIndex((categoryTwo: Category) => categoryTwo.id === categoryId);

    if (index < 0) {
      return;
    }

    this.slider.slideTo(index, undefined, true).then(() => {
      console.log('Clicked to slide in category slider');

      this.slider.ionSlideDidChange.toPromise().then(() => {
        this.slider.getSwiper().then((swiper) => {
          this.selectHandler(this.levelData.categories[swiper.realIndex]);
          this.isInitialized = true;
        });
      });
    });
  }

  onClickHandler(category: Category) {
    console.log('Current active category: ', this.levelData.activeCategoryId);
    console.log('Clicked to category: ', category);

    if (category.id === this.levelData.activeCategoryId) {
      this.selectHandler(category);
      return;
    }

    this.slideSwipe(category.id);
  }

  getSliderTextClass() {
    return this.levelData.categories && this.levelData.categories[0] && this.levelData.categories[0].parentCategory.id !== null;
  }
}
