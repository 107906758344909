<ion-content>
  <div class="container">
    <div class="category-path"></div>
    <div class="new-note-category-breadcrumbs">
      <app-category-breadcrumbs
        [categories]="selectedCategory"
        [mod]="'NEW'">
      </app-category-breadcrumbs>
    </div>
    <app-quill #quillComponent *ngIf="firstSlide" class="quill-editor-app"></app-quill>
    <div class="significance_range_container" *ngIf="!firstSlide">
      <input
        type="range"
        min="1"
        max="5"
        [(ngModel)]="selectedWeightLevel"
      />
      <div class="tickmarks_container">
        <div class="tickmark"></div>
        <div class="tickmark"></div>
        <div class="tickmark"></div>
        <div class="tickmark"></div>
        <div class="tickmark"></div>
      </div>
    </div>

    <div class="button-group" *ngIf="!firstSlide" [bdcWalkTriggerFor]="addNoteGuideTask10">
      <ng-container *ngFor="let accessType of privacy">
        <button class="button-group__item"
                *ngIf="accessType !== friendAccessType"
                [class.button-group__item--active]="selectedAccessType === accessType"
                (click)="changeAccessType(accessType)">
            {{ accessType| translate }}
        </button>

<!--        <button class="button-group__item"-->
<!--                *ngIf="accessType === friendAccessType"-->
<!--                [class.button-group__item&#45;&#45;active]="selectedAccessType === accessType"-->
<!--                (click)="presentAccessTypeMenu($event)">-->
<!--          {{ group| translate }}-->
<!--        </button>-->

      </ng-container>
    </div>
  </div>
  <bdc-walk-popup class="wide-popup" #addNoteGuideTask10 name="addNoteGuideTask10" xPosition="after" yPosition="below"
                  [mustCompleted]="{addNoteGuideShowWalkthrough: true, addNoteGuideTask9: true}"
                  [showCloseButton]="true" [showButton]="true" [buttonText]="'Ok' | translate"
                  [closeOnClick]="true">
    <div translate>
      Colour bar indicates, how the note is important for you<br>
    </div>
  </bdc-walk-popup>
</ion-content>
