import { Component } from '@angular/core';
import { Globals } from '../../shared/globals';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { MenuService } from '@app/shared/services/menu/menu.service';
import { Events } from '@ionic/angular';
import { NewFeed } from "@app/modules/home/NewFeed";
import { FeedType } from "@app/modules/home/FeedType";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/globals";
import * as i2 from "@angular/router";
import * as i3 from "@app/core/auth/authentication.service";
import * as i4 from "@app/shared/services/toolbar/toolbar.service";
import * as i5 from "@app/shared/services/menu/menu.service";
import * as i6 from "@ionic/angular";
import * as i7 from "@angular/common";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@app/shared/pipes/user-name.pipe";
function MenuComponent_ion_menu_0_ng_container_13_ion_item_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-item", 14);
    i0.ɵɵlistener("click", function MenuComponent_ion_menu_0_ng_container_13_ion_item_1_Template_ion_item_click_0_listener() { const p_r7 = ctx.$implicit; return p_r7.action ? p_r7.action() : null; });
    i0.ɵɵelementStart(1, "div", 15);
    i0.ɵɵelement(2, "img", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p", 17);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const p_r7 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", p_r7.url);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", p_r7.icon, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(p_r7.title);
} }
function MenuComponent_ion_menu_0_ng_container_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenuComponent_ion_menu_0_ng_container_13_ion_item_1_Template, 5, 3, "ion-item", 13);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r1.pagesForAdmin);
} }
function MenuComponent_ion_menu_0_ng_template_14_ng_container_0_ion_item_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-item", 14);
    i0.ɵɵlistener("click", function MenuComponent_ion_menu_0_ng_template_14_ng_container_0_ion_item_1_Template_ion_item_click_0_listener() { const p_r11 = ctx.$implicit; return p_r11.action ? p_r11.action() : null; });
    i0.ɵɵelementStart(1, "div", 15);
    i0.ɵɵelement(2, "img", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p", 17);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const p_r11 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", p_r11.url);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", p_r11.icon, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(p_r11.title);
} }
function MenuComponent_ion_menu_0_ng_template_14_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenuComponent_ion_menu_0_ng_template_14_ng_container_0_ion_item_1_Template, 5, 3, "ion-item", 13);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r9.pagesForEditor);
} }
function MenuComponent_ion_menu_0_ng_template_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵtemplate(0, MenuComponent_ion_menu_0_ng_template_14_ng_container_0_Template, 2, 1, "ng-container", 10);
} if (rf & 2) {
    i0.ɵɵnextContext();
    const _r4 = i0.ɵɵreference(17);
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r3.globals.isEditor)("ngIfElse", _r4);
} }
function MenuComponent_ion_menu_0_ng_template_16_ion_item_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-item", 14);
    i0.ɵɵlistener("click", function MenuComponent_ion_menu_0_ng_template_16_ion_item_0_Template_ion_item_click_0_listener() { const p_r14 = ctx.$implicit; return p_r14.action ? p_r14.action() : null; });
    i0.ɵɵelementStart(1, "div", 15);
    i0.ɵɵelement(2, "img", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p", 17);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const p_r14 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", p_r14.url);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", p_r14.icon, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(p_r14.title);
} }
function MenuComponent_ion_menu_0_ng_template_16_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵtemplate(0, MenuComponent_ion_menu_0_ng_template_16_ion_item_0_Template, 5, 3, "ion-item", 13);
} if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngForOf", ctx_r5.pages);
} }
const _c0 = function (a0) { return { "padding-top": a0 }; };
function MenuComponent_ion_menu_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-menu", 1);
    i0.ɵɵelementStart(1, "ion-content", 2);
    i0.ɵɵelementStart(2, "ion-list");
    i0.ɵɵelementStart(3, "ion-menu-toggle", 3);
    i0.ɵɵelementStart(4, "ion-item", 4);
    i0.ɵɵelementStart(5, "div", 5);
    i0.ɵɵelement(6, "img", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "div", 7);
    i0.ɵɵelementStart(8, "p", 8);
    i0.ɵɵtext(9);
    i0.ɵɵpipe(10, "userName");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "p", 9);
    i0.ɵɵtext(12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(13, MenuComponent_ion_menu_0_ng_container_13_Template, 2, 1, "ng-container", 10);
    i0.ɵɵtemplate(14, MenuComponent_ion_menu_0_ng_template_14_Template, 1, 2, "ng-template", null, 11, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵtemplate(16, MenuComponent_ion_menu_0_ng_template_16_Template, 1, 1, "ng-template", null, 12, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const _r2 = i0.ɵɵreference(15);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(8, _c0, ctx_r0.toolbarService.isNewIphone ? "30px" : "0"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", ctx_r0.globals.owner.userPhotoName, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(10, 6, ctx_r0.globals.owner), " ");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.globals.owner.ssoId, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.globals.isAdmin)("ngIfElse", _r2);
} }
export class MenuComponent {
    constructor(globals, router, authService, toolbarService, menuService, events) {
        this.globals = globals;
        this.router = router;
        this.authService = authService;
        this.toolbarService = toolbarService;
        this.menuService = menuService;
        this.events = events;
        this.fullname = document.getElementById('fullname');
        this.pages = [
            {
                title: 'Home',
                icon: 'assets/icons/design_v4/home.svg',
                action: () => this.refreshHome()
            },
            {
                title: 'Messages',
                icon: 'assets/icons/design_v4/messages.svg',
                url: 'chat'
            },
            {
                title: 'Friends',
                icon: 'assets/icons/design_v4/friends.svg',
                url: 'friends'
            },
            {
                title: 'Settings',
                icon: 'assets/icons/design_v4/settings.png',
                url: 'settings'
            },
            {
                title: 'Support',
                icon: 'assets/icons/design_v4/work_and_education_inverted.svg',
                url: 'alerts'
            },
            {
                title: 'Log Out',
                icon: 'assets/icons/design_v4/logout.svg',
                action: () => this.logout()
            }
        ];
        this.pagesForEditor = [
            {
                title: 'Home',
                icon: 'assets/icons/design_v4/home.svg',
                action: () => this.refreshHome()
            },
            {
                title: 'Messages',
                icon: 'assets/icons/design_v4/messages.svg',
                url: 'chat'
            },
            {
                title: 'Friends',
                icon: 'assets/icons/design_v4/friends.svg',
                url: 'friends',
            },
            {
                title: 'Settings',
                icon: 'assets/icons/design_v4/settings.png',
                url: 'settings',
            },
            {
                title: 'Support',
                icon: 'assets/icons/design_v4/work_and_education_inverted.svg',
                url: 'alerts',
            },
            {
                title: 'Editors',
                icon: 'assets/icons/design_v4/icon-editors.svg',
                url: 'editors',
            },
            {
                title: 'Log Out',
                icon: 'assets/icons/design_v4/logout.svg',
                action: () => this.logout()
            }
        ];
        this.pagesForAdmin = [
            {
                title: 'Home',
                icon: 'assets/icons/design_v4/home.svg',
                action: () => this.refreshHome()
            },
            {
                title: 'Messages',
                icon: 'assets/icons/design_v4/messages.svg',
                url: 'chat'
            },
            {
                title: 'Friends',
                icon: 'assets/icons/design_v4/friends.svg',
                url: 'friends'
            },
            {
                title: 'Settings',
                icon: 'assets/icons/design_v4/settings.png',
                url: 'settings'
            },
            {
                title: 'Support',
                icon: 'assets/icons/design_v4/work_and_education_inverted.svg',
                url: 'alerts'
            },
            {
                title: 'Editors',
                icon: 'assets/icons/design_v4/icon-editors.svg',
                url: 'editors'
            },
            {
                title: 'Admin',
                icon: 'assets/icons/design_v4/settings.png',
                url: 'admin'
            },
            {
                title: 'Log Out',
                icon: 'assets/icons/design_v4/logout.svg',
                action: () => this.logout()
            }
        ];
    }
    updateProfileData() {
        this.fullname.innerText = `${this.globals.owner.firstName} ${this.globals.owner.lastName}`;
    }
    refreshHome() {
        this.globals.selectedUser = this.globals.owner;
        this.globals.needPageRefresh = true;
        this.globals.activeFilters.removeFilter(this.globals.activeFilters.categoryFilter);
        this.router.navigate(['/']);
        this.globals.feed = new NewFeed(FeedType.WORLD);
        this.events.publish('refresh:home', true);
        this.menuService.toggleMenu();
    }
    logout() {
        this.authService.logout();
    }
}
MenuComponent.ɵfac = function MenuComponent_Factory(t) { return new (t || MenuComponent)(i0.ɵɵdirectiveInject(i1.Globals), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.AuthenticationService), i0.ɵɵdirectiveInject(i4.ToolbarService), i0.ɵɵdirectiveInject(i5.MenuService), i0.ɵɵdirectiveInject(i6.Events)); };
MenuComponent.ɵcmp = i0.ɵɵdefineComponent({ type: MenuComponent, selectors: [["app-menu"]], decls: 1, vars: 1, consts: [["contentId", "content", "max-edge-start", "35", "menuId", "main-menu", "id", "main-menu", "side", "start", "type", "push", 4, "ngIf"], ["contentId", "content", "max-edge-start", "35", "menuId", "main-menu", "id", "main-menu", "side", "start", "type", "push"], ["color", "primary"], ["auto-hide", "false"], ["color", "primary", "routerLink", "profile", "routerDirection", "forward", 1, "user-menu-item", 3, "ngStyle"], [1, "avatar"], [3, "src"], [1, "user-data-column"], ["id", "fullname", 1, "user-fullname"], [1, "user-ssoid"], [4, "ngIf", "ngIfElse"], ["noAdminPage", ""], ["noEditorPages", ""], ["color", "primary", "routerDirection", "forward", "class", "menu-item", 3, "routerLink", "click", 4, "ngFor", "ngForOf"], ["color", "primary", "routerDirection", "forward", 1, "menu-item", 3, "routerLink", "click"], [1, "icons-column"], [1, "menu-icon", 3, "src"], ["translate", "", 1, "menu-title"]], template: function MenuComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, MenuComponent_ion_menu_0_Template, 18, 10, "ion-menu", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.globals.owner);
    } }, directives: [i7.NgIf, i6.IonMenu, i6.IonContent, i6.IonList, i6.IonMenuToggle, i6.IonItem, i2.RouterLink, i6.RouterLinkDelegate, i7.NgStyle, i7.NgForOf, i8.TranslateDirective], pipes: [i9.UserNamePipe], styles: ["ion-menu[_ngcontent-%COMP%]   ion-content[_ngcontent-%COMP%]   ion-list[_ngcontent-%COMP%]{background:var(--ion-color-primary)!important}ion-menu[_ngcontent-%COMP%]   ion-content[_ngcontent-%COMP%]   ion-item[_ngcontent-%COMP%]{color:var(--ion-color-light)}ion-menu[_ngcontent-%COMP%]   div.icons-column[_ngcontent-%COMP%]{width:45px;text-align:center;padding-right:10px}ion-menu[_ngcontent-%COMP%]   p.menu-title[_ngcontent-%COMP%]{color:var(--ion-color-light);font-size:18px;padding:20px 0}ion-menu[_ngcontent-%COMP%]   ion-menu-toggle[_ngcontent-%COMP%]:last-child   p.menu-title[_ngcontent-%COMP%]{padding-top:40px}ion-menu[_ngcontent-%COMP%]   ion-menu-toggle[_ngcontent-%COMP%]:last-child   img.menu-icon[_ngcontent-%COMP%]{padding-top:20px}ion-menu[_ngcontent-%COMP%]   .user-menu-item[_ngcontent-%COMP%]{min-height:80px}ion-menu[_ngcontent-%COMP%]   .user-menu-item[_ngcontent-%COMP%]   .avatar[_ngcontent-%COMP%]{min-width:75px;min-height:75px}ion-menu[_ngcontent-%COMP%]   .user-menu-item[_ngcontent-%COMP%]   .avatar[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{border-radius:100%;width:70px;-o-object-fit:contain;object-fit:contain;padding:10px 0}ion-menu[_ngcontent-%COMP%]   .user-menu-item[_ngcontent-%COMP%]   .user-data-column[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{padding:5px 15px;color:var(--ion-color-light)}ion-menu[_ngcontent-%COMP%]   .user-menu-item[_ngcontent-%COMP%]   .user-data-column[_ngcontent-%COMP%]   p.user-ssoid[_ngcontent-%COMP%]{color:RGBA(244,245,248,.7)}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MenuComponent, [{
        type: Component,
        args: [{
                selector: 'app-menu',
                templateUrl: './menu.component.html',
                styleUrls: ['./menu.component.css']
            }]
    }], function () { return [{ type: i1.Globals }, { type: i2.Router }, { type: i3.AuthenticationService }, { type: i4.ToolbarService }, { type: i5.MenuService }, { type: i6.Events }]; }, null); })();
