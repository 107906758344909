import {UserProfile} from './UserProfile';
import {UserSocialLink} from './user-social-link';

export class SiteUser {
  id: number;
  ssoId: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  userProfiles: UserProfile[];
  userPhotoName: string;
  noteCounter?: number;
  socialNetworks?: UserSocialLink[];
  witaAuthToken?: object;
  provider?: string;
  referrer?: string;
  relationType?: number;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}
