var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AlertController } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
import * as i3 from "@ionic/angular";
import * as i4 from "@ionic-native/app-version/ngx";
import * as i5 from "@ionic-native/market/ngx";
export class CheckUpdateService {
    constructor(http, globals, alertController, appVersion, market) {
        this.http = http;
        this.globals = globals;
        this.alertController = alertController;
        this.appVersion = appVersion;
        this.market = market;
    }
    isNeedUpdate() {
        return __awaiter(this, void 0, void 0, function* () {
            const appVersionObj = yield this.getInfo();
            const currentAppVersionCode = yield this.appVersion.getVersionCode();
            if (appVersionObj && appVersionObj.enableFeature && +appVersionObj.version.split('.').join('') > +currentAppVersionCode) {
                this.presentAlertConfirm(appVersionObj);
            }
        });
    }
    presentAlertConfirm(appVersionObj) {
        return __awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: 'Update info',
                message: `New version <strong>${appVersionObj.version}</strong> is available. Please update application`,
                buttons: [
                    {
                        text: 'Later',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => { }
                    }, {
                        text: 'Update',
                        handler: () => {
                            this.market.open(appVersionObj.androidUri);
                        }
                    }
                ]
            });
            yield alert.present();
        });
    }
    getInfo() {
        return this.http.get(this.globals.apiUrl + '/api/version/android').toPromise();
    }
}
CheckUpdateService.ɵfac = function CheckUpdateService_Factory(t) { return new (t || CheckUpdateService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.AlertController), i0.ɵɵinject(i4.AppVersion), i0.ɵɵinject(i5.Market)); };
CheckUpdateService.ɵprov = i0.ɵɵdefineInjectable({ token: CheckUpdateService, factory: CheckUpdateService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CheckUpdateService, [{
        type: Injectable,
        args: [{
                providedIn: 'root',
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.AlertController }, { type: i4.AppVersion }, { type: i5.Market }]; }, null); })();
