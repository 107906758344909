import {LabelTypeTitle} from "@app/modules/label/label-type-title";

export class LabelType {
  title: LabelTypeTitle;
  isChecked: boolean;

  constructor(title: LabelTypeTitle) {
    this.title = title;
    this.isChecked = false;
  }
}
