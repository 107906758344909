import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordResetService} from '../../../shared/services/password-reset.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {matchOtherValidator, noCyrillic} from '../../../shared/const/customFormValidators';
import {UiAlertService} from '@app/shared/services/ui-alert-service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  id: string;
  token: string;
  status: number;

  resetForm: FormGroup;
  isLoading = true;

  constructor(
    private formBuilder: FormBuilder,
    private uiAlertService: UiAlertService,
    public router: Router,
    public route: ActivatedRoute, public passwordResetService: PasswordResetService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.token = params['token'];

      this.passwordResetService.validateToken(this.id, this.token).subscribe(async (response) => {
        this.status = response.status;
        if (this.status === 204) {
          await this.uiAlertService.createAlertWithButton('Error', 'Invalid token', 'Okay', () => {
            this.router.navigateByUrl('/auth');
          });
        }
        if (this.status === 200) {
          this.isLoading = false;
        }
      });
    });

    this.resetForm = this.formBuilder.group({
      password: [null, [
        Validators.required,
        noCyrillic,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'),
      ]],
      confirmPassword: [null, [
        Validators.required,
        matchOtherValidator('password')
      ]]
    });
  }

  ngOnInit() {
  }

  resetPassword() {
    this.passwordResetService.changeUserPassword(this.resetForm.value.password, this.id).subscribe(async (res) => {
      console.log(res);
      await this.uiAlertService.createAlertWithButton('Success!', 'Password restored!', 'Okay', () => {
        this.router.navigateByUrl('/login');
      });
    });
  }

}
