<div class="container" *ngIf="globals.isAuthenticated">

  <div class="sign-up__logo">
    <img [src]="'assets/icons/design_v4/logo.svg'" alt="">
  </div>

  <form class="form" role="form">
    <div class="form__item" *ngIf="!globals.owner.firstName">
      <img class="form__item__icon" [src]="friendsFeedHeader.png" alt="">
      <input
        [(ngModel)]="firstName"
        (change)="disabledButton()"
        class="form__item__input"
        type="text"
        placeholder="{{'Name' | translate}}"
        id="name"
        name="name"
      />
    </div>

    <div class="form__item" *ngIf="!globals.owner.email">
      <img class="form__item__icon" [src]="'assets/icons/design_v4/email_icon.svg'" alt="">
      <input
        [(ngModel)]="email"
        (change)="disabledButton()"
        class="form__item__input"
        type="email"
        placeholder="{{'Email' | translate}}"
        id="email"
        name="email"
      />
    </div>

    <div class="form__item">
      <img class="form__item__icon" [src]="'assets/icons/design_v4/comment-inverted.svg'" alt="">
      <ion-input
        [(ngModel)]="theme"
        (ionChange)="disabledButton()"
        class="form__item__input"
        type="text"
        placeholder="{{'Theme' | translate}}"
        id="theme"
        name="theme"
      ></ion-input>
    </div>

    <div class="form__item form__item_textarea">
      <img class="form__item__icon form__item_icon_textarea" [src]="'assets/icons/design_v4/align.svg'" alt="">
      <ion-textarea
        [(ngModel)]="text"
        (ionChange)="disabledButton()"
        class="form__item__input"
        type="text"
        placeholder="{{'Issue' | translate}}"
        id="issue"
        name="issue"
        rows="3"
      ></ion-textarea>
    </div>

    <ion-button
      class="form__button"
      [disabled]="disabled"
      color="primary"
      (click)="addAlertToFeedback()"
    >
      <span
        translate
        class="form__button__text"
      >
        Send form
      </span>
    </ion-button>
  </form>
</div>
