<app-auth-header title="Contact" [endBtns]="endActions"></app-auth-header>
<ion-content>

  <div class="sign-up__form">

    <div class="sign-up__logo">
      <img
        [src]="'assets/icons/design_v4/logo.svg'"
        alt="">
    </div>

    <form
      class="form"
      [formGroup]="supportForm"
      role="form">

      <div class="form__item">
        <img
          class="form__item__icon"
          [src]="'assets/icons/design_v4/user_icon.svg'"
          alt="">
        <input
          class="form__item__input"
          type="text"
          placeholder="{{'Name' | translate}}"
          id="name"
          name="name"
          formControlName="name"/>
      </div>

      <div class="form__item">
        <img
          class="form__item__icon"
          [src]="'assets/icons/design_v4/email_icon.svg'"
          alt="">
        <input
          class="form__item__input"
          type="email"
          placeholder="{{'Email' | translate}}"
          id="email"
          name="email"
          formControlName="email"/>
      </div>

      <div class="form__item">
        <img
          class="form__item__icon"
          [src]="'assets/icons/design_v4/comment-inverted.svg'"
          alt="">
        <input
          class="form__item__input"
          formControlName="theme"
          type="text"
          placeholder="{{'Theme' | translate}}"
          id="theme"
          name="theme"/>
      </div>

      <div class="form__item form__item_textarea">
        <img
          class="form__item__icon form__item_icon_textarea"
          [src]="'assets/icons/design_v4/align.svg'"
          alt="">
        <textarea
          class="form__item__input"
          formControlName="issue"
          type="text"
          placeholder="{{'Issue' | translate}}"
          id="issue"
          name="issue"></textarea>
      </div>

      <p
        class="form__error"
        *ngIf="errorMsg !== ''"
        translate>
        {{errorMsg}}
      </p>
      <p
        class="form__error"
        *ngIf="validationData.email"
        translate>
        {{validationData.email}}
      </p>

      <ion-button
        class="form__button"
        [disabled]="supportForm.invalid || validationData.email !== null"
        color="primary"
        (click)="sendForm()">
        <span
          translate
          class="form__button__text">
          Send form
        </span>
      </ion-button>
    </form>
  </div>
</ion-content>
