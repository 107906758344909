<app-auth-header title="Reset password"></app-auth-header>

<ion-content>

<div class="forgot-password">
  <div class="forgot-password__logo">
    <img
      [src]="'assets/icons/design_v4/lock.svg'"
      alt="">
  </div>

  <div class="forgot-password__form">

    <form
      class="form"
      [formGroup]="fpForm"
      role="form">

      <div class="form__item">
        <ion-spinner
          class="form__item__loader"
          *ngIf="isCheckingEmail"
          name="crescent"></ion-spinner>
        <img
          *ngIf="!isCheckingEmail"
          class="form__item__icon"
          [src]="'assets/icons/design_v4/email_icon.svg'"
          alt="">
        <input
          (keyup)="errorMsg = null"
          class="form__item__input"
          type="email"
          placeholder="{{'Email' | translate}}"
          id="email"
          name="email"
          formControlName="email"/>
      </div>

      <div *ngIf="errorMsg" class="form__error" translate>{{errorMsg}}</div>

      <ion-button
        [disabled]="fpForm.invalid"
        color="primary"
        style="text-transform: capitalize"
        (click)="restorePassword()">
        <span
          translate
          class="form__button__text reset-password-text">
          Reset password
        </span>
      </ion-button>
    </form>
  </div>
</div>
</ion-content>
