import {Component, OnInit} from '@angular/core';
import {FacebookAuthService} from '../facebook-auth.service';
import {GoogleAuthService} from '../google-auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../authentication.service';
import {LoadingController, PopoverController} from '@ionic/angular';
import {Router} from '@angular/router';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {LanguagesList} from '@app/core/languages';
import {TranslateService} from '@ngx-translate/core';
import {LangPickerDropDownComponent} from '@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component';
import {Globals} from '@app/shared/globals';
import {AppleAuthService} from '@app/core/auth/apple-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', './../authentication.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  popover: any;
  languageList = LanguagesList;
  endActions = [];


  constructor(
    private facebookAuthService: FacebookAuthService,
    private googleAuthService: GoogleAuthService,
    private formBuilder: FormBuilder,
    public app: AuthenticationService,
    public loadingController: LoadingController,
    public router: Router,
    private menuService: MenuService,
    private toolbarService: ToolbarService,
    public translate: TranslateService,
    private globals: Globals,
    private appleAuthService: AppleAuthService,
    public popoverController: PopoverController,
  ) {
    this.loginForm = this.formBuilder.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
  }

  ngOnInit() {
    const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;

    this.endActions = [
      {
        icon: `langs/${lang.toLocaleLowerCase()}.svg`,
        name: lang,
        callback: env => this.presentLanguageMenu(env)
      }
    ];
  }

  async presentLanguageMenu(env) {

    this.popover = await this.popoverController.create({
      component: LangPickerDropDownComponent,
      event: env,
      translucent: false,
      showBackdrop: false,
    });


    await this.popover.present();

    this.popover.onDidDismiss().then(
      e => {
        if (e.data) {
          this.endActions[0].icon = `langs/${e.data.name.toLocaleLowerCase()}.svg`;
          this.endActions[0].name = e.data.name;
        }
      }
    );
  }

  loginWithFb = () => this.facebookAuthService.accessWithFB("", false);
  loginWithGoogle = () => this.googleAuthService.accessWithGoogle("", false);
  loginWithAppleID = () => this.appleAuthService.accessWithAppleID("", false);

  async login(hideLoading?: boolean, credential?: any) {
    let loading: any;

    if (!hideLoading) {
      loading = await this.loadingController.create({});
      await loading.present();
    }


    this.loginForm.value.username = this.loginForm.value.username.trim();


    this.app.authenticateWithLowCase(credential ? credential : this.loginForm.value,
      async () => {

        await this.router.navigateByUrl('/');
        this.toolbarService.setHeaderVisibility(true);
        this.menuService.activeMainMenu();
        if (!hideLoading && loading) {
          loading.dismiss();
        }
      },
      async (error) => {
        if (error.status !== 401) {
          await this.app.handleError(error);
        }

        if (!hideLoading && loading) {
          loading.dismiss();
        }
      });
    return false;
  }

}
