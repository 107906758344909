<ion-content>
  <div class="profile">
    <div class="profile__header">
      <div
        class="backdrop">
        <img
          src="{{globals.selectedUserInfo && globals.selectedUserInfo.userBannerName ? globals.selectedUserInfo.userBannerName : 'assets/icons/design_v4/emptybackdrop.svg'}}"
          alt=""
          class="backdrop__image">
      </div>
    </div>
  </div>
<div class="container">
  <div class="row">
    <div class="col-12 settings-profile-data">
      <div class="profile-image">
        <img id="user-photo" [src]="globals.owner.userPhotoName" alt="Profile picture"/>
      </div>
      <div class="profile-name">{{globals.owner | userName}}</div>
    </div>
    <div class="col-12 settings-wrapper">
      <div class="row">
        <div class="setting-container setting-title col-12" translate>
            Change password
          <div
            class="password-display-change"
            (click)="oldPassword.changePasswordDisplay();
                         newPassword.changePasswordDisplay();
                         confirmPassword.changePasswordDisplay()"
            [ngClass]="oldPassword.passwordHidden === true
                        || newPassword === true
                        || confirmPassword === true ? '' : 'eye-closed'"
          ></div>
          <form
              [formGroup]="changePassForm"
              class="pass-change-form">
            <div class="u-form-group">
              <input
                  type="{{oldPassword.passwordHidden === true ? 'password' : 'username'}}"
                  #oldPasswordRef
                  placeholder="{{'Old password' | translate}}"
                  name="oldPassword"
                  formControlName="oldPassword"/>

            </div>
            <div class="u-form-group">
              <input
                  type="{{newPassword.passwordHidden === true ? 'password' : 'username'}}"
                  #newPasswordRef
                  placeholder="{{'New password' | translate}}"
                  name="password"
                  formControlName="password"/>
            </div>
            <div class="u-form-group">
              <input
                  type="{{confirmPassword.passwordHidden === true ? 'password' : 'username'}}"
                  #confirmPasswordRef
                  placeholder="{{'Confirm password' | translate}}"
                  name="confirmPassword"
                  formControlName="confirmPassword"/>
            </div>
            <p class="match-warning"
               *ngIf="changePassForm.get('password').invalid && changePassForm.get('password').touched && changePassForm.get('password')['errors']['pattern']"
               translate>
              Password must contain one uppercase letter, one lowercase, one digit, one character and be longer then 8 symbols.
            </p>
            <p class="match-warning"
               *ngIf="changePassForm.get('password').invalid && changePassForm.get('password').touched && changePassForm.get('password')['errors']['hasCyrillic']"
               translate>
              Password can only contain latin characters, numbers
            </p>
            <p class="match-warning"
               *ngIf="changePassForm.get('confirmPassword').invalid && changePassForm.get('confirmPassword').touched && changePassForm.get('confirmPassword')['errors']['matchOther']"
               translate>
              Your password and confirmation password do notmatch.
            </p>
            <div class="u-form-group">
              <ion-button
                  class="btn-save btn-md text-center"
                  [disabled]="changePassForm.invalid"
                  color="primary" (click)="saveCurrentPassword()" translate>
                <i class="fa fa-check fa-small"></i>Change password</ion-button>
            </div>
          </form>
        </div>

        <div class="col-12 setting-container  page-privacy-container" *ngIf="false">
          <div class="setting-title" translate>Page privacy:</div>
          <ng-select
            class="custom"
            [items]="globals.privacyLevels"
            bindLabel="name"
            placeholder="{{'Privacy level' | translate}}"
            [(ngModel)]="selectedAccessType"
            [clearSearchOnAdd]="true"
            [clearable]="true"
            [searchable]="false"
            (change)="changeUserPrivacy()"
          >
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</div>
</ion-content>
