import {NoteFilter} from "@app/modules/note-filters/note-filter";

export class DateFilter extends NoteFilter {
  dateTo: Date;
  dateFrom: Date;

  constructor(dateTo: Date, dateFrom: Date) {
    super();
    this.dateTo = dateTo;
    this.dateFrom = dateFrom;
  }

  isEmpty() {
    return this.dateTo === null && this.dateFrom === null;
  }

  toString() {
    return "DateFilter. DateFrom = " + this.dateFrom + "; DateTo = " + this.dateTo;
  }
}
