import {Component, OnInit} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {FacebookAuthService} from '@app/core/auth/facebook-auth.service';
import {GoogleAuthService} from '@app/core/auth/google-auth.service';
import {AppleAuthService} from "@app/core/auth/apple-auth.service";

@Component({
  selector: 'app-reset-send',
  templateUrl: './reset-send.component.html',
  styleUrls: ['./reset-send.component.css', './../authentication.component.css', '../forgot-password/forgot-password.component.css']
})
export class ResetSendComponent implements OnInit {

  readonly HEADERS = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(public facebookAuthService: FacebookAuthService,
              private appleAuthService: AppleAuthService,
              public googleAuthService: GoogleAuthService) {
  }

  ngOnInit() {
  }

  loginWithFb = () => this.facebookAuthService.accessWithFB("", false);
  loginWithGoogle = () => this.googleAuthService.accessWithGoogle("", false);
  loginWithAppleID = () => this.appleAuthService.accessWithAppleID("", false);


}
