import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../globals';
import * as WebStomp from 'webstomp-client';
import * as SockJS from 'sockjs-client';
import {BehaviorSubject, Observable} from 'rxjs';
import {Message} from '../../modules/chat/chat-messages/message';
import {Tokens} from '@app/shared/models/tokens';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  webSocketEndPoint: string = this.globals.apiUrl + '/wss';
  topic: string = '/topic/chat';
  stompClient;
  public messageList: Array<Message>;
  public answer = new BehaviorSubject<Array<Message>>(null);
  receivedMessage: Message;

  newMessageReceived: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(public http: HttpClient, public globals: Globals) {
  }

  _connect() {
    const tokens = new Tokens(JSON.parse(localStorage.getItem('tokens')));
    const ws = new SockJS(this.webSocketEndPoint);
    this.stompClient = WebStomp.over(ws);
    this.stompClient.debug = () => {
    };
    this.stompClient.connect({Authorization: tokens.jwtToken}, frame => {
      this.stompClient.subscribe(this.topic + '/' + this.globals.currentChat.id , sdkEvent => {
        this.onMessageReceived(sdkEvent);
      });
    }, this.errorCallBack);
  }

  _disconnect() {
    if (this.stompClient !== null && this.stompClient !== undefined && this.stompClient.connected) {
      this.stompClient.disconnect();
    }
  }

  errorCallBack(error) {
    console.log('errorCallBack -> ' + error);
    setTimeout(() => {
      this._connect();
    }, 5000);
  }

  sendMsg(message) {
    this.stompClient.send('/app/message/messaging/' + this.globals.currentChat.id + '/' + this.globals.owner.id, JSON.stringify(message));
  }

  readMessages() {
    if (this.stompClient !== undefined) {
      this.stompClient.send('/app/message/list/' + this.globals.currentChat.id + '/' + this.globals.owner.id);
    }
  }

  onMessageReceived(message) {
    this.receivedMessage = JSON.parse(message.body);
    this.globals.messageList = this.globals.messageList.concat(this.receivedMessage);
    this.messageList = this.globals.messageList;

    if (this.globals.owner.id === this.receivedMessage.sender.id) {
      this.newMessageReceived.next(null);
    }
    this.getAnswer();
  }

  getAnswer(): Observable<Array<Message>> {
    this.answer.next(this.messageList);
    return this.answer.asObservable();
  }
}
