import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Globals } from "@app/shared/globals";
import { Router } from "@angular/router";
import { Label } from "@app/modules/label/label";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
import * as i3 from "@angular/router";
export class LabelService {
    constructor(http, globals, router) {
        this.http = http;
        this.globals = globals;
        this.router = router;
        this.labelSource = new BehaviorSubject(new Label());
        this.selectedLabel = this.labelSource.asObservable();
    }
    changeSelectedLabel(label) {
        this.labelSource.next(label);
    }
    createLabel(label) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.post(this.globals.apiUrl + '/label', label, { params });
    }
    updateLabel(label) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.put(this.globals.apiUrl + '/label', label, { params });
    }
}
LabelService.ɵfac = function LabelService_Factory(t) { return new (t || LabelService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.Router)); };
LabelService.ɵprov = i0.ɵɵdefineInjectable({ token: LabelService, factory: LabelService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LabelService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.Router }]; }, null); })();
