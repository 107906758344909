import { Component } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ToolbarService } from "@app/shared/services/toolbar/toolbar.service";
import { Globals } from "@app/shared/globals";
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/services/toolbar/toolbar.service";
import * as i3 from "@app/shared/globals";
import * as i4 from "@angular/router";
import * as i5 from "@ionic/angular";
import * as i6 from "@angular/common";
function AdminPageComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "ion-button", 3);
    i0.ɵɵelementStart(2, "ion-col");
    i0.ɵɵelement(3, "ion-icon", 4);
    i0.ɵɵelementStart(4, "div", 5);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const p_r1 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("routerLink", p_r1.url);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", p_r1.icon);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(p_r1.title);
} }
export class AdminPageComponent {
    constructor(http, toolbarService, globals, router) {
        this.http = http;
        this.toolbarService = toolbarService;
        this.globals = globals;
        this.router = router;
        this.panels = [
            {
                title: 'Users',
                icon: 'assets/icons/design_v4/person.svg',
                url: 'users'
            },
            {
                title: 'Templates',
                icon: 'assets/icons/design_v4/notes.svg',
                url: 'templates'
            },
            {
                title: 'Categories',
                icon: 'assets/icons/design_v4/category-path-white.svg',
                url: 'categories/127'
            },
            {
                title: 'Voter',
                icon: 'assets/icons/design_v4/stats.svg',
                url: 'voter'
            }
        ];
        this.headerActions = [
            {
                title: 'Home',
                path: '/',
                icon: 'assets/icons/design_v4/home_white.svg',
            },
            {
                title: 'Notifications',
                icon: 'assets/icons/design_v4/notifications-sharp.svg',
                path: '/notifications',
            },
        ];
    }
    ngOnInit() {
    }
    updateNavigation() {
        this.toolbarService.setMenuItems(this.headerActions);
    }
    canActivate() {
        let isActive = true;
        if (!this.globals.isAdmin) {
            this.router.navigate(['/']);
            isActive = false;
        }
        return isActive;
    }
}
AdminPageComponent.ɵfac = function AdminPageComponent_Factory(t) { return new (t || AdminPageComponent)(i0.ɵɵdirectiveInject(i1.HttpClient), i0.ɵɵdirectiveInject(i2.ToolbarService), i0.ɵɵdirectiveInject(i3.Globals), i0.ɵɵdirectiveInject(i4.Router)); };
AdminPageComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AdminPageComponent, selectors: [["app-admin-page"]], decls: 4, vars: 1, consts: [[1, "container"], [1, "admin-main-panel"], [4, "ngFor", "ngForOf"], [1, "buttons-row-item", 3, "routerLink"], [1, "buttons-row-item-icon", 3, "src"], [1, "buttons-row-item-title"]], template: function AdminPageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-content");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "ion-grid", 1);
        i0.ɵɵtemplate(3, AdminPageComponent_ng_container_3_Template, 6, 3, "ng-container", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngForOf", ctx.panels);
    } }, directives: [i5.IonContent, i5.IonGrid, i6.NgForOf, i5.IonButton, i4.RouterLink, i5.RouterLinkDelegate, i5.IonCol, i5.IonIcon], styles: [".admin-main-panel[_ngcontent-%COMP%]{display:table}.buttons-row-item[_ngcontent-%COMP%]{width:20vh;height:20vh;margin:2vh}.buttons-row-item-icon[_ngcontent-%COMP%]{width:10vh;height:10vh;color:#fff}.buttons-row-item-title[_ngcontent-%COMP%]{color:#fff}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AdminPageComponent, [{
        type: Component,
        args: [{
                selector: 'app-admin-page',
                templateUrl: './admin-page.component.html',
                styleUrls: ['./admin-page.component.scss']
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.ToolbarService }, { type: i3.Globals }, { type: i4.Router }]; }, null); })();
