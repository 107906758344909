
<ion-list class="note-list" style="margin-bottom: 90px; min-height: 75vh">
  <div *ngIf="paginator && paginator.items.length > 0; else noteList">
    <app-note-list-item
      class="cursor-pointer"
      *ngFor="let note of paginator.items"
      [noteEntity]="note"
    ></app-note-list-item>
  </div>
  <ng-template #noteList>
    <div class="note-list__not-found">
      <p translate>...</p>
    </div>
  </ng-template>
</ion-list>

