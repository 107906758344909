var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '@app/shared/services/category.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Globals } from '@app/shared/globals';
import { UiAlertService } from '@app/shared/services/ui-alert-service';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from '@app/shared/services/subscription.service';
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/services/category.service";
import * as i2 from "@angular/router";
import * as i3 from "@app/shared/globals";
import * as i4 from "@app/shared/services/ui-alert-service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@app/shared/services/subscription.service";
export class NewNoteDataResolve {
    constructor(categoryService, router, globals, uiAlertService, translationService, subscriptionService) {
        this.categoryService = categoryService;
        this.router = router;
        this.globals = globals;
        this.uiAlertService = uiAlertService;
        this.translationService = translationService;
        this.subscriptionService = subscriptionService;
        this.updateTranslations();
        this.translationService.onLangChange.subscribe((event) => {
            this.updateTranslations();
        });
    }
    resolve(route) {
        const id = route.params.id;
        return this.categoryService.getCategoryById(id).pipe(catchError((error) => __awaiter(this, void 0, void 0, function* () {
            if (error instanceof HttpErrorResponse && error.status !== 401) {
                yield this.uiAlertService.createAlert(this.translations['Error'], this.translations[`You can't  create new note in this category!`]);
                this.globals.action = null;
                yield this.router.navigate(['/']);
                return throwError(error);
            }
        })));
    }
    updateTranslations() {
        const translationsSub = this.translationService.get([
            'Error',
            `You can't  create new note in this category!`
        ]).subscribe(tr => {
            this.translations = tr;
        });
        this.subscriptionService.unSubscriber(translationsSub);
    }
}
NewNoteDataResolve.ɵfac = function NewNoteDataResolve_Factory(t) { return new (t || NewNoteDataResolve)(i0.ɵɵinject(i1.CategoryService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Globals), i0.ɵɵinject(i4.UiAlertService), i0.ɵɵinject(i5.TranslateService), i0.ɵɵinject(i6.SubscriptionService)); };
NewNoteDataResolve.ɵprov = i0.ɵɵdefineInjectable({ token: NewNoteDataResolve, factory: NewNoteDataResolve.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NewNoteDataResolve, [{
        type: Injectable
    }], function () { return [{ type: i1.CategoryService }, { type: i2.Router }, { type: i3.Globals }, { type: i4.UiAlertService }, { type: i5.TranslateService }, { type: i6.SubscriptionService }]; }, null); })();
