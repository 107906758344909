import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../globals';
import {Note} from '../../modules/note/note';
import {NoteService} from './note.service';
import {NoteComment} from '../../modules/note-comment/note-comment';

@Injectable({
  providedIn: 'root'
})
export class NoteCommentService {

  constructor(public http: HttpClient, public globals: Globals, public noteService: NoteService) {
  }

  refreshCommentList() {
    this.noteService.refreshNote();
  }

  addComment(newComment: NoteComment, callback) {
    this.http.post(this.globals.apiUrl + '/notes/comments/', newComment).subscribe(() => {
      return callback && callback();
    });
  }

  deleteComment(currentComment: NoteComment, callback) {
    this.http.delete(this.globals.apiUrl + '/notes/comments/' + currentComment.id).subscribe(() => {
      return callback && callback();
    });
  }

  editComment(currentComment: NoteComment, callback) {
    this.http.put(this.globals.apiUrl + '/notes/comments/', currentComment).subscribe(() => {
      return callback && callback();
    });
  }

  getCommentCounts(note: Note) {
    let count = 0;
    count = note.comments.length;
    let comment: NoteComment;
    for (comment of note.comments) {
      count = count + comment.childComments.length;
    }
    return count;
  }
}
