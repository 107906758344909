import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";

@Component({
  selector: 'app-auth-header',
  template: `
    <ion-toolbar [ngStyle]="{'padding-top': toolbarService.isNewIphone ? '30px' : '10px'}" class="auth-header" color="primary">
      <ion-buttons slot="start" style="margin-left: 15px">
        <img (click)="backBtnHandler()" class="auth-header__back-btn" [src]="'assets/icons/design_v4/go-left.svg'">
     </ion-buttons>
      <ion-title class="text-center" translate> {{title}}</ion-title>

      <ion-buttons slot="end" *ngIf="endBtns">
        <ion-button shape="round"  *ngFor="let btn of endBtns"
                    (click)="btn.callback($event)">
          <img
            *ngIf="btn.icon"
            [src]="'assets/icons/design_v4/' + btn.icon"
            [alt]="btn.name">
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  `,
  styles: [`
   .auth-header{
      color: white;
       max-width: 540px;
       margin: 0 auto;
    }
    .auth-header__back-btn{
      font-size: 32px;
    }
  `]
})
export class AuthHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() endBtns: any[];
  constructor(
    private router: Router,
    public toolbarService: ToolbarService
  ) {

  }

  backBtnHandler() {
    this.router.navigate(['auth']);
  }

  ngOnInit() {
  }

}
