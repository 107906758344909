var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../globals';
import { Languages } from '../../core/languages';
import { Category } from '@app/modules/category/category';
import { ADD_NEW_NOT_ACTION, EDIT_NOTE_ACTION } from '@app/shared/actions';
import { CategoryFilter } from '@app/modules/note-filters/filters/category-filter';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
import * as i3 from "@angular/router";
import * as i4 from "@ionic/angular";
export class CategoryService {
    constructor(http, globals, router, modalController) {
        this.http = http;
        this.globals = globals;
        this.router = router;
        this.modalController = modalController;
    }
    getAllCategories(userId, categoryId) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/categories/' + categoryId + '/users/' + userId, {
            params
        });
    }
    getRootCategories(userId) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/categories/flower/users/' + userId, {
            params
        });
    }
    getChildren(catId, userId) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/categories/' + catId + '/children/users/' + userId, {
            params
        });
    }
    uploadCategoryFlower(userId, callback) {
        this.getRootCategories(userId).subscribe((response) => {
            this.setGlobalsAllCategories(response);
            return callback && callback();
        });
    }
    insertNewCat(old, newCat) {
        console.log("old " + old.length);
        let counter = 0;
        for (const n of newCat) {
            for (const o of old) {
            }
        }
        for (const n of newCat) {
            for (const o of old) {
                if (n.id === o.id) {
                    counter = 0;
                    break;
                }
                counter++;
            }
            if (counter > 0) {
                old.push(n);
                counter = 0;
            }
        }
        console.log("new " + old.length);
        return old;
    }
    setGlobalsAllCategories(categories) {
        this.globals.allCategories = null;
        this.globals.allCategories = this.assignCategoriesParents(categories);
    }
    getUserRecentCategories() {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/categories/recent-categories', {
            params
        }).subscribe((recentCategoriesResponse) => {
            this.globals.recentCategories = [];
            for (const categories of recentCategoriesResponse) {
                const category = this.getParentForCategory(categories, this.globals.allCategories);
                this.globals.recentCategories.push(category);
            }
        });
    }
    getCategoryById(categoryId) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/categories/' + categoryId, {
            params
        });
    }
    deleteCategory(id, callback, error) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.delete(this.globals.apiUrl + '/categories/' + id, {
            params
        }).subscribe(response => {
            return callback && callback(response);
        }, e => {
            return error && error(e);
        });
    }
    updateCategory(category, callback) {
        this.http.put(this.globals.apiUrl + '/categories', this.toJson(category)).subscribe(() => {
            return callback && callback();
        });
    }
    addCategory(newCat, callback, error) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        this.http.post(this.globals.apiUrl + '/categories', this.toJson(newCat), {
            params
        }).subscribe(response => {
            return callback && callback(response);
        }, e => {
            return error && error(e);
        });
    }
    getTitleForTranslation(titleLang, newCategoryTitle, i) {
        switch (titleLang) {
            case Languages.AR:
                i.ar = newCategoryTitle;
                break;
            case Languages.BN:
                i.bn = newCategoryTitle;
                break;
            case Languages.DE:
                i.de = newCategoryTitle;
                break;
            case Languages.EN:
                i.en = newCategoryTitle;
                break;
            case Languages.ES:
                i.es = newCategoryTitle;
                break;
            case Languages.FR:
                i.fr = newCategoryTitle;
                break;
            case Languages.HI:
                i.hi = newCategoryTitle;
                break;
            case Languages.ID:
                i.id = newCategoryTitle;
                break;
            case Languages.IT:
                i.it = newCategoryTitle;
                break;
            case Languages.JA:
                i.ja = newCategoryTitle;
                break;
            case Languages.PT:
                i.pt = newCategoryTitle;
                break;
            case Languages.RU:
                i.ru = newCategoryTitle;
                break;
            case Languages.ZH:
                i.zh = newCategoryTitle;
                break;
        }
        return i;
    }
    getTranslationForTitle(category) {
        return this.translationSwitcher(this.globals.currentLanguage, category);
    }
    getTranslationForTitleByLang(category) {
        if (!category) {
            return '';
        }
        return this.translationSwitcher(category.nativeLanguage, category);
    }
    translationSwitcher(lang, category) {
        let i = '';
        if (category && category.translation != null) {
            switch (lang) {
                case Languages.AR:
                    i = category.translation.ar;
                    break;
                case Languages.BN:
                    i = category.translation.bn;
                    break;
                case Languages.DE:
                    i = category.translation.de;
                    break;
                case Languages.EN:
                    i = category.translation.en;
                    break;
                case Languages.ES:
                    i = category.translation.es;
                    break;
                case Languages.FR:
                    i = category.translation.fr;
                    break;
                case Languages.HI:
                    i = category.translation.hi;
                    break;
                case Languages.ID:
                    i = category.translation.id;
                    break;
                case Languages.IT:
                    i = category.translation.it;
                    break;
                case Languages.JA:
                    i = category.translation.ja;
                    break;
                case Languages.PT:
                    i = category.translation.pt;
                    break;
                case Languages.RU:
                    i = category.translation.ru;
                    break;
                case Languages.ZH:
                    i = category.translation.zh;
                    break;
            }
        }
        return i;
    }
    moveCategory(categoryToMoveId, newParentCategoryId, callback, error) {
        const params = new HttpParams().append('newParentId', newParentCategoryId.toString());
        this.http.patch(this.globals.apiUrl + '/categories/' + categoryToMoveId, null, {
            params
        }).subscribe(() => {
            return callback && callback();
        }, e => {
            return error && error(e);
        });
    }
    uploadCategoryTree(userId, categoryId, callback) {
        this.getAllCategories(userId, categoryId).subscribe((response) => {
            this.setGlobalsAllCategories(response);
            return callback && callback();
        });
    }
    assignCategoriesParents(categories) {
        const catList = new Array();
        for (const category of categories) {
            const parId = category.parentCategoryId;
            let tmp = categories.filter((item) => {
                return parId === item.id;
            })[0];
            if (tmp === undefined) {
                tmp = new Category();
            }
            category.parentCategory = tmp;
            catList.push(category);
        }
        return catList;
    }
    getParentForCategory(category, existingCategories) {
        const parId = category.parentCategoryId;
        let tmp = existingCategories.filter((item) => {
            return parId === item.id;
        })[0];
        if (tmp === undefined) {
            tmp = new Category();
        }
        category.parentCategory = tmp;
        return category;
    }
    findCategoryChildesByParentId(parentId) {
        if (this.globals.allCategories && this.globals.allCategories.length > 0) {
            return this.globals.allCategories.filter((item) => {
                return item.parentCategory.id === parentId && item.id !== 100;
            });
        }
        return [];
    }
    findCategoryById(id) {
        if (this.globals.allCategories && this.globals.allCategories.length > 0) {
            return this.globals.allCategories.find(item => item.id === id);
        }
        return null;
    }
    initializeFlowerCategories() {
        this.setTopCategories(this.globals.allCategories);
    }
    setTopCategories(categories) {
        const topCategories = [];
        if (!categories) {
            return;
        }
        this.globals.topCategoryList = [];
        for (const category of categories) {
            if (category.parentCategory.id === 100) {
                topCategories.push(category);
            }
        }
        const health = topCategories.filter((item) => {
            return item.id === 125;
        });
        this.globals.topCategoryList.push(health[0]);
        const spirLife = topCategories.filter((item) => {
            return item.id === 126;
        });
        this.globals.topCategoryList.push(spirLife[0]);
        const home = topCategories.filter((item) => {
            return item.id === 102;
        });
        this.globals.topCategoryList.push(home[0]);
        const myPersonality = topCategories.filter((item) => {
            return item.id === 127;
        });
        this.globals.topCategoryList.push(myPersonality[0]);
        const socLife = topCategories.filter((item) => {
            return item.id === 104;
        });
        this.globals.topCategoryList.push(socLife[0]);
        const work = topCategories.filter((item) => {
            return item.id === 101;
        });
        this.globals.topCategoryList.push(work[0]);
        const edu = topCategories.filter((item) => {
            return item.id === 103;
        });
        this.globals.topCategoryList.push(edu[0]);
    }
    getParentPath(category) {
        const parents = [];
        for (const parentTitle of category.parentTitles) {
            parents.push(parentTitle.id);
        }
        return parents;
    }
    findCategories(title) {
        const params = new HttpParams().append('title', title).append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/categories/search', { params });
    }
    getTranslationForTitleById(id) {
        const params = new HttpParams().append('titlesId', id);
        return this.http.get(this.globals.apiUrl + '/categories/translation', { params });
    }
    getCurrentTitleTranslate(res) {
        let i;
        switch (this.globals.currentLanguage) {
            case Languages.AR:
                i = res.ar;
                break;
            case Languages.BN:
                i = res.bn;
                break;
            case Languages.DE:
                i = res.de;
                break;
            case Languages.EN:
                i = res.en;
                break;
            case Languages.ES:
                i = res.es;
                break;
            case Languages.FR:
                i = res.fr;
                break;
            case Languages.HI:
                i = res.hi;
                break;
            case Languages.ID:
                i = res.id;
                break;
            case Languages.IT:
                i = res.it;
                break;
            case Languages.JA:
                i = res.ja;
                break;
            case Languages.PT:
                i = res.pt;
                break;
            case Languages.RU:
                i = res.ru;
                break;
            case Languages.ZH:
                i = res.zh;
                break;
        }
        return i;
    }
    toJson(category) {
        const { parentCategory, parentTitles, creationDate, numberOfNotes, children } = category, mainProps = __rest(category, ["parentCategory", "parentTitles", "creationDate", "numberOfNotes", "children"]);
        return mainProps;
    }
    filterByCategory(categoryId) {
        this.globals.currentCategoryId = categoryId;
        if (this.globals.action === ADD_NEW_NOT_ACTION) {
            this.globals.action = null;
            this.router.navigateByUrl(`/categories/${categoryId}/new-note`).then(e => console.log(e));
            return;
        }
        if (this.globals.action === EDIT_NOTE_ACTION) {
            this.modalController.dismiss({
                currentCategory: categoryId,
            }).then(r => {
                this.globals.action = null;
            });
            return;
        }
        this.globals.activeFilters.addFilter(new CategoryFilter(categoryId));
        this.globals.needPageRefresh = true;
        if (this.globals.owner.id === this.globals.selectedUser.id) {
            this.router.navigateByUrl('/', {
                queryParams: {
                    feed: true
                }
            }).then(e => console.log(e));
        }
        else {
            this.router.navigateByUrl('/users/' + this.globals.selectedUser.id + '/notes').then(e => console.log(e));
        }
    }
}
CategoryService.ɵfac = function CategoryService_Factory(t) { return new (t || CategoryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.ModalController)); };
CategoryService.ɵprov = i0.ɵɵdefineInjectable({ token: CategoryService, factory: CategoryService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CategoryService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.Router }, { type: i4.ModalController }]; }, null); })();
