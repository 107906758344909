export class PasswordSetting {
  constructor(
    public newPassword: boolean = true,
    public input: HTMLElement
  ) {}
  public passwordHidden : boolean = true;
  changePasswordDisplay() {
    this.passwordHidden = !this.passwordHidden;
  }
}
