import {AfterContentInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PasswordSetting} from './password-setting';
import {Globals} from '@app/shared/globals';
import {SiteUserService} from '@app/shared/services/site-user.service';
import {SiteUserInfo} from '../site-user/site-user-info';
import {ProfileService} from '@app/shared/services/profile.service';
import {PrivacyLevel} from '../site-user/privacy-level';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {matchOtherValidator, noCyrillic} from '@app/shared/const/customFormValidators';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {UiAlertService} from '@app/shared/services/ui-alert-service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: [
    './settings.component.css', '../profile/profile.component.css',
    '../../../assets/fonts/passbullets/passbullets.css'
  ]
})
export class SettingsComponent implements OnInit, AfterContentInit, UpdateNavigation {
  @ViewChild('oldPasswordRef', {static: true}) oldPasswordRef: ElementRef;
  @ViewChild('newPasswordRef', {static: true}) newPasswordRef: ElementRef;
  @ViewChild('confirmPasswordRef', {static: true}) confirmPasswordRef: ElementRef;

  public selectedAccessType: PrivacyLevel;
  public oldPassword;
  public newPassword;
  public confirmPassword;
  public resetPassData = {oldPassword: '', password: '', confirmPassword: ''};
  public changePassForm: FormGroup;

  menuButtonsUnreadMessages: MenuItem[] = [
    {
      title: 'Notifications',
      icon: 'assets/icons/design_v4/notifications-sharp-unread.svg',
      path: '/notifications',
    },
  ];

  menuButtonsWithoutNotification: MenuItem[] = [
    {
      title: 'Notifications',
      icon: 'assets/icons/design_v4/notifications-sharp.svg',
      path: '/notifications',
    }
  ];

  translationText: any;

  constructor(
    public globals: Globals,
    public siteUserService: SiteUserService,
    public profileService: ProfileService,
    public formBuilder: FormBuilder,
    private toolbarService: ToolbarService,
    private loadingController: LoadingController,
    private uiAlertService: UiAlertService,
    private translate: TranslateService
  ) {
    this.changePassForm = this.formBuilder.group({
      oldPassword: [null, [Validators.required]],
      password: [null, [
        Validators.required,
        noCyrillic,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],
      confirmPassword: [null, [
        Validators.required,
        matchOtherValidator('password')
      ]],
    });
  }

  ngOnInit() {
    this.translate.get([
      'Success',
      'Password has been successfully changed!',
      'Error',
      'Your password was not changed. Invalid current password.',
      'Updating'
    ]).subscribe(tr => {
      this.translationText = tr;
      console.log(tr);
    });

    this.profileService.getUserInfo(this.globals.owner).subscribe((data: SiteUserInfo) => {
      this.globals.selectedUserInfo = data;
      this.selectedAccessType = this.globals.selectedUserInfo.privacyLevel;
    });
    this.siteUserService.getALLPrivacyLevels().subscribe((data: PrivacyLevel[]) => {
      this.globals.privacyLevels = data;
    });
  }

  ngAfterContentInit() {
    this.oldPassword = new PasswordSetting(
      false,
      this.oldPasswordRef.nativeElement
    );
    this.newPassword = new PasswordSetting(
      false,
      this.newPasswordRef.nativeElement
    );
    this.confirmPassword = new PasswordSetting(
      false,
      this.confirmPasswordRef.nativeElement
    );
  }

  updateNavigation(): void {
    if (this.globals.unreadNotificationCount > 0) {
      this.toolbarService.setMenuItems(this.menuButtonsUnreadMessages);
    } else {
      this.toolbarService.setMenuItems(this.menuButtonsWithoutNotification);
    }
  }

  async saveCurrentPassword() {
    this.resetPassData = this.changePassForm.value;
    const oldInputPassword = this.resetPassData.oldPassword;
    const newInputPassword = this.resetPassData.password;

    const loading = await this.loadingController.create({
      message: this.translationText['Updating'],
    });
    await loading.present();


    const alert = async (title, subTitle) => {
      await this.uiAlertService.createAlert(title, subTitle);
    };

    const success = async () => {
      await loading.dismiss();
      this.changePassForm.reset();
      await this.uiAlertService.createAlert(this.translationText['Success'], this.translationText['Password has been successfully changed!']);
    };

    const error = async () => {
      await loading.dismiss();
      this.changePassForm.reset();
      await this.uiAlertService.createAlert(this.translationText['Error'], this.translationText['Your password was not changed. Invalid current password.']);
    };


    this.siteUserService.changeUserPassword(oldInputPassword, newInputPassword, success, error);

  }

  changeUserPrivacy() {
    this.globals.selectedUserInfo.privacyLevel = this.selectedAccessType;
    this.profileService.editUserProfileInfo(this.globals.selectedUserInfo, () => {
    });
  }

}
