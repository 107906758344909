import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {CategoryService} from '@app/shared/services/category.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {Globals} from '@app/shared/globals';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {TranslateService} from '@ngx-translate/core';
import {SubscriptionService} from '@app/shared/services/subscription.service';

@Injectable()
export class NewNoteDataResolve implements Resolve<any> {

  private translations: string[];

  constructor(private categoryService: CategoryService,
              private router: Router,
              public globals: Globals,
              private uiAlertService: UiAlertService,
              private translationService: TranslateService,
              private subscriptionService: SubscriptionService) {
    this.updateTranslations();
    this.translationService.onLangChange.subscribe((event) => {
      this.updateTranslations();
    });
  }

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params.id;
    return this.categoryService.getCategoryById(id).pipe(catchError(async (error) => {
      if (error instanceof HttpErrorResponse && error.status !== 401) {
        await this.uiAlertService.createAlert(this.translations['Error'], this.translations[`You can't  create new note in this category!`]);
        this.globals.action = null;
        await this.router.navigate(['/']);
        return throwError(error);
      }
    }));
  }

  private updateTranslations() {
    const translationsSub = this.translationService.get([
      'Error',
      `You can't  create new note in this category!`
    ]).subscribe(tr => {
      this.translations = tr;
    });
    this.subscriptionService.unSubscriber(translationsSub);
  }
}
