import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoadingController, PopoverController} from '@ionic/angular';
import {LangPickerDropDownComponent} from '@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component';
import {LanguagesList} from '@app/core/languages';
import {Globals} from '@app/shared/globals';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {SupportService} from '@app/shared/services/support.service';
import {UiAlertService} from '@app/shared/services/ui-alert-service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css', '../auth/authentication.component.css']
})
export class SupportComponent implements OnInit {

  supportForm: FormGroup;
  validationData = {
    email: null
  };
  loading: any;
  errorMsg = '';
  isCheckingEmail = false;
  endActions;
  languageList = LanguagesList;
  popover;

  constructor(
    private formBuilder: FormBuilder,
    public globals: Globals,
    private toolbarService: ToolbarService,
    private popoverController: PopoverController,
    public supportService: SupportService,
    private uiAlertService: UiAlertService,
    private loadingController: LoadingController
  ) {
    this.toolbarService.setHeaderVisibility(false);
    this.supportForm = this.formBuilder.group({
      name: [null, null],
      email: [null, [Validators.required, Validators.email]],
      theme: [null, null],
      issue: [null, null]
    });
  }

  ngOnInit() {
    const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;

    this.endActions = [
      {
        icon: `langs/${lang.toLocaleLowerCase()}.svg`,
        name: lang,
        callback: env => this.presentLanguageMenu(env)
      }
    ];
  }

  async presentLanguageMenu(env) {

    this.popover = await this.popoverController.create({
      component: LangPickerDropDownComponent,
      event: env,
      translucent: false,
      showBackdrop: false,
    });

    await this.popover.present();

    this.popover.onDidDismiss().then(
      e => {
        if (e.data) {
          this.endActions[0].icon = `langs/${e.data.name.toLocaleLowerCase()}.svg`;
          this.endActions[0].name = e.data.name;
        }
      }
    );
  }

  async sendForm() {
    this.loading = await this.loadingController.create({
      message: 'Sending...',
    });
    await this.loading.present();
    this.supportService.sendSupportRequest(this.supportForm.value.name, this.supportForm.value.email,
      this.supportForm.value.theme, this.supportForm.value.issue, async () => {
        await this.uiAlertService.createAlertWithButton('Success!', `Your request was sent!`, 'ok', () => {
          this.supportForm.reset();
        });
      });
    await this.loading.dismiss();
  }
}
