<ion-app>
  <ng-container *ngIf="!isLoading">

    <ng-container>

      <bdc-walk-dialog #addNoteGuideFinish name="addNoteGuideFinish" width="400px"
                       [mustCompleted]="{
                       addNoteGuideTask7: true,
                       addNoteGuideTask8: true,
                       addNoteGuideTask9: true,
                       addNoteGuideTask10: true,
                       addNoteGuideTask11: true,
                       addNoteGuideTask12: true
                       }">
        <h1 translate>
          <mat-icon [inline]="true">flag</mat-icon>
          Finished!
        </h1>
        <h4 translate>
          You've successfully finished the tutorial.
        </h4><br>
        <div>
          <ion-button
            (click)="addNoteGuideFinish.close({addNoteGuideShowWalkthrough: false}); globals.isFirstTimeNote = false"
            expand="block" translate>Close
          </ion-button>
        </div>
      </bdc-walk-dialog>

      <bdc-walk-dialog #welcomeGuideFinish name="welcomeGuideFinish" width="400px"
                       [mustCompleted]="{
                       welcomeGuideTask1: true,
                       welcomeGuideTask2: true,
                       welcomeGuideTask3: true,
                       welcomeGuideTask4: true,
                       welcomeGuideTask5: true
                       }">
        <h1 translate>
          <mat-icon [inline]="true">flag</mat-icon>
          Hurray,
        </h1>
        <h4 translate>
          Now you can filter posts. To add a new one just click "Plus" below.
        </h4><br>
        <div>
          <ion-button
            (click)="welcomeGuideFinish.close({welcomeGuideShowWalkthrough: false}); globals.isNewUser = false; globals.isFirstTimeNote = true"
            expand="block" translate>Close
          </ion-button>
        </div>
      </bdc-walk-dialog>

      <!--bdc-walkthrough-->

      <app-menu></app-menu>
      <div id="content" class="content-wrapper">
        <div>
          <app-header-toolbar *ngIf="globals.isAuthenticated"
                              (walkThroughEvent)="startWalkthrough()"></app-header-toolbar>
        </div>
        <ion-router-outlet
          class="content"
          [swipeGesture]="false"
          [ngStyle]="{ 'margin-top' : isVisible ? toolbarService.isNewIphone ? '77px' : '54px' : '0px'}"
          (activate)="setRoutedComponent($event)"
        >
        </ion-router-outlet>
      </div>

    </ng-container>

  </ng-container>

  <ng-container *ngIf="isLoading">
    <ion-spinner class="main-loader" name="crescent"></ion-spinner>
  </ng-container>
</ion-app>
