import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { featureReducer } from "./reducer";
import { CategoryStoreEffects } from "./effects";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ngrx/effects";
export class CategoryStoreModule {
}
CategoryStoreModule.ɵmod = i0.ɵɵdefineNgModule({ type: CategoryStoreModule });
CategoryStoreModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CategoryStoreModule_Factory(t) { return new (t || CategoryStoreModule)(); }, providers: [CategoryStoreEffects], imports: [[
            CommonModule,
            StoreModule.forFeature("categoryStore", featureReducer),
            EffectsModule.forFeature([CategoryStoreEffects])
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CategoryStoreModule, { imports: [CommonModule, i1.StoreFeatureModule, i2.EffectsFeatureModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CategoryStoreModule, [{
        type: NgModule,
        args: [{
                declarations: [],
                imports: [
                    CommonModule,
                    StoreModule.forFeature("categoryStore", featureReducer),
                    EffectsModule.forFeature([CategoryStoreEffects])
                ],
                providers: [CategoryStoreEffects]
            }]
    }], null, null); })();
