import { NgModule } from "@angular/core";
import { AutofocusDirective } from "./autofocus.directive";
import * as i0 from "@angular/core";
export class AutofocusDirectiveModule {
}
AutofocusDirectiveModule.ɵmod = i0.ɵɵdefineNgModule({ type: AutofocusDirectiveModule });
AutofocusDirectiveModule.ɵinj = i0.ɵɵdefineInjector({ factory: function AutofocusDirectiveModule_Factory(t) { return new (t || AutofocusDirectiveModule)(); }, imports: [[]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AutofocusDirectiveModule, { declarations: [AutofocusDirective], exports: [AutofocusDirective] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AutofocusDirectiveModule, [{
        type: NgModule,
        args: [{
                imports: [],
                declarations: [
                    AutofocusDirective,
                ],
                exports: [
                    AutofocusDirective,
                ],
            }]
    }], null, null); })();
