import {Component} from '@angular/core';
import {Globals} from '../../shared/globals';
import {Router} from '@angular/router';
import {AuthenticationService} from '@app/core/auth/authentication.service';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {Events} from '@ionic/angular';
import {NewFeed} from "@app/modules/home/NewFeed";
import {FeedType} from "@app/modules/home/FeedType";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  public fullname = document.getElementById('fullname');

  pages = [
    {
      title: 'Home',
      icon: 'assets/icons/design_v4/home.svg',
      action: () => this.refreshHome()
    },
    {
      title: 'Messages',
      icon: 'assets/icons/design_v4/messages.svg',
      url: 'chat'
    },
    {
      title: 'Friends',
      icon: 'assets/icons/design_v4/friends.svg',
      url: 'friends'
    },
    {
      title: 'Settings',
      icon: 'assets/icons/design_v4/settings.png',
      url: 'settings'
    },
    {
      title: 'Support',
      icon: 'assets/icons/design_v4/work_and_education_inverted.svg',
      url: 'alerts'
    },
    {
      title: 'Log Out',
      icon: 'assets/icons/design_v4/logout.svg',
      action: () => this.logout()
    }
  ];

  pagesForEditor = [
    {
      title: 'Home',
      icon: 'assets/icons/design_v4/home.svg',
      action: () => this.refreshHome()
    },
    {
      title: 'Messages',
      icon: 'assets/icons/design_v4/messages.svg',
      url: 'chat'
    },
    {
      title: 'Friends',
      icon: 'assets/icons/design_v4/friends.svg',
      url: 'friends',
    },
    {
      title: 'Settings',
      icon: 'assets/icons/design_v4/settings.png',
      url: 'settings',
    },
    {
      title: 'Support',
      icon: 'assets/icons/design_v4/work_and_education_inverted.svg',
      url: 'alerts',
    },
    {
      title: 'Editors',
      icon: 'assets/icons/design_v4/icon-editors.svg',
      url: 'editors',

    },
    {
      title: 'Log Out',
      icon: 'assets/icons/design_v4/logout.svg',
      action: () => this.logout()
    }
  ];

  pagesForAdmin = [
    {
      title: 'Home',
      icon: 'assets/icons/design_v4/home.svg',
      action: () => this.refreshHome()
    },
    {
      title: 'Messages',
      icon: 'assets/icons/design_v4/messages.svg',
      url: 'chat'
    },
    {
      title: 'Friends',
      icon: 'assets/icons/design_v4/friends.svg',
      url: 'friends'
    },
    {
      title: 'Settings',
      icon: 'assets/icons/design_v4/settings.png',
      url: 'settings'
    },
    {
      title: 'Support',
      icon: 'assets/icons/design_v4/work_and_education_inverted.svg',
      url: 'alerts'
    },
    {
      title: 'Editors',
      icon: 'assets/icons/design_v4/icon-editors.svg',
      url: 'editors'
    },
    {
      title: 'Admin',
      icon: 'assets/icons/design_v4/settings.png',
      url: 'admin'
    },
    {
      title: 'Log Out',
      icon: 'assets/icons/design_v4/logout.svg',
      action: () => this.logout()
    }
  ];

  constructor(public globals: Globals,
              public router: Router,
              public authService: AuthenticationService,
              private toolbarService: ToolbarService,
              private menuService: MenuService,
              private events: Events) {
  }

  updateProfileData() {
    this.fullname.innerText = `${this.globals.owner.firstName} ${this.globals.owner.lastName}`;
  }

  refreshHome() {
    this.globals.selectedUser = this.globals.owner;
    this.globals.needPageRefresh = true;
    this.globals.activeFilters.removeFilter(this.globals.activeFilters.categoryFilter);
    this.router.navigate(['/']);
    this.globals.feed = new NewFeed(FeedType.WORLD);
    this.events.publish('refresh:home', true);
    this.menuService.toggleMenu();
  }

  logout() {
    this.authService.logout();
  }
}
