<div *ngIf="loading">
  <div id="loader" class="loader"></div>
</div>
<input type="file" (change)="processFile($event)" style="display: none" id="fileInputField" name="fileInputField"/>
<input type="file" style="display: none" id="videoInputField" accept="video/*"/>
<div class="editor-window">
  <quill-editor
    class="quill-editor"
    [placeholder]="'Type your post...' | translate"
    (onEditorCreated)="getEditorInstance($event)"
    [(ngModel)]="globals.newNoteContent"
    [modules]="customModules"
  >
  </quill-editor>
</div>
<div id="toolbar" class="quill-toolbar" >
  <div class="quill-toolbar__grid">
    <button type="submit" style="height: 40px;" class="backBtn" (click)="prev()"><i
      class="slider-arrow slider-left-arrow"></i></button>
    <button type="submit" style="height: 40px;" class="nextBtn" (click)="next()"><i
      class="slider-arrow slider-right-arrow"></i></button>
    <ion-slides class="slides-row" tappable [options]="rootSliderOptions" #slides>
      <ion-slide class="slider">
        <ion-item-group class="quill-toolbar__grid-row slider-items-row">
          <button type="button" (click)="videoHandler()"><i class="quill-custom-button video-file-button" [bdcWalkTriggerFor]="addNoteGuideTask8"></i></button>
          <button type="button" (click)="selectImage()"><i class="quill-custom-button image-file-button"></i></button>
          <button type="button" class="emoji-pick-button" (click)="toggleEmojiPicker()">
            <img style="padding: 5px" src="../../../assets/icons/design_v4/emoji.svg" alt="">
          </button>
          <select title="Size" class="ql-size">
            <option value="10px" translate="Small"></option>
            <option value="18px" selected translate="Normal"></option>
            <option value="32px" translate="Large"></option>
          </select>
        </ion-item-group>
      </ion-slide>
      <ion-slide>
        <ion-item-group class="quill-toolbar__grid-row slider-items-row">
          <button type="button" class="ql-bold"></button>
          <button type="button" class="ql-italic"></button>
          <select class="ql-color"></select>
          <button type="button" class="ql-underline"></button>
          <button type="button" class="ql-strike"></button>
        </ion-item-group>
      </ion-slide>
      <ion-slide>
        <ion-item-group class="quill-toolbar__grid-row slider-items-row">
          <button type="button" class="ql-indent" value="-1"></button>
          <select class="ql-align">
            <option value=""></option>
            <option value="center"></option>
            <option value="right"></option>
          </select>
          <button type="button" class="ql-indent" value="+1"></button>
        </ion-item-group>
      </ion-slide>
    </ion-slides>
  </div>
  <emoji-content class="toolbar-emoji-picker" *ngIf="showEmojiPicker" (emoji-selection)="emojiHandler($event)">
  </emoji-content>
  <bdc-walk-popup class="wide-popup" #addNoteGuideTask8 name="addNoteGuideTask8" xPosition="after" yPosition="above"
                  [mustCompleted]="{addNoteGuideShowWalkthrough: true, addNoteGuideTask7: true}"
                  [showCloseButton]="true" [showButton]="true" [buttonText]="'Ok' | translate"
                  [closeOnClick]="true">
    <div translate>
      You can type a text note above<br><br translate>
      add 3 min video, YouTube video link, or image below<br>
    </div>
  </bdc-walk-popup>
</div>
