var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { AuthService, AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../shared/globals';
import { SiteUser } from '../../modules/site-user/site-user';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Facebook } from '@ionic-native/facebook/ngx';
import { LoadingController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { MenuService } from '@app/shared/services/menu/menu.service';
import { TokensService } from '@app/shared/services/tokens.service';
import { ProfileService } from '@app/shared/services/profile.service';
import { UiAlertService } from '@app/shared/services/ui-alert-service';
import { OneSignalService } from '@app/shared/services/one-signal.service';
import * as i0 from "@angular/core";
import * as i1 from "angularx-social-login";
import * as i2 from "@angular/common/http";
import * as i3 from "../../shared/globals";
import * as i4 from "@angular/router";
import * as i5 from "@ionic/storage";
import * as i6 from "@ionic/angular";
import * as i7 from "@ionic-native/facebook/ngx";
import * as i8 from "@app/shared/services/menu/menu.service";
import * as i9 from "@app/shared/services/toolbar/toolbar.service";
import * as i10 from "./authentication.service";
import * as i11 from "@app/shared/services/tokens.service";
import * as i12 from "@app/shared/services/ui-alert-service";
import * as i13 from "@app/shared/services/profile.service";
import * as i14 from "@app/shared/services/one-signal.service";
export class FacebookAuthService extends AuthService {
    constructor(config, http, globals, router, storage, platform, fb, menuService, toolbarService, authenticationService, tokensService, uiAlertService, profileService, oneSignalService, loadingController) {
        super(config);
        this.http = http;
        this.globals = globals;
        this.router = router;
        this.storage = storage;
        this.platform = platform;
        this.fb = fb;
        this.menuService = menuService;
        this.toolbarService = toolbarService;
        this.authenticationService = authenticationService;
        this.tokensService = tokensService;
        this.uiAlertService = uiAlertService;
        this.profileService = profileService;
        this.oneSignalService = oneSignalService;
        this.loadingController = loadingController;
    }
    authByFacebook(user, needRegister) {
        return __awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingController.create({});
            yield loading.present();
            const siteUser = new SiteUser();
            siteUser.firstName = user.firstName;
            siteUser.lastName = user.lastName;
            siteUser.email = user.email;
            siteUser.ssoId = user.id;
            siteUser.userPhotoName = user.photoUrl;
            this.globals.owner = siteUser;
            let params = new HttpParams();
            params = params.append('currentLanguage', this.globals.currentLanguage);
            params = params.append('needRegister', needRegister);
            params = params.append('deviceId', this.oneSignalService.getDeviceId());
            return this.http.post(this.globals.apiUrl + '/accounts/login/social', siteUser, { params })
                .toPromise()
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                yield this.loadingController.dismiss();
                if (response !== null) {
                    this.tokensService.setTokens(response.witaAuthToken);
                    localStorage.setItem('wita-user', JSON.stringify(response));
                    this.storage.set('wita-user', response);
                    this.globals.owner = response;
                    this.authenticationService.afterLoginInitiation();
                }
            })).catch((er) => __awaiter(this, void 0, void 0, function* () {
                console.log('throw error after social login request inside facebook auth service');
                yield this.loadingController.dismiss();
                throw er;
            }));
        });
    }
    accessWithFB(referrer, needRegister) {
        if (this.platform.is('cordova')) {
            this.fb.login(['public_profile', 'email'])
                .then((user) => __awaiter(this, void 0, void 0, function* () {
                console.log('Logged into Facebook!', user);
                if (user !== null) {
                    const fields = ['email', 'first_name', 'last_name', 'picture.type(normal)'].join(',');
                    const userData = yield this.http.get(`https://graph.facebook.com/${user.authResponse.userID}?access_token=${user.authResponse.accessToken}&fields=${fields}`)
                        .toPromise();
                    const userFb = {
                        firstName: userData.first_name,
                        lastName: userData.last_name,
                        id: userData.id,
                        email: userData.email,
                        photoUrl: userData.picture.data.url
                    };
                    this.authByFacebook(userFb, needRegister).then(res => {
                        this.profileService.setReferrer(this.globals.owner, referrer);
                        this.router.navigate(['/']);
                        this.toolbarService.setHeaderVisibility(true);
                        this.menuService.activeMainMenu();
                    }).catch((er) => __awaiter(this, void 0, void 0, function* () {
                        yield this.authenticationService.checkConfirmationRequired(er);
                    }));
                }
            })).catch((er) => __awaiter(this, void 0, void 0, function* () {
                yield this.authenticationService.checkConfirmationRequired(er);
            }));
        }
        else {
            this.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
                if (user !== null) {
                    this.authByFacebook(user, needRegister).then(() => {
                        this.profileService.setReferrer(this.globals.owner, referrer);
                        this.router.navigate(['/']);
                        this.toolbarService.setHeaderVisibility(true);
                        this.menuService.activeMainMenu();
                    }).catch((er) => __awaiter(this, void 0, void 0, function* () {
                        yield this.authenticationService.checkConfirmationRequired(er);
                    }));
                }
            });
        }
    }
}
FacebookAuthService.ɵfac = function FacebookAuthService_Factory(t) { return new (t || FacebookAuthService)(i0.ɵɵinject(i1.AuthServiceConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Globals), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.Storage), i0.ɵɵinject(i6.Platform), i0.ɵɵinject(i7.Facebook), i0.ɵɵinject(i8.MenuService), i0.ɵɵinject(i9.ToolbarService), i0.ɵɵinject(i10.AuthenticationService), i0.ɵɵinject(i11.TokensService), i0.ɵɵinject(i12.UiAlertService), i0.ɵɵinject(i13.ProfileService), i0.ɵɵinject(i14.OneSignalService), i0.ɵɵinject(i6.LoadingController)); };
FacebookAuthService.ɵprov = i0.ɵɵdefineInjectable({ token: FacebookAuthService, factory: FacebookAuthService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FacebookAuthService, [{
        type: Injectable
    }], function () { return [{ type: i1.AuthServiceConfig }, { type: i2.HttpClient }, { type: i3.Globals }, { type: i4.Router }, { type: i5.Storage }, { type: i6.Platform }, { type: i7.Facebook }, { type: i8.MenuService }, { type: i9.ToolbarService }, { type: i10.AuthenticationService }, { type: i11.TokensService }, { type: i12.UiAlertService }, { type: i13.ProfileService }, { type: i14.OneSignalService }, { type: i6.LoadingController }]; }, null); })();
