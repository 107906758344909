<div class="level-list">
  <button class="btn btn-outline-success"
          *ngFor="let category of levelData.categories"
          [class.category__has_label]="category.label !== null"
          [class.category__private]="category.accessType === 3"
          [class.category__mod]="category.accessType === 2"
          (click)="selectCategoryHandler(category.id)">
    {{ categoryService.getTranslationForTitle(category) }}
    <span
      class="notes-number"
      *ngIf="category.numberOfNotes > 0 && (globals.feed.feedType === feedService.getFeedType().HOME || globals.feed.feedType === feedService.getFeedType().USER)"
    >{{category.numberOfNotes}}</span>
  </button>

  <button class="btn btn-success"
          *ngIf="levelData.showAddCategoryButton"
          (click)="showAddNewCategoryFormHandler()" translate>+ Add category</button>
</div>
