import { Pipe } from '@angular/core';
import * as i0 from "@angular/core";
export class UserNamePipe {
    transform(user) {
        if (!user.firstName && !user.lastName) {
            return 'unknown';
        }
        if (user.firstName && !user.lastName) {
            return `${user.firstName}`;
        }
        if (!user.firstName && user.lastName) {
            return `${user.lastName}`;
        }
        return `${user.firstName} ${user.lastName}`;
    }
}
UserNamePipe.ɵfac = function UserNamePipe_Factory(t) { return new (t || UserNamePipe)(); };
UserNamePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "userName", type: UserNamePipe, pure: false });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UserNamePipe, [{
        type: Pipe,
        args: [{
                name: 'userName',
                pure: false
            }]
    }], null, null); })();
