import {Component, OnInit} from '@angular/core';
import {UtilsService} from "../../services/utils.service";
import { faUndo, faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import {Platform} from "@ionic/angular";
import {ImageCroppedEvent} from "ngx-image-cropper";

@Component({
  selector: 'app-image-crop',
  template: `
    <ion-segment>
      <ion-segment-button (click)="actions('left')">
        <fa-icon [icon]="faUndo"></fa-icon>
      </ion-segment-button>
      <ion-segment-button (click)="actions('right')">
        <fa-icon style="transform: rotate(180deg);" [icon]="faUndo"></fa-icon>
      </ion-segment-button>
      <ion-segment-button (click)="actions('zoom-in')">
        <fa-icon [icon]="faSearchPlus"></fa-icon>
      </ion-segment-button>
      <ion-segment-button (click)="actions('zoom-out')">
        <fa-icon [icon]="faSearchMinus"></fa-icon>
      </ion-segment-button>
    </ion-segment>

    <image-cropper
      *ngIf="component.img"
      [maintainAspectRatio]="true"
      [imageQuality]="75"
      [canvasRotation]="rotate"
      [aspectRatio]="aspectRatio"
      [imageBase64]="component.img"
      [roundCropper]="roundCropper"
      [(transform)]="transform"
      (imageCropped)="imageCropped($event)"
      format="jpeg">
    </image-cropper>


    <ion-segment (ionChange)="segmentChanged($event)">
      <ion-segment-button value="save">
        <ion-label style="margin-bottom: 0">Save</ion-label>
      </ion-segment-button>
      <ion-segment-button value="cancel">
        <ion-label style="margin-bottom: 0">Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>

  `,
  styleUrls: []
})
export class ImageCropComponent implements OnInit {
  component: any;

  rotate = 0;

  roundCropper = false;

  aspectRatio;
  transform: any = {
    scale: 1,
    rotate: 0
  };

  croppedImage;

  faUndo = faUndo;
  faSearchMinus = faSearchMinus;
  faSearchPlus = faSearchPlus;

  constructor(
    private platform: Platform,
    private utilsService: UtilsService) {
  }

  ngOnInit() {
    this.component = this;

    if (this.platform.is('cordova')) {
      this.component.img = "data:image/jpeg;base64, " + this.component.img;
    }

    if (this.component.cantResize) {
      this.aspectRatio = 1;
      this.roundCropper = true;
    } else {
      this.aspectRatio = 6 / 2;
      this.roundCropper = false;
    }

  }

  actions(action) {
    switch (action) {
      case 'left':
        this.transform = {
          ...this.transform,
          rotate: this.transform.rotate + 90
        };
        break;
      case 'right':
        this.transform = {
          ...this.transform,
          rotate: this.transform.rotate - 90
        };
        break;
      case 'zoom-in':
        this.transform = {
          ...this.transform,
          scale: this.transform.scale + 0.1
        };
        break;
      case 'zoom-out':
        this.transform = {
          ...this.transform,
          scale: this.transform.scale - 0.1
        };
        break;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  save() {
    const block = this.croppedImage.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];

    this.component.callback(this.utilsService.b64toBlob(realData, contentType, 512));
  }

  segmentChanged(ev: any) {
    switch (ev.detail.value) {
      case 'save':
        this.save();
        break;
      case 'cancel':
        this.component.dismiss();
    }
  }

}
