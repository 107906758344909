import {Directive, HostListener} from '@angular/core';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';

@Directive({
  selector: '[appImageOpener]'
})
export class ImageOpenerDirective {
  options = {
    share: true, // default is false
    closeButton: true, // default is true
    copyToReference: true, // default is false
    headers: "",  // If it is not provided, it will trigger an exception
    piccasoOptions: { } // If it is not provided, it will trigger an exception
  };
  constructor(private photoViewer: PhotoViewer) {
  }

  @HostListener('click', ['$event.target'])
  mouseDown(targetElement) {
    this.openPhoto(targetElement);
  }

  private openPhoto(targetElement) {
    if (targetElement.src && targetElement.tagName === 'IMG') {
      console.log(targetElement.src);
      this.photoViewer.show(targetElement.src, '', this.options);
    }
  }
}
