import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../globals';
import * as $ from '../../../assets/js/jquery-3.3.1.min';
import { SiteUserIpAndLocation } from '../../modules/site-user/site-user-ip-and-location';
import { TokensService } from '@app/shared/services/tokens.service';
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
import * as i3 from "@app/shared/services/tokens.service";
export class SiteUserService {
    constructor(http, globals, tokensService) {
        this.http = http;
        this.globals = globals;
        this.tokensService = tokensService;
        this.walkThroughTasks = [
            { name: 'welcomeGuideTask0', title: 'Welcome', done: false },
            { name: 'welcomeGuideTask1', title: 'Open a post', done: false },
            { name: 'welcomeGuideTask2', title: 'Close a post', done: false },
            { name: 'welcomeGuideTask3', title: 'Filter by a category', done: false },
            { name: 'welcomeGuideTask4', title: 'Choose a category', done: false },
            { name: 'welcomeGuideTask5', title: 'Confirm selection', done: false },
            { name: 'addNoteGuideTask7', title: 'Confirm selection 2', done: false },
            { name: 'addNoteGuideTask8', title: 'Note typing', done: false },
            { name: 'addNoteGuideTask9', title: 'Go note', done: false },
            { name: 'addNoteGuideTask10', title: 'Access and Weight', done: false },
            { name: 'addNoteGuideTask11', title: 'Save note', done: false },
            { name: 'addNoteGuideTask12', title: 'Swipe back', done: false }
        ];
        this.newUserSource = new BehaviorSubject(false);
        this.isNewUser = this.newUserSource.asObservable();
    }
    setSelectedUser(friend) {
        this.globals.selectedUser = friend;
    }
    setIsNewUser(isNewUser) {
        this.newUserSource.next(isNewUser);
    }
    getUsersBy(valueFilter, page, relationFilter, currentUserId) {
        let params = new HttpParams();
        params = params.append('valueFilter', valueFilter);
        params = params.append('page', page.toString());
        if (relationFilter !== undefined) {
            params = params.append('relationFilter', relationFilter.toString());
            if (currentUserId !== undefined && currentUserId !== 0) {
                params = params.append('currentUserId', currentUserId.toString());
            }
        }
        return this.http.get(this.globals.apiUrl + '/users', {
            params: params,
            responseType: 'json'
        });
    }
    changeUserPassword(oldPassword, password, success, error) {
        let params = new HttpParams();
        params = params.append('password', password);
        params = params.append('oldPassword', oldPassword);
        this.http.put(this.globals.apiUrl + '/users/password', null, {
            params: params,
            observe: 'response'
        }).subscribe((response) => {
            if (response.status === 204) {
                error();
            }
            if (response.status === 200) {
                const tokens = response.body;
                this.tokensService.setTokens(tokens);
                success();
            }
        });
    }
    updateLocation() {
        const access_key = '163aa10303ba501cdca287efbe6ea784';
        let location;
        const siteUserIpAndLocation = new SiteUserIpAndLocation();
        siteUserIpAndLocation.owner = this.globals.owner;
        const globals = this.globals;
        const http = this.http;
        $.ajax({
            url: 'https://api.ipapi.com/api/check?access_key=' + access_key + '&fields=country_name,region_name,city,zip,ip',
            dataType: 'jsonp',
            success: function (json) {
                let country;
                let region;
                let city;
                if (json.ip != null) {
                    siteUserIpAndLocation.lastLoginIP = json.ip;
                }
                if (json.country_name !== null) {
                    country = json.country_name;
                }
                else if (globals.apiUrl === 'http://localhost:8080') {
                    country = 'localhost';
                }
                else {
                    country = 'unknown country';
                }
                if (json.region_name !== null) {
                    region = json.region_name;
                }
                else if (globals.apiUrl === 'http://localhost:8080') {
                    region = 'localhost';
                }
                else {
                    region = 'unknown region';
                }
                if (json.city !== null) {
                    city = json.city;
                }
                else if (globals.apiUrl === 'http://localhost:8080') {
                    city = 'localhost';
                }
                else {
                    city = 'unknown city';
                }
                location = country + ', ' + region + ', ' + city;
                siteUserIpAndLocation.lastLoginLocation = location;
            }
        }).done(function () {
            http.put(globals.apiUrl + '/users/geo', siteUserIpAndLocation).subscribe(() => {
            });
        });
    }
    getCountOfAllNotifications() {
        this.http.get(this.globals.apiUrl + '/users/notifications').subscribe((data) => {
            this.globals.notificationCount = data;
        });
    }
    getALLPrivacyLevels() {
        return this.http.get(this.globals.apiUrl + '/users/privacy-levels');
    }
}
SiteUserService.ɵfac = function SiteUserService_Factory(t) { return new (t || SiteUserService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.TokensService)); };
SiteUserService.ɵprov = i0.ɵɵdefineInjectable({ token: SiteUserService, factory: SiteUserService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SiteUserService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.TokensService }]; }, null); })();
