import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormatPipe',
})
export class DateFormatPipePipe implements PipeTransform {
  transform(value) {
    return moment(value).format('MM DD YYYY') === 'Invalid date' ? '' : moment(value).format('MM DD YYYY');
  }
}
