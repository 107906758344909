var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Globals } from '../../../shared/globals';
import { Router } from '@angular/router';
import { DropDownListComponent } from '../../../shared/components/drop-down-list/drop-down-list.component';
import { ToolbarService } from "@app/shared/services/toolbar/toolbar.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/globals";
import * as i2 from "@app/shared/services/toolbar/toolbar.service";
import * as i3 from "@angular/router";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/common";
import * as i6 from "../chat-messages/chat-messages.component";
import * as i7 from "@app/shared/pipes/user-name.pipe";
function MobileChatModalComponent_ng_container_9_img_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "img", 10);
} if (rf & 2) {
    const a_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("src", a_r2.icon, i0.ɵɵsanitizeUrl)("routerLink", a_r2.route);
} }
function MobileChatModalComponent_ng_container_9_img_2_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "img", 11);
    i0.ɵɵlistener("click", function MobileChatModalComponent_ng_container_9_img_2_Template_img_click_0_listener($event) { i0.ɵɵrestoreView(_r7); const a_r2 = i0.ɵɵnextContext().$implicit; return a_r2.action($event); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const a_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("src", ctx_r4.globals.isCategoryEditingMode && a_r2.onCompleteIcon ? a_r2.onCompleteIcon : a_r2.icon, i0.ɵɵsanitizeUrl);
} }
function MobileChatModalComponent_ng_container_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MobileChatModalComponent_ng_container_9_img_1_Template, 1, 2, "img", 8);
    i0.ɵɵtemplate(2, MobileChatModalComponent_ng_container_9_img_2_Template, 1, 1, "img", 9);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const a_r2 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", a_r2.route);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", a_r2.action);
} }
function MobileChatModalComponent_app_chat_messages_11_Template(rf, ctx) { if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-chat-messages", 12);
    i0.ɵɵlistener("lastMessageUpdated", function MobileChatModalComponent_app_chat_messages_11_Template_app_chat_messages_lastMessageUpdated_0_listener($event) { i0.ɵɵrestoreView(_r11); const ctx_r10 = i0.ɵɵnextContext(); return ctx_r10.getLastMessage($event); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("isMobile", true)("data", ctx_r1.chatList);
} }
const _c0 = function (a0) { return { "padding-top": a0 }; };
export class MobileChatModalComponent {
    constructor(globals, toolbarService, router, popoverController, modalCtrl) {
        this.globals = globals;
        this.toolbarService = toolbarService;
        this.router = router;
        this.popoverController = popoverController;
        this.modalCtrl = modalCtrl;
        this.actions = [
            {
                name: 'Category tree',
                icon: 'assets/icons/design_v4/category_path.svg',
                action: (ev) => {
                    this.dismiss();
                    this.router.navigate(['/categories']);
                }
            },
            {
                name: 'More',
                icon: 'assets/icons/design_v4/more_btn.svg',
                action: (ev) => {
                    console.log(this);
                    this.dropDownMenu(ev);
                }
            }
        ];
        this.dropDownMenuItems = {
            data: [
                {
                    callback: () => {
                        console.log('1');
                    },
                    name: 'First'
                },
                {
                    callback: () => {
                        console.log('2');
                    },
                    name: 'Second'
                }
            ]
        };
    }
    ngOnInit() {
    }
    dismiss() {
        this.modalCtrl.dismiss();
    }
    dropDownMenu(ev) {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = yield this.popoverController.create({
                component: DropDownListComponent,
                componentProps: this.dropDownMenuItems,
                event: ev,
                translucent: true
            });
            yield modal.present();
        });
    }
}
MobileChatModalComponent.ɵfac = function MobileChatModalComponent_Factory(t) { return new (t || MobileChatModalComponent)(i0.ɵɵdirectiveInject(i1.Globals), i0.ɵɵdirectiveInject(i2.ToolbarService), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i4.PopoverController), i0.ɵɵdirectiveInject(i4.ModalController)); };
MobileChatModalComponent.ɵcmp = i0.ɵɵdefineComponent({ type: MobileChatModalComponent, selectors: [["app-mobile-chat-modal"]], decls: 12, vars: 9, consts: [["color", "primary", 3, "ngStyle"], ["slot", "start", 1, "left-actions", "ml-3"], ["shape", "round", 3, "click"], [3, "src"], [1, "ion-text-center", 2, "color", "white"], ["slot", "end"], [4, "ngFor", "ngForOf"], [3, "isMobile", "data", "lastMessageUpdated", 4, "ngIf"], ["class", "action-button mx-2", 3, "src", "routerLink", 4, "ngIf"], ["class", "action-button mx-2", 3, "src", "click", 4, "ngIf"], [1, "action-button", "mx-2", 3, "src", "routerLink"], [1, "action-button", "mx-2", 3, "src", "click"], [3, "isMobile", "data", "lastMessageUpdated"]], template: function MobileChatModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-header");
        i0.ɵɵelementStart(1, "ion-toolbar", 0);
        i0.ɵɵelementStart(2, "ion-buttons", 1);
        i0.ɵɵelementStart(3, "ion-button", 2);
        i0.ɵɵlistener("click", function MobileChatModalComponent_Template_ion_button_click_3_listener() { return ctx.dismiss(); });
        i0.ɵɵelement(4, "img", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "ion-title", 4);
        i0.ɵɵtext(6);
        i0.ɵɵpipe(7, "userName");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "ion-buttons", 5);
        i0.ɵɵtemplate(9, MobileChatModalComponent_ng_container_9_Template, 3, 2, "ng-container", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(10, "ion-content");
        i0.ɵɵtemplate(11, MobileChatModalComponent_app_chat_messages_11_Template, 1, 2, "app-chat-messages", 7);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(7, _c0, ctx.toolbarService.isNewIphone ? "30px" : "0"));
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("src", "assets/icons/design_v4/back_icon.svg", i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(7, 5, ctx.globals.receiver), " ");
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngForOf", ctx.actions);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.activeDialogue !== undefined && ctx.chatList);
    } }, directives: [i4.IonHeader, i4.IonToolbar, i5.NgStyle, i4.IonButtons, i4.IonButton, i4.IonTitle, i5.NgForOf, i4.IonContent, i5.NgIf, i3.RouterLink, i4.RouterLinkDelegate, i6.ChatMessagesComponent], pipes: [i7.UserNamePipe], styles: [""] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MobileChatModalComponent, [{
        type: Component,
        args: [{
                selector: 'app-mobile-chat-modal',
                templateUrl: './mobile-chat-modal.component.html',
                styleUrls: ['./mobile-chat-modal.component.css']
            }]
    }], function () { return [{ type: i1.Globals }, { type: i2.ToolbarService }, { type: i3.Router }, { type: i4.PopoverController }, { type: i4.ModalController }]; }, null); })();
