<ion-content [scrollEvents]="true">
  <ion-refresher slot="fixed" (ionRefresh)="doRefreshContent($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <div class="reset-filters" *ngIf="!globals.activeFilters.isEmpty()">
    <ion-button class="reset-filters-button" fill="outline" color="primary" (click)="clearFilters()">
      <p class="reset-filters-title" translate>сlear all filters</p>
    </ion-button>
  </div>


    <div *ngFor="let feed of feeds | async;">
      <div class="backdrop">
        <div style="display: block">
          <app-profile-header #profileHeader style="width: 100%"></app-profile-header>
          <ion-img *ngIf="worldFeed.isFeedEnabled" class="backdrop__image" [src]="'assets/icons/design_v4/worldFeedHeader.jpg'"
                   style="width: 100%"></ion-img>

          <ion-img *ngIf="friendFeed.isFeedEnabled" class="backdrop__image" [src]="'assets/icons/design_v4/friendFeedHeader.jpg'"
                   style="width: 100%"></ion-img>
          <ion-spinner
            *ngIf="!areNotesLoaded"
            class="main-loader"
            name="crescent">
          </ion-spinner>
          <app-note-list
            *ngIf="areNotesLoaded"
            style="width: 100%"
            [paginator]="feed.paginator"
          ></app-note-list>
        </div>
      </div>
    </div>


  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<div>
  <ion-button *ngIf="homeFeed.isFeedEnabled || worldFeed.isFeedEnabled" fill="clear"
              class="slide-btn next-slide-btn">

  </ion-button>
  <ion-button *ngIf="worldFeed.isFeedEnabled || friendFeed.isFeedEnabled"
              fill="clear"
              class="slide-btn prev-slide-btn" >
  </ion-button>

</div>
<ion-fab *ngIf="globals.selectedUser.id === globals.owner.id" style="bottom: 30px;" vertical="bottom"
         horizontal="center" slot="fixed">
  <ion-fab-button appButtonTouch id="new-note-button" color="light" (click)="showCategoriesPage()">
    <img [src]="'assets/icons/design_v4/plus_btn.svg'">
  </ion-fab-button>
</ion-fab>

