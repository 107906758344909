import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NoteCommentService} from '../../shared/services/note-comment.service';
import {Globals} from '../../shared/globals';
import {NoteComment} from './note-comment';
import {DateConverterService} from '../../shared/services/date-converter.service';
import {Note} from '@app/modules/note/note';
import {PopoverController} from '@ionic/angular';
import {DropDownListComponent} from '@app/shared/components/drop-down-list/drop-down-list.component';
import {UiAlertService} from '@app/shared/services/ui-alert-service';

@Component({
  selector: 'app-note-comment',
  templateUrl: './note-comment.component.html',
  styleUrls: ['./note-comment.component.scss']
})
export class NoteCommentComponent implements OnInit {

  @Output() haveAddedNewComment = new EventEmitter<boolean>();
  @Output() haveDeletedComment = new EventEmitter<NoteComment>();

  @Input() currentParentComment: NoteComment;
  @Input() noteEntity: Note;
  @Input() translationsText: string[];

  @ViewChild('newReply', {static: false}) newReply;

  public editedCommentContent;
  public isEdit = false;
  public isChildEdit = false;
  public isNewChild = false;
  private selectedComment: NoteComment;

  dropDownEditItems = {
    data: []
  };

  editDrop = {
    callback: () => {
      this.editComment(this.selectedComment);
    },
    name: 'Edit'
  };

  deleteDrop = {
    callback: () => {
      this.deleteComment(this.selectedComment);
    },
    name: 'Remove'
  };

  constructor(public commentService: NoteCommentService,
              public globals: Globals,
              public dateService: DateConverterService,
              private popoverController: PopoverController,
              private uiAlertService: UiAlertService) {
  }

  ngOnInit() {
  }

  addChildComment() {
    this.isNewChild = true;
    setTimeout(() => this.newReply.focusOnInput());
  }

  async deleteComment(comment: NoteComment) {
    await this.uiAlertService.createConfirm(
      this.translationsText['Confirm'],
      this.translationsText['Are you sure you want to remove the comment?'],
      this.translationsText['Cancel'],
      this.translationsText['Remove'], () => {
        this.commentService.deleteComment(comment, () => {
          this.haveDeletedComment.emit(comment);
        });
      });
  }

  editComment(comment: NoteComment) {
    this.isEdit = true;
    this.editedCommentContent = comment.content;
  }

  async updateComment(comment: NoteComment) {
    if (this.editedCommentContent !== '') {
      comment.content = this.editedCommentContent;
      this.commentService.editComment(comment, () => {
      });
    } else {
      await this.uiAlertService.createAlert(this.translationsText['Error'], this.translationsText['This field can`t be empty']);
    }
    this.isEdit = false;
    this.isChildEdit = false;
  }

  addedNewComment(event: boolean) {
    this.haveAddedNewComment.emit(event);
  }

  async openCommentPopover(ev: any, comment: NoteComment) {
    let canOpen = false;
    this.dropDownEditItems.data = [];
    if (this.globals.owner.id === this.noteEntity.owner.id) {
      this.dropDownEditItems.data.push(this.deleteDrop);
      canOpen = true;
    }
    if (this.globals.owner.id === comment.owner.id) {
      this.dropDownEditItems.data = [];
      this.dropDownEditItems.data.push(this.editDrop);
      this.dropDownEditItems.data.push(this.deleteDrop);
      canOpen = true;
    }
    if (canOpen) {
      this.selectedComment = comment;
      const modal = await this.popoverController.create({
        component: DropDownListComponent,
        componentProps: this.dropDownEditItems,
        event: ev,
        translucent: false
      });
      await modal.present();
    }
  }
}
