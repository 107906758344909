import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Globals } from "@app/shared/globals";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
export class VotingService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    sendQuestionToVoting(question) {
        return this.http.post(this.globals.apiUrl + '/voting/question', question);
    }
    getAllQuestions() {
        return this.http.get(this.globals.apiUrl + '/voting/questions');
    }
    updateQuestionsAndAnswers() {
        this.getAllQuestions().subscribe((questions) => {
            this.globals.votingQuestions = questions;
        });
        this.getAnswersByUserId(this.globals.owner.id).subscribe((answers) => {
            for (let a of answers) {
                this.globals.votingAnsweredQuestionsId.push(a.questionsId);
            }
        });
    }
    getQuestionById(id) {
        let params = new HttpParams().append('id', id.toString());
        this.http.get(this.globals.apiUrl + '/voting/question', { params }).subscribe((question) => {
            console.log(question);
            return question;
        });
    }
    editQuestion(question) {
        return this.http.put(this.globals.apiUrl + '/voting/question', question);
    }
    deleteQuestionById(id) {
        let params = new HttpParams().append('id', id.toString());
        return this.http.delete(this.globals.apiUrl + '/voting/question', { params });
    }
    sendAnswerToVoting(answer) {
        return this.http.post(this.globals.apiUrl + '/voting/answer', answer).subscribe((res) => {
            this.updateQuestionsAndAnswers();
        });
    }
    getAnswerById(id) {
        let params = new HttpParams().append('id', id.toString());
        this.http.get(this.globals.apiUrl + '/voting/answer', { params }).subscribe((answer) => {
            return answer;
        });
    }
    getAnswersByUserId(userId) {
        let params = new HttpParams().append('userId', userId.toString());
        return this.http.get(this.globals.apiUrl + '/voting/answers/user', { params });
    }
    getAnswersByCategoryId(disId) {
        let params = new HttpParams().append('disId', disId.toString());
        this.http.get(this.globals.apiUrl + '/voting/answers/discussion', { params }).subscribe((answerList) => {
            return answerList;
        });
    }
    getAnswersByQuestionId(questionId) {
        let params = new HttpParams().append('questionId', questionId.toString());
        this.http.get(this.globals.apiUrl + '/voting/answers/question', { params }).subscribe((answerList) => {
            return answerList;
        });
    }
}
VotingService.ɵfac = function VotingService_Factory(t) { return new (t || VotingService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
VotingService.ɵprov = i0.ɵɵdefineInjectable({ token: VotingService, factory: VotingService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(VotingService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
