<ion-header>
  <ion-toolbar color="primary" [ngStyle]="{'padding-top': toolbarService.isNewIphone ? '30px' : '0'}" >
    <ion-buttons class="left-actions ml-3" slot="start">
      <ion-button shape="round" (click)="dismiss()">
        <img [src]="'assets/icons/design_v4/back_icon.svg'"/>
      </ion-button>

    </ion-buttons>
    <ion-title class="ion-text-center" style="color: white">
      {{globals.receiver | userName}}
    </ion-title>
    <ion-buttons slot="end">
      <ng-container *ngFor="let a of actions">
        <img *ngIf="a.route"
             class="action-button mx-2"
             [src]="a.icon"
             [routerLink]="a.route"/>

        <img *ngIf="a.action"
             class="action-button mx-2"
             [src]="globals.isCategoryEditingMode && a.onCompleteIcon ? a.onCompleteIcon : a.icon "
             (click)="a.action($event)"/>
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-chat-messages
    *ngIf="activeDialogue !== undefined && chatList"
    [isMobile]="true"
    [data]="chatList"
    (lastMessageUpdated)="getLastMessage($event)">
  </app-chat-messages>
</ion-content>
