import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Globals } from "../../../shared/globals";
// store
import { Store, select } from "@ngrx/store";
import * as RootStoreState from "@app/root-store/state";
import * as featureActions from "@app/root-store/category/actions";
import {
  CategoryStoreActions,
  CategoryStoreSelectors
} from "@app/root-store/category";
import { CategoryApiService } from "../category-api.service";
//
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";
import { Category } from "@app/modules/category/category";

@Component({
  selector: "app-category-tree-ngrx",
  templateUrl: "./category-tree.component.html",
  styleUrls: ["./category-tree.component.scss"]
})
export class CategoryTreeNgrxComponent implements OnInit {
  categoryIdParam$: Observable<string>;
  categoryIdParam: any;
  categories$: Observable<any>;
  levels$: Observable<any>;
  currentSelectCategory$: Observable<any>;

  rootCategories$: Observable<any>;
  rootCategories: any;
  constructor(
    private store$: Store<RootStoreState.State>,
    private route: ActivatedRoute,
    private globals: Globals,
    private categoryService: CategoryApiService
  ) {
    this.categoryIdParam$ = route.params.pipe(map(p => p.id));

    this.store$.dispatch(
      new CategoryStoreActions.LoadRequestAction({
        userId: this.globals.selectedUser.id
      })
    );

    this.store$.dispatch(
      new CategoryStoreActions.LoadRootCategoriesAction({
        userId: this.globals.selectedUser.id
      })
    );

    this.categoryIdParam$.subscribe(id => {
      this.categoryIdParam = id;
      this.store$.dispatch(
        new CategoryStoreActions.LoadCategoryByIdAction({
          categoryId: id
        })
      );
    });
  }

  ngOnInit() {
    this.categories$ = this.store$.pipe(
      select(CategoryStoreSelectors.selectCategoriesItems)
    );

    this.currentSelectCategory$ = this.store$.pipe(
      select(CategoryStoreSelectors.selectCurrentCategory)
    );

    this.rootCategories$ = this.categories$.pipe(
      map((categories: Category[]) =>
        this.categoryService.filterByScopeScope(categories, 11)
      )
    );

    this.levels$ = this.store$.pipe(
      select(CategoryStoreSelectors.selectLevels)
    );

    this.rootCategories$.subscribe(data => {
      this.rootCategories = data;
    });
  }

  doRefresh(): void {}
}
