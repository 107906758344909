import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Continent} from "@app/modules/place/continent";
import {GeoService} from "@app/shared/services/geo.service";
import {LabelService} from "@app/shared/services/label.service";
import {LabelType} from "@app/modules/label/label-type";
import {LabelTypeTitle} from "@app/modules/label/label-type-title";
import {Label} from "@app/modules/label/label";
import {Place} from "@app/modules/place/place";
import {LevelData} from "@app/modules/category-level/LevelData";

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {

  @Input() levelData: LevelData;

  public selectedLabel;

  public labelType = new LabelType(LabelTypeTitle.PLACE);

  public world: Continent[];
  private selectedPlace: Place;

  constructor(private modalController: ModalController, public geoService: GeoService, public labelService: LabelService) {
  }

  ngOnInit() {
    this.geoService.getDefaultPlace().subscribe((place: Place) => {
      this.selectedPlace = place;
      this.geoService.changeSelectedPlace(place);
    });
  }

  selectLabelType() {
    if (this.labelType.isChecked) {
      const label = new Label();
      label.places = [this.selectedPlace];
      this.createLabel(label);
    }
    this.modalController.dismiss();
  }

  createLabel(label: Label) {
    this.labelService.createLabel(label).subscribe((result: Label) => {
      this.selectedLabel = result;
      this.labelService.changeSelectedLabel(this.selectedLabel);
    });
  }
}
