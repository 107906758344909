<div class="add-comment" *ngIf="globals.isAuthenticated">
  <div class="container">
    <div class="add-comment__row">
      <div id="addGroup" class="add-comment__row-group">
        <div class="add-comment__row-empty">
        </div>
        <div class="add-comment__group-control">
          <ion-input #comment
                     (focusin)="focusInput(true)"
                     (focusout)="focusInput(false)"
                     type="text"
                     (keyup)="inputMessageHandler($event)"
                     (click)="inputMessageHandler($event)"
                     [(ngModel)]="commentText"
                     placeholder="{{'Type your comment…'| translate}}">

          </ion-input>
        </div>
        <div class="add-comment__group-button">
          <button class="add-comment__button" [disabled]="commentText === ''" (click)="addNewComment()">
            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.9181 11.7303L2.52827 12.6273L0.0568994 20.427C-0.0906259 20.8881 0.0544377 21.3916 0.42571 21.7072C0.796983 22.0227 1.32304 22.0895 1.76315 21.877L22.3424 12.0423C22.7447 11.8486 23 11.4454 23 11.0035C23 10.5617 22.7447 10.1585 22.3424 9.96477L1.77752 0.122961C1.3374 -0.0895013 0.811352 -0.0226845 0.440079 0.292836C0.0688062 0.608357 -0.0762574 1.11188 0.0712679 1.573L2.54264 9.3727L11.9145 10.2697C12.2931 10.3066 12.5816 10.6209 12.5816 10.9965C12.5816 11.372 12.2931 11.6863 11.9145 11.7232L11.9181 11.7303Z"
                fill="#48CDC4"/>
            </svg>
          </button>
        </div>

      </div>
    </div>
  </div>
  <emoji-content class="toolbar-emoji-picker" *ngIf="showEmojiPicker" (emoji-selection)="emojiHandler($event)">
  </emoji-content>
</div>
