import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Tokens } from '@app/shared/models/tokens';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { TokensService } from '@app/shared/services/tokens.service';
import { Router } from '@angular/router';
import { Globals } from '@app/shared/globals';
import * as i0 from "@angular/core";
import * as i1 from "@app/core/auth/authentication.service";
import * as i2 from "@app/shared/services/tokens.service";
import * as i3 from "@angular/router";
import * as i4 from "@app/shared/globals";
export class TokenInterceptor {
    constructor(authService, tokensService, router, globals) {
        this.authService = authService;
        this.tokensService = tokensService;
        this.router = router;
        this.globals = globals;
        this.isRefreshing = false;
        this.refreshTokenSubject = new BehaviorSubject(null);
    }
    intercept(request, next) {
        const tokens = JSON.parse(localStorage.getItem('tokens'));
        if (((request.url.includes("accounts")) && (!request.url.includes("logout"))) || request.url.includes('assets')) {
            return next.handle(request).pipe(catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    this.isRefreshing = false;
                    this.authService.logoutProcesses();
                    return throwError(error);
                }
                else {
                    return next.handle(request);
                }
            }));
        }
        if (tokens) {
            if (tokens.jwtToken && !this.tokensService.isExpired()) {
                request = this.addToken(request, tokens.jwtToken);
            }
            else {
                if (request && request.body && !request.body.skip || !request.body) {
                    return this.workWithToken(request, next);
                }
            }
        }
        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                this.authService.logoutProcesses();
            }
            else if (error instanceof HttpErrorResponse && error.status === 403 && request.url.includes('categories') && request.method === 'GET') {
                return throwError(error);
            }
            else {
                this.globals.action = null;
                this.router.navigate(['/']);
            }
            return throwError(error);
        }));
    }
    addToken(request, token) {
        request = request.clone({ headers: request.headers.set('X-Requested-With', 'XMLHttpRequest') });
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    workWithToken(request, next) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authService.refreshAuthToken().pipe(switchMap((token) => {
                const tokens = new Tokens(Object.assign(Object.assign({}, JSON.parse(localStorage.getItem('tokens'))), { jwtToken: token.jwtToken, createdDate: new Date().toISOString() }));
                localStorage.setItem('tokens', JSON.stringify(tokens));
                this.isRefreshing = false;
                this.refreshTokenSubject.next(tokens.jwtToken);
                return next.handle(this.addToken(request, tokens.jwtToken));
            }));
        }
        else {
            return this.refreshTokenSubject.pipe(filter(token => token != null), take(1), switchMap(jwtToken => {
                return next.handle(this.addToken(request, jwtToken));
            }));
        }
    }
}
TokenInterceptor.ɵfac = function TokenInterceptor_Factory(t) { return new (t || TokenInterceptor)(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.TokensService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.Globals)); };
TokenInterceptor.ɵprov = i0.ɵɵdefineInjectable({ token: TokenInterceptor, factory: TokenInterceptor.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TokenInterceptor, [{
        type: Injectable
    }], function () { return [{ type: i1.AuthenticationService }, { type: i2.TokensService }, { type: i3.Router }, { type: i4.Globals }]; }, null); })();
