import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
export class SubscriptionService {
    constructor() {
    }
    unSubscriber(subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }
}
SubscriptionService.ɵfac = function SubscriptionService_Factory(t) { return new (t || SubscriptionService)(); };
SubscriptionService.ɵprov = i0.ɵɵdefineInjectable({ token: SubscriptionService, factory: SubscriptionService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SubscriptionService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return []; }, null); })();
