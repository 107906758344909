import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {Globals} from '@app/shared/globals';
import {Page} from '@app/core/page';
import {CategoryDiscussion} from '@app/modules/category/category-discussion';
import {CategoryComment} from '@app/modules/category/category-comment';
import {EditorsService} from '@app/shared/services/editors.service';
import {DateConverterService} from '@app/shared/services/date-converter.service';
import {Router} from '@angular/router';
import {Category} from '@app/modules/category/category';
import {EditorsTermsComponent} from '@app/modules/editors-page/editors-terms/editors-terms.component';
import {CategoryService} from '@app/shared/services/category.service';
import {VotingQuestion} from '@app/modules/voting-question/voting-question';
import {VotingService} from '@app/shared/services/voting-service';
import {VotingAnswer} from '@app/modules/voting-answer/voting-answer';
import {TranslateService} from '@ngx-translate/core';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";

interface Dictionary {
    [index: string]: string;
}

@Component({
    selector: 'app-editors-discuss',
    templateUrl: './editors-discuss.component.html',
    styleUrls: ['./editors-discuss.component.scss']
})
export class EditorsDiscussComponent implements OnInit {

    @Output() haveAddedNewComment = new EventEmitter<boolean>();

    recentDiscussions: Page<CategoryDiscussion>;

    public parentCategory: Category;
    public vote: number;
    public upvotes: number;
    public downvotes: number;

    @Input() discuss: CategoryDiscussion;

    public langs: Map<string, string>;
    public langKeysArray = [];
    public langTranslation: string;
    public selectedLang: string;
    public lansInObj = {};
    public currentCategory: Category;

    isNewChild = false;

    objIterable: Dictionary;

    editorModeNav = [{
        title: 'Info',
        name: 'Info',
        icon: 'assets/icons/design_v4/icon-info.svg',
        action: () => {
            this.presentModal();
        }
    }];

    adminModeNav = [
      {
        title: 'Info',
        name: 'Info',
        icon: 'assets/icons/design_v4/icon-info.svg',
        action: () => {
            this.presentModal();
        }
      },
      {
        title: 'Approve',
        name: 'Approve',
        icon: 'assets/icons/design_v4/check.svg',
        action: () => {
          this.alertBeforeMakePublic(1);
        }
      },
      {
        title: 'Hide',
        name: 'Hide',
        icon: 'assets/icons/design_v4/close_icon.svg',
        action: () => {
          this.alertBeforeMakePublic(3);
        }
      }
    ];

    parentCommentId: number;
    commentText = '';
    showEmojiPicker = false;
    childCommentText = '';
    selectionStart = 0;
    question: VotingQuestion = null;
    questionId: number = null;
    canAsk: boolean;
    translationText: string = "";

    constructor(public router: Router,
                public globals: Globals,
                public navParams: NavParams,
                public modalCtrl: ModalController,
                public editorsService: EditorsService,
                public dateService: DateConverterService,
                public categoryService: CategoryService,
                public votingService: VotingService,
                public translationService: TranslateService,
                public toolbarService: ToolbarService,
                private uiAlertService: UiAlertService) {

        this.objIterable = navParams.data;
    }

    ngOnInit() {
        this.translationService.get([
            "Are you sure you want to make this category public?",
            "Are you sure you want to make this category private?",
            "Cancel",
            "Yes"
        ]).subscribe(tr => {
            this.translationText = tr;
        });

        this.currentCategory = this.navParams.get('currentCategory');
        this.discuss.categoryComments = this.navParams.get('discuss').categoryComments;
        this.recentDiscussions = this.navParams.get('discuss');

        this.initializeLangs();
        this.initializeVotes();
        this.randomize();
        this.question = this.getQuestion();
    }

    updateNavigation(): void {
        this.toolbarService.setMenuItems(this.globals.isAdmin ? this.adminModeNav : this.editorModeNav);
    }

    addChildComment(commentId: number) {
        this.parentCommentId = commentId;
        this.isNewChild = true;
    }

    visibleLang(lang: string, state: boolean) {
        for (const key in this.lansInObj) {
            if (key !== lang) {
                this.lansInObj[key] = state;
            }
        }
    }

    initializeVotes() {
        if (this.discuss.siteUserVotes !== null) {
            this.vote = this.discuss.siteUserVotes.lastVote;
        } else {
            this.vote = 0;
        }
        this.upvotes = this.discuss.categoryVotes.likesCounter;
        this.downvotes = this.discuss.categoryVotes.dislikesCounter;
    }

    keys(): Array<string> {
        return Object.keys(this.objIterable);
    }

    dismiss() {
        this.modalCtrl.dismiss({
            approved: false
        });
    }

    private upvote(change: number) {
        this.upvotes += change;
    }

    private downvote(change: number) {
        this.downvotes += change;
    }

    private toggleVote(voteValue: number) {
        if (voteValue === -1) {
            this.downvote(voteValue === this.vote ? -1 : 1);
            this.upvote(this.vote === 1 ? -1 : 0);
        }
        if (voteValue === 1) {
            this.upvote(voteValue === this.vote ? -1 : 1);
            this.downvote(this.vote === -1 ? -1 : 0);
        }
        this.vote = this.vote === voteValue ? 0 : voteValue;
        this.editorsService.vote(this.discuss.id, this.vote);
    }

    initializeLangs() {
        this.langs = this.navParams.get('langs');
        this.langKeysArray = Array.from(this.langs.keys());

        this.langs.forEach((key, value) => {
            this.lansInObj[value] = true;
        });
    }

    translateLang(event) {
        this.langTranslation = String(event.target.value);
    }

    private changeSelectedLang(lang: string) {
        this.selectedLang = lang;
        this.visibleLang(lang, false);
    }

    hideTranslate(lang) {
        this.selectedLang = null;
        this.visibleLang(lang, true);
    }

    addNewComment() {
        const newComment = this.prepareNewComment();
        this.editorsService.addComment(newComment, response => {
            this.editorsService.refreshCommentList(this.discuss.id).subscribe((data: Array<CategoryComment>) => {
                this.discuss.categoryComments = data;
                this.parentCommentId = null;
                this.isNewChild = false;
                this.commentText = '';
                this.childCommentText = '';
                this.showEmojiPicker = false;
                this.discuss.categoryComments = data;
            });
        });
    }

    prepareNewComment() {
        const value: CategoryComment = new CategoryComment();
        value.content = this.commentText;
        value.categoryDiscussion = this.discuss.id;
        value.owner = this.globals.owner;
        if (this.isNewChild) {
            value.content = this.childCommentText;
            value.parentComment = this.parentCommentId;
        }
        return value;
    }

    toggleEmojiPicker() {
        this.showEmojiPicker = !this.showEmojiPicker;
    }

    inputMessageHandler(e) {
        this.selectionStart = e.target.selectionStart;
    }

    emojiHandler(event) {
        this.commentText += event[0];
        if (this.isNewChild) {
            this.childCommentText += event[0];
        }
    }

    async presentModal() {
        const modal: any = await this.modalCtrl.create({
            component: EditorsTermsComponent
        });
        return await modal.present();
    }

    private async alertBeforeMakePublic(access) {
        let privacyName;
        access == 1 ? privacyName = 'public' : privacyName = 'private';
        await this.uiAlertService.createConfirm('', this.translationText[`Are you sure you want to make this category ${privacyName}?`],
          this.translationText['Cancel'], this.translationText['Yes'], () => {
              this.changePrivacy(access);
          });
    }

    async changePrivacy(access) {
      this.currentCategory.accessType = access;
      this.categoryService.updateCategory(this.currentCategory, (response) => {
        this.modalCtrl.dismiss({
          approved: true,
          currentCategory: this.currentCategory.id,
          currentDiscussion: this.discuss.id
        });
      });
    }

    getQuestion() {
        let questions: VotingQuestion[] = [];
        for (let q of this.globals.votingQuestions) {
            if (!this.globals.votingAnsweredQuestionsId.includes(q.id)) {
                questions.push(q);
            }
        }
        if (questions.length != 0) {
            return questions[Math.floor(Math.random() * questions.length)];
        } else {
            this.canAsk = false;
            return null;
        }
    }

    sendAnswer(answer: number) {
        let obj: VotingAnswer = new VotingAnswer();
        obj.userId = this.globals.owner.id;
        obj.categoryDiscussionId = this.discuss.id;
        obj.questionsId = this.question.id;
        obj.userVote = answer;
        this.canAsk = false;
        this.votingService.sendAnswerToVoting(obj);
    }

    randomize() {
        let min = Math.ceil(1);
        let max = Math.floor(5);
        let num = Math.floor(Math.random() * (max - min)) + min;
        this.canAsk = num == 1;
        console.log(this.canAsk, num);
    }
}
