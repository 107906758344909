import { Injectable } from '@angular/core';
import { Globals } from '@app/shared/globals';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/globals";
import * as i2 from "@ngx-translate/core";
export class UtilsService {
    constructor(globals, translate) {
        this.globals = globals;
        this.translate = translate;
        this.insert = (arr, i, newItem) => [
            ...arr.slice(0, i),
            newItem,
            ...arr.slice(i)
        ];
    }
    switchLanguage(language) {
        this.translate.use(language);
        this.globals.currentLanguage = language;
        localStorage.setItem('wita-lang', language);
    }
    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }
}
UtilsService.ɵfac = function UtilsService_Factory(t) { return new (t || UtilsService)(i0.ɵɵinject(i1.Globals), i0.ɵɵinject(i2.TranslateService)); };
UtilsService.ɵprov = i0.ɵɵdefineInjectable({ token: UtilsService, factory: UtilsService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UtilsService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.Globals }, { type: i2.TranslateService }]; }, null); })();
