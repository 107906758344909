import { Component, ViewChild } from '@angular/core';
import { LanguagesList } from "@app/core/languages";
import { of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Globals } from "@app/shared/globals";
import { ActivatedRoute, Router } from "@angular/router";
import { SiteUserService } from "@app/shared/services/site-user.service";
import { NoteService } from "@app/shared/services/note.service";
import { EditorsService } from "@app/shared/services/editors.service";
import { Events, IonContent, PopoverController } from "@ionic/angular";
import { ToolbarService } from "@app/shared/services/toolbar/toolbar.service";
import { SubscriptionService } from "@app/shared/services/subscription.service";
import { NoteListPage } from "@app/modules/home/NoteListPage";
import { BdcWalkService } from "bdc-walkthrough";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
import * as i3 from "@angular/router";
import * as i4 from "@app/shared/services/site-user.service";
import * as i5 from "@app/shared/services/note.service";
import * as i6 from "@app/shared/services/editors.service";
import * as i7 from "@ionic/angular";
import * as i8 from "@app/shared/services/toolbar/toolbar.service";
import * as i9 from "@app/shared/services/subscription.service";
import * as i10 from "bdc-walkthrough";
import * as i11 from "@angular/common";
import * as i12 from "@ngx-translate/core";
import * as i13 from "@app/modules/profile/profile-header/profile-header.component";
import * as i14 from "../note-list/note-list.component";
import * as i15 from "@app/shared/directive/button-touch.directive";
const _c0 = ["profileHeader"];
function SiteUserFeedComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵelementStart(1, "ion-button", 10);
    i0.ɵɵlistener("click", function SiteUserFeedComponent_div_3_Template_ion_button_click_1_listener() { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.clearFilters(); });
    i0.ɵɵelementStart(2, "p", 11);
    i0.ɵɵtext(3, "\u0441lear all filters");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function SiteUserFeedComponent_div_4_ion_img_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-img", 19);
} if (rf & 2) {
    i0.ɵɵproperty("src", "assets/icons/design_v4/worldFeedHeader.jpg");
} }
function SiteUserFeedComponent_div_4_ion_img_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-img", 19);
} if (rf & 2) {
    i0.ɵɵproperty("src", "assets/icons/design_v4/friendFeedHeader.jpg");
} }
function SiteUserFeedComponent_div_4_ion_spinner_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-spinner", 20);
} }
function SiteUserFeedComponent_div_4_app_note_list_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "app-note-list", 21);
} if (rf & 2) {
    const feed_r7 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("paginator", feed_r7.paginator);
} }
function SiteUserFeedComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 12);
    i0.ɵɵelementStart(2, "div", 13);
    i0.ɵɵelement(3, "app-profile-header", 14, 15);
    i0.ɵɵtemplate(5, SiteUserFeedComponent_div_4_ion_img_5_Template, 1, 1, "ion-img", 16);
    i0.ɵɵtemplate(6, SiteUserFeedComponent_div_4_ion_img_6_Template, 1, 1, "ion-img", 16);
    i0.ɵɵtemplate(7, SiteUserFeedComponent_div_4_ion_spinner_7_Template, 1, 0, "ion-spinner", 17);
    i0.ɵɵtemplate(8, SiteUserFeedComponent_div_4_app_note_list_8_Template, 1, 1, "app-note-list", 18);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", ctx_r1.worldFeed.isFeedEnabled);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.friendFeed.isFeedEnabled);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r1.areNotesLoaded);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.areNotesLoaded);
} }
function SiteUserFeedComponent_ion_button_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-button", 22);
} }
function SiteUserFeedComponent_ion_button_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-button", 23);
} }
function SiteUserFeedComponent_ion_fab_11_Template(rf, ctx) { if (rf & 1) {
    const _r15 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-fab", 24);
    i0.ɵɵelementStart(1, "ion-fab-button", 25);
    i0.ɵɵlistener("click", function SiteUserFeedComponent_ion_fab_11_Template_ion_fab_button_click_1_listener() { i0.ɵɵrestoreView(_r15); const ctx_r14 = i0.ɵɵnextContext(); return ctx_r14.showCategoriesPage(); });
    i0.ɵɵelement(2, "img", 26);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", "assets/icons/design_v4/plus_btn.svg", i0.ɵɵsanitizeUrl);
} }
export class SiteUserFeedComponent extends NoteListPage {
    constructor(http, globals, activeRoute, userService, noteService, editorsService, router, popoverController, toolbarService, subscriptionService, events, bdcWalkService) {
        super(http, globals, activeRoute, userService, noteService, editorsService, router, popoverController, toolbarService, subscriptionService, events, bdcWalkService);
        this.http = http;
        this.globals = globals;
        this.activeRoute = activeRoute;
        this.userService = userService;
        this.noteService = noteService;
        this.editorsService = editorsService;
        this.router = router;
        this.popoverController = popoverController;
        this.toolbarService = toolbarService;
        this.subscriptionService = subscriptionService;
        this.events = events;
        this.bdcWalkService = bdcWalkService;
        this.languageList = LanguagesList;
        this.popoverOpened = false;
        this.headerActions = [
            {
                title: 'Category tree',
                icon: 'assets/icons/design_v4/category_path.svg',
                action: () => {
                    this.routeCategories();
                }
            },
            {
                title: 'Notifications',
                path: '/notifications',
                icon: 'assets/icons/design_v4/notifications-outline.svg',
                action: () => {
                    // this.globals.feed.closeFeed();
                },
            },
            {
                icon: 'assets/icons/design_v4/filter.svg',
                title: 'Filter',
                action: this.onToggleNoteFilters.bind(this),
            }
        ];
        this.feeds = of([
            this.userFeed
        ]);
    }
    ionViewWillEnter() {
        console.log(" ionViewWillEnter ");
        let params;
        this.activeRoute.params.subscribe((response) => params = response);
        if (params && params.id) {
            this.http.get(`${this.globals.apiUrl}/users/` + params.id)
                .subscribe((user) => {
                if (user !== null) {
                    this.userService.setSelectedUser(user);
                    this.updateNotesAndHeader(true, this.globals.selectedUser.id);
                }
            });
        }
    }
    scrollUp() {
        this.content.scrollToTop();
    }
    ionViewDidLeave() {
        console.log(" ionViewDidLeave ");
    }
    ionViewDidEnter() {
        console.log(" ionViewDidEnter ");
        if (this.globals.firstLoad) {
            if (this.globals.isEditor || this.globals.isAdmin) {
                this.userService.updateLocation();
            }
        }
        else {
            this.toolbarService.refreshMenuItems();
        }
    }
    updateNotesAndHeader(areNotesLoaded, userId, event) {
        console.log(" updateNotesAndHeader ");
        this.profileHeader.init(userId);
        this.areNotesLoaded = areNotesLoaded;
        this.globals.needPageRefresh = true;
        this.dispatchHomeContentRefreshRequest(0, true, event);
    }
    dispatchHomeContentRefreshRequest(page, clearNotes, event) {
        console.log(" dispatchHomeContentRefreshRequest ");
        if (this.globals.activeFilters.categoryFilter.isEmpty()) {
            this.userFeedSubscription = this.noteService.getUserAllNotes(page)
                .subscribe((data) => {
                this.finishNotesRefresh(data, clearNotes, event);
            });
        }
        else {
            this.userFeedByCategorySubscription = this.noteService.getUserNoteListByCategory(this.globals.currentCategoryId, page)
                .subscribe((data) => {
                this.finishNotesRefresh(data, clearNotes, event);
            });
        }
    }
    finishNotesRefresh(response, clearNotes, event) {
        console.log(" finishNotesRefresh ");
        this.globals.noteList = clearNotes ? response.content || [] : this.globals.noteList.concat(response.content);
        response.content = this.globals.noteList;
        this.globals.needPageRefresh = false;
        this.areNotesLoaded = true;
        this.userFeed.paginator = this.setPaginatorFields(response, this.userFeed.paginator);
        if (event) {
            this.completeEffects();
            // event.target.complete();
        }
    }
    loadDataForPagination(event) {
        console.log(" loadDataForPagination ");
        this.globals.needPageRefresh = true;
        if (this.userFeed.paginator.currentPage < this.userFeed.paginator.lastPage) {
            this.dispatchHomeContentRefreshRequest(++this.userFeed.paginator.currentPage, false, event);
        }
        else {
            if (event) {
                event.target.complete();
            }
        }
    }
    completeEffects() {
        if (this.refresherEffect) {
            this.refresherEffect.target.complete();
        }
        if (this.paginationEffect) {
            this.paginationEffect.target.complete();
        }
    }
}
SiteUserFeedComponent.ɵfac = function SiteUserFeedComponent_Factory(t) { return new (t || SiteUserFeedComponent)(i0.ɵɵdirectiveInject(i1.HttpClient), i0.ɵɵdirectiveInject(i2.Globals), i0.ɵɵdirectiveInject(i3.ActivatedRoute), i0.ɵɵdirectiveInject(i4.SiteUserService), i0.ɵɵdirectiveInject(i5.NoteService), i0.ɵɵdirectiveInject(i6.EditorsService), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i7.PopoverController), i0.ɵɵdirectiveInject(i8.ToolbarService), i0.ɵɵdirectiveInject(i9.SubscriptionService), i0.ɵɵdirectiveInject(i7.Events), i0.ɵɵdirectiveInject(i10.BdcWalkService)); };
SiteUserFeedComponent.ɵcmp = i0.ɵɵdefineComponent({ type: SiteUserFeedComponent, selectors: [["app-site-user-feed"]], viewQuery: function SiteUserFeedComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, true);
        i0.ɵɵviewQuery(IonContent, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.profileHeader = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    } }, features: [i0.ɵɵInheritDefinitionFeature], decls: 12, vars: 8, consts: [[3, "scrollEvents"], ["slot", "fixed", 3, "ionRefresh"], ["class", "reset-filters", 4, "ngIf"], [4, "ngFor", "ngForOf"], ["threshold", "100px", 3, "ionInfinite"], ["loadingSpinner", "bubbles", "loadingText", "Loading more data..."], ["fill", "clear", "class", "slide-btn next-slide-btn", 4, "ngIf"], ["fill", "clear", "class", "slide-btn prev-slide-btn", 4, "ngIf"], ["style", "bottom: 30px;", "vertical", "bottom", "horizontal", "center", "slot", "fixed", 4, "ngIf"], [1, "reset-filters"], ["fill", "outline", "color", "primary", 1, "reset-filters-button", 3, "click"], ["translate", "", 1, "reset-filters-title"], [1, "backdrop"], [2, "display", "block"], [2, "width", "100%"], ["profileHeader", ""], ["class", "backdrop__image", "style", "width: 100%", 3, "src", 4, "ngIf"], ["class", "main-loader", "name", "crescent", 4, "ngIf"], ["style", "width: 100%", 3, "paginator", 4, "ngIf"], [1, "backdrop__image", 2, "width", "100%", 3, "src"], ["name", "crescent", 1, "main-loader"], [2, "width", "100%", 3, "paginator"], ["fill", "clear", 1, "slide-btn", "next-slide-btn"], ["fill", "clear", 1, "slide-btn", "prev-slide-btn"], ["vertical", "bottom", "horizontal", "center", "slot", "fixed", 2, "bottom", "30px"], ["appButtonTouch", "", "id", "new-note-button", "color", "light", 3, "click"], [3, "src"]], template: function SiteUserFeedComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-content", 0);
        i0.ɵɵelementStart(1, "ion-refresher", 1);
        i0.ɵɵlistener("ionRefresh", function SiteUserFeedComponent_Template_ion_refresher_ionRefresh_1_listener($event) { return ctx.doRefreshContent($event); });
        i0.ɵɵelement(2, "ion-refresher-content");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(3, SiteUserFeedComponent_div_3_Template, 4, 0, "div", 2);
        i0.ɵɵtemplate(4, SiteUserFeedComponent_div_4_Template, 9, 4, "div", 3);
        i0.ɵɵpipe(5, "async");
        i0.ɵɵelementStart(6, "ion-infinite-scroll", 4);
        i0.ɵɵlistener("ionInfinite", function SiteUserFeedComponent_Template_ion_infinite_scroll_ionInfinite_6_listener($event) { return ctx.loadData($event); });
        i0.ɵɵelement(7, "ion-infinite-scroll-content", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "div");
        i0.ɵɵtemplate(9, SiteUserFeedComponent_ion_button_9_Template, 1, 0, "ion-button", 6);
        i0.ɵɵtemplate(10, SiteUserFeedComponent_ion_button_10_Template, 1, 0, "ion-button", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(11, SiteUserFeedComponent_ion_fab_11_Template, 3, 1, "ion-fab", 8);
    } if (rf & 2) {
        i0.ɵɵproperty("scrollEvents", true);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.globals.activeFilters.isEmpty());
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(5, 6, ctx.feeds));
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngIf", ctx.homeFeed.isFeedEnabled || ctx.worldFeed.isFeedEnabled);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.worldFeed.isFeedEnabled || ctx.friendFeed.isFeedEnabled);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.globals.selectedUser.id === ctx.globals.owner.id);
    } }, directives: [i7.IonContent, i7.IonRefresher, i7.IonRefresherContent, i11.NgIf, i11.NgForOf, i7.IonInfiniteScroll, i7.IonInfiniteScrollContent, i7.IonButton, i12.TranslateDirective, i13.ProfileHeaderComponent, i7.IonImg, i7.IonSpinner, i14.NoteListComponent, i7.IonFab, i7.IonFabButton, i15.ButtonTouchDirective], pipes: [i11.AsyncPipe], styles: [""] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SiteUserFeedComponent, [{
        type: Component,
        args: [{
                selector: 'app-site-user-feed',
                templateUrl: './site-user-feed.component.html',
                styleUrls: ['./site-user-feed.component.css']
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.ActivatedRoute }, { type: i4.SiteUserService }, { type: i5.NoteService }, { type: i6.EditorsService }, { type: i3.Router }, { type: i7.PopoverController }, { type: i8.ToolbarService }, { type: i9.SubscriptionService }, { type: i7.Events }, { type: i10.BdcWalkService }]; }, { profileHeader: [{
            type: ViewChild,
            args: ['profileHeader', { static: false }]
        }], content: [{
            type: ViewChild,
            args: [IonContent, { static: false }]
        }] }); })();
