import { Pipe, PipeTransform } from '@angular/core';
import {SiteUser} from "@app/modules/site-user/site-user";

@Pipe({
  name: 'userName',
  pure: false
})
export class UserNamePipe implements PipeTransform {

  transform(user: SiteUser): any {
    if (!user.firstName && !user.lastName) {
      return 'unknown';
    }

    if (user.firstName && !user.lastName) {
      return `${user.firstName}`;
    }

    if (!user.firstName && user.lastName) {
      return `${user.lastName}`;
    }

    return `${user.firstName} ${user.lastName}`;
  }

}
