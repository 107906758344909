import {Paginator} from "@app/shared/models/Paginator";
import {FeedType} from "@app/modules/home/FeedType";

export class NewFeed {

  public isFeedEnabled = false;
  public feedType: FeedType;
  public paginator = new Paginator();

  constructor(feedType: FeedType) {
    this.feedType = feedType;
  }

  openFeed() {
    this.isFeedEnabled = true;
  }

  closeFeed() {
    this.isFeedEnabled = false;
  }

}
