import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LanguagesList } from '@app/core/languages';
import { UtilsService } from '@app/shared/services/utils.service';
import { Globals } from '@app/shared/globals';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@app/shared/services/utils.service";
import * as i3 from "@app/shared/globals";
import * as i4 from "@angular/common";
const _c0 = function (a0) { return { "opacity": a0 }; };
function LangPickerDropDownComponent_ion_item_1_img_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "img", 8);
} if (rf & 2) {
    const menu_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(3, _c0, menu_r1.active ? "0.3" : 1))("src", "assets/icons/design_v4/" + menu_r1.icon, i0.ɵɵsanitizeUrl)("alt", menu_r1.name);
} }
function LangPickerDropDownComponent_ion_item_1_img_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "img", 9);
} if (rf & 2) {
    const menu_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("src", "assets/icons/design_v4/first_check.svg", i0.ɵɵsanitizeUrl)("alt", menu_r1.name);
} }
function LangPickerDropDownComponent_ion_item_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-item", 2);
    i0.ɵɵlistener("click", function LangPickerDropDownComponent_ion_item_1_Template_ion_item_click_0_listener() { const menu_r1 = ctx.$implicit; return menu_r1.callback(); });
    i0.ɵɵelementStart(1, "span", 3);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵtemplate(4, LangPickerDropDownComponent_ion_item_1_img_4_Template, 1, 5, "img", 5);
    i0.ɵɵelementStart(5, "span", 6);
    i0.ɵɵtemplate(6, LangPickerDropDownComponent_ion_item_1_img_6_Template, 1, 2, "img", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const menu_r1 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(4, _c0, menu_r1.active ? "0.3" : 1));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(menu_r1.name);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", menu_r1.icon);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", menu_r1.active);
} }
export class LangPickerDropDownComponent {
    constructor(popoverCtrl, utilsService, globals) {
        this.popoverCtrl = popoverCtrl;
        this.utilsService = utilsService;
        this.globals = globals;
        this.languageList = LanguagesList;
    }
    ngOnInit() {
        const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0];
        this.dataList = [...this.languageList.map(l => {
                return {
                    name: l.name,
                    active: lang.name === l.name,
                    icon: `langs/${l.name.toLocaleLowerCase()}.svg`,
                    callback: () => {
                        this.utilsService.switchLanguage(l.en);
                        this.popoverCtrl.dismiss(l);
                    }
                };
            })];
    }
}
LangPickerDropDownComponent.ɵfac = function LangPickerDropDownComponent_Factory(t) { return new (t || LangPickerDropDownComponent)(i0.ɵɵdirectiveInject(i1.PopoverController), i0.ɵɵdirectiveInject(i2.UtilsService), i0.ɵɵdirectiveInject(i3.Globals)); };
LangPickerDropDownComponent.ɵcmp = i0.ɵɵdefineComponent({ type: LangPickerDropDownComponent, selectors: [["app-lang-picker-drop-down"]], decls: 2, vars: 1, consts: [[2, "max-height", "340px", "overflow-x", "auto"], ["button", "", 3, "click", 4, "ngFor", "ngForOf"], ["button", "", 3, "click"], [3, "ngStyle"], ["slot", "end", 2, "display", "flex"], [3, "ngStyle", "src", "alt", 4, "ngIf"], [2, "width", "22px", "height", "22px"], ["style", "height: 22px; margin-left: 10px", 3, "src", "alt", 4, "ngIf"], [3, "ngStyle", "src", "alt"], [2, "height", "22px", "margin-left", "10px", 3, "src", "alt"]], template: function LangPickerDropDownComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-list", 0);
        i0.ɵɵtemplate(1, LangPickerDropDownComponent_ion_item_1_Template, 7, 6, "ion-item", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.dataList);
    } }, directives: [i1.IonList, i4.NgForOf, i1.IonItem, i4.NgStyle, i4.NgIf], styles: [""] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LangPickerDropDownComponent, [{
        type: Component,
        args: [{
                selector: 'app-lang-picker-drop-down',
                template: `
    <ion-list style="max-height: 340px; overflow-x: auto;">
      <ion-item
        *ngFor="let menu of dataList"
        (click)="menu.callback()"
        button>
        <span [ngStyle]="{'opacity': menu.active ? '0.3' : 1}">{{menu.name}}</span>


        <div slot="end" style="display: flex">
          <img
            *ngIf="menu.icon"
            [ngStyle]="{'opacity': menu.active ? '0.3' : 1}"
            [src]="'assets/icons/design_v4/' + menu.icon"
            [alt]="menu.name">

          <span style="width: 22px; height: 22px">
            <img
              style="height: 22px; margin-left: 10px"
              *ngIf="menu.active"
              [src]="'assets/icons/design_v4/first_check.svg'"
              [alt]="menu.name">
          </span>
        </div>

      </ion-item>
    </ion-list>
  `,
                styles: [``]
            }]
    }], function () { return [{ type: i1.PopoverController }, { type: i2.UtilsService }, { type: i3.Globals }]; }, null); })();
