import {Component, OnInit} from '@angular/core';
import {SiteUser} from '@app/modules/site-user/site-user';
import {ModalController} from '@ionic/angular';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {Page} from '@app/core/page';
import {SiteUserService} from '@app/shared/services/site-user.service';

@Component({
  selector: 'app-received-from-modal',
  templateUrl: './received-from-modal.component.html',
  styleUrls: ['./received-from-modal.component.css']
})
export class ReceivedFromModalComponent implements OnInit {

  friends: SiteUser[] = [];
  isLoading = true;
  searchValue = '';
  private pageData: Page<SiteUser>;

  constructor(
    public toolbarService: ToolbarService,
    public modalCtrl: ModalController,
    private userService: SiteUserService,
  ) {
  }

  ngOnInit() {
    this.searchUser('', 0);
  }

  searchUser(value: string, page: number, callback?: any) {
    this.isLoading = true;
    this.userService.getUsersBy(value, page, 2).subscribe((data: Page<SiteUser>) => {
      this.pageData = data;
      this.friends = page === 0 ? this.pageData.content : this.friends.concat(this.pageData.content);
      this.isLoading = false;
      if (callback) {
        callback();
      }
    });
  }

  doRefreshContent(event) {
    this.searchUser('', 0, () => {
      event.target.complete();
    });
  }

  nextPage(event?) {
    if (!this.pageData.last) {
      this.searchUser(this.searchValue, this.pageData.number + 1, () => {
        event.target.complete();
      });
    } else {
      if (event) {
        event.target.complete();
      }
    }
  }

  searchInputHandler(event) {
    this.searchValue = event;
    this.searchUser(this.searchValue, 0);
  }

  onItemClickHandler(friend) {
    this.modalCtrl.dismiss({
      friend: friend,
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      friend: null,
    });
  }
}
