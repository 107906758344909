import {Injectable} from '@angular/core';
import {AuthService, AuthServiceConfig, FacebookLoginProvider, SocialUser} from 'angularx-social-login';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../../shared/globals';
import {SiteUser} from '../../modules/site-user/site-user';
import {Router} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Facebook, FacebookLoginResponse} from '@ionic-native/facebook/ngx';
import {LoadingController, Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {TokensService} from '@app/shared/services/tokens.service';
import {ProfileService} from '@app/shared/services/profile.service';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {OneSignalService} from '@app/shared/services/one-signal.service';

@Injectable()
export class FacebookAuthService extends AuthService {

  constructor(
    config: AuthServiceConfig,
    public http: HttpClient,
    public globals: Globals,
    public router: Router,
    public storage: Storage,
    public platform: Platform,
    public fb: Facebook,
    private menuService: MenuService,
    private toolbarService: ToolbarService,
    public authenticationService: AuthenticationService,
    private tokensService: TokensService,
    private uiAlertService: UiAlertService,
    private profileService: ProfileService,
    private oneSignalService: OneSignalService,
    public loadingController: LoadingController) {
    super(config);
  }

  async authByFacebook(user: SocialUser, needRegister): Promise<any> {
    const loading = await this.loadingController.create({});
    await loading.present();
    const siteUser = new SiteUser();
    siteUser.firstName = user.firstName;
    siteUser.lastName = user.lastName;
    siteUser.email = user.email;
    siteUser.ssoId = user.id;
    siteUser.userPhotoName = user.photoUrl;
    this.globals.owner = siteUser;

    let params = new HttpParams();
    params = params.append('currentLanguage', this.globals.currentLanguage);
    params = params.append('needRegister', needRegister);
    params = params.append('deviceId', this.oneSignalService.getDeviceId());

    return this.http.post(this.globals.apiUrl + '/accounts/login/social', siteUser, {params})
      .toPromise()
      .then(async (response: SiteUser) => {
        await this.loadingController.dismiss();
        if (response !== null) {
          this.tokensService.setTokens(response.witaAuthToken);
          localStorage.setItem('wita-user', JSON.stringify(response));
          this.storage.set('wita-user', response);
          this.globals.owner = response as SiteUser;
          this.authenticationService.afterLoginInitiation();
        }
      }).catch(async (er) => {
        console.log('throw error after social login request inside facebook auth service');
        await this.loadingController.dismiss();
        throw er;
      });
  }

  accessWithFB(referrer: string, needRegister: boolean): void {
    if (this.platform.is('cordova')) {
      this.fb.login(['public_profile', 'email'])
        .then(async (user: FacebookLoginResponse) => {
          console.log('Logged into Facebook!', user);
          if (user !== null) {
            const fields = ['email', 'first_name', 'last_name', 'picture.type(normal)'].join(',');

            const userData: any = await this.http.get(
              `https://graph.facebook.com/${user.authResponse.userID}?access_token=${user.authResponse.accessToken}&fields=${fields}`)
              .toPromise();

            const userFb = {
              firstName: userData.first_name,
              lastName: userData.last_name,
              id: userData.id,
              email: userData.email,
              photoUrl: userData.picture.data.url
            };

            this.authByFacebook((<any> userFb), needRegister).then(res => {
              this.profileService.setReferrer(this.globals.owner, referrer);
              this.router.navigate(['/']);
              this.toolbarService.setHeaderVisibility(true);
              this.menuService.activeMainMenu();
            }).catch(async (er) => {
              await this.authenticationService.checkConfirmationRequired(er);
            });
          }
        }).catch(async (er) => {
        await this.authenticationService.checkConfirmationRequired(er);
      });
    } else {
      this.signIn(FacebookLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
        if (user !== null) {
          this.authByFacebook(user, needRegister).then(() => {
            this.profileService.setReferrer(this.globals.owner, referrer);
            this.router.navigate(['/']);
            this.toolbarService.setHeaderVisibility(true);
            this.menuService.activeMainMenu();
          }).catch(async (er) => {
            await this.authenticationService.checkConfirmationRequired(er);
          });
        }
      });
    }
  }
}
