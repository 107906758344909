import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Globals } from '../globals';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
export class NoteService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
        this.eventSource = new Subject();
        this.event$ = this.eventSource.asObservable();
    }
    newEvent(event) {
        this.eventSource.next(event);
    }
    getUserAllNotes(page) {
        let params = this.applyNoteFilters();
        params = params.append('page', page.toString());
        params = params.append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/notes/users/' + this.globals.selectedUser.id, {
            params: params,
            responseType: 'json'
        });
    }
    refreshNote() {
        this.getNoteByNoteId().subscribe((data) => {
            this.globals.currentNote = data;
        });
    }
    refreshNoteById(id, callback) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/notes/' + id, {
            params: params
        }).subscribe((data) => {
            this.globals.currentNote = data;
            return callback && callback(data);
        });
    }
    getUserNoteListByCategory(catId, page) {
        let params = this.applyNoteFilters();
        params = params.append('page', page.toString());
        params = params.append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/notes/categories/' + catId + '/users/' + this.globals.selectedUser.id, {
            params: params,
            responseType: 'json'
        });
    }
    getNoteByNoteId() {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/notes/' + this.globals.currentNote.id, {
            params: params,
        });
    }
    getNoteById(id) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(`${this.globals.apiUrl}/notes/${id}`, {
            params: params,
        });
    }
    addNote(newNote, callback) {
        this.http.post(this.globals.apiUrl + '/notes/', newNote).subscribe(() => {
            return callback && callback();
        });
    }
    deleteNote(currentNote, callback) {
        this.http.delete(this.globals.apiUrl + '/notes/' + currentNote.id).subscribe(() => {
            return callback && callback();
        });
    }
    editNote(currentNote, callback) {
        this.http.put(this.globals.apiUrl + '/notes/', currentNote).subscribe(() => {
            return callback && callback();
        });
    }
    applyNoteFilters() {
        let params = new HttpParams();
        if (!this.globals.activeFilters.isEmpty()) {
            params = this.applyNoteFiltersForFeed();
            if (this.globals.owner.id === this.globals.selectedUser.id && !this.globals.activeFilters.accessFilter.isEmpty()) {
                params = params.append('access-filter', this.globals.activeFilters.accessFilter.getNumberValue().toString());
            }
        }
        return params;
    }
    applyNoteFiltersForFeed() {
        let params = new HttpParams();
        if (!this.globals.activeFilters.isEmpty()) {
            if (!this.globals.activeFilters.dateFilter.isEmpty()) {
                params = params.append('date-to-filter', new Date(this.globals.activeFilters.dateFilter.dateTo).toISOString());
                params = params.append('date-from-filter', new Date(this.globals.activeFilters.dateFilter.dateFrom).toISOString());
            }
            if (!this.globals.activeFilters.weightFilter.isEmpty()) {
                params = params.append('weight-filter', this.globals.activeFilters.weightFilter.weight.toString());
            }
        }
        return params;
    }
    canCurrentUserEditNote(note) {
        return this.globals.isAuthenticated && note.owner.id === this.globals.owner.id;
    }
    getFriendsNotes(page = 0) {
        let params = this.applyNoteFiltersForFeed();
        params = params.append('currentLang', this.globals.currentLanguage);
        params = params.append('page', page.toString());
        return this.http.get(`${this.globals.apiUrl}/notes/feed/friends`, { params });
    }
    getNotes(page = 0) {
        let params = this.applyNoteFiltersForFeed();
        params = params.append('currentLang', this.globals.currentLanguage);
        params = params.append('page', page.toString());
        return this.http.get(`${this.globals.apiUrl}/notes/feed/world`, { params });
    }
    getFriendNoteListByCategory(catId, page) {
        let params = this.applyNoteFiltersForFeed();
        params = params.append('page', page.toString());
        params = params.append('currentUserId', this.globals.owner.id.toString());
        params = params.append('currentLang', this.globals.currentLanguage);
        return this.http.get(`${this.globals.apiUrl}/notes/feed/friends/categories/${this.globals.currentCategoryId.toString()}`, {
            params,
            responseType: 'json'
        });
    }
    getNoteListByCategory(catId, page) {
        let params = this.applyNoteFiltersForFeed();
        params = params.append('page', page.toString());
        params = params.append('currentLang', this.globals.currentLanguage);
        return this.http.get(`${this.globals.apiUrl}/notes/feed/world/categories/${this.globals.currentCategoryId.toString()}`, {
            params,
            responseType: 'json'
        });
    }
}
NoteService.ɵfac = function NoteService_Factory(t) { return new (t || NoteService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
NoteService.ɵprov = i0.ɵɵdefineInjectable({ token: NoteService, factory: NoteService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NoteService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
