import { Injectable } from '@angular/core';
import { Globals } from '@app/shared/globals';
import { Tokens } from '@app/shared/models/tokens';
import * as WebStomp from 'webstomp-client';
import * as SockJS from 'sockjs-client';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
import * as i3 from "@app/shared/services/toolbar/toolbar.service";
import * as i4 from "@angular/router";
export class NotificationService {
    constructor(http, globals, toolbarService, router) {
        this.http = http;
        this.globals = globals;
        this.toolbarService = toolbarService;
        this.router = router;
        this.webSocketEndPoint = this.globals.apiUrl + '/wss';
        this.topic = '/user/topic/notify/';
        this.unreadReceivedNotificationsId = [];
    }
    connect() {
        console.log('connecting WS');
        const tokens = new Tokens(JSON.parse(localStorage.getItem('tokens')));
        const ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = WebStomp.over(ws);
        this.stompClient.debug = () => {
        };
        this.stompClient.connect({ Authorization: tokens.jwtToken }, frame => {
            console.log('connected WS');
            this.stompClient.subscribe(this.topic, sdkEvent => {
                this.onMessageReceived(sdkEvent);
            });
        }, function (e) {
            console.error(e, 'Reconnecting WS', this.webSocketEndPoint);
            setTimeout(() => {
                if (this.globals.isAuthenticated) {
                    this.connect();
                }
            }, 5000);
        }.bind(this));
    }
    disconnect() {
        if (this.stompClient !== null && this.stompClient !== undefined && this.stompClient.connected) {
            this.stompClient.disconnect();
        }
    }
    addToReceivedNotifications(notificationsArray) {
        for (const notification of notificationsArray) {
            if (notification.unread) {
                this.unreadReceivedNotificationsId.push(notification.id);
            }
        }
        this.readNotifications();
    }
    readNotifications() {
        if (this.stompClient !== undefined && this.unreadReceivedNotificationsId.length > 0) {
            this.stompClient.send('/app/notifications/read', JSON.stringify(this.unreadReceivedNotificationsId));
            this.globals.unreadNotificationCount = this.globals.unreadNotificationCount - this.unreadReceivedNotificationsId.length;
            if (this.globals.unreadNotificationCount < 0) {
                this.globals.unreadNotificationCount = 0;
            }
            this.unreadReceivedNotificationsId = [];
        }
    }
    onMessageReceived(notification) {
        let receivedNotification;
        receivedNotification = JSON.parse(notification.body);
        this.unreadReceivedNotificationsId.push(receivedNotification.id);
        this.receivedNotificationAction(receivedNotification);
        if (this.router.url === '/notifications') {
            this.readNotifications();
        }
    }
    receivedNotificationAction(notification) {
        if (this.globals.userNotifications !== undefined) {
            this.globals.userNotifications.unshift(notification);
        }
        if (notification.type === '1') {
            this.globals.unreadMessagesCount = this.globals.unreadMessagesCount + 1;
        }
        this.globals.notificationCount = this.globals.notificationCount + 1;
        this.globals.unreadNotificationCount = this.globals.unreadNotificationCount + 1;
        this.toolbarService.refreshMenuItems();
    }
    checkLocalUnreadNotifications() {
        this.globals.userNotifications.filter((item) => {
            if (item.unread) {
                item.unread = false;
            }
        });
    }
    getNotifications(page) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        return this.http.get(this.globals.apiUrl + '/notifications', {
            params: params,
        });
    }
    countUnreadNotifications() {
        return this.http.get(this.globals.apiUrl + '/notifications/count');
    }
    removeNotification(id, callback) {
        this.http.delete(this.globals.apiUrl + '/notifications/' + id).subscribe(() => {
            if (callback) {
                return callback && callback();
            }
        });
    }
}
NotificationService.ɵfac = function NotificationService_Factory(t) { return new (t || NotificationService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.ToolbarService), i0.ɵɵinject(i4.Router)); };
NotificationService.ɵprov = i0.ɵɵdefineInjectable({ token: NotificationService, factory: NotificationService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NotificationService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.ToolbarService }, { type: i4.Router }]; }, null); })();
