import { Component } from '@angular/core';
import { VotingQuestion } from "@app/modules/voting-question/voting-question";
import { HttpClient } from "@angular/common/http";
import { ToolbarService } from "@app/shared/services/toolbar/toolbar.service";
import { VotingService } from "@app/shared/services/voting-service";
import { Globals } from "@app/shared/globals";
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/services/toolbar/toolbar.service";
import * as i3 from "@app/shared/services/voting-service";
import * as i4 from "@app/shared/globals";
import * as i5 from "@angular/router";
import * as i6 from "@ionic/angular";
import * as i7 from "@angular/common";
import * as i8 from "@angular/forms";
import * as i9 from "@ngx-translate/core";
function VotingPanelComponent_ion_item_3_ion_button_8_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-button", 14);
    i0.ɵɵlistener("click", function VotingPanelComponent_ion_item_3_ion_button_8_Template_ion_button_click_0_listener() { i0.ɵɵrestoreView(_r6); const item_r1 = i0.ɵɵnextContext().$implicit; const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.updateVotingQuestion(item_r1); });
    i0.ɵɵelement(1, "ion-icon", 15);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("dis", ctx_r2.id !== item_r1.id);
} }
function VotingPanelComponent_ion_item_3_ion_button_9_Template(rf, ctx) { if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-button", 14);
    i0.ɵɵlistener("click", function VotingPanelComponent_ion_item_3_ion_button_9_Template_ion_button_click_0_listener() { i0.ɵɵrestoreView(_r9); const ctx_r8 = i0.ɵɵnextContext(2); ctx_r8.isEditing = false; return ctx_r8.id = null; });
    i0.ɵɵelement(1, "ion-icon", 16);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("dis", ctx_r3.id !== item_r1.id);
} }
function VotingPanelComponent_ion_item_3_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-item");
    i0.ɵɵelementStart(1, "ion-button", 6);
    i0.ɵɵlistener("click", function VotingPanelComponent_ion_item_3_Template_ion_button_click_1_listener() { i0.ɵɵrestoreView(_r12); const item_r1 = ctx.$implicit; const ctx_r11 = i0.ɵɵnextContext(); return ctx_r11.makeImportant(item_r1); });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "ion-col", 7);
    i0.ɵɵelementStart(4, "ion-input", 8);
    i0.ɵɵlistener("ngModelChange", function VotingPanelComponent_ion_item_3_Template_ion_input_ngModelChange_4_listener($event) { i0.ɵɵrestoreView(_r12); const ctx_r13 = i0.ɵɵnextContext(); return ctx_r13.questionText = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "ion-col", 9);
    i0.ɵɵlistener("click", function VotingPanelComponent_ion_item_3_Template_ion_col_click_5_listener() { i0.ɵɵrestoreView(_r12); const item_r1 = ctx.$implicit; const ctx_r14 = i0.ɵɵnextContext(); return ctx_r14.changeData(item_r1); });
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "div", 10);
    i0.ɵɵtemplate(8, VotingPanelComponent_ion_item_3_ion_button_8_Template, 2, 2, "ion-button", 11);
    i0.ɵɵtemplate(9, VotingPanelComponent_ion_item_3_ion_button_9_Template, 2, 2, "ion-button", 11);
    i0.ɵɵelementStart(10, "ion-button", 12);
    i0.ɵɵlistener("click", function VotingPanelComponent_ion_item_3_Template_ion_button_click_10_listener() { i0.ɵɵrestoreView(_r12); const item_r1 = ctx.$implicit; const ctx_r15 = i0.ɵɵnextContext(); return ctx_r15.deleteVotingQuestion(item_r1.id); });
    i0.ɵɵelement(11, "ion-icon", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("color", ctx_r0.importantQuestion(item_r1));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", item_r1.id, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵclassProp("dis", ctx_r0.id !== item_r1.id);
    i0.ɵɵpropertyInterpolate("id", item_r1.id);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngModel", ctx_r0.questionText);
    i0.ɵɵadvance(1);
    i0.ɵɵclassProp("dis", ctx_r0.id === item_r1.id);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" \"", item_r1.questionText, "\" ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.isEditing);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.isEditing);
} }
export class VotingPanelComponent {
    constructor(http, toolbarService, votingService, globals, router) {
        this.http = http;
        this.toolbarService = toolbarService;
        this.votingService = votingService;
        this.globals = globals;
        this.router = router;
        this.question = "";
        this.idList = [];
        this.questionText = "";
        this.isEditing = false;
        this.headerActions = [
            {
                title: 'Back',
                path: '/admin',
                icon: 'assets/icons/design_v4/back_icon.svg',
            },
            {
                title: 'Messages',
                path: '/chat',
                icon: 'assets/icons/design_v4/messages.svg'
            }
        ];
    }
    ngOnInit() {
        this.updateQuestions();
    }
    updateQuestions() {
        this.idList = [];
        this.questionsList = [];
        this.votingService.getAllQuestions().subscribe((res) => {
            this.questionsList = res.sort((a, b) => {
                if (a.id > b.id) {
                    return 1;
                }
                else {
                    return -1;
                }
            });
            for (let obj of res) {
                this.idList.push(obj.id);
            }
        });
        this.question = "";
        this.id = null;
    }
    updateNavigation() {
        this.toolbarService.setMenuItems(this.headerActions);
    }
    addQuestionToVotingQuestions() {
        let votingQuestion = new VotingQuestion();
        votingQuestion.questionText = this.question;
        votingQuestion.important = false;
        this.votingService.sendQuestionToVoting(votingQuestion).subscribe(() => {
            this.updateQuestions();
        });
        this.question = "";
    }
    deleteVotingQuestion(id) {
        this.votingService.deleteQuestionById(id).subscribe(() => {
            this.updateQuestions();
        });
    }
    changeData(item) {
        this.isEditing = true;
        this.id = item.id;
        this.questionText = item.questionText;
    }
    updateVotingQuestion(question) {
        question.questionText = this.questionText;
        this.votingService.editQuestion(question).subscribe(() => {
            this.updateQuestions();
        });
        this.id = null;
        this.questionText = "";
        this.isEditing = false;
    }
    importantQuestion(question) {
        if (question.important) {
            return 'warning';
        }
        return 'white';
    }
    makeImportant(question) {
        question.important = !question.important;
        this.votingService.editQuestion(question).subscribe(() => {
            this.updateQuestions();
        });
    }
    canActivate() {
        let isActive = true;
        if (!this.globals.isAdmin) {
            this.router.navigate(['/']);
            isActive = false;
        }
        return isActive;
    }
}
VotingPanelComponent.ɵfac = function VotingPanelComponent_Factory(t) { return new (t || VotingPanelComponent)(i0.ɵɵdirectiveInject(i1.HttpClient), i0.ɵɵdirectiveInject(i2.ToolbarService), i0.ɵɵdirectiveInject(i3.VotingService), i0.ɵɵdirectiveInject(i4.Globals), i0.ɵɵdirectiveInject(i5.Router)); };
VotingPanelComponent.ɵcmp = i0.ɵɵdefineComponent({ type: VotingPanelComponent, selectors: [["app-voting-panel"]], decls: 10, vars: 3, consts: [[1, "voting-panel"], [1, "question-list"], [4, "ngFor", "ngForOf"], ["rows", "1", "cols", "20", "placeholder", "Enter question here...", 3, "ngModel", "ngModelChange"], ["item-end", "", 1, "item-note"], ["expand", "block", "translate", "", 1, "form__button__text", 3, "disabled", "click"], [3, "color", "click"], [3, "id"], ["autofocus", "true", 1, "question-textarea", 3, "ngModel", "ngModelChange"], [3, "click"], ["item-end", ""], ["class", "form__button__text", "translate", "", 3, "dis", "click", 4, "ngIf"], ["color", "danger", "translate", "", 1, "form__button__text", 3, "click"], ["name", "trash"], ["translate", "", 1, "form__button__text", 3, "click"], ["name", "checkmark"], ["name", "close"]], template: function VotingPanelComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "ion-col");
        i0.ɵɵelementStart(2, "ion-list", 1);
        i0.ɵɵtemplate(3, VotingPanelComponent_ion_item_3_Template, 12, 11, "ion-item", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "ion-col");
        i0.ɵɵelementStart(5, "ion-item");
        i0.ɵɵelementStart(6, "ion-textarea", 3);
        i0.ɵɵlistener("ngModelChange", function VotingPanelComponent_Template_ion_textarea_ngModelChange_6_listener($event) { return ctx.question = $event; });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 4);
        i0.ɵɵelementStart(8, "ion-button", 5);
        i0.ɵɵlistener("click", function VotingPanelComponent_Template_ion_button_click_8_listener() { return ctx.addQuestionToVotingQuestions(); });
        i0.ɵɵtext(9, " Add ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngForOf", ctx.questionsList);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngModel", ctx.question);
        i0.ɵɵadvance(2);
        i0.ɵɵpropertyInterpolate("disabled", ctx.question.length < 5);
    } }, directives: [i6.IonCol, i6.IonList, i7.NgForOf, i6.IonItem, i6.IonTextarea, i6.TextValueAccessor, i8.NgControlStatus, i8.NgModel, i6.IonButton, i9.TranslateDirective, i6.IonInput, i7.NgIf, i6.IonIcon], styles: ["ion-row[_ngcontent-%COMP%]{justify-content:center}ion-row[_ngcontent-%COMP%]   .question-textarea[_ngcontent-%COMP%]{padding-right:5px}.question-list[_ngcontent-%COMP%]{width:100%;overflow-y:auto;word-wrap:break-word;word-break:break-all;max-height:60%}.form__button__text[_ngcontent-%COMP%]{color:#fff;align-self:center}.dis[_ngcontent-%COMP%]{display:none}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(VotingPanelComponent, [{
        type: Component,
        args: [{
                selector: 'app-voting-panel',
                templateUrl: './voting-panel.component.html',
                styleUrls: ['./voting-panel.component.scss']
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.ToolbarService }, { type: i3.VotingService }, { type: i4.Globals }, { type: i5.Router }]; }, null); })();
