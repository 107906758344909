import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { NoteService } from './note.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
import * as i3 from "./note.service";
export class NoteCommentService {
    constructor(http, globals, noteService) {
        this.http = http;
        this.globals = globals;
        this.noteService = noteService;
    }
    refreshCommentList() {
        this.noteService.refreshNote();
    }
    addComment(newComment, callback) {
        this.http.post(this.globals.apiUrl + '/notes/comments/', newComment).subscribe(() => {
            return callback && callback();
        });
    }
    deleteComment(currentComment, callback) {
        this.http.delete(this.globals.apiUrl + '/notes/comments/' + currentComment.id).subscribe(() => {
            return callback && callback();
        });
    }
    editComment(currentComment, callback) {
        this.http.put(this.globals.apiUrl + '/notes/comments/', currentComment).subscribe(() => {
            return callback && callback();
        });
    }
    getCommentCounts(note) {
        let count = 0;
        count = note.comments.length;
        let comment;
        for (comment of note.comments) {
            count = count + comment.childComments.length;
        }
        return count;
    }
}
NoteCommentService.ɵfac = function NoteCommentService_Factory(t) { return new (t || NoteCommentService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.NoteService)); };
NoteCommentService.ɵprov = i0.ɵɵdefineInjectable({ token: NoteCommentService, factory: NoteCommentService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NoteCommentService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.NoteService }]; }, null); })();
