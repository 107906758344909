import { Directive, ElementRef } from '@angular/core';
import * as i0 from "@angular/core";
export class CustomSelectDirective {
    constructor(el) {
        this.el = el;
    }
    ngAfterViewInit() {
        const style = document.createElement('style');
        style.innerHTML = '' +
            'div.item-native, button, div.item-inner { padding: 0; min-height: auto; border: none;}' +
            ':host {--min-height: 22px;}' +
            'div.select-icon {width: 20px}' +
            '.select-icon {transform: rotate(180deg);}' +
            'div.select-placeholder {opacity: 1}' +
            'item-inner-highlight { height: auto}';
        this.el.nativeElement.shadowRoot.appendChild(style);
    }
}
CustomSelectDirective.ɵfac = function CustomSelectDirective_Factory(t) { return new (t || CustomSelectDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
CustomSelectDirective.ɵdir = i0.ɵɵdefineDirective({ type: CustomSelectDirective, selectors: [["", "appCustomSelect", ""]] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomSelectDirective, [{
        type: Directive,
        args: [{
                selector: '[appCustomSelect]'
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, null); })();
