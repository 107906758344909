import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor() {
  }

  unSubscriber(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

}
