import {Component, Input, OnInit} from '@angular/core';
import {NoteComment} from '../note-comment/note-comment';
import {Note} from "@app/modules/note/note";

@Component({
  selector: 'app-child-note-comment-list',
  templateUrl: './child-note-comment-list.component.html',
  styleUrls: ['./child-note-comment-list.component.css']
})
export class ChildNoteCommentListComponent implements OnInit {

  @Input() passCurrentParentComment: NoteComment;
  @Input() noteEntity: Note;
  @Input() translationsText: string[];

  constructor() {
  }

  ngOnInit() {
  }

  deletedComment(comment: NoteComment) {
    this.passCurrentParentComment.childComments.splice(this.passCurrentParentComment.childComments.findIndex((item: NoteComment) => item.id === comment.id), 1);
  }
}
