import { Component } from '@angular/core';
import { UtilsService } from "../../services/utils.service";
import { faUndo, faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { Platform } from "@ionic/angular";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "../../services/utils.service";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/common";
import * as i5 from "ngx-image-cropper";
function ImageCropComponent_image_cropper_9_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "image-cropper", 8);
    i0.ɵɵlistener("transformChange", function ImageCropComponent_image_cropper_9_Template_image_cropper_transformChange_0_listener($event) { i0.ɵɵrestoreView(_r2); const ctx_r1 = i0.ɵɵnextContext(); return ctx_r1.transform = $event; })("imageCropped", function ImageCropComponent_image_cropper_9_Template_image_cropper_imageCropped_0_listener($event) { i0.ɵɵrestoreView(_r2); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.imageCropped($event); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("maintainAspectRatio", true)("imageQuality", 75)("canvasRotation", ctx_r0.rotate)("aspectRatio", ctx_r0.aspectRatio)("imageBase64", ctx_r0.component.img)("roundCropper", ctx_r0.roundCropper)("transform", ctx_r0.transform);
} }
export class ImageCropComponent {
    constructor(platform, utilsService) {
        this.platform = platform;
        this.utilsService = utilsService;
        this.rotate = 0;
        this.roundCropper = false;
        this.transform = {
            scale: 1,
            rotate: 0
        };
        this.faUndo = faUndo;
        this.faSearchMinus = faSearchMinus;
        this.faSearchPlus = faSearchPlus;
    }
    ngOnInit() {
        this.component = this;
        if (this.platform.is('cordova')) {
            this.component.img = "data:image/jpeg;base64, " + this.component.img;
        }
        if (this.component.cantResize) {
            this.aspectRatio = 1;
            this.roundCropper = true;
        }
        else {
            this.aspectRatio = 6 / 2;
            this.roundCropper = false;
        }
    }
    actions(action) {
        switch (action) {
            case 'left':
                this.transform = Object.assign(Object.assign({}, this.transform), { rotate: this.transform.rotate + 90 });
                break;
            case 'right':
                this.transform = Object.assign(Object.assign({}, this.transform), { rotate: this.transform.rotate - 90 });
                break;
            case 'zoom-in':
                this.transform = Object.assign(Object.assign({}, this.transform), { scale: this.transform.scale + 0.1 });
                break;
            case 'zoom-out':
                this.transform = Object.assign(Object.assign({}, this.transform), { scale: this.transform.scale - 0.1 });
                break;
        }
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    save() {
        const block = this.croppedImage.split(";");
        const contentType = block[0].split(":")[1];
        const realData = block[1].split(",")[1];
        this.component.callback(this.utilsService.b64toBlob(realData, contentType, 512));
    }
    segmentChanged(ev) {
        switch (ev.detail.value) {
            case 'save':
                this.save();
                break;
            case 'cancel':
                this.component.dismiss();
        }
    }
}
ImageCropComponent.ɵfac = function ImageCropComponent_Factory(t) { return new (t || ImageCropComponent)(i0.ɵɵdirectiveInject(i1.Platform), i0.ɵɵdirectiveInject(i2.UtilsService)); };
ImageCropComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ImageCropComponent, selectors: [["app-image-crop"]], decls: 17, vars: 5, consts: [[3, "click"], [3, "icon"], [2, "transform", "rotate(180deg)", 3, "icon"], ["format", "jpeg", 3, "maintainAspectRatio", "imageQuality", "canvasRotation", "aspectRatio", "imageBase64", "roundCropper", "transform", "transformChange", "imageCropped", 4, "ngIf"], [3, "ionChange"], ["value", "save"], [2, "margin-bottom", "0"], ["value", "cancel"], ["format", "jpeg", 3, "maintainAspectRatio", "imageQuality", "canvasRotation", "aspectRatio", "imageBase64", "roundCropper", "transform", "transformChange", "imageCropped"]], template: function ImageCropComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-segment");
        i0.ɵɵelementStart(1, "ion-segment-button", 0);
        i0.ɵɵlistener("click", function ImageCropComponent_Template_ion_segment_button_click_1_listener() { return ctx.actions("left"); });
        i0.ɵɵelement(2, "fa-icon", 1);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "ion-segment-button", 0);
        i0.ɵɵlistener("click", function ImageCropComponent_Template_ion_segment_button_click_3_listener() { return ctx.actions("right"); });
        i0.ɵɵelement(4, "fa-icon", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "ion-segment-button", 0);
        i0.ɵɵlistener("click", function ImageCropComponent_Template_ion_segment_button_click_5_listener() { return ctx.actions("zoom-in"); });
        i0.ɵɵelement(6, "fa-icon", 1);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "ion-segment-button", 0);
        i0.ɵɵlistener("click", function ImageCropComponent_Template_ion_segment_button_click_7_listener() { return ctx.actions("zoom-out"); });
        i0.ɵɵelement(8, "fa-icon", 1);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(9, ImageCropComponent_image_cropper_9_Template, 1, 7, "image-cropper", 3);
        i0.ɵɵelementStart(10, "ion-segment", 4);
        i0.ɵɵlistener("ionChange", function ImageCropComponent_Template_ion_segment_ionChange_10_listener($event) { return ctx.segmentChanged($event); });
        i0.ɵɵelementStart(11, "ion-segment-button", 5);
        i0.ɵɵelementStart(12, "ion-label", 6);
        i0.ɵɵtext(13, "Save");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(14, "ion-segment-button", 7);
        i0.ɵɵelementStart(15, "ion-label", 6);
        i0.ɵɵtext(16, "Cancel");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("icon", ctx.faUndo);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("icon", ctx.faUndo);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("icon", ctx.faSearchPlus);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("icon", ctx.faSearchMinus);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.component.img);
    } }, directives: [i1.IonSegment, i1.SelectValueAccessor, i1.IonSegmentButton, i3.FaIconComponent, i4.NgIf, i1.IonLabel, i5.ImageCropperComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ImageCropComponent, [{
        type: Component,
        args: [{
                selector: 'app-image-crop',
                template: `
    <ion-segment>
      <ion-segment-button (click)="actions('left')">
        <fa-icon [icon]="faUndo"></fa-icon>
      </ion-segment-button>
      <ion-segment-button (click)="actions('right')">
        <fa-icon style="transform: rotate(180deg);" [icon]="faUndo"></fa-icon>
      </ion-segment-button>
      <ion-segment-button (click)="actions('zoom-in')">
        <fa-icon [icon]="faSearchPlus"></fa-icon>
      </ion-segment-button>
      <ion-segment-button (click)="actions('zoom-out')">
        <fa-icon [icon]="faSearchMinus"></fa-icon>
      </ion-segment-button>
    </ion-segment>

    <image-cropper
      *ngIf="component.img"
      [maintainAspectRatio]="true"
      [imageQuality]="75"
      [canvasRotation]="rotate"
      [aspectRatio]="aspectRatio"
      [imageBase64]="component.img"
      [roundCropper]="roundCropper"
      [(transform)]="transform"
      (imageCropped)="imageCropped($event)"
      format="jpeg">
    </image-cropper>


    <ion-segment (ionChange)="segmentChanged($event)">
      <ion-segment-button value="save">
        <ion-label style="margin-bottom: 0">Save</ion-label>
      </ion-segment-button>
      <ion-segment-button value="cancel">
        <ion-label style="margin-bottom: 0">Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>

  `,
                styleUrls: []
            }]
    }], function () { return [{ type: i1.Platform }, { type: i2.UtilsService }]; }, null); })();
