<ion-menu
        *ngIf="globals.owner"
        contentId="content"
        max-edge-start="35"
        menuId="main-menu"
        id="main-menu"
        side="start"
        type="push">
    <ion-content color="primary">
        <ion-list>
            <ion-menu-toggle auto-hide="false">
                <ion-item
                        [ngStyle]="{'padding-top': toolbarService.isNewIphone ? '30px' : '0'}"
                        color="primary"
                        routerLink="profile"
                        routerDirection="forward"
                        class="user-menu-item">
                    <div class="avatar">
                        <img [src]="globals.owner.userPhotoName" />
                    </div>
                    <div class="user-data-column">
                        <p class="user-fullname" id="fullname">
                            {{ globals.owner | userName }}
                        </p>
                        <p class="user-ssoid">
                            {{globals.owner.ssoId}}
                        </p>
                    </div>
                </ion-item>
            </ion-menu-toggle>

            <ng-container *ngIf="globals.isAdmin; else noAdminPage">
                <ion-item color="primary"
                        *ngFor="let p of pagesForAdmin"
                        [routerLink]="p.url"
                          routerDirection="forward"
                          class="menu-item"
                        (click)="p.action ? p.action() : null">
                    <div class="icons-column">
                        <img class="menu-icon" [src]="p.icon"/>
                    </div>
                    <p class="menu-title" translate>{{ p.title }}</p>
                </ion-item>
            </ng-container>

            <ng-template #noAdminPage>
                <ng-container *ngIf="globals.isEditor; else noEditorPages">
                    <ion-item color="primary"
                            *ngFor="let p of pagesForEditor"
                            [routerLink]="p.url"
                              routerDirection="forward"
                              class="menu-item"
                            (click)="p.action ? p.action() : null">
                        <div class="icons-column">
                            <img class="menu-icon" [src]="p.icon"/>
                        </div>
                        <p class="menu-title" translate>{{ p.title }}</p>
                    </ion-item>
                </ng-container>
            </ng-template>

            <ng-template #noEditorPages>
                <ion-item color="primary"
                        *ngFor="let p of pages"
                        [routerLink]="p.url"
                          routerDirection="forward"
                          class="menu-item"
                        (click)="p.action ? p.action() : null">
                    <div class="icons-column">
                        <img class="menu-icon" [src]="p.icon"/>
                    </div>
                    <p class="menu-title" translate>{{ p.title }}</p>
                </ion-item>
            </ng-template>

        </ion-list>
    </ion-content>
</ion-menu>
