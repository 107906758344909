<div class="filter mb-s" *ngIf="showFilter">
  <ion-item>
    <ion-label translate>Sort by</ion-label>
    <ion-select
      [(ngModel)]="currentFilterType"
      (ionCancel)="currentFilterType = null; sortBy('recent')"
      (ionChange)="sortBy($event.detail.value)"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet">
      <ion-select-option
        *ngFor="let item of filterBy"
        style="text-transform: capitalize;"
        value="{{item | translate}}"
        translate>{{item}}</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item button (click)="presentReceivedFrom()"  class="list-inputs__location">
    <ion-label translate>Received from</ion-label>
    <ion-input
            readonly="true"
            class="ion-text-right"
            style="text-transform: capitalize;"
            value="{{this.friend === null? '' : friend.ssoId | translate}}"
            type="search">
    </ion-input>
  </ion-item>

  <ion-item class="list-inputs__location">
    <ion-label
      class="list-inputs__label" translate>
      Search message
    </ion-label>
    <ion-input
      class="ion-text-right"
      (ionChange)="filterByKeyword($event.detail.value)"
      type="search">
    </ion-input>
  </ion-item>

</div>

<app-friends-requests></app-friends-requests>

  <div *ngFor="let item of filteredChatList; let i = index" >

    <div
      *ngIf="canViewDivider(i)"
      class="divider">
      <div></div>
      <span>{{dividerText(i)}}</span>
      <div></div>
    </div>

    <div
      (click)="setActiveDialogue(i)"
      [ngClass]="{'chat-item--active' : item.unreadCount > 0}"
      class="chat-item">
      <img
        class="chat-item__avatar"
        src="{{
            item.firstUser.id === globals.owner.id
              ? item.secondUser.userPhotoName
              : item.firstUser.userPhotoName
          }}">
      <div class="chat-item__text">
        <h4 class="chat-item__text chat-item__text--title">
          {{
          item.firstUser.id === globals.owner.id
          ? (item.secondUser | userName)
          : (item.firstUser | userName)
          }}
          <span class="chat-item__text__time" slot="end">
            {{item.lastMessageDto ? dateService.fromNow(item.lastMessageDto.updateDate) : ''}}
          </span>
        </h4>
        <p
          [ngClass]="{'chat-item__text--active' : item.unreadCount > 0}"
          class="chat-item__text chat-item__text--preview"
          style="-webkit-box-orient: vertical;">
          {{item.lastMessageDto ? item.lastMessageDto.content : ''}}
        </p>
      </div>
    </div>
  </div>

