import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToolbarService } from "@app/shared/services/toolbar/toolbar.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@app/shared/services/toolbar/toolbar.service";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
function AuthHeaderComponent_ion_buttons_5_ion_button_1_img_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "img", 9);
} if (rf & 2) {
    const btn_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("src", "assets/icons/design_v4/" + btn_r2.icon, i0.ɵɵsanitizeUrl)("alt", btn_r2.name);
} }
function AuthHeaderComponent_ion_buttons_5_ion_button_1_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-button", 7);
    i0.ɵɵlistener("click", function AuthHeaderComponent_ion_buttons_5_ion_button_1_Template_ion_button_click_0_listener($event) { i0.ɵɵrestoreView(_r6); const btn_r2 = ctx.$implicit; return btn_r2.callback($event); });
    i0.ɵɵtemplate(1, AuthHeaderComponent_ion_buttons_5_ion_button_1_img_1_Template, 1, 2, "img", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const btn_r2 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", btn_r2.icon);
} }
function AuthHeaderComponent_ion_buttons_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-buttons", 5);
    i0.ɵɵtemplate(1, AuthHeaderComponent_ion_buttons_5_ion_button_1_Template, 2, 1, "ion-button", 6);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.endBtns);
} }
const _c0 = function (a0) { return { "padding-top": a0 }; };
export class AuthHeaderComponent {
    constructor(router, toolbarService) {
        this.router = router;
        this.toolbarService = toolbarService;
    }
    backBtnHandler() {
        this.router.navigate(['auth']);
    }
    ngOnInit() {
    }
}
AuthHeaderComponent.ɵfac = function AuthHeaderComponent_Factory(t) { return new (t || AuthHeaderComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.ToolbarService)); };
AuthHeaderComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AuthHeaderComponent, selectors: [["app-auth-header"]], inputs: { title: "title", endBtns: "endBtns" }, decls: 6, vars: 6, consts: [["color", "primary", 1, "auth-header", 3, "ngStyle"], ["slot", "start", 2, "margin-left", "15px"], [1, "auth-header__back-btn", 3, "src", "click"], ["translate", "", 1, "text-center"], ["slot", "end", 4, "ngIf"], ["slot", "end"], ["shape", "round", 3, "click", 4, "ngFor", "ngForOf"], ["shape", "round", 3, "click"], [3, "src", "alt", 4, "ngIf"], [3, "src", "alt"]], template: function AuthHeaderComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-toolbar", 0);
        i0.ɵɵelementStart(1, "ion-buttons", 1);
        i0.ɵɵelementStart(2, "img", 2);
        i0.ɵɵlistener("click", function AuthHeaderComponent_Template_img_click_2_listener() { return ctx.backBtnHandler(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "ion-title", 3);
        i0.ɵɵtext(4);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, AuthHeaderComponent_ion_buttons_5_Template, 2, 1, "ion-buttons", 4);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(4, _c0, ctx.toolbarService.isNewIphone ? "30px" : "10px"));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("src", "assets/icons/design_v4/go-left.svg", i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", ctx.title, "");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.endBtns);
    } }, directives: [i3.IonToolbar, i4.NgStyle, i3.IonButtons, i3.IonTitle, i5.TranslateDirective, i4.NgIf, i4.NgForOf, i3.IonButton], styles: [".auth-header[_ngcontent-%COMP%]{\n      color: white;\n       max-width: 540px;\n       margin: 0 auto;\n    }\n    .auth-header__back-btn[_ngcontent-%COMP%]{\n      font-size: 32px;\n    }"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AuthHeaderComponent, [{
        type: Component,
        args: [{
                selector: 'app-auth-header',
                template: `
    <ion-toolbar [ngStyle]="{'padding-top': toolbarService.isNewIphone ? '30px' : '10px'}" class="auth-header" color="primary">
      <ion-buttons slot="start" style="margin-left: 15px">
        <img (click)="backBtnHandler()" class="auth-header__back-btn" [src]="'assets/icons/design_v4/go-left.svg'">
     </ion-buttons>
      <ion-title class="text-center" translate> {{title}}</ion-title>

      <ion-buttons slot="end" *ngIf="endBtns">
        <ion-button shape="round"  *ngFor="let btn of endBtns"
                    (click)="btn.callback($event)">
          <img
            *ngIf="btn.icon"
            [src]="'assets/icons/design_v4/' + btn.icon"
            [alt]="btn.name">
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  `,
                styles: [`
   .auth-header{
      color: white;
       max-width: 540px;
       margin: 0 auto;
    }
    .auth-header__back-btn{
      font-size: 32px;
    }
  `]
            }]
    }], function () { return [{ type: i1.Router }, { type: i2.ToolbarService }]; }, { title: [{
            type: Input
        }], endBtns: [{
            type: Input
        }] }); })();
