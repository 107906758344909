import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NoteComment} from '@app/modules/note-comment/note-comment';
import {Note} from '@app/modules/note/note';

@Component({
  selector: 'app-note-comment-list',
  templateUrl: './note-comment-list.component.html',
  styleUrls: ['./note-comment-list.component.css']
})
export class NoteCommentListComponent implements OnInit {

  @Output() haveAddedNewComment = new EventEmitter<boolean>();

  @Input() comments: NoteComment[];
  @Input() noteEntity: Note;
  @Input() translationsText: string[];

  constructor() {
  }

  ngOnInit() {
  }

  deletedComment(comment: NoteComment) {
    this.comments.splice(this.comments.findIndex((item: NoteComment) => item.id === comment.id), 1);
  }

  addedNewComment($event: boolean) {
    this.haveAddedNewComment.emit($event);
  }
}
