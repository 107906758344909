import {Component, Input, OnInit} from '@angular/core';
import {Continent} from "@app/modules/place/continent";
import {Country} from "@app/modules/place/country";
import {Region} from "@app/modules/place/region";
import {City} from "@app/modules/place/city";
import {ModalController} from "@ionic/angular";
import {GeoService} from "@app/shared/services/geo.service";
import {LabelService} from "@app/shared/services/label.service";
import {Place} from "@app/modules/place/place";
import {GeoItem} from "@app/modules/place/geo-item";
import {Globals} from "@app/shared/globals";
import {LevelData} from "@app/modules/category-level/LevelData";
import {Level} from "@app/modules/category-level/Level";
import {Label} from "@app/modules/label/label";

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.css']
})
export class PlaceComponent implements OnInit {

  @Input() levelData: LevelData;
  @Input() level: Level;

  private selectedPlace;
  public world: Continent[];
  public continents: Continent[];
  public countries: Country[];
  public regions: Region[];
  public cities: City[];
  private selectedGroups: number[];

  constructor(
    private modalController: ModalController,
    public geoService: GeoService,
    public labelService: LabelService,
    public globals: Globals) {
  }

  ngOnInit() {
    this.geoService.continents.subscribe(continents => this.continents = continents);
    this.geoService.getAllContinents().subscribe((continents: Continent[]) => {
      this.continents = continents;
      this.world = [];
      this.world.push(this.geoService.getWorld(this.continents));
      this.selectedPlace = new Place(this.world[0]);
      this.selectedGroups = [];
      this.geoService.changeContinents(continents);
    });
  }

  selectPlace() {
    if (this.selectedPlace.id !== undefined) {
      if (this.globals.isCategoryAddMode) {
        this.labelService.changeSelectedLabel(new Label([this.selectedPlace]));
      }
    } else {
      this.geoService.createPlace(this.selectedPlace).subscribe((result: Place) => {
        this.selectedPlace = result;
        this.geoService.changeSelectedPlace(this.selectedPlace);
        if (this.globals.isCategoryAddMode) {
          this.labelService.changeSelectedLabel(new Label([this.selectedPlace]));
        }
      });

    }
    this.modalController.dismiss();
  }

  openCountries(continent) {

    if (this.selectedGroups.length < 1 || !this.selectedGroups.includes(continent.id)) {
      this.geoService.getAllCountriesForContinent(continent.id).subscribe((counrties: Country[]) => {
        this.selectedGroups = [];
        this.selectedGroups.push(continent.id);
        this.selectedPlace = new Place();
        this.selectedPlace.continent = continent;
        this.countries = counrties;
        document.getElementById("continent" + continent.id).scrollIntoView();
      });
    } else {
      this.setWorld();
    }
    this.toggleGroup(continent);

  }

  openRegions(country) {
    if (this.selectedGroups.length < 2 || !this.selectedGroups.includes(country.id)) {
      this.geoService.getAllRegionsForCountry(country.id).subscribe((regions: Region[]) => {
        this.selectedGroups = [];
        this.selectedGroups.push(country.id, country.continent.id);
        this.selectedPlace = new Place();
        this.selectedPlace.continent = country.continent;
        this.selectedPlace.country = country;
        this.regions = regions;
        document.getElementById("country" + country.id).scrollIntoView();
      });
    } else {
      this.selectedGroups = [];
      this.selectedGroups.push(country.continent.id);
      this.selectedPlace = new Place();
      this.selectedPlace.continent = country.continent;
    }
    this.toggleGroup(country);
  }

  openCities(region) {
    if (this.selectedGroups.length < 3 || !this.selectedGroups.includes(region.id)) {
      this.geoService.getAllCitiesForRegion(region.id).subscribe((cities: City[]) => {
        this.selectedGroups = [];
        this.selectedGroups.push(region.id, region.country.id, region.country.continent.id);
        this.selectedPlace = new Place();
        this.selectedPlace.continent = region.country.continent;
        this.selectedPlace.country = region.country;
        this.selectedPlace.region = region;
        this.cities = cities;
        document.getElementById("region" + region.id).scrollIntoView();
      });
    } else {
      this.selectedGroups = [];
      this.selectedGroups.push(region.country.id, region.country.continent.id);
      this.selectedPlace = new Place();
      this.selectedPlace.continent = region.country.continent;
      this.selectedPlace.country = region.country;
    }
    this.toggleGroup(region);
  }

  selectCities(city) {
    this.selectedGroups = [];
    this.selectedGroups.push(city.id, city.region.id, city.region.country.id, city.region.country.continent.id);
    this.selectedPlace = new Place();
    this.selectedPlace.continent = city.region.country.continent;
    this.selectedPlace.country = city.region.country;
    this.selectedPlace.region = city.region;
    this.selectedPlace.city = city;
  }

  toggleGroup(group: GeoItem) {
    group.isOpened = !group.isOpened;
  }

  isGroupShown(group: GeoItem) {
    return this.selectedGroups.includes(group.id);
  }


  setWorld() {
    this.selectedPlace = new Place(this.world[0]);
    this.selectedGroups = [];
  }
}
