import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { Globals } from "@app/shared/globals";
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/globals";
export class DateConverterService {
    constructor(globals) {
        this.globals = globals;
    }
    dateConverter(longDate) {
        const date = formatDate(longDate, 'MM-dd-yyyy', 'en');
        return date;
    }
    fromNow(date) {
        moment.locale(this.globals.currentLanguage);
        const tomorrow = moment(new Date()).add(1, 'day');
        const dateToShow = moment(date);
        if (tomorrow.diff(dateToShow, 'days') > 1) {
            return dateToShow.format('DD.MM.YYYY');
        }
        else {
            return dateToShow.fromNow();
        }
    }
    isSameDay(firstDay, secondDay) {
        return moment(firstDay).isSame(moment(secondDay), 'day');
    }
}
DateConverterService.ɵfac = function DateConverterService_Factory(t) { return new (t || DateConverterService)(i0.ɵɵinject(i1.Globals)); };
DateConverterService.ɵprov = i0.ɵɵdefineInjectable({ token: DateConverterService, factory: DateConverterService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DateConverterService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.Globals }]; }, null); })();
