<ion-slides tappable [options]="rootSliderOptions" [class.category__slider-txt]="getSliderTextClass()">

  <ng-container *ngIf="isInitialized">
    <ion-slide class="category__slider" [style.width]="slideDivDynamicStyles.width"
      *ngFor="let category of categories; let i = index">

      <div class="category__slider-item" [style.min-height]="slideDivDynamicStyles.minHeight"
        [class.category__private]="category.accessType === 2" (click)="onClickHandler(category, i)">

        <div class="category__slider-icon" *ngIf="config.showIconsInSlider">
          <img [src]="category | CategoryIcon" />
          <img class="inverted" [src]="category | CategoryIcon: true" />
        </div>

        <p class="category__slider-title" [style.font-size]="slideDivDynamicStyles.fontSize">
          {{getTranslationForTitle(category)}}</p>
        <span class="notes-number"
          *ngIf="!config.showIconsInSlider && category.numberOfNotes > 0">{{category.numberOfNotes}}</span>
      </div>
    </ion-slide>
  </ng-container>

</ion-slides>
