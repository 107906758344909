<app-auth-header title="Sign up" [endBtns]="endActions"></app-auth-header>
<ion-content>

<div class="sign-up__form">

  <div class="sign-up__logo">
<!--    <img-->
<!--      [src]="'assets/icons/design_v4/logo.svg'"-->
<!--      alt="">-->
  </div>

  <form
    class="form"
    [formGroup]="signUpForm"
    role="form">

    <div class="form__item">
      <ion-spinner
        *ngIf="isCheckingName"
        class="form__item__loader"
        name="crescent"></ion-spinner>
      <img
        *ngIf="!isCheckingName"
        class="form__item__icon"
        [src]="'assets/icons/design_v4/user_icon.svg'"
        alt="">
      <ion-input
        (change)="onSsoIdInput()"
        class="form__item__input"
        type="text"
        placeholder="{{'Username' | translate}}"
        id="ssoId"
        name="ssoId"
        formControlName="ssoId"></ion-input>
    </div>

    <div class="form__item">
      <ion-spinner
        class="form__item__loader"
        *ngIf="isCheckingEmail"
        name="crescent"></ion-spinner>
      <img
        *ngIf="!isCheckingEmail"
        class="form__item__icon"
        [src]="'assets/icons/design_v4/email_icon.svg'"
        alt="">
      <ion-input
        (change)="onEmailInput()"
        class="form__item__input"
        type="email"
        placeholder="{{'Email' | translate}}"
        id="email"
        name="email"
        formControlName="email"></ion-input>
    </div>
    <div *ngIf="signUpForm.get('password').dirty">
      <div *ngIf="!hasPassDigit" class="form__error" translate>digit</div>
      <div *ngIf="!hasPassCapitalLetter" class="form__error" translate>uppercase letter</div>
      <div *ngIf="!hasPassLowCaseLetter" class="form__error" translate>lowercase letter</div>
      <div *ngIf="!hasPassCharacter" class="form__error" translate>character :;[]!@#$%^&*()?<></div>
      <div *ngIf="!isPassLengthOk" class="form__error" translate>longer then 8 symbols</div>
    </div>
    <div class="form__item">
      <img
        class="form__item__icon"
        [src]="'assets/icons/design_v4/lock_icon.svg'"
        alt="">
      <ion-input
        (ionChange)="checkPassHandler($event.detail.value)"
        class="form__item__input"
        formControlName="password"
        type="password"
        placeholder="{{'Password' | translate}}"
        id="password"
        name="password"></ion-input>
    </div>
    <div *ngIf="!hasPassDigit||!hasPassCapitalLetter||!hasPassLowCaseLetter||!hasPassCharacter||!isPassLengthOk"
         class="password__example"
         translate>Password example: AEro41*[</div>
    <div class="form__item">
      <img
        class="form__item__icon"
        [src]="'assets/icons/design_v4/lock_icon.svg'"
        alt="">
      <ion-input
        class="form__item__input"
        formControlName="confirmPassword"
        type="password"
        placeholder="{{'Confirm password' | translate}}"
        id="confirmPassword"
        name="confirmPassword"></ion-input>
    </div>

    <p
      class="form__error"
      *ngIf="signUpForm.get('password').invalid && signUpForm.get('password').touched && signUpForm.get('password')['errors']['hasCyrillic']"
      translate>
      Password can only contain latin characters, numbers
    </p>
    <p
      class="form__error"
      *ngIf="signUpForm.get('confirmPassword').invalid && signUpForm.get('confirmPassword').touched && signUpForm.get('confirmPassword')['errors']['matchOther']"
      translate>
      Your password and confirmation password do not match.
    </p>
    <p
      class="form__error"
      *ngIf="errorMsg !== ''"
      translate>
      {{errorMsg}}
    </p>
    <p
      class="form__error"
      *ngIf="validationData.ssoId"
      translate>
      {{validationData.ssoId}}
    </p>
    <p
      class="form__error"
      *ngIf="validationData.email"
      translate>
      {{validationData.email}}
    </p>

    <div class="policy__agree" ><span translate>By clicking Sign Up, Google or Facebook buttons, you agree to our </span> <a href="/terms" translate>Terms and Conditions</a>, <span translate></span>and <a href="/policy" translate>Privacy Policy</a>.</div>

<!--    <div class="form__item">-->
<!--      <img-->
<!--        class="form__item__icon"-->
<!--        [src]="'assets/icons/design_v4/social_media.svg'"-->
<!--        alt="">-->
<!--      <input-->
<!--        (change)="onReferrerInput()"-->
<!--        class="form__item__input"-->
<!--        formControlName="referrer"-->
<!--        type="text"-->
<!--        placeholder="{{'Referrer (optional)' | translate}}"-->
<!--        id="referrer"-->
<!--        name="referrer"/>-->
<!--    </div>-->
    <p
      class="form__error"
      *ngIf="validationData.referrer"
      translate>
      {{validationData.referrer}}
    </p>

    <ion-button
      class="form__button"
      [disabled]="signUpForm.invalid || validationData.ssoId !== null || validationData.email !== null"
      color="primary"
      (click)="signUp()">
        <span
          translate
          class="form__button__text">
          Sign up
        </span>
    </ion-button>

  </form>


</div>

<div class="social-login">

  <ion-fab class="social-login__actions"  vertical="bottom" horizontal="center">
    <ion-fab-button (click)="loginWithGoogle()">
      <i class="fa fa-google-plus fa-sm social-login__icon"></i>
    </ion-fab-button>
    <ion-fab-button (click)="loginWithFb()">
      <i class="fa fa-facebook fa-sm social-login__icon"></i>
    </ion-fab-button>
    <!--<ion-fab-button (click)="loginWithAppleID()">-->
      <!--<i class="fa fa-apple fa-sm social-login__icon"></i>-->
    <!--</ion-fab-button>-->
  </ion-fab>


</div>
</ion-content>
