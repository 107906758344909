import {Component, Input, OnInit} from '@angular/core';
import {NoteCommentService} from '../../shared/services/note-comment.service';
import {Globals} from '../../shared/globals';
import {DateConverterService} from '../../shared/services/date-converter.service';
import {NoteCommentComponent} from '../note-comment/note-comment.component';
import {NoteComment} from '../note-comment/note-comment';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {PopoverController} from '@ionic/angular';
import {Note} from '@app/modules/note/note';

@Component({
  selector: 'app-child-note-comment',
  templateUrl: './child-note-comment.component.html',
  styleUrls: ['./child-note-comment.component.scss']
})
export class ChildNoteCommentComponent extends NoteCommentComponent implements OnInit {
  @Input() currentCommentFromChildList: NoteComment;
  @Input() noteEntity: Note;
  @Input() translationsText: string[];

  constructor(noteCommentService: NoteCommentService,
              globals: Globals,
              dateConverterService: DateConverterService,
              popoverController: PopoverController,
              uiAlertService: UiAlertService) {
    super(noteCommentService, globals, dateConverterService, popoverController, uiAlertService);
  }

  ngOnInit() {
  }


}
