import { Component, ViewChild } from '@angular/core';
import { Globals } from '../../shared/globals';
import { SiteUserService } from '../../shared/services/site-user.service';
import { IonSearchbar } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/globals";
import * as i2 from "../../shared/services/site-user.service";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
const _c0 = ["userSearchBar"];
export class SearchComponent {
    constructor(globals, userService) {
        this.globals = globals;
        this.userService = userService;
        this.searchValue = '';
    }
    ngOnInit() {
    }
    focusOnInput() {
        setTimeout(() => {
            this.userSearchBar.setFocus().then();
        }, 300);
    }
    ionViewDidLeave() {
        this.searchInputHandler('');
    }
    searchUser(value, page, callback) {
        this.userService.getUsersBy(value, page).subscribe((data) => {
            this.pageData = data;
            this.globals.people = page === 0 ? this.pageData.content : this.globals.people.concat(this.pageData.content);
            this.globals.isSearchingPeople = true;
            if (callback) {
                callback();
            }
        });
    }
    searchInputHandler(value) {
        this.searchValue = value;
        if (this.searchValue === '') {
            this.globals.isSearchingPeople = false;
            this.globals.people = this.globals.friendList;
        }
        else {
            this.searchUser(this.searchValue, 0);
        }
    }
    /**
     * this method is used from ViewChild in friendsComponent
     */
    nextPage(event) {
        if (!this.pageData.last) {
            this.searchUser(this.searchValue, this.pageData.number + 1, () => {
                event.target.complete();
            });
        }
        else {
            if (event) {
                event.target.complete();
            }
        }
    }
}
SearchComponent.ɵfac = function SearchComponent_Factory(t) { return new (t || SearchComponent)(i0.ɵɵdirectiveInject(i1.Globals), i0.ɵɵdirectiveInject(i2.SiteUserService)); };
SearchComponent.ɵcmp = i0.ɵɵdefineComponent({ type: SearchComponent, selectors: [["app-search"]], viewQuery: function SearchComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.userSearchBar = _t.first);
    } }, decls: 3, vars: 3, consts: [["animated", "", 3, "placeholder", "ionChange"], ["userSearchBar", ""]], template: function SearchComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-searchbar", 0, 1);
        i0.ɵɵlistener("ionChange", function SearchComponent_Template_ion_searchbar_ionChange_0_listener($event) { return ctx.searchInputHandler($event.detail.value); });
        i0.ɵɵpipe(2, "translate");
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(2, 1, "Search"));
    } }, directives: [i3.IonSearchbar, i3.TextValueAccessor], pipes: [i4.TranslatePipe], styles: ["input[type=text][_ngcontent-%COMP%]{padding:5px;margin-left:5%;font-size:17px;border:3px solid #e4e4e4;border-right:none}.search-container[_ngcontent-%COMP%]{float:none;padding-right:0;padding-left:0;width:100%;margin:0}.search-container[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{padding:5px;background-color:#eefffa;font-size:17px;border:3px solid #e4e4e4;border-left:none;cursor:pointer}.search-container[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]:hover{background:#b6d4ce}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SearchComponent, [{
        type: Component,
        args: [{
                selector: 'app-search',
                templateUrl: './search.component.html',
                styleUrls: ['./search.component.css']
            }]
    }], function () { return [{ type: i1.Globals }, { type: i2.SiteUserService }]; }, { userSearchBar: [{
            type: ViewChild,
            args: ['userSearchBar', { static: false }]
        }] }); })();
