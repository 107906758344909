<ion-content>

  <div class="chat">

    <div
      [ngClass]="{'hide-list' : innerWidth <= 768 && activeDialogue !== null}"
      class="chat__list">
      <app-friends-list #friendList
        (setNewActiveDialogue)="openChat($event)"
        [showFilter]="showFilter">
      </app-friends-list>
    </div>

    <div
      *ngIf="innerWidth >= 768"
      class="chat__messages">
      <app-chat-messages
        *ngIf="activeDialogue !== undefined && !globals.isFriendRequestsOpen"
        [data]="chatList"
        (dialogueClosed)="activeDialogue = $event"
        (lastMessageUpdated)="getLastMessage($event)">
      </app-chat-messages>
      <div *ngIf="!globals.isFriendRequestsOpen && (activeDialogue === null|| activeDialogue === undefined)">
        <div style="margin-top: 12px;" class="d-flex align-items-center justify-content-center" translate>
          Choose a dialogue
        </div>
      </div>
    </div>
  </div>

</ion-content>
