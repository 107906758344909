import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import * as i0 from "@angular/core";
export class OutsideClickDirective {
    constructor(_elementRef) {
        this._elementRef = _elementRef;
        this.clickOutside = new EventEmitter();
    }
    onMouseEnter(targetElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.clickOutside.emit(null);
        }
    }
}
OutsideClickDirective.ɵfac = function OutsideClickDirective_Factory(t) { return new (t || OutsideClickDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
OutsideClickDirective.ɵdir = i0.ɵɵdefineDirective({ type: OutsideClickDirective, selectors: [["", "appOutsideClick", ""]], hostBindings: function OutsideClickDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function OutsideClickDirective_click_HostBindingHandler($event) { return ctx.onMouseEnter($event.target); }, false, i0.ɵɵresolveDocument);
    } }, outputs: { clickOutside: "clickOutside" } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(OutsideClickDirective, [{
        type: Directive,
        args: [{
                selector: '[appOutsideClick]'
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { clickOutside: [{
            type: Output,
            args: ['clickOutside']
        }], onMouseEnter: [{
            type: HostListener,
            args: ['document:click', ['$event.target']]
        }] }); })();
