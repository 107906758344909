<ion-content>
  <ion-list class="place-list">
    <div *ngFor="let c of world">
      <ion-item class="item-stable"
                (click)="this.setWorld()"
                class="active">
        <ion-icon name="md-remove" class="geo-icon"></ion-icon> &nbsp;
        {{c.title}}
      </ion-item>
      <div *ngFor="let continent of continents">
        <ion-item class="item-stable item-continent"
                  *ngIf="continent.title!=='World'"
                  [id]="'continent' + continent.id"
                  (click)="openCountries(continent)"
                  [ngClass]="isGroupShown(continent)? 'active' : ''">
          <ion-icon *ngIf="!isGroupShown(continent)" name="md-add" class="geo-icon"></ion-icon>
          <ion-icon *ngIf="isGroupShown(continent)" name="md-remove" class="geo-icon"></ion-icon> &nbsp;
          {{continent.title}}
        </ion-item>
        <div *ngIf="isGroupShown(continent)">
          <div *ngFor="let country of countries">
            <ion-item class="item-accordion item-country"
                      [id]="'country' + country.id"
                      (click)="openRegions(country)"
                      [ngClass]="isGroupShown(country)? 'active' : ''">
              <ion-icon *ngIf="!isGroupShown(country)" name="md-add" class="geo-icon"></ion-icon>
              <ion-icon *ngIf="isGroupShown(country)" name="md-remove" class="geo-icon"></ion-icon>
              {{country.title}}
            </ion-item>
            <div *ngIf="isGroupShown(country)">
              <div *ngFor="let region of regions">
                <ion-item class="item-accordion item-region"
                          [id]="'region' + region.id"
                          (click)="openCities(region)"
                          [ngClass]="isGroupShown(region)? 'active' : ''">
                  <ion-icon *ngIf="!isGroupShown(region)" name="md-add" class="geo-icon"></ion-icon>
                  <ion-icon *ngIf="isGroupShown(region)" name="md-remove" class="geo-icon"></ion-icon>
                  {{region.title}}
                </ion-item>
                <div *ngIf="isGroupShown(region)">
                  <div *ngFor="let city of cities">
                    <ion-item class="item-accordion item-city"
                              [id]="'city' + city.id"
                              (click)="selectCities(city)"
                              [ngClass]="isGroupShown(city)? 'active' : ''">
                      {{city.cityTitle}}
                    </ion-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-list>
  <ion-item></ion-item>
  <ion-item class="place-select">
    <ion-button class="place-select-btn" (click)="selectPlace()">
      Select
    </ion-button>
  </ion-item>
</ion-content>
