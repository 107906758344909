import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class ButtonTouchDirective {
    constructor(router) {
        this.router = router;
    }
    tapped() {
        this.down();
    }
    untapped() {
        this.up();
    }
    mouseDown() {
        this.down();
    }
    mouseUp() {
        this.up();
    }
    mouseLeave() {
        this.up();
    }
    down() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.router.navigateByUrl('categories/437/new-note').then(r => console.log(r));
        }, 500);
    }
    up() {
        clearTimeout(this.timeout);
    }
}
ButtonTouchDirective.ɵfac = function ButtonTouchDirective_Factory(t) { return new (t || ButtonTouchDirective)(i0.ɵɵdirectiveInject(i1.Router)); };
ButtonTouchDirective.ɵdir = i0.ɵɵdefineDirective({ type: ButtonTouchDirective, selectors: [["", "appButtonTouch", ""]], hostBindings: function ButtonTouchDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("touchstart", function ButtonTouchDirective_touchstart_HostBindingHandler() { return ctx.tapped(); })("touchend", function ButtonTouchDirective_touchend_HostBindingHandler() { return ctx.untapped(); })("mousedown", function ButtonTouchDirective_mousedown_HostBindingHandler() { return ctx.mouseDown(); })("mouseup", function ButtonTouchDirective_mouseup_HostBindingHandler() { return ctx.mouseUp(); })("mouseleave", function ButtonTouchDirective_mouseleave_HostBindingHandler() { return ctx.mouseLeave(); });
    } } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ButtonTouchDirective, [{
        type: Directive,
        args: [{
                selector: '[appButtonTouch]'
            }]
    }], function () { return [{ type: i1.Router }]; }, { tapped: [{
            type: HostListener,
            args: ['touchstart']
        }], untapped: [{
            type: HostListener,
            args: ['touchend']
        }], mouseDown: [{
            type: HostListener,
            args: ['mousedown']
        }], mouseUp: [{
            type: HostListener,
            args: ['mouseup']
        }], mouseLeave: [{
            type: HostListener,
            args: ['mouseleave']
        }] }); })();
