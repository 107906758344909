import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../globals';
import {SiteUser} from '../../modules/site-user/site-user';

@Injectable({
  providedIn: 'root'
})
export class UserRelationService {

  constructor(public http: HttpClient, public globals: Globals) {
  }

  refreshRequestsList() {
    this.getFriendRequests();
  }

  refreshOutgoingRequests() {
    this.getOutgoingRequests();
  }

  getAllUserFriends(userId: number, page: number) {
    const params = new HttpParams().append('page', page.toString());
    return this.http.get(this.globals.apiUrl + '/relations/friends/users/' + userId, {
      params
    });
  }

  getFriendRequests() {
    this.http.get(this.globals.apiUrl + '/relations/incoming-requests').subscribe((data: SiteUser[]) => {
      this.globals.requestList = data as SiteUser[];
      this.globals.notificationCount = this.globals.unreadMessagesCount + this.globals.requestList.length;
    });
  }

  getOutgoingRequests() {
    this.http.get(this.globals.apiUrl + '/relations/outgoing-requests').subscribe((data: SiteUser[]) => {
      this.globals.outgoingRequests = data as SiteUser[];
    });
  }

  addUserRelation(friend: SiteUser, callback) {
    let params = new HttpParams();
    params = params.append('friendId', friend.id.toString());
    this.http.post(this.globals.apiUrl + '/relations', null, {
      params: params,
      observe: 'response'
    }).subscribe(() => {
      return callback && callback();
    });
  }

  deleteUserRelation(friend: SiteUser, callback) {
    this.http.delete(this.globals.apiUrl + '/relations/' + friend.id).subscribe(() => {
      return callback && callback();
    });
  }

  editUserRelation(friend: SiteUser, relationType: number, callback) {
    let params = new HttpParams();
    params = params.append('friendId', friend.id.toString());
    params = params.append('relation', relationType.toString());
    this.http.put(this.globals.apiUrl + '/relations', null, {
      params: params,
      observe: 'response'
    }).subscribe(() => {
      return callback && callback();
    });
  }
}
