<ng-container *ngIf="isVisible">
  <ng-container>
    <ion-header [ngStyle]="{'height': toolbarService.isNewIphone ? '74px' : '54px'}">
      <ion-toolbar
        [ngStyle]="{'padding-top': toolbarService.isNewIphone ? '30px' : '10px', 'height': toolbarService.isNewIphone ? '74px' : '54px'}"
        color="primary" class="header-tools">
        <ion-title color="light" class="ion-text-center" translate>{{ title }}</ion-title>
        <ion-buttons class="left-actions ml-3" slot="start">
          <ion-button *ngIf="mainBtn"
                      (click)="applyItem(mainBtn, $event)">
            <div *ngIf="mainBtn.title ==='Back'" [bdcWalkTriggerFor]="welcomeGuideTask2">
              <img
                [src]="mainBtn.icon"
                [alt]="mainBtn.title"
              />
            </div>
            <div *ngIf="mainBtn.title !='Back'">
              <img
                [src]="mainBtn.icon"
                [alt]="mainBtn.title"
              />
            </div>
          </ion-button>

          <ion-button
            (click)="openMenu()"
            *ngIf="!mainBtn">
            <img [src]="'assets/icons/design_v4/menu.svg'"/>
          </ion-button>
        </ion-buttons>

        <ion-searchbar #userSearchBar
                       slot="start"
                       class="searchbar-header"
                       animated
                       mode="ios"
                       search-icon
                       placeholder="{{searchbar.placeholder | translate}}"
                       *ngIf="searchbar != null "
                       (ionChange)="applyItem(searchbar, $event)"
                       (ionCancel)="globals.enabledSearch = false"
                       (ionBlur)="blurHandle($event)"
                       (ionFocus)="globals.enabledSearch = true"
                       [showCancelButton]="true"
                       [(ngModel)]="globals.searchbarField"
        >
        </ion-searchbar>

          <ion-buttons slot="end" *ngIf="!globals.enabledSearch">

            <ion-button *ngFor="let item of items"
                        (click)="applyItem(item, $event)"
            >
              <div *ngIf="item.title ==='Category tree'" [bdcWalkTriggerFor]="welcomeGuideTask3">
                <img
                  [src]="item.icon"
                  [alt]="item.title"
                />
              </div>
              <div *ngIf="item.title ==='Go to next step'" [bdcWalkTriggerFor]="addNoteGuideTask9">
                <img
                  [src]="item.icon"
                  [alt]="item.title"
                />
              </div>
              <div *ngIf="item.title ==='Add New Note'" [bdcWalkTriggerFor]="addNoteGuideTask11">
                <img
                  [src]="item.icon"
                  [alt]="item.title"
                />
              </div>
              <div *ngIf="(item.title !='Category tree' && item.title !='Back' && item.title !='Go to next step' && item.title !='Add New Note')">
                <img
                  [src]="item.icon"
                  [alt]="item.title"
                />
              </div>
            </ion-button>

          </ion-buttons>

      </ion-toolbar>
    </ion-header>


    <bdc-walk-popup class="custom-popup" #welcomeGuideTask2 name="welcomeGuideTask2" xPosition="after"
                    [mustCompleted]="{welcomeGuideShowWalkthrough: true, welcomeGuideTask1: true}"
                    [showCloseButton]="false" [closeOnClick]="true">
      <span translate>Click the arrow above to get back<br></span>
    </bdc-walk-popup>
    <bdc-walk-popup class="custom-popup" #welcomeGuideTask3 name="welcomeGuideTask3" xPosition="before"
                    [mustCompleted]="{welcomeGuideShowWalkthrough: true, welcomeGuideTask2: true}"
                    [showCloseButton]="false"
                    [closeOnClick]="true">
      <span translate>Click to filter by a Category<br></span>
    </bdc-walk-popup>
    <bdc-walk-popup class="custom-popup" #addNoteGuideTask9 name="addNoteGuideTask9" xPosition="before" yPosition="below"
                    [mustCompleted]="{addNoteGuideShowWalkthrough: true, addNoteGuideTask8: true}"
                    [showCloseButton]="false"
                    [closeOnClick]="true">
      <span translate>Click when done<br></span>
    </bdc-walk-popup>
    <bdc-walk-popup class="custom-popup" #addNoteGuideTask11 name="addNoteGuideTask11" xPosition="before" yPosition="below"
                    [mustCompleted]="{addNoteGuideShowWalkthrough: true, addNoteGuideTask10: true}"
                    [showCloseButton]="false"
                    [closeOnClick]="true">
      <span translate>Save<br></span>
    </bdc-walk-popup>
  </ng-container>
</ng-container>
