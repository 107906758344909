import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from "@ngrx/store";

import { State } from "./state";
import { Category } from "@app/modules/category/category";

const getCategories = (state: State): any => state.categories;

const getRootCategories = (state: State): any => state.rootCategories;

const getCurrentCategory = (state: State): any => state.currentSelectCategory;

const getFlatNavigateLevel = (state: State): any => state.flatTreeNavigate;

export const selectCategoryState = createFeatureSelector<State>(
  "categoryStore"
);

export const selectCategoriesItems = createSelector(
  selectCategoryState,
  getCategories
);

export const selectRootCategories = createSelector(
  selectCategoryState,
  getRootCategories
);

export const selectCurrentCategory = createSelector(
  selectCategoryState,
  getCurrentCategory
);

export const selectLevels = createSelector(
  selectCategoryState,
  getFlatNavigateLevel
);

export const selectCurrentChilds = createSelector(
  selectCategoryState,
  getCategories,
  getCurrentCategory,
  (state: State) => {
    if (state.currentSelectCategory && state.categories) {
      return state.categories.filter(
        (category: Category) =>
          category.parentCategory.id === state.currentSelectCategory.id
      );
    } else {
      return state.categories;
    }
  }
);
