import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilsService } from "@app/shared/services/utils.service";
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/services/utils.service";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/common";
function EmojiPickerComponent_ion_slide_3_span_1_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵlistener("click", function EmojiPickerComponent_ion_slide_3_span_1_Template_span_click_0_listener() { i0.ɵɵrestoreView(_r5); const item_r3 = ctx.$implicit; const ctx_r4 = i0.ɵɵnextContext(2); return ctx_r4.setValue(item_r3); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r3 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", item_r3, " ");
} }
function EmojiPickerComponent_ion_slide_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-slide", 4);
    i0.ɵɵtemplate(1, EmojiPickerComponent_ion_slide_3_span_1_Template, 2, 1, "span", 5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const items_r1 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", items_r1);
} }
export const EMOJI_PICKER_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmojiPickerComponent),
    multi: true
};
export class EmojiPickerComponent {
    // ' 👹 👺 💩 👻 💀 ☠️ 👽 👾 🤖 🎃 😺 😸 😹 😻 😼 😽 🙀 😿 😾 👐 🙌 👏 🙏 🤝 👍 👎 👊 ✊ 🤛 🤜 🤞 ✌️ 🤘 👌 👈 👉 👆 👇 ☝️ ✋ 🤚' +
    // ' 🖐 🖖 👋 🤙 💪 🖕 ✍️ 🤳 💅 🖖 💄 💋 👄 👅 👂 👃 👣 👁 👀 🗣 👤 👥 👶 👦 👧 👨 👩 👱‍♀️ 👱 👴 👵 👲 👳‍♀️ 👳 👮‍♀️ 👮 👷‍♀️ 👷' +
    // ' 💂‍♀️ 💂 🕵️‍♀️ 🕵️ 👩‍⚕️ 👨‍⚕️ 👩‍🌾 👨‍🌾 👩‍🍳 👨‍🍳 👩‍🎓 👨‍🎓 👩‍🎤 👨‍🎤 👩‍🏫 👨‍🏫 👩‍🏭 👨‍🏭 👩‍💻 👨‍💻 👩‍💼 👨‍💼 👩‍🔧 👨‍🔧 👩‍🔬 👨‍🔬' +
    // ' 👩‍🎨 👨‍🎨 👩‍🚒 👨‍🚒 👩‍✈️ 👨‍✈️ 👩‍🚀 👨‍🚀 👩‍⚖️ 👨‍⚖️ 🤶 🎅 👸 🤴 👰 🤵 👼 🤰 🙇‍♀️ 🙇 💁 💁‍♂️ 🙅 🙅‍♂️ 🙆 🙆‍♂️ 🙋 🙋‍♂️ 🤦‍♀️ 🤦‍♂️ 🤷‍♀' +
    // '️ 🤷‍♂️ 🙎 🙎‍♂️ 🙍 🙍‍♂️ 💇 💇‍♂️ 💆 💆‍♂️ 🕴 💃 🕺 👯 👯‍♂️ 🚶‍♀️ 🚶 🏃‍♀️ 🏃 👫 👭 👬 💑 👩‍❤️‍👩 👨‍❤️‍👨 💏 👩‍❤️‍💋‍👩 👨‍❤️‍💋‍👨 👪 👨‍👩‍👧' +
    // ' 👨‍👩‍👧‍👦 👨‍👩‍👦‍👦 👨‍👩‍👧‍👧 👩‍👩‍👦 👩‍👩‍👧 👩‍👩‍👧‍👦 👩‍👩‍👦‍👦 👩‍👩‍👧‍👧 👨‍👨‍👦 👨‍👨‍👧 👨‍👨‍👧‍👦 👨‍👨‍👦‍👦 👨‍👨‍👧‍👧 👩‍👦 👩‍👧' +
    // ' 👩‍👧‍👦 👩‍👦‍👦 👩‍👧‍👧 👨‍👦 👨‍👧 👨‍👧‍👦 👨‍👦‍👦 👨‍👧‍👧 👚 👕 👖 👔 👗 👙 👘 👠 👡 👢 👞 👟 👒 🎩 🎓 👑 ⛑ 🎒 👝 👛 👜 💼 👓' +
    // ' 🕶 🌂 ☂️';
    constructor(unitlsService) {
        this.unitlsService = unitlsService;
        this.emojiArr = [];
        this.selectionStart = 0;
        this._EMOJIS = '😀 😃 😄 😁 😆 😅 😂 🤣 😊 😇 🙂 🙃 😉 😌 😍 😘 😗 😙 😚 😋 😜 😝 😛 🤑 🤗 🤓 😎 🤡 🤠 😏 😒 😞 😔 😟 😕 🙁' +
            ' ☹️ 😣 😖 😫 😩 😤 😠 😡 😶 😐 😑 😯 😦 😧 😮 😲 😵 😳 😱 😨 😰 😢 😥 🤤 😭 😓 😪 😴 🙄 🤔 🤥 😬 🤐 🤢 🤧 😷 🤒 🤕 😈 👿';
        const EmojiArr = Array.from(this._EMOJIS);
        const groupNum = Math.ceil(EmojiArr.length / (24));
        const items = [];
        for (let i = 0; i < groupNum; i++) {
            items.push(EmojiArr.slice(i * 24, (i + 1) * 24));
        }
        this.emojiArr = items;
    }
    writeValue(obj) {
        this._content = obj;
    }
    registerOnChange(fn) {
        this._onChanged = fn;
        this.setValue(this._content);
    }
    registerOnTouched(fn) {
        this._onTouched = fn;
    }
    setValue(val) {
        this._content = this.insertEmoji(this.selectionStart, this._content, val);
        this.selectionStart = this.selectionStart + 1;
        if (this._content) {
            this._onChanged(this._content);
        }
    }
    insertEmoji(index, str, emoji) {
        if (index === 1) {
            return emoji + str;
        }
        else {
            if (str !== null) {
                const s = Array.from(str);
                return this.unitlsService.insert(s, (index >= s.length ? index - 1 : index), emoji).join('');
            }
            return str;
        }
    }
}
EmojiPickerComponent.ɵfac = function EmojiPickerComponent_Factory(t) { return new (t || EmojiPickerComponent)(i0.ɵɵdirectiveInject(i1.UtilsService)); };
EmojiPickerComponent.ɵcmp = i0.ɵɵdefineComponent({ type: EmojiPickerComponent, selectors: [["app-emoji-picker"]], inputs: { selectionStart: "selectionStart" }, features: [i0.ɵɵProvidersFeature([EMOJI_PICKER_VALUE_ACCESSOR])], decls: 4, vars: 1, consts: [[1, "emoji-picker"], [1, "emoji-items"], ["pager", ""], ["style", "flex-wrap: wrap;", 4, "ngFor", "ngForOf"], [2, "flex-wrap", "wrap"], ["class", "emoji-item", 3, "click", 4, "ngFor", "ngForOf"], [1, "emoji-item", 3, "click"]], template: function EmojiPickerComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "ion-slides", 2);
        i0.ɵɵtemplate(3, EmojiPickerComponent_ion_slide_3_Template, 2, 1, "ion-slide", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngForOf", ctx.emojiArr);
    } }, directives: [i2.IonSlides, i3.NgForOf, i2.IonSlide], styles: [".emoji-picker[_ngcontent-%COMP%]{height:195px;border-top:1px solid #999}.emoji-picker[_ngcontent-%COMP%]   .emoji-items[_ngcontent-%COMP%]{padding:10px;width:100%;height:100%}.emoji-picker[_ngcontent-%COMP%]   .emoji-items[_ngcontent-%COMP%]   .emoji-item[_ngcontent-%COMP%]{display:block;width:12.5%;height:42px;font-size:1.2em;line-height:42px;text-align:center;margin-bottom:10px}.emoji-items[_ngcontent-%COMP%]:nth-child(10n){flex-break:after}ion-slide[_ngcontent-%COMP%]{flex-wrap:wrap}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EmojiPickerComponent, [{
        type: Component,
        args: [{
                selector: 'app-emoji-picker',
                providers: [EMOJI_PICKER_VALUE_ACCESSOR],
                templateUrl: './emoji-picker.component.html',
                styleUrls: ['./emoji-picker.component.css']
            }]
    }], function () { return [{ type: i1.UtilsService }]; }, { selectionStart: [{
            type: Input
        }] }); })();
