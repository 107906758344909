import { Component, OnInit } from '@angular/core';
import {Globals} from "@app/shared/globals";
import {SiteUser} from "@app/modules/site-user/site-user";
import {AuthenticationService} from "@app/core/auth/authentication.service";
import {CanActivate, Router} from "@angular/router";
import {FacebookAuthService} from "@app/core/auth/facebook-auth.service";
import {GoogleAuthService} from "@app/core/auth/google-auth.service";
import {AppleAuthService} from "@app/core/auth/apple-auth.service";
import {MenuService} from "@app/shared/services/menu/menu.service";
import {AlertController, LoadingController, PopoverController} from "@ionic/angular";
import {HttpClient, HttpParams} from "@angular/common/http";
import {TokensService} from "@app/shared/services/tokens.service";
import {Storage} from "@ionic/storage";
import {LanguagesList} from "@app/core/languages";
import {LangPickerDropDownComponent} from "@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component";
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit, CanActivate {

  currentUser: SiteUser;
  referrerUser: SiteUser;
  acceptedTerms: boolean = false;

  readonly typingTimerInterval = 300;
  referrerTypingTimerId;
  isCheckingReferrer = false;
  referrerToCheck;

  endActions;
  languageList = LanguagesList;
  popover;

  constructor(
    public router: Router,
    public globals: Globals,
    public app: AuthenticationService,
    public facebookAuthService: FacebookAuthService,
    public googleAuthService: GoogleAuthService,
    public appleAuthService: AppleAuthService,
    public menuService: MenuService,
    public alertCtrl: AlertController,
    public http: HttpClient,
    public authenticationService: AuthenticationService,
    public tokensService: TokensService,
    public storage: Storage,
    public popoverController: PopoverController,
    public toolbarService: ToolbarService,
    public loadingController: LoadingController,
  ) { }

  ngOnInit() {
    this.currentUser = this.globals.owner;
    console.log(this.currentUser);

    const lang = this.languageList.filter(l  => l.en === this.globals.currentLanguage)[0].name;
    this.endActions = [
      {
        icon: `langs/${lang.toLocaleLowerCase()}.svg`,
        name: lang,
        callback: env => this.presentLanguageMenu(env)
      }
    ];
  }

  async presentLanguageMenu(env) {
    this.popover = await this.popoverController.create({
      component: LangPickerDropDownComponent,
      event: env,
      translucent: false,
      showBackdrop: false,
    });

    await this.popover.present();

    this.popover.onDidDismiss().then(
      e => {
        if (e.data) {
          this.endActions[0].icon = `langs/${e.data.name.toLocaleLowerCase()}.svg`;
          this.endActions[0].name = e.data.name;
        }
      }
    );
  }

  public async confirmAccount() {
    let loading = await this.loadingController.create({});
    await loading.present();

    let params = new HttpParams();
    params = params.append('currentLanguage', this.globals.currentLanguage);
    params = params.append('needRegister', "true");
    this.http.post(this.globals.apiUrl + '/accounts/login/social', this.currentUser, {params})
      .toPromise()
      .then((response: SiteUser) => {
        if (response !== null) {
          this.tokensService.setTokens(response.witaAuthToken);
          localStorage.setItem('wita-user', JSON.stringify(response));
          this.storage.set('wita-user', response);
          this.globals.owner = response as SiteUser;
          this.authenticationService.afterLoginInitiation();
          this.router.navigate(['/']);
          this.toolbarService.setHeaderVisibility(true);
          this.menuService.activeMainMenu();
          if (loading) {
            loading.dismiss();
          }
        }
      }).catch(async (er) => {
      console.log('throw error after social login request');
      throw er;
    });
  };

  onReferrerInput(): void {
    clearTimeout(this.referrerTypingTimerId);
    this.referrerTypingTimerId = setTimeout(() => {
      this.isCheckingReferrer = true;

      this.app.checkRegisterReferrer(this.referrerToCheck.toLowerCase())
        .subscribe((res: any) =>  {
          this.referrerUser = res && res.message ? res.message : null;
          this.isCheckingReferrer = false;
        });

    }, this.typingTimerInterval);
  }

  canActivate(): boolean {
    let isActive: boolean = true;
    if (!this.globals.owner) {
      this.router.navigate(['/']);
      isActive = false;
    }
    return isActive;
  }

}
