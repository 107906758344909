import {Component, Input, ViewChild} from '@angular/core';
import {NoteService} from '@app/shared/services/note.service';
import {Globals} from '@app/shared/globals';
import {TranslateService} from '@ngx-translate/core';
import {NoteCommentService} from '@app/shared/services/note-comment.service';
import {Note} from './note';
import {ActivatedRoute, Router} from '@angular/router';
import {PrivacyAccessType} from '../../shared/privacy.access-type';
import {CategoryService} from '../../shared/services/category.service';
import {Category} from '@app/modules/category/category';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {DropDownListComponent} from '@app/shared/components/drop-down-list/drop-down-list.component';
import {ModalController, PopoverController} from '@ionic/angular';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Subscription, throwError} from 'rxjs';
import {ADD_NEW_NOT_ACTION, EDIT_NOTE_ACTION} from '@app/shared/actions';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {SiteUserService} from '@app/shared/services/site-user.service';
import {SubscriptionService} from '@app/shared/services/subscription.service';
import {CategoryTreeComponent} from '@app/modules/category-tree/category-tree.component';
import {ClipboardService} from "ngx-clipboard";
import {FeedType} from "@app/modules/home/FeedType";

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss', '../new-note/new-note.component.scss']
})
export class NoteComponent implements UpdateNavigation {
  @Input() noteEntity: Note;
  @ViewChild('quillComponent', {static: false}) quillComponent;
  @ViewChild('catBreadcrumbs', {static: false}) catBreadcrumbs;

  public category: Category;
  public friendAccessType = PrivacyAccessType.FRIENDS;
  public editedWeightLevel: number;
  public accessIndex: number;
  public editedAccessType;
  public showButton = true;
  public inputOnFocus = false;
  public emojiWasClicked = false;
  public group = 'Group';
  public translationText: string[];
  public endActions = [];
  public isEdit = false;

  private canEdit: boolean;
  private selectedAccessType;
  private getNoteSubscription: Subscription;

  closeNote: MenuItem = {
    title: 'Back',
    icon: 'assets/icons/design_v4/back_icon.svg',
    action: () => {
      this.showLess();
    }
  };

  editMenu: MenuItem[] = [
    {
      title: 'Save',
      icon: 'assets/icons/design_v4/check.svg',
      action: this.updateNote.bind(this),
    }
  ];

  cancelEditBtn: MenuItem = {
    title: 'Cancel',
    icon: 'assets/icons/design_v4/cancel.svg',
    action: this.cancelEdit.bind(this),
  };

  unreadMessagesMenuButton: MenuItem = {
    title: 'Notifications',
    icon: 'assets/icons/design_v4/notifications-sharp-unread.svg',
    path: '/notifications',
  };

  messagesMenuButton: MenuItem = {
    title: 'Notifications',
    icon: 'assets/icons/design_v4/notifications-sharp.svg',
    path: '/notifications',
  };

  deleteNoteMenuButton: MenuItem = {
    title: 'Delete',
    icon: 'assets/icons/design_v4/delete.svg',
    action: this.alertBeforeDelete.bind(this),
  };

  editNoteMenuButton: MenuItem = {
    title: 'Edit',
    icon: 'assets/icons/design_v4/edit.svg',
    action: this.editNote.bind(this)
  };

  public privacy: Array<PrivacyAccessType> = [
    PrivacyAccessType.PRIVATE,
    PrivacyAccessType.PUBLIC,
    PrivacyAccessType.FRIENDS
  ];

  constructor(
    public translationService: TranslateService,
    public noteService: NoteService,
    public globals: Globals,
    public commentService: NoteCommentService,
    public router: Router,
    public categoryService: CategoryService,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService,
    private popoverController: PopoverController,
    public menuService: MenuService,
    private uiAlertService: UiAlertService,
    public userService: SiteUserService,
    private subscriptionService: SubscriptionService,
    private modalController: ModalController,
    private clipboard: ClipboardService
  ) {
    this.updateTranslations();
    this.translationService.onLangChange.subscribe((event) => {
      this.updateTranslations();
    });
  }

  ionViewWillEnter() {
    this.toolbarService.setMainHeaderBtn(this.closeNote);
    const noteId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.getNoteSubscription = this.noteService.getNoteById(noteId).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse && error.status !== 401) {
        this.router.navigate(['/']);
        return throwError(error);
      }
    })).subscribe((note: Note) => {
      this.noteEntity = note;

      this.selectedAccessType = this.globals.currentNote.accessType === 0 ? 0 : this.privacy[(this.globals.currentNote.accessType - 1)];
      this.canEdit = this.noteService.canCurrentUserEditNote(this.noteEntity);

      this.updateNavigation();
    });
  }

  ionViewDidLeave() {
    this.toolbarService.setMainHeaderBtn(null);
    this.updateNavigation();
    this.getNoteSubscription.unsubscribe();
  }

  getTagIcon(value) {
    return `assets/icons/design_v4/tag_${value ? value : 5}.svg`;
  }

  showLess() {
    this.toolbarService.setMainHeaderBtn({
      title: 'Menu',
      icon: 'assets/icons/design_v4/menu.svg',
      action: () => {
        this.menuService.toggleMenu();
      }
    });

    this.updateNavigation();
    this.isEdit = false;
    this.navigateToNotes();
  }

  private async alertBeforeDelete() {
    await this.uiAlertService.createConfirm('', this.translationText['Delete the note?'],
      this.translationText['Cancel'], this.translationText['Delete'], () => {
        this.deleteNote();
      });
  }

  deleteNote() {
    if (this.noteEntity.owner.id !== this.globals.owner.id) {
      return;
    }

    this.updateNavigation();
    this.noteService.deleteNote(this.noteEntity, () => {
      this.showLess();
      this.globals.needPageRefresh = true;

      this.globals.noteList = this.globals.noteList.filter((note) => {
        return note.id !== this.noteEntity.id;
      });
    });
  }

  getAccessType(accessType: number) {
    let type;
    if (accessType === 3) {
      type = PrivacyAccessType.PRIVATE;
    }
    if (accessType === 2) {
      type = PrivacyAccessType.FRIENDS;
      this.group = PrivacyAccessType.FRIENDS;
    }
    if (accessType === 1) {
      type = PrivacyAccessType.PUBLIC;
    }
    return type;
  }

  async presentAccessTypeMenu(env) {
    const dropDownMenuItems = {
      data: [
        {
          name: PrivacyAccessType.FRIENDS,
          callback: () => {
            this.changeAccessType(PrivacyAccessType.FRIENDS);
            this.group = PrivacyAccessType.FRIENDS;

          }
        }
      ]
    };

    const popover = await this.popoverController.create({
      component: DropDownListComponent,
      event: env,
      componentProps: dropDownMenuItems,
    });

    await popover.present();
  }

  editNote() {
    if (this.noteEntity.owner.id !== this.globals.owner.id) {
      return;
    }

    this.editedWeightLevel = this.noteEntity.weight;
    this.editedAccessType = this.getAccessType(this.noteEntity.accessType);
    this.accessIndex = this.noteEntity.accessType;
    this.globals.newNoteContent = this.noteEntity.content;
    this.isEdit = true;
    this.toolbarService.setMenuItems(
      this.editMenu
    );
    this.toolbarService.setMainHeaderBtn(
      this.cancelEditBtn
    );
  }

  updateNote() {
    this.quillComponent.parseUrl();
    this.noteEntity.content = this.globals.newNoteContent;
    this.noteEntity.attachments = this.globals.newAttachments;
    this.noteEntity.weight = this.editedWeightLevel;
    this.noteEntity.accessType = this.accessIndex;
    this.noteEntity.parentCategory = this.constructParentCategory();

    this.noteService.editNote(this.noteEntity, () => {
      this.isEdit = false;
      this.globals.needPageRefresh = true;
      this.globals.newAttachments = [];
    });

    this.refreshBreadCrumbs();
    this.toolbarService.setMainHeaderBtn(this.closeNote);
    this.updateNavigation();
  }

  private constructParentCategory() {
    const breadcrumbs = this.catBreadcrumbs.getCurrentBreadCrumb();
    const currentBreadCrumb = breadcrumbs[breadcrumbs.length - 1];
    const parentCategory = new Category();
    parentCategory.id = currentBreadCrumb.id;
    parentCategory.title = currentBreadCrumb.title;
    breadcrumbs.splice(-1, 1);
    parentCategory.parentTitles = breadcrumbs;

    return parentCategory;
  }

  refreshBreadCrumbs() {
    if (this.catBreadcrumbs) {
      this.catBreadcrumbs.updateBreadcrumbs(this.noteEntity.parentCategory);
    }
  }

  refreshComments() {
    this.noteService.refreshNoteById(this.noteEntity.id, response => {
      this.noteEntity = response;
    });
  }

  cancelEdit() {
    this.isEdit = false;
    this.refreshBreadCrumbs();
    this.toolbarService.setMainHeaderBtn(this.closeNote);
    this.updateNavigation();
  }

  changeAccessType(accessType: PrivacyAccessType) {
    this.editedAccessType = accessType;
    if (accessType === PrivacyAccessType.PRIVATE) {
      this.accessIndex = 3;
    }
    if (accessType === PrivacyAccessType.FRIENDS) {
      this.accessIndex = 2;
    }
    if (accessType === PrivacyAccessType.PUBLIC) {
      this.accessIndex = 1;
    }
    this.group = 'Group';
  }

  getDefaultMenu(): MenuItem[] {
    const items: MenuItem[] = [];
    if (this.globals.unreadNotificationCount > 0) {
      items.push(this.unreadMessagesMenuButton);
    } else {
      items.push(this.messagesMenuButton);
    }
    if (this.noteEntity && this.noteEntity.owner.id === this.globals.owner.id) {
      items.push(this.deleteNoteMenuButton);

      items.push(this.editNoteMenuButton);
      return items;
    }

    return items;
  }

  updateNavigation() {
    this.toolbarService.setMenuItems(
      this.getDefaultMenu()
    );
  }

  redirectToNewNote() {
    this.globals.action = ADD_NEW_NOT_ACTION;

    const path = `/categories/${this.noteEntity.parentCategory.id}/new-note`;
    this.router.navigateByUrl(path).then(r => console.log(r));
  }

  navigateToNotes(moveToAnotherUser?: boolean) {
    console.log('nav to note');
    this.toolbarService.setMainHeaderBtn(null);
    if (this.globals.selectedUser.id === this.globals.owner.id && !moveToAnotherUser) {
      this.router.navigate(['/']);
    } else {
      this.globals.allCategories = [];
      this.globals.activeFilters.removeFilter(this.globals.activeFilters.categoryFilter);
      this.userService.setSelectedUser(this.noteEntity.owner);
      this.router.navigate(['/users/' + this.globals.selectedUser.id + '/notes']);
    }
  }

  showPlusButton() {
    (this.inputOnFocus || this.emojiWasClicked) ? this.showButton = false : this.showButton = true;
  }

  private updateTranslations() {
    const translationsSub = this.translationService.get([
      'Delete the note?',
      'Cancel',
      'Delete',
      'This field can`t be empty',
      'Error',
      'Remove',
      'Are you sure you want to remove the comment?'
    ]).subscribe(tr => this.translationText = tr);
    this.subscriptionService.unSubscriber(translationsSub);
  }

  getMod() {
    if (this.isEdit) {
      return 'EDIT';
    } else {
      return 'FILTER';
    }
  }

  async editNoteCategory() {
    this.globals.action = EDIT_NOTE_ACTION;
    const modal: any = await this.modalController.create({
      component: CategoryTreeComponent,
      componentProps: {
        editedNoteCategory: this.noteEntity.parentCategory
      }
    });
    modal.onWillDismiss().then(response => {
      if (response.data) {
        this.categoryService.getCategoryById(response.data.currentCategory).subscribe((data: Category) => {
          this.noteEntity.parentCategory = data;
          this.refreshBreadCrumbs();
        });
      }
    });
    await modal.present();
  }

  copyUsername() {
    this.clipboard.copy(this.globals.clientUrl + this.router.url);
  }
}
