import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { SiteUserService } from '@app/shared/services/site-user.service';
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/services/toolbar/toolbar.service";
import * as i2 from "@ionic/angular";
import * as i3 from "@app/shared/services/site-user.service";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@app/shared/pipes/user-name.pipe";
function ReceivedFromModalComponent_ion_spinner_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-spinner", 12);
} }
function ReceivedFromModalComponent_ion_item_14_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-item", 13);
    i0.ɵɵlistener("click", function ReceivedFromModalComponent_ion_item_14_Template_ion_item_click_0_listener() { i0.ɵɵrestoreView(_r4); const siteUser_r2 = ctx.$implicit; const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.onItemClickHandler(siteUser_r2); });
    i0.ɵɵelementStart(1, "ion-avatar", 14);
    i0.ɵɵelement(2, "img", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "ion-label");
    i0.ɵɵelementStart(4, "h2", 15);
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "userName");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "p", 16);
    i0.ɵɵtext(8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const siteUser_r2 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("src", siteUser_r2.userPhotoName, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(6, 3, siteUser_r2));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(siteUser_r2.ssoId);
} }
const _c0 = function (a0) { return { "padding-top": a0 }; };
export class ReceivedFromModalComponent {
    constructor(toolbarService, modalCtrl, userService) {
        this.toolbarService = toolbarService;
        this.modalCtrl = modalCtrl;
        this.userService = userService;
        this.friends = [];
        this.isLoading = true;
        this.searchValue = '';
    }
    ngOnInit() {
        this.searchUser('', 0);
    }
    searchUser(value, page, callback) {
        this.isLoading = true;
        this.userService.getUsersBy(value, page, 2).subscribe((data) => {
            this.pageData = data;
            this.friends = page === 0 ? this.pageData.content : this.friends.concat(this.pageData.content);
            this.isLoading = false;
            if (callback) {
                callback();
            }
        });
    }
    doRefreshContent(event) {
        this.searchUser('', 0, () => {
            event.target.complete();
        });
    }
    nextPage(event) {
        if (!this.pageData.last) {
            this.searchUser(this.searchValue, this.pageData.number + 1, () => {
                event.target.complete();
            });
        }
        else {
            if (event) {
                event.target.complete();
            }
        }
    }
    searchInputHandler(event) {
        this.searchValue = event;
        this.searchUser(this.searchValue, 0);
    }
    onItemClickHandler(friend) {
        this.modalCtrl.dismiss({
            friend: friend,
        });
    }
    dismiss() {
        this.modalCtrl.dismiss({
            friend: null,
        });
    }
}
ReceivedFromModalComponent.ɵfac = function ReceivedFromModalComponent_Factory(t) { return new (t || ReceivedFromModalComponent)(i0.ɵɵdirectiveInject(i1.ToolbarService), i0.ɵɵdirectiveInject(i2.ModalController), i0.ɵɵdirectiveInject(i3.SiteUserService)); };
ReceivedFromModalComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ReceivedFromModalComponent, selectors: [["app-received-from-modal"]], decls: 17, vars: 9, consts: [["color", "primary", 3, "ngStyle"], ["slot", "start", 1, "left-actions", "ml-3"], ["shape", "round", 3, "click"], [3, "src"], ["color", "white", "translate", "", 1, "ion-text-center"], ["animated", "", 3, "placeholder", "ionChange"], ["name", "crescent", 4, "ngIf"], ["slot", "fixed", 3, "ionRefresh"], [1, "friends-list"], ["class", "on-left-line cursor-pointer", "lines", "none", "style", "margin-right: 2px", 3, "click", 4, "ngFor", "ngForOf"], ["threshold", "100px", 3, "ionInfinite"], ["loadingSpinner", "bubbles", "loadingText", "Loading more data..."], ["name", "crescent"], ["lines", "none", 1, "on-left-line", "cursor-pointer", 2, "margin-right", "2px", 3, "click"], ["slot", "start", 1, "avatar-img"], [1, "user-name"], [1, "user-sub"]], template: function ReceivedFromModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-header");
        i0.ɵɵelementStart(1, "ion-toolbar", 0);
        i0.ɵɵelementStart(2, "ion-buttons", 1);
        i0.ɵɵelementStart(3, "ion-button", 2);
        i0.ɵɵlistener("click", function ReceivedFromModalComponent_Template_ion_button_click_3_listener() { return ctx.dismiss(); });
        i0.ɵɵelement(4, "img", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "ion-title", 4);
        i0.ɵɵtext(6, "Received from");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "ion-content");
        i0.ɵɵelementStart(8, "ion-searchbar", 5);
        i0.ɵɵlistener("ionChange", function ReceivedFromModalComponent_Template_ion_searchbar_ionChange_8_listener($event) { return ctx.searchInputHandler($event.detail.value); });
        i0.ɵɵpipe(9, "translate");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(10, ReceivedFromModalComponent_ion_spinner_10_Template, 1, 0, "ion-spinner", 6);
        i0.ɵɵelementStart(11, "ion-refresher", 7);
        i0.ɵɵlistener("ionRefresh", function ReceivedFromModalComponent_Template_ion_refresher_ionRefresh_11_listener($event) { return ctx.doRefreshContent($event); });
        i0.ɵɵelement(12, "ion-refresher-content");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "ion-list", 8);
        i0.ɵɵtemplate(14, ReceivedFromModalComponent_ion_item_14_Template, 9, 5, "ion-item", 9);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(15, "ion-infinite-scroll", 10);
        i0.ɵɵlistener("ionInfinite", function ReceivedFromModalComponent_Template_ion_infinite_scroll_ionInfinite_15_listener($event) { return ctx.nextPage($event); });
        i0.ɵɵelement(16, "ion-infinite-scroll-content", 11);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(7, _c0, ctx.toolbarService.isNewIphone ? "30px" : "0"));
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("src", "assets/icons/design_v4/back_icon.svg", i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(4);
        i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(9, 5, "Search"));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.isLoading);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngForOf", ctx.friends);
    } }, directives: [i2.IonHeader, i2.IonToolbar, i4.NgStyle, i2.IonButtons, i2.IonButton, i2.IonTitle, i5.TranslateDirective, i2.IonContent, i2.IonSearchbar, i2.TextValueAccessor, i4.NgIf, i2.IonRefresher, i2.IonRefresherContent, i2.IonList, i4.NgForOf, i2.IonInfiniteScroll, i2.IonInfiniteScrollContent, i2.IonSpinner, i2.IonItem, i2.IonAvatar, i2.IonLabel], pipes: [i5.TranslatePipe, i6.UserNamePipe], styles: ["ion-spinner[_ngcontent-%COMP%]{display:block;margin:20px auto;width:40px;height:40px}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ReceivedFromModalComponent, [{
        type: Component,
        args: [{
                selector: 'app-received-from-modal',
                templateUrl: './received-from-modal.component.html',
                styleUrls: ['./received-from-modal.component.css']
            }]
    }], function () { return [{ type: i1.ToolbarService }, { type: i2.ModalController }, { type: i3.SiteUserService }]; }, null); })();
