var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { Camera } from '@ionic-native/camera/ngx';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/camera/ngx";
export class ImageService {
    constructor(camera) {
        this.camera = camera;
    }
    pickImage(sourceType) {
        return __awaiter(this, void 0, void 0, function* () {
            const options = {
                quality: 60,
                sourceType: sourceType === 'lib' ? this.camera.PictureSourceType.PHOTOLIBRARY : this.camera.PictureSourceType.CAMERA,
                destinationType: this.camera.DestinationType.DATA_URL,
                encodingType: this.camera.EncodingType.JPEG,
                mediaType: this.camera.MediaType.PICTURE,
                correctOrientation: true
            };
            return this.camera.getPicture(options);
        });
    }
}
ImageService.ɵfac = function ImageService_Factory(t) { return new (t || ImageService)(i0.ɵɵinject(i1.Camera)); };
ImageService.ɵprov = i0.ɵɵdefineInjectable({ token: ImageService, factory: ImageService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ImageService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.Camera }]; }, null); })();
