import { Pipe } from '@angular/core';
import * as moment from 'moment';
import * as i0 from "@angular/core";
export class DateFormatPipePipe {
    transform(value) {
        return moment(value).format('MM DD YYYY') === 'Invalid date' ? '' : moment(value).format('MM DD YYYY');
    }
}
DateFormatPipePipe.ɵfac = function DateFormatPipePipe_Factory(t) { return new (t || DateFormatPipePipe)(); };
DateFormatPipePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "dateFormatPipe", type: DateFormatPipePipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DateFormatPipePipe, [{
        type: Pipe,
        args: [{
                name: 'dateFormatPipe',
            }]
    }], null, null); })();
