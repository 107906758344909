import {Injectable} from '@angular/core';
import {LoadingController, Platform} from '@ionic/angular';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SiteUser} from '@app/modules/site-user/site-user';
import {Globals} from '@app/shared/globals';
import {AuthenticationService} from '@app/core/auth/authentication.service';
import {Router} from '@angular/router';
import {TokensService} from '@app/shared/services/tokens.service';
import {Storage} from '@ionic/storage';
import {ProfileService} from '@app/shared/services/profile.service';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {OneSignalService} from '@app/shared/services/one-signal.service';

@Injectable({
  providedIn: 'root'
})
export class AppleAuthService {

  loading;
  referrer;

  constructor(
    public platform: Platform,
    private menuService: MenuService,
    private toolbarService: ToolbarService,
    private authenticationService: AuthenticationService,
    public loadingController: LoadingController,
    public globals: Globals,
    public http: HttpClient,
    public router: Router,
    private uiAlertService: UiAlertService,
    private tokensService: TokensService,
    private storage: Storage,
    private profileService: ProfileService,
    private oneSignalService: OneSignalService,
  ) { }

  async authByApple(siteUser, needRegister): Promise<any> {
    this.loading = await this.loadingController.create({});
    await this.loading.present();

    let params = new HttpParams();
    params = params.append('currentLanguage', this.globals.currentLanguage);
    params = params.append('needRegister', needRegister);
    params = params.append('deviceId', this.oneSignalService.getDeviceId());

    return this.http.post(this.globals.apiUrl + '/accounts/login/social', siteUser, {params})
      .toPromise()
      .then(async (response: SiteUser) => {
        if (response !== null) {
          this.tokensService.setTokens(response.witaAuthToken);
          localStorage.setItem('wita-user', JSON.stringify(response));
          this.storage.set('wita-user', response);
          this.globals.owner = response;
          this.globals.owner.provider = 'apple';
          this.profileService.setReferrer(this.globals.owner, this.referrer);
          this.router.navigate(['/']);
          this.authenticationService.afterLoginInitiation();
          this.toolbarService.setHeaderVisibility(true);
          await this.menuService.activeMainMenu();
          this.loading.dismiss();
        }
      }).catch(async er => {
        console.log('throw error after social login request inside apple-auth service');
        throw er;
      });
  }

  async accessWithAppleID(referrer: string, needRegister: boolean) {
    if (this.platform.is('cordova')) {
      (window as any).cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        async (res) => {
          const siteUser = new SiteUser({
            firstName: res.fullName.givenName,
            lastName: res.fullName.familyName,
            ssoId: res.user,
            email: res.email,
            userPhotoName: ''
          });
          this.referrer = referrer;
          this.authByApple(siteUser, needRegister);
        },
        async (er) => {
          await this.authenticationService.checkConfirmationRequired(er);
        }
      );
    }
  }

  private async errorLoginAlert() {
    await this.uiAlertService.createAlert('Error', 'Error logging with AppleID');
  }
}
