<div class="row comment_wrapper comment__row">
  <div class="col comment__user flex-grow-0">
    <div class="comment_img"
         [ngStyle]="{backgroundImage: 'url(' + currentParentComment.owner.userPhotoName + ')'}"
         style="background-size: contain;"></div>
  </div>
  <div class="col comment__content comment_text_container">
      <div (click)="openCommentPopover($event, currentParentComment)">
          <div class="article_text_header">
              <div class="article_text_autor">
                  {{currentParentComment.owner | userName}}
              </div>
          </div>
          <div class="article_text_main">
              <div class="article_text_main_wrapper">
                  <p class="article_text_text select-text">
                      {{currentParentComment.content}}
                  </p>
              </div>
          </div>
      </div>
    <div class="add_comment_place_wrapper" *ngIf="isEdit">
<!--      <div class="row flex-column">-->
        <div class="add_comment_text" id="parentCommentEdit" contenteditable="true"
             (input)="editedCommentContent = $event.target.innerText">
          {{currentParentComment.content}}
        </div>
<!--      </div>-->
      <div class="add_comment_send" (click)="updateComment(currentParentComment)">
      </div>
    </div>

    <div class="article__footer">
      <div class="article_text_date">
        {{dateService.dateConverter(currentParentComment.creationDate) | date:'d.M.yyyy'}}
      </div>

      <div
        class="article-answer"
        (click)="addChildComment()"
        *ngIf="globals.isAuthenticated && !isEdit"
        translate>
        <i class="fa fa-reply"></i> Reply
      </div>
    </div>
    <div
      class="sub-comment__wrapper"
      *ngIf="globals.isAuthenticated"
    >
      <app-child-note-comment-list
        [passCurrentParentComment]="currentParentComment"
        [noteEntity]="noteEntity"
        [translationsText]="translationsText"
      ></app-child-note-comment-list>


    </div>
    <div class="comment-input" *ngIf="isNewChild">
      <app-new-note-comment #newReply
        [passParentComment]="currentParentComment"
        [noteEntity]="noteEntity"
        (haveAddedNewComment)="addedNewComment($event)"
        class="sub-comment"
      ></app-new-note-comment>
    </div>
  </div>
</div>
