var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals } from '@app/shared/globals';
import { TranslateService } from '@ngx-translate/core';
import { IonSearchbar, MenuController } from '@ionic/angular';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { UtilsService } from "@app/shared/services/utils.service";
import { ChatService } from "@app/shared/services/chat.service";
import { MenuService } from "@app/shared/services/menu/menu.service";
import { SharedService } from '@app/shared/services/shared.service';
import { SiteUserService } from "@app/shared/services/site-user.service";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@app/core/auth/authentication.service";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/router";
import * as i5 from "@app/shared/globals";
import * as i6 from "@ionic/angular";
import * as i7 from "@app/shared/services/menu/menu.service";
import * as i8 from "@app/shared/services/toolbar/toolbar.service";
import * as i9 from "@app/shared/services/utils.service";
import * as i10 from "@app/shared/services/chat.service";
import * as i11 from "@app/shared/services/shared.service";
import * as i12 from "@app/shared/services/site-user.service";
import * as i13 from "@angular/common";
import * as i14 from "bdc-walkthrough";
import * as i15 from "@angular/forms";
const _c0 = ["userSearchBar"];
function HeaderComponent_ng_container_0_ion_button_7_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵelement(1, "img", 20);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵnextContext(2);
    const _r5 = i0.ɵɵreference(12);
    const ctx_r9 = i0.ɵɵnextContext();
    i0.ɵɵproperty("bdcWalkTriggerFor", _r5);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", ctx_r9.mainBtn.icon, i0.ɵɵsanitizeUrl)("alt", ctx_r9.mainBtn.title);
} }
function HeaderComponent_ng_container_0_ion_button_7_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "img", 20);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", ctx_r10.mainBtn.icon, i0.ɵɵsanitizeUrl)("alt", ctx_r10.mainBtn.title);
} }
function HeaderComponent_ng_container_0_ion_button_7_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-button", 17);
    i0.ɵɵlistener("click", function HeaderComponent_ng_container_0_ion_button_7_Template_ion_button_click_0_listener($event) { i0.ɵɵrestoreView(_r12); const ctx_r11 = i0.ɵɵnextContext(2); return ctx_r11.applyItem(ctx_r11.mainBtn, $event); });
    i0.ɵɵtemplate(1, HeaderComponent_ng_container_0_ion_button_7_div_1_Template, 2, 3, "div", 18);
    i0.ɵɵtemplate(2, HeaderComponent_ng_container_0_ion_button_7_div_2_Template, 2, 2, "div", 0);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.mainBtn.title === "Back");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.mainBtn.title != "Back");
} }
function HeaderComponent_ng_container_0_ion_button_8_Template(rf, ctx) { if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-button", 17);
    i0.ɵɵlistener("click", function HeaderComponent_ng_container_0_ion_button_8_Template_ion_button_click_0_listener() { i0.ɵɵrestoreView(_r14); const ctx_r13 = i0.ɵɵnextContext(2); return ctx_r13.openMenu(); });
    i0.ɵɵelement(1, "img", 21);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", "assets/icons/design_v4/menu.svg", i0.ɵɵsanitizeUrl);
} }
function HeaderComponent_ng_container_0_ion_searchbar_9_Template(rf, ctx) { if (rf & 1) {
    const _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-searchbar", 22, 23);
    i0.ɵɵlistener("ionChange", function HeaderComponent_ng_container_0_ion_searchbar_9_Template_ion_searchbar_ionChange_0_listener($event) { i0.ɵɵrestoreView(_r17); const ctx_r16 = i0.ɵɵnextContext(2); return ctx_r16.applyItem(ctx_r16.searchbar, $event); })("ionCancel", function HeaderComponent_ng_container_0_ion_searchbar_9_Template_ion_searchbar_ionCancel_0_listener() { i0.ɵɵrestoreView(_r17); const ctx_r18 = i0.ɵɵnextContext(2); return ctx_r18.globals.enabledSearch = false; })("ionBlur", function HeaderComponent_ng_container_0_ion_searchbar_9_Template_ion_searchbar_ionBlur_0_listener($event) { i0.ɵɵrestoreView(_r17); const ctx_r19 = i0.ɵɵnextContext(2); return ctx_r19.blurHandle($event); })("ionFocus", function HeaderComponent_ng_container_0_ion_searchbar_9_Template_ion_searchbar_ionFocus_0_listener() { i0.ɵɵrestoreView(_r17); const ctx_r20 = i0.ɵɵnextContext(2); return ctx_r20.globals.enabledSearch = true; })("ngModelChange", function HeaderComponent_ng_container_0_ion_searchbar_9_Template_ion_searchbar_ngModelChange_0_listener($event) { i0.ɵɵrestoreView(_r17); const ctx_r21 = i0.ɵɵnextContext(2); return ctx_r21.globals.searchbarField = $event; });
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(2, 3, ctx_r3.searchbar.placeholder));
    i0.ɵɵproperty("showCancelButton", true)("ngModel", ctx_r3.globals.searchbarField);
} }
function HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵelement(1, "img", 20);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r23 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵnextContext(2);
    const _r6 = i0.ɵɵreference(17);
    i0.ɵɵproperty("bdcWalkTriggerFor", _r6);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", item_r23.icon, i0.ɵɵsanitizeUrl)("alt", item_r23.title);
} }
function HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵelement(1, "img", 20);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r23 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵnextContext(2);
    const _r7 = i0.ɵɵreference(22);
    i0.ɵɵproperty("bdcWalkTriggerFor", _r7);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", item_r23.icon, i0.ɵɵsanitizeUrl)("alt", item_r23.title);
} }
function HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵelement(1, "img", 20);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r23 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵnextContext(2);
    const _r8 = i0.ɵɵreference(27);
    i0.ɵɵproperty("bdcWalkTriggerFor", _r8);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", item_r23.icon, i0.ɵɵsanitizeUrl)("alt", item_r23.title);
} }
function HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "img", 20);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r23 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", item_r23.icon, i0.ɵɵsanitizeUrl)("alt", item_r23.title);
} }
function HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_Template(rf, ctx) { if (rf & 1) {
    const _r33 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-button", 17);
    i0.ɵɵlistener("click", function HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_Template_ion_button_click_0_listener($event) { i0.ɵɵrestoreView(_r33); const item_r23 = ctx.$implicit; const ctx_r32 = i0.ɵɵnextContext(3); return ctx_r32.applyItem(item_r23, $event); });
    i0.ɵɵtemplate(1, HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_div_1_Template, 2, 3, "div", 18);
    i0.ɵɵtemplate(2, HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_div_2_Template, 2, 3, "div", 18);
    i0.ɵɵtemplate(3, HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_div_3_Template, 2, 3, "div", 18);
    i0.ɵɵtemplate(4, HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_div_4_Template, 2, 2, "div", 0);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r23 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", item_r23.title === "Category tree");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", item_r23.title === "Go to next step");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", item_r23.title === "Add New Note");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", item_r23.title != "Category tree" && item_r23.title != "Back" && item_r23.title != "Go to next step" && item_r23.title != "Add New Note");
} }
function HeaderComponent_ng_container_0_ion_buttons_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-buttons", 24);
    i0.ɵɵtemplate(1, HeaderComponent_ng_container_0_ion_buttons_10_ion_button_1_Template, 5, 4, "ion-button", 25);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r4.items);
} }
const _c1 = function (a0) { return { "height": a0 }; };
const _c2 = function (a0, a1) { return { "padding-top": a0, "height": a1 }; };
const _c3 = function () { return { welcomeGuideShowWalkthrough: true, welcomeGuideTask1: true }; };
const _c4 = function () { return { welcomeGuideShowWalkthrough: true, welcomeGuideTask2: true }; };
const _c5 = function () { return { addNoteGuideShowWalkthrough: true, addNoteGuideTask8: true }; };
const _c6 = function () { return { addNoteGuideShowWalkthrough: true, addNoteGuideTask10: true }; };
function HeaderComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementContainerStart(1);
    i0.ɵɵelementStart(2, "ion-header", 1);
    i0.ɵɵelementStart(3, "ion-toolbar", 2);
    i0.ɵɵelementStart(4, "ion-title", 3);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "ion-buttons", 4);
    i0.ɵɵtemplate(7, HeaderComponent_ng_container_0_ion_button_7_Template, 3, 2, "ion-button", 5);
    i0.ɵɵtemplate(8, HeaderComponent_ng_container_0_ion_button_8_Template, 2, 1, "ion-button", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(9, HeaderComponent_ng_container_0_ion_searchbar_9_Template, 3, 5, "ion-searchbar", 6);
    i0.ɵɵtemplate(10, HeaderComponent_ng_container_0_ion_buttons_10_Template, 2, 1, "ion-buttons", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "bdc-walk-popup", 8, 9);
    i0.ɵɵelementStart(13, "span", 10);
    i0.ɵɵtext(14, "Click the arrow above to get back");
    i0.ɵɵelement(15, "br");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "bdc-walk-popup", 11, 12);
    i0.ɵɵelementStart(18, "span", 10);
    i0.ɵɵtext(19, "Click to filter by a Category");
    i0.ɵɵelement(20, "br");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(21, "bdc-walk-popup", 13, 14);
    i0.ɵɵelementStart(23, "span", 10);
    i0.ɵɵtext(24, "Click when done");
    i0.ɵɵelement(25, "br");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(26, "bdc-walk-popup", 15, 16);
    i0.ɵɵelementStart(28, "span", 10);
    i0.ɵɵtext(29, "Save");
    i0.ɵɵelement(30, "br");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(19, _c1, ctx_r0.toolbarService.isNewIphone ? "74px" : "54px"));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(21, _c2, ctx_r0.toolbarService.isNewIphone ? "30px" : "10px", ctx_r0.toolbarService.isNewIphone ? "74px" : "54px"));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.title);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.mainBtn);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.mainBtn);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.searchbar != null);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.globals.enabledSearch);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("mustCompleted", i0.ɵɵpureFunction0(24, _c3))("showCloseButton", false)("closeOnClick", true);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("mustCompleted", i0.ɵɵpureFunction0(25, _c4))("showCloseButton", false)("closeOnClick", true);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("mustCompleted", i0.ɵɵpureFunction0(26, _c5))("showCloseButton", false)("closeOnClick", true);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("mustCompleted", i0.ɵɵpureFunction0(27, _c6))("showCloseButton", false)("closeOnClick", true);
} }
export class HeaderComponent {
    constructor(translate, app, http, router, globals, menuCtrl, mainMenuService, toolbarService, utilsService, chatService, sharedService, siteUserService) {
        this.translate = translate;
        this.app = app;
        this.http = http;
        this.router = router;
        this.globals = globals;
        this.menuCtrl = menuCtrl;
        this.mainMenuService = mainMenuService;
        this.toolbarService = toolbarService;
        this.utilsService = utilsService;
        this.chatService = chatService;
        this.sharedService = sharedService;
        this.siteUserService = siteUserService;
        this.wita = 'wita';
        this.isNewSiteUser = false;
        this.isVisible = true;
        this.actions = [];
        this.walkThroughEvent = new EventEmitter();
        translate.setDefaultLang(this.globals.currentLanguage);
        this.titleSubscription = toolbarService.title$.subscribe(title => {
            this.title = title;
        });
        this.itemsSubscription = toolbarService.items$.subscribe(items => {
            this.items = items;
            this.globals.currentHeader = this.items;
        });
        this.headerVisibilitySubscription = toolbarService.headerVisibility$.subscribe(isVisible => {
            this.isVisible = isVisible;
        });
        this.mainBtnSubscription = toolbarService.mainBtn$.subscribe(mainBtn => {
            this.mainBtn = mainBtn;
        });
        this.searchbarSubscription = toolbarService.searchbar$.subscribe(item => {
            this.searchbar = item;
        });
    }
    ngOnInit() {
        if (this.globals.isNewUser && this.globals.isAuthenticated) {
            setTimeout(() => this.callParent(), 3000);
        }
    }
    callParent() {
        console.log("onInit header !!! ");
        this.walkThroughEvent.next('startWalkthrough');
    }
    ngOnDestroy() {
        this.itemsSubscription.unsubscribe();
        this.titleSubscription.unsubscribe();
        this.headerVisibilitySubscription.unsubscribe();
        this.mainBtnSubscription.unsubscribe();
        this.searchbarSubscription.unsubscribe();
    }
    applyItem(item, env) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.menuCtrl.close('feed');
            if (item.action) {
                return item.action(env);
            }
            if (item.path) {
                if (item.path == '/chat') {
                    this.router.navigateByUrl(item.path).then(event => {
                        console.log(event ? 'Modified navigation is successful!' : 'Modified navigation has failed!');
                        this.sharedService.emitChange({
                            data: undefined,
                            action: 'open-chatlist'
                        });
                    });
                }
                else {
                    this.router.navigateByUrl(item.path).then(event => {
                        console.log(event ? 'Navigation is successful!' : 'Navigation has failed!');
                    });
                }
            }
        });
    }
    blurHandle(event) {
        event.stopPropagation();
    }
    openMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.menuCtrl.close('feed');
            this.globals.enabledSearch = false;
            this.mainMenuService.toggleMenu();
        });
    }
    focusOnInput() {
        setTimeout(() => {
            this.userSearchBar.setFocus().then();
        }, 300);
    }
}
HeaderComponent.ɵfac = function HeaderComponent_Factory(t) { return new (t || HeaderComponent)(i0.ɵɵdirectiveInject(i1.TranslateService), i0.ɵɵdirectiveInject(i2.AuthenticationService), i0.ɵɵdirectiveInject(i3.HttpClient), i0.ɵɵdirectiveInject(i4.Router), i0.ɵɵdirectiveInject(i5.Globals), i0.ɵɵdirectiveInject(i6.MenuController), i0.ɵɵdirectiveInject(i7.MenuService), i0.ɵɵdirectiveInject(i8.ToolbarService), i0.ɵɵdirectiveInject(i9.UtilsService), i0.ɵɵdirectiveInject(i10.ChatService), i0.ɵɵdirectiveInject(i11.SharedService), i0.ɵɵdirectiveInject(i12.SiteUserService)); };
HeaderComponent.ɵcmp = i0.ɵɵdefineComponent({ type: HeaderComponent, selectors: [["app-header-toolbar"]], viewQuery: function HeaderComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.userSearchBar = _t.first);
    } }, inputs: { directoryName: "directoryName", actions: "actions" }, outputs: { walkThroughEvent: "walkThroughEvent" }, decls: 1, vars: 1, consts: [[4, "ngIf"], [3, "ngStyle"], ["color", "primary", 1, "header-tools", 3, "ngStyle"], ["color", "light", "translate", "", 1, "ion-text-center"], ["slot", "start", 1, "left-actions", "ml-3"], [3, "click", 4, "ngIf"], ["slot", "start", "class", "searchbar-header", "animated", "", "mode", "ios", "search-icon", "", 3, "placeholder", "showCancelButton", "ngModel", "ionChange", "ionCancel", "ionBlur", "ionFocus", "ngModelChange", 4, "ngIf"], ["slot", "end", 4, "ngIf"], ["name", "welcomeGuideTask2", "xPosition", "after", 1, "custom-popup", 3, "mustCompleted", "showCloseButton", "closeOnClick"], ["welcomeGuideTask2", ""], ["translate", ""], ["name", "welcomeGuideTask3", "xPosition", "before", 1, "custom-popup", 3, "mustCompleted", "showCloseButton", "closeOnClick"], ["welcomeGuideTask3", ""], ["name", "addNoteGuideTask9", "xPosition", "before", "yPosition", "below", 1, "custom-popup", 3, "mustCompleted", "showCloseButton", "closeOnClick"], ["addNoteGuideTask9", ""], ["name", "addNoteGuideTask11", "xPosition", "before", "yPosition", "below", 1, "custom-popup", 3, "mustCompleted", "showCloseButton", "closeOnClick"], ["addNoteGuideTask11", ""], [3, "click"], [3, "bdcWalkTriggerFor", 4, "ngIf"], [3, "bdcWalkTriggerFor"], [3, "src", "alt"], [3, "src"], ["slot", "start", "animated", "", "mode", "ios", "search-icon", "", 1, "searchbar-header", 3, "placeholder", "showCancelButton", "ngModel", "ionChange", "ionCancel", "ionBlur", "ionFocus", "ngModelChange"], ["userSearchBar", ""], ["slot", "end"], [3, "click", 4, "ngFor", "ngForOf"]], template: function HeaderComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, HeaderComponent_ng_container_0_Template, 31, 28, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.isVisible);
    } }, directives: [i13.NgIf, i6.IonHeader, i13.NgStyle, i6.IonToolbar, i6.IonTitle, i1.TranslateDirective, i6.IonButtons, i14.BdcWalkPopupComponent, i6.IonButton, i14.BdcWalkTriggerDirective, i6.IonSearchbar, i6.TextValueAccessor, i15.NgControlStatus, i15.NgModel, i13.NgForOf], pipes: [i1.TranslatePipe], styles: [".main_logo_green[_ngcontent-%COMP%]{height:4px;width:4px;background-color:#00bfa5;display:inline-block;border-radius:60px;margin:0 2px}ion-header[_ngcontent-%COMP%]{background-color:#48cdc5}.header-md[_ngcontent-%COMP%]{position:fixed;top:0;left:0;width:100%;height:44px}.header-md[_ngcontent-%COMP%]   ion-menu-button[_ngcontent-%COMP%]{width:40px;height:40px}.header-tools[_ngcontent-%COMP%]{margin:0 auto;max-width:900px;height:44px;display:flex;align-items:center}.left-actions[_ngcontent-%COMP%]   ion-menu-button[_ngcontent-%COMP%]   .action-button[_ngcontent-%COMP%]{width:21px;height:21px}.searchbar-header[_ngcontent-%COMP%]{margin-top:5px;width:85%;color:#fff!important;--color:white;--icon-color:white;--cancel-button-color:white;--clear-button-color:white;--placeholder-color:white;--placeholder-opacity:0.7;--background:rgba(0, 0, 0, 0.15)}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(HeaderComponent, [{
        type: Component,
        args: [{
                selector: 'app-header-toolbar',
                templateUrl: './header.component.html',
                styleUrls: ['./header.component.scss']
            }]
    }], function () { return [{ type: i1.TranslateService }, { type: i2.AuthenticationService }, { type: i3.HttpClient }, { type: i4.Router }, { type: i5.Globals }, { type: i6.MenuController }, { type: i7.MenuService }, { type: i8.ToolbarService }, { type: i9.UtilsService }, { type: i10.ChatService }, { type: i11.SharedService }, { type: i12.SiteUserService }]; }, { directoryName: [{
            type: Input
        }], actions: [{
            type: Input
        }], userSearchBar: [{
            type: ViewChild,
            args: ['userSearchBar', { static: false }]
        }], walkThroughEvent: [{
            type: Output
        }] }); })();
