import { Component } from '@angular/core';
import { ToolbarService } from "@app/shared/services/toolbar/toolbar.service";
import { Globals } from "@app/shared/globals";
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/services/toolbar/toolbar.service";
import * as i2 from "@app/shared/globals";
import * as i3 from "@angular/router";
export class UsersPanelComponent {
    constructor(toolbarService, globals, router) {
        this.toolbarService = toolbarService;
        this.globals = globals;
        this.router = router;
        this.headerActions = [
            {
                title: 'Back',
                path: '/admin',
                icon: 'assets/icons/design_v4/back_icon.svg',
            },
        ];
    }
    ngOnInit() {
    }
    updateNavigation() {
        this.toolbarService.setMenuItems(this.headerActions);
    }
    canActivate() {
        let isActive = true;
        if (!this.globals.isAdmin) {
            this.router.navigate(['/']);
            isActive = false;
        }
        return isActive;
    }
}
UsersPanelComponent.ɵfac = function UsersPanelComponent_Factory(t) { return new (t || UsersPanelComponent)(i0.ɵɵdirectiveInject(i1.ToolbarService), i0.ɵɵdirectiveInject(i2.Globals), i0.ɵɵdirectiveInject(i3.Router)); };
UsersPanelComponent.ɵcmp = i0.ɵɵdefineComponent({ type: UsersPanelComponent, selectors: [["app-users-panel"]], decls: 2, vars: 0, template: function UsersPanelComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "p");
        i0.ɵɵtext(1, "users-panel works!");
        i0.ɵɵelementEnd();
    } }, styles: [""] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UsersPanelComponent, [{
        type: Component,
        args: [{
                selector: 'app-users-panel',
                templateUrl: './users-panel.component.html',
                styleUrls: ['./users-panel.component.css']
            }]
    }], function () { return [{ type: i1.ToolbarService }, { type: i2.Globals }, { type: i3.Router }]; }, null); })();
