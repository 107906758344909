import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Note } from '@app/modules/note/note';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../note-comment/note-comment.component";
function NoteCommentListComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "app-note-comment", 2);
    i0.ɵɵlistener("haveDeletedComment", function NoteCommentListComponent_div_0_Template_app_note_comment_haveDeletedComment_1_listener($event) { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.deletedComment($event); })("haveAddedNewComment", function NoteCommentListComponent_div_0_Template_app_note_comment_haveAddedNewComment_1_listener($event) { i0.ɵɵrestoreView(_r3); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.addedNewComment($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const comment_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("currentParentComment", comment_r1)("noteEntity", ctx_r0.noteEntity)("translationsText", ctx_r0.translationsText);
} }
export class NoteCommentListComponent {
    constructor() {
        this.haveAddedNewComment = new EventEmitter();
    }
    ngOnInit() {
    }
    deletedComment(comment) {
        this.comments.splice(this.comments.findIndex((item) => item.id === comment.id), 1);
    }
    addedNewComment($event) {
        this.haveAddedNewComment.emit($event);
    }
}
NoteCommentListComponent.ɵfac = function NoteCommentListComponent_Factory(t) { return new (t || NoteCommentListComponent)(); };
NoteCommentListComponent.ɵcmp = i0.ɵɵdefineComponent({ type: NoteCommentListComponent, selectors: [["app-note-comment-list"]], inputs: { comments: "comments", noteEntity: "noteEntity", translationsText: "translationsText" }, outputs: { haveAddedNewComment: "haveAddedNewComment" }, decls: 1, vars: 1, consts: [["class", "comments", 4, "ngFor", "ngForOf"], [1, "comments"], [3, "currentParentComment", "noteEntity", "translationsText", "haveDeletedComment", "haveAddedNewComment"]], template: function NoteCommentListComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, NoteCommentListComponent_div_0_Template, 2, 3, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngForOf", ctx.comments);
    } }, directives: [i1.NgForOf, i2.NoteCommentComponent], styles: [""] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NoteCommentListComponent, [{
        type: Component,
        args: [{
                selector: 'app-note-comment-list',
                templateUrl: './note-comment-list.component.html',
                styleUrls: ['./note-comment-list.component.css']
            }]
    }], function () { return []; }, { haveAddedNewComment: [{
            type: Output
        }], comments: [{
            type: Input
        }], noteEntity: [{
            type: Input
        }], translationsText: [{
            type: Input
        }] }); })();
