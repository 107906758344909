import {Component, Inject, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ProfileService} from '@app/shared/services/profile.service';
import {DOCUMENT} from '@angular/common';
import {Globals} from '@app/shared/globals';
import {UserLanguage} from '../site-user/user-language';
import {ProfileLanguage} from '../site-user/profile-language';
import {Proficiency} from '../site-user/proficiency';
import {LoadingController, Platform, PopoverController} from '@ionic/angular';
import {ImageService} from '@app/shared/services/image.service';
import {Storage} from '@ionic/storage';
import {UserSocialLink} from '../site-user/user-social-link';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {Router} from '@angular/router';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {ClipboardService} from 'ngx-clipboard';
import {SiteUserInfo} from '@app/modules/site-user/site-user-info';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css', '../settings/settings.component.css']
})
export class ProfileComponent implements UpdateNavigation {
  public destroy$ = new Subject<void>();
  public profileLanguages: Array<UserLanguage>;
  @ViewChild('profileHeader', {static: false}) profileHeader;

  public socialNetworks: UserSocialLink[] = [];

  popover: any;
  loading: any;

  isEditingMode = false;
  profileForm: FormGroup;

  baseActions: MenuItem[] = [
    {
      title: 'Edit',
      icon: 'assets/icons/design_v4/edit.svg',
      action: this.edit.bind(this),
    }
  ];


  mainHeaderButton: MenuItem = {
    title: 'Menu',
    icon: 'assets/icons/design_v4/menu.svg',
    action: () => {
      this.menuService.toggleMenu();
    }
  };

  done: MenuItem[] = [{
    title: 'Done',
    icon: 'assets/icons/design_v4/check.svg',
    action: () => {
      this.edit();
      this.isEditingMode = false;
      this.menuService.activeMainMenu();
    }
  }];

  stopEditing: MenuItem = {
    title: 'Close',
    icon: 'assets/icons/design_v4/cancel.svg',
    action: () => {
      this.isEditingMode = false;
      this.updateNavigation();
      this.initializeFields();
      this.menuService.activeMainMenu();
    }
  };

  constructor(
    private loadingController: LoadingController,
    public popoverController: PopoverController,
    private platform: Platform,
    private imageService: ImageService,
    public globals: Globals,
    public profileService: ProfileService,
    private storage: Storage,
    @Inject(DOCUMENT) public document: Document,
    private formBuilder: FormBuilder,
    private toolbarService: ToolbarService,
    private menuService: MenuService,
    public router: Router,
    private clipboard: ClipboardService,
  ) {
    this.profileForm = this.formBuilder.group({
      location: [null, []],
      dateOfBirth: [null, []],
      email: [null, [Validators.required]],
      ssiod: [null, [Validators.required]],
    });
  }

  ionViewWillEnter() {
    this.globals.selectedUser = this.globals.owner;
    this.toolbarService.setMainHeaderBtn(this.mainHeaderButton);
    this.toolbarService.setMenuItems(this.baseActions);

    this.profileService.getUserInfo(this.globals.owner).subscribe((data: SiteUserInfo) => {
      this.globals.selectedUserInfo = data;
      this.initializeFields();
      this.profileHeader.init(this.globals.owner.id, this.globals.selectedUserInfo);
    });
    this.profileService.getAvailableLanguages().subscribe((data: ProfileLanguage[]) => {
      this.globals.profileLanguageList = data;
    });
    this.profileService.getProficiencyList().subscribe((data: Proficiency[]) => {
      this.globals.proficiencyList = data;
    });
  }

  async edit() {
    this.isEditingMode = !this.isEditingMode;

    if (this.isEditingMode) {
      this.toolbarService.setMainHeaderBtn(this.stopEditing);
      this.toolbarService.setMenuItems(this.done);
    } else {
      this.toolbarService.setMainHeaderBtn(this.mainHeaderButton);
      this.toolbarService.setMenuItems(this.baseActions);
    }

    if (!this.isEditingMode) {
      this.profileHeader.saveInfo(this.profileForm, this.profileLanguages, this.socialNetworks);
      await this.menuService.activeMainMenu();
    }
  }

  languageUpdateHandler(language, profileId) {
    this.profileLanguages[profileId].language = this.globals.profileLanguageList.filter(p => p.id === language)[0];
  }

  isDisabled(lang: ProfileLanguage) {
    for (let i = 0; i < this.profileLanguages.length; i++) {
      if (this.profileLanguages[i].language.id === lang.id) {
        return true;
      }
    }
    return false;
  }

  levelUpdateHandler(levelId, profileId) {
    this.profileLanguages[profileId].proficiency = this.globals.proficiencyList.filter(l => l.id === levelId)[0];
  }

  addLanguageRow() {
    const newUserLanguageRow = new UserLanguage();
    newUserLanguageRow.language = new ProfileLanguage();
    newUserLanguageRow.proficiency = new Proficiency();
    newUserLanguageRow.siteUserInfo = this.globals.selectedUserInfo.id;

    this.profileLanguages.push(newUserLanguageRow);
  }

  addSocialLink() {
    const newUserSocialLink = new UserSocialLink();
    newUserSocialLink.link = '';
    newUserSocialLink.network = null;
    newUserSocialLink.isEditing = true;
    newUserSocialLink.siteUserInfoId = this.globals.selectedUserInfo.id;
    this.socialNetworks.push(newUserSocialLink);
    console.log(this.socialNetworks);
  }

  removeLanguageRow(index) {
    this.profileLanguages.splice(index, 1);
  }

  removeSocialLink(index) {
    this.socialNetworks.splice(index, 1);
  }

  initializeFields() {
    const siteUserInfo = this.globals.selectedUserInfo;
    const dob = siteUserInfo.birthDate === null ? null : new Date(siteUserInfo.birthDate).toISOString();
    this.profileForm.controls['dateOfBirth'].setValue(dob);
    this.profileForm.controls['email'].setValue(siteUserInfo.owner.email);
    this.profileForm.controls['ssiod'].setValue(siteUserInfo.owner.ssoId);
    this.profileForm.controls['location'].setValue(siteUserInfo.city);
    this.profileLanguages = siteUserInfo.userLanguageDtos === null ? null : siteUserInfo.userLanguageDtos;
    this.socialNetworks = siteUserInfo.userSocialLinkDtos === null || siteUserInfo.userSocialLinkDtos === undefined ? [] : siteUserInfo.userSocialLinkDtos;
  }

  ionViewDidLeave(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  updateNavigation() {
    this.toolbarService.setMainHeaderBtn({
      title: 'Menu',
      icon: 'assets/icons/design_v4/menu.svg',
      action: () => {
        this.menuService.toggleMenu();
      }
    });

    this.toolbarService.setMenuItems(this.baseActions);
  }

  copyUsername() {
    this.clipboard.copy(this.globals.selectedUserInfo.owner.ssoId);
    // document.execCommand('copy');
  }
}
