<ion-content>
  <div class="row">
    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
      <div class="row">
        <div class="col-12">
          <p class="filter-title" translate>By Date</p>
        </div>
        <div class="col-12">
          <ion-item class="row text-center col-12">
            <div class="col-6">
              <p class="date-filter-title" translate>from</p>
              <ion-datetime
                value="2018-04-15"
                min="2018-02"
                [(ngModel)]="globals.activeFilters.dateFilter.dateFrom"
                max="2100"
                displayFormat="DD MMM YYYY"
                pickerFormat="DD.MMMM.YYYY"
              ></ion-datetime>
            </div>
            <div class="col-6">
              <p class="date-filter-title" translate>to</p>
              <ion-datetime
                value="2018-04-15"
                min="2018-02"
                [(ngModel)]="globals.activeFilters.dateFilter.dateTo"
                max="2100"
                displayFormat="DD MMM YYYY"
                pickerFormat="DD.MMMM.YYYY"
              ></ion-datetime>
            </div>
          </ion-item>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p class="filter-title" translate>By Weight</p>
        </div>
        <div class="col-12">
          <ion-item class="row text-center col-12">
            <div class="col-1"></div>
            <input class="col-10"
                   type="range"
                   min="1"
                   max="5"
                   [(ngModel)]="globals.activeFilters.weightFilter.weight"
            />
            <div class="col-1"></div>
            <div class="tickmarks_container">
              <div class="tickmark"></div>
              <div class="tickmark"></div>
              <div class="tickmark"></div>
              <div class="tickmark"></div>
              <div class="tickmark"></div>
            </div>
          </ion-item>
        </div>
      </div>

      <div class="row" *ngIf="globals.selectedUser.id === globals.owner.id && (globals.feed.feedType === feedService.getFeedType().HOME || globals.feed.feedType === feedService.getFeedType().USER)">
        <div class="col-12">
          <p class="filter-title" translate>By Access</p>
        </div>
        <div class="col-12">

          <div class="button-group">
            <ng-container *ngFor="let accessType of privacy">
              <button
                      class="button-group__item"
                      [class.button-group__item--active]="selectedAccess === accessType"
                      (click)="changeAccessType(accessType)"
              >
                {{ accessType| translate }}
              </button>
            </ng-container>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="reset-all" (click)="clearAllFilters()" translate>Clear all filters</p>
        </div>
      </div>
    </div>
  </div>
</ion-content>
