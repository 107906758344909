import { Component } from '@angular/core';
import { EditorsService } from '@app/shared/services/editors.service';
import { Globals } from '@app/shared/globals';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { Router } from '@angular/router';
import { VotingService } from "@app/shared/services/voting-service";
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/services/editors.service";
import * as i2 from "@app/shared/globals";
import * as i3 from "@angular/router";
import * as i4 from "@app/shared/services/toolbar/toolbar.service";
import * as i5 from "@app/shared/services/voting-service";
import * as i6 from "@ionic/angular";
import * as i7 from "@angular/common";
import * as i8 from "./editors-row/editors-row.component";
function EditorsPageComponent_div_1_div_2_app_editors_row_5_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-editors-row", 6);
    i0.ɵɵlistener("categoryApproved", function EditorsPageComponent_div_1_div_2_app_editors_row_5_Template_app_editors_row_categoryApproved_0_listener($event) { i0.ɵɵrestoreView(_r8); const ctx_r7 = i0.ɵɵnextContext(3); return ctx_r7.categoryApproved($event); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const subItem_r5 = ctx.$implicit;
    const i_r6 = ctx.index;
    const k_r3 = i0.ɵɵnextContext().index;
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("discuss", ctx_r4.discuss[i_r6 + k_r3])("category", subItem_r5);
} }
function EditorsPageComponent_div_1_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 4);
    i0.ɵɵelementStart(2, "span");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div");
    i0.ɵɵtemplate(5, EditorsPageComponent_div_1_div_2_app_editors_row_5_Template, 1, 2, "app-editors-row", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r2 = ctx.$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(item_r2[0]);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", item_r2[1]);
} }
function EditorsPageComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵtemplate(2, EditorsPageComponent_div_1_div_2_Template, 6, 2, "div", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r0.objDateToArr);
} }
export class EditorsPageComponent {
    constructor(editorsService, globals, router, toolbarService, votingService) {
        this.editorsService = editorsService;
        this.globals = globals;
        this.router = router;
        this.toolbarService = toolbarService;
        this.votingService = votingService;
        this.currentPage = 0;
        this.filtredRowsByDate = {};
        this.objDateToArr = [];
        this.headerActions = [{
                icon: 'assets/icons/design_v4/filter.svg',
                title: 'Filter',
                action: this.onToggleNoteFilters.bind(this),
            }];
        this.editorsLoaded = false;
    }
    ionViewWillEnter() {
        this.editorsService.getRecentPrivateCategories(0).subscribe((response) => {
            this.recentCategories = response;
            this.rows = this.recentCategories.content;
            this.filterRows(this.rows);
        });
        this.editorsService.getRecentPrivateCategoriesDiscussions(0).subscribe((discuss) => {
            this.recentDiscussions = discuss;
            this.discuss = this.recentDiscussions.content;
        });
        this.votingService.updateQuestionsAndAnswers();
        this.editorsService.initializeCategoryLangs().then(() => this.editorsLoaded = true);
    }
    updateNavigation() {
        this.toolbarService.setMenuItems(this.headerActions);
    }
    onToggleNoteFilters() {
        this.router.navigate(['note-filters']);
    }
    getPage() {
        this.currentPage++;
        this.editorsService.getRecentPrivateCategories(this.currentPage).subscribe((categories) => {
            this.editorsService.getRecentPrivateCategoriesDiscussions(this.currentPage).subscribe((discuss) => {
                this.recentCategories = categories;
                this.recentDiscussions = discuss;
                this.rows = this.rows.concat(this.recentCategories.content);
                this.discuss = this.discuss.concat(this.recentDiscussions.content);
            });
        });
    }
    filterRows(arr) {
        console.log(arr, 'this.filtredRowsByDate');
        this.filtredRowsByDate = [];
        this.objDateToArr = [];
        let date;
        arr.map((x, key) => {
            date = new Date(new Date(arr[key].creationDate));
            let strDate = date.toString();
            let name = strDate.slice(4, 15).trim();
            if (!this.filtredRowsByDate.hasOwnProperty(name)) {
                this.filtredRowsByDate[name] = [x];
            }
            else {
                this.filtredRowsByDate[name].push(x);
            }
        });
        console.log(name, this.filtredRowsByDate);
        for (let key in this.filtredRowsByDate) {
            this.objDateToArr.push([key, this.filtredRowsByDate[key]]);
        }
        console.log(this.objDateToArr);
    }
    categoryApproved(event) {
        if (event.approved) {
            this.rows.splice(this.rows.findIndex((item) => item.id === event.currentCategory), 1);
            this.discuss.splice(this.discuss.findIndex((item) => item.id === event.currentDiscussion), 1);
            this.filterRows(this.rows);
        }
    }
    canActivate() {
        let isActive = true;
        if (!this.globals.isAdmin && !this.globals.isEditor) {
            this.router.navigate(['/']);
            isActive = false;
        }
        return isActive;
    }
}
EditorsPageComponent.ɵfac = function EditorsPageComponent_Factory(t) { return new (t || EditorsPageComponent)(i0.ɵɵdirectiveInject(i1.EditorsService), i0.ɵɵdirectiveInject(i2.Globals), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i4.ToolbarService), i0.ɵɵdirectiveInject(i5.VotingService)); };
EditorsPageComponent.ɵcmp = i0.ɵɵdefineComponent({ type: EditorsPageComponent, selectors: [["app-editors-page"]], decls: 2, vars: 1, consts: [["class", "container", 4, "ngIf"], [1, "container"], [1, "editors"], [4, "ngFor", "ngForOf"], [1, "editors-category__timestamp"], ["class", "editors-category__list", 3, "discuss", "category", "categoryApproved", 4, "ngFor", "ngForOf"], [1, "editors-category__list", 3, "discuss", "category", "categoryApproved"]], template: function EditorsPageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-content");
        i0.ɵɵtemplate(1, EditorsPageComponent_div_1_Template, 3, 1, "div", 0);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.editorsLoaded);
    } }, directives: [i6.IonContent, i7.NgIf, i7.NgForOf, i8.EditorsRowComponent], styles: ["app-editors-page.ion-page{overflow:auto}.editors-category__timestamp[_ngcontent-%COMP%]{position:relative}.editors-category__timestamp[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{position:relative;z-index:1;background:#fff;padding:0 5px;color:rgba(0,0,0,.3);font-size:12px}.editors-category__timestamp[_ngcontent-%COMP%]:after{content:\"\";position:absolute;left:0;right:0;top:50%;bottom:0;height:1px;z-index:0;background-color:#d8d8d8}.editors-category__list[_ngcontent-%COMP%]{position:relative;display:block;padding:10px 6px;border-bottom:1px solid #d8d8d8;margin-bottom:5px}.editors-category__list[_ngcontent-%COMP%]:last-of-type{border-bottom:none;margin-bottom:0}.editors[_ngcontent-%COMP%]{padding-top:10px;text-align:center;padding-bottom:20px}.editors-row[_ngcontent-%COMP%]{display:block}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EditorsPageComponent, [{
        type: Component,
        args: [{
                selector: 'app-editors-page',
                templateUrl: './editors-page.component.html',
                styleUrls: ['./editors-page.component.scss']
            }]
    }], function () { return [{ type: i1.EditorsService }, { type: i2.Globals }, { type: i3.Router }, { type: i4.ToolbarService }, { type: i5.VotingService }]; }, null); })();
