import {Directive, HostListener} from "@angular/core";
import {PhotoViewer} from "@ionic-native/photo-viewer/ngx";
import {ModalController} from "@ionic/angular";
import {ViewerModalComponent} from "ngx-ionic-image-viewer";

@Directive({
  selector: '[appImageOpenerCust]'
})
export class CastomIonImgViewerDirective {

  constructor(public modalController: ModalController) {
  }

  @HostListener('click', ['$event.target'])
  mouseDown(targetElement) {
    if (targetElement.nodeName === "IMG") {
      this.openViewer(targetElement);
    }
  }

  async openViewer(targetElement) {
    const modal = await this.modalController.create({
      component: ViewerModalComponent,
      componentProps: {
        src: targetElement.src
      },
      cssClass: 'ion-img-viewer',
      keyboardClose: true,
      showBackdrop: true
    });

    return await modal.present();
  }

}
