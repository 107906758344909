import {NoteFilter} from "@app/modules/note-filters/note-filter";
import {DateFilter} from "@app/modules/note-filters/filters/date-filter";
import {WeightFilter} from "@app/modules/note-filters/filters/weight-filter";
import {AccessFilter} from "@app/modules/note-filters/filters/access-filter";
import {CategoryFilter} from "@app/modules/note-filters/filters/category-filter";

export class NoteFilterStorage {
  dateFilter: DateFilter;
  accessFilter: AccessFilter;
  weightFilter: WeightFilter;
  categoryFilter: CategoryFilter;

  constructor() {
    this.resetAllFilters();
  }

  public resetAllFilters() {
    console.log("resetAllFilters");
    this.dateFilter = new DateFilter(null, null);
    this.categoryFilter = new CategoryFilter(null);
    this.accessFilter = new AccessFilter(null);
    this.weightFilter = new WeightFilter(null);
  }

  private executeFilter(filter: NoteFilter, isDelete: boolean) {
    if (filter instanceof DateFilter) {
      this.dateFilter = isDelete ? new DateFilter(null, null) : filter as DateFilter;
    } else if (filter instanceof AccessFilter) {
      this.accessFilter = isDelete ? new AccessFilter(null) : filter as AccessFilter;
    } else if (filter instanceof WeightFilter) {
      this.weightFilter = isDelete ? new WeightFilter(null) : filter as WeightFilter;
    } else if (filter instanceof CategoryFilter) {
      this.categoryFilter = isDelete ? new CategoryFilter(null) : filter as CategoryFilter;
    }
  }

  public addFilter(filter: NoteFilter) {
    console.log("addFilter " + filter.toString());
    this.executeFilter(filter, false);
  }

  public removeFilter(filter: NoteFilter) {
    console.log("removeFilter " + filter.toString());
    this.executeFilter(filter, true);
  }

  public updateFilter(filter: NoteFilter) {
    this.executeFilter(filter, false);
  }

  size() {
    return (
      (this.dateFilter.isEmpty() ? 0 : 1) +
      (this.weightFilter.isEmpty() ? 0 : 1) +
      (this.accessFilter.isEmpty() ? 0 : 1) +
      (this.categoryFilter.isEmpty() ? 0 : 1)
    );
  }

  isEmpty() {
    return this.size() === 0;
  }
}
