import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Globals} from "@app/shared/globals";
import {VotingQuestion} from "@app/modules/voting-question/voting-question";
import {VotingAnswer} from "@app/modules/voting-answer/voting-answer";

@Injectable({
  providedIn: 'root'
})

export class VotingService {

  constructor(public http: HttpClient, public globals: Globals) {}

  sendQuestionToVoting(question: VotingQuestion) {
    return this.http.post(this.globals.apiUrl + '/voting/question', question);
  }

  getAllQuestions() {
    return this.http.get(this.globals.apiUrl + '/voting/questions');
  }

  updateQuestionsAndAnswers() {
    this.getAllQuestions().subscribe((questions: VotingQuestion[]) => {
      this.globals.votingQuestions = questions;
    });
    this.getAnswersByUserId(this.globals.owner.id).subscribe((answers: VotingAnswer[]) => {
      for (let a of answers) {
        this.globals.votingAnsweredQuestionsId.push(a.questionsId);
      }
    });
  }

  getQuestionById(id: number) {
    let params = new HttpParams().append('id', id.toString());
    this.http.get(this.globals.apiUrl + '/voting/question', {params}).subscribe((question: VotingQuestion) => {
      console.log(question);
      return question;
    });
  }

  editQuestion(question: VotingQuestion) {
    return this.http.put(this.globals.apiUrl + '/voting/question', question);
  }

  deleteQuestionById(id: number) {
    let params = new HttpParams().append('id', id.toString());
    return this.http.delete(this.globals.apiUrl + '/voting/question', {params});
  }

  sendAnswerToVoting(answer: VotingAnswer) {
    return this.http.post(this.globals.apiUrl + '/voting/answer', answer).subscribe((res) => {
      this.updateQuestionsAndAnswers();
    });
  }

  getAnswerById(id: number) {
    let params = new HttpParams().append('id', id.toString());
    this.http.get(this.globals.apiUrl + '/voting/answer', {params}).subscribe((answer: VotingAnswer) => {
      return answer;
    });
  }

  getAnswersByUserId(userId: number) {
    let params = new HttpParams().append('userId', userId.toString());
    return this.http.get(this.globals.apiUrl + '/voting/answers/user', {params});
  }

  getAnswersByCategoryId(disId: number) {
    let params = new HttpParams().append('disId', disId.toString());
    this.http.get(this.globals.apiUrl + '/voting/answers/discussion', {params}).subscribe((answerList: VotingAnswer[]) => {
      return answerList;
    });
  }

  getAnswersByQuestionId(questionId: number) {
    let params = new HttpParams().append('questionId', questionId.toString());
    this.http.get(this.globals.apiUrl + '/voting/answers/question', {params}).subscribe((answerList: VotingAnswer[]) => {
      return answerList;
    });
  }

}
