import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AuthenticationService} from '@app/core/auth/authentication.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Globals} from '@app/shared/globals';
import {TranslateService} from '@ngx-translate/core';
import {IonSearchbar, MenuController} from '@ionic/angular';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {Subscription} from 'rxjs';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {UtilsService} from "@app/shared/services/utils.service";
import {ChatService} from "@app/shared/services/chat.service";
import {MenuService} from "@app/shared/services/menu/menu.service";
import {SharedService} from '@app/shared/services/shared.service';
import {SiteUserService} from "@app/shared/services/site-user.service";

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  readonly wita = 'wita';

  titleSubscription: Subscription;
  itemsSubscription: Subscription;
  headerVisibilitySubscription: Subscription;
  mainBtnSubscription: Subscription;
  searchbarSubscription: Subscription;
  public isNewSiteUser = false;
  title: string;
  items: MenuItem[];
  isVisible = true;
  mainBtn: MenuItem;
  searchbar: MenuItem;

  @Input() directoryName: string;
  @Input() actions: any[] = [];
  @ViewChild('userSearchBar', {static: false}) userSearchBar: IonSearchbar;
  @Output() walkThroughEvent = new EventEmitter<string>();


  constructor(
    public translate: TranslateService,
    public app: AuthenticationService,
    public http: HttpClient,
    public router: Router,
    public globals: Globals,
    public menuCtrl: MenuController,
    public mainMenuService: MenuService,
    private toolbarService: ToolbarService,
    private utilsService: UtilsService,
    private chatService: ChatService,
    private sharedService: SharedService,
    public siteUserService: SiteUserService,
  ) {
    translate.setDefaultLang(this.globals.currentLanguage);

    this.titleSubscription = toolbarService.title$.subscribe(
      title => {
        this.title = title;
      }
    );

    this.itemsSubscription = toolbarService.items$.subscribe(
      items => {
        this.items = items;
        this.globals.currentHeader = this.items;
      }
    );

    this.headerVisibilitySubscription = toolbarService.headerVisibility$.subscribe(
      isVisible => {
        this.isVisible = isVisible;
      }
    );

    this.mainBtnSubscription = toolbarService.mainBtn$.subscribe(
      mainBtn => {
        this.mainBtn = mainBtn;
      }
    );

    this.searchbarSubscription = toolbarService.searchbar$.subscribe(
      item => {
        this.searchbar = item;
      }
    );
  }

  ngOnInit() {
    if (this.globals.isNewUser && this.globals.isAuthenticated) {
      setTimeout(() => this.callParent(), 3000);
    }
  }

  callParent(): void {
    console.log("onInit header !!! ");
    this.walkThroughEvent.next('startWalkthrough');
  }

  ngOnDestroy(): void {
    this.itemsSubscription.unsubscribe();
    this.titleSubscription.unsubscribe();
    this.headerVisibilitySubscription.unsubscribe();
    this.mainBtnSubscription.unsubscribe();
    this.searchbarSubscription.unsubscribe();
  }

  async applyItem(item: MenuItem, env: any) {

    await this.menuCtrl.close('feed');

    if (item.action) {
      return item.action(env);
    }

    if (item.path) {
      if (item.path == '/chat') {
        this.router.navigateByUrl(item.path).then(event => {
          console.log(event ? 'Modified navigation is successful!' : 'Modified navigation has failed!');
          this.sharedService.emitChange({
            data: undefined,
            action: 'open-chatlist'
          });
        });
      } else {
        this.router.navigateByUrl(item.path).then(event => {
          console.log(event ? 'Navigation is successful!' : 'Navigation has failed!');
        });
      }
    }
  }

  blurHandle(event) {
    event.stopPropagation();
  }

  async openMenu() {
    await this.menuCtrl.close('feed');
    this.globals.enabledSearch = false;
    this.mainMenuService.toggleMenu();
  }

  focusOnInput() {
    setTimeout(() => {
      this.userSearchBar.setFocus().then();
    }, 300);
  }

}
