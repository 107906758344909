import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import * as WebStomp from 'webstomp-client';
import * as SockJS from 'sockjs-client';
import { BehaviorSubject } from 'rxjs';
import { Tokens } from '@app/shared/models/tokens';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
export class MessageService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
        this.webSocketEndPoint = this.globals.apiUrl + '/wss';
        this.topic = '/topic/chat';
        this.answer = new BehaviorSubject(null);
        this.newMessageReceived = new BehaviorSubject(null);
    }
    _connect() {
        const tokens = new Tokens(JSON.parse(localStorage.getItem('tokens')));
        const ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = WebStomp.over(ws);
        this.stompClient.debug = () => {
        };
        this.stompClient.connect({ Authorization: tokens.jwtToken }, frame => {
            this.stompClient.subscribe(this.topic + '/' + this.globals.currentChat.id, sdkEvent => {
                this.onMessageReceived(sdkEvent);
            });
        }, this.errorCallBack);
    }
    _disconnect() {
        if (this.stompClient !== null && this.stompClient !== undefined && this.stompClient.connected) {
            this.stompClient.disconnect();
        }
    }
    errorCallBack(error) {
        console.log('errorCallBack -> ' + error);
        setTimeout(() => {
            this._connect();
        }, 5000);
    }
    sendMsg(message) {
        this.stompClient.send('/app/message/messaging/' + this.globals.currentChat.id + '/' + this.globals.owner.id, JSON.stringify(message));
    }
    readMessages() {
        if (this.stompClient !== undefined) {
            this.stompClient.send('/app/message/list/' + this.globals.currentChat.id + '/' + this.globals.owner.id);
        }
    }
    onMessageReceived(message) {
        this.receivedMessage = JSON.parse(message.body);
        this.globals.messageList = this.globals.messageList.concat(this.receivedMessage);
        this.messageList = this.globals.messageList;
        if (this.globals.owner.id === this.receivedMessage.sender.id) {
            this.newMessageReceived.next(null);
        }
        this.getAnswer();
    }
    getAnswer() {
        this.answer.next(this.messageList);
        return this.answer.asObservable();
    }
}
MessageService.ɵfac = function MessageService_Factory(t) { return new (t || MessageService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
MessageService.ɵprov = i0.ɵɵdefineInjectable({ token: MessageService, factory: MessageService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MessageService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
