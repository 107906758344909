import {SiteUser} from './site-user';

export class SiteUserIpAndLocation {
  id: number;
  owner: SiteUser;
  firstLoginIP: string;
  lastLoginIP: string;
  firstLoginLocation: string;
  lastLoginLocation: string;
}
