<ion-header>
  <ion-toolbar color="primary"  [ngStyle]="{'padding-top': toolbarService.isNewIphone ? '30px' : '0'}" >
    <ion-buttons class="left-actions ml-3" slot="start">
      <ion-button shape="round" (click)="dismiss()">
        <img [src]="'assets/icons/design_v4/back_icon.svg'"/>
      </ion-button>

    </ion-buttons>
    <ion-title color="white" class="ion-text-center" translate>New chat</ion-title>

  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-searchbar
    [disabled]="disabledSearch"
    (ionChange)="searchInputHandler($event.detail.value)"
    placeholder="{{ 'Search' | translate }}"
    animated>
  </ion-searchbar>

  <ion-spinner
    *ngIf="isLoading"
    name="crescent">
  </ion-spinner>

  <ion-refresher slot="fixed" (ionRefresh)="doRefreshContent($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-list class="friends-list">
      <ion-item
        *ngFor="let siteUser of friends"
        class="on-left-line cursor-pointer"
        lines="none"
        (click)="onItemClickHandler(siteUser)"
        style="margin-right: 2px">
        <ion-avatar slot="start" class="avatar-img">
          <img src="{{siteUser.userPhotoName}}">
        </ion-avatar>
        <ion-label>
          <h2 class="user-name">{{siteUser | userName}}</h2>
          <p class="user-sub">{{siteUser.ssoId}}</p>
        </ion-label>
      </ion-item>
  </ion-list>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="nextPage($event)">
    <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
