var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals } from '../../shared/globals';
import { PasswordResetService } from '../../shared/services/password-reset.service';
import { Storage } from '@ionic/storage';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { finalize, tap } from 'rxjs/operators';
import { MenuService } from '@app/shared/services/menu/menu.service';
import { SiteUserService } from '@app/shared/services/site-user.service';
import { TokensService } from '@app/shared/services/tokens.service';
import { Tokens } from '@app/shared/models/tokens';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SharedService } from '@app/shared/services/shared.service';
import { OneSignalService } from '@app/shared/services/one-signal.service';
import { AlertController, Platform } from '@ionic/angular';
import { NotificationService } from '@app/shared/services/notification.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../shared/globals";
import * as i4 from "@app/shared/services/toolbar/toolbar.service";
import * as i5 from "../../shared/services/password-reset.service";
import * as i6 from "@ionic/storage";
import * as i7 from "@app/shared/services/site-user.service";
import * as i8 from "@app/shared/services/menu/menu.service";
import * as i9 from "@app/shared/services/tokens.service";
import * as i10 from "@ionic-native/facebook/ngx";
import * as i11 from "@ionic-native/google-plus/ngx";
import * as i12 from "@app/shared/services/shared.service";
import * as i13 from "@app/shared/services/one-signal.service";
import * as i14 from "@ionic/angular";
import * as i15 from "@app/shared/services/notification.service";
export class AuthenticationService {
    constructor(http, router, globals, toolbarService, passwordResetService, storage, userService, menuService, tokensService, fb, googlePlus, sharedService, oneSignalService, platform, notificationService, alertCtrl) {
        this.http = http;
        this.router = router;
        this.globals = globals;
        this.toolbarService = toolbarService;
        this.passwordResetService = passwordResetService;
        this.storage = storage;
        this.userService = userService;
        this.menuService = menuService;
        this.tokensService = tokensService;
        this.fb = fb;
        this.googlePlus = googlePlus;
        this.sharedService = sharedService;
        this.oneSignalService = oneSignalService;
        this.platform = platform;
        this.notificationService = notificationService;
        this.alertCtrl = alertCtrl;
        this.isWrongPassword = false;
        this.HEADERS = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
    authenticateWithLowCase(credentials, callback, onError) {
        let params = new HttpParams();
        params = params.append('deviceId', this.oneSignalService.getDeviceId());
        this.http.post(this.globals.apiUrl + '/accounts/login', {
            username: credentials.username.toLocaleLowerCase(),
            password: credentials.password
        }, { params: params }).subscribe((response) => {
            localStorage.setItem('wita-user', JSON.stringify(response));
            this.tokensService.setTokens(response.witaAuthToken);
            this.globals.owner = response;
            if (this.globals.owner.ssoId === credentials.username.toLowerCase() || this.globals.owner.email === credentials.username.toLowerCase()) {
                this.isWrongPassword = false;
                this.parseUserFromResponse(response);
                this.afterLoginInitiation();
            }
            return callback && callback();
        }, (error) => {
            if (error.status === 401) {
                this.isWrongPassword = true;
                this.globals.isAuthenticated = false;
            }
            else {
                this.globals.serverError = true;
            }
            return onError && onError(error);
        });
    }
    checkRegisterSsoId(ssoId) {
        const options = {
            headers: this.HEADERS
        };
        return this.http.get(`${this.globals.apiUrl}/accounts/ssoid/check?ssoid=${ssoId}`, options);
    }
    checkRegisterEmail(email) {
        const options = {
            headers: this.HEADERS
        };
        return this.http.get(`${this.globals.apiUrl}/accounts/email/check?email=${email}`, options);
    }
    checkRegisterReferrer(referrer) {
        const options = {
            headers: this.HEADERS
        };
        return this.http.get(`${this.globals.apiUrl}/accounts/referrer/check?referrer=${referrer}`, options);
    }
    register(siteUser, callback, error) {
        const options = {
            headers: this.HEADERS
        };
        console.log('register is running');
        this.http.post(this.globals.apiUrl + '/accounts/registration', {
            ssoId: siteUser.ssoId.toLowerCase(),
            email: siteUser.email,
            password: siteUser.password
        }, options).subscribe(response => {
            console.log('responce' + response);
            this.toolbarService.setHeaderVisibility(true);
            return callback && callback();
        }, e => {
            return error && error(e);
        });
    }
    sendResetPasswordEmail(email) {
        let params = new HttpParams();
        params = params.append('email', email);
        this.passwordResetService.sendResetMessage(params).subscribe();
    }
    getSiteUser(cashedOwner) {
        return this.http.get(this.globals.apiUrl + '/users/' + cashedOwner.id);
    }
    parseUserFromResponse(response) {
        this.globals.selectedUser.id = response.id;
        this.globals.selectedUser.ssoId = response.ssoId;
        this.globals.selectedUser.firstName = response.firstName;
        this.globals.selectedUser.lastName = response.lastName;
        this.globals.selectedUser.password = response.password;
    }
    canActivate() {
        let isActive = true;
        if (!this.globals.isAuthenticated) {
            console.log('canActivate ' + this.globals.isAuthenticated);
            this.router.navigate(['auth/']);
            isActive = false;
        }
        return isActive;
    }
    isEditor(user) {
        let profile;
        for (profile of user.userProfiles) {
            if (profile.id === 4) {
                return true;
            }
        }
        return false;
    }
    isAdmin(user) {
        let profile;
        for (profile of user.userProfiles) {
            if (profile.id === 1) {
                return true;
            }
        }
        return false;
    }
    afterLoginInitiation() {
        this.globals.selectedUser = this.globals.owner;
        this.globals.isAuthenticated = true;
        this.globals.isEditor = this.isEditor(this.globals.owner);
        this.globals.isAdmin = this.isAdmin(this.globals.owner);
        this.globals.editorsPagePreloaded = false;
        this.globals.firstLoad = true;
        this.globals.needPageRefresh = true;
        this.storage.set('wita-user', this.globals.owner);
        this.toolbarService.setHeaderVisibility(true);
        this.userService.getCountOfAllNotifications();
        this.notificationService.connect();
        this.notificationService.countUnreadNotifications().subscribe((data) => {
            this.globals.unreadNotificationCount = data;
        });
    }
    logout() {
        this.globals.isAuthenticated = false;
        let params = new HttpParams();
        params = params.append('deviceId', this.oneSignalService.getDeviceId());
        this.http.post(this.globals.apiUrl + '/accounts/logout', {}, {
            params
        }).pipe(finalize(() => __awaiter(this, void 0, void 0, function* () {
            this.logoutProcesses();
        }))).subscribe();
    }
    logoutProcesses() {
        return __awaiter(this, void 0, void 0, function* () {
            yield localStorage.clear();
            yield this.storage.set('wita-user', null);
            yield this.router.navigate(['login']);
            this.toolbarService.setHeaderVisibility(false);
            yield this.menuService.disableMainMenu();
            this.globals.noteList = [];
            this.globals.owner = undefined;
            this.globals.selectedUserInfo = undefined;
            this.globals.firstLoad = true;
            this.globals.action = null;
            this.globals.currentCategoryId = undefined;
            this.globals.userNotifications = undefined;
            this.globals.activeFilters.resetAllFilters();
            this.notificationService.disconnect();
            yield this.googlePlus.logout();
            yield this.fb.logout();
        });
    }
    refreshAuthToken(skip) {
        const tokens = new Tokens(JSON.parse(localStorage.getItem('tokens')));
        if (!tokens.jwtToken || !tokens.refreshToken || !tokens.ssoId) {
            this.logout();
            return;
        }
        return this.http.post(`${this.globals.apiUrl}/accounts/token`, {
            ssoId: tokens.ssoId,
            jwtToken: tokens.jwtToken,
            refreshToken: tokens.refreshToken,
            skip
        }).pipe(tap((token) => token));
    }
    checkConfirmationRequired(er) {
        return __awaiter(this, void 0, void 0, function* () {
            if (er.status == 404) {
                yield this.router.navigate(['sign-up/confirm']);
            }
            else {
                if (er.status != undefined && er.status !== 401) {
                    this.handleError(er);
                }
            }
        });
    }
    handleError(er) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.globals.apiUrl !== 'https://www.witaspace.com:8443/witasocial') { // For a debug
                const alert = yield this.alertCtrl.create({
                    header: 'Server error',
                    message: 'STATUS: ' + er.status + ' URL: ' + er.url + '\n' + 'MESSAGE' + er.message,
                });
                yield alert.present();
            }
            else { // For a users
                const alert = yield this.alertCtrl.create({
                    header: 'Server error',
                    message: 'Sorry, the service is unavailable at the moment',
                });
                yield alert.present();
            }
        });
    }
}
AuthenticationService.ɵfac = function AuthenticationService_Factory(t) { return new (t || AuthenticationService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Globals), i0.ɵɵinject(i4.ToolbarService), i0.ɵɵinject(i5.PasswordResetService), i0.ɵɵinject(i6.Storage), i0.ɵɵinject(i7.SiteUserService), i0.ɵɵinject(i8.MenuService), i0.ɵɵinject(i9.TokensService), i0.ɵɵinject(i10.Facebook), i0.ɵɵinject(i11.GooglePlus), i0.ɵɵinject(i12.SharedService), i0.ɵɵinject(i13.OneSignalService), i0.ɵɵinject(i14.Platform), i0.ɵɵinject(i15.NotificationService), i0.ɵɵinject(i14.AlertController)); };
AuthenticationService.ɵprov = i0.ɵɵdefineInjectable({ token: AuthenticationService, factory: AuthenticationService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AuthenticationService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Router }, { type: i3.Globals }, { type: i4.ToolbarService }, { type: i5.PasswordResetService }, { type: i6.Storage }, { type: i7.SiteUserService }, { type: i8.MenuService }, { type: i9.TokensService }, { type: i10.Facebook }, { type: i11.GooglePlus }, { type: i12.SharedService }, { type: i13.OneSignalService }, { type: i14.Platform }, { type: i15.NotificationService }, { type: i14.AlertController }]; }, null); })();
