import { Directive, ElementRef } from '@angular/core';
import * as i0 from "@angular/core";
export class AutofocusDirective {
    constructor(el) {
        this.el = el;
    }
    ngAfterViewInit() {
        this.el.nativeElement.focus();
    }
}
AutofocusDirective.ɵfac = function AutofocusDirective_Factory(t) { return new (t || AutofocusDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
AutofocusDirective.ɵdir = i0.ɵɵdefineDirective({ type: AutofocusDirective, selectors: [["", "appCustomAutofocus", ""]] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AutofocusDirective, [{
        type: Directive,
        args: [{
                selector: '[appCustomAutofocus]'
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, null); })();
