import { Component, Input } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { GeoService } from "@app/shared/services/geo.service";
import { LabelService } from "@app/shared/services/label.service";
import { Place } from "@app/modules/place/place";
import { Globals } from "@app/shared/globals";
import { Level } from "@app/modules/category-level/Level";
import { Label } from "@app/modules/label/label";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@app/shared/services/geo.service";
import * as i3 from "@app/shared/services/label.service";
import * as i4 from "@app/shared/globals";
import * as i5 from "@angular/common";
function PlaceComponent_div_2_div_4_ion_item_1_ion_icon_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-icon", 11);
} }
function PlaceComponent_div_2_div_4_ion_item_1_ion_icon_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-icon", 5);
} }
function PlaceComponent_div_2_div_4_ion_item_1_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-item", 8);
    i0.ɵɵlistener("click", function PlaceComponent_div_2_div_4_ion_item_1_Template_ion_item_click_0_listener() { i0.ɵɵrestoreView(_r10); const continent_r3 = i0.ɵɵnextContext().$implicit; const ctx_r8 = i0.ɵɵnextContext(2); return ctx_r8.openCountries(continent_r3); });
    i0.ɵɵtemplate(1, PlaceComponent_div_2_div_4_ion_item_1_ion_icon_1_Template, 1, 0, "ion-icon", 9);
    i0.ɵɵtemplate(2, PlaceComponent_div_2_div_4_ion_item_1_ion_icon_2_Template, 1, 0, "ion-icon", 10);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const continent_r3 = i0.ɵɵnextContext().$implicit;
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("id", "continent" + continent_r3.id)("ngClass", ctx_r4.isGroupShown(continent_r3) ? "active" : "");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r4.isGroupShown(continent_r3));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r4.isGroupShown(continent_r3));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" \u00A0 ", continent_r3.title, " ");
} }
function PlaceComponent_div_2_div_4_div_2_div_1_ion_icon_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-icon", 11);
} }
function PlaceComponent_div_2_div_4_div_2_div_1_ion_icon_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-icon", 5);
} }
function PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_ion_icon_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-icon", 11);
} }
function PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_ion_icon_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-icon", 5);
} }
function PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_div_5_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r25 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "ion-item", 14);
    i0.ɵɵlistener("click", function PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_div_5_div_1_Template_ion_item_click_1_listener() { i0.ɵɵrestoreView(_r25); const city_r23 = ctx.$implicit; const ctx_r24 = i0.ɵɵnextContext(8); return ctx_r24.selectCities(city_r23); });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const city_r23 = ctx.$implicit;
    const ctx_r22 = i0.ɵɵnextContext(8);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("id", "city" + city_r23.id)("ngClass", ctx_r22.isGroupShown(city_r23) ? "active" : "");
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", city_r23.cityTitle, " ");
} }
function PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_div_5_div_1_Template, 3, 3, "div", 1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r21 = i0.ɵɵnextContext(7);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r21.cities);
} }
function PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r27 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "ion-item", 13);
    i0.ɵɵlistener("click", function PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_Template_ion_item_click_1_listener() { i0.ɵɵrestoreView(_r27); const region_r18 = ctx.$implicit; const ctx_r26 = i0.ɵɵnextContext(6); return ctx_r26.openCities(region_r18); });
    i0.ɵɵtemplate(2, PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_ion_icon_2_Template, 1, 0, "ion-icon", 9);
    i0.ɵɵtemplate(3, PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_ion_icon_3_Template, 1, 0, "ion-icon", 10);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_div_5_Template, 2, 1, "div", 7);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const region_r18 = ctx.$implicit;
    const ctx_r17 = i0.ɵɵnextContext(6);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("id", "region" + region_r18.id)("ngClass", ctx_r17.isGroupShown(region_r18) ? "active" : "");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r17.isGroupShown(region_r18));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r17.isGroupShown(region_r18));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", region_r18.title, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r17.isGroupShown(region_r18));
} }
function PlaceComponent_div_2_div_4_div_2_div_1_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, PlaceComponent_div_2_div_4_div_2_div_1_div_5_div_1_Template, 6, 6, "div", 1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r16 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r16.regions);
} }
function PlaceComponent_div_2_div_4_div_2_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r29 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "ion-item", 12);
    i0.ɵɵlistener("click", function PlaceComponent_div_2_div_4_div_2_div_1_Template_ion_item_click_1_listener() { i0.ɵɵrestoreView(_r29); const country_r13 = ctx.$implicit; const ctx_r28 = i0.ɵɵnextContext(4); return ctx_r28.openRegions(country_r13); });
    i0.ɵɵtemplate(2, PlaceComponent_div_2_div_4_div_2_div_1_ion_icon_2_Template, 1, 0, "ion-icon", 9);
    i0.ɵɵtemplate(3, PlaceComponent_div_2_div_4_div_2_div_1_ion_icon_3_Template, 1, 0, "ion-icon", 10);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, PlaceComponent_div_2_div_4_div_2_div_1_div_5_Template, 2, 1, "div", 7);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const country_r13 = ctx.$implicit;
    const ctx_r12 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("id", "country" + country_r13.id)("ngClass", ctx_r12.isGroupShown(country_r13) ? "active" : "");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r12.isGroupShown(country_r13));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r12.isGroupShown(country_r13));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", country_r13.title, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r12.isGroupShown(country_r13));
} }
function PlaceComponent_div_2_div_4_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, PlaceComponent_div_2_div_4_div_2_div_1_Template, 6, 6, "div", 1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r5.countries);
} }
function PlaceComponent_div_2_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, PlaceComponent_div_2_div_4_ion_item_1_Template, 4, 5, "ion-item", 6);
    i0.ɵɵtemplate(2, PlaceComponent_div_2_div_4_div_2_Template, 2, 1, "div", 7);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const continent_r3 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", continent_r3.title !== "World");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.isGroupShown(continent_r3));
} }
function PlaceComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r31 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "ion-item", 4);
    i0.ɵɵlistener("click", function PlaceComponent_div_2_Template_ion_item_click_1_listener() { i0.ɵɵrestoreView(_r31); const ctx_r30 = i0.ɵɵnextContext(); return ctx_r30.setWorld(); });
    i0.ɵɵelement(2, "ion-icon", 5);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, PlaceComponent_div_2_div_4_Template, 3, 2, "div", 1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const c_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0 ", c_r1.title, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.continents);
} }
export class PlaceComponent {
    constructor(modalController, geoService, labelService, globals) {
        this.modalController = modalController;
        this.geoService = geoService;
        this.labelService = labelService;
        this.globals = globals;
    }
    ngOnInit() {
        this.geoService.continents.subscribe(continents => this.continents = continents);
        this.geoService.getAllContinents().subscribe((continents) => {
            this.continents = continents;
            this.world = [];
            this.world.push(this.geoService.getWorld(this.continents));
            this.selectedPlace = new Place(this.world[0]);
            this.selectedGroups = [];
            this.geoService.changeContinents(continents);
        });
    }
    selectPlace() {
        if (this.selectedPlace.id !== undefined) {
            if (this.globals.isCategoryAddMode) {
                this.labelService.changeSelectedLabel(new Label([this.selectedPlace]));
            }
        }
        else {
            this.geoService.createPlace(this.selectedPlace).subscribe((result) => {
                this.selectedPlace = result;
                this.geoService.changeSelectedPlace(this.selectedPlace);
                if (this.globals.isCategoryAddMode) {
                    this.labelService.changeSelectedLabel(new Label([this.selectedPlace]));
                }
            });
        }
        this.modalController.dismiss();
    }
    openCountries(continent) {
        if (this.selectedGroups.length < 1 || !this.selectedGroups.includes(continent.id)) {
            this.geoService.getAllCountriesForContinent(continent.id).subscribe((counrties) => {
                this.selectedGroups = [];
                this.selectedGroups.push(continent.id);
                this.selectedPlace = new Place();
                this.selectedPlace.continent = continent;
                this.countries = counrties;
                document.getElementById("continent" + continent.id).scrollIntoView();
            });
        }
        else {
            this.setWorld();
        }
        this.toggleGroup(continent);
    }
    openRegions(country) {
        if (this.selectedGroups.length < 2 || !this.selectedGroups.includes(country.id)) {
            this.geoService.getAllRegionsForCountry(country.id).subscribe((regions) => {
                this.selectedGroups = [];
                this.selectedGroups.push(country.id, country.continent.id);
                this.selectedPlace = new Place();
                this.selectedPlace.continent = country.continent;
                this.selectedPlace.country = country;
                this.regions = regions;
                document.getElementById("country" + country.id).scrollIntoView();
            });
        }
        else {
            this.selectedGroups = [];
            this.selectedGroups.push(country.continent.id);
            this.selectedPlace = new Place();
            this.selectedPlace.continent = country.continent;
        }
        this.toggleGroup(country);
    }
    openCities(region) {
        if (this.selectedGroups.length < 3 || !this.selectedGroups.includes(region.id)) {
            this.geoService.getAllCitiesForRegion(region.id).subscribe((cities) => {
                this.selectedGroups = [];
                this.selectedGroups.push(region.id, region.country.id, region.country.continent.id);
                this.selectedPlace = new Place();
                this.selectedPlace.continent = region.country.continent;
                this.selectedPlace.country = region.country;
                this.selectedPlace.region = region;
                this.cities = cities;
                document.getElementById("region" + region.id).scrollIntoView();
            });
        }
        else {
            this.selectedGroups = [];
            this.selectedGroups.push(region.country.id, region.country.continent.id);
            this.selectedPlace = new Place();
            this.selectedPlace.continent = region.country.continent;
            this.selectedPlace.country = region.country;
        }
        this.toggleGroup(region);
    }
    selectCities(city) {
        this.selectedGroups = [];
        this.selectedGroups.push(city.id, city.region.id, city.region.country.id, city.region.country.continent.id);
        this.selectedPlace = new Place();
        this.selectedPlace.continent = city.region.country.continent;
        this.selectedPlace.country = city.region.country;
        this.selectedPlace.region = city.region;
        this.selectedPlace.city = city;
    }
    toggleGroup(group) {
        group.isOpened = !group.isOpened;
    }
    isGroupShown(group) {
        return this.selectedGroups.includes(group.id);
    }
    setWorld() {
        this.selectedPlace = new Place(this.world[0]);
        this.selectedGroups = [];
    }
}
PlaceComponent.ɵfac = function PlaceComponent_Factory(t) { return new (t || PlaceComponent)(i0.ɵɵdirectiveInject(i1.ModalController), i0.ɵɵdirectiveInject(i2.GeoService), i0.ɵɵdirectiveInject(i3.LabelService), i0.ɵɵdirectiveInject(i4.Globals)); };
PlaceComponent.ɵcmp = i0.ɵɵdefineComponent({ type: PlaceComponent, selectors: [["app-place"]], inputs: { levelData: "levelData", level: "level" }, decls: 7, vars: 1, consts: [[1, "place-list"], [4, "ngFor", "ngForOf"], [1, "place-select"], [1, "place-select-btn", 3, "click"], [1, "active", 3, "click"], ["name", "md-remove", 1, "geo-icon"], ["class", "item-stable item-continent", 3, "id", "ngClass", "click", 4, "ngIf"], [4, "ngIf"], [1, "item-stable", "item-continent", 3, "id", "ngClass", "click"], ["name", "md-add", "class", "geo-icon", 4, "ngIf"], ["name", "md-remove", "class", "geo-icon", 4, "ngIf"], ["name", "md-add", 1, "geo-icon"], [1, "item-accordion", "item-country", 3, "id", "ngClass", "click"], [1, "item-accordion", "item-region", 3, "id", "ngClass", "click"], [1, "item-accordion", "item-city", 3, "id", "ngClass", "click"]], template: function PlaceComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-content");
        i0.ɵɵelementStart(1, "ion-list", 0);
        i0.ɵɵtemplate(2, PlaceComponent_div_2_Template, 5, 2, "div", 1);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(3, "ion-item");
        i0.ɵɵelementStart(4, "ion-item", 2);
        i0.ɵɵelementStart(5, "ion-button", 3);
        i0.ɵɵlistener("click", function PlaceComponent_Template_ion_button_click_5_listener() { return ctx.selectPlace(); });
        i0.ɵɵtext(6, " Select ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.world);
    } }, directives: [i1.IonContent, i1.IonList, i5.NgForOf, i1.IonItem, i1.IonButton, i1.IonIcon, i5.NgIf, i5.NgClass], styles: [".list[_ngcontent-%COMP%]   .item.item-accordion[_ngcontent-%COMP%]{line-height:38px;padding-top:0;padding-bottom:0;transition:90ms all linear}.active[_ngcontent-%COMP%]{color:#48cdc5}.list[_ngcontent-%COMP%]   .item.item-accordion.ng-hide[_ngcontent-%COMP%]{line-height:0}.list[_ngcontent-%COMP%]   .item.item-accordion.ng-hide-add[_ngcontent-%COMP%], .list[_ngcontent-%COMP%]   .item.item-accordion.ng-hide-remove[_ngcontent-%COMP%]{display:block!important}.item-continent[_ngcontent-%COMP%]{margin-left:30px}.item-country[_ngcontent-%COMP%]{margin-left:55px}.item-region[_ngcontent-%COMP%]{margin-left:75px}.item-city[_ngcontent-%COMP%]{margin-left:125px}.geo-icon[_ngcontent-%COMP%]{width:.5em}.place-select[_ngcontent-%COMP%]{width:100%;background-color:#fff;opacity:.9;position:fixed;bottom:5px}.place-select-btn[_ngcontent-%COMP%]{max-width:70px;margin:0 auto}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(PlaceComponent, [{
        type: Component,
        args: [{
                selector: 'app-place',
                templateUrl: './place.component.html',
                styleUrls: ['./place.component.css']
            }]
    }], function () { return [{ type: i1.ModalController }, { type: i2.GeoService }, { type: i3.LabelService }, { type: i4.Globals }]; }, { levelData: [{
            type: Input
        }], level: [{
            type: Input
        }] }); })();
