import {Component, Input, ViewChild} from '@angular/core';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Paginator} from '@app/shared/models/Paginator';

@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss', '../new-note/new-note.component.scss']
})
export class NoteListComponent {
  @ViewChild(CdkVirtualScrollViewport, {static: false})

  viewport: CdkVirtualScrollViewport;

  @Input() paginator: Paginator;

  constructor() {
  }

  go() {
    this.viewport.scrollToIndex(2);
  }
}
