import { Component } from '@angular/core';
import { ModalController } from "@ionic/angular";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class EditorsTermsComponent {
    constructor(modalController) {
        this.modalController = modalController;
    }
    ngOnInit() {
    }
    dismiss() {
        this.modalController.dismiss();
    }
}
EditorsTermsComponent.ɵfac = function EditorsTermsComponent_Factory(t) { return new (t || EditorsTermsComponent)(i0.ɵɵdirectiveInject(i1.ModalController)); };
EditorsTermsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: EditorsTermsComponent, selectors: [["app-editors-terms"]], decls: 25, vars: 1, consts: [["color", "primary"], ["slot", "start", 1, "left-actions", "ml-3"], ["shape", "round", 3, "click"], [3, "src"], [1, "ion-text-center"]], template: function EditorsTermsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-header");
        i0.ɵɵelementStart(1, "ion-toolbar", 0);
        i0.ɵɵelementStart(2, "ion-buttons", 1);
        i0.ɵɵelementStart(3, "ion-button", 2);
        i0.ɵɵlistener("click", function EditorsTermsComponent_Template_ion_button_click_3_listener() { return ctx.dismiss(); });
        i0.ɵɵelement(4, "img", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "ion-title", 4);
        i0.ɵɵtext(6, "Terms");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "ion-content");
        i0.ɵɵelementStart(8, "ul");
        i0.ɵɵelementStart(9, "li");
        i0.ɵɵtext(10, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "li");
        i0.ɵɵtext(12, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "li");
        i0.ɵɵtext(14, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(15, "li");
        i0.ɵɵtext(16, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(17, "li");
        i0.ɵɵtext(18, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(19, "li");
        i0.ɵɵtext(20, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(21, "li");
        i0.ɵɵtext(22, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(23, "li");
        i0.ɵɵtext(24, "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, non?");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("src", "assets/icons/design_v4/close_icon.svg", i0.ɵɵsanitizeUrl);
    } }, directives: [i1.IonHeader, i1.IonToolbar, i1.IonButtons, i1.IonButton, i1.IonTitle, i1.IonContent], styles: ["ion-title[_ngcontent-%COMP%]{color:#fff}ul[_ngcontent-%COMP%]{padding:0 15px 0 30px;margin-top:15px;list-style-type:decimal;list-style-position:outside}li[_ngcontent-%COMP%]{font:16px/21px Montserrat-Medium;margin-bottom:10px;color:rgba(0,0,0,.4)}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EditorsTermsComponent, [{
        type: Component,
        args: [{
                selector: 'app-editors-terms',
                templateUrl: './editors-terms.component.html',
                styleUrls: ['./editors-terms.component.scss']
            }]
    }], function () { return [{ type: i1.ModalController }]; }, null); })();
