import {NoteFilter} from "@app/modules/note-filters/note-filter";

export class CategoryFilter extends NoteFilter {
  categoryId: number;

  constructor(categoryId: number) {
    super();
    this.categoryId = categoryId;
  }

  isEmpty() {
    return this.categoryId === null;
  }

  toString() {
    return "CategoryFilter. CatId = " + this.categoryId;
  }
}
