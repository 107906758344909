import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {Globals} from '@app/shared/globals';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  private itemsSource = new Subject<MenuItem[]>();
  private mainBtnSource = new Subject<MenuItem>();
  private titleSource = new Subject<string>();
  private headerVisibilitySource = new Subject<boolean>();
  private searchbarSource = new Subject<MenuItem>();

  items$ = this.itemsSource.asObservable();
  title$ = this.titleSource.asObservable();
  mainBtn$ = this.mainBtnSource.asObservable();
  headerVisibility$ = this.headerVisibilitySource.asObservable();
  searchbar$ = this.searchbarSource.asObservable();

  isNewIphone = false;
  newIphone = ['828x1792', '1242x2688', '828x1792', '1125x2436', '1242x2688', '1125x2436'];


  constructor(private globals: Globals) {
      const w = (window as any);
      const physicalScreenWidth = w.screen.width * w.devicePixelRatio;
      const physicalScreenHeight = w.screen.height * w.devicePixelRatio;
      const currentDevice = physicalScreenWidth + "x" + physicalScreenHeight;

      this.isNewIphone = this.newIphone.filter(i => i === currentDevice).length !== 0;

      if (physicalScreenWidth > 1242 || physicalScreenWidth < 750) {
        this.isNewIphone = false;
      }
  }

  setTitle(title: string) {
    this.titleSource.next(title);
  }

  setMenuItems(items: MenuItem[]) {
    this.itemsSource.next(items);
  }

  setHeaderVisibility(isVisible: boolean) {
    this.headerVisibilitySource.next(isVisible);
  }

  setMainHeaderBtn(item: MenuItem) {
    this.mainBtnSource.next(item);
  }

  setSearchbar(item: MenuItem) {
    this.searchbarSource.next(item);
  }

  refreshMenuItems() {
    for (const item of this.globals.currentHeader) {
      if (item.title === 'Notifications') {
        item.icon = this.globals.unreadNotificationCount > 0 ?
          'assets/icons/design_v4/notifications-sharp-unread.svg' : 'assets/icons/design_v4/notifications-sharp.svg';
      }
      if (item.title === 'Messages') {
        item.icon = this.globals.notificationCount > 0 ?
          'assets/icons/design_v4/messages-unread.svg' : 'assets/icons/design_v4/messages.svg';
      }
    }
    this.setMenuItems(this.globals.currentHeader);
  }
}
