<div class="chat-messages" [ngStyle]="{height: toolbarService.isNewIphone ? 'calc(100% - 32px)' : 'calc(100% -62px)'}">
  <ion-spinner
    *ngIf="!messagesLoaded"
    class="main-loader"
    name="crescent">
  </ion-spinner>

  <div class="messages"
       #chatList
       *ngIf="messagesLoaded">
    <div
      #updater
      *ngIf="messagePage <= maxPages && globals.messageList !== null && globals.messageList.length >= 19"
      class="updater">
      <ion-spinner
        name="crescent">
      </ion-spinner>
    </div>
    <ion-list>
      <div
        class="msg"
        *ngFor="let message of globals.messageList; let index = index">

        <div
          *ngIf="canViewDivider(index)"
          class="msg__divider">
            <div></div>
            <span>{{dividerText(index)}}</span>
            <div></div>
        </div>
        <div
          [ngClass]="{
          'mine-msg' : globals.owner.id === message.sender.id,
          'friend-msg' : globals.owner.id !== message.sender.id,
          'msg__block--sub' : !canViewNextImg(index),
          'isMobile': isMobile &&  globals.owner.id === message.sender.id
          }"

          [ngStyle]="{'flex-direction' : isMobile && globals.owner.id === message.sender.id ? 'row-reverse' : ''}"
          class="msg__block mb-s">

          <div class="msg__block__icon">
            <img
              (click)="redirectToFriendsPage(message.sender.id, isMobile)"
              [ngStyle]="{'cursor' : globals.owner.id === message.sender.id ? '' : 'pointer'}"
              [src]="message['sender'].userPhotoName"
              alt="">
            <div
              [ngStyle]="{
              'right' : isMobile && globals.owner.id === message.sender.id ? '18px' : '',
              'left' : !isMobile && globals.owner.id === message.sender.id ? '18px' : ''
              }"

              class="checks">
              <img [src]="'assets/icons/design_v4/first_check.svg'" alt="">
              <img *ngIf="!message.unread" [src]="'assets/icons/design_v4/first_check.svg'" alt="">
              <img *ngIf="message.unread" [src]="'assets/icons/design_v4/second_check.svg'" alt="">
            </div>
          </div>

          <div class="msg-content">
            <p
              #chatBlockOwner
              [ngStyle]="{'text-align' : isMobile && globals.owner.id === message.sender.id ? 'end' : ''}"
              class="msg-content__owner">
              <span
                (click)="redirectToFriendsPage(message.sender.id, isMobile)"
                [ngStyle]="{'cursor' : globals.owner.id === message.sender.id ? '' : 'pointer'}"
              >
                 {{ message.sender | userName }}
              </span>

              <span
                [ngStyle]="{'float' : isMobile && globals.owner.id === message.sender.id ? 'left' : ''}"
                class="msg-content__owner--time">
                {{dateService.fromNow(message.creationDate)}}
              </span>
            </p>
            <p
              [style.maxWidth]="chatBlockContent + 'px'"
              [ngStyle]="{'float' : isMobile && globals.owner.id === message.sender.id ? 'right' : ''}"
              [innerHTML]="message.content | linkify"
              class="msg-content__text">
            </p>
          </div>

        </div>

      </div>
    </ion-list>
  </div>

  <app-new-message></app-new-message>
</div>
