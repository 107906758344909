import {Injectable} from '@angular/core';
import {SiteUser} from '../modules/site-user/site-user';
import {Note} from '../modules/note/note';
import {Category} from '@app/modules/category/category';
import {Chat} from '../modules/chat/chat';
import {Message} from '../modules/chat/chat-messages/message';
import {SiteUserInfo} from '../modules/site-user/site-user-info';
import {Proficiency} from '../modules/site-user/proficiency';
import {ProfileLanguage} from '../modules/site-user/profile-language';
import {PrivacyLevel} from '../modules/site-user/privacy-level';
import {Page} from '../core/page';
import {CategoryDiscussion} from '../modules/category/category-discussion';
import {Attachment} from '../core/attachment';
import {VotingQuestion} from '@app/modules/voting-question/voting-question';
import {UserLanguage} from '@app/modules/site-user/user-language';
import {NoteFilterStorage} from "@app/modules/note-filters/note-filter-storage";
import {Notification} from '@app/modules/notifications-page/notification';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {NewFeed} from "@app/modules/home/NewFeed";


@Injectable()
export class Globals {
  // apiUrl = 'http://185.108.211.109:8080';
  // apiUrl = 'http://188.134.72.112:8080';
  // apiUrl = 'http://188.134.72.4:8080';
  apiUrl = 'https://www.witaspace.com:8443/witasocial';
  // apiUrl = 'http://localhost:8080';


  clientUrl = 'https://www.wita.space';

  ROOT_CATEGORY_TREE_SCOPE = 11;
  currentLanguage = 'en';
  isSearching = false;
  isAuthenticated = false;
  selectedUser: SiteUser = new SiteUser();
  selectedUserInfo: SiteUserInfo;
  proficiencyList: Array<Proficiency>;
  profileLanguageList: Array<ProfileLanguage>;
  profileSocialLink: string[] = [
    'Instagram',
    'Facebook',
    'Twitter',
    'VK',
    'YouTube',
    'TikTok',
    'Tumblr'
  ];
  token = '';
  noteList: Array<Note>;
  feed: NewFeed;

  messageList: Array<Message>;
  currentCategoryId: number;
  currentNote = new Note();
  chatList: Array<Chat>;
  isNewNote = false;
  friendList: SiteUser[] = [];
  people: SiteUser[] = [];
  requestList: SiteUser[] = [];
  friend: SiteUser;
  owner: SiteUser;
  serverError: boolean;
  receiver = new SiteUser();
  sender = new SiteUser();
  currentChat = new Chat();
  unreadMessagesCount: number;
  unreadNotificationCount: number;
  notificationCount: number;
  recentCategories: Array<Category> = new Array<Category>();
  maxSizeOfRecentCategories = 4;
  privacyLevels: Array<PrivacyLevel>;
  isSearchingPeople = false;
  isFriendRequestsOpen = false;
  isEditor = false;
  isCategoryEditingMode = false;
  isCategoryAddMode = false;
  isRemovingMode = false;
  needPageRefresh = true;
  isAdmin = false;
  newNoteContent: string;
  preloadedRecentCategories: Page<Category>;
  preloadedRecentDiscussions: Page<CategoryDiscussion>;
  topCategoryList: Category[];
  allCategories: Array<Category> = [];
  firstLoad: boolean;
  editorsPagePreloaded: boolean;
  newAttachments: Array<Attachment> = [];
  action: string = null;
  outgoingRequests: SiteUser[] = [];
  votingQuestions: VotingQuestion[] = [];
  votingAnsweredQuestionsId: number[] = [];
  userLanguages: Array<UserLanguage>;
  enabledSearch = false;
  searchbarField = "";
  activeFilters = new NoteFilterStorage();
  userNotifications: Array<Notification>;
  currentHeader: MenuItem[] = [];
  isDeletable = true;
  isFirstTimeNote = false;
  isNewUser = false;
}
