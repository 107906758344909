import { Component, Input } from '@angular/core';
import { CategoryService } from 'src/app/shared/services/category.service';
import { Router } from '@angular/router';
import { Globals } from '@app/shared/globals';
import * as i0 from "@angular/core";
import * as i1 from "src/app/shared/services/category.service";
import * as i2 from "@angular/router";
import * as i3 from "@app/shared/globals";
import * as i4 from "@angular/common";
function CategoryBreadcrumbsComponent_div_1_i_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "i", 5);
} }
function CategoryBreadcrumbsComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "p", 3);
    i0.ɵɵlistener("click", function CategoryBreadcrumbsComponent_div_1_Template_p_click_1_listener() { i0.ɵɵrestoreView(_r5); const breadcrumb_r1 = ctx.$implicit; const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.redirectToCategoryTreeById(breadcrumb_r1.id); });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, CategoryBreadcrumbsComponent_div_1_i_3_Template, 1, 0, "i", 4);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const breadcrumb_r1 = ctx.$implicit;
    const last_r2 = ctx.last;
    i0.ɵɵadvance(1);
    i0.ɵɵclassProp("active", last_r2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", breadcrumb_r1.title, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !last_r2);
} }
export class CategoryBreadcrumbsComponent {
    constructor(categoryService, router, globals) {
        this.categoryService = categoryService;
        this.router = router;
        this.globals = globals;
        this.breadcrumbs = [];
    }
    set categories(value) {
        if (value) {
            this.updateBreadcrumbs(value);
        }
    }
    redirectToCategoryTreeById(id) {
        switch (this.mod) {
            case 'NEW':
                this.router.navigateByUrl(`/categories/${id}/new-note`).then(r => console.log(r));
                break;
            case 'EDIT':
                const position = this.breadcrumbs.findIndex((item) => item.id === id);
                this.breadcrumbs.splice(position + 1, this.breadcrumbs.length - position);
                break;
            case 'FILTER':
                this.categoryService.filterByCategory(id);
                break;
        }
    }
    getCurrentBreadCrumb() {
        return this.breadcrumbs;
    }
    updateBreadcrumbs(currentCategory) {
        this.breadcrumbs = this.getCategoryTitles(currentCategory);
    }
    getCategoryTitles(category) {
        const result = [];
        const idArr = [];
        if (category.parentTitles) {
            for (const parent of category.parentTitles) {
                idArr.push(parent.id);
            }
            idArr.push(category.id);
            const translationSub = this.categoryService.getTranslationForTitleById(idArr).subscribe((res) => {
                for (const r of res) {
                    result.push({
                        id: r.trans_id,
                        title: this.categoryService.getCurrentTitleTranslate(r)
                    });
                }
                translationSub.unsubscribe();
            });
        }
        return result;
    }
}
CategoryBreadcrumbsComponent.ɵfac = function CategoryBreadcrumbsComponent_Factory(t) { return new (t || CategoryBreadcrumbsComponent)(i0.ɵɵdirectiveInject(i1.CategoryService), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.Globals)); };
CategoryBreadcrumbsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CategoryBreadcrumbsComponent, selectors: [["app-category-breadcrumbs"]], inputs: { mod: "mod", categories: "categories" }, decls: 2, vars: 1, consts: [[1, "breadcrumbs"], ["class", "breadcrumb-block", 4, "ngFor", "ngForOf"], [1, "breadcrumb-block"], [1, "breadcrumb-item", 3, "click"], ["class", "breadcrumb-separator", 4, "ngIf"], [1, "breadcrumb-separator"]], template: function CategoryBreadcrumbsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, CategoryBreadcrumbsComponent_div_1_Template, 4, 4, "div", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.breadcrumbs);
    } }, directives: [i4.NgForOf, i4.NgIf], styles: [".breadcrumbs[_ngcontent-%COMP%]{display:flex;flex-wrap:wrap}.breadcrumbs[_ngcontent-%COMP%]   .breadcrumb-block[_ngcontent-%COMP%]{display:flex;color:rgba(0,0,0,.5);font-family:\"San Francisco Display\",sans-serif;font-size:16px;font-weight:400}.breadcrumbs[_ngcontent-%COMP%]   .breadcrumb-block[_ngcontent-%COMP%]   .breadcrumb-item[_ngcontent-%COMP%]{cursor:pointer}.breadcrumbs[_ngcontent-%COMP%]   .breadcrumb-block[_ngcontent-%COMP%]   .breadcrumb-item.active[_ngcontent-%COMP%]{color:rgba(0,0,0,.5);font-family:\"San Francisco Display\",sans-serif;font-size:16px;font-weight:600}.breadcrumbs[_ngcontent-%COMP%]   .breadcrumb-block[_ngcontent-%COMP%]   .breadcrumb-separator[_ngcontent-%COMP%]{width:5px;height:5px;margin:5px 5px 8px;background-image:url(oval-breadcrumb.eec720eaeef8e2e54072.svg);background-size:contain;background-repeat:no-repeat}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CategoryBreadcrumbsComponent, [{
        type: Component,
        args: [{
                selector: 'app-category-breadcrumbs',
                templateUrl: './category-breadcrumbs.component.html',
                styleUrls: ['./category-breadcrumbs.component.css']
            }]
    }], function () { return [{ type: i1.CategoryService }, { type: i2.Router }, { type: i3.Globals }]; }, { mod: [{
            type: Input
        }], categories: [{
            type: Input
        }] }); })();
