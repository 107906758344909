import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/shared/globals';
import { Tokens } from '@app/shared/models/tokens';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
export class TokensService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    setTokens(data) {
        const tokens = new Tokens({
            jwtToken: data.jwtToken,
            refreshToken: data.refreshToken,
            liveTimeSec: data.liveTimeSec,
            ssoId: data.ssoId,
            createdDate: new Date().toISOString(),
        });
        localStorage.setItem('tokens', JSON.stringify(tokens));
    }
    isExpired() {
        const tmp = JSON.parse(localStorage.getItem('tokens'));
        if (!tmp) {
            return false;
        }
        const tokens = new Tokens(tmp);
        const a = moment(new Date(tokens.createdDate));
        const b = moment(new Date());
        return b.diff(a, 'seconds') > tokens.liveTimeSec;
    }
}
TokensService.ɵfac = function TokensService_Factory(t) { return new (t || TokensService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
TokensService.ɵprov = i0.ɵɵdefineInjectable({ token: TokensService, factory: TokensService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TokensService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
