import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Globals } from "@app/shared/globals";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
export class TemplateService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    getAllTemplates() {
        return this.http.get(this.globals.apiUrl + '/template/list');
    }
    getTemplateById(id) {
        const params = new HttpParams().append('id', id.toString());
        return this.http.get(this.globals.apiUrl + '/template', { params });
    }
    getTemplateByPurpose(purpose) {
        const params = new HttpParams().append('purpose', purpose);
        return this.http.get(this.globals.apiUrl + '/template/purpose', { params });
    }
    addNewTemplate(template) {
        return this.http.post(this.globals.apiUrl + '/template/new', template);
    }
    updateTemplate(template) {
        return this.http.put(this.globals.apiUrl + '/template', template);
    }
    deleteTemplateById(id) {
        const params = new HttpParams().append('id', id.toString());
        return this.http.delete(this.globals.apiUrl + '/template', { params });
    }
}
TemplateService.ɵfac = function TemplateService_Factory(t) { return new (t || TemplateService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
TemplateService.ɵprov = i0.ɵɵdefineInjectable({ token: TemplateService, factory: TemplateService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TemplateService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
