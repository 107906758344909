import { Component, OnInit } from "@angular/core";
import { ToolbarService } from "@app/shared/services/toolbar/toolbar.service";
import { MenuItem } from "@app/shared/models/menu/MenuItem";
import { Globals } from "../../../shared/globals";
import { LanguagesList } from "@app/core/languages";
import { PopoverController } from "@ionic/angular";
import { DropDownListComponent } from "@app/shared/components/drop-down-list/drop-down-list.component";
import { LangPickerDropDownComponent } from "@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component";

@Component({
  selector: "app-category-container",
  templateUrl: "./category-container.component.html",
  styleUrls: ["./category-container.component.scss"]
})
export class CategoryContainerComponent implements OnInit {
  readonly PAGE_TITLE = "Categories";
  popover: any;
  headerActionCategory: MenuItem[] = [
    {
      title: "Edit",
      icon: "assets/icons/design_v4/edit.svg",
      action: env => this.showEditMenu(env)
    }
  ];

  dropDownEditItems = {
    data: [
      {
        callback: () => {
          this.onToggleEdit();
        },
        name: "Edit"
      },
      {
        callback: () => {
          this.onToggleRemove();
        },
        name: "Remove"
      }
    ]
  };

  languageList = LanguagesList;

  constructor(
    private toolbarService: ToolbarService,
    private globals: Globals,
    private popoverController: PopoverController
  ) {
    const lang = this.languageList.filter(
      l => l.en === this.globals.currentLanguage
    )[0].name;

    this.headerActionCategory.push({
      icon: `assets/icons/design_v4/langs/${lang.toLocaleLowerCase()}.svg`,
      title: lang,
      action: env => this.presentLanguageMenu(env)
    });
  }
  updateNavigation(): void {
    this.toolbarService.setTitle(this.PAGE_TITLE);

    if (this.globals.isCategoryEditingMode) {
      const done: MenuItem[] = [];

      this.toolbarService.setMenuItems(done);
    } else {
      this.toolbarService.setMenuItems(this.headerActionCategory);
    }

    if (this.globals.isRemovingMode) {
      const done: MenuItem[] = [];

      this.toolbarService.setMenuItems(done);
    } else {
      this.toolbarService.setMenuItems(this.headerActionCategory);
    }
  }

  async showEditMenu(env) {
    const modal = await this.popoverController.create({
      component: DropDownListComponent,
      componentProps: this.dropDownEditItems,
      event: env,
      translucent: false
    });

    await modal.present();
  }

  async presentLanguageMenu(env) {
    this.popover = await this.popoverController.create({
      component: LangPickerDropDownComponent,
      event: env,
      translucent: false,
      showBackdrop: false
    });

    await this.popover.present();

    this.popover.onDidDismiss().then(e => {
      if (!e.data) {
        return;
      }

      this.headerActionCategory[
        this.headerActionCategory.length - 1
      ].icon = `assets/icons/design_v4/langs/${e.data.name.toLocaleLowerCase()}.svg`;
      this.headerActionCategory[this.headerActionCategory.length - 1].title =
        e.data.name;
    });
  }

  ngOnInit(): void {}

  onToggleRemove(): void {}

  onToggleEdit(): void {}
}
