import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
function DropDownListComponent_ion_item_1_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-item", 2);
    i0.ɵɵlistener("click", function DropDownListComponent_ion_item_1_Template_ion_item_click_0_listener() { i0.ɵɵrestoreView(_r3); const menu_r1 = ctx.$implicit; const ctx_r2 = i0.ɵɵnextContext(); menu_r1.callback(); return ctx_r2.popoverCtrl.dismiss(); });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const menu_r1 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 1, menu_r1.name), " ");
} }
export class DropDownListComponent {
    constructor(popoverCtrl) {
        this.popoverCtrl = popoverCtrl;
        this.popoverData = [];
    }
    ngOnInit() {
        const component = this;
        this.popoverData = component.data;
    }
}
DropDownListComponent.ɵfac = function DropDownListComponent_Factory(t) { return new (t || DropDownListComponent)(i0.ɵɵdirectiveInject(i1.PopoverController)); };
DropDownListComponent.ɵcmp = i0.ɵɵdefineComponent({ type: DropDownListComponent, selectors: [["app-drop-down-component"]], decls: 2, vars: 1, consts: [[2, "max-height", "240px", "overflow-x", "auto"], ["button", "", 3, "click", 4, "ngFor", "ngForOf"], ["button", "", 3, "click"]], template: function DropDownListComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-list", 0);
        i0.ɵɵtemplate(1, DropDownListComponent_ion_item_1_Template, 3, 3, "ion-item", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.popoverData);
    } }, directives: [i1.IonList, i2.NgForOf, i1.IonItem], pipes: [i3.TranslatePipe], styles: ["ion-item[_ngcontent-%COMP%]:last-child{--inner-border-width:0}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DropDownListComponent, [{
        type: Component,
        args: [{
                selector: 'app-drop-down-component',
                template: `
    <ion-list style="max-height: 240px; overflow-x: auto;">
      <ion-item
        *ngFor="let menu of popoverData"
        (click)="menu.callback(); popoverCtrl.dismiss()"
        button>
        {{menu.name | translate}}
      </ion-item>
    </ion-list>
  `,
                styleUrls: ['drop-down-list.component.css']
            }]
    }], function () { return [{ type: i1.PopoverController }]; }, null); })();
