import {SiteUser} from '../site-user/site-user';
import {Message} from './chat-messages/message';


export class Chat {
  id: number;
  firstUser: SiteUser;
  secondUser: SiteUser;
  lastMessageDto: Message;
  unreadCount: number;
  totalCount?: number;
}
