<div class="emoji-picker">
  <div class="emoji-items">
    <ion-slides pager>

      <ion-slide *ngFor="let items of emojiArr" style="flex-wrap: wrap;">
        <span class="emoji-item"
              (click)="setValue(item)"
              *ngFor="let item of items">
          {{item}}
        </span>
      </ion-slide>

    </ion-slides>
  </div>
</div>
