import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Globals} from '@app/shared/globals';
import {SiteUser} from '../site-user/site-user';
import {UserRelationService} from '@app/shared/services/user-relation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ChatService} from '@app/shared/services/chat.service';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {ADD_NEW_NOT_ACTION} from '@app/shared/actions';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {TranslateService} from '@ngx-translate/core';
import {Page} from '@app/core/page';
import {SiteUserService} from '@app/shared/services/site-user.service';
import {Subscription} from 'rxjs';
import {UserNamePipe} from '@app/shared/pipes/user-name.pipe';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.css']
})
export class FriendsComponent implements OnInit, UpdateNavigation, OnDestroy {
  readonly FRIEND_RELATION_TYPE = 2;
  readonly REQUEST_RELATION_TYPE = 1;

  pageData: Page<SiteUser>;
  currentUserId: number;

  @ViewChild('searchComponent', {static: false}) searchComponent;

  menuButtonWithoutNotifications: MenuItem[] = [
    {
      title: 'Notifications',
      icon: 'assets/icons/design_v4/notifications-sharp.svg',
      path: '/notifications',
    },
    {
      title: 'Friends Search',
      icon: 'assets/icons/design_v4/search.svg',
      action: this.toggleFriendsSearch.bind(this),
    },
  ];

  menuButtonUnreadNotifications: MenuItem[] = [
    {
      title: 'Notifications',
      icon: 'assets/icons/design_v4/notifications-sharp-unread.svg',
      path: '/notifications',
    },
    {
      title: 'Friends Search',
      icon: 'assets/icons/design_v4/search.svg',
      action: this.toggleFriendsSearch.bind(this),
    },
  ];

  translationText: any;
  private routeSubscription: Subscription;

  constructor(public globals: Globals,
              public userRelationService: UserRelationService,
              private uiAlertService: UiAlertService,
              public chatService: ChatService,
              public router: Router,
              private toolbarService: ToolbarService,
              private translate: TranslateService,
              private userService: SiteUserService,
              public activatedRoute: ActivatedRoute,
              public userName: UserNamePipe
  ) {
  }

  ngOnInit() {
    this.translate.get([
      'Friend request was sent.',
      'You are sure you want to remove',
      'from friends?',
      'Confirm!',
      'Cancel',
      'Remove',
      'Success'
    ]).subscribe(tr => {
      this.translationText = tr;
    });
    this.routeSubscription = this.activatedRoute.queryParamMap.subscribe(params => {
      this.currentUserId = Number(params.get('id'));
      console.log(  this.currentUserId);
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  getFriends(page: number, callback?: any) {
    this.userService.getUsersBy('', page, this.FRIEND_RELATION_TYPE, this.currentUserId).subscribe((data: Page<SiteUser>) => {
      this.pageData = data;
      this.globals.friendList = page === 0 ? this.pageData.content :   this.globals.friendList.concat(this.pageData.content);
      this.globals.people = this.globals.friendList;

      this.globals.isSearchingPeople = false;
      if (callback) {
        callback();
      }
    });
  }

  ionViewWillEnter() {
    this.getFriends(0);
  }

  updateNavigation(): void {
    if (this.globals.unreadNotificationCount > 0) {
      this.toolbarService.setMenuItems(this.menuButtonUnreadNotifications);
    } else {
      this.toolbarService.setMenuItems(this.menuButtonWithoutNotifications);
    }
  }

  checkLink(link): string {
    return link.includes('https://') ? link : `https://${link}`;
  }

  removeFromFriends(friend: SiteUser) {
    this.userRelationService.deleteUserRelation(friend, () => {
      friend.relationType = 0;
      this.globals.friendList.splice(this.globals.friendList.indexOf(friend), 1);
    });
  }

  toggleFriendsSearch() {
    this.globals.isSearching = !this.globals.isSearching;
    this.globals.isSearchingPeople = false;
    this.globals.people = this.globals.friendList;
    this.updateNavigation();
    if (this.globals.isSearching) {
      setTimeout(() => this.searchComponent.focusOnInput());
    }
  }

  ionViewDidLeave(): void {
    this.globals.isSearching = false;
  }

  sendFriendRequest(friend: SiteUser) {
    this.userRelationService.addUserRelation(friend, () => {
      friend.relationType = 1;
    });
  }

  toFriend(id) {
    this.globals.noteList = null;
    this.globals.allCategories = [];
    this.globals.activeFilters.removeFilter(this.globals.activeFilters.categoryFilter);
    this.globals.needPageRefresh = true;
    this.router.navigate(['/users/' + id + '/notes']);
  }

  canAddFriend(relationType: number) {
    const isNotFriend = relationType !== this.FRIEND_RELATION_TYPE;
    const isNotRequested = relationType !== this.REQUEST_RELATION_TYPE;
    return isNotFriend && isNotRequested;
  }

  onItemClickHandler(user: SiteUser) {
    this.toFriend(user.id);
  }

  async onPlusClickHandler(user: SiteUser) {
    await this.uiAlertService.createAlert(this.translationText['Success'], this.translationText['Friend request was sent.']);
    this.sendFriendRequest(user);
  }

  transform(user: SiteUser): any {
    if (!user.firstName && !user.lastName) {
      return user.ssoId;
    }

    return `${user.firstName} ${user.lastName}`;
  }

  async confirmRemove(friend: SiteUser) {
    await this.uiAlertService.createConfirm(this.translationText['Confirm!'],
      this.translationText['You are sure you want to remove'] + ` ${this.userName.transform(friend)} ` + this.translationText['from friends?'],
      this.translationText['Cancel'], this.translationText['Remove'], () => {
        this.removeFromFriends(friend);
      });
  }

  doRefreshContent(event) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: ''
      });
    this.currentUserId = 0;
    this.getFriends(0, () => {
      this.globals.isSearching = false;
      event.target.complete();
    });
  }

  loadData($event: CustomEvent) {
    if (this.globals.isSearchingPeople) {
      this.searchComponent.nextPage($event);
    } else {
      this.nextPage($event);
    }
  }

  nextPage(event?) {
    if (!this.pageData.last) {
      this.getFriends(this.pageData.number + 1, () => {
        event.target.complete();
      });
    } else {
      if (event) {
        event.target.complete();
      }
    }
  }
}
