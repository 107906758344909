import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Globals } from "@app/shared/globals";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
export class AlertsService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    sendAlert(alert) {
        return this.http.post(this.globals.apiUrl + '/feedback', alert);
    }
    getAlertById(id) {
        let params = new HttpParams().append('id', id.toString());
        this.http.get(this.globals.apiUrl + '/feedback', { params }).subscribe((alert) => {
            console.log(alert);
        });
    }
    getAlertsByUserId(userId) {
        let params = new HttpParams().append('userId', userId.toString());
        return this.http.get(this.globals.apiUrl + '/feedback', { params });
    }
    editQuestion(alert) {
        return this.http.put(this.globals.apiUrl + '/feedback', alert);
    }
    deleteQuestionById(id) {
        let params = new HttpParams().append('id', id.toString());
        return this.http.delete(this.globals.apiUrl + '/feedback', { params });
    }
}
AlertsService.ɵfac = function AlertsService_Factory(t) { return new (t || AlertsService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
AlertsService.ɵprov = i0.ɵɵdefineInjectable({ token: AlertsService, factory: AlertsService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AlertsService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
