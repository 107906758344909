import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LevelData} from '@app/modules/category-level/LevelData';
import {CategoryService} from '@app/shared/services/category.service';
import {Globals} from "@app/shared/globals";
import {LabelService} from "@app/shared/services/label.service";
import {Label} from "@app/modules/label/label";
import {GeoService} from "@app/shared/services/geo.service";
import {Place} from "@app/modules/place/place";
import {Category} from "@app/modules/category/category";
import {FeedService} from "@app/shared/services/feed.service";


@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  @Input() levelData: LevelData;

  @Output() selectedCategory = new EventEmitter<number>();
  @Output() showAddNewCategoryBlock = new EventEmitter<null>();

  private selectedPlace: Place;

  constructor(public categoryService: CategoryService,
              public globals: Globals,
              public labelService: LabelService,
              public geoService: GeoService,
              public feedService: FeedService) {
  }

  ngOnInit() {
    this.geoService.selectedPlace.subscribe((place: Place) => {
      this.selectedPlace = place;
    });
  }

  selectCategoryHandler(categoryId: number) {
    this.categoryService.getChildren(categoryId, this.globals.selectedUser.id).subscribe((response: Category[]) => {
      this.globals.allCategories = this.categoryService.insertNewCat(this.globals.allCategories, response);
      this.globals.allCategories = this.categoryService.assignCategoriesParents(this.globals.allCategories);
      this.selectedCategory.emit(categoryId);
    });
  }

  showAddNewCategoryFormHandler() {
    const label = new Label();
    if (!this.geoService.isPlaceEmpty(this.selectedPlace)) {
      label.places = [this.selectedPlace];
      this.labelService.createLabel(label).subscribe((result: Label) => {
        this.labelService.changeSelectedLabel(result);
      });
    }
    this.globals.isCategoryAddMode = true;
    this.levelData.showAddCategoryButton = false;
    this.showAddNewCategoryBlock.emit();
  }

}
