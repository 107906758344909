import {Component, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '@app/shared/globals';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteUserService} from '@app/shared/services/site-user.service';
import {NoteService} from '@app/shared/services/note.service';
import {Page} from '@app/core/page';
import {CategoryDiscussion} from '../category/category-discussion';
import {EditorsService} from '@app/shared/services/editors.service';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {Note} from '@app/modules/note/note';
import {LanguagesList} from '@app/core/languages';
import {Events, IonContent, IonSlides, PopoverController} from '@ionic/angular';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {of, Subscription} from 'rxjs';
import {SubscriptionService} from '@app/shared/services/subscription.service';
import {Category} from '@app/modules/category/category';
import {NoteListPage} from "@app/modules/home/NoteListPage";
import {BdcWalkService} from "bdc-walkthrough";

@Component({
  selector: 'app-home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends NoteListPage implements UpdateNavigation {

  @ViewChild(IonSlides, {static: true}) slider: IonSlides;
  @ViewChild('profileHeader', {static: false}) profileHeader;
  @ViewChild(IonContent, {static: false}) content: IonContent;

  readonly slideOpts = {
    autoHeight: true,
    initialSlide: 1,
  };
  popover;
  languageList = LanguagesList;
  popoverOpened = false;

  sliderIndex = 1;
  private previousIndex: number;
  private homeSubscription: Subscription;
  private friendFeedSubscription: Subscription;
  private worldFeedSubscription: Subscription;
  private homeByCategorySubscription: Subscription;
  private friendFeedByCategorySubscription: Subscription;
  private worldFeedByCategorySubscription: Subscription;
  private sliderSubscription: Subscription;


  readonly headerActions: MenuItem[] = [
    {
      title: 'Category tree',
      icon: 'assets/icons/design_v4/category_path.svg',
      action: () => {
        this.routeCategories();
      }
    },
    {
      title: 'Notifications',
      path: '/notifications',
      icon: 'assets/icons/design_v4/notifications-outline.svg',
      action: () => {
        this.globals.feed.closeFeed();
      },
    },
    {
      icon: 'assets/icons/design_v4/filter.svg',
      title: 'Filter',
      action: this.onToggleNoteFilters.bind(this),
    }
  ];


  constructor(
    public http: HttpClient,
    public globals: Globals,
    public activeRoute: ActivatedRoute,
    public userService: SiteUserService,
    public noteService: NoteService,
    public editorsService: EditorsService,
    public router: Router,
    public popoverController: PopoverController,
    public toolbarService: ToolbarService,
    public subscriptionService: SubscriptionService,
    public events: Events,
    public bdcWalkService: BdcWalkService
  ) {
    super(http, globals, activeRoute, userService, noteService, editorsService,
      router, popoverController, toolbarService, subscriptionService, events, bdcWalkService);
    this.feeds = of([
      this.homeFeed,
      this.worldFeed,
      this.friendFeed
    ]);
    this.events.subscribe('refresh:home', (isRefresh) => {
      if (isRefresh) {
        console.log(" constructor ");
        this.slideTo(1);
      }
    });
  }

  ionViewWillEnter() {
    console.log(" ionViewWillEnter ");
    this.setCurrentFeed();

    this.userService.setSelectedUser(this.globals.owner);
    this.updateNotesAndHeader(false, this.globals.selectedUser.id);

    this.sliderSubscription = this.slider.ionSlideWillChange.subscribe(e => {
      console.log(" ionViewWillEnter ionSlideDidChange ");
      this.slider.getSwiper().then(swiper => {
        this.onSlide(swiper);
        this.updateNotesAndHeader(false, this.globals.selectedUser.id);
        this.scrollUp();
      });
    });
  }

  ionViewDidEnter(): void {
    console.log(" ionViewDidEnter ");
    if (this.globals.firstLoad) {
      if (this.globals.isEditor || this.globals.isAdmin) {
        this.initializeEditorPage();
        this.userService.updateLocation();
      }
    } else {
      this.toolbarService.refreshMenuItems();
    }
  }

  ionViewDidLeave() {
    console.log(" ionViewDidLeave ");
    this.subscriptionService.unSubscriber(this.sliderSubscription);
    this.subscriptionService.unSubscriber(this.homeSubscription);
    this.subscriptionService.unSubscriber(this.friendFeedSubscription);
    this.subscriptionService.unSubscriber(this.worldFeedSubscription);
  }

  onSlide(swiper) {
    console.log(" slideChanged ");
    this.sliderIndex = swiper.realIndex;
    this.previousIndex = swiper.previousIndex;
    switch (swiper.realIndex) {
      case 1:
        this.slider.lockSwipeToNext(false);
        this.slider.lockSwipeToPrev(false);
        this.worldFeed.openFeed();
        this.homeFeed.closeFeed();
        this.friendFeed.closeFeed();
        this.globals.feed = this.worldFeed;
        break;
      case 2:
        this.slider.lockSwipeToNext(true);
        this.slider.lockSwipeToPrev(false);
        this.friendFeed.openFeed();
        this.worldFeed.closeFeed();
        this.homeFeed.closeFeed();
        this.globals.feed = this.friendFeed;
        break;
      default:
        this.slider.lockSwipeToNext(false);
        this.slider.lockSwipeToPrev(true);
        this.homeFeed.openFeed();
        this.worldFeed.closeFeed();
        this.friendFeed.closeFeed();
        this.globals.feed = this.homeFeed;
    }
    this.setCurrentFeed();
    this.completeEffects();
  }


  scrollUp() {
    this.content.scrollToTop();
  }


  updateNotesAndHeader(areNotesLoaded: boolean, userId: number, event?) {
    console.log(" updateNotesAndHeader ");
    this.profileHeader.init(userId);
    this.areNotesLoaded = areNotesLoaded;
    this.globals.needPageRefresh = true;
    this.dispatchContentRefreshRequest(0, true, event);
  }

  private dispatchContentRefreshRequest(page: number, clearNotes: boolean, event?) {
    console.log(" dispatchContentRefreshRequest " + this.globals.feed.isFeedEnabled);
    if (!this.globals.needPageRefresh) {
      console.log("tra ta ta");
      return;
    }
    console.log(" world " + this.worldFeed.isFeedEnabled);
    console.log(" home " + this.homeFeed.isFeedEnabled);
    console.log(" friend " + this.friendFeed.isFeedEnabled);
    console.log(" user " + this.userFeed.isFeedEnabled);
    if (this.homeFeed.isFeedEnabled) {
      console.log(" home ");
      this.homeFeed.paginator.currentPage = page;
      this.dispatchHomeContentRefreshRequest(page, clearNotes, event);
    } else if (this.friendFeed.isFeedEnabled) {
      console.log(" friend ");
      this.friendFeed.paginator.currentPage = page;
      this.dispatchFriendsContentRefreshRequest(page, clearNotes, event);
    } else if (this.worldFeed.isFeedEnabled) {
      console.log(" world ");
      this.worldFeed.paginator.currentPage = page;
      this.dispatchWorldContentRefreshRequest(page, clearNotes, event);
    }
  }

  private dispatchHomeContentRefreshRequest(page: number, clearNotes: boolean, event?) {
    console.log(" dispatchHomeContentRefreshRequest ");
    if (this.globals.activeFilters.categoryFilter.isEmpty()) {
      this.subscriptionService.unSubscriber(this.friendFeedSubscription);
      this.subscriptionService.unSubscriber(this.worldFeedSubscription);
      this.homeSubscription = this.noteService.getUserAllNotes(page)
        .subscribe((data: Page<Note>) => {
          this.finishNotesRefresh(data, clearNotes, event);
        });
    } else {
      this.subscriptionService.unSubscriber(this.friendFeedByCategorySubscription);
      this.subscriptionService.unSubscriber(this.worldFeedByCategorySubscription);
      this.homeByCategorySubscription = this.noteService.getUserNoteListByCategory(this.globals.currentCategoryId, page)
        .subscribe((data: Page<Note>) => {
          this.finishNotesRefresh(data, clearNotes, event);
        });
    }

  }

  private dispatchFriendsContentRefreshRequest(page: number, clearNotes: boolean, event?) {
    console.log(" dispatchFriendsContentRefreshRequest ");

    if (this.globals.activeFilters.categoryFilter.isEmpty()) {
      this.subscriptionService.unSubscriber(this.homeSubscription);
      this.subscriptionService.unSubscriber(this.worldFeedSubscription);
      this.friendFeedSubscription = this.noteService.getFriendsNotes(page)
        .subscribe((response: Page<Note>) => {
          this.finishNotesRefresh(response, clearNotes, event);
        });
    } else {
      this.subscriptionService.unSubscriber(this.homeByCategorySubscription);
      this.subscriptionService.unSubscriber(this.worldFeedByCategorySubscription);
      this.friendFeedByCategorySubscription = this.noteService.getFriendNoteListByCategory(this.globals.currentCategoryId, page)
        .subscribe((data: Page<Note>) => {
          this.finishNotesRefresh(data, clearNotes, event);
        });
    }

  }

  private dispatchWorldContentRefreshRequest(page: number, clearNotes: boolean, event?) {
    console.log(" dispatchWorldContentRefreshRequest ");
    if (this.globals.activeFilters.categoryFilter.isEmpty()) {
      this.subscriptionService.unSubscriber(this.homeSubscription);
      this.subscriptionService.unSubscriber(this.friendFeedSubscription);
      this.worldFeedSubscription = this.noteService.getNotes(page)
        .subscribe((data: Page<Note>) => {
          this.finishNotesRefresh(data, clearNotes, event);
        });
    } else {
      this.subscriptionService.unSubscriber(this.homeByCategorySubscription);
      this.subscriptionService.unSubscriber(this.friendFeedByCategorySubscription);
      this.worldFeedByCategorySubscription = this.noteService.getNoteListByCategory(this.globals.currentCategoryId, page)
        .subscribe((data: Page<Note>) => {
          this.finishNotesRefresh(data, clearNotes, event);
        });
    }
  }

  private finishNotesRefresh(response: Page<Note>, clearNotes: boolean, event?) {
    console.log(" finishNotesRefresh ");
    this.globals.noteList = clearNotes ? response.content || [] : this.globals.noteList.concat(response.content);
    response.content = this.globals.noteList;
    this.globals.needPageRefresh = false;
    this.areNotesLoaded = true;
    if (this.friendFeed.isFeedEnabled) {
      this.friendFeed.paginator = this.setPaginatorFields(response, this.friendFeed.paginator);
    } else if (this.worldFeed.isFeedEnabled) {
      this.worldFeed.paginator = this.setPaginatorFields(response, this.worldFeed.paginator);
      if (this.globals.firstLoad) {
        this.globals.noteList = this.worldFeed.paginator.items;
        this.globals.firstLoad = false;
      }
    } else if (this.homeFeed.isFeedEnabled) {
      this.homeFeed.paginator = this.setPaginatorFields(response, this.homeFeed.paginator);
    }
    if (event) {
      this.completeEffects();
      // event.target.complete();
    }
  }


  private initializeEditorPage() {
    console.log("init editors page");
    this.editorsService.getRecentPrivateCategories(0).subscribe((categories: Page<Category>) => {
      this.globals.preloadedRecentCategories = categories as Page<Category>;
      this.globals.editorsPagePreloaded = true;
    });
    this.editorsService.getRecentPrivateCategoriesDiscussions(0).subscribe((discuss: Page<CategoryDiscussion>) => {
      this.globals.preloadedRecentDiscussions = discuss as Page<CategoryDiscussion>;
    });
    this.editorsService.initializeCategoryLangs();
  }


  loadDataForPagination(event) {
    console.log(" loadDataForPagination ");
    this.globals.needPageRefresh = true;
    if (this.homeFeed.paginator.currentPage < this.homeFeed.paginator.lastPage ||
      this.worldFeed.paginator.currentPage < this.worldFeed.paginator.lastPage ||
      this.friendFeed.paginator.currentPage < this.friendFeed.paginator.lastPage) {
      if (this.homeFeed.isFeedEnabled) {
        this.dispatchContentRefreshRequest(++this.homeFeed.paginator.currentPage, false, event);
      } else if (this.friendFeed.isFeedEnabled) {
        this.dispatchContentRefreshRequest(++this.friendFeed.paginator.currentPage, false, event);
      } else if (this.worldFeed.isFeedEnabled) {
        this.dispatchContentRefreshRequest(++this.worldFeed.paginator.currentPage, false, event);
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  }

  nextSlide() {
    this.scrollUp();
    this.sliderIndex++;
    this.slider.slideNext();
    this.completeEffects();
  }

  prevSlide() {
    this.scrollUp();
    this.sliderIndex--;
    this.slider.slidePrev();
    this.completeEffects();
  }



}
