import { Component, Input } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { GeoService } from "@app/shared/services/geo.service";
import { LabelService } from "@app/shared/services/label.service";
import { LabelType } from "@app/modules/label/label-type";
import { LabelTypeTitle } from "@app/modules/label/label-type-title";
import { Label } from "@app/modules/label/label";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@app/shared/services/geo.service";
import * as i3 from "@app/shared/services/label.service";
import * as i4 from "@angular/forms";
export class LabelComponent {
    constructor(modalController, geoService, labelService) {
        this.modalController = modalController;
        this.geoService = geoService;
        this.labelService = labelService;
        this.labelType = new LabelType(LabelTypeTitle.PLACE);
    }
    ngOnInit() {
        this.geoService.getDefaultPlace().subscribe((place) => {
            this.selectedPlace = place;
            this.geoService.changeSelectedPlace(place);
        });
    }
    selectLabelType() {
        if (this.labelType.isChecked) {
            const label = new Label();
            label.places = [this.selectedPlace];
            this.createLabel(label);
        }
        this.modalController.dismiss();
    }
    createLabel(label) {
        this.labelService.createLabel(label).subscribe((result) => {
            this.selectedLabel = result;
            this.labelService.changeSelectedLabel(this.selectedLabel);
        });
    }
}
LabelComponent.ɵfac = function LabelComponent_Factory(t) { return new (t || LabelComponent)(i0.ɵɵdirectiveInject(i1.ModalController), i0.ɵɵdirectiveInject(i2.GeoService), i0.ɵɵdirectiveInject(i3.LabelService)); };
LabelComponent.ɵcmp = i0.ɵɵdefineComponent({ type: LabelComponent, selectors: [["app-label"]], inputs: { levelData: "levelData" }, decls: 13, vars: 2, consts: [["translucent", "true"], ["slot", "end", 3, "ngModel", "ngModelChange"], [1, "label-select"], [1, "label-select-btn", 3, "click"]], template: function LabelComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-content");
        i0.ɵɵelementStart(1, "ion-header", 0);
        i0.ɵɵelementStart(2, "ion-toolbar");
        i0.ɵɵelementStart(3, "ion-title");
        i0.ɵɵtext(4, "Select Label Type");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "ion-list");
        i0.ɵɵelementStart(6, "ion-item");
        i0.ɵɵelementStart(7, "ion-label");
        i0.ɵɵtext(8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "ion-checkbox", 1);
        i0.ɵɵlistener("ngModelChange", function LabelComponent_Template_ion_checkbox_ngModelChange_9_listener($event) { return ctx.labelType.isChecked = $event; });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(10, "ion-item", 2);
        i0.ɵɵelementStart(11, "ion-button", 3);
        i0.ɵɵlistener("click", function LabelComponent_Template_ion_button_click_11_listener() { return ctx.selectLabelType(); });
        i0.ɵɵtext(12, " Select ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(8);
        i0.ɵɵtextInterpolate(ctx.labelType.title);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngModel", ctx.labelType.isChecked);
    } }, directives: [i1.IonContent, i1.IonHeader, i1.IonToolbar, i1.IonTitle, i1.IonList, i1.IonItem, i1.IonLabel, i1.IonCheckbox, i1.BooleanValueAccessor, i4.NgControlStatus, i4.NgModel, i1.IonButton], styles: [".label-select[_ngcontent-%COMP%]{width:100%;background-color:#fff;opacity:.9;position:fixed;bottom:5px}.label-select-btn[_ngcontent-%COMP%]{max-width:70px;margin:0 auto}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LabelComponent, [{
        type: Component,
        args: [{
                selector: 'app-label',
                templateUrl: './label.component.html',
                styleUrls: ['./label.component.css']
            }]
    }], function () { return [{ type: i1.ModalController }, { type: i2.GeoService }, { type: i3.LabelService }]; }, { levelData: [{
            type: Input
        }] }); })();
