import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../globals';
import {SiteUser} from '../../modules/site-user/site-user';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(public http: HttpClient, public globals: Globals) {
  }

  editUserPhoto(uploadData) {
    return this.http.post(this.globals.apiUrl + '/users/photo', uploadData, {
      reportProgress: true
    });
  }

  editSiteUserName() {
    this.http.put(this.globals.apiUrl + '/users', this.globals.owner).subscribe(() => {
    });
  }

  getUserInfo(user: SiteUser) {
    return this.http.get(this.globals.apiUrl + '/users/' + user.id + '/info');
  }

  getUserInfoById(userId: number) {
    return this.http.get(this.globals.apiUrl + '/users/' + userId + '/info');
  }

  getReferralCounter(user: SiteUser) {
    return this.http.get(this.globals.apiUrl + '/users/' + user.id + '/info/counters/referrals');
  }

  getAvailableLanguages() {
    return this.http.get(this.globals.apiUrl + '/users/languages');
  }

  getProficiencyList() {
    return this.http.get(this.globals.apiUrl + '/users/proficiencies');
  }

  editUserProfileInfo(userInfo, callback) {
    this.http.put(this.globals.apiUrl + '/users/info', userInfo).subscribe(() => {
      return callback && callback();
    });
  }

  editUserBanner(uploadData) {
    return this.http.post(this.globals.apiUrl + '/users/banner', uploadData, {
      reportProgress: true
    });
  }

  getUserLanguages(userId: number) {
    return this.http.get(this.globals.apiUrl + '/users/' + userId + '/languages');
  }

  setReferrer(selectedUser: SiteUser, referrer: string) {
    if (referrer != null && referrer !== "") {
      referrer = referrer.toLowerCase();
      console.log("Service referrer is " + referrer);
      this.http.put(this.globals.apiUrl + '/users/' + selectedUser.id + '/info/referrer', referrer).subscribe(() => {
      });
    }
  }
}
