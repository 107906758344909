import {Component, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {LanguagesList} from '@app/core/languages';
import {UtilsService} from '@app/shared/services/utils.service';
import {Globals} from '@app/shared/globals';

@Component({
  selector: 'app-lang-picker-drop-down',
  template: `
    <ion-list style="max-height: 340px; overflow-x: auto;">
      <ion-item
        *ngFor="let menu of dataList"
        (click)="menu.callback()"
        button>
        <span [ngStyle]="{'opacity': menu.active ? '0.3' : 1}">{{menu.name}}</span>


        <div slot="end" style="display: flex">
          <img
            *ngIf="menu.icon"
            [ngStyle]="{'opacity': menu.active ? '0.3' : 1}"
            [src]="'assets/icons/design_v4/' + menu.icon"
            [alt]="menu.name">

          <span style="width: 22px; height: 22px">
            <img
              style="height: 22px; margin-left: 10px"
              *ngIf="menu.active"
              [src]="'assets/icons/design_v4/first_check.svg'"
              [alt]="menu.name">
          </span>
        </div>

      </ion-item>
    </ion-list>
  `,
  styles: [``]
})
export class LangPickerDropDownComponent implements OnInit {

  languageList = LanguagesList;
  dataList;


  constructor(
    private popoverCtrl: PopoverController,
    private utilsService: UtilsService,
    private globals: Globals
  ) {}

  ngOnInit() {
    const lang = this.languageList.filter(l  => l.en === this.globals.currentLanguage)[0];

    this.dataList = [...this.languageList.map(l => {
      return {
        name: l.name,
        active: lang.name === l.name ,
        icon: `langs/${l.name.toLocaleLowerCase()}.svg`,
        callback: () => {
          this.utilsService.switchLanguage(l.en);
          this.popoverCtrl.dismiss(l);
        }
      };
    })];

  }

}
