<ion-content>

  <ion-header translucent="true">
    <ion-toolbar>
      <ion-title>Select Label Type</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-list>
    <ion-item>
      <ion-label>{{labelType.title}}</ion-label>
      <ion-checkbox slot="end" [(ngModel)]="labelType.isChecked"></ion-checkbox>
    </ion-item>
  </ion-list>
  <ion-item class="label-select">
    <ion-button class="label-select-btn" (click)="selectLabelType()">
      Select
    </ion-button>
  </ion-item>
</ion-content>
