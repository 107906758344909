import {Component, OnInit} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {Globals} from '../../../shared/globals';
import {Router} from '@angular/router';
import {DropDownListComponent} from '../../../shared/components/drop-down-list/drop-down-list.component';
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";

@Component({
  selector: 'app-mobile-chat-modal',
  templateUrl: './mobile-chat-modal.component.html',
  styleUrls: ['./mobile-chat-modal.component.css']
})
export class MobileChatModalComponent implements OnInit {

  getLastMessage: any;
  activeDialogue: any;
  chatList;

  actions = [
    {
      name: 'Category tree',
      icon: 'assets/icons/design_v4/category_path.svg',
      action: (ev?) => {
        this.dismiss();
        this.router.navigate(['/categories']);
      }
    },
    {
      name: 'More',
      icon: 'assets/icons/design_v4/more_btn.svg',
      action: (ev?: any) => {
        console.log(this)
        this.dropDownMenu(ev);
      }
    }
  ];

  dropDownMenuItems = {
    data: [
      {
        callback: () => {
          console.log('1');
        },
        name: 'First'
      },
      {
        callback: () => {
          console.log('2');
        },
        name: 'Second'
      }
    ]
  };

  constructor(
    public globals: Globals,
    public toolbarService: ToolbarService,
    public router: Router,
    public popoverController: PopoverController,
    public modalCtrl: ModalController) {
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async dropDownMenu(ev: any) {
    const modal = await this.popoverController.create({
      component: DropDownListComponent,
      componentProps: this.dropDownMenuItems,
      event: ev,
      translucent: true
    });

    await modal.present();
  }
}
