import {SiteUser} from '../../site-user/site-user';

export class Message {
  id: number;
  content: string;
  sender: SiteUser;
  receiver: SiteUser;
  unread: boolean;
  chat: number;
  updateDate: Date;
  creationDate: Date;
}
