import {Component} from '@angular/core';
import {CategoryService} from '@app/shared/services/category.service';
import {Globals} from '@app/shared/globals';
import {NoteService} from '@app/shared/services/note.service';
import {Category} from '@app/modules/category/category';
import {Router} from '@angular/router';
import {SharedService} from '@app/shared/services/shared.service';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {LanguagesList} from "@app/core/languages";
import {LangPickerDropDownComponent} from "@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component";
import {PopoverController} from "@ionic/angular";
import {FeedService} from "@app/shared/services/feed.service";

@Component({
  selector: 'app-category-flower',
  templateUrl: './category-flower.component.html',
  styleUrls: ['./category-flower.component.scss']
})
export class CategoryFlowerComponent implements UpdateNavigation {
  readonly firstRowLength = 2;
  readonly rowLength = 3;
  readonly corrective = this.rowLength - this.firstRowLength;
  public categoriesLoaded = false;

  public disabledSearch = true;
  public searchTitle = '';
  public searchResult: Category[];

  languageList = LanguagesList;
  popover: any;

  private upMotion: string;
  menuButtonUnreadMessages: MenuItem[] = [
    {
      title: 'Notifications',
      icon: 'assets/icons/design_v4/notifications-sharp-unread.svg',
      path: '/notifications',
    }
  ];
  menuButtonWithoutNotification: MenuItem[] = [
    {
      title: 'Notifications',
      icon: 'assets/icons/design_v4/notifications-sharp.svg',
      path: '/notifications',
    }
  ];

  headerSearchbar: MenuItem = {
    title: 'searchbar',
    placeholder: 'Search category...',
    cancelButton: false,
    action: (env) => this.onSearch(env)
  };

  constructor(public categoryService: CategoryService,
              public globals: Globals,
              public noteService: NoteService,
              public sharedService: SharedService,
              public router: Router,
              public toolbarService: ToolbarService,
              public popoverController: PopoverController,
              public feedService: FeedService) {
    const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;

    this.menuButtonUnreadMessages.push({
      icon: `assets/icons/design_v4/langs/${lang.toLocaleLowerCase()}.svg`,
      title: lang,
      action: env => this.presentLanguageMenu(env)
    });
    this.menuButtonWithoutNotification.push({
      icon: `assets/icons/design_v4/langs/${lang.toLocaleLowerCase()}.svg`,
      title: lang,
      action: env => this.presentLanguageMenu(env)
    });
  }

  ionViewWillEnter() {
    // todo fix load
    this.categoriesLoaded = false;
    const userId = this.globals.selectedUser ? this.globals.selectedUser.id : this.globals.owner.id;
    this.categoryService.uploadCategoryFlower(this.globals.selectedUser.id, () => {
      this.categoryService.getUserRecentCategories();
      this.categoryService.initializeFlowerCategories();
      this.upMotion = 'slideUp';
      this.categoriesLoaded = true;
    });
  }

  ionViewDidLeave() {
    this.toolbarService.setSearchbar(null);
  }

  updateNavigation(): void {
    this.searchResult = [];
    if (this.globals.unreadNotificationCount > 0) {
      this.toolbarService.setMenuItems(this.menuButtonUnreadMessages);
      this.toolbarService.setSearchbar(this.headerSearchbar);
    } else {
      this.toolbarService.setMenuItems(this.menuButtonWithoutNotification);
      this.toolbarService.setSearchbar(this.headerSearchbar);
    }
  }

  async presentLanguageMenu(env) {
    this.popover = await this.popoverController.create({
      component: LangPickerDropDownComponent,
      event: env,
      translucent: false,
      showBackdrop: false,
    });

    await this.popover.present();
    this.popover.onDidDismiss().then(
      e => {
        if (e.data) {
          this.globals.currentLanguage = e.data.en;
          this.menuButtonUnreadMessages[this.menuButtonUnreadMessages.length - 1].icon = `assets/icons/design_v4/langs/${e.data.name.toLocaleLowerCase()}.svg`;
          this.menuButtonUnreadMessages[this.menuButtonUnreadMessages.length - 1].title = e.data.name;
          this.menuButtonWithoutNotification[this.menuButtonWithoutNotification.length - 1].icon = `assets/icons/design_v4/langs/${e.data.name.toLocaleLowerCase()}.svg`;
          this.menuButtonWithoutNotification[this.menuButtonWithoutNotification.length - 1].title = e.data.name;
          this.updateNavigation();
        }
      }
    );
  }

  onToggleNoteFilters(): void {

  }

  onCategoryClick(category: Category) {
    this.globals.enabledSearch = false;
    this.globals.searchbarField = "";
    this.searchResult = [];
    this.router.navigateByUrl(`/categories/${category.id}/tree`).then(r => console.log(r));
  }

  getTopCategoriesByRow(rowIndex: number) {
    if (rowIndex === 0) {
      return this.globals.topCategoryList.slice(0, this.firstRowLength);
    }

    if (rowIndex !== 0) {
      return this.globals.topCategoryList.slice
      (this.rowLength * rowIndex - this.corrective, this.rowLength * rowIndex + this.rowLength - this.corrective);
    }
  }

  getTopCategoriesRowCount() {
    let rowsCount = this.globals.topCategoryList.length;
    rowsCount -= this.firstRowLength;
    rowsCount /= this.rowLength;

    return new Array(Math.ceil(rowsCount) + 1);
  }

  getTranslatedCategoryTitle(category: Category): string {
    return this.categoryService.getTranslationForTitle(category);
  }

  isObjectEmpty(obj) {
    return !Object.keys(obj).length;
  }

  onSearch(env) {
    if (this.globals.searchbarField != "") {
      this.categoryService.findCategories(this.globals.searchbarField).subscribe((data: Category[]) => {
        this.searchResult = data.sort((a, b) => {
          if (a.parentTitles.length > b.parentTitles.length) {
            return -1;
          } else {
            return 1;
          }
        });
      });
    }
  }

  getCategoryTitles(category) {
    const result = [];

    if (category.parentTitles) {
      for (const parent of category.parentTitles) {
        result.push({
          id: parent.id,
          title: parent.title.toString(),
        });
      }
    }
    result.push({
      id: category.id,
      title: this.categoryService.getTranslationForTitle(category).toString(),
    });
    return result;
  }
}
