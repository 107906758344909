<ion-content>
  <div class="container">
    <ion-grid class="admin-main-panel">
      <ng-container *ngFor="let p of panels">

        <ion-button class="buttons-row-item" [routerLink]="p.url">
          <ion-col>
            <ion-icon class="buttons-row-item-icon" [src]="p.icon"></ion-icon>
            <div class="buttons-row-item-title">{{ p.title }}</div>
          </ion-col>
        </ion-button>

      </ng-container>
    </ion-grid>
  </div>
</ion-content>


