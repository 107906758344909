import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../globals';
import {CategoryComment} from '../../modules/category/category-comment';
import {UserLanguage} from '../../modules/site-user/user-language';
import {CategoryTranslation} from '../../core/categoryTranslation';
import {ProfileService} from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class EditorsService {

  constructor(public http: HttpClient, public globals: Globals,
              public profileService: ProfileService) {
  }

  getRecentPrivateCategories(page: number) {
    let params = new HttpParams();
    params = params.append('currentLang', this.globals.currentLanguage);
    params = params.append('page', page.toString());
    return this.http.get(this.globals.apiUrl + '/categories/private', {
      params: params,
      responseType: 'json'
    });
  }

  getRecentPrivateCategoriesDiscussions(page: number) {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    return this.http.get(this.globals.apiUrl + '/categories-discussions/private', {
      params: params,
      responseType: 'json'
    });
  }

  vote(discussionId: number, vote: number) {
    let params = new HttpParams();
    params = params.append('vote', vote.toString());
    this.http.post(this.globals.apiUrl + '/categories-discussions/' + discussionId + '/category-votes', null, {
      params: params,
      observe: 'response'
    }).subscribe();
  }

  addComment(categoryComment: CategoryComment, callback) {
    this.http.post(this.globals.apiUrl + '/categories-discussions/category-comments', categoryComment).subscribe(() => {
      return callback && callback();
    });
  }

  refreshCommentList(discussId: number) {
    return this.http.get(this.globals.apiUrl + '/categories-discussions/' + discussId + '/category-comments');
  }

  editCategoryTranslation(translation: string, currentLang: string, categoryId: number) {
    let params = new HttpParams();
    params = params.append('translation', translation);

    return this.http.patch(this.globals.apiUrl + '/categories/' + categoryId + '/translation/' + currentLang, null, {
      params: params
    });
  }

  async initializeCategoryLangs() {
    let promise: Promise<any>;
    if (this.globals.selectedUserInfo === undefined) {
      promise = this.profileService.getUserLanguages(this.globals.owner.id).toPromise().then((data: Array<UserLanguage>) => {
        this.globals.userLanguages = data;
      });
    } else {
      promise = new Promise<any>((resolve, reject) => {
        this.globals.userLanguages = this.globals.selectedUserInfo.userLanguageDtos;
        resolve();
      });
    }
    return promise;
  }

  fillTheMap(lang, languagesMap, translation: CategoryTranslation) {
    switch (lang.language.languageTitle) {
      case 'Arabic':
        if (translation.ar === 'no translation') {
          languagesMap.set('ar', translation.ar);
        }
        break;
      case 'Bengali':
        if (translation.bn === 'no translation') {
          languagesMap.set('bn', translation.bn);
        }
        break;
      case 'German':
        if (translation.de === 'no translation') {
          languagesMap.set('de', translation.de);
        }
        break;
      case 'Russian':
        if (translation.ru === 'no translation') {
          languagesMap.set('ru', translation.ru);
        }
        break;
      case 'English':
        if (translation.en === 'no translation') {
          languagesMap.set('en', translation.en);
        }
        break;
      case 'Spanish':
        if (translation.es === 'no translation') {
          languagesMap.set('es', translation.es);
        }
        break;
      case 'French':
        if (translation.fr === 'no translation') {
          languagesMap.set('fr', translation.fr);
        }
        break;
      case 'Hindi':
        if (translation.hi === 'no translation') {
          languagesMap.set('hi', translation.hi);
        }
        break;
      case 'Indonesian':
        if (translation.id === 'no translation') {
          languagesMap.set('id', translation.id);
        }
        break;
      case 'Italian':
        if (translation.it === 'no translation') {
          languagesMap.set('it', translation.it);
        }
        break;
      case 'Japanese':
        if (translation.ja === 'no translation') {
          languagesMap.set('ja', translation.ja);
        }
        break;
      case 'Portuguese':
        if (translation.pt === 'no translation') {
          languagesMap.set('pt', translation.pt);
        }
        break;
      case 'Chinese':
        if (translation.zh === 'no translation') {
          languagesMap.set('zh', translation.zh);
        }
        break;
    }
    return languagesMap;
  }
}

