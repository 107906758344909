import {Pipe, PipeTransform} from '@angular/core';
import {Category} from '@app/modules/category/category';
import {CategoryService} from '../shared/services/category.service';

@Pipe({
  name: 'TranslateCategory'
})
export class TranslateCategoryPipe implements PipeTransform {

  constructor(
    private categoryService: CategoryService
  ) {
  }

  transform(category: Category, args?: any): string | null {
    return this.categoryService.getTranslationForTitle(category);
  }

}
