import { Injectable } from '@angular/core';
import { SiteUser } from '../modules/site-user/site-user';
import { Note } from '../modules/note/note';
import { Chat } from '../modules/chat/chat';
import { NoteFilterStorage } from "@app/modules/note-filters/note-filter-storage";
import * as i0 from "@angular/core";
export class Globals {
    constructor() {
        // apiUrl = 'http://185.108.211.109:8080';
        // apiUrl = 'http://188.134.72.112:8080';
        // apiUrl = 'http://188.134.72.4:8080';
        this.apiUrl = 'https://www.witaspace.com:8443/witasocial';
        // apiUrl = 'http://localhost:8080';
        this.clientUrl = 'https://www.wita.space';
        this.ROOT_CATEGORY_TREE_SCOPE = 11;
        this.currentLanguage = 'en';
        this.isSearching = false;
        this.isAuthenticated = false;
        this.selectedUser = new SiteUser();
        this.profileSocialLink = [
            'Instagram',
            'Facebook',
            'Twitter',
            'VK',
            'YouTube',
            'TikTok',
            'Tumblr'
        ];
        this.token = '';
        this.currentNote = new Note();
        this.isNewNote = false;
        this.friendList = [];
        this.people = [];
        this.requestList = [];
        this.receiver = new SiteUser();
        this.sender = new SiteUser();
        this.currentChat = new Chat();
        this.recentCategories = new Array();
        this.maxSizeOfRecentCategories = 4;
        this.isSearchingPeople = false;
        this.isFriendRequestsOpen = false;
        this.isEditor = false;
        this.isCategoryEditingMode = false;
        this.isCategoryAddMode = false;
        this.isRemovingMode = false;
        this.needPageRefresh = true;
        this.isAdmin = false;
        this.allCategories = [];
        this.newAttachments = [];
        this.action = null;
        this.outgoingRequests = [];
        this.votingQuestions = [];
        this.votingAnsweredQuestionsId = [];
        this.enabledSearch = false;
        this.searchbarField = "";
        this.activeFilters = new NoteFilterStorage();
        this.currentHeader = [];
        this.isDeletable = true;
        this.isFirstTimeNote = false;
        this.isNewUser = false;
    }
}
Globals.ɵfac = function Globals_Factory(t) { return new (t || Globals)(); };
Globals.ɵprov = i0.ɵɵdefineInjectable({ token: Globals, factory: Globals.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(Globals, [{
        type: Injectable
    }], null, null); })();
