import {NoteFilter} from "@app/modules/note-filters/note-filter";

export class WeightFilter extends NoteFilter {
  weight: number;

  constructor(weight: number) {
    super();
    this.weight = weight;
  }

  isEmpty() {
    return this.weight === null;
  }

  toString() {
    return "WeightFilter. Weight = " + this.weight;
  }
}
