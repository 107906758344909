import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../globals';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(public http: HttpClient, public globals: Globals) {
  }

  sendSupportRequest(name: string, email: string, theme: string, issue: string, callback) {
    let params = new HttpParams();
    params = params.append('name', name);
    params = params.append('email', email);
    params = params.append('theme', theme);
    params = params.append('issue', issue);
    this.http.post(this.globals.apiUrl + '/support', null, {
      params: params
    }).subscribe(response => {
      return callback && callback(response);
    });
  }
}
