var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { MenuController } from "@ionic/angular";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class MenuService {
    constructor(menu) {
        this.menu = menu;
        this.MAIN_MENU = 'main-menu';
    }
    disableMainMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.menu.enable(false, this.MAIN_MENU);
        });
    }
    activeMainMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.menu.enable(true, this.MAIN_MENU);
        });
    }
    toggleMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.menu.toggle(this.MAIN_MENU);
        });
    }
}
MenuService.ɵfac = function MenuService_Factory(t) { return new (t || MenuService)(i0.ɵɵinject(i1.MenuController)); };
MenuService.ɵprov = i0.ɵɵdefineInjectable({ token: MenuService, factory: MenuService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MenuService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.MenuController }]; }, null); })();
