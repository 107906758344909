import {Component, Input, OnInit} from '@angular/core';
import {Note} from '@app/modules/note/note';
import {NoteCommentService} from '@app/shared/services/note-comment.service';
import {CategoryService} from '@app/shared/services/category.service';
import {Router} from "@angular/router";
import {MenuController} from "@ionic/angular";

@Component({
  selector: 'app-note-list-item',
  templateUrl: './note-list-item.component.html',
  styleUrls: ['./note-list-item.component.scss']
})
export class NoteListItemComponent implements OnInit {
  @Input() noteEntity: Note;

  public URL_LENGTH = 25;
  public NUMBER_OF_LINES_IN_PREVIEW = 7;
  public NUMBER_OF_CHARS_IN_PREVIEW = 200;

  constructor(
    public commentService: NoteCommentService,
    public categoryService: CategoryService,
    public menuCtrl: MenuController,
    public router: Router,
  ) {
  }

  ngOnInit() {
  }

  getTagIcon(value) {
    return `assets/icons/design_v4/tag_${value ? value : 5}.svg`;
  }

  chopNote(content: string) {

    let result = '';
    let charCounter = 0;
    let mediaCounter = 0;
    let pCounter = 0;
    const separator = this.getSeparator(content);
    if (separator !== '') {
      const contentRowsArray = content.split(separator);

      for (const oneRow of contentRowsArray) {
        if (pCounter <= this.NUMBER_OF_LINES_IN_PREVIEW && charCounter < this.NUMBER_OF_CHARS_IN_PREVIEW) {
          if (this.hasImg(oneRow) || this.hasVideo(oneRow)) {
            if (mediaCounter < 1) {
              result = result + oneRow;
              mediaCounter++;
            }
          } else {
            if (this.hasUrl(oneRow)) {
              result = result + this.handleUrl(oneRow);
              charCounter = +50;
            } else {
              result = result + oneRow;
              charCounter = charCounter + oneRow.length;
            }
            if (oneRow.length > this.NUMBER_OF_CHARS_IN_PREVIEW * 2) {
              charCounter = this.NUMBER_OF_CHARS_IN_PREVIEW + 1;
            }
          }
        } else {
          return result;
        }
        result = result + separator;
        pCounter++;
      }

    }
    return result;
  }

  async openNote(id) {
    // await this.menuCtrl.close('feed')
    this.router.navigateByUrl('/notes/' + id);
  }

  private hasImg(oneRow: string) {
    return oneRow.indexOf('<img') !== -1;
  }

  private hasUrl(oneRow: string) {
    return oneRow.indexOf('href') !== -1;
  }

  private handleUrl(oneRow: string) {
    let urlName = '';
    let result = '';
    let t = '';
    const subStringArray = oneRow.split('</a>');
    for (const subString of subStringArray) {
      for (let i = subString.length - 1; i > 0; i--) {
        t = subString.charAt(i);
        if (t !== '>') {
          urlName = t + urlName;
        } else {
          break;
        }
      }
      let urlLength = urlName.length;
      const beginning = subString.substr(0, subString.length - urlLength);
      if (urlLength > this.URL_LENGTH) {
        urlLength = this.URL_LENGTH;
        urlName = urlName.substr(0, urlLength);
      }
      result = result + beginning + urlName + '</a>';
      urlName = '';
    }
    return result;
  }

  private hasVideo(oneRow: string) {
    return oneRow.indexOf('<video') !== -1;
  }

  private getSeparator(content: string) {
    let result = '';
    if (content.indexOf('</p>') !== -1) {
      result = '</p>';
    }
    if (content.indexOf('</li>') !== -1) {
      result = '</li>';
    }
    return result;
  }
}
