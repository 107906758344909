import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Globals} from "@app/shared/globals";
import {Feedback} from "@app/modules/feedback/feedback";

@Injectable({
  providedIn: 'root'
})

export class AlertsService {

  constructor(public http: HttpClient,
              public globals: Globals) {
  }

  sendAlert(alert: Feedback) {
    return this.http.post(this.globals.apiUrl + '/feedback', alert);
  }

  getAlertById(id: number) {
    let params = new HttpParams().append('id', id.toString());
    this.http.get(this.globals.apiUrl + '/feedback', {params}).subscribe((alert: Feedback) => {
      console.log(alert)
    });
  }

  getAlertsByUserId(userId: number) {
    let params = new HttpParams().append('userId', userId.toString());
    return this.http.get(this.globals.apiUrl + '/feedback', {params});
  }

  editQuestion(alert: AlertsService) {
    return this.http.put(this.globals.apiUrl + '/feedback', alert);
  }

  deleteQuestionById(id: number) {
    let params = new HttpParams().append('id', id.toString());
    return this.http.delete(this.globals.apiUrl + '/feedback', {params});
  }
}
