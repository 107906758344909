import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Globals} from '@app/shared/globals';
import {Message} from './chat-messages/message';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {MobileChatModalComponent} from './mobile-chat-modal/mobile-chat-modal.component';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {SharedService} from '@app/shared/services/shared.service';
import {NewChatModalComponent} from '@app/modules/chat/new-chat-modal/new-chat-modal.component';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewInit, UpdateNavigation, OnDestroy {

  showFilter = false;
  innerWidth = 0;
  activeDialogue = undefined;
  chatList: any = new BehaviorSubject(null);
  routeSubscription: Subscription;
  private modalIsOpen: boolean;
  @ViewChild('friendList', {static: false}) friendListComponent;

  headerActions: MenuItem[] = [
    {
      title: 'Category tree',
      icon: 'assets/icons/design_v4/category_path.svg',
      path: '/categories'
    },
    {
      title: 'Filter',
      icon: 'assets/icons/design_v4/filter.svg',
      action: () => this.showFilter = !this.showFilter,
    },
    {
      title: 'New Message',
      icon: 'assets/icons/design_v4/add_btn_icon.svg',
      action: () => this.presentNewChat(),
    }
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.innerWidth = event.target.innerWidth;

    if (this.innerWidth <= 767 && this.activeDialogue !== undefined) {
      this.activeDialogue = undefined;
    }

  }

  constructor(public globals: Globals,
              private toolbarService: ToolbarService,
              public modalController: ModalController,
              private sharedService: SharedService) {
  }

  updateNavigation() {
    this.toolbarService.setMenuItems(this.headerActions);
    this.toolbarService.setMainHeaderBtn(null);
  }

  ngAfterViewInit() {
    this.innerWidth = window.innerWidth;
    this.routeSubscription = this.sharedService.changeEmitted.subscribe(res => {
      if (res && res.action === 'open-chat') {
        this.openChat({chatList: res.data, activeDialogue: null});
      }
      if (res && res.action === 'open-chatlist') {
        console.log('open-chatlist');
        this.activeDialogue = undefined;
        this.openChat({chatList: res.data, activeDialogue: undefined});
      }
    });
  }

  ionViewDidLeave() {
    this.friendListComponent.chatClosed();
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  async presentModal(data: any) {
    const modal: any = await this.modalController.create({
      component: MobileChatModalComponent,
      componentProps: {
        ...data,
        getLastMessage: this.getLastMessage
      },
    });

    modal.onDidDismiss().then(
      () => {
        this.activeDialogue = null;
        this.modalIsOpen = false;
      }
    );

    return await modal.present();
  }

  async presentNewChat() {
    const modal: any = await this.modalController.create({
      component: NewChatModalComponent
    });
    return await modal.present();
  }

  openChat(event) {
    if (event.activeDialogue === null || event.activeDialogue !== this.activeDialogue) {
      this.activeDialogue = event.activeDialogue;

      if (this.innerWidth <= 767) {
        if (!this.modalIsOpen) {
          this.presentModal({
            activeDialogue: event.activeDialogue,
            chatList: event.chatList
          });
          this.modalIsOpen = true;
        }
      } else {
        this.chatList.next(event.chatList);
      }
    }
  }

  getLastMessage(message: Message) {
    if (message !== undefined && this.activeDialogue != null) {
      if (this.chatList.getValue && this.chatList.getValue().id === message.chat) {
        this.chatList.getValue().lastMessageDto = message;
      } else if (this.chatList.id === message.chat) {
        this.chatList.lastMessageDto = message;
      }
    }
  }
}
