import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NoteCommentService } from '../../shared/services/note-comment.service';
import { Globals } from '../../shared/globals';
import { TranslateService } from '@ngx-translate/core';
import { NoteComment } from '../note-comment/note-comment';
import { Router } from '@angular/router';
import { Note } from '@app/modules/note/note';
import { Platform } from '@ionic/angular';
import { NoteComponent } from '@app/modules/note/note.component';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../shared/services/note-comment.service";
import * as i3 from "@ionic/angular";
import * as i4 from "../../shared/globals";
import * as i5 from "@angular/router";
import * as i6 from "@app/modules/note/note.component";
import * as i7 from "@angular/common";
import * as i8 from "@angular/forms";
import * as i9 from "ngx-emoji-picker";
const _c0 = ["comment"];
function NewNoteCommentComponent_div_0_emoji_content_13_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(0, "emoji-content", 14);
    i0.ɵɵlistener("emoji-selection", function NewNoteCommentComponent_div_0_emoji_content_13_Template_emoji_content_emoji_selection_0_listener($event) { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(2); return ctx_r3.emojiHandler($event); });
    i0.ɵɵelementEnd();
} }
function NewNoteCommentComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "div", 3);
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵelement(4, "div", 5);
    i0.ɵɵelementStart(5, "div", 6);
    i0.ɵɵelementStart(6, "ion-input", 7, 8);
    i0.ɵɵlistener("focusin", function NewNoteCommentComponent_div_0_Template_ion_input_focusin_6_listener() { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.focusInput(true); })("focusout", function NewNoteCommentComponent_div_0_Template_ion_input_focusout_6_listener() { i0.ɵɵrestoreView(_r6); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.focusInput(false); })("keyup", function NewNoteCommentComponent_div_0_Template_ion_input_keyup_6_listener($event) { i0.ɵɵrestoreView(_r6); const ctx_r8 = i0.ɵɵnextContext(); return ctx_r8.inputMessageHandler($event); })("click", function NewNoteCommentComponent_div_0_Template_ion_input_click_6_listener($event) { i0.ɵɵrestoreView(_r6); const ctx_r9 = i0.ɵɵnextContext(); return ctx_r9.inputMessageHandler($event); })("ngModelChange", function NewNoteCommentComponent_div_0_Template_ion_input_ngModelChange_6_listener($event) { i0.ɵɵrestoreView(_r6); const ctx_r10 = i0.ɵɵnextContext(); return ctx_r10.commentText = $event; });
    i0.ɵɵpipe(8, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "div", 9);
    i0.ɵɵelementStart(10, "button", 10);
    i0.ɵɵlistener("click", function NewNoteCommentComponent_div_0_Template_button_click_10_listener() { i0.ɵɵrestoreView(_r6); const ctx_r11 = i0.ɵɵnextContext(); return ctx_r11.addNewComment(); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(11, "svg", 11);
    i0.ɵɵelement(12, "path", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(13, NewNoteCommentComponent_div_0_emoji_content_13_Template, 1, 0, "emoji-content", 13);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(6);
    i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(8, 4, "Type your comment\u2026"));
    i0.ɵɵproperty("ngModel", ctx_r0.commentText);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("disabled", ctx_r0.commentText === "");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.showEmojiPicker);
} }
export class NewNoteCommentComponent {
    constructor(translate, noteCommentService, platform, globals, router, noteComponent) {
        this.translate = translate;
        this.noteCommentService = noteCommentService;
        this.platform = platform;
        this.globals = globals;
        this.router = router;
        this.noteComponent = noteComponent;
        this.haveAddedNewComment = new EventEmitter();
        this.commentText = '';
        this.disabledNewButton = false;
        this.showEmojiPicker = false;
        this.selectionStart = 0;
    }
    ngOnInit() {
    }
    focusOnInput() {
        this.commentInput.nativeElement.focus();
    }
    addNewComment() {
        if (!this.globals.isAuthenticated) {
            this.router.navigateByUrl('/auth').then(r => console.log(r));
            return;
        }
        if (!this.disabledNewButton) {
            const newComment = this.prepareNewComment();
            this.disabledNewButton = true;
            this.noteCommentService.addComment(newComment, response => {
                this.haveAddedNewComment.emit(true);
                this.disabledNewButton = false;
                this.commentText = ' ';
                this.focusInput(false);
            });
            this.showEmojiPicker = false;
        }
    }
    inputMessageHandler(e) {
        this.selectionStart = e.target.selectionStart;
    }
    prepareNewComment() {
        const value = new NoteComment();
        value.content = this.commentText;
        value.note = this.noteEntity.id;
        value.owner = this.globals.owner;
        if (this.passParentComment !== undefined) {
            value.parentComment = this.passParentComment.id;
        }
        return value;
    }
    toggleEmojiPicker() {
        this.showEmojiPicker = !this.showEmojiPicker;
        this.noteComponent.emojiWasClicked = !this.noteComponent.emojiWasClicked;
        this.noteComponent.showPlusButton();
    }
    emojiHandler(event) {
        this.commentText += event[0];
    }
    focusInput(isOn) {
        this.noteComponent.inputOnFocus = isOn;
        this.noteComponent.showPlusButton();
    }
}
NewNoteCommentComponent.ɵfac = function NewNoteCommentComponent_Factory(t) { return new (t || NewNoteCommentComponent)(i0.ɵɵdirectiveInject(i1.TranslateService), i0.ɵɵdirectiveInject(i2.NoteCommentService), i0.ɵɵdirectiveInject(i3.Platform), i0.ɵɵdirectiveInject(i4.Globals), i0.ɵɵdirectiveInject(i5.Router), i0.ɵɵdirectiveInject(i6.NoteComponent)); };
NewNoteCommentComponent.ɵcmp = i0.ɵɵdefineComponent({ type: NewNoteCommentComponent, selectors: [["app-new-note-comment"]], viewQuery: function NewNoteCommentComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.commentInput = _t.first);
    } }, inputs: { passParentComment: "passParentComment", noteEntity: "noteEntity" }, outputs: { haveAddedNewComment: "haveAddedNewComment" }, decls: 1, vars: 1, consts: [["class", "add-comment", 4, "ngIf"], [1, "add-comment"], [1, "container"], [1, "add-comment__row"], ["id", "addGroup", 1, "add-comment__row-group"], [1, "add-comment__row-empty"], [1, "add-comment__group-control"], ["type", "text", 3, "ngModel", "placeholder", "focusin", "focusout", "keyup", "click", "ngModelChange"], ["comment", ""], [1, "add-comment__group-button"], [1, "add-comment__button", 3, "disabled", "click"], ["width", "23", "height", "22", "viewBox", "0 0 23 22", "fill", "none", "xmlns", "http://www.w3.org/2000/svg"], ["d", "M11.9181 11.7303L2.52827 12.6273L0.0568994 20.427C-0.0906259 20.8881 0.0544377 21.3916 0.42571 21.7072C0.796983 22.0227 1.32304 22.0895 1.76315 21.877L22.3424 12.0423C22.7447 11.8486 23 11.4454 23 11.0035C23 10.5617 22.7447 10.1585 22.3424 9.96477L1.77752 0.122961C1.3374 -0.0895013 0.811352 -0.0226845 0.440079 0.292836C0.0688062 0.608357 -0.0762574 1.11188 0.0712679 1.573L2.54264 9.3727L11.9145 10.2697C12.2931 10.3066 12.5816 10.6209 12.5816 10.9965C12.5816 11.372 12.2931 11.6863 11.9145 11.7232L11.9181 11.7303Z", "fill", "#48CDC4"], ["class", "toolbar-emoji-picker", 3, "emoji-selection", 4, "ngIf"], [1, "toolbar-emoji-picker", 3, "emoji-selection"]], template: function NewNoteCommentComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, NewNoteCommentComponent_div_0_Template, 14, 6, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.globals.isAuthenticated);
    } }, directives: [i7.NgIf, i3.IonInput, i3.TextValueAccessor, i8.NgControlStatus, i8.NgModel, i9.EmojiContentComponent], pipes: [i1.TranslatePipe], styles: [".add-comment[_ngcontent-%COMP%]{width:100%;z-index:10}.add-comment[_ngcontent-%COMP%]   .container[_ngcontent-%COMP%]{padding:5px 14px 5px 10px;position:sticky;position:-webkit-sticky;margin-bottom:10px;bottom:0}.add-comment__row[_ngcontent-%COMP%]{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;min-height:50px}.add-comment__row-avatar[_ngcontent-%COMP%]{width:25px;max-height:25px;box-sizing:content-box;overflow:hidden;border-radius:50%}.add-comment__row-empty[_ngcontent-%COMP%]{width:55px;max-height:25px;box-sizing:content-box;overflow:hidden;border-radius:50%}.add-comment__emoji-button[_ngcontent-%COMP%]{position:absolute;padding:0;top:50%;transform:translateY(-50%);right:10px;width:21px;max-height:22px;border:none;background:0 0}.add-comment__row-group[_ngcontent-%COMP%]{display:flex;flex-direction:row;flex-wrap:nowrap;flex-grow:1;align-items:center}.add-comment__group-control[_ngcontent-%COMP%]{position:relative;flex-grow:1;width:calc(100% - 45px);margin:0 8px}.add-comment__group-control[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{width:100%;padding:11px 40px 10px 10px;border:1px solid #48cdc4;border-radius:5px;background:#f7f7f7;color:rgba(0,0,0,.3);font-family:\"San Francisco Display\";font-size:14px;font-weight:400}.add-comment__button[_ngcontent-%COMP%]{padding:0;border:none;background:0 0}.toolbar-emoji-picker[_ngcontent-%COMP%]{z-index:10;width:100%;box-shadow:none}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NewNoteCommentComponent, [{
        type: Component,
        args: [{
                selector: 'app-new-note-comment',
                templateUrl: './new-note-comment.component.html',
                styleUrls: ['./new-note-comment.component.scss']
            }]
    }], function () { return [{ type: i1.TranslateService }, { type: i2.NoteCommentService }, { type: i3.Platform }, { type: i4.Globals }, { type: i5.Router }, { type: i6.NoteComponent }]; }, { passParentComment: [{
            type: Input
        }], noteEntity: [{
            type: Input
        }], haveAddedNewComment: [{
            type: Output
        }], commentInput: [{
            type: ViewChild,
            args: ['comment', { static: false }]
        }] }); })();
