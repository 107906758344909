import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Globals} from '@app/shared/globals';
import {NoteService} from '@app/shared/services/note.service';
import {Note} from '../note/note';
import {TranslateService} from '@ngx-translate/core';
import {PrivacyAccessType} from '@app/shared/privacy.access-type';
import 'quill-emoji';
import {CategoryService} from '@app/shared/services/category.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {DropDownListComponent} from '@app/shared/components/drop-down-list/drop-down-list.component';
import {PopoverController} from '@ionic/angular';
import {SharedService} from '@app/shared/services/shared.service';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {Category} from '@app/modules/category/category';
import {GeoService} from "@app/shared/services/geo.service";
import {Place} from "@app/modules/place/place";
import {NewFeed} from "@app/modules/home/NewFeed";
import {FeedType} from "@app/modules/home/FeedType";

@Component({
  selector: 'app-new-note',
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.scss']
})

export class NewNoteComponent implements OnInit, OnDestroy, UpdateNavigation {
  public isCategoryEmpty = false;

  @ViewChild('quillComponent', {static: false}) quillComponent;

  public selectedCategory: Category;
  public firstSlide;
  public friendAccessType = PrivacyAccessType.FRIENDS;
  public accessIndex = 3;

  public compareWith;
  public group = "Group";
  public translationText: any;
  private disabledNewButton = false;

  baseActions: MenuItem[] = [
    {
      title: 'Go to next step',
      icon: 'assets/icons/design_v4/go-right.svg',
      action: this.nextSlide.bind(this),
    }
  ];

  done: MenuItem[] = [{
    title: 'Add New Note',
    icon: 'assets/icons/design_v4/check.svg',
    action: this.addNewNote.bind(this),
  }];
  stopEditing: MenuItem = {
    title: 'previous slide',
    icon: 'assets/icons/design_v4/go-left.svg',
    action: this.previousSlide.bind(this),
  };
  private selectedPlace: Place;

  constructor(
    public translate: TranslateService,
    public globals: Globals,
    public noteService: NoteService,
    public categoryService: CategoryService,
    public route: ActivatedRoute,
    private router: Router,
    private toolbarService: ToolbarService,
    private menuService: MenuService,
    private popoverController: PopoverController,
    private sharedService: SharedService,
    private uiAlertService: UiAlertService,
    private geoService: GeoService
  ) {
  }

  public privacy: Array<PrivacyAccessType> = [
    PrivacyAccessType.PRIVATE,
    PrivacyAccessType.PUBLIC,
    PrivacyAccessType.FRIENDS
  ];

  public selectedAccessType: PrivacyAccessType;
  public selectedWeightLevel = 1;
  readonly BUTTON_GROUP_PLACEHOLDER = 'Group';

  ngOnInit() {

    this.translate.get([
      'Empty Note!',
      'Note should not be empty'
    ]).subscribe(tr => {
      this.translationText = tr;
    });

    this.route.data
      .subscribe((data: { category: Category }) => {
        this.selectedCategory = data.category;
      });
    this.globals.newNoteContent = '';
    this.firstSlide = true;
    this.selectedAccessType = this.privacy[0];
    this.accessIndex = 3;
    this.globals.newAttachments = [];
    this.geoService.selectedPlace.subscribe((place: Place) => {
      this.selectedPlace = place;
    });
  }

  ionViewWillEnter() {
    this.globals.action = null;
    this.firstSlide = true;
  }

  cancel() {
    this.toolbarService.setMainHeaderBtn({
      title: 'Menu',
      icon: 'assets/icons/design_v4/menu.svg',
      action: () => {
        this.menuService.toggleMenu();
      }
    });
    this.router.navigateByUrl('/').then(r => console.log(r));
  }

  addNewNote() {
    if (!this.disabledNewButton) {
      this.isCategoryEmpty = false;
      const newNote = this.prepareNewNote();
      this.disabledNewButton = true;
      this.noteService.addNote(newNote, response => {
        this.globals.newNoteContent = '';
        this.globals.newAttachments = [];
        this.globals.isNewNote = false;
        this.globals.selectedUser = this.globals.owner;
        this.globals.needPageRefresh = true;
        this.toolbarService.setMainHeaderBtn({
          title: 'Menu',
          icon: 'assets/icons/design_v4/menu.svg',
          action: () => {
            this.menuService.toggleMenu();
          }
        });
        this.disabledNewButton = false;
        this.globals.feed = new NewFeed(FeedType.HOME);
        this.router.navigate(['/']);
      });
    }
  }

  prepareNewNote() {
    const category = new Category();
    category.id = this.selectedCategory.id;

    const value = new Note();
    value.content = this.globals.newNoteContent;
    value.parentCategory = category;
    value.owner = this.globals.owner;
    value.accessType = this.accessIndex;
    value.weight = this.selectedWeightLevel;
    value.views = 0;
    value.attachments = this.globals.newAttachments;
    if ((this.selectedPlace !== undefined) && (this.selectedPlace.id !== undefined)) {
      value.placeId = this.selectedPlace.id;
    }
    return value;
  }

  changeAccessType(accessType: PrivacyAccessType) {
    this.selectedAccessType = accessType;
    if (accessType === PrivacyAccessType.PRIVATE) {
      this.accessIndex = 3;
    }
    if (accessType === PrivacyAccessType.FRIENDS) {
      this.accessIndex = 2;
    }
    if (accessType === PrivacyAccessType.PUBLIC) {
      this.accessIndex = 1;
    }
    this.group = "Group";
  }

  async presentAccessTypeMenu(env) {

    const dropDownMenuItems = {
      data: [
        {
          name: PrivacyAccessType.FRIENDS,
          callback: () => {
            this.changeAccessType(PrivacyAccessType.FRIENDS);
            this.group = PrivacyAccessType.FRIENDS;
          }
        }
      ]
    };

    const popover = await this.popoverController.create({
      component: DropDownListComponent,
      event: env,
      componentProps: dropDownMenuItems,
    });


    await popover.present();
  }

  private async nextSlide() {
    if (this.globals.newNoteContent && this.globals.newNoteContent !== '') {
      this.quillComponent.parseUrl();
      this.firstSlide = false;
      this.updateNavigation();
    } else {
      await this.uiAlertService.createAlert(this.translationText['Empty Note!'], this.translationText['Note should not be empty']);
    }
  }

  private previousSlide() {
    this.firstSlide = true;
    this.updateNavigation();
  }

  updateNavigation(): void {
    if (!this.firstSlide) {
      this.toolbarService.setMainHeaderBtn(this.stopEditing);
      this.toolbarService.setMenuItems(this.done);
    } else {
      this.toolbarService.setMainHeaderBtn({
        title: 'Cancel',
        icon: 'assets/icons/design_v4/cancel.svg',
        action: this.cancel.bind(this),
      });
      this.toolbarService.setMenuItems(this.baseActions);
    }
  }

  ngOnDestroy(): void {
    this.firstSlide = true;
  }
}
