import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from './core/auth/authentication.service';
import {Globals} from './shared/globals';
import {TranslateService} from '@ngx-translate/core';
import {SiteUser} from './modules/site-user/site-user';
import {GoogleAuthService} from './core/auth/google-auth.service';
import {MenuController, Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {FacebookAuthService} from './core/auth/facebook-auth.service';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {Tokens} from '@app/shared/models/tokens';
import {TokensService} from '@app/shared/services/tokens.service';
import {AppleAuthService} from '@app/core/auth/apple-auth.service';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {CheckUpdateService} from './shared/services/check-update.service';
import {OneSignalService} from '@app/shared/services/one-signal.service';
import {Subscription} from "rxjs";
import {BdcWalkService} from "bdc-walkthrough";
import {SiteUserService} from "@app/shared/services/site-user.service";
import {NoteService} from "@app/shared/services/note.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'wita';
  isLoading = true;
  isVisible: boolean;
  private currentPath;
  idCatFilter = 'welcomeGuideShowWalkthrough';

  walkThroughSubscription: Subscription;

  // {name: 'welcomeGuideTask6', title: 'New note', done: false},

  constructor(private translate: TranslateService,
              public app: AuthenticationService,
              public router: Router,
              public globals: Globals,
              private storage: Storage,
              private platform: Platform,
              private splashScreen: SplashScreen,
              private statusBar: StatusBar,
              public googleAuthService: GoogleAuthService,
              public appleAuthService: AppleAuthService,
              private toolbarService: ToolbarService,
              public faceBookAuthService: FacebookAuthService,
              private tokensService: TokensService,
              public menuService: MenuService,
              public menuCtrl: MenuController,
              public bdcWalkService: BdcWalkService,
              public checkUpdateService: CheckUpdateService,
              public siteUserService: SiteUserService,
              private oneSignalService: OneSignalService) {

    this.platform.ready().then(() => {

      if (this.platform.is('android')) {
        this.checkUpdateService.isNeedUpdate();
      }
      if (this.platform.is('cordova')) {
        this.oneSignalService.initOneSignal();
      }
      this.router.events.subscribe(async (res) => {

        if (res instanceof NavigationEnd) {
          this.currentPath = this.router.url;

          const isOpen = await menuCtrl.isOpen(this.menuService.MAIN_MENU);

          if (isOpen) {
            await this.menuCtrl.close(this.menuService.MAIN_MENU);
          }
        }

      });

      this.statusBar.styleDefault();
      this.splashScreen.hide();

      const user = localStorage.getItem('wita-user') ? JSON.parse(localStorage.getItem('wita-user')) : null;
      const tokens = localStorage.getItem('tokens') ? JSON.parse(localStorage.getItem('tokens')) : null;
      if (user && tokens) {
        this.globals.isAuthenticated = true;
      }

      this.toolbarService.headerVisibility$.subscribe(v => this.isVisible = v);
      if (tokens && this.tokensService.isExpired()) {
        this.app.refreshAuthToken(true).subscribe((token) => {
          const newToken = new Tokens({
            ...JSON.parse(localStorage.getItem('tokens')),
            jwtToken: token.jwtToken,
            createdDate: new Date().toISOString(),
          });
          localStorage.setItem('tokens', JSON.stringify(newToken));
          this.checkLogin();
        });
      } else {
        this.checkLogin();
      }
    });
  }


  public setRoutedComponent(componentRef: UpdateNavigation): void {
    if (componentRef.updateNavigation) {
      componentRef.updateNavigation();
      return;
    }

    this.toolbarService.setTitle('');
    this.toolbarService.setMenuItems([]);
  }

  checkLogin() {
    const lang = localStorage.getItem('wita-lang') || 'en';

    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.globals.currentLanguage = lang;

    if (this.platform.is('cordova')) {
      this.isLoading = false;

      this.storage.get('wita-user').then(res => {

        if (res && res.provider && res.provider === 'GOOGLE') {
          this.googleAuthService.authByGoogle(res, false).then(() => {
            this.onAutoLoginSuccess();
          });
        }

        if (res && res.provider && res.provider === 'apple') {
          this.appleAuthService.authByApple(res, false);
        }

        if (res && res.ssoId) {
          this.globals.owner = res;
          this.app.afterLoginInitiation();
          this.router.navigateByUrl('/');
          this.app.getSiteUser(res).subscribe((data: SiteUser) => {
            this.globals.owner = data;
            this.onAutoLoginSuccess();
          });
        }
      });
    } else {

      const user = JSON.parse(localStorage.getItem('wita-user'));

      if (user && user.provider && user.provider === 'GOOGLE') {
        if (user.id != null) {
          user.ssoId = user.id;
          delete user.id;
        }
        this.globals.isAuthenticated = true;
        this.googleAuthService.authByGoogle(user, false)
          .then(() => {
            this.onAutoLoginSuccess();
          }).catch(error => {
          console.log(error);
          this.router.navigate(['/login']);
          this.isLoading = false;
        });
      } else if (user && user.ssoId) {
        this.globals.owner = user;
        this.app.afterLoginInitiation();
        this.app.getSiteUser(user).toPromise()
          .then((data: SiteUser) => {
            this.globals.owner = data;
            this.onAutoLoginSuccess();
          }).catch(error => {
          console.log(error);
          this.isLoading = false;
        });
      } else if (user && user.facebook) {
        this.globals.isAuthenticated = true;
        this.faceBookAuthService.authByFacebook(user, false)
          .then(() => {
            this.onAutoLoginSuccess();
          }).catch(error => {
          console.log(error);
          this.router.navigate(['/login']);
          this.isLoading = false;
        });

      } else {
        this.isLoading = false;
      }
    }
  }

  onAutoLoginSuccess() {
    this.isLoading = false;
    this.toolbarService.setHeaderVisibility(true);
  }

  onTaskChanges() {
    // refresh the status of each task
    this.siteUserService.walkThroughTasks.forEach(task => task.done = this.bdcWalkService.getTaskCompleted(task.name));
  }

  startWalkthrough() {
    this.walkThroughSubscription = this.bdcWalkService.changes.subscribe(() => this.onTaskChanges());
    this.resetWalkthrough('welcomeGuide');
    this.bdcWalkService.setTaskCompleted(this.idCatFilter, true);
  }

  resetWalkthrough(p: string) {
    this.bdcWalkService.reset(p);
  }
}
