import { isDevMode } from '@angular/core';

const disableLogs = () => {
  if (!isDevMode) {
    if (!window.console) {
      window.console = {};
    }

    const methods = [
      'log',
      'debug',
      'warn',
      'info',
      'group',
      'groupCollapsed',
      'endGroup'
    ];

    for (const method of methods) {
      window.console[method] = () => {
      };
    }
  }
};

disableLogs();
