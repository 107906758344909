var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../globals';
import { ProfileService } from './profile.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
import * as i3 from "./profile.service";
export class EditorsService {
    constructor(http, globals, profileService) {
        this.http = http;
        this.globals = globals;
        this.profileService = profileService;
    }
    getRecentPrivateCategories(page) {
        let params = new HttpParams();
        params = params.append('currentLang', this.globals.currentLanguage);
        params = params.append('page', page.toString());
        return this.http.get(this.globals.apiUrl + '/categories/private', {
            params: params,
            responseType: 'json'
        });
    }
    getRecentPrivateCategoriesDiscussions(page) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        return this.http.get(this.globals.apiUrl + '/categories-discussions/private', {
            params: params,
            responseType: 'json'
        });
    }
    vote(discussionId, vote) {
        let params = new HttpParams();
        params = params.append('vote', vote.toString());
        this.http.post(this.globals.apiUrl + '/categories-discussions/' + discussionId + '/category-votes', null, {
            params: params,
            observe: 'response'
        }).subscribe();
    }
    addComment(categoryComment, callback) {
        this.http.post(this.globals.apiUrl + '/categories-discussions/category-comments', categoryComment).subscribe(() => {
            return callback && callback();
        });
    }
    refreshCommentList(discussId) {
        return this.http.get(this.globals.apiUrl + '/categories-discussions/' + discussId + '/category-comments');
    }
    editCategoryTranslation(translation, currentLang, categoryId) {
        let params = new HttpParams();
        params = params.append('translation', translation);
        return this.http.patch(this.globals.apiUrl + '/categories/' + categoryId + '/translation/' + currentLang, null, {
            params: params
        });
    }
    initializeCategoryLangs() {
        return __awaiter(this, void 0, void 0, function* () {
            let promise;
            if (this.globals.selectedUserInfo === undefined) {
                promise = this.profileService.getUserLanguages(this.globals.owner.id).toPromise().then((data) => {
                    this.globals.userLanguages = data;
                });
            }
            else {
                promise = new Promise((resolve, reject) => {
                    this.globals.userLanguages = this.globals.selectedUserInfo.userLanguageDtos;
                    resolve();
                });
            }
            return promise;
        });
    }
    fillTheMap(lang, languagesMap, translation) {
        switch (lang.language.languageTitle) {
            case 'Arabic':
                if (translation.ar === 'no translation') {
                    languagesMap.set('ar', translation.ar);
                }
                break;
            case 'Bengali':
                if (translation.bn === 'no translation') {
                    languagesMap.set('bn', translation.bn);
                }
                break;
            case 'German':
                if (translation.de === 'no translation') {
                    languagesMap.set('de', translation.de);
                }
                break;
            case 'Russian':
                if (translation.ru === 'no translation') {
                    languagesMap.set('ru', translation.ru);
                }
                break;
            case 'English':
                if (translation.en === 'no translation') {
                    languagesMap.set('en', translation.en);
                }
                break;
            case 'Spanish':
                if (translation.es === 'no translation') {
                    languagesMap.set('es', translation.es);
                }
                break;
            case 'French':
                if (translation.fr === 'no translation') {
                    languagesMap.set('fr', translation.fr);
                }
                break;
            case 'Hindi':
                if (translation.hi === 'no translation') {
                    languagesMap.set('hi', translation.hi);
                }
                break;
            case 'Indonesian':
                if (translation.id === 'no translation') {
                    languagesMap.set('id', translation.id);
                }
                break;
            case 'Italian':
                if (translation.it === 'no translation') {
                    languagesMap.set('it', translation.it);
                }
                break;
            case 'Japanese':
                if (translation.ja === 'no translation') {
                    languagesMap.set('ja', translation.ja);
                }
                break;
            case 'Portuguese':
                if (translation.pt === 'no translation') {
                    languagesMap.set('pt', translation.pt);
                }
                break;
            case 'Chinese':
                if (translation.zh === 'no translation') {
                    languagesMap.set('zh', translation.zh);
                }
                break;
        }
        return languagesMap;
    }
}
EditorsService.ɵfac = function EditorsService_Factory(t) { return new (t || EditorsService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.ProfileService)); };
EditorsService.ɵprov = i0.ɵɵdefineInjectable({ token: EditorsService, factory: EditorsService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EditorsService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.ProfileService }]; }, null); })();
