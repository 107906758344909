import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryService } from '@app/shared/services/category.service';
import { Globals } from "@app/shared/globals";
import { LabelService } from "@app/shared/services/label.service";
import { Label } from "@app/modules/label/label";
import { GeoService } from "@app/shared/services/geo.service";
import { FeedService } from "@app/shared/services/feed.service";
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/services/category.service";
import * as i2 from "@app/shared/globals";
import * as i3 from "@app/shared/services/label.service";
import * as i4 from "@app/shared/services/geo.service";
import * as i5 from "@app/shared/services/feed.service";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
function CategoryListComponent_button_1_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const category_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(category_r2.numberOfNotes);
} }
function CategoryListComponent_button_1_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 3);
    i0.ɵɵlistener("click", function CategoryListComponent_button_1_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r6); const category_r2 = ctx.$implicit; const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.selectCategoryHandler(category_r2.id); });
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, CategoryListComponent_button_1_span_2_Template, 2, 1, "span", 4);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const category_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("category__has_label", category_r2.label !== null)("category__private", category_r2.accessType === 3)("category__mod", category_r2.accessType === 2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.categoryService.getTranslationForTitle(category_r2), " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", category_r2.numberOfNotes > 0 && (ctx_r0.globals.feed.feedType === ctx_r0.feedService.getFeedType().HOME || ctx_r0.globals.feed.feedType === ctx_r0.feedService.getFeedType().USER));
} }
function CategoryListComponent_button_2_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵlistener("click", function CategoryListComponent_button_2_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r8); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.showAddNewCategoryFormHandler(); });
    i0.ɵɵtext(1, "+ Add category");
    i0.ɵɵelementEnd();
} }
export class CategoryListComponent {
    constructor(categoryService, globals, labelService, geoService, feedService) {
        this.categoryService = categoryService;
        this.globals = globals;
        this.labelService = labelService;
        this.geoService = geoService;
        this.feedService = feedService;
        this.selectedCategory = new EventEmitter();
        this.showAddNewCategoryBlock = new EventEmitter();
    }
    ngOnInit() {
        this.geoService.selectedPlace.subscribe((place) => {
            this.selectedPlace = place;
        });
    }
    selectCategoryHandler(categoryId) {
        this.categoryService.getChildren(categoryId, this.globals.selectedUser.id).subscribe((response) => {
            this.globals.allCategories = this.categoryService.insertNewCat(this.globals.allCategories, response);
            this.globals.allCategories = this.categoryService.assignCategoriesParents(this.globals.allCategories);
            this.selectedCategory.emit(categoryId);
        });
    }
    showAddNewCategoryFormHandler() {
        const label = new Label();
        if (!this.geoService.isPlaceEmpty(this.selectedPlace)) {
            label.places = [this.selectedPlace];
            this.labelService.createLabel(label).subscribe((result) => {
                this.labelService.changeSelectedLabel(result);
            });
        }
        this.globals.isCategoryAddMode = true;
        this.levelData.showAddCategoryButton = false;
        this.showAddNewCategoryBlock.emit();
    }
}
CategoryListComponent.ɵfac = function CategoryListComponent_Factory(t) { return new (t || CategoryListComponent)(i0.ɵɵdirectiveInject(i1.CategoryService), i0.ɵɵdirectiveInject(i2.Globals), i0.ɵɵdirectiveInject(i3.LabelService), i0.ɵɵdirectiveInject(i4.GeoService), i0.ɵɵdirectiveInject(i5.FeedService)); };
CategoryListComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CategoryListComponent, selectors: [["app-category-list"]], inputs: { levelData: "levelData" }, outputs: { selectedCategory: "selectedCategory", showAddNewCategoryBlock: "showAddNewCategoryBlock" }, decls: 3, vars: 2, consts: [[1, "level-list"], ["class", "btn btn-outline-success", 3, "category__has_label", "category__private", "category__mod", "click", 4, "ngFor", "ngForOf"], ["class", "btn btn-success", "translate", "", 3, "click", 4, "ngIf"], [1, "btn", "btn-outline-success", 3, "click"], ["class", "notes-number", 4, "ngIf"], [1, "notes-number"], ["translate", "", 1, "btn", "btn-success", 3, "click"]], template: function CategoryListComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, CategoryListComponent_button_1_Template, 3, 8, "button", 1);
        i0.ɵɵtemplate(2, CategoryListComponent_button_2_Template, 2, 0, "button", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.levelData.categories);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.levelData.showAddCategoryButton);
    } }, directives: [i6.NgForOf, i6.NgIf, i7.TranslateDirective], styles: [".level-list[_ngcontent-%COMP%]{flex-wrap:wrap;justify-content:center;padding:0 15px}.btn[_ngcontent-%COMP%]{margin:10px auto;border-radius:8px;border-color:var(--ion-color-primary,#3880ff);color:var(--gray);box-shadow:0 2px 7px rgba(0,0,0,.2);padding-right:10px;max-width:60vw;min-width:200px;white-space:normal;display:block}.category__has_label[_ngcontent-%COMP%]{background-image:url(label.9c336fc5d774d7b325d6.svg);background-repeat:no-repeat;background-position:left top;background-size:11px 13px;background-origin:padding-box}.btn-success[_ngcontent-%COMP%]{background-color:var(--ion-color-primary,#3880ff);color:#fff}.btn-success[_ngcontent-%COMP%]:not(:disabled):not(.disabled):active{color:#fff;background-color:var(--ion-color-primary-shade,#3fb4ad);border-color:var(--ion-color-primary-shade,#3fb4ad)}.btn-success[_ngcontent-%COMP%]:focus{outline:0;box-shadow:0 0 0 .2rem rgba(72,205,197,.5)}.notes-number[_ngcontent-%COMP%]{display:inline-block;width:24px;height:24px;text-align:center;font-size:12px;line-height:24px;margin-left:4px;border-radius:100%;color:var(--ion-color-white);background:var(--ion-color-primary)}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CategoryListComponent, [{
        type: Component,
        args: [{
                selector: 'app-category-list',
                templateUrl: './category-list.component.html',
                styleUrls: ['./category-list.component.scss']
            }]
    }], function () { return [{ type: i1.CategoryService }, { type: i2.Globals }, { type: i3.LabelService }, { type: i4.GeoService }, { type: i5.FeedService }]; }, { levelData: [{
            type: Input
        }], selectedCategory: [{
            type: Output
        }], showAddNewCategoryBlock: [{
            type: Output
        }] }); })();
