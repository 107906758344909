import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {CanActivate, Router} from '@angular/router';
import {Globals} from '../../shared/globals';
import {SiteUser} from '../../modules/site-user/site-user';
import {PasswordResetService} from '../../shared/services/password-reset.service';
import {UserProfile} from '../../modules/site-user/UserProfile';
import {Storage} from '@ionic/storage';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {finalize, tap} from 'rxjs/operators';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {SiteUserService} from '@app/shared/services/site-user.service';
import {TokensService} from '@app/shared/services/tokens.service';
import {Tokens} from '@app/shared/models/tokens';
import {Facebook} from '@ionic-native/facebook/ngx';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {SharedService} from '@app/shared/services/shared.service';
import {OneSignalService} from '@app/shared/services/one-signal.service';
import {AlertController, Platform} from '@ionic/angular';
import {NotificationService} from '@app/shared/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements CanActivate {
  isWrongPassword = false;

  readonly HEADERS = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(public http: HttpClient,
              public router: Router,
              public globals: Globals,
              private toolbarService: ToolbarService,
              public passwordResetService: PasswordResetService,
              private storage: Storage,
              private userService: SiteUserService,
              public menuService: MenuService,
              private tokensService: TokensService,
              public fb: Facebook,
              private googlePlus: GooglePlus,
              private sharedService: SharedService,
              private oneSignalService: OneSignalService,
              private platform: Platform,
              private notificationService: NotificationService,
              public alertCtrl: AlertController) {

  }

  authenticateWithLowCase(credentials, callback, onError) {
    let params = new HttpParams();
    params = params.append('deviceId', this.oneSignalService.getDeviceId());
    this.http.post(this.globals.apiUrl + '/accounts/login', {
      username: credentials.username.toLocaleLowerCase(),
      password: credentials.password
    }, {params: params}).subscribe((response: SiteUser) => {
      localStorage.setItem('wita-user', JSON.stringify(response));
      this.tokensService.setTokens(response.witaAuthToken);
      this.globals.owner = response as SiteUser;
      if (this.globals.owner.ssoId === credentials.username.toLowerCase() || this.globals.owner.email === credentials.username.toLowerCase()) {
        this.isWrongPassword = false;
        this.parseUserFromResponse(response);
        this.afterLoginInitiation();
      }
      return callback && callback();
    }, (error: Response) => {
      if (error.status === 401) {
        this.isWrongPassword = true;
        this.globals.isAuthenticated = false;
      } else {
        this.globals.serverError = true;
      }

      return onError && onError(error);
    });
  }

  checkRegisterSsoId(ssoId: string) {
    const options = {
      headers: this.HEADERS
    };

    return this.http.get(`${this.globals.apiUrl}/accounts/ssoid/check?ssoid=${ssoId}`, options);
  }

  checkRegisterEmail(email: string) {
    const options = {
      headers: this.HEADERS
    };

    return this.http.get(`${this.globals.apiUrl}/accounts/email/check?email=${email}`, options);
  }

  checkRegisterReferrer(referrer: string) {
    const options = {
      headers: this.HEADERS
    };

    return this.http.get(`${this.globals.apiUrl}/accounts/referrer/check?referrer=${referrer}`, options);
  }

  register(siteUser: SiteUser, callback, error) {
    const options = {
      headers: this.HEADERS
    };

    console.log('register is running');
    this.http.post(this.globals.apiUrl + '/accounts/registration', {
      ssoId: siteUser.ssoId.toLowerCase(),
      email: siteUser.email,
      password: siteUser.password
    }, options).subscribe(
      response => {

        console.log('responce' + response);
        this.toolbarService.setHeaderVisibility(true);
        return callback && callback();
      },
      e => {
        return error && error(e);
      });

  }

  sendResetPasswordEmail(email: string) {
    let params = new HttpParams();
    params = params.append('email', email);
    this.passwordResetService.sendResetMessage(params).subscribe();
  }

  getSiteUser(cashedOwner: SiteUser) {
    return this.http.get(this.globals.apiUrl + '/users/' + cashedOwner.id);
  }

  public parseUserFromResponse(response) {
    this.globals.selectedUser.id = response.id;
    this.globals.selectedUser.ssoId = response.ssoId;
    this.globals.selectedUser.firstName = response.firstName;
    this.globals.selectedUser.lastName = response.lastName;
    this.globals.selectedUser.password = response.password;
  }

  canActivate(): boolean {
    let isActive = true;
    if (!this.globals.isAuthenticated) {
      console.log('canActivate ' + this.globals.isAuthenticated);
      this.router.navigate(['auth/']);
      isActive = false;
    }
    return isActive;
  }

  isEditor(user: SiteUser) {
    let profile: UserProfile;
    for (profile of user.userProfiles) {
      if (profile.id === 4) {
        return true;
      }
    }
    return false;
  }

  isAdmin(user: SiteUser) {
    let profile: UserProfile;
    for (profile of user.userProfiles) {
      if (profile.id === 1) {
        return true;
      }
    }
    return false;
  }

  afterLoginInitiation() {
    this.globals.selectedUser = this.globals.owner;
    this.globals.isAuthenticated = true;
    this.globals.isEditor = this.isEditor(this.globals.owner);
    this.globals.isAdmin = this.isAdmin(this.globals.owner);
    this.globals.editorsPagePreloaded = false;
    this.globals.firstLoad = true;
    this.globals.needPageRefresh = true;
    this.storage.set('wita-user', this.globals.owner);
    this.toolbarService.setHeaderVisibility(true);
    this.userService.getCountOfAllNotifications();
    this.notificationService.connect();
    this.notificationService.countUnreadNotifications().subscribe((data) => {
      this.globals.unreadNotificationCount = data;
    });
  }

  logout() {
    this.globals.isAuthenticated = false;
    let params = new HttpParams();
    params = params.append('deviceId', this.oneSignalService.getDeviceId());
    this.http.post(this.globals.apiUrl + '/accounts/logout', {}, {
      params
    }).pipe(
        finalize(async () => {
          this.logoutProcesses();
        })
      ).subscribe();
  }

  async logoutProcesses() {
    await localStorage.clear();
    await this.storage.set('wita-user', null);
    await this.router.navigate(['login']);
    this.toolbarService.setHeaderVisibility(false);
    await this.menuService.disableMainMenu();
    this.globals.noteList = [];
    this.globals.owner = undefined;
    this.globals.selectedUserInfo = undefined;
    this.globals.firstLoad = true;
    this.globals.action = null;
    this.globals.currentCategoryId = undefined;
    this.globals.userNotifications = undefined;
    this.globals.activeFilters.resetAllFilters();
    this.notificationService.disconnect();
    await this.googlePlus.logout();
    await this.fb.logout();
  }

  refreshAuthToken(skip?: boolean) {
    const tokens = new Tokens(JSON.parse(localStorage.getItem('tokens')));
    if (!tokens.jwtToken || !tokens.refreshToken || !tokens.ssoId) {
      this.logout();
      return;
    }
    return this.http.post(`${this.globals.apiUrl}/accounts/token`, {
      ssoId: tokens.ssoId,
      jwtToken: tokens.jwtToken,
      refreshToken: tokens.refreshToken,
      skip
    }).pipe(tap((token: any) => token));
  }

  async checkConfirmationRequired(er: HttpErrorResponse) {
    if (er.status == 404) {
      await this.router.navigate(['sign-up/confirm']);
    } else {
      if (er.status != undefined && er.status !== 401) {
        this.handleError(er);
      }
    }
  }

  async handleError(er: HttpErrorResponse) {
    if (this.globals.apiUrl !== 'https://www.witaspace.com:8443/witasocial') { // For a debug
      const alert = await this.alertCtrl.create({
        header: 'Server error',
        message: 'STATUS: ' + er.status + ' URL: ' + er.url + '\n' + 'MESSAGE' + er.message,
      });
      await alert.present();
    } else { // For a users
      const alert = await this.alertCtrl.create({
        header: 'Server error',
        message: 'Sorry, the service is unavailable at the moment',
      });
      await alert.present();
    }
  }
}
