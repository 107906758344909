import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/shared/globals';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
export class AttachmentService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    uploadImage(uploadData) {
        return this.http.post(this.globals.apiUrl + '/attachments/image/deletable=' + this.globals.isDeletable, uploadData, {
            reportProgress: true,
            responseType: 'text'
        });
    }
    uploadVideo(uploadData) {
        return this.http.post(this.globals.apiUrl + '/attachments/video/deletable=' + this.globals.isDeletable, uploadData, {
            reportProgress: true,
            responseType: 'text'
        });
    }
}
AttachmentService.ɵfac = function AttachmentService_Factory(t) { return new (t || AttachmentService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
AttachmentService.ɵprov = i0.ɵɵdefineInjectable({ token: AttachmentService, factory: AttachmentService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AttachmentService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
