<div
  *ngIf="globals.requestList.length > 0"
  (click)="showRequest = !showRequest"
  class="divider mb-s">
  <div></div>
  <span translate>New friends request <span class="badge badge--small">{{globals.requestList.length}}</span>
</span>
  <div></div>
</div>

<ion-virtual-scroll
  *ngIf="showRequest && globals.requestList.length > 0"
  [items]="globals.requestList"
  approxItemHeight="90px">
  <div *virtualItem="let item; let i = index" >
    <div class="chat-item chat-item--active">
      <img
        class="chat-item__avatar"
        src="{{item.userPhotoName}}">
      <div class="chat-item__text">
        <h4 class="chat-item__text chat-item__text--title"  style="cursor:pointer" (click)="toFriend(item.id)">
          {{item | userName}}
        </h4>
        <p class="chat-item__text chat-item__text--preview" style="-webkit-box-orient: vertical; color: white;" translate>
          New Friend request
        </p>

        <p class="chat-item__actions">
          <i
            (click)="acceptFriendship(item)"
            class="chat-item__actions__btn fa fa-check"
            aria-hidden="true">
          </i>
          <i
            (click)="declineFriendship(item)"
            class="chat-item__actions__btn fa fa-times"
            aria-hidden="true">
          </i>
        </p>
      </div>
    </div>
  </div>
</ion-virtual-scroll>
