import {Component, OnInit} from '@angular/core';
import {Globals} from '@app/shared/globals';
import {Router} from '@angular/router';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {PrivacyAccessType} from '@app/shared/privacy.access-type';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {AccessFilter} from "@app/modules/note-filters/filters/access-filter";
import {FeedService} from "@app/shared/services/feed.service";
import {FeedType} from "@app/modules/home/FeedType";

@Component({
  selector: 'app-note-filters',
  templateUrl: './note-filters.component.html',
  styleUrls: ['./note-filters.component.scss', '../new-note/new-note.component.scss']
})

export class NoteFiltersComponent implements OnInit, UpdateNavigation {

  public selectedAccess;

  public privacy: Array<PrivacyAccessType> = [
    PrivacyAccessType.PRIVATE,
    PrivacyAccessType.PUBLIC,
    PrivacyAccessType.FRIENDS
  ];

  done: MenuItem[] = [{
    title: 'Done',
    icon: 'assets/icons/design_v4/check.svg',
    action: () => {
      this.menuService.activeMainMenu();
      this.toolbarService.setMainHeaderBtn(null);
      this.applyFilter();
    },
  }];
  stopEditing: MenuItem = {
    title: 'Close',
    icon: 'assets/icons/design_v4/cancel.svg',
    action: () => {
      this.menuService.activeMainMenu();
      this.toolbarService.setMainHeaderBtn(null);
      this.resetAll();
    }
  };

  constructor(
    public globals: Globals,
    public router: Router,
    public toolbarService: ToolbarService,
    public menuService: MenuService,
    public feedService: FeedService
  ) {
  }

  ngOnInit(): void {
  }

  applyFilter() {
    this.globals.needPageRefresh = true;
    this.globals.activeFilters.addFilter(this.globals.activeFilters.dateFilter);
    this.globals.activeFilters.addFilter(this.globals.activeFilters.accessFilter);
    this.globals.activeFilters.addFilter(this.globals.activeFilters.weightFilter);
    this.closeFilters();
  }

  resetAll() {
    this.clearAllFilters();
    this.closeFilters();
  }

  closeFilters() {
    if (this.globals.owner.id === this.globals.selectedUser.id || this.globals.feed.feedType === "HOME" ||
      this.globals.feed.feedType === "USER") {
      this.router.navigateByUrl('/', {
        queryParams: {
          feed: true
        }
      });
    } else {
      this.globals.needPageRefresh = true;
      this.router.navigate(['/users/' + this.globals.selectedUser.id + '/notes']);
    }
  }

  updateNavigation(): void {

    this.toolbarService.setMainHeaderBtn(this.stopEditing);
    this.toolbarService.setMenuItems(this.done);
  }

  clearAllFilters() {
    this.globals.activeFilters.resetAllFilters();
    this.selectedAccess = null;
  }

  changeAccessType(accessType: PrivacyAccessType) {
    this.selectedAccess = accessType;
    this.globals.activeFilters.accessFilter = new AccessFilter(accessType);
  }
}
