import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs/index";
import * as i0 from "@angular/core";
export class SharedService {
    constructor() {
        this.emitChangeSource = new BehaviorSubject(null);
        this.changeEmitted = this.emitChangeSource.asObservable();
    }
    emitChange(change) {
        this.emitChangeSource.next(change);
    }
}
SharedService.ɵfac = function SharedService_Factory(t) { return new (t || SharedService)(); };
SharedService.ɵprov = i0.ɵɵdefineInjectable({ token: SharedService, factory: SharedService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SharedService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], null, null); })();
