import {Injectable} from '@angular/core';
import {Globals} from '@app/shared/globals';
import {Tokens} from '@app/shared/models/tokens';
import * as WebStomp from 'webstomp-client';
import * as SockJS from 'sockjs-client';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Notification} from '@app/modules/notifications-page/notification';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private webSocketEndPoint: string = this.globals.apiUrl + '/wss';
  private topic: string = '/user/topic/notify/';
  private stompClient;
  private unreadReceivedNotificationsId: Array<number> = [];

  constructor(private http: HttpClient,
              private globals: Globals,
              private toolbarService: ToolbarService,
              private router: Router) {
  }

  connect() {
    console.log('connecting WS');
    const tokens = new Tokens(JSON.parse(localStorage.getItem('tokens')));
    const ws = new SockJS(this.webSocketEndPoint);
    this.stompClient = WebStomp.over(ws);
    this.stompClient.debug = () => {
    };
    this.stompClient.connect({Authorization: tokens.jwtToken}, frame => {
      console.log('connected WS');
      this.stompClient.subscribe(this.topic, sdkEvent => {
        this.onMessageReceived(sdkEvent);
      });
    }, function(e) {
      console.error(e, 'Reconnecting WS', this.webSocketEndPoint);
      setTimeout(() => {
        if (this.globals.isAuthenticated) {
          this.connect();
        }
      }, 5000);
    }.bind(this));
  }

  disconnect() {
    if (this.stompClient !== null && this.stompClient !== undefined && this.stompClient.connected) {
      this.stompClient.disconnect();
    }
  }

  addToReceivedNotifications(notificationsArray: Notification[]) {
    for (const notification of notificationsArray) {
      if (notification.unread) {
        this.unreadReceivedNotificationsId.push(notification.id);
      }
    }
    this.readNotifications();
  }

  readNotifications() {
    if (this.stompClient !== undefined && this.unreadReceivedNotificationsId.length > 0) {
      this.stompClient.send('/app/notifications/read', JSON.stringify(this.unreadReceivedNotificationsId));
      this.globals.unreadNotificationCount = this.globals.unreadNotificationCount - this.unreadReceivedNotificationsId.length;
      if (this.globals.unreadNotificationCount < 0) {
        this.globals.unreadNotificationCount = 0;
      }
      this.unreadReceivedNotificationsId = [];
    }
  }

  private onMessageReceived(notification) {
    let receivedNotification: Notification;
    receivedNotification = JSON.parse(notification.body);
    this.unreadReceivedNotificationsId.push(receivedNotification.id);
    this.receivedNotificationAction(receivedNotification);
    if (this.router.url === '/notifications') {
      this.readNotifications();
    }
  }

  private receivedNotificationAction(notification: Notification) {
    if (this.globals.userNotifications !== undefined) {
      this.globals.userNotifications.unshift(notification);
    }
    if (notification.type === '1') {
      this.globals.unreadMessagesCount = this.globals.unreadMessagesCount + 1;
    }

    this.globals.notificationCount = this.globals.notificationCount + 1;
    this.globals.unreadNotificationCount = this.globals.unreadNotificationCount + 1;
    this.toolbarService.refreshMenuItems();
  }

  checkLocalUnreadNotifications() {
    this.globals.userNotifications.filter((item: Notification) => {
      if (item.unread) {
        item.unread = false;
      }
    });
  }

  getNotifications(page: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    return this.http.get(this.globals.apiUrl + '/notifications', {
      params: params,
    });
  }

  countUnreadNotifications(): Observable<any> {
    return this.http.get(this.globals.apiUrl + '/notifications/count');
  }

  removeNotification(id: number, callback?: any) {
    this.http.delete(this.globals.apiUrl + '/notifications/' + id).subscribe(() => {
      if (callback) {
        return callback && callback();
      }
    });
  }
}
