var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component } from "@angular/core";
import { ToolbarService } from "@app/shared/services/toolbar/toolbar.service";
import { Globals } from "../../../shared/globals";
import { LanguagesList } from "@app/core/languages";
import { PopoverController } from "@ionic/angular";
import { DropDownListComponent } from "@app/shared/components/drop-down-list/drop-down-list.component";
import { LangPickerDropDownComponent } from "@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component";
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/services/toolbar/toolbar.service";
import * as i2 from "../../../shared/globals";
import * as i3 from "@ionic/angular";
import * as i4 from "@app/modules/category-ngrx/category-tree/category-tree.component";
export class CategoryContainerComponent {
    constructor(toolbarService, globals, popoverController) {
        this.toolbarService = toolbarService;
        this.globals = globals;
        this.popoverController = popoverController;
        this.PAGE_TITLE = "Categories";
        this.headerActionCategory = [
            {
                title: "Edit",
                icon: "assets/icons/design_v4/edit.svg",
                action: env => this.showEditMenu(env)
            }
        ];
        this.dropDownEditItems = {
            data: [
                {
                    callback: () => {
                        this.onToggleEdit();
                    },
                    name: "Edit"
                },
                {
                    callback: () => {
                        this.onToggleRemove();
                    },
                    name: "Remove"
                }
            ]
        };
        this.languageList = LanguagesList;
        const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;
        this.headerActionCategory.push({
            icon: `assets/icons/design_v4/langs/${lang.toLocaleLowerCase()}.svg`,
            title: lang,
            action: env => this.presentLanguageMenu(env)
        });
    }
    updateNavigation() {
        this.toolbarService.setTitle(this.PAGE_TITLE);
        if (this.globals.isCategoryEditingMode) {
            const done = [];
            this.toolbarService.setMenuItems(done);
        }
        else {
            this.toolbarService.setMenuItems(this.headerActionCategory);
        }
        if (this.globals.isRemovingMode) {
            const done = [];
            this.toolbarService.setMenuItems(done);
        }
        else {
            this.toolbarService.setMenuItems(this.headerActionCategory);
        }
    }
    showEditMenu(env) {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = yield this.popoverController.create({
                component: DropDownListComponent,
                componentProps: this.dropDownEditItems,
                event: env,
                translucent: false
            });
            yield modal.present();
        });
    }
    presentLanguageMenu(env) {
        return __awaiter(this, void 0, void 0, function* () {
            this.popover = yield this.popoverController.create({
                component: LangPickerDropDownComponent,
                event: env,
                translucent: false,
                showBackdrop: false
            });
            yield this.popover.present();
            this.popover.onDidDismiss().then(e => {
                if (!e.data) {
                    return;
                }
                this.headerActionCategory[this.headerActionCategory.length - 1].icon = `assets/icons/design_v4/langs/${e.data.name.toLocaleLowerCase()}.svg`;
                this.headerActionCategory[this.headerActionCategory.length - 1].title =
                    e.data.name;
            });
        });
    }
    ngOnInit() { }
    onToggleRemove() { }
    onToggleEdit() { }
}
CategoryContainerComponent.ɵfac = function CategoryContainerComponent_Factory(t) { return new (t || CategoryContainerComponent)(i0.ɵɵdirectiveInject(i1.ToolbarService), i0.ɵɵdirectiveInject(i2.Globals), i0.ɵɵdirectiveInject(i3.PopoverController)); };
CategoryContainerComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CategoryContainerComponent, selectors: [["app-category-container"]], decls: 2, vars: 0, template: function CategoryContainerComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-content");
        i0.ɵɵelement(1, "app-category-tree-ngrx");
        i0.ɵɵelementEnd();
    } }, directives: [i3.IonContent, i4.CategoryTreeNgrxComponent], styles: [""] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CategoryContainerComponent, [{
        type: Component,
        args: [{
                selector: "app-category-container",
                templateUrl: "./category-container.component.html",
                styleUrls: ["./category-container.component.scss"]
            }]
    }], function () { return [{ type: i1.ToolbarService }, { type: i2.Globals }, { type: i3.PopoverController }]; }, null); })();
