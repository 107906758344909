import {Pipe, PipeTransform} from '@angular/core';
import {Category} from '@app/modules/category/category';

@Pipe({
  name: 'CategoryIcon'
})
export class CategoryIconPipe implements PipeTransform {

  transform(category: Category, inverted?: boolean): string | null {
    switch (category.id) {
      case 125:
        return `assets/icons/design_v4/spiritual_life${inverted ? '_inverted' : ''}.svg`;
      case 103:
        return `assets/icons/design_v4/education${inverted ? '_inverted' : ''}.svg`;
      case 102:
        return `assets/icons/design_v4/home_and_family${inverted ? '_inverted' : ''}.svg`;
      case 127:
        return `assets/icons/design_v4/personality${inverted ? '_inverted' : ''}.svg`;
      case 101:
        return `assets/icons/design_v4/work_and_hobby${inverted ? '_inverted' : ''}.svg`;
      case 104:
        return `assets/icons/design_v4/social_media${inverted ? '_inverted' : ''}.svg`;
      case 126:
        return `assets/icons/design_v4/health${inverted ? '_inverted' : ''}.svg`;
      default:
        return null;
    }
  }

}
