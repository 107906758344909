import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError, EMPTY} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {Tokens} from '@app/shared/models/tokens';
import {AuthenticationService} from '@app/core/auth/authentication.service';
import {TokensService} from '@app/shared/services/tokens.service';
import {Router} from '@angular/router';
import {Globals} from '@app/shared/globals';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthenticationService,
              public tokensService: TokensService,
              private router: Router,
              public globals: Globals) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokens: Tokens = JSON.parse(localStorage.getItem('tokens'));
    if (((request.url.includes("accounts")) && (!request.url.includes("logout"))) || request.url.includes('assets')) {
      return next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.isRefreshing = false;
          this.authService.logoutProcesses();
          return throwError(error);
        } else {
          return next.handle(request);
        }
      }));
    }

    if (tokens) {
      if (tokens.jwtToken && !this.tokensService.isExpired() ) {
        request = this.addToken(request, tokens.jwtToken);
      } else {
        if (request && request.body && !request.body.skip || !request.body) {
          return this.workWithToken(request, next);
        }
      }
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        this.authService.logoutProcesses();
      } else if (error instanceof HttpErrorResponse && error.status === 403 && request.url.includes('categories') && request.method === 'GET') {
        return throwError(error);
      } else {
        this.globals.action = null;
        this.router.navigate(['/']);
      }
      return throwError(error);
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    request = request.clone({headers: request.headers.set('X-Requested-With', 'XMLHttpRequest')});
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private workWithToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshAuthToken().pipe(
        switchMap((token: any) => {
          const tokens = new Tokens({
            ...JSON.parse(localStorage.getItem('tokens')),
            jwtToken: token.jwtToken,
            createdDate: new Date().toISOString(),
          });
          localStorage.setItem('tokens', JSON.stringify(tokens));
          this.isRefreshing = false;
          this.refreshTokenSubject.next(tokens.jwtToken);
          return next.handle(this.addToken(request, tokens.jwtToken));
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwtToken => {
          return next.handle(this.addToken(request, jwtToken));
        }));
    }
  }
}
