<div class="voting-panel">

    <ion-col>
        <ion-list class="question-list">
            <ion-item *ngFor="let item of this.questionsList">

                <ion-button [color]="importantQuestion(item)"
                            (click)="makeImportant(item)"
                >
                    {{ item.id }}
                </ion-button >

                <ion-col id="{{item.id}}"
                         [class.dis]="id !== item.id"
                >
                    <ion-input class="question-textarea"
                               autofocus="true"
                               [(ngModel)]="questionText"
                    >
                    </ion-input>
                </ion-col>

                <ion-col [class.dis]="id === item.id"
                         (click)="changeData(item)"
                >
                    "{{ item.questionText }}"
                </ion-col>

                <div item-end>
                    <ion-button *ngIf="isEditing"
                                [class.dis]="id !== item.id"
                                class="form__button__text"
                                (click)="updateVotingQuestion(item)"
                                translate
                    >
                        <ion-icon name="checkmark"></ion-icon>
                    </ion-button>

                    <ion-button *ngIf="isEditing"
                                [class.dis]="id !== item.id"
                                class="form__button__text"
                                (click)="isEditing = false; id = null"
                                translate
                    >
                        <ion-icon name="close"></ion-icon>
                    </ion-button>

                    <ion-button class="form__button__text"
                                color="danger"
                                (click)="deleteVotingQuestion(item.id)"
                                translate
                    >
                        <ion-icon name="trash"></ion-icon>
                    </ion-button>
                </div>

            </ion-item>
        </ion-list>
    </ion-col>

    <ion-col>
        <ion-item>
            <ion-textarea rows="1"
                          cols="20"
                          placeholder="Enter question here..."
                          [(ngModel)]="question"
            >
            </ion-textarea>
            <div class="item-note" item-end>
                <ion-button disabled="{{ question.length < 5 }}"
                            class="form__button__text"
                            expand="block"
                            (click)="addQuestionToVotingQuestions()"
                            translate
                >
                    Add
                </ion-button>
            </div>
        </ion-item>
    </ion-col>

</div>
