<ion-content>
    <ion-row class="buttons-row" *ngIf="!isEditor; else editorPanel">
        <ion-button class="buttons-row-item"
                    *ngFor="let b of previewButtons"
                    (click)="b.action()"
        >
            <div class="buttons-row-item-title">{{ b.title }}</div>
        </ion-button>
    </ion-row>

    <ng-template #editorPanel>
        <ion-row class="buttons-row">
            <ion-button class="buttons-row-item"
                        *ngFor="let b of editorButtons"
                        (click)="b.action()"
            >
                <div class="buttons-row-item-title">{{ b.title }}</div>
            </ion-button>

            <ion-button class="buttons-row-item"
                        color="danger"
                        *ngIf="!isNewTemplate"
                        (click)="deleteTemplate()">
                <div class="buttons-row-item-title">{{ "Delete" }}</div>
            </ion-button>
        </ion-row>
    </ng-template>

    <ion-col class="container" *ngIf="!isEditor">
        <ion-spinner
            *ngIf="isRefreshing"
            class="main-loader"
            name="crescent">
        </ion-spinner>
        <ion-list class="note-list" *ngIf="!isRefreshing">
            <ion-item  class="note-preview" *ngFor="let item of this.templatesList">
                <div class="note-header">
                    <ion-button class="edit-button" (click)="toggleEditor(item)">
                        <div class="buttons-row-item-title">{{ "Edit template" }}</div>
                    </ion-button>
                    <img class="color-tag" [src]="getExampleTagIcon()"/>
                </div>
                <div class="note-block">
                    <div class="left-column">
                        <div class="avatar">
                            <img [src]="globals.owner.userPhotoName"/>
                        </div>
                        <div class="counter-blocks">
                            <div class="views-counter">
                                <div class="icon-square view-count">
                                    <i class="far fa-angry"></i>
                                </div>
                                <div class="count">0</div>
                            </div>
                            <div class="comments-counter">
                                <div class="icon-square comment-count">
                                    <i class="far fa-angry"></i>
                                </div>
                                <div class="count">0</div>
                            </div>
                        </div>
                    </div>
                    <div class="main-column">
                        <div class="author-name">{{ globals.owner | userName }}</div>
                        <div class="author-additional-data">
                            <div class="date">{{ item.purpose }}</div>
                        </div>
                        <div *ngIf="item.tempId != previewId">
                            <quill-view-html class="quill-view-html" [content]="chopTemplate(getTemplateContent(item))"></quill-view-html>
                            <span class="explain-button" translate (click)="togglePreview(item)">more...</span>
                        </div>
                        <div *ngIf="item.tempId == previewId">
                            <quill-view-html class="quill-view-html" [content]="getTemplateContent(item)"></quill-view-html>
                            <span class="explain-button" translate (click)="togglePreview(item)">less...</span>
                        </div>
                    </div>
                    <div class=cut-corner-column></div>
                </div>
            </ion-item>
        </ion-list>
    </ion-col>

    <div style="position: relative; width: 90%; margin-left: 5%;">
        <ion-textarea rows="1"
                      cols="20"
                      placeholder="Enter template purpose. Example: tutorial, greeting, etc."
                      [(ngModel)]="templatePurpose"
                      *ngIf="isEditor && isNewTemplate"
        >
        </ion-textarea>
        <app-quill *ngIf="isEditor" class="quill-editor-app"></app-quill>
    </div>
</ion-content>
