<ion-header *ngIf="isNoteEditing()">
  <ion-toolbar color="primary" [ngStyle]="{'padding-top': toolbarService.isNewIphone ? '30px' : '10px'}">
    <ion-buttons class="left-actions ml-3" slot="start">
      <ion-button shape="round" (click)="dismissCategoryTreeModal()">
        <img [src]="'assets/icons/design_v4/back_icon.svg'"/>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" *ngFor="let a of headerActionCategory">
      <img *ngIf="a.action"
           class="action-button mx-2"
           [src]="a.icon"
           (click)="a.action($event)"/>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>
  <ion-spinner
    *ngIf="!categoriesLoaded"
    class="main-loader"
    name="crescent">
  </ion-spinner>

  <ng-container *ngIf="categoriesLoaded">
    <div class="categories-container" [bdcWalkTriggerFor]="welcomeGuideTask4">
      <div class="main-categories-block">
        <div class="category-row">
          <app-category-level
            [level]="rootLevel"
            *ngIf="rootLevel"
            (categoryChanged)="categoryChangedInRoot($event)"
          ></app-category-level>
        </div>
      </div>

      <div class="categories__txt-slider">
        <div *ngFor="let level of categoriesLevels; let i = index">
          <ion-title class="ion-text-center"
                     *ngIf="checkPlaceVisibility(level.levelData.activeCategoryId)"
                     (click)="openPlace()">{{getPlaceTitle()}}</ion-title>
          <div>
            <app-category-level
              [level]="level"
              (selectedCategory)="selectedCategoryHandler(i, $event)"
              (showAddNewCategoryBlock)="showAddNewCategoryHandler()"
              (categoryChanged)="categoryChangedHandler(i, $event)"
            ></app-category-level>
          </div>

        </div>
      </div>
    </div>

    <div class="navigation-bar" *ngIf="!globals.isCategoryEditingMode">
      <div class="btn-group"
           role="group"
           *ngIf="!isVisibleAddNewCategoryBlock else newCategoryForm">
        <button type="button"
                class="btn"
                *ngIf="!movingCategory && canUserMoveLastActiveCategory()"
                (click)="enableMovingFlag()"
        >
          <img [src]="'assets/icons/design_v4/path.svg'" alt="">
        </button>
        <div [bdcWalkTriggerFor]="welcomeGuideTask5">
          <button type="button" [bdcWalkTriggerFor]="addNoteGuideTask7"
                  class="btn"
                  *ngIf="!movingCategory"
                  (click)="acceptCategory()">
            <!--Accept-->
            <img [src]="'assets/icons/design_v4/check.svg'" alt="" >
          </button>
        </div>

        <button type="button"
                class="btn"
                *ngIf="movingCategory"
                (click)="cancelMoving()"
                translate
        >
          Cancel
        </button>

        <button type="button"
                class="btn"
                *ngIf="movingCategory"
                (click)="moveCurrentCategory()"
                translate
        >
          Apply moving
        </button>
      </div>

      <ng-template #newCategoryForm>
        <div class="new-category-form" *ngIf="isVisibleAddNewCategoryBlock">
          <div class="label-icon"
               (click)="label.action ? label.action() : null">
            <img [src]="label.icon"/>
          </div>
          <div class="form-group">
            <label>
              <input type="text"
                     name="subject"
                     autofocus="autofocus"
                     appCustomAutofocus
                     class="form-control"
                     placeholder="{{CATEGORY_NAME | translate}}"
                     [(ngModel)]="newCategoryName">
            </label>
          </div>

          <button type="button"
                  class="btn btn-close"
                  (click)="hideAddNewCategoryBlock()">
            <img class="icon-close" [src]="'assets/icons/design_v4/close.png'" alt="">
          </button>
          <button type="button"
                  class="btn"
                  (click)="addNewCategoryToActiveLevel()">
            <img [src]="'assets/icons/design_v4/check.svg'" alt="">
          </button>
        </div>
      </ng-template>
    </div>

    <ng-container *ngIf="globals.isCategoryEditingMode">
      <div class="navigation-bar">
        <input type="text" class="input__edit-mode"
               appCustomAutofocus
               (keyup)="checkNameField()"
               [placeholder]="categoryService.getTranslationForTitleByLang(editCategory)"
               [(ngModel)]="editedCategoryTitle">
        <button type="button"
                class="btn"
                [disabled]="!canSave"
                (click)="updateCategory()">
          <!--Accept-->
          <img [src]="'assets/icons/design_v4/check.svg'" alt="">
        </button>
      </div>
    </ng-container>

    <bdc-walk-popup class="custom-popup"#welcomeGuideTask4 name="welcomeGuideTask4" [alignCenter]="true" yPosition="below"
                    [mustCompleted]="{welcomeGuideShowWalkthrough: true, welcomeGuideTask3: true}"
                    [showCloseButton]="false"
                    [closeOnClick]="true">
      <span translate>Select any category above (You can use the search bar)<br></span>
    </bdc-walk-popup>
    <bdc-walk-popup class="custom-popup" #welcomeGuideTask5 name="welcomeGuideTask5" xPosition="after" yPosition="above"
                    [mustCompleted]="{welcomeGuideShowWalkthrough: true, welcomeGuideTask4: true}"
                    [showCloseButton]="false"
                    [closeOnClick]="true">
      <span translate>Click 'V' button to confirm your choice<br></span>
    </bdc-walk-popup>
    <bdc-walk-popup class="custom-popup" #addNoteGuideTask7 name="addNoteGuideTask7" xPosition="after" yPosition="above"
                    [mustCompleted]="{addNoteGuideShowWalkthrough: true}"
                    [showCloseButton]="false"
                    [closeOnClick]="true">
      <span translate>Click 'V' button to create a note in this category.<br></span>
    </bdc-walk-popup>
  </ng-container>

</ion-content>
