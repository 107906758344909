<ion-content>

    <ion-refresher slot="fixed" (ionRefresh)="doRefreshContent($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <ion-list class="notification-list" style="margin-top: 10px;">

        <ion-item-sliding
                class="custom-line cursor-pointer cursor-drag"
                *ngFor="let notification of globals.userNotifications; let i = index; ">

            <ion-item-divider
                    *ngIf="canViewDivider(i)"
                    class="divider">
                <ion-label>{{dividerText(i)}}</ion-label>
            </ion-item-divider>

            <ion-item
                    class="on-left-line"
                    lines="none"
                    [ngClass]="{'notification-item--active' : notification.unread}"
                    style="margin-right: 2px" (click)="onItemClickHandler(notification)">
                <ion-avatar
                        slot="start"
                        class="avatar-img">
                    <img  src="{{notification.sender.userPhotoName}}" alt="photo">
                </ion-avatar>

                <ion-label>
                    <h2 class="user-name">{{notification.sender | userName}}
                        <span class="notification-item__text__time" slot="end">
                    {{notification.creationDate ? dateService.fromNow(notification.creationDate) : ''}}
                        </span>
                    </h2>
                    <p class="user-sub">{{getNotificationText(notification)}}
                        <ion-icon class="item_icon"  src="{{getNotificationIcon(notification)}}" size="small" slot="end"></ion-icon>
                    </p>
                </ion-label>
            </ion-item>

            <ion-item-options side="end">
                <ion-item-option *ngIf="!canViewDivider(i)"
                        (click)="confirmRemove(notification)"
                        class="sub-item"
                        color="white">
                    <img
                            style="width: 30px"
                            [src]="'assets/icons/design_v4/trash-outline.svg'" alt="">
                </ion-item-option>
                <ion-item-option *ngIf="canViewDivider(i)"
                        (click)="confirmRemove(notification)" style="padding-top: 32px"
                        class="sub-item"
                        color="white">
                    <img
                            style="width: 30px"
                            [src]="'assets/icons/design_v4/trash-outline.svg'" alt="">
                </ion-item-option>
            </ion-item-options>
        </ion-item-sliding>

    </ion-list>

    <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
        <ion-infinite-scroll-content
                loadingSpinner="bubbles"
                loadingText="Loading more data...">
        </ion-infinite-scroll-content>
    </ion-infinite-scroll>

</ion-content>

