<ion-content>
  <div class="categories-container">
    <ion-spinner
            *ngIf="!categoriesLoaded"
            class="main-loader"
            name="crescent">
    </ion-spinner>

    <ng-container *ngIf="categoriesLoaded && !globals.enabledSearch else searchCategory">

      <div *ngIf="globals.topCategoryList" class="main-categories-block">
        <div class="category-row" *ngFor="let _ of getTopCategoriesRowCount(), let rowIndex = index">
          <div *ngFor="let category of getTopCategoriesByRow(rowIndex), let itemIndex = index"
               class="category-item main-category-item"
               [class.center-item]="rowIndex === 1 && itemIndex === 1"
               (click)="onCategoryClick(category)">
            <div
              class="notes-count"
              [class.hidden]="(globals.feed.feedType === feedService.getFeedType().WORLD || globals.feed.feedType === feedService.getFeedType().FRIEND)"
              [style.marginLeft]="itemIndex === 0 ? '-5px' : ''"
            >
              <div class="notes-number">{{ category.numberOfNotes }}</div>
            </div>
            <div class="content">
              <div class="icon">
                <img [src]="category | CategoryIcon"/>
              </div>
              <div class="title">{{ getTranslatedCategoryTitle(category) }}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="(globals.feed.feedType === feedService.getFeedType().HOME || globals.feed.feedType === feedService.getFeedType().USER) && globals.recentCategories
        && globals.selectedUser.id === globals.owner.id"
        class="recent-categories-block"
      >
        <div class="category-item"
             *ngFor="let category of globals.recentCategories.slice(0, globals.maxSizeOfRecentCategories)"
             [class.category__private]="category.accessType === 3"
             [class.category__mod]="category.accessType === 2"
             (click)="onCategoryClick(category)">
          {{ getTranslatedCategoryTitle(category) }}
        </div>
      </div>
    </ng-container>

    <ng-template #searchCategory>
      <div class="search-block">
        <ion-list class="search__result-list">
          <div class="search__result-item"
               *ngFor="let item of this.searchResult"
               [class.category__private]="item.accessType === 3"
               [class.category__mod]="item.accessType === 2"
               (click)="onCategoryClick(item)">
            <div class="breadcrumb-block" *ngFor="let breadcrumb of this.getCategoryTitles(item), let last = last">
              <p class="breadcrumb-item"
                 [class.active]="last">
                {{breadcrumb.title}}
              </p>
              <i *ngIf="!last" class="breadcrumb-separator"></i>
            </div>
          </div>
        </ion-list>
      </div>
    </ng-template>
  </div>
</ion-content>
