import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Category } from '@app/modules/category/category';
import { Globals } from "../../shared/globals";
import { Languages } from "../../core/languages";
import { CategoryTranslation } from "../../core/categoryTranslation";


@Injectable()
export class CategoryApiService {
  constructor(public http: HttpClient, private globals: Globals) {}

  getRootCategories(userId: number): Observable<Category[]> {
    const params = new HttpParams().append(
      "currentLang",
      this.globals.currentLanguage
    );
    return this.http.get<Category[]>(
      this.globals.apiUrl + "/categories/flower/users/" + userId,
      {
        params
      }
    );
  }

  getAllCategories(userId: number): Observable<Category[]> {
    const params = new HttpParams().append(
      "currentLang",
      this.globals.currentLanguage
    );
    return this.http.get<Category[]>(
      this.globals.apiUrl + "/categories/users/" + userId,
      {
        params
      }
    );
  }

  getCategoryById(categoryId: number) {
    const params = new HttpParams().append(
      "currentLang",
      this.globals.currentLanguage
    );
    return this.http.get(this.globals.apiUrl + "/categories/" + categoryId, {
      params
    });
  }

  getChildCategoriesByParentId(categoryId: number) {
    const params = new HttpParams()
      .append("currentLang", this.globals.currentLanguage)
      .append("userId", this.globals.selectedUser.id.toString());
    return this.http.get(
      this.globals.apiUrl + "/categories/child/" + categoryId,
      {
        params
      }
    );
  }

  filterByScopeScope(categories: Category[], scope: number): Category[] {
    let parentsCategories = [];
    if (categories) {
      const filter = categories.filter((c: Category) => c.scope === scope);
      parentsCategories = [...filter];
    }
    return parentsCategories;
  }

  assignCategoriesParents(categories: Category[]) {
    const catList = new Array<Category>();
    for (const category of categories) {
      const parId = category.parentCategoryId;
      let tmp = categories.filter((item: Category) => {
        return parId === item.id;
      })[0];
      if (tmp === undefined) {
        tmp = new Category();
      }
      category.parentCategory = tmp;
      catList.push(category);
    }
    return catList;
  }

  getTitleForTranslation(titleLang, newCategoryTitle, i: CategoryTranslation) {
    switch (titleLang) {
      case Languages.AR:
        i.ar = newCategoryTitle;
        break;
      case Languages.BN:
        i.bn = newCategoryTitle;
        break;
      case Languages.DE:
        i.de = newCategoryTitle;
        break;
      case Languages.EN:
        i.en = newCategoryTitle;
        break;
      case Languages.ES:
        i.es = newCategoryTitle;
        break;
      case Languages.FR:
        i.fr = newCategoryTitle;
        break;
      case Languages.HI:
        i.hi = newCategoryTitle;
        break;
      case Languages.ID:
        i.id = newCategoryTitle;
        break;
      case Languages.IT:
        i.it = newCategoryTitle;
        break;
      case Languages.JA:
        i.ja = newCategoryTitle;
        break;
      case Languages.PT:
        i.pt = newCategoryTitle;
        break;
      case Languages.RU:
        i.ru = newCategoryTitle;
        break;
      case Languages.ZH:
        i.zh = newCategoryTitle;
        break;
    }
    return i;
  }

  getTranslationForTitle(category) {
    let i = "";
    if (category.translation != null) {
      switch (this.globals.currentLanguage) {
        case Languages.AR:
          i = category.translation.ar;
          break;
        case Languages.BN:
          i = category.translation.bn;
          break;
        case Languages.DE:
          i = category.translation.de;
          break;
        case Languages.EN:
          i = category.translation.en;
          break;
        case Languages.ES:
          i = category.translation.es;
          break;
        case Languages.FR:
          i = category.translation.fr;
          break;
        case Languages.HI:
          i = category.translation.hi;
          break;
        case Languages.ID:
          i = category.translation.id;
          break;
        case Languages.IT:
          i = category.translation.it;
          break;
        case Languages.JA:
          i = category.translation.ja;
          break;
        case Languages.PT:
          i = category.translation.pt;
          break;
        case Languages.RU:
          i = category.translation.ru;
          break;
        case Languages.ZH:
          i = category.translation.zh;
          break;
      }
    }
    return i;
  }

  getTranslationForTitleByLang(category) {
    if (!category) {
      return;
    }
    const lang = category.nativeLanguage;
    let i = "";
    if (category.translation != null) {
      switch (lang) {
        case Languages.AR:
          i = category.translation.ar;
          break;
        case Languages.BN:
          i = category.translation.bn;
          break;
        case Languages.DE:
          i = category.translation.de;
          break;
        case Languages.EN:
          i = category.translation.en;
          break;
        case Languages.ES:
          i = category.translation.es;
          break;
        case Languages.FR:
          i = category.translation.fr;
          break;
        case Languages.HI:
          i = category.translation.hi;
          break;
        case Languages.ID:
          i = category.translation.id;
          break;
        case Languages.IT:
          i = category.translation.it;
          break;
        case Languages.JA:
          i = category.translation.ja;
          break;
        case Languages.PT:
          i = category.translation.pt;
          break;
        case Languages.RU:
          i = category.translation.ru;
          break;
        case Languages.ZH:
          i = category.translation.zh;
          break;
      }
    }
    return i;
  }

  getCategoryIndex(categories: Category[], categoryId: number): number {
    return categories.findIndex(
      (category: Category) => category.id === +categoryId
    );
  }

  getCategoryIdByIndex(categories: Category[], index: number): number {
    return categories ? categories[index].id : null;
  }
}
