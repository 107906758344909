var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component } from '@angular/core';
import { Globals } from "@app/shared/globals";
import { AuthenticationService } from "@app/core/auth/authentication.service";
import { Router } from "@angular/router";
import { FacebookAuthService } from "@app/core/auth/facebook-auth.service";
import { GoogleAuthService } from "@app/core/auth/google-auth.service";
import { AppleAuthService } from "@app/core/auth/apple-auth.service";
import { MenuService } from "@app/shared/services/menu/menu.service";
import { AlertController, LoadingController, PopoverController } from "@ionic/angular";
import { HttpClient, HttpParams } from "@angular/common/http";
import { TokensService } from "@app/shared/services/tokens.service";
import { Storage } from "@ionic/storage";
import { LanguagesList } from "@app/core/languages";
import { LangPickerDropDownComponent } from "@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component";
import { ToolbarService } from "@app/shared/services/toolbar/toolbar.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@app/shared/globals";
import * as i3 from "@app/core/auth/authentication.service";
import * as i4 from "@app/core/auth/facebook-auth.service";
import * as i5 from "@app/core/auth/google-auth.service";
import * as i6 from "@app/core/auth/apple-auth.service";
import * as i7 from "@app/shared/services/menu/menu.service";
import * as i8 from "@ionic/angular";
import * as i9 from "@angular/common/http";
import * as i10 from "@app/shared/services/tokens.service";
import * as i11 from "@ionic/storage";
import * as i12 from "@app/shared/services/toolbar/toolbar.service";
import * as i13 from "../../../shared/components/auth-header/auth-header.component";
import * as i14 from "@angular/forms";
import * as i15 from "@ngx-translate/core";
export class ConfirmAccountComponent {
    constructor(router, globals, app, facebookAuthService, googleAuthService, appleAuthService, menuService, alertCtrl, http, authenticationService, tokensService, storage, popoverController, toolbarService, loadingController) {
        this.router = router;
        this.globals = globals;
        this.app = app;
        this.facebookAuthService = facebookAuthService;
        this.googleAuthService = googleAuthService;
        this.appleAuthService = appleAuthService;
        this.menuService = menuService;
        this.alertCtrl = alertCtrl;
        this.http = http;
        this.authenticationService = authenticationService;
        this.tokensService = tokensService;
        this.storage = storage;
        this.popoverController = popoverController;
        this.toolbarService = toolbarService;
        this.loadingController = loadingController;
        this.acceptedTerms = false;
        this.typingTimerInterval = 300;
        this.isCheckingReferrer = false;
        this.languageList = LanguagesList;
    }
    ngOnInit() {
        this.currentUser = this.globals.owner;
        console.log(this.currentUser);
        const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;
        this.endActions = [
            {
                icon: `langs/${lang.toLocaleLowerCase()}.svg`,
                name: lang,
                callback: env => this.presentLanguageMenu(env)
            }
        ];
    }
    presentLanguageMenu(env) {
        return __awaiter(this, void 0, void 0, function* () {
            this.popover = yield this.popoverController.create({
                component: LangPickerDropDownComponent,
                event: env,
                translucent: false,
                showBackdrop: false,
            });
            yield this.popover.present();
            this.popover.onDidDismiss().then(e => {
                if (e.data) {
                    this.endActions[0].icon = `langs/${e.data.name.toLocaleLowerCase()}.svg`;
                    this.endActions[0].name = e.data.name;
                }
            });
        });
    }
    confirmAccount() {
        return __awaiter(this, void 0, void 0, function* () {
            let loading = yield this.loadingController.create({});
            yield loading.present();
            let params = new HttpParams();
            params = params.append('currentLanguage', this.globals.currentLanguage);
            params = params.append('needRegister', "true");
            this.http.post(this.globals.apiUrl + '/accounts/login/social', this.currentUser, { params })
                .toPromise()
                .then((response) => {
                if (response !== null) {
                    this.tokensService.setTokens(response.witaAuthToken);
                    localStorage.setItem('wita-user', JSON.stringify(response));
                    this.storage.set('wita-user', response);
                    this.globals.owner = response;
                    this.authenticationService.afterLoginInitiation();
                    this.router.navigate(['/']);
                    this.toolbarService.setHeaderVisibility(true);
                    this.menuService.activeMainMenu();
                    if (loading) {
                        loading.dismiss();
                    }
                }
            }).catch((er) => __awaiter(this, void 0, void 0, function* () {
                console.log('throw error after social login request');
                throw er;
            }));
        });
    }
    ;
    onReferrerInput() {
        clearTimeout(this.referrerTypingTimerId);
        this.referrerTypingTimerId = setTimeout(() => {
            this.isCheckingReferrer = true;
            this.app.checkRegisterReferrer(this.referrerToCheck.toLowerCase())
                .subscribe((res) => {
                this.referrerUser = res && res.message ? res.message : null;
                this.isCheckingReferrer = false;
            });
        }, this.typingTimerInterval);
    }
    canActivate() {
        let isActive = true;
        if (!this.globals.owner) {
            this.router.navigate(['/']);
            isActive = false;
        }
        return isActive;
    }
}
ConfirmAccountComponent.ɵfac = function ConfirmAccountComponent_Factory(t) { return new (t || ConfirmAccountComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.Globals), i0.ɵɵdirectiveInject(i3.AuthenticationService), i0.ɵɵdirectiveInject(i4.FacebookAuthService), i0.ɵɵdirectiveInject(i5.GoogleAuthService), i0.ɵɵdirectiveInject(i6.AppleAuthService), i0.ɵɵdirectiveInject(i7.MenuService), i0.ɵɵdirectiveInject(i8.AlertController), i0.ɵɵdirectiveInject(i9.HttpClient), i0.ɵɵdirectiveInject(i3.AuthenticationService), i0.ɵɵdirectiveInject(i10.TokensService), i0.ɵɵdirectiveInject(i11.Storage), i0.ɵɵdirectiveInject(i8.PopoverController), i0.ɵɵdirectiveInject(i12.ToolbarService), i0.ɵɵdirectiveInject(i8.LoadingController)); };
ConfirmAccountComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ConfirmAccountComponent, selectors: [["app-confirm-account"]], decls: 30, vars: 11, consts: [["title", "Confirm account", 3, "endBtns"], [1, "container__profile__accept__block"], ["size", "10", 1, "container__profile__accept"], [1, "new__profile__logo"], ["alt", "", 3, "src"], [1, "new__profile__name"], [1, "new__profile__email"], ["size", "auto", 1, "form__profile__accept"], [1, "form__referrer__input"], ["alt", "", 1, "form__item__icon", 3, "src"], ["type", "text", 3, "placeholder", "ngModel", "ionInput", "ngModelChange"], [1, "privacy__policy__block"], [1, "privacy__policy__checkbox", 3, "ngModel", "ngModelChange"], [1, "policy__agree"], ["translate", ""], ["href", "/terms", "translate", ""], ["href", "/policy", "translate", ""], ["color", "primary", 1, "form__accept__profile__button", 3, "disabled", "click"], ["translate", "", 1, "form__button__text"]], template: function ConfirmAccountComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "app-auth-header", 0);
        i0.ɵɵelementStart(1, "ion-grid", 1);
        i0.ɵɵelementStart(2, "ion-col", 2);
        i0.ɵɵelementStart(3, "ion-row", 3);
        i0.ɵɵelement(4, "img", 4);
        i0.ɵɵelementStart(5, "label", 5);
        i0.ɵɵtext(6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "label", 6);
        i0.ɵɵtext(8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "ion-row", 7);
        i0.ɵɵelementStart(10, "ion-item", 8);
        i0.ɵɵelement(11, "img", 9);
        i0.ɵɵelementStart(12, "ion-input", 10);
        i0.ɵɵlistener("ionInput", function ConfirmAccountComponent_Template_ion_input_ionInput_12_listener() { return ctx.onReferrerInput(); })("ngModelChange", function ConfirmAccountComponent_Template_ion_input_ngModelChange_12_listener($event) { return ctx.referrerToCheck = $event; });
        i0.ɵɵpipe(13, "translate");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(14, "div", 11);
        i0.ɵɵelementStart(15, "ion-checkbox", 12);
        i0.ɵɵlistener("ngModelChange", function ConfirmAccountComponent_Template_ion_checkbox_ngModelChange_15_listener($event) { return ctx.acceptedTerms = $event; });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(16, "div", 13);
        i0.ɵɵelementStart(17, "span", 14);
        i0.ɵɵtext(18, "By clicking Sign Up, Google or Facebook buttons, you agree to our ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(19, "a", 15);
        i0.ɵɵtext(20, "Terms and Conditions");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(21, ", ");
        i0.ɵɵelement(22, "span", 14);
        i0.ɵɵtext(23, "and ");
        i0.ɵɵelementStart(24, "a", 16);
        i0.ɵɵtext(25, "Privacy Policy");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(26, ". ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(27, "ion-button", 17);
        i0.ɵɵlistener("click", function ConfirmAccountComponent_Template_ion_button_click_27_listener() { return ctx.confirmAccount(); });
        i0.ɵɵelementStart(28, "span", 18);
        i0.ɵɵtext(29, " Accept and continue ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("endBtns", ctx.endActions);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("src", ctx.currentUser.userPhotoName, i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", ctx.currentUser.firstName + " " + ctx.currentUser.lastName, " ");
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", ctx.currentUser.email, " ");
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("src", "assets/icons/design_v4/user_icon.svg", i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(1);
        i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(13, 9, "Referrer (optional)"));
        i0.ɵɵproperty("ngModel", ctx.referrerToCheck);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngModel", ctx.acceptedTerms);
        i0.ɵɵadvance(12);
        i0.ɵɵproperty("disabled", !ctx.acceptedTerms);
    } }, directives: [i13.AuthHeaderComponent, i8.IonGrid, i8.IonCol, i8.IonRow, i8.IonItem, i8.IonInput, i8.TextValueAccessor, i14.NgControlStatus, i14.NgModel, i8.IonCheckbox, i8.BooleanValueAccessor, i15.TranslateDirective, i8.IonButton], pipes: [i15.TranslatePipe], styles: [".container__profile__accept[_ngcontent-%COMP%], .container__profile__accept__block[_ngcontent-%COMP%]{display:flex;flex-flow:column;align-items:center}.new__profile__logo[_ngcontent-%COMP%]{margin:30px 0;display:flex;flex-flow:column;align-items:center}.new__profile__logo[_ngcontent-%COMP%] > img[_ngcontent-%COMP%]{border-radius:100%;height:100px;margin:0 auto;display:block}.new__profile__name[_ngcontent-%COMP%]{font-weight:bolder}.new__profile__email[_ngcontent-%COMP%]{font-weight:400}.form__profile__accept[_ngcontent-%COMP%]{display:flex;flex-flow:column;align-items:flex-start}.form__item__icon[_ngcontent-%COMP%]{margin-right:10px}.form__referrer__input[_ngcontent-%COMP%]{width:100%;margin:10px 0}.privacy__policy__block[_ngcontent-%COMP%]{margin:10px;display:grid;grid-auto-flow:column}.privacy__policy__checkbox[_ngcontent-%COMP%]{width:20px;height:20px;margin:auto 5px}.form__accept__profile__button[_ngcontent-%COMP%]{margin:20px;max-width:400px}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ConfirmAccountComponent, [{
        type: Component,
        args: [{
                selector: 'app-confirm-account',
                templateUrl: './confirm-account.component.html',
                styleUrls: ['./confirm-account.component.scss']
            }]
    }], function () { return [{ type: i1.Router }, { type: i2.Globals }, { type: i3.AuthenticationService }, { type: i4.FacebookAuthService }, { type: i5.GoogleAuthService }, { type: i6.AppleAuthService }, { type: i7.MenuService }, { type: i8.AlertController }, { type: i9.HttpClient }, { type: i3.AuthenticationService }, { type: i10.TokensService }, { type: i11.Storage }, { type: i8.PopoverController }, { type: i12.ToolbarService }, { type: i8.LoadingController }]; }, null); })();
