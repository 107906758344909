var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { Globals } from '@app/shared/globals';
import { AlertController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/globals";
import * as i2 from "@ionic/angular";
export class UiAlertService {
    constructor(globals, alertController) {
        this.globals = globals;
        this.alertController = alertController;
    }
    createAlert(headerText, messageText) {
        return __awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: headerText,
                message: messageText,
            });
            yield alert.present();
        });
    }
    createAlertWithButton(headerText, messageText, buttonText, callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: headerText,
                message: messageText,
                buttons: [
                    {
                        text: buttonText,
                        handler: () => __awaiter(this, void 0, void 0, function* () {
                            yield callback();
                        })
                    }
                ]
            });
            yield alert.present();
        });
    }
    createConfirm(headerText, messageText, declineBtnText, applyBtnText, callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: headerText,
                message: messageText,
                buttons: [
                    {
                        text: declineBtnText,
                        role: 'cancel',
                    }, {
                        text: applyBtnText,
                        handler: () => __awaiter(this, void 0, void 0, function* () {
                            yield callback();
                        })
                    }
                ]
            });
            yield alert.present();
        });
    }
}
UiAlertService.ɵfac = function UiAlertService_Factory(t) { return new (t || UiAlertService)(i0.ɵɵinject(i1.Globals), i0.ɵɵinject(i2.AlertController)); };
UiAlertService.ɵprov = i0.ɵɵdefineInjectable({ token: UiAlertService, factory: UiAlertService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UiAlertService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.Globals }, { type: i2.AlertController }]; }, null); })();
