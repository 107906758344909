import {Component, OnInit} from '@angular/core';
import {matchOtherValidator, noCyrillic} from '@app/shared/const/customFormValidators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../authentication.service';
import {FacebookAuthService} from '../facebook-auth.service';
import {Globals} from '@app/shared/globals';
import {GoogleAuthService} from '../google-auth.service';
import {SiteUser} from '@app/modules/site-user/site-user';
import {LoadingController, PopoverController} from '@ionic/angular';
import {Router} from '@angular/router';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {LangPickerDropDownComponent} from '@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component';
import {LanguagesList} from '@app/core/languages';
import {TranslateService} from '@ngx-translate/core';
import {AppleAuthService} from "@app/core/auth/apple-auth.service";
import {NoteService} from "@app/shared/services/note.service";
import {TemplateService} from "@app/shared/services/template.service";
import {CategoryService} from "@app/shared/services/category.service";
import {ProfileService} from "@app/shared/services/profile.service";
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {SiteUserService} from "@app/shared/services/site-user.service";

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css', './../authentication.component.css']
})
export class SignUpComponent implements OnInit {

  PASS_LOW_PATTERN = '(?=.*[a-z])';
  PASS_CAPITAL_PATTERN = '(?=.*[A-Z])';
  PASS_CHARACTER_PATTERN = '(?=.*[\\[\\]!@#$%^&*\\(\\)?\\.,><:;])';
  PASS_DIGIT_PATTERN = '(?=.*[0-9])';
  PASS_REGEX_PATTERN = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\[\\]!@#$%^&*\\(\\)?\\.,><:;])[A-Za-z\d\\[\\]!@#$%^&*\\(\\)?\\.,><:;].{7,}';


  signUpForm: FormGroup;
  validationData = {
    ssoId: null,
    email: null,
    referrer: null
  };
  errorMsg = '';

  isCheckingEmail = false;
  isCheckingName = false;
  isCheckingReferrer = false;

  isPassLengthOk = false;
  hasPassDigit = false;
  hasPassCapitalLetter = false;
  hasPassLowCaseLetter = false;
  hasPassCharacter = false;

  readonly typingTimerInterval = 600;

  ssoIdTypingTimerId;
  emailTypingTimerId;
  referrerTypingTimerId;
  endActions;
  languageList = LanguagesList;
  popover;


  constructor(
    private formBuilder: FormBuilder,
    public app: AuthenticationService,
    public faceBookAuthService: FacebookAuthService,
    public globals: Globals,
    public router: Router,
    public loadingController: LoadingController,
    public googleAuthService: GoogleAuthService,
    private menuService: MenuService,
    private popoverController: PopoverController,
    public translate: TranslateService,
    private appleAuthService: AppleAuthService,
    private uiAlertService: UiAlertService,
    private toolbarService: ToolbarService,
    public noteService: NoteService,
    public templateService: TemplateService,
    public categoryService: CategoryService,
    private profileService: ProfileService,
    public siteUserService: SiteUserService
  ) {
    this.signUpForm = this.formBuilder.group({
      ssoId: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [
        Validators.required,
        noCyrillic,
        Validators.pattern(this.PASS_REGEX_PATTERN),
      ]],
      confirmPassword: [null, [
        Validators.required,
        matchOtherValidator('password')
      ]],
      referrer: [null]
    });
  }

  ngOnInit() {

    const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;
    this.checkPassHandler('');
    this.endActions = [
      {
        icon: `langs/${lang.toLocaleLowerCase()}.svg`,
        name: lang,
        callback: env => this.presentLanguageMenu(env)
      }
    ];
  }

  async presentLanguageMenu(env) {

    this.popover = await this.popoverController.create({
      component: LangPickerDropDownComponent,
      event: env,
      translucent: false,
      showBackdrop: false,
    });


    await this.popover.present();

    this.popover.onDidDismiss().then(
      e => {
        if (e.data) {
          this.endActions[0].icon = `langs/${e.data.name.toLocaleLowerCase()}.svg`;
          this.endActions[0].name = e.data.name;
        }
      }
    );
  }

  parseReferrer(referrer) {
    return referrer !== null ? referrer.toLowerCase().trim() : "";
  }

  loginWithFb = () => this.faceBookAuthService.accessWithFB(this.parseReferrer(this.signUpForm.value.referrer), true);
  loginWithGoogle = () => this.googleAuthService.accessWithGoogle(this.parseReferrer(this.signUpForm.value.referrer), true);
  loginWithAppleID = () => this.appleAuthService.accessWithAppleID(this.parseReferrer(this.signUpForm.value.referrer), true);


  async signUp() {
    if (this.validationData.ssoId == null && this.validationData.email == null) {
      const loading = await this.loadingController.create({});
      await loading.present();

      this.signUpForm.value.ssoId = this.signUpForm.value.ssoId.trim();
      this.signUpForm.value.email = this.signUpForm.value.email.trim();
      this.signUpForm.value.referrer = this.parseReferrer(this.signUpForm.value.referrer);
      this.globals.isNewUser = true;
      const error: any = async (msg) => {
        await this.uiAlertService.createAlert("Error", `<b>${msg}</b>`);
      };

      try {
        this.errorMsg = '';

        const newUser = new SiteUser(this.signUpForm.value);

        this.app.register(newUser,
          () => {

            this.app.authenticateWithLowCase({username: newUser.ssoId, password: newUser.password},
              async () => {
                this.profileService.setReferrer(this.globals.selectedUser, this.signUpForm.value.referrer);
                await this.router.navigateByUrl('/');
                this.toolbarService.setHeaderVisibility(true);
                this.menuService.activeMainMenu();
                loading.dismiss();
              },
              async er => {
                console.log(er);
                loading.dismiss();
                error("Cant access to created profile");
              });
          },
          er => {
            loading.dismiss();
            this.errorMsg = er.error.message;
            error(er.error.message);
          });
      } catch (er) {
        loading.dismiss();
        this.errorMsg = er.message;
        error(er.message);
        console.log(er);
      }
      return false;
    }
  }

  onSsoIdInput(): void {
    clearTimeout(this.ssoIdTypingTimerId);
    this.ssoIdTypingTimerId = setTimeout(() => {
      this.isCheckingName = true;

      this.app.checkRegisterSsoId(this.signUpForm.value.ssoId.toLowerCase())
        .subscribe((res: any) => {
          this.validationData.ssoId = res && res.message ? res.message : null;
          this.isCheckingName = false;
        });
    }, this.typingTimerInterval);
  }

  onEmailInput(): void {
    clearTimeout(this.emailTypingTimerId);
    this.emailTypingTimerId = setTimeout(() => {
      this.isCheckingEmail = true;

      this.app.checkRegisterEmail(this.signUpForm.value.email.toLowerCase())
        .subscribe((res: any) => {
          this.validationData.email = res && res.message ? res.message : null;
          this.isCheckingEmail = false;
        });

    }, this.typingTimerInterval);
  }

  onReferrerInput(): void {
    clearTimeout(this.referrerTypingTimerId);
    this.referrerTypingTimerId = setTimeout(() => {
      this.isCheckingReferrer = true;

      this.app.checkRegisterReferrer(this.signUpForm.value.referrer.toLowerCase())
        .subscribe((res: any) => {
          this.validationData.referrer = res && res.message ? res.message : null;
          this.isCheckingReferrer = false;
        });

    }, this.typingTimerInterval);
  }

  checkPassHandler(input: string) {
    const regexDigit = RegExp(this.PASS_DIGIT_PATTERN);
    const regexLow = RegExp(this.PASS_LOW_PATTERN);
    const regexCapital = RegExp(this.PASS_CAPITAL_PATTERN);
    const regexCharacter = RegExp(this.PASS_CHARACTER_PATTERN);
    this.isPassLengthOk = input.length >= 8;
    this.hasPassDigit = regexDigit.test(input);
    this.hasPassLowCaseLetter = regexLow.test(input);
    this.hasPassCapitalLetter = regexCapital.test(input);
    this.hasPassCharacter = regexCharacter.test(input);
  }
}
