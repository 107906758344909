import {NgModule} from "@angular/core";
import {CustomSelectDirective} from "@app/shared/directive/customSelect-directive/customSelect.directive";


@NgModule({
	imports: [],
	declarations: [
		CustomSelectDirective
	],
	exports: [
		CustomSelectDirective
	],
})

export class CustomSelectDirectiveModule {
}
