import {Component} from '@angular/core';
import {AlertsService} from '@app/shared/services/alerts-service'
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";
import {MenuItem} from "@app/shared/models/menu/MenuItem";
import {Feedback} from "@app/modules/feedback/feedback";
import {HttpClient} from "@angular/common/http";
import {Globals} from "@app/shared/globals";
import {Router} from "@angular/router";
import {ProfileService} from "@app/shared/services/profile.service";

@Component({
  selector: 'app-alerts-page',
  templateUrl: './alerts-page.component.html',
  styleUrls: ['./alerts-page.component.scss', '../../core/auth/authentication.component.css']
})

export class AlertsPageComponent {
  public firstName: string = "";
  public email: string = "";
  public theme: string = "";
  public text: string = "";
  public disabled: boolean = true;
  public isFirstNameEmpty: boolean = true;
  public isEmailEmpty: boolean = true;

  headerActions: MenuItem[] = [];

  constructor(public http: HttpClient,
              public alertsService: AlertsService,
              public toolbarService: ToolbarService,
              public globals: Globals,
              public router: Router,
              public profileService: ProfileService) {
  }

  ngOnInit() {
  }

  updateNavigation(): void {
    this.toolbarService.setMenuItems(this.headerActions);
    this.isFirstNameEmpty = !this.globals.owner.firstName;
    this.isEmailEmpty = !this.globals.owner.email;
  }

  updateLocalData() {
    this.firstName = "";
    this.email = "";
    this.theme = "";
    this.text = "";
  }

  setUserData() {
    if (this.isFirstNameEmpty) {
      this.globals.owner.firstName = this.firstName
    }

    if (this.isEmailEmpty) {
      this.globals.owner.email = this.email
    }

    this.profileService.editSiteUserName()
  }

  disabledButton() {
    if ((this.firstName.length > 1 || this.globals.owner.firstName.length > 0) &&
        (this.email.length > 1 || this.globals.owner.email.length > 0) &&
        this.theme.length > 2 && this.text.length > 5) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  addAlertToFeedback() {
    this.setUserData();

    let feedback: Feedback = new Feedback();
    feedback.user = this.globals.owner;
    feedback.feedback = `${this.theme}: ${this.text}`;

    this.alertsService.sendAlert(feedback).subscribe(() => {
      this.alertsService.sendAlert(feedback);
    });

    this.updateLocalData();
    this.router.navigate(['/']);
  }

}
