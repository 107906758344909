<ion-content>
  <div class="policy">
    <div class="policy__close">
      <a href="/sign-up" translate>X</a>
    </div>
    <div class="policy__header">
      <div class="policy__location">
        State of California
      </div>
      <div class="policy__index">
        Rev. 133ED55
      </div>
    </div>
    <div class="policy__title">
      TERMS OF USE
    </div>
    <div class="policy__website">
      WITA.SPACE
    </div>
    <div class="policy__date">
      Version Date: January 13, 2020
    </div>


    <h5>TERMS OF USE AGREEMENT</h5>

    <p>This Terms of Use Agreement (“Agreement”) constitutes a legally binding agreement made between you, whether
      personally or on behalf of an entity (“user” or “you”) and Andrei Kania and its affiliated companies
      (collectively, “Company” or “we” or “us” or “our”), concerning your access to and use of the
      https://www.wita.space website as well as any other media form, media channel, mobile website or mobile
      application related or connected thereto (collectively, the “Website”). The Website provides the following
      service: social network experience (“Company Services”). Supplemental terms and conditions or documents that may
      be posted on the Website from time to time, are hereby expressly incorporated into this Agreement by reference.
    </p><br>
    <p>Company makes no representation that the Website is appropriate or available in other locations other than where
      it is operated by Company. The information provided on the Website is not intended for distribution to or use by
      any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or
      regulation or which would subject Company to any registration requirement within such jurisdiction or country.
      Accordingly, those persons who choose to access the Website from other locations do so on their own initiative and
      are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
    </p><br>
    <p>All users who are minors in the jurisdiction in which they reside (generally under the age of 18) are not
      permitted to register for the Website or use the Company Services.
    </p><br>
    <p>YOU ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT BY ACKNOWLEDGING SUCH ACCEPTANCE DURING THE REGISTRATION
      PROCESS (IF APPLICABLE) AND ALSO BY CONTINUING TO USE THE WEBSITE. IF YOU DO NOT AGREE TO ABIDE BY THIS AGREEMENT,
      OR TO MODIFICATIONS THAT COMPANY MAY MAKE TO THIS AGREEMENT IN THE FUTURE, DO NOT USE OR ACCESS OR CONTINUE TO USE
      OR ACCESS THE COMPANY SERVICES OR THE WEBSITE.
    </p>
    <h5>USER REPRESENTATIONS
    </h5>
    <h6>Regarding Your Registration
    </h6>
    <p>By using the Company Services, you represent and warrant that:
    </p><br>
    <ul>
      <li>A. all registration information you submit is truthful and accurate;
      </li>
      <li>B. you will maintain the accuracy of such information;
      </li>
      <li>C. you will keep your password confidential and will be responsible for all use of your password and account;
      </li>
      <li>D. you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental
        permission to use this Website; and
      </li>
      <li>E. your use of the Company Services does not violate any applicable law or regulation.
      </li>
    </ul>
    <p>You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the
      Website’s registration form and (b) maintain and promptly update registration data to keep it true, accurate,
      current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or
      Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete,
      Company has the right to suspend or terminate your account and refuse any and all current or future use of the
      Website (or any portion thereof).
    </p><br>
    <h6>Regarding Content You Provide
    </h6>
    <p>The Website may invite you to chat or participate in blogs, message boards, online forums and other functionality
      and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute
      or broadcast content and materials to Company and/or to or via the Website, including, without limitation, text,
      writings, video, audio, photographs, graphics, comments, suggestions or personally identifiable information or
      other material (collectively "Contributions"). Any Contributions you transmit to Company will be treated as
      non-confidential and non-proprietary. When you create or make available a Contribution, you thereby represent and
      warrant that:
    </p><br>
    <p>We may use hyperlinks on the Website which will redirect you to a social network if you click on the respective
      link. However, when you click on a social plug-in, such as Facebook’s “Like” button, Twitter’s “tweet” button or
      the Google+, that particular social network’s plugin will be activated and your browser will directly connect to
      that provider’s servers. If you do not use these buttons, none of your data will be sent to the respective social
      network’s plugin provider. So for example, when you click on the Facebook’s “Like” button on the Website, Facebook
      will receive your IP address, the browser version and screen resolution, and the operating system of the device
      you have used to access the Website. Settings regarding privacy protection can be found on the websites of these
      social networks and are not within our control.
    </p>
    <ul>
      <li>A. the creation, distribution, transmission, public display and performance, accessing, downloading and
        copying of your Contribution does not and will not infringe the proprietary rights, including but not limited to
        the copyright, patent, trademark, trade secret or moral rights of any third party;
      </li>
      <li>B. you are the creator and owner of or have the necessary licenses, rights, consents, releases and permissions
        to use and to authorize Company and the Website users to use your Contributions as necessary to exercise the
        licenses granted by you under this Agreement;
      </li>
      <li>C. you have the written consent, release, and/or permission of each and every identifiable individual person
        in the Contribution to use the name or likeness of each and every such identifiable individual person to enable
        inclusion and use of the Contribution in the manner contemplated by this Website;
      </li>
      <li>D. your Contribution is not obscene, lewd, lascivious, filthy, violent, harassing or otherwise objectionable
        (as determined by Company), libelous or slanderous, does not ridicule, mock, disparage, intimidate or abuse
        anyone, does not advocate the violent overthrow of any government, does not incite, encourage or threaten
        physical harm against another, does not violate any applicable law, regulation, or rule, and does not violate
        the privacy or publicity rights of any third party;
      </li>
      <li>E. your Contribution does not contain material that solicits personal information from anyone under 18 or
        exploit people under the age of 18 in a sexual or violent manner, and does not violate any federal or state law
        concerning child pornography or otherwise intended to protect the health or well-being of minors;
      </li>
      <li>F. your Contribution does not include any offensive comments that are connected to race, national origin,
        gender, sexual preference or physical handicap;
      </li>
      <li>G. your Contribution does not otherwise violate, or link to material that violates, any provision of this
        Agreement or any applicable law or regulation.
      </li>
    </ul>
    <h5>CONTRIBUTION LICENSE
    </h5>
    <p>By posting Contributions to any part of the Website, or making them accessible to the Website by linking your
      account to any of your social network accounts, you automatically grant, and you represent and warrant that you
      have the right to grant, to Company an unrestricted, unconditional, unlimited, irrevocable, perpetual,
      non-exclusive, transferable, royalty-free, fully-paid, worldwide right and license to host, use, copy, reproduce,
      disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display,
      reformat, translate, transmit, excerpt (in whole or in part) and distribute such Contributions (including, without
      limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative
      works of, or incorporate into other works, such Contributions, and to grant and authorize sublicenses of the
      foregoing. The use and distribution may occur in any media formats and through any media channels. Such use and
      distribution license will apply to any form, media, or technology now known or hereafter developed, and includes
      our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks,
      trade names and logos, personal and commercial images you provide. Company does not assert any ownership over your
      Contributions; rather, as between us and you, subject to the rights granted to us in this Agreement, you retain
      full ownership of all of your Contributions and any intellectual property rights or other proprietary rights
      associated with your Contributions.
    </p><br>
    <p>Company has the right, in our sole and absolute discretion, to (i) edit, redact or otherwise change any
      Contributions, (ii) re-categorize any Contributions to place them in more appropriate locations or (iii)
      pre-screen or delete any Contributions that are determined to be inappropriate or otherwise in violation of this
      Agreement.
    </p><br>
    <p>By uploading your Contributions to the Website, you hereby authorize Company to grant to each end user a
      personal, limited, no-transferable, perpetual, non-exclusive, royalty-free, fully-paid license to access,
      download, print and otherwise use your Contributions for t heir internal purposes and not for distribution,
      transfer, sale or commercial exploitation of any kind.
    </p>
    <h5>MOBILE APPLICATION LICENSE
    </h5>
    <h6>Use License
    </h6>
    <p>If you are accessing the Company Services via a mobile application, then Company grants you a revocable,
      non-exclusive, non-transferable, limited right to install and use the application on wireless handsets owned and
      controlled by you, and to access and use the application on such devices strictly in accordance with the terms and
      conditions of this license. You shall use the application strictly in accordance with the terms of this license
      and shall not: (a) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the
      application; (b) make any modification, adaptation, improvement, enhancement, translation or derivative work from
      the application; (c) violate any applicable laws, rules or regulations in connection with your access or use of
      the application; (d) remove, alter or obscure any proprietary notice (including any notice of copyright or
      trademark) of Company or its affiliates, partners, suppliers or the licensors of the application; (e) use the
      application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not
      designed or intended; (f) make the application available over a network or other environment permitting access or
      use by multiple devices or users at the same time; (g) use the application for creating a product, service or
      software that is, directly or indirectly, competitive with or in any way a substitute for the application; (h) use
      the application to send automated queries to any website or to send any unsolicited commercial e-mail; or (i) use
      any proprietary information or interfaces of Company or other intellectual property of Company in the design,
      development, manufacture, licensing or distribution of any applications, accessories or devices for use with the
      application.
    </p>
    <h6>Terms Applicable to Apple and Android Devices
    </h6>
    <p>The following terms apply when you use a mobile application obtained from either the Apple Store or Google Play
      to access the Company Services. You acknowledge that this Agreement is concluded between you and Company only, and
      not with Apple Inc. or Google, Inc. (each an “App Distributor”), and Company, not an App Distributor, is solely
      responsible for the Company application and the content thereof. (1) SCOPE OF LICENSE: The license granted to you
      for the Company application is limited to a non-transferable license to use the Company application on a device
      that utilizes the Apple iOS or Android operating system, as applicable, and in accordance with the usage rules set
      forth in the applicable App Distributor terms of service. (2) MAINTENANCE AND SUPPORT: Company is solely
      responsible for providing any maintenance and support services with respect to the Company application, as
      specified in this Agreement, or as required under applicable law. You acknowledge that each App Distributor has no
      obligation whatsoever to furnish any maintenance and support services with respect to the Company application. (3)
      WARRANTY: Company is solely responsible for any product warranties, whether express or implied by law, to the
      extent not effectively disclaimed. In the event of any failure of the Company application to conform to any
      applicable warranty, you may notify an App Distributor, and the App Distributor, in accordance with its terms and
      policies, may refund the purchase price, if any, paid for the Company application, and to the maximum extent
      permitted by applicable law, an App Distributor will have no other warranty obligation whatsoever with respect to
      the Company application, and any other claims, losses, liabilities, damages, costs or expenses attributable to any
      failure to conform to any warranty will be Company’s sole responsibility. (4) PRODUCT CLAIMS: You acknowledge that
      Company, not an App Distributor, is responsible for addressing any claims of yours or any third party relating to
      the Company application or your possession and/or use of the Company application, including, but not limited to:
      (i) product liability claims; (ii) any claim that the Company application fails to conform to any applicable legal
      or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. (5)
      INTELLECTUAL PROPERTY RIGHTS: You acknowledge that, in the event of any third party claim that the Company
      application or your possession and use of the Company application infringes a third party’s intellectual property
      rights, the App Distributor will not be responsible for the investigation, defense, settlement and discharge of
      any such intellectual property infringement claim. (6) LEGAL COMPLIANCE: You represent and warrant that (i) you
      are not located in a country that is subject to a U.S. government embargo, or that has been designated by the U.S.
      government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. government list of
      prohibited or restricted parties. (7) THIRD PARTY TERMS OF AGREEMENT: You must comply with applicable third party
      terms of agreement when using the Company application, e.g., if you have a VoIP application, then you must not be
      in violation of their wireless data service agreement when using the Company application. (8) THIRD PARTY
      BENEFICIARY: Company and you acknowledge and agree that the App Distributors, and their subsidiaries, are third
      party beneficiaries of this Agreement, and that, upon your acceptance of the terms and conditions of this
      Agreement, each App Distributor will have the right (and will be deemed to have accepted the right) to enforce
      this Agreement against you as a third party beneficiary thereof.
    </p>
    <h5>SOCIAL MEDIA
    </h5>
    <p>As part of the functionality of the Website, you may link your account with online accounts you may have with
      third party service providers (each such account, a “Third Party Account”) by either: (i) providing your Third
      Party Account login information through the Website; or (ii) allowing Company to access your Third Party Account,
      as is permitted under the applicable terms and conditions that govern your use of each Third Party Account. You
      represent that you are entitled to disclose your Third Party Account login information to Company and/or grant
      Company access to your Third Party Account (including, but not limited to, for use for the purposes described
      herein), without breach by you of any of the terms and conditions that govern your use of the applicable Third
      Party Account and without obligating Company to pay any fees or making Company subject to any usage limitations
      imposed by such third party service providers. By granting Company access to any Third Party Accounts, you
      understand that (i) Company may access, make available and store (if applicable) any content that you have
      provided to and stored in your Third Party Account (the “Social Network Content”) so that it is available on and
      through the Website via your account, including without limitation any friend lists, and (ii) Company may submit
      and receive additional information to your Third Party Account to the extent you are notified when you link your
      account with the Third Party Account. Depending on the Third Party Accounts you choose and subject to the privacy
      settings that you have set in such Third Party Accounts, personally identifiable information that you post to your
      Third Party Accounts may be available on and through your account on the Website. Please note that if a Third
      Party Account or associated service becomes unavailable or Company’s access to such Third Party Account is
      terminated by the third party service provider, then Social Network Content may no longer be available on and
      through the Website. You will have the ability to disable the connection between your account on the Website and
      your Third Party Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD PARTY SERVICE PROVIDERS
      ASSOCIATED WITH YOUR THIRD PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD PARTY SERVICE
      PROVIDERS. Company makes no effort to review any Social Network Content for any purpose, including but not limited
      to, for accuracy, legality or non-infringement, and Company is not responsible for any Social Network Content. You
      acknowledge and agree that Company may access your e-mail address book associated with a Third Party Account and
      your contacts list stored on your mobile device or tablet computer solely for the purposes of identifying and
      informing you of those contacts who have also registered to use the Website. At your request made via email to our
      email address listed below, or through your account settings (if applicable), Company will deactivate the
      connection between the Website and your Third Party Account and delete any information stored on Company’s servers
      that was obtained through such Third Party Account, except the username and profile picture that become associated
      with your account.
    </p>
    <h5>SUBMISSIONS</h5>
    <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about
      the Website or the Company Services ("Submissions") provided by you to Company are non-confidential and Company
      (as well as any designee of Company) shall be entitled to the unrestricted use and dissemination of these
      Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.
    </p>
    <h5>PROHIBITED ACTIVITIES</h5>
    <p>You may not access or use the Website for any other purpose other than that for which Company makes it available.
      The Website may not be used in connection with any commercial endeavors except those that are specifically
      endorsed or approved by Company. Prohibited activity includes, but is not limited to:
    </p>
    <ul>
      <li>A. attempting to bypass any measures of the Website designed to prevent or restrict access to the Website, or
        any portion of the Website
      </li>
      <li>B. attempting to impersonate another user or person or using the username of another user
      </li>
      <li>C. criminal or tortious activity
      </li>
      <li>D. deciphering, decompiling, disassembling or reverse engineering any of the software comprising or in any way
        making up a part of the Website
      </li>
      <li>E. deleting the copyright or other proprietary rights notice from any Website content
      </li>
      <li>F. engaging in any automated use of the system, such as using any data mining, robots or similar data
        gathering and extraction tools
      </li>
      <li>G. except as may be the result of standard search engine or Internet browser usage, using or launching,
        developing or distributing any automated system, including, without limitation, any spider, robot (or "bot"),
        cheat utility, scraper or offline reader that accesses the Website, or using or launching any unauthorized
        script or other software
      </li>
      <li>H. harassing, annoying, intimidating or threatening any Company employees or agents engaged in providing any
        portion of the Company Services to you
      </li>
      <li>J. making any unauthorized use of the Company Services, including collecting usernames and/or email addresses
        of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by
        automated means or under false pretenses
      </li>
      <li>K. systematic retrieval of data or other content from the Website to create or compile, directly or
        indirectly, a collection, compilation, database or directory without written permission from Company
      </li>
      <li>L. tricking, defrauding or misleading Company and other users, especially in any attempt to learn sensitive
        account information such as passwords
      </li>
      <li>M. using any information obtained from the Website in order to harass, abuse, or harm another person
      </li>
      <li>N. using the Company Services as part of any effort to compete with Company or to provide services as a
        service bureau
      </li>
      <li>O. using the Website in a manner inconsistent with any and all applicable laws and regulations
      </li>
    </ul>
    <h5>INTELLECTUAL PROPERTY RIGHTS
    </h5>
    <p>The content on the Website (“Company Content”) and the trademarks, service marks and logos contained therein
      (“Marks”) are owned by or licensed to Company, and are subject to copyright and other intellectual property rights
      under United States and foreign laws and international conventions. Company Content, includes, without limitation,
      all source code, databases, functionality, software, website designs, audio, video, text, photographs and
      graphics. All Company graphics, logos, designs, page headers, button icons, scripts and service names are
      registered trademarks, common law trademarks or trade dress of Company in the United States and/or other
      countries. Company's trademarks and trade dress may not be used, including as part of trademarks and/or as part of
      domain names, in connection with any product or service in any manner that is likely to cause confusion and may
      not be copied, imitated, or used, in whole or in part, without the prior written permission of the Company.
    </p>
    <p>Company Content on the Website is provided to you “AS IS” for your information and personal use only and may not
      be used, copied, reproduced, aggregated, distributed, transmitted, broadcast, displayed, sold, licensed, or
      otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners.
      Provided that you are eligible to use the Website, you are granted a limited license to access and use the Website
      and the Company Content and to download or print a copy of any portion of the Company Content to which you have
      properly gained access solely for your personal, non-commercial use. Company reserves all rights not expressly
      granted to you in and to the Website and Company Content and Marks.
    </p>
    <h5>THIRD PARTY WEBSITES AND CONTENT
    </h5>
    <p>The Website contains (or you may be sent through the Website or the Company Services) links to other websites
      ("Third Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video,
      information, applications, software and other content or items belonging to or originating from third parties (the
      "Third Party Content"). Such Third Party Websites and Third Party Content are not investigated, monitored or
      checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third Party
      Websites accessed through the Website or any Third Party Content posted on, available through or installed from
      the Website, including the content, accuracy, offensiveness, opinions, reliability, privacy practices or other
      policies of or contained in the Third Party Websites or the Third Party Content. Inclusion of, linking to or
      permitting the use or installation of any Third Party Website or any Third Party Content does not imply approval
      or endorsement thereof by us. If you decide to leave the Website and access the Third Party Websites or to use or
      install any Third Party Content, you do so at your own risk and you should be aware that our terms and policies no
      longer govern. You should review the applicable terms and policies, including privacy and data gathering
      practices, of any website to which you navigate from the Website or relating to any applications you use or
      install from the Website. Any purchases you make through Third Party Websites will be through other websites and
      from other companies, and Company takes no responsibility whatsoever in relation to such purchases which are
      exclusively between you and the applicable third party.
    </p>
    <h5>SITE MANAGEMENT
    </h5>
    <p>Company reserves the right but does not have the obligation to:
    </p>
    <ul>
      <li>A. monitor the Website for violations of this Agreement;
      </li>
      <li>B. take appropriate legal action against anyone who, in Company’s sole discretion, violates this Agreement,
        including without limitation, reporting such user to law enforcement authorities;
      </li>
      <li>C. in Company’s sole discretion and without limitation, refuse, restrict access to or availability of, or
        disable (to the extent technologically feasible) any user’s contribution or any portion thereof that may violate
        this Agreement or any Company policy;
      </li>
      <li>D. in Company’s sole discretion and without limitation, notice or liability to remove from the Website or
        otherwise disable all files and content that are excessive in size or are in any way burdensome to Company’s
        systems;
      </li>
      <li>E. otherwise manage the Website in a manner designed to protect the rights and property of Company and others
        and to facilitate the proper functioning of the Website.
      </li>
    </ul>
    <h5>PRIVACY POLICY
    </h5>
    <p>We care about the privacy of our users. Please review the Company Privacy Policy. By using the Website or Company
      Services, you are consenting to have your personal data transferred to and processed in the United States. By
      using the Website or the Company Services, you are consenting to the terms of our Privacy Policy.
    </p>
    <h5>TERM AND TERMINATION</h5>
    <p>This Agreement shall remain in full force and effect while you use the Website or are otherwise a user or member
      of the Website, as applicable. You may terminate your use or participation at any time, for any reason, by
      following the instructions for terminating user accounts in your account settings, if available, or by contacting
      us using the contact information below.
    </p><br>
    <p>WITHOUT LIMITING ANY OTHER PROVISION OF THIS AGREEMENT, COMPANY RESERVES THE RIGHT TO, IN COMPANY’S SOLE
      DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE WEBSITE AND THE COMPANY SERVICES, TO ANY
      PERSON FOR ANY REASON OR FOR NO REASON AT ALL, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
      WARRANTY OR COVENANT CONTAINED IN THIS AGREEMENT, OR OF ANY APPLICABLE LAW OR REGULATION, AND COMPANY MAY
      TERMINATE YOUR USE OR PARTICIPATION IN THE WEBSITE AND THE COMPANY SERVICES, DELETE YOUR PROFILE AND ANY CONTENT
      OR INFORMATION THAT YOU HAVE POSTED AT ANY TIME, WITHOUT WARNING, IN COMPANY’S SOLE DISCRETION.
    </p><br>
    <p>In order to protect the integrity of the Website and Company Services, Company reserves the right at any time in
      its sole discretion to block certain IP addresses from accessing the Website and Company Services.
    </p><br>
    <p>Any provisions of this Agreement that, in order to fulfill the purposes of such provisions, need to survive the
      termination or expiration of this Agreement, shall be deemed to survive for as long as necessary to fulfill such
      purposes.
    </p><br>
    <p>YOU UNDERSTAND THAT CERTAIN STATES ALLOW YOU TO CANCEL THIS AGREEMENT, WITHOUT ANY PENALTY OR OBLIGATION, AT ANY
      TIME PRIOR TO MIDNIGHT OF COMPANY’S THIRD BUSINESS DAY FOLLOWING THE DATE OF THIS AGREEMENT, EXCLUDING SUNDAYS AND
      HOLIDAYS. TO CANCEL, CALL A COMPANY CUSTOMER CARE REPRESENTATIVE DURING NORMAL BUSINESS HOURS USING THE CONTACT
      INFORMATION LISTING BELOW IN THIS AGREEMENT OR BY ACCESSING YOUR ACCOUNT SETTINGS. THIS SECTION APPLIES ONLY TO
      INDIVIDUALS RESIDING IN STATES WITH SUCH LAWS.
    </p><br>
    <p>If Company terminates or suspends your account for any reason, you are prohibited from registering and creating a
      new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on
      behalf of the third party. In addition to terminating or suspending your account, Company reserves the right to
      take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
    </p>
    <h5>MODIFICATIONS
    </h5>
    <h6>To Agreement
    </h6>
    <p>Company may modify this Agreement from time to time. Any and all changes to this Agreement will be posted on the
      Website and revisions will be indicated by date. You agree to be bound to any changes to this Agreement when you
      use the Company Services after any such modification becomes effective. Company may also, in its discretion,
      choose to alert all users with whom it maintains email information of such modifications by means of an email to
      their most recently provided email address. It is therefore important that you regularly review this Agreement and
      keep your contact information current in your account settings to ensure you are informed of changes. You agree
      that you will periodically check the Website for updates to this Agreement and you will read the messages we send
      you to inform you of any changes. Modifications to this Agreement shall be effective after posting.
    </p>
    <h6>To Services
    </h6>
    <h5>DISPUTES
    </h5>
    <h6>Between Users
    </h6>
    <p>If there is a dispute between users of the Website, or between users and any third party, you understand and
      agree that Company is under no obligation to become involved. In the event that you have a dispute with one or
      more other users, you hereby release Company, its officers, employees, agents and successors in rights from
      claims, demands and damages (actual and consequential) of every kind or nature, known or unknown, suspected and
      unsuspected, disclosed and undisclosed, arising out of or in any way related to such disputes and/or the Company
      Services.
    </p>
    <h6>With Company
    </h6>
    <p>All questions of law, rights, and remedies regarding any act, event or occurrence undertaken pursuant or relating
      to this Website or the Company Services shall be governed and construed by the laws of the State of California,
      excluding such state’s conflicts of law rules. Any legal action of whatever nature by or against Company arising
      out of or related in any respect to this Website and the Company Services shall be brought solely in either the
      applicable federal or state courts located in or with jurisdiction over Los Angeles County County, State of
      California; subject, however, to the right of Company, at the Company's sole discretion, to bring an action to
      seek injunctive relief to enforce this Agreement or to stop or prevent an infringement of proprietary or other
      third party rights (or any similar cause of action) in any applicable court in any jurisdiction where jurisdiction
      exists with regard to a user. You hereby consent to (and waive any challenge or objection to) personal
      jurisdiction and venue in the above-referenced courts. Application of the United Nations Convention on Contracts
      for the International Sale of Goods is excluded from this Agreement. Additionally, application of the Uniform
      Computer Information Transaction Act (UCITA) is excluded from this Agreement. In no event shall any claim, action
      or proceeding by you related in any way to the Website and/or the Company Services (including your visit to or use
      of the Website and/or the Company Services) be instituted more than two (2) years after the cause of action arose.
      You will be liable for any attorneys' fees and costs if we have to take any legal action to enforce this
      Agreement.
    </p>
    <h5>CORRECTIONS
    </h5>
    <p>Occasionally there may be information on the Website that contains typographical errors, inaccuracies or
      omissions that may relate to service descriptions, pricing, availability, and various other information. Company
      reserves the right to correct any errors, inaccuracies or omissions and to change or update the information at any
      time, without prior notice.
    </p>
    <h5>DISCLAIMERS
    </h5>
    <p>Company cannot control the nature of all of the content available on the Website. By operating the Website,
      Company does not represent or imply that Company endorses any blogs, contributions or other content available on
      or linked to by the Website, including without limitation content hosted on third party websites or provided by
      third party applications, or that Company believes contributions, blogs or other content to be accurate, useful or
      non-harmful. We do not control and are not responsible for unlawful or otherwise objectionable content you may
      encounter on the Website or in connection with any contributions. The Company is not responsible for the conduct,
      whether online or offline, of any user of the Website or Company Services.
    </p><br>
    <p>YOU AGREE THAT YOUR USE OF THE WEBSITE AND COMPANY SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT
      PERMITTED BY LAW, COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR
      IMPLIED, IN CONNECTION WITH THE WEBSITE AND THE COMPANY SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
      LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
      COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE WEBSITE’S CONTENT OR THE
      CONTENT OF ANY WEBSITES LINKED TO THIS WEBSITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (A) ERRORS,
      MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
      WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITE, (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
      SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (D) ANY
      INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE OR COMPANY SERVICES, (E) ANY BUGS, VIRUSES,
      TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY THIRD PARTY, AND/OR (F) ANY
      ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
      USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE WEBSITE. COMPANY DOES NOT WARRANT,
      ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
      THROUGH THE WEBSITE OR ANY HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND COMPANY WILL
      NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
      PROVIDERS OF PRODUCTS OR SERVICES.
    </p>
    <h5>LIMITATIONS OF LIABILITY</h5>
    <p>IN NO EVENT SHALL COMPANY OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
      DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST
      REVENUE, LOSS OF DATA OR OTHER DAMAGES ARISING FROM YOUR USE OF THE WEBSITE OR COMPANY SERVICES, EVEN IF COMPANY
      HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
      COMPANY’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
      LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO COMPANY FOR THE COMPANY SERVICES DURING THE PERIOD OF THREE (3)
      MONTHS PRIOR TO ANY CAUSE OF ACTION ARISING.
    </p>
    <p>CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
      DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
      YOU MAY HAVE ADDITIONAL RIGHTS.
    </p>
    <p>IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."
    </p>
    <h5>INDEMNITY
    </h5>
    <p>You agree to defend, indemnify and hold Company, its subsidiaries, and affiliates, and their respective officers,
      agents, partners and employees, harmless from and against, any loss, damage, liability, claim, or demand,
      including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of your
      contributed content, use of the Company Services, and/or arising from a breach of this Agreement and/or any breach
      of your representations and warranties set forth above. Notwithstanding the foregoing, Company reserves the right,
      at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify
      Company, and you agree to cooperate, at your expense, with Company’s defense of such claims. Company will use
      reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification
      upon becoming aware of it.
    </p>
    <h5>NOTICES
    </h5>
    <p>Except as explicitly stated otherwise, any notices given to Company shall be given by email to the address listed
      in the contact information below. Any notices given to you shall be given to the email address you provided during
      the registration process, or such other address as each party may specify. Notice shall be deemed to be given
      twenty-four (24) hours after the email is sent, unless the sending party is notified that the email address is
      invalid. We may also choose to send notices by regular mail.
    </p>
    <h5>USER DATA
    </h5>
    <p>Our Website will maintain certain data that you transfer to the Website for the purpose of the performance of the
      Company Services, as well as data relating to your use of the Company Services. Although we perform regular
      routine backups of data, you are primarily responsible for all data that you have transferred or that relates to
      any activity you have undertaken using the Company Services. You agree that Company shall have no liability to you
      for any loss or corruption of any such data, and you hereby waive any right of action against Company arising from
      any such loss or corruption of such data.
    </p>
    <h5>ELECTRONIC CONTRACTING
    </h5>
    <p>Your use of the Company Services includes the ability to enter into agreements and/or to make transactions
      electronically. YOU ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS CONSTITUTE YOUR AGREEMENT AND INTENT TO BE BOUND
      BY FOR SUCH AGREEMENTS AND TRANSACTIONS. YOUR AGREEMENT AND INTENT TO BE BOUND BY ELECTRONIC SUBMISSIONS APPLIES
      TO ALL RECORDS RELATING TO ALL TRANSACTIONS YOU ENTER INTO RELATING TO THE COMPANY SERVICES, INCLUDING NOTICES OF
      CANCELLATION, POLICIES, CONTRACTS, AND APPLICATIONS. In order to access and retain your electronic records, you
      may be required to have certain hardware and software, which are your sole responsibility.
    </p>
    <h5>ELECTRONIC SIGNATURES
    </h5>
    <p>Users are allowed on wita.space to transmit and receive valid electronic signatures in the United States under
      the Electronic Signatures in Global and National Commerce Act (E-Sign Act) of 2000 and the Uniform Electronic
      Transactions Act (UETA) of 1999 as adopted by individual states. Users’ signatures and identities are not
      authenticated on wita.space.
    </p>
    <h5>MISCELLANEOUS</h5>
    <p>This Agreement constitutes the entire agreement between you and Company regarding the use of the Company
      Services. The failure of Company to exercise or enforce any right or provision of this Agreement shall not operate
      as a waiver of such right or provision. The section titles in this Agreement are for convenience only and have no
      legal or contractual effect. This Agreement operates to the fullest extent permissible by law. This Agreement and
      your account may not be assigned by you without our express written consent. Company may assign any or all of its
      rights and obligations to others at any time. Company shall not be responsible or liable for any loss, damage,
      delay or failure to act caused by any cause beyond Company's reasonable control. If any provision or part of a
      provision of this Agreement is unlawful, void or unenforceable, that provision or part of the provision is deemed
      severable from this Agreement and does not affect the validity and enforceability of any remaining provisions.
      There is no joint venture, partnership, employment or agency relationship created between you and Company as a
      result of this Agreement or use of the Website and Company Services. Upon Company’s request, you will furnish
      Company any documentation, substantiation or releases necessary to verify your compliance with this Agreement. You
      agree that this Agreement will not be construed against Company by virtue of having drafted them. You hereby waive
      any and all defenses you may have based on the electronic form of this Agreement and the lack of signing by the
      parties hereto to execute this Agreement.
    </p>
    <h5>CONTACT US
    </h5>
    <p>In order to resolve a complaint regarding the Company Services or to receive further information regarding use of
      the Company Services, please contact Company as set forth below or, if any complaint with us is not satisfactorily
      resolved, and you are a California resident, you can contact the Complaint Assistance Unit of the Division of
      Consumer Services of the Department of Consumer Affairs in writing at 400 "R" Street, Sacramento, California 95814
      or by telephone at 1-916-445-1254.
    </p><br>

    <p>Andrei Kania</p>
    <p>Attn: Privacy Officer</p>
    <p>6301 Glade Avenue, K113</p>
    <p>Los Angeles, CA 91367</p>
    <p>Email: admin@wita.space</p>
    <p>Phone: 8138633474</p>
  </div>
</ion-content>
