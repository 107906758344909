import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PasswordResetService} from '../../../shared/services/password-reset.service';
import {Globals} from '../../../shared/globals';
import {throwError} from 'rxjs';
import {Router} from '@angular/router';
import {finalize, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css', './../authentication.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  fpForm: FormGroup;
  isCheckingEmail = false;
  errorMsg = null;

  constructor(
    private formBuilder: FormBuilder,
    private passwordResetService: PasswordResetService,
    public router: Router,
    public globals: Globals) {
    this.fpForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
  }

  restorePassword() {
    this.isCheckingEmail = true;
    this.passwordResetService.checkRegisterEmail(this.fpForm.value.email)
      .pipe(
        switchMap((data: any) => {
          if (data !== null) {
            return this.passwordResetService.sendResetEmail(this.fpForm.value.email);
          } else {
            return throwError(data);
          }
        }),
        finalize(() => this.isCheckingEmail = false)).subscribe(res => {
          this.router.navigateByUrl('/reset-send');
      }, err => {
        this.errorMsg = `Cant find user with email ${this.fpForm.value.email}`;
      });
  }
}
