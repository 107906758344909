<ng-container>
  <ion-slides tappable
              [options]="rootSliderOptions"
              [class.category__slider-txt]="getSliderTextClass()">

    <ion-slide class="category__slider fake"></ion-slide>

    <ion-slide class="category__slider"
               *ngFor="let category of levelData.categories; let i = index">
      <div class="category__slider-item"
           [class.category__has_label]="category.label !== null"
           [class.category__private]="category.accessType === 3"
           [class.category__mod]="category.accessType === 2"
           (click)="onClickHandler(category)"
      >
        <div class="category__slider-icon"
             *ngIf="levelData.showIconsInSlider"
        >
          <img [src]="category | CategoryIcon"/>
          <img class="inverted" [src]="category | CategoryIcon: true"/>
        </div>
        <p class="category__slider-title">{{categoryService.getTranslationForTitle(category)}}</p>
        <span class="notes-number" *ngIf="!levelData.showIconsInSlider && category.numberOfNotes > 0 && (globals.feed.feedType === feedService.getFeedType().HOME || globals.feed.feedType === feedService.getFeedType().USER)">{{category.numberOfNotes}}</span>
      </div>
    </ion-slide>

    <ion-slide class="category__slider fake"></ion-slide>

  </ion-slides>
</ng-container>
