import {Component, OnInit, ViewChild} from '@angular/core';
import {SiteUser} from "@app/modules/site-user/site-user";
import {LanguagesList} from "@app/core/languages";
import {Observable, of, Subscription} from "rxjs";
import {MenuItem} from "@app/shared/models/menu/MenuItem";
import {NewFeed} from "@app/modules/home/NewFeed";
import {HttpClient} from "@angular/common/http";
import {Globals} from "@app/shared/globals";
import {ActivatedRoute, Router} from "@angular/router";
import {SiteUserService} from "@app/shared/services/site-user.service";
import {NoteService} from "@app/shared/services/note.service";
import {EditorsService} from "@app/shared/services/editors.service";
import {Events, IonContent, IonSlides, PopoverController} from "@ionic/angular";
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";
import {SubscriptionService} from "@app/shared/services/subscription.service";
import {Page} from "@app/core/page";
import {Note} from "@app/modules/note/note";
import {NoteListPage} from "@app/modules/home/NoteListPage";
import {UpdateNavigation} from "@app/shared/models/UpdateNavigation";
import {BdcWalkService} from "bdc-walkthrough";

@Component({
  selector: 'app-site-user-feed',
  templateUrl: './site-user-feed.component.html',
  styleUrls: ['./site-user-feed.component.css']
})
export class SiteUserFeedComponent extends NoteListPage implements UpdateNavigation {

  @ViewChild('profileHeader', {static: false}) profileHeader;
  @ViewChild(IonContent, {static: false}) content: IonContent;

  popover;
  languageList = LanguagesList;
  popoverOpened = false;

  private userFeedSubscription: Subscription;

  private userFeedByCategorySubscription: Subscription;



  readonly headerActions: MenuItem[] = [
    {
      title: 'Category tree',
      icon: 'assets/icons/design_v4/category_path.svg',
      action: () => {
        this.routeCategories();
      }
    },
    {
      title: 'Notifications',
      path: '/notifications',
      icon: 'assets/icons/design_v4/notifications-outline.svg',
      action: () => {
        // this.globals.feed.closeFeed();
      },
    },
    {
      icon: 'assets/icons/design_v4/filter.svg',
      title: 'Filter',
      action: this.onToggleNoteFilters.bind(this),
    }
  ];

  areNotesLoaded;

  constructor(
    public http: HttpClient,
    public globals: Globals,
    public activeRoute: ActivatedRoute,
    public userService: SiteUserService,
    public noteService: NoteService,
    public editorsService: EditorsService,
    public router: Router,
    public popoverController: PopoverController,
    public toolbarService: ToolbarService,
    public subscriptionService: SubscriptionService,
    public events: Events,
    public bdcWalkService: BdcWalkService
  ) {
    super(http, globals, activeRoute, userService, noteService, editorsService,
      router, popoverController, toolbarService, subscriptionService, events, bdcWalkService);
    this.feeds = of([
      this.userFeed
    ]);
  }


  ionViewWillEnter() {
    console.log(" ionViewWillEnter ");
    let params;
    this.activeRoute.params.subscribe((response: any) => params = response);
    if (params && params.id) {
      this.http.get(`${this.globals.apiUrl}/users/` + params.id)
        .subscribe((user: SiteUser) => {
          if (user !== null) {
            this.userService.setSelectedUser(user);
            this.updateNotesAndHeader(true, this.globals.selectedUser.id);
          }
        });
    }
  }

  scrollUp() {
    this.content.scrollToTop();
  }

  ionViewDidLeave() {
    console.log(" ionViewDidLeave ");

  }


  ionViewDidEnter(): void {
    console.log(" ionViewDidEnter ");
    if (this.globals.firstLoad) {
      if (this.globals.isEditor || this.globals.isAdmin) {
        this.userService.updateLocation();
      }
    } else {
      this.toolbarService.refreshMenuItems();
    }
  }

  updateNotesAndHeader(areNotesLoaded: boolean, userId: number, event?) {
    console.log(" updateNotesAndHeader ");
    this.profileHeader.init(userId);
    this.areNotesLoaded = areNotesLoaded;
    this.globals.needPageRefresh = true;
    this.dispatchHomeContentRefreshRequest(0, true, event);
  }


  private dispatchHomeContentRefreshRequest(page: number, clearNotes: boolean, event?) {
    console.log(" dispatchHomeContentRefreshRequest ");
    if (this.globals.activeFilters.categoryFilter.isEmpty()) {

      this.userFeedSubscription = this.noteService.getUserAllNotes(page)
        .subscribe((data: Page<Note>) => {
          this.finishNotesRefresh(data, clearNotes, event);
        });
    } else {

      this.userFeedByCategorySubscription = this.noteService.getUserNoteListByCategory(this.globals.currentCategoryId, page)
        .subscribe((data: Page<Note>) => {
          this.finishNotesRefresh(data, clearNotes, event);
        });
    }

  }


  private finishNotesRefresh(response: Page<Note>, clearNotes: boolean, event?) {
    console.log(" finishNotesRefresh ");
    this.globals.noteList = clearNotes ? response.content || [] : this.globals.noteList.concat(response.content);
    response.content = this.globals.noteList;
    this.globals.needPageRefresh = false;
    this.areNotesLoaded = true;
    this.userFeed.paginator = this.setPaginatorFields(response, this.userFeed.paginator);

    if (event) {
      this.completeEffects();
      // event.target.complete();
    }
  }


  loadDataForPagination(event) {
    console.log(" loadDataForPagination ");
    this.globals.needPageRefresh = true;
    if (this.userFeed.paginator.currentPage < this.userFeed.paginator.lastPage) {
      this.dispatchHomeContentRefreshRequest(++this.userFeed.paginator.currentPage, false, event);
    } else {
      if (event) {
        event.target.complete();
      }
    }
  }

  completeEffects() {
    if (this.refresherEffect) {
      this.refresherEffect.target.complete();
    }
    if (this.paginationEffect) {
      this.paginationEffect.target.complete();
    }
  }


}
