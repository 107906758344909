var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { MenuService } from '@app/shared/services/menu/menu.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SiteUser } from '@app/modules/site-user/site-user';
import { Globals } from '@app/shared/globals';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { Router } from '@angular/router';
import { TokensService } from '@app/shared/services/tokens.service';
import { Storage } from '@ionic/storage';
import { ProfileService } from '@app/shared/services/profile.service';
import { UiAlertService } from '@app/shared/services/ui-alert-service';
import { OneSignalService } from '@app/shared/services/one-signal.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@app/shared/services/menu/menu.service";
import * as i3 from "@app/shared/services/toolbar/toolbar.service";
import * as i4 from "@app/core/auth/authentication.service";
import * as i5 from "@app/shared/globals";
import * as i6 from "@angular/common/http";
import * as i7 from "@angular/router";
import * as i8 from "@app/shared/services/ui-alert-service";
import * as i9 from "@app/shared/services/tokens.service";
import * as i10 from "@ionic/storage";
import * as i11 from "@app/shared/services/profile.service";
import * as i12 from "@app/shared/services/one-signal.service";
export class AppleAuthService {
    constructor(platform, menuService, toolbarService, authenticationService, loadingController, globals, http, router, uiAlertService, tokensService, storage, profileService, oneSignalService) {
        this.platform = platform;
        this.menuService = menuService;
        this.toolbarService = toolbarService;
        this.authenticationService = authenticationService;
        this.loadingController = loadingController;
        this.globals = globals;
        this.http = http;
        this.router = router;
        this.uiAlertService = uiAlertService;
        this.tokensService = tokensService;
        this.storage = storage;
        this.profileService = profileService;
        this.oneSignalService = oneSignalService;
    }
    authByApple(siteUser, needRegister) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({});
            yield this.loading.present();
            let params = new HttpParams();
            params = params.append('currentLanguage', this.globals.currentLanguage);
            params = params.append('needRegister', needRegister);
            params = params.append('deviceId', this.oneSignalService.getDeviceId());
            return this.http.post(this.globals.apiUrl + '/accounts/login/social', siteUser, { params })
                .toPromise()
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                if (response !== null) {
                    this.tokensService.setTokens(response.witaAuthToken);
                    localStorage.setItem('wita-user', JSON.stringify(response));
                    this.storage.set('wita-user', response);
                    this.globals.owner = response;
                    this.globals.owner.provider = 'apple';
                    this.profileService.setReferrer(this.globals.owner, this.referrer);
                    this.router.navigate(['/']);
                    this.authenticationService.afterLoginInitiation();
                    this.toolbarService.setHeaderVisibility(true);
                    yield this.menuService.activeMainMenu();
                    this.loading.dismiss();
                }
            })).catch((er) => __awaiter(this, void 0, void 0, function* () {
                console.log('throw error after social login request inside apple-auth service');
                throw er;
            }));
        });
    }
    accessWithAppleID(referrer, needRegister) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.platform.is('cordova')) {
                window.cordova.plugins.SignInWithApple.signin({ requestedScopes: [0, 1] }, (res) => __awaiter(this, void 0, void 0, function* () {
                    const siteUser = new SiteUser({
                        firstName: res.fullName.givenName,
                        lastName: res.fullName.familyName,
                        ssoId: res.user,
                        email: res.email,
                        userPhotoName: ''
                    });
                    this.referrer = referrer;
                    this.authByApple(siteUser, needRegister);
                }), (er) => __awaiter(this, void 0, void 0, function* () {
                    yield this.authenticationService.checkConfirmationRequired(er);
                }));
            }
        });
    }
    errorLoginAlert() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.uiAlertService.createAlert('Error', 'Error logging with AppleID');
        });
    }
}
AppleAuthService.ɵfac = function AppleAuthService_Factory(t) { return new (t || AppleAuthService)(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.MenuService), i0.ɵɵinject(i3.ToolbarService), i0.ɵɵinject(i4.AuthenticationService), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i5.Globals), i0.ɵɵinject(i6.HttpClient), i0.ɵɵinject(i7.Router), i0.ɵɵinject(i8.UiAlertService), i0.ɵɵinject(i9.TokensService), i0.ɵɵinject(i10.Storage), i0.ɵɵinject(i11.ProfileService), i0.ɵɵinject(i12.OneSignalService)); };
AppleAuthService.ɵprov = i0.ɵɵdefineInjectable({ token: AppleAuthService, factory: AppleAuthService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppleAuthService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.Platform }, { type: i2.MenuService }, { type: i3.ToolbarService }, { type: i4.AuthenticationService }, { type: i1.LoadingController }, { type: i5.Globals }, { type: i6.HttpClient }, { type: i7.Router }, { type: i8.UiAlertService }, { type: i9.TokensService }, { type: i10.Storage }, { type: i11.ProfileService }, { type: i12.OneSignalService }]; }, null); })();
