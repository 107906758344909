import {Component} from '@angular/core';
import {Globals} from '@app/shared/globals';
import {CategoryService} from '@app/shared/services/category.service';
import {Level} from '../category-level/Level';
import {CategorySliderComponent} from '../category-slider/category-slider.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Category} from '@app/modules/category/category';
import {CategoryListComponent} from '@app/modules/category-list/category-list.component';
import {CategoryChanged} from '@app/modules/category-slider/CategoryChanged';
import {SharedService} from '@app/shared/services/shared.service';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {EDIT_NOTE_ACTION} from '@app/shared/actions';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {LangPickerDropDownComponent} from '@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component';
import {LanguagesList} from '@app/core/languages';
import {LoadingController, ModalController, PopoverController} from '@ionic/angular';
import {DropDownListComponent} from '@app/shared/components/drop-down-list/drop-down-list.component';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {CategoryTranslation} from '@app/core/categoryTranslation';
import {TranslateService} from '@ngx-translate/core';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {LabelComponent} from "@app/modules/label/label.component";
import {LabelService} from "@app/shared/services/label.service";
import {Label} from "@app/modules/label/label";
import {PlaceComponent} from "@app/modules/place/place.component";
import {GeoService} from "@app/shared/services/geo.service";
import {Place} from "@app/modules/place/place";

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss'],
})
export class CategoryTreeComponent implements UpdateNavigation {
  readonly MAX_NESTED_LEVEL = 4;

  public rootLevel: Level;

  public categoriesLevels: Level[] = [];

  protected rootActiveCategoryId: number = null;
  protected categoryHierarchy: number[] = [];

  public isVisibleAddNewCategoryBlock = false;

  public newCategoryName: string = null;

  public editCategory: Category;
  public editedCategoryTitle = '';

  protected movingCategory: Category = null;

  public canSave = false;

  previousLevelOfMovingCategory;
  editedNoteCategory: Category;

  languageList = LanguagesList;
  popover: any;

  public categoriesLoaded = false;
  private disabledNewButton = false;

  headerSearchbar: MenuItem = {
    title: 'searchbar',
    placeholder: 'Search category...',
    cancelButton: true,
    action: (env) => this.onSearch(env)
  };

  headerActionCategory: MenuItem[] = [

    {
      title: 'Edit',
      icon: 'assets/icons/design_v4/edit.svg',
      action: (env) => this.showEditMenu(env),
    },
  ];

  label = {
    title: '+ label',
    icon: 'assets/icons/label.svg',
    action: () => this.openLabelModal()
  };

  dropDownEditItems = {
    data: [
      {
        callback: () => {
          console.log('1');
          this.onToggleEdit();
        },
        name: 'Edit'
      },
      {
        callback: () => {
          console.log('2');
          this.onToggleRemove();
        },
        name: 'Remove'
      }
    ]
  };


  readonly CATEGORY_NAME = 'Category name';

  translationText: any;
  private selectedLabel: Label;
  private selectedPlace: Place;
  private currentCategory: Category;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public categoryService: CategoryService,
    public globals: Globals,
    public sharedService: SharedService,
    private popoverController: PopoverController,
    private toolbarService: ToolbarService,
    public translationService: TranslateService,
    private uiAlertService: UiAlertService,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private labelService: LabelService,
    private geoService: GeoService
  ) {
    const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;

    this.headerActionCategory.push({
      icon: `assets/icons/design_v4/langs/${lang.toLocaleLowerCase()}.svg`,
      title: lang,
      action: env => this.presentLanguageMenu(env)
    });
  }

  ionViewWillEnter() {
    this.updatePopUpTranslations();
    this.translationService.onLangChange.subscribe((event) => {
      this.updatePopUpTranslations();
    });
    this.categoriesLoaded = false;
    this.categoriesLevels = [];
    this.labelService.selectedLabel.subscribe(label => this.selectedLabel = label);
    this.geoService.selectedPlace.subscribe(place => this.selectedPlace = place);
    this.rootActiveCategoryId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    if (this.globals.action === EDIT_NOTE_ACTION) {
      this.rootActiveCategoryId = this.editedNoteCategory.id;
    }
    this.categoryService.uploadCategoryTree(this.globals.selectedUser.id, this.rootActiveCategoryId, () => {
      this.categoryService.initializeFlowerCategories();
      this.categoriesLoaded = true;

      const category = this.globals.allCategories.find((cat: Category) => cat.id === this.rootActiveCategoryId);
      if (!category) {
        this.router.navigateByUrl('/categories');
        return;
      }
      const isCategoryInRoot = this.globals.topCategoryList.findIndex((item: Category) => item.id === category.id);
      if (isCategoryInRoot < 0) {
        this.findCategoriesLevelsByCategoryId(category.id);
        return;
      }
      this.initializeRootLevel();

      if (category) {
        this.addNewListLevel(category.id, false);
      }
    });
  }

  ionViewDidLeave() {
    this.globals.isCategoryEditingMode = false;
    if (!this.globals.enabledSearch) {
      this.toolbarService.setSearchbar(null);
    }
  }

  updateNavigation(): void {
    if (this.globals.isCategoryEditingMode) {
      const done: MenuItem[] = [];

      this.toolbarService.setMenuItems(done);
      this.toolbarService.setSearchbar(null);
    } else {
      this.toolbarService.setMenuItems(this.headerActionCategory);
      this.toolbarService.setSearchbar(this.headerSearchbar);
    }

    if (this.globals.isRemovingMode) {
      const done: MenuItem[] = [];

      this.toolbarService.setMenuItems(done);
      this.toolbarService.setSearchbar(null);
    } else {
      this.toolbarService.setMenuItems(this.headerActionCategory);
      this.toolbarService.setSearchbar(this.headerSearchbar);
    }
  }

  onSearch(env) {
    this.router.navigate(['/categories']);
  }

  async presentLanguageMenu(env) {
    this.popover = await this.popoverController.create({
      component: LangPickerDropDownComponent,
      event: env,
      translucent: false,
      showBackdrop: false,
    });

    await this.popover.present();

    this.popover.onDidDismiss().then(e => {
        if (!e.data) {
          return;
        }

        this.headerActionCategory[this.headerActionCategory.length - 1].icon = `assets/icons/design_v4/langs/${e.data.name.toLocaleLowerCase()}.svg`;
        this.headerActionCategory[this.headerActionCategory.length - 1].title = e.data.name;
      }
    );
  }

  async showEditMenu(env) {
    const modal = await this.popoverController.create({
      component: DropDownListComponent,
      componentProps: this.dropDownEditItems,
      event: env,
      translucent: false
    });

    await modal.present();
  }

  initializeRootLevel() {
    this.rootLevel = new Level(CategorySliderComponent, {
      categories: this.globals.topCategoryList,
      showIconsInSlider: true,
      activeCategoryId: this.rootActiveCategoryId,
    });
  }

  onToggleNoteFilters(): void {
    console.log('onToggleNoteFilters');

  }

  async onToggleEdit() {
    this.globals.isCategoryEditingMode = !this.globals.isCategoryEditingMode;
    const category = this.getCurrentActiveCategory();
    if (!this.canEdit(category)) {
      this.globals.isCategoryEditingMode = false;
      await this.uiAlertService.createAlert(this.translationText['Error'], this.translationText['You don`t have permission to edit this category!']);
      return;
    }

    if (
      this.categoriesLevels.length > 1 &&
      category.parentCategory.id !== 100 &&
      (category.owner === this.globals.owner.id || this.globals.isAdmin)
    ) {
      if (this.globals.isCategoryEditingMode === false) {
        this.sharedService.emitChange({
          action: 'Done',
          data: this.globals.isCategoryEditingMode
        });
      } else {
        this.editCategory = this.getCurrentActiveCategory();
      }

    } else {
      this.editCategory = category;
    }
  }

  async onToggleRemove() {
    this.globals.isRemovingMode = !this.globals.isRemovingMode;
    const categoryToDelete = this.getCurrentActiveCategory();

    if (!this.canDelete(categoryToDelete)) {
      this.globals.isRemovingMode = false;
      await this.uiAlertService.createAlert(this.translationText['Error'], this.translationText['You don`t have permission to remove this category!']);
      return;
    }

    if (this.globals.isRemovingMode === false) {
      this.sharedService.emitChange({
        action: 'Done',
        data: this.globals.isRemovingMode
      });
    } else {
      this.editCategory = categoryToDelete;
      const deletingLoader: any = await this.loadingController.create({
        message: this.translationText['Deleting'] + ` <b>${this.categoryService.getTranslationForTitle(categoryToDelete)}</b>`
      });
      await this.uiAlertService.createConfirm(this.translationText['Confirm'],
        this.translationText['Are you sure to remove'] + ` ${this.categoryService.getTranslationForTitle(this.editCategory)} ` + this.translationText['category?'],
        this.translationText['Cancel'], this.translationText['Delete'], async () => {
          await deletingLoader.present();
          this.categoryService.deleteCategory(this.editCategory.id, () => {
            this.categoryService.uploadCategoryTree(this.globals.owner.id, this.editCategory.parentCategoryId, () => {
              if (this.editCategory.parentCategory.scope === this.globals.ROOT_CATEGORY_TREE_SCOPE) {
                this.categoryHierarchy = [];
                this.categoriesLevels = [];

                this.addNewListLevel(this.editCategory.parentCategory.id, false);
              } else {
                this.categoriesLevels.splice(this.categoriesLevels.length - 2);
                this.selectedCategoryHandler(this.categoriesLevels.length - 1, this.editCategory.parentCategory.id);
              }
              deletingLoader.dismiss();
            });
          }, () => {
            deletingLoader.dismiss();
          });
        });
      this.globals.isRemovingMode = false;
    }
  }

  openPlace() {
    this.modalController.create({component: PlaceComponent}).then((modalElement) => {
      modalElement.present();
    });
  }

  selectedCategoryHandler(levelIndex: number, selectedCategoryId: number) {
    if (!this.categoriesLevels[levelIndex]) {
      return;
    }

    const level = this.categoriesLevels[levelIndex];

    const category = this.categoryService.findCategoryById(selectedCategoryId);

    if (category) {
      if (category.label && this.geoService.isPlaceEmpty(this.selectedPlace)) {
        this.openPlace();
      } else if (!category.label) {
        this.geoService.changeSelectedPlace(new Place());
        this.labelService.changeSelectedLabel(new Label());
      }
      this.currentCategory = category;
      this.categoriesLevels[levelIndex] = new Level(CategorySliderComponent, {
        categories: level.levelData.categories,
        activeCategoryId: category.id,
        parentId: category.parentCategory.id,
      });

      this.addNewListLevel(selectedCategoryId);
    }
  }

  showAddNewCategoryHandler() {
    this.isVisibleAddNewCategoryBlock = true;
    this.labelService.changeSelectedLabel(new Label([this.geoService.placeSource.getValue()]));
  }

  categoryChangedInRoot(category: CategoryChanged) {
    this.categoryHierarchy = [];
    this.categoriesLevels = [];

    this.rootActiveCategoryId = category.currentActiveId;

    this.addNewListLevel(category.currentActiveId, false);
  }

  categoryChangedHandler(index, category: CategoryChanged) {
    console.log('index:', index);

    if (index < 0) {
      return;
    }
    this.categoryService.getChildren(category.currentActiveId, this.globals.selectedUser.id).subscribe((response: Category[]) => {
      this.globals.allCategories = this.categoryService.insertNewCat(this.globals.allCategories, response);
      this.globals.allCategories = this.categoryService.assignCategoriesParents(this.globals.allCategories);
    });
    this.categoriesLevels.splice(index + 1, this.MAX_NESTED_LEVEL, new Level(CategoryListComponent, {
      categories: this.categoryService.findCategoryChildesByParentId(category.currentActiveId),
      showAddCategoryButton: true,
    }));

    const currentCategory = this.categoryService.findCategoryById(category.currentActiveId);
    this.currentCategory = currentCategory;
    console.log('current category', currentCategory);

    if (
      currentCategory &&
      currentCategory.parentCategory &&
      this.globals.topCategoryList.findIndex(item => currentCategory.parentCategory.id === item.id) < 0
    ) {
      if (this.categoriesLevels[0] && this.categoriesLevels[0].levelData.parentId) {
        const parentCategory = this.categoryService.findCategoryById(this.categoriesLevels[0].levelData.parentId);

        if (
          parentCategory && parentCategory.parentCategory &&
          this.globals.topCategoryList.findIndex(item => parentCategory.id === item.id) < 0
        ) {
          this.categoriesLevels.unshift(new Level(CategorySliderComponent, {
            categories: this.categoryService.findCategoryChildesByParentId(parentCategory.parentCategory.id),
            activeCategoryId: parentCategory.id || null,
            parentId: parentCategory.parentCategory.id || null,
          }));
        }
      }

      if (this.categoriesLevels[0]) {
        const catId = this.categoriesLevels[0].levelData.activeCategoryId;
        const activeIndex = this.categoryHierarchy.findIndex(id => id === catId);
        this.categoryHierarchy = this.categoryHierarchy.slice(0, activeIndex);

        for (const level of this.categoriesLevels) {
          if (level.levelData.activeCategoryId) {
            this.categoryHierarchy.push(level.levelData.activeCategoryId);
          }
        }
      }

      this.updateCategoryHierarchy(category);

      return;
    }

    if (currentCategory.parentCategory && currentCategory.parentCategory.id) {
      const categoryIndex = this.categoryHierarchy.findIndex(id => id === currentCategory.parentCategory.id);

      if (categoryIndex < 0) {
        const newHierarchy = [];
        newHierarchy.push(currentCategory.id);
        this.categoryHierarchy = newHierarchy;

        this.updateCategoryHierarchy(category);
        return;
      }
    }

  }

  private updateCategoryHierarchy(category: CategoryChanged) {
    const hierarchyIndex = this.categoryHierarchy.findIndex((categoryId) => {
      return categoryId === category.currentActiveId;
    });

    this.categoryHierarchy = this.categoryHierarchy.slice(0, hierarchyIndex);
    this.categoryHierarchy.push(category.currentActiveId);
  }

  showLastBtnAddItem() {
    const categoriLevels = this.categoriesLevels;
    const lastElem = categoriLevels.length - 1;

    categoriLevels.map(elem => elem.levelData.showAddCategoryButton = false);
    categoriLevels[lastElem].levelData.showAddCategoryButton = true;
  }

  hideAddNewCategoryBlock() {
    this.globals.isCategoryAddMode = false;
    this.labelService.changeSelectedLabel(null);
    this.isVisibleAddNewCategoryBlock = false;
    this.newCategoryName = null;

    this.showLastBtnAddItem();
  }

  addNewListLevel(categoryId: number, addToHierarchy = true) {
    const levels = this.categoriesLevels;
    levels.push(new Level(CategoryListComponent, {
      categories: this.categoryService.findCategoryChildesByParentId(categoryId),
      showAddCategoryButton: true
    }));

    if (addToHierarchy) {
      this.categoryHierarchy.push(categoryId);
    }

    this.categoriesLevels = this.trimLevels(levels);
    this.hideAddNewCategoryBlock();
  }

  async updateCategory() {
    if (this.canSave) {
      const category = this.editCategory;
      this.globals.isCategoryEditingMode = false;
      category.translation = this.categoryService.getTitleForTranslation(
        this.globals.currentLanguage,
        this.editedCategoryTitle,
        category.translation
      );
      if (this.canEdit(category)) {
        this.categoryService.updateCategory(category, response => {
          console.log('updated ' + response);
          this.editedCategoryTitle = '';
          this.editCategory = null;
        });
      } else {
        await this.uiAlertService.createAlert(this.translationText['Error'], this.translationText['You don`t have permission to edit this category!']);
      }
      this.canSave = false;
    }
  }

  trimLevels(levels: any[]) {
    if (levels.length <= this.MAX_NESTED_LEVEL) {
      return levels;
    }

    return levels.slice(levels.length - this.MAX_NESTED_LEVEL);
  }

  updateActiveCategoriesList(category: Category) {
    this.globals.allCategories.push(category);
    const level = this.getActiveLevel();
    level.levelData.categories.push(category);
  }

  addNewCategoryToActiveLevel() {
    if (!this.disabledNewButton) {
      this.globals.isCategoryAddMode = false;
      const activeCategory = this.getCurrentActiveCategory();

      const category = this.prepareNewCategory(activeCategory);
      this.disabledNewButton = true;

      if (this.selectedLabel.places.length > 0) {
        this.labelService.updateLabel(this.selectedLabel);
      }
      this.categoryService.addCategory(category, (response: Category) => {

        this.newCategoryName = null;
        this.hideAddNewCategoryBlock();
        this.showLastBtnAddItem();
        if (response) {
          this.updateActiveCategoriesList(this.categoryService.getParentForCategory(response, this.globals.allCategories));
        }
        this.disabledNewButton = false;
        this.labelService.changeSelectedLabel(null);
      });
    }
  }

  prepareNewCategory(parentCategory: Category): Category {
    const newCategory = new Category();
    newCategory.translation = this.categoryService.getTitleForTranslation(
      this.globals.currentLanguage,
      this.newCategoryName,
      new CategoryTranslation()
    );
    newCategory.title = this.newCategoryName;
    newCategory.parentCategoryId = parentCategory.id;
    newCategory.scope = parentCategory.scope + 1;
    newCategory.owner = this.globals.owner.id;
    newCategory.accessType = 2;
    if (this.selectedLabel.places.length > 0) {
      console.log("prepare " + this.selectedLabel.places[0].id);
      console.log("prepare lm " + this.selectedLabel.id);
      newCategory.label = this.selectedLabel.id;
    }
    return newCategory;
  }

  getCurrentActiveCategory(): Category | null {
    let id: number;
    if (this.categoryHierarchy.length !== 0) {
      id = this.categoryHierarchy[this.categoryHierarchy.length - 1];
    } else {
      id = this.categoriesLevels[0].levelData.categories[0].parentCategory.id;
    }
    return this.categoryService.findCategoryById(id);
  }

  canUserMoveLastActiveCategory() {
    if (this.categoryHierarchy.length < 1) {
      return false;
    }
    const currentCategory = this.getCurrentActiveCategory();

    if (currentCategory) {
      return this.canMove(currentCategory);
    }

    return false;
  }

  async acceptCategory() {
    if (this.categoryHierarchy.length <= 0) {
      const category = this.categoryService.findCategoryById(this.rootActiveCategoryId);
      if (!category) {
        return;
      }
      this.categoryService.filterByCategory(category.id);
      return;
    }

    const currentCategory = this.getCurrentActiveCategory();

    if (currentCategory) {
      this.categoryService.filterByCategory(currentCategory.id);
    }

    if (currentCategory === undefined) {
      await this.uiAlertService.createAlert(this.translationText['Error'], this.translationText['Please select a category from the list.']);
    }
  }

  getActiveLevel(): Level {
    return this.categoriesLevels[this.categoriesLevels.length - 1];
  }

  enableMovingFlag() {
    const currentActiveCategory = this.getCurrentActiveCategory();

    if (currentActiveCategory) {
      this.movingCategory = currentActiveCategory;
      this.previousLevelOfMovingCategory = this.categoriesLevels[this.categoriesLevels.length - 2];
    }
  }

  async moveCurrentCategory() {
    const categoryToInsert = this.getCurrentActiveCategory();
    console.log('categoryToInsert ' + categoryToInsert.title);
    if (!this.canMove(this.movingCategory)) {

      this.movingCategory = null;
      return;
    }
    const parents = this.categoryService.getParentPath(categoryToInsert);
    if (parents.includes(this.movingCategory.id)) {
      this.movingCategory = null;
      await this.uiAlertService.createAlert(this.translationText['Error'],
        this.translationText['You can`t move parent category to child!']);
      return;
    }
    const loading: any = await this.loadingController.create({});
    await this.uiAlertService.createConfirm(this.translationText['Confirm'],
      this.translationText['Are you sure you want to move'] + ` ${this.categoryService.getTranslationForTitle(this.movingCategory)} ` +
      this.translationText['into'] + ` ${this.categoryService.getTranslationForTitle(categoryToInsert)}?`,
      this.translationText['Cancel'], this.translationText['Move'], async () => {
        await loading.present();
        this.categoryService.moveCategory(this.movingCategory.id, categoryToInsert.id, () => {

          this.categoryService.uploadCategoryTree(this.globals.owner.id, this.movingCategory.id, () => {
            const previousLevel = this.previousLevelOfMovingCategory;

            if (previousLevel) {
              previousLevel.levelData.categories.splice(previousLevel.levelData.categories.findIndex(cat => cat.id === this.movingCategory.id), 1);
            }
            if (this.movingCategory.parentCategory.scope === this.globals.ROOT_CATEGORY_TREE_SCOPE) {
              this.categoryHierarchy = [];
              this.categoriesLevels = [];

              this.addNewListLevel(categoryToInsert.id, false);
            } else {
              this.categoriesLevels.splice(this.categoriesLevels.length - 1);
              this.selectedCategoryHandler(this.categoriesLevels.length - 1, categoryToInsert.id);
            }
            this.cancelMoving();
          });
          loading.dismiss();
        }, () => {
          loading.dismiss();
        });
      });
  }

  cancelMoving() {
    this.movingCategory = null;
  }

  findCategoriesLevelsByCategoryId(id: number) {
    const category = this.categoryService.findCategoryById(id);
    let hierarchy = [category];
    let parentCategory = category;

    while (true) {
      parentCategory = this.categoryService.findCategoryById(parentCategory.parentCategory.id);

      if ((parentCategory && parentCategory.id === 100) || !parentCategory) {
        break;
      }

      hierarchy.push(parentCategory);
    }

    hierarchy = hierarchy.reverse();
    const rootCategory = hierarchy.shift();

    if (this.globals.topCategoryList.findIndex(item => rootCategory.id === item.id) >= 0) {
      this.rootActiveCategoryId = rootCategory.id;
      this.initializeRootLevel();
    } else {
      hierarchy.unshift(rootCategory);
    }

    for (const cat of hierarchy) {
      this.categoryHierarchy.push(cat.id);
    }

    const trimmedLevels = this.trimLevels(hierarchy);

    for (const cat of trimmedLevels) {
      this.categoriesLevels.push(new Level(CategorySliderComponent, {
        categories: this.categoryService.findCategoryChildesByParentId(cat.parentCategory.id),
        activeCategoryId: cat.id,
        parentId: cat.parentCategory.id,
      }));
    }

    const lastCategory = trimmedLevels.pop();
    this.addNewListLevel(lastCategory.id, false);
  }

  checkNameField() {
    this.canSave = !!this.editedCategoryTitle.trim();
    return this.canSave;
  }

  canDelete(category: Category): boolean {
    if (this.canAction(category)) {
      if (this.globals.action === EDIT_NOTE_ACTION && category.id === this.editedNoteCategory.id) {
        return false;
      }
      if (this.globals.isAdmin) {
        return true;
      }
      return this.isCategoryEditableForUserRole(category);
    }
    return false;
  }

  canMove(category): boolean {
    if (this.canAction(category)) {
      if (this.globals.isAdmin) {
        return true;
      }
      return this.isCategoryEditableForUserRole(category);
    }
    return false;
  }

  isCategoryEditableForUserRole(category: Category): boolean {
    return this.globals.owner.id === category.owner && (category.accessType === 2 || category.accessType === 3);
  }

  canEdit(category): boolean {
    if (this.canAction(category)) {
      if (this.globals.isAdmin) {
        return true;
      }
      if (this.globals.isEditor && (category.accessType === 2 || category.accessType === 3)) {
        return true;
      }
      return this.isCategoryEditableForUserRole(category);
    }
    return false;
  }

  canAction(category): boolean {
    return this.categoriesLevels.length >= 1 && category.parentCategory.id !== 100;
  }

  doRefresh(event) {
    this.globals.currentCategoryId = undefined;
    this.router.navigateByUrl('/categories').then(r => console.log(r));
    event.target.complete();
  }

  private updatePopUpTranslations() {
    this.translationService.get([
      'You don`t have permission to edit this category!',
      'You don`t have permission to remove this category!',
      'Are you sure to remove',
      'category?',
      'Please select a category from the list.',
      'Are you sure you want to move',
      'into',
      'You can`t move parent category to child!',
      'Error',
      'Cancel',
      'Move',
      'Delete',
      'Deleting'
    ]).subscribe(tr => this.translationText = tr);
  }

  isNoteEditing() {
    return this.globals.action === EDIT_NOTE_ACTION;
  }

  dismissCategoryTreeModal() {
    this.modalController.dismiss();
    this.globals.action = null;
  }

  private openPlaceContent() {
    this.modalController.create({component: PlaceComponent}).then((modalElement) => {
      modalElement.present();
    });
  }

  private openLabelContent() {
    this.modalController.create({component: LabelComponent}).then((modalElement) => {
      modalElement.present();
    });
  }

  private openLabelModal() {
    if (this.currentCategory.label !== null) {
      console.log("label " + this.currentCategory.label);
      this.openPlaceContent();
    } else {
      console.log("label " + this.currentCategory.label);
      this.openLabelContent();
    }
  }

  getPlaceTitle(): string {
    if (!this.geoService.isPlaceEmpty(this.selectedPlace)) {
      if (this.selectedPlace.city) {
        return this.selectedPlace.city.cityTitle;
      } else if (this.selectedPlace.region) {
        return this.selectedPlace.region.title;
      } else if (this.selectedPlace.country) {
        return this.selectedPlace.country.title;
      } else {
        return this.selectedPlace.continent.title;
      }
    }
    return "";
  }

  checkPlaceVisibility(id: number): boolean {
    return this.currentCategory ? this.selectedPlace && (this.currentCategory.id === id) : false;
  }
}
