import {Injectable} from '@angular/core';
import {formatDate} from '@angular/common';
import * as moment from 'moment';
import {Globals} from "@app/shared/globals";

@Injectable({
  providedIn: 'root'
})
export class DateConverterService {
  constructor(public globals: Globals) {
  }

  dateConverter(longDate: Date) {
    const date = formatDate(longDate, 'MM-dd-yyyy', 'en');

    return date;
  }

  fromNow(date: any) {
    moment.locale(this.globals.currentLanguage);

    const tomorrow = moment(new Date()).add(1, 'day');
    const dateToShow = moment(date);

    if (tomorrow.diff(dateToShow, 'days') > 1) {
      return dateToShow.format('DD.MM.YYYY');
    } else {
      return dateToShow.fromNow();
    }
  }

  isSameDay(firstDay, secondDay) {
    return moment(firstDay).isSame(moment(secondDay), 'day');
  }
}
