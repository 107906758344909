import { Injectable } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SiteUserService } from '@app/shared/services/site-user.service';
import { ChatService } from '@app/shared/services/chat.service';
import { ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Globals } from '@app/shared/globals';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/onesignal/ngx";
import * as i2 from "@app/shared/services/site-user.service";
import * as i3 from "@app/shared/services/chat.service";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/router";
import * as i6 from "@app/shared/globals";
export class OneSignalService {
    constructor(oneSignal, userService, chatService, platform, router, globals, modalController) {
        this.oneSignal = oneSignal;
        this.userService = userService;
        this.chatService = chatService;
        this.platform = platform;
        this.router = router;
        this.globals = globals;
        this.modalController = modalController;
        this.deviceId = '';
    }
    initOneSignal() {
        if (this.platform.is('android')) {
            this.oneSignal.startInit('0a31ee6d-c505-4832-8160-6f11b8522c9d', '682297175404');
        }
        if (this.platform.is('ios')) {
            this.oneSignal.startInit('0a31ee6d-c505-4832-8160-6f11b8522c9d');
        }
        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
        this.oneSignal.handleNotificationReceived().subscribe((message) => {
        });
        this.oneSignal.handleNotificationOpened().subscribe((messageOpen) => {
            this.onOpenActions();
        });
        this.oneSignal.getIds().then(identity => {
            this.deviceId = identity.userId;
        });
        this.oneSignal.endInit();
    }
    onOpenActions() {
        this.modalController.dismiss();
        this.router.navigateByUrl('/notifications');
    }
    getDeviceId() {
        return this.deviceId;
    }
}
OneSignalService.ɵfac = function OneSignalService_Factory(t) { return new (t || OneSignalService)(i0.ɵɵinject(i1.OneSignal), i0.ɵɵinject(i2.SiteUserService), i0.ɵɵinject(i3.ChatService), i0.ɵɵinject(i4.Platform), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.Globals), i0.ɵɵinject(i4.ModalController)); };
OneSignalService.ɵprov = i0.ɵɵdefineInjectable({ token: OneSignalService, factory: OneSignalService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(OneSignalService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.OneSignal }, { type: i2.SiteUserService }, { type: i3.ChatService }, { type: i4.Platform }, { type: i5.Router }, { type: i6.Globals }, { type: i4.ModalController }]; }, null); })();
