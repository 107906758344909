import { Action } from "@ngrx/store";
import {Category} from '@app/modules/category/category';
import { NavigateLevel } from "./state";

export enum ActionTypes {
  LOAD_CATEGORIES_REQUEST = "[Category] Api get categories",
  LOAD_FAILURE = "[Category] Api Load Failure",
  LOAD_CATEGORIES_SUCCESS = "[Category] Set categories",
  SELECT_CATEGORY = "[Category] Select category",
  LOAD_CATEGORY = "[Category] get category by id",
  LOAD_CATEGORY_FAILURE = "[Category] Load category by id Failure",
  LOAD_CATEGORY_SUCCESS = "[Category] set category",

  LOAD_CATEGORY_CHILDS = "[Category] load category childs",
  LOAD_CATEGORY_CHILDS_FAILURE = "[Category] load category childs failure",
  LOAD_CATEGORY_CHILDS_SUCCESS = "[Category] set category childs",

  ADD_NAVIGATE_LEVEL = "[Category Tree Page] Add navigate level",
  GET_CHILD_CATEGORIES_BY_PARENT_ID = "[Category Slider] Get child categories by parent id",

  GET_ROOT_CATEGORIES = "[Category Tree Page] API get roots categories",
  LOAD_ROOT_CATEGORIES_FAILURE = "[Category Tree Page] API get root categories failure",
  LOAD_ROOT_CATEGORIES_SUCCESS = "[Category Tree Page] Set root categories"
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_REQUEST;
  constructor(public payload: { userId: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_SUCCESS;
  constructor(public payload: { categories: Category[] }) {}
}

export class SelectCategoryAction implements Action {
  readonly type = ActionTypes.SELECT_CATEGORY;
  constructor(public payload: { category: Category }) {}
}

export class LoadCategoryByIdAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORY;
  constructor(public payload: { categoryId: any }) {}
}

export class LoadCategoryByIdSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORY_SUCCESS;
  constructor(public payload: { currentSelectCategory: any }) {}
}

export class LoadFailureCategoryByIdAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCategoryChildsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORY_CHILDS_SUCCESS;
  constructor(public payload: { currentCategoryChilds: any }) {}
}

export class LoadCategoryChildsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORY_CHILDS_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class LoadCategoryChildsAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORY_CHILDS;
  constructor(public payload: { parentId: number }) {}
}

export class AddNavigateLevelAction implements Action {
  readonly type = ActionTypes.ADD_NAVIGATE_LEVEL;
  constructor(public payload: { level: NavigateLevel }) {}
}

export class GetChildsCategoriesAction implements Action {
  readonly type = ActionTypes.GET_CHILD_CATEGORIES_BY_PARENT_ID;
  constructor(public payload: { categoryId: any }) {}
}

// load root categories actions
export class LoadRootCategoriesAction implements Action {
  readonly type = ActionTypes.GET_ROOT_CATEGORIES;
  constructor(public payload: { userId: number }) {}
}

export class LoadRootCategoriesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ROOT_CATEGORIES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadRootCategoriesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ROOT_CATEGORIES_SUCCESS;
  constructor(public payload: { categories: Category[] }) {}
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | SelectCategoryAction
  | LoadCategoryByIdAction
  | LoadCategoryByIdSuccessAction
  | LoadFailureCategoryByIdAction
  | LoadCategoryChildsAction
  | LoadCategoryChildsSuccessAction
  | LoadCategoryChildsFailureAction
  | AddNavigateLevelAction
  | GetChildsCategoriesAction
  | LoadRootCategoriesAction
  | LoadRootCategoriesFailureAction
  | LoadRootCategoriesSuccessAction;
