import { Injectable } from '@angular/core';
import { FeedType } from "@app/modules/home/FeedType";
import * as i0 from "@angular/core";
export class FeedService {
    constructor() { }
    getFeedType() {
        return FeedType;
    }
}
FeedService.ɵfac = function FeedService_Factory(t) { return new (t || FeedService)(); };
FeedService.ɵprov = i0.ɵɵdefineInjectable({ token: FeedService, factory: FeedService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FeedService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return []; }, null); })();
