import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Actions, ActionTypes } from "./actions";
import { initialState, State } from "./state";

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GET_ROOT_CATEGORIES:
      return {
        ...state,
        error: null,
        isLoading: true
      };

    case ActionTypes.LOAD_ROOT_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false
      };

    case ActionTypes.LOAD_ROOT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rootCategories: action.payload.categories
      };

    case ActionTypes.LOAD_CATEGORIES_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ActionTypes.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        isLoading: false
      };
    case ActionTypes.LOAD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false
      };

    case ActionTypes.SELECT_CATEGORY:
      return {
        ...state,
        currentSelectCategory: action.payload.category,
        isLoading: true
      };

    case ActionTypes.LOAD_CATEGORY:
      return {
        ...state,
        error: null,
        isLoading: true
      };

    case ActionTypes.LOAD_CATEGORY_SUCCESS:
      return {
        ...state,
        currentSelectCategory: action.payload.currentSelectCategory,
        isLoading: false
      };

    case ActionTypes.LOAD_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false
      };

    case ActionTypes.LOAD_CATEGORY_CHILDS:
      return {
        ...state,
        error: null,
        isLoading: true
      };

    case ActionTypes.LOAD_CATEGORY_CHILDS_SUCCESS:
      return {
        ...state,
        currentCategoryChilds: action.payload.currentCategoryChilds,
        isLoading: false
      };

    case ActionTypes.ADD_NAVIGATE_LEVEL:
      return {
        ...state,
        flatTreeNavigate: state.flatTreeNavigate.concat(action.payload.level)
      };

    case ActionTypes.GET_CHILD_CATEGORIES_BY_PARENT_ID:
      return {
        ...state
      };

    default: {
      return state;
    }
  }
}
