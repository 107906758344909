import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../globals';
import {Observable} from 'rxjs';
import {Chat} from '../../modules/chat/chat';
import {SharedService} from '@app/shared/services/shared.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(public http: HttpClient, public globals: Globals, private sharedService: SharedService, private router: Router) {
  }

  getAllChats(): Observable<any> {
    return this.http.get(this.globals.apiUrl + '/chats');
  }

  getChat(friendId: number): Observable<any> {
    return this.http.get(this.globals.apiUrl + '/chats/users/' + friendId);
  }

  getMessagesByContent(content: string): Observable<any> {
    return this.http.get(this.globals.apiUrl + '/chats/messages?content=' + content);
  }

  getMessageListForChat(page: number) {
    const params = new HttpParams().append('page', page.toString());
    return this.http.get(this.globals.apiUrl + '/chats/' + this.globals.currentChat.id + '/messages', {
      params: params,
      responseType: 'json'
    });
  }

  getUnreadMessagesCount() {
    return this.http.get(this.globals.apiUrl + '/chats/messages/count');
  }

  refreshUnreadMessagesCount() {
    this.getUnreadMessagesCount().subscribe((data: number) => {
      this.globals.unreadMessagesCount = data;
      this.globals.notificationCount = this.globals.unreadMessagesCount + this.globals.requestList.length;
    });
  }

  sortByLastMsg(chatList: Chat[]) {
    chatList.sort((a: Chat, b: Chat) => {
      if (a.lastMessageDto != null && b.lastMessageDto != null) {
        if (a.lastMessageDto.creationDate < b.lastMessageDto.creationDate) {
          return 1;
        }
        if (a.lastMessageDto.creationDate === b.lastMessageDto.creationDate) {
          return 0;
        }
        if (a.lastMessageDto.creationDate > b.lastMessageDto.creationDate) {
          return -1;
        }
      }
    });
  }

  startChat(selectedUserId: number) {
    this.getChat(selectedUserId).subscribe((data: Chat) => {
      this.globals.currentChat = data;
      this.sharedService.emitChange({
        data,
        action: 'open-chat'
      });
      this.router.navigateByUrl('/chat');
    });
  }
}
