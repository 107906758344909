var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, Output, ViewChild } from '@angular/core';
import { ADD_NEW_NOT_ACTION } from '@app/shared/actions';
import { LangPickerDropDownComponent } from '@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component';
import { LanguagesList } from '@app/core/languages';
import { IonContent, IonSlides } from '@ionic/angular';
import { NewFeed } from "@app/modules/home/NewFeed";
import { FeedType } from "@app/modules/home/FeedType";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
import * as i3 from "@angular/router";
import * as i4 from "@app/shared/services/site-user.service";
import * as i5 from "@app/shared/services/note.service";
import * as i6 from "@app/shared/services/editors.service";
import * as i7 from "@ionic/angular";
import * as i8 from "@app/shared/services/toolbar/toolbar.service";
import * as i9 from "@app/shared/services/subscription.service";
import * as i10 from "bdc-walkthrough";
const _c0 = ["profileHeader"];
export class NoteListPage {
    constructor(http, globals, activeRoute, userService, noteService, editorsService, router, popoverController, toolbarService, subscriptionService, events, bdcWalkService) {
        this.http = http;
        this.globals = globals;
        this.activeRoute = activeRoute;
        this.userService = userService;
        this.noteService = noteService;
        this.editorsService = editorsService;
        this.router = router;
        this.popoverController = popoverController;
        this.toolbarService = toolbarService;
        this.subscriptionService = subscriptionService;
        this.events = events;
        this.bdcWalkService = bdcWalkService;
        this.noteEvent = new EventEmitter();
        this.slideOpts = {
            autoHeight: true,
            initialSlide: 1,
        };
        this.idNewNote = 'addNoteGuideShowWalkthrough';
        this.languageList = LanguagesList;
        this.popoverOpened = false;
        this.sliderIndex = 0;
        this.headerActions = [
            {
                title: 'Category tree',
                icon: 'assets/icons/design_v4/category_path.svg',
                action: () => {
                    this.routeCategories();
                }
            },
            {
                title: 'Notifications',
                path: '/notifications',
                icon: 'assets/icons/design_v4/notifications-outline.svg',
                action: () => {
                    this.globals.feed.closeFeed();
                },
            },
            {
                icon: 'assets/icons/design_v4/filter.svg',
                title: 'Filter',
                action: this.onToggleNoteFilters.bind(this),
            }
        ];
        this.homeFeed = new NewFeed(FeedType.HOME);
        this.friendFeed = new NewFeed(FeedType.FRIEND);
        this.worldFeed = new NewFeed(FeedType.WORLD);
        this.userFeed = new NewFeed(FeedType.USER);
    }
    doRefreshContent(event) {
        this.refresherEffect = event;
        console.log(" doRefreshContent ");
        this.globals.activeFilters.resetAllFilters();
        this.updateNotesAndHeader(false, this.globals.selectedUser.id, event);
    }
    updateNotesAndHeader(b, id, event) {
    }
    setPaginatorFields(data, paginator) {
        console.log(" setPaginatorFields ");
        paginator.items = data.content;
        paginator.lastPage = data.maxPages || 0;
        paginator.countItems = data.maxElements;
        return paginator;
    }
    presentLanguageMenu(env) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("presentLanguageMenu");
            this.popover = yield this.popoverController.create({
                component: LangPickerDropDownComponent,
                event: env,
                translucent: false,
                showBackdrop: false,
            });
            yield this.popover.present();
            this.popoverOpened = true;
            this.popover.onDidDismiss().then(e => {
                if (e.data) {
                    this.globals.currentLanguage = e.data.en;
                    this.updateNavigation();
                }
                this.popoverOpened = false;
            });
        });
    }
    onToggleNoteFilters() {
        this.router.navigate(['note-filters']);
    }
    showCategoriesPage() {
        if (this.globals.isFirstTimeNote) {
            this.walkThroughSubscription = this.bdcWalkService.changes.subscribe(() => this.userService.walkThroughTasks.forEach(task => task.done = this.bdcWalkService.getTaskCompleted(task.name)));
            this.bdcWalkService.reset('addNoteGuide');
            this.bdcWalkService.setTaskCompleted(this.idNewNote, true);
        }
        this.globals.feed.closeFeed();
        this.globals.action = ADD_NEW_NOT_ACTION;
        this.routeCategories();
    }
    updateNavigation() {
        console.log(" updateNavigation ");
        const defaultActions = [...this.headerActions];
        defaultActions.splice(1, 1, {
            title: 'Notifications',
            icon: this.globals.unreadNotificationCount > 0 && !this.popoverOpened ?
                'assets/icons/design_v4/notifications-sharp-unread.svg' : 'assets/icons/design_v4/notifications-sharp.svg',
            action: () => {
                this.router.navigateByUrl('notifications').then(r => console.log(r));
                this.globals.feed.closeFeed();
            },
        });
        defaultActions.splice(2, 2, {
            title: 'Filter',
            icon: this.globals.activeFilters.isEmpty() ?
                'assets/icons/design_v4/filter.svg' : 'assets/icons/design_v4/filter-active.svg',
            action: this.onToggleNoteFilters.bind(this),
        });
        const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;
        defaultActions.push({
            icon: `assets/icons/design_v4/langs/${lang.toLocaleLowerCase()}.svg`,
            title: lang,
            action: env => this.presentLanguageMenu(env)
        });
        this.toolbarService.setMenuItems(defaultActions);
    }
    loadDataForPagination(event) {
    }
    loadData($event) {
        this.paginationEffect = $event;
        this.loadDataForPagination(this.paginationEffect);
    }
    routeCategories() {
        const path = this.globals.currentCategoryId > 0 ? `/categories/${this.globals.currentCategoryId}/tree` : `/categories`;
        this.router.navigateByUrl(path).then(r => console.log(r));
    }
    clearFilters() {
        this.globals.activeFilters.resetAllFilters();
        this.updateNavigation();
        this.updateNotesAndHeader(true, this.globals.selectedUser.id);
    }
    setCurrentFeed() {
        if (this.globals.feed) {
            if (this.globals.feed.feedType === FeedType.HOME) {
                this.globals.feed = this.homeFeed;
                this.slideTo(0);
            }
            else if (this.globals.feed.feedType === FeedType.WORLD) {
                this.globals.feed = this.worldFeed;
                this.slideTo(1);
            }
            else if (this.globals.feed.feedType === FeedType.FRIEND) {
                this.globals.feed = this.friendFeed;
                this.slideTo(2);
            }
            else if (this.globals.feed.feedType === FeedType.USER) {
                this.globals.feed = this.userFeed;
                this.slideTo(0);
            }
        }
        else {
            this.globals.feed = this.worldFeed;
        }
        this.globals.feed.openFeed();
    }
    completeEffects() {
        if (this.refresherEffect) {
            this.refresherEffect.target.complete();
        }
        if (this.paginationEffect) {
            this.paginationEffect.target.complete();
        }
    }
    slideTo(index) {
        this.sliderIndex = index;
        this.slider.slideTo(this.sliderIndex);
        this.completeEffects();
    }
}
NoteListPage.ɵfac = function NoteListPage_Factory(t) { return new (t || NoteListPage)(i0.ɵɵdirectiveInject(i1.HttpClient), i0.ɵɵdirectiveInject(i2.Globals), i0.ɵɵdirectiveInject(i3.ActivatedRoute), i0.ɵɵdirectiveInject(i4.SiteUserService), i0.ɵɵdirectiveInject(i5.NoteService), i0.ɵɵdirectiveInject(i6.EditorsService), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i7.PopoverController), i0.ɵɵdirectiveInject(i8.ToolbarService), i0.ɵɵdirectiveInject(i9.SubscriptionService), i0.ɵɵdirectiveInject(i7.Events), i0.ɵɵdirectiveInject(i10.BdcWalkService)); };
NoteListPage.ɵdir = i0.ɵɵdefineDirective({ type: NoteListPage, viewQuery: function NoteListPage_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵstaticViewQuery(IonSlides, true);
        i0.ɵɵviewQuery(_c0, true);
        i0.ɵɵviewQuery(IonContent, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.slider = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.profileHeader = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    } }, outputs: { noteEvent: "noteEvent" } });
