import {NoteFilter} from "@app/modules/note-filters/note-filter";
import {PrivacyAccessType} from "@app/shared/privacy.access-type";

export class AccessFilter extends NoteFilter {
  access: PrivacyAccessType = undefined;

  constructor(access: PrivacyAccessType) {
    super();
    this.access = access;
  }

  isEmpty() {
    return this.access === null || this.access === undefined;
  }

  getNumberValue() {
    if (this.access !== null) {
      switch (this.access.toString()) {
        case PrivacyAccessType.PRIVATE:
          return 3;
        case PrivacyAccessType.FRIENDS:
          return 2;
        case PrivacyAccessType.PUBLIC:
          return 1;
      }
      return 1;
    }
  }

  toString() {
    return "AccessFilter. Access = " + this.access;
  }
}
