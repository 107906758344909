import { Injectable } from '@angular/core';
import {FeedType} from "@app/modules/home/FeedType";

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  constructor() { }


  public getFeedType(): typeof FeedType {
    return FeedType;
  }
}
