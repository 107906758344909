import {Continent} from "@app/modules/place/continent";
import {Country} from "@app/modules/place/country";
import {Region} from "@app/modules/place/region";
import {City} from "@app/modules/place/city";

export class Place {
  public id: number;
  public continent: Continent;
  public country: Country;
  public region: Region;
  public city: City;

  constructor(continent?: Continent, country?: Country, region?: Region, city?: City) {
    this.continent = continent;
    this.country = country;
    this.region = region;
    this.city = city;
  }
}
