import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Globals} from "@app/shared/globals";
import {Router} from "@angular/router";
import {Label} from "@app/modules/label/label";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LabelService {
  public labelSource = new BehaviorSubject<Label>(new Label());
  public selectedLabel = this.labelSource.asObservable();

  constructor(public http: HttpClient, public globals: Globals, public router: Router) {
  }

  changeSelectedLabel(label: Label) {
    this.labelSource.next(label);
  }

  createLabel(label: Label) {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.post(this.globals.apiUrl + '/label', label, {params});
  }

  updateLabel(label: Label) {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.put(this.globals.apiUrl + '/label', label, {params});
  }
}
