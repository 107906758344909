var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component } from '@angular/core';
import { NotificationService } from '@app/shared/services/notification.service';
import { Globals } from '@app/shared/globals';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from '@app/shared/services/chat.service';
import { Router } from '@angular/router';
import { DateConverterService } from '@app/shared/services/date-converter.service';
import * as moment from 'moment';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { AlertController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/services/notification.service";
import * as i2 from "@app/shared/globals";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@app/shared/services/chat.service";
import * as i5 from "@angular/router";
import * as i6 from "@app/shared/services/date-converter.service";
import * as i7 from "@app/shared/services/toolbar/toolbar.service";
import * as i8 from "@ionic/angular";
import * as i9 from "@angular/common";
import * as i10 from "@app/shared/pipes/user-name.pipe";
function NotificationsPageComponent_ion_item_sliding_4_ion_item_divider_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-item-divider", 17);
    i0.ɵɵelementStart(1, "ion-label");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const i_r2 = i0.ɵɵnextContext().index;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r3.dividerText(i_r2));
} }
function NotificationsPageComponent_ion_item_sliding_4_ion_item_option_15_Template(rf, ctx) { if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-item-option", 18);
    i0.ɵɵlistener("click", function NotificationsPageComponent_ion_item_sliding_4_ion_item_option_15_Template_ion_item_option_click_0_listener() { i0.ɵɵrestoreView(_r9); const notification_r1 = i0.ɵɵnextContext().$implicit; const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.confirmRemove(notification_r1); });
    i0.ɵɵelement(1, "img", 19);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", "assets/icons/design_v4/trash-outline.svg", i0.ɵɵsanitizeUrl);
} }
function NotificationsPageComponent_ion_item_sliding_4_ion_item_option_16_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-item-option", 20);
    i0.ɵɵlistener("click", function NotificationsPageComponent_ion_item_sliding_4_ion_item_option_16_Template_ion_item_option_click_0_listener() { i0.ɵɵrestoreView(_r12); const notification_r1 = i0.ɵɵnextContext().$implicit; const ctx_r10 = i0.ɵɵnextContext(); return ctx_r10.confirmRemove(notification_r1); });
    i0.ɵɵelement(1, "img", 19);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", "assets/icons/design_v4/trash-outline.svg", i0.ɵɵsanitizeUrl);
} }
const _c0 = function (a0) { return { "notification-item--active": a0 }; };
function NotificationsPageComponent_ion_item_sliding_4_Template(rf, ctx) { if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-item-sliding", 5);
    i0.ɵɵtemplate(1, NotificationsPageComponent_ion_item_sliding_4_ion_item_divider_1_Template, 3, 1, "ion-item-divider", 6);
    i0.ɵɵelementStart(2, "ion-item", 7);
    i0.ɵɵlistener("click", function NotificationsPageComponent_ion_item_sliding_4_Template_ion_item_click_2_listener() { i0.ɵɵrestoreView(_r14); const notification_r1 = ctx.$implicit; const ctx_r13 = i0.ɵɵnextContext(); return ctx_r13.onItemClickHandler(notification_r1); });
    i0.ɵɵelementStart(3, "ion-avatar", 8);
    i0.ɵɵelement(4, "img", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "ion-label");
    i0.ɵɵelementStart(6, "h2", 10);
    i0.ɵɵtext(7);
    i0.ɵɵpipe(8, "userName");
    i0.ɵɵelementStart(9, "span", 11);
    i0.ɵɵtext(10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "p", 12);
    i0.ɵɵtext(12);
    i0.ɵɵelement(13, "ion-icon", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "ion-item-options", 14);
    i0.ɵɵtemplate(15, NotificationsPageComponent_ion_item_sliding_4_ion_item_option_15_Template, 2, 1, "ion-item-option", 15);
    i0.ɵɵtemplate(16, NotificationsPageComponent_ion_item_sliding_4_ion_item_option_16_Template, 2, 1, "ion-item-option", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const notification_r1 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.canViewDivider(i_r2));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(11, _c0, notification_r1.unread));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("src", notification_r1.sender.userPhotoName, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind1(8, 9, notification_r1.sender), " ");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", notification_r1.creationDate ? ctx_r0.dateService.fromNow(notification_r1.creationDate) : "", " ");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", ctx_r0.getNotificationText(notification_r1), " ");
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("src", ctx_r0.getNotificationIcon(notification_r1));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r0.canViewDivider(i_r2));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.canViewDivider(i_r2));
} }
export class NotificationsPageComponent {
    constructor(notificationService, globals, translationService, chatService, router, dateService, toolbarService, alertController) {
        this.notificationService = notificationService;
        this.globals = globals;
        this.translationService = translationService;
        this.chatService = chatService;
        this.router = router;
        this.dateService = dateService;
        this.toolbarService = toolbarService;
        this.alertController = alertController;
        this.menuButtonUnreadNotifications = [
            {
                title: 'Category tree',
                icon: 'assets/icons/design_v4/category_path.svg',
                action: () => {
                    this.routeCategories();
                }
            }
        ];
        this.menuButtonWithoutNotifications = [
            {
                title: 'Category tree',
                icon: 'assets/icons/design_v4/category_path.svg',
                action: () => {
                    this.routeCategories();
                }
            }
        ];
    }
    routeCategories() {
        const path = this.globals.currentCategoryId > 0 ? `/categories/${this.globals.currentCategoryId}/tree` : `/categories`;
        this.router.navigateByUrl(path).then(r => console.log(r));
    }
    ngOnInit() {
        this.updatePopUpTranslations();
        this.translationService.onLangChange.subscribe((event) => {
            this.updatePopUpTranslations();
        });
    }
    ionViewWillEnter() {
        this.notificationService.readNotifications();
        if (this.globals.userNotifications === undefined) {
            this.getNotifications(0);
        }
    }
    ionViewWillLeave() {
        this.notificationService.readNotifications();
        this.notificationService.checkLocalUnreadNotifications();
    }
    doRefreshContent(event) {
        this.getNotifications(0, () => {
            event.target.complete();
        });
    }
    nextPage(event) {
        if (!this.notificationPage.last) {
            this.getNotifications(this.notificationPage.number + 1, () => {
                event.target.complete();
            });
        }
        else {
            if (event) {
                event.target.complete();
            }
        }
    }
    getNotifications(page, callback) {
        this.notificationService.getNotifications(page).subscribe((data) => {
            this.notificationPage = data;
            this.globals.userNotifications = page === 0 ? this.notificationPage.content : this.globals.userNotifications.concat(this.notificationPage.content);
            this.notificationService.addToReceivedNotifications(this.notificationPage.content);
            if (callback) {
                callback();
            }
        });
    }
    loadData($event) {
        this.nextPage($event);
    }
    onItemClickHandler(notification) {
        switch (notification.type) {
            case '1':
                this.chatService.startChat(notification.sender.id);
                break;
            case '2':
                this.router.navigateByUrl('/notes/' + notification.actionData);
                break;
            case '3':
                this.router.navigateByUrl('/notes/' + notification.actionData);
                break;
            case '4':
                this.router.navigateByUrl('/chat');
                break;
            case '5':
                this.router.navigateByUrl('/friends');
                break;
        }
    }
    confirmRemove(notification) {
        return __awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: this.translationText['Confirm!'],
                message: this.translationText['Remove notification?'],
                buttons: [
                    {
                        text: this.translationText['Cancel'],
                        role: 'cancel',
                    }, {
                        text: this.translationText['Remove'],
                        handler: () => {
                            this.notificationService.removeNotification(notification.id, () => {
                                this.globals.userNotifications.splice(this.globals.userNotifications.indexOf(notification), 1);
                            });
                        }
                    }
                ]
            });
            yield alert.present();
        });
    }
    getNotificationIcon(notification) {
        switch (notification.type) {
            case '1':
                return '../../../assets/icons/design_v4/mail-outline.svg';
            case '2':
                return '../../../assets/icons/design_v4/chatbubble-ellipses-outline.svg';
            case '3':
                return '../../../assets/icons/design_v4/chatbubble-ellipses-outline.svg';
            case '4':
                return '../../../assets/icons/design_v4/people-outline.svg';
            case '5':
                return '../../../assets/icons/design_v4/people-outline.svg';
        }
    }
    getNotificationText(notification) {
        switch (notification.type) {
            case '1':
                return this.translationText['send you a new message'];
            case '2':
                return this.translationText['comment your note'];
            case '3':
                return this.translationText['reply to your comment'];
            case '4':
                return this.translationText['send you a friend request'];
            case '5':
                return this.translationText['accept your friend request'];
        }
    }
    canViewDivider(i) {
        if (this.globals.userNotifications[i - 1] &&
            this.globals.userNotifications[i - 1] !== null &&
            this.globals.userNotifications[i - 1].creationDate &&
            this.globals.userNotifications[i] &&
            this.globals.userNotifications[i] !== null &&
            this.globals.userNotifications[i].creationDate) {
            if (i === 0) {
                return true;
            }
            else {
                return !this.dateService.isSameDay(this.globals.userNotifications[i].creationDate, this.globals.userNotifications[i - 1].creationDate);
            }
        }
        else {
            return false;
        }
    }
    dividerText(i) {
        if (this.globals.userNotifications[i].creationDate) {
            if (i === 0) {
                if (moment(this.globals.userNotifications[i].creationDate).format('MMMM DD') === moment(new Date()).format('MMMM DD')) {
                    return 'Today';
                }
                else {
                    return moment(this.globals.userNotifications[i].creationDate).format('MMMM DD');
                }
            }
            else {
                return moment(this.globals.userNotifications[i].creationDate).format('MMMM DD');
            }
        }
    }
    updateNavigation() {
        if (this.globals.notificationCount > 0) {
            this.toolbarService.setMenuItems(this.menuButtonUnreadNotifications);
        }
        else {
            this.toolbarService.setMenuItems(this.menuButtonWithoutNotifications);
        }
    }
    updatePopUpTranslations() {
        this.translationService.get([
            'send you a new message',
            'comment your note',
            'reply to your comment',
            'send you a friend request',
            'accept your friend request',
            'Confirm!',
            'Remove notification?',
            'Cancel',
            'Remove'
        ]).subscribe(tr => this.translationText = tr);
    }
}
NotificationsPageComponent.ɵfac = function NotificationsPageComponent_Factory(t) { return new (t || NotificationsPageComponent)(i0.ɵɵdirectiveInject(i1.NotificationService), i0.ɵɵdirectiveInject(i2.Globals), i0.ɵɵdirectiveInject(i3.TranslateService), i0.ɵɵdirectiveInject(i4.ChatService), i0.ɵɵdirectiveInject(i5.Router), i0.ɵɵdirectiveInject(i6.DateConverterService), i0.ɵɵdirectiveInject(i7.ToolbarService), i0.ɵɵdirectiveInject(i8.AlertController)); };
NotificationsPageComponent.ɵcmp = i0.ɵɵdefineComponent({ type: NotificationsPageComponent, selectors: [["app-notifications-page"]], decls: 7, vars: 1, consts: [["slot", "fixed", 3, "ionRefresh"], [1, "notification-list", 2, "margin-top", "10px"], ["class", "custom-line cursor-pointer cursor-drag", 4, "ngFor", "ngForOf"], ["threshold", "100px", 3, "ionInfinite"], ["loadingSpinner", "bubbles", "loadingText", "Loading more data..."], [1, "custom-line", "cursor-pointer", "cursor-drag"], ["class", "divider", 4, "ngIf"], ["lines", "none", 1, "on-left-line", 2, "margin-right", "2px", 3, "ngClass", "click"], ["slot", "start", 1, "avatar-img"], ["alt", "photo", 3, "src"], [1, "user-name"], ["slot", "end", 1, "notification-item__text__time"], [1, "user-sub"], ["size", "small", "slot", "end", 1, "item_icon", 3, "src"], ["side", "end"], ["class", "sub-item", "color", "white", 3, "click", 4, "ngIf"], ["style", "padding-top: 32px", "class", "sub-item", "color", "white", 3, "click", 4, "ngIf"], [1, "divider"], ["color", "white", 1, "sub-item", 3, "click"], ["alt", "", 2, "width", "30px", 3, "src"], ["color", "white", 1, "sub-item", 2, "padding-top", "32px", 3, "click"]], template: function NotificationsPageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-content");
        i0.ɵɵelementStart(1, "ion-refresher", 0);
        i0.ɵɵlistener("ionRefresh", function NotificationsPageComponent_Template_ion_refresher_ionRefresh_1_listener($event) { return ctx.doRefreshContent($event); });
        i0.ɵɵelement(2, "ion-refresher-content");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "ion-list", 1);
        i0.ɵɵtemplate(4, NotificationsPageComponent_ion_item_sliding_4_Template, 17, 13, "ion-item-sliding", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "ion-infinite-scroll", 3);
        i0.ɵɵlistener("ionInfinite", function NotificationsPageComponent_Template_ion_infinite_scroll_ionInfinite_5_listener($event) { return ctx.loadData($event); });
        i0.ɵɵelement(6, "ion-infinite-scroll-content", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngForOf", ctx.globals.userNotifications);
    } }, directives: [i8.IonContent, i8.IonRefresher, i8.IonRefresherContent, i8.IonList, i9.NgForOf, i8.IonInfiniteScroll, i8.IonInfiniteScrollContent, i8.IonItemSliding, i9.NgIf, i8.IonItem, i9.NgClass, i8.IonAvatar, i8.IonLabel, i8.IonIcon, i8.IonItemOptions, i8.IonItemDivider, i8.IonItemOption], pipes: [i10.UserNamePipe], styles: [".notification-list[_ngcontent-%COMP%]{max-width:600px;margin:0 auto}.custom-line[_ngcontent-%COMP%]{border-bottom:1px solid #80808047}.on-left-line[_ngcontent-%COMP%]{box-shadow:-5px 0 0 -4px #80808047;padding-bottom:5px}.avatar-img[_ngcontent-%COMP%]{height:55px;width:55px;margin-right:12px}.user-name[_ngcontent-%COMP%]{font-size:18px;line-height:21px;color:#3c3c3c;margin-bottom:2px}.user-sub[_ngcontent-%COMP%]{font-size:16px;line-height:19px;margin-bottom:10px;color:rgba(0,0,0,.5)}.sub-item[_ngcontent-%COMP%]:first-child{padding:0 0 0 25px}.sub-item[_ngcontent-%COMP%]:last-child{padding:0 25px 0 0}.notification-item__text__time[_ngcontent-%COMP%]{float:right;color:rgba(0,0,0,.3);font-size:14px}.item_icon[_ngcontent-%COMP%]{float:right}.divider[_ngcontent-%COMP%]   ion-label[_ngcontent-%COMP%]{text-align:center;color:#bfbfbf;font-size:12px;padding:0 10px}.notification-item--active[_ngcontent-%COMP%]{--ion-background-color:linear-gradient(90deg, #48CDC4 10.19%, #FE972B 100%)}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NotificationsPageComponent, [{
        type: Component,
        args: [{
                selector: 'app-notifications-page',
                templateUrl: './notifications-page.component.html',
                styleUrls: ['./notifications-page.component.scss']
            }]
    }], function () { return [{ type: i1.NotificationService }, { type: i2.Globals }, { type: i3.TranslateService }, { type: i4.ChatService }, { type: i5.Router }, { type: i6.DateConverterService }, { type: i7.ToolbarService }, { type: i8.AlertController }]; }, null); })();
