import {Component, OnInit, ViewChild} from '@angular/core';
import {fromEvent, Observable, Subject} from 'rxjs';
import {finalize, first, mergeMap, takeUntil} from 'rxjs/operators';
import {Globals} from '../../shared/globals';
import {ActionSheetController, IonSlides, LoadingController, Platform} from '@ionic/angular';
import {AttachmentService} from '@app/shared/services/attachment-service';
import ImageRotate from 'quill-image-rotate-module';
import * as QuillNamespace from 'quill';
import * as $ from '../../../assets/js/jquery-3.3.1.min';
import {ImageService} from '@app/shared/services/image.service';
import {UtilsService} from '@app/shared/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {UiAlertService} from '@app/shared/services/ui-alert-service';

const Quill: any = QuillNamespace;

const BlockEmbed = Quill.import('blots/block/embed');

class iPhoneVideo extends BlockEmbed {
  static create(src) {
    const node = super.create();
    node.dataset.src = src;
    node.setAttribute('src', src);
    node.setAttribute('controls', '');
    node.setAttribute('preload', 'metadata');
    node.setAttribute('webkit-playsinline', 'webkit-playsinline');
    return node;
  }

  static value(domNode) {
    return domNode.dataset.src;
  }

}

iPhoneVideo['blotName'] = 'iPhoneVideo';
iPhoneVideo['className'] = 'iPhoneVideo';
iPhoneVideo['tagName'] = 'video';


Quill.register({'formats/iPhoneVideo': iPhoneVideo});
Quill.register("modules/ImageRotate", ImageRotate);

@Component({
  selector: 'app-quill',
  templateUrl: './quill.component.html',
  styleUrls: ['./quill.component.scss']
})
export class QuillComponent implements OnInit {

  @ViewChild('slides', {static: false}) slides: IonSlides;

  translationText: any;
  public quillEditorRef;
  public destroy$ = new Subject<void>();
  public loading = false;
  uploading: any;
  popover: any;
  public customModules = {
    toolbar: '#toolbar',
    ImageRotate: {
      modules: ["Toolbar"],
      displayStyles: {
        border: 'none',
      },
      toolbarStyles: {
        top: "-10px"
      }
    }
  };
  public showEmojiPicker = false;

  readonly rootSliderOptions = {
    slidesPerView: 1,
    slidesPerColumn: 1,
    slidesPerGroup: 1,
    coverflowEffect: {
      stretch: 0,
      modifier: 6,
      slideShadows: false,
      centeredSlides: true,
    }
  };

  constructor(public attachmentService: AttachmentService,
              public globals: Globals,
              private platform: Platform,
              public actionSheetController: ActionSheetController,
              private imageService: ImageService,
              private loadingController: LoadingController,
              private utilsService: UtilsService,
              private translateService: TranslateService,
              private uiAlertService: UiAlertService) {
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  ngOnInit() {
    this.translateService.get([
      'You could only upload images',
      'Image needs to be less than 6MB',
      'Uploading...',
      'Error'
    ]).subscribe(tr => {
      this.translationText = tr;
    });
    const icons = Quill.import('ui/icons');
    icons['image'] = '<img src="../../../assets/icons/design_v4/image-file.svg" class="fm_editor_icon">';
    icons['color'] = '<img src="../../../assets/icons/design_v4/font-color.svg" class="fm_editor_icon">';
    icons['italic'] = '<img src="../../../assets/icons/design_v4/italic.svg" class="fm_editor_icon">';
    icons['underline'] = '<img src="../../../assets/icons/design_v4/underline.svg" class="fm_editor_icon">';
    icons['bold'] = '<img src="../../../assets/icons/design_v4/bold.svg" class="fm_editor_icon">';
    icons['strike'] = '<img src="../../../assets/icons/design_v4/strike.svg" class="fm_editor_icon">';
    icons['indent']['+1'] = '<img src="../../../assets/icons/design_v4/indent-left.svg" class="fm_editor_icon">';
    icons['indent']['-1'] = '<img src="../../../assets/icons/design_v4/indent-right.svg" class="fm_editor_icon">';
    icons['align']['center'] = '<img src="../../../assets/icons/design_v4/align.svg" class="fm_editor_icon">';
    icons['align'][''] = '<img src="../../../assets/icons/design_v4/alignment-left.svg" class="fm_editor_icon">';
    icons['align']['right'] = '<img src="../../../assets/icons/design_v4/alignment-right.svg" class="fm_editor_icon">';
    const size = Quill.import('attributors/style/size');
    Quill.register(size, true);
    Quill.register({"formats/indent": IndentStyle}, true);
  }

  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    this.quillEditorRef.focus();
    this.quillEditorRef.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      delta.ops = delta.ops.map(op => {
        return {
          insert: op.insert
        };
      });
      return delta;
    });    this.quillEditorRef.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      delta.ops = delta.ops.map(op => {
        return {
          insert: op.insert
        };
      });
      return delta;
    });
  }

  videoHandler = () => {
    console.log('video handler run ');

    let input = document.getElementById('videoInputField');
    fromEvent(input, 'change')
      .pipe(
        first(),
        mergeMap(event => {
          const fileInput = event.target as HTMLInputElement;
          let file: File;
          const uploadData = new FormData();
          file = fileInput.files[0];
          console.log('checking file ');
          console.log(file.type + ' type');
          if (/^video\//.test(file.type)) {
            if (file.size > 10000000) {
              alert('Video needs to be less than 10MB');
            } else {
              this.loading = true;
              uploadData.append('file', file, file.name);
              console.log('sending request with: ' + uploadData + '\nfile name: ' + file.name);
              return this.attachmentService.uploadVideo(uploadData);
            }
          } else {
            alert('You could only upload video.');
          }
        }),
        finalize(() => {
          input = null;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data: any) => {
        const attachment = JSON.parse(data);
        this.globals.newAttachments.push(attachment);
        this.quillEditorRef.focus();
        const range = this.quillEditorRef.getSelection(true);
        this.quillEditorRef.insertEmbed(range.index + 1, 'iPhoneVideo', attachment.url + '?autoplay=0', 'user');
        this.loading = false;
      });
    input.click();
  };

  onUpdateServerName(event: Event) {
    this.globals.newNoteContent = (<HTMLInputElement> event.target).textContent;
  }

  next() {
    this.slides.slideNext();
  }

  prev() {
    this.slides.slidePrev();
  }

  emojiHandler(event: any) {
    const range = this.quillEditorRef.getSelection(true);
    this.quillEditorRef.insertText(range.index, event[0], 'user');
    this.quillEditorRef.setSelection(range.index + 2, 0);
  }

  parseUrl() {
    const urlPatterns = [/(https)?:\/\/[^\s]+/, /(http)?:\/\/[^\s]+/, /(www)\.[^\s]+/];
    const newOptions = [];
    for (const option of this.quillEditorRef.getContents().ops) {
      if (typeof (option.insert) === 'string') {
        const combinedPattern = urlPatterns
          .map(pattern => `(${pattern.source})`)
          .join('|');
        const combinedRegexp = new RegExp(combinedPattern, 'gi');
        const str = option.insert;
        let lastMatchEndIndex = 0;
        let match: RegExpExecArray | null = combinedRegexp.exec(str);
        if (match == null) {
          newOptions.push(option);
        }
        while (match !== null) {
          if (match.index > lastMatchEndIndex) {
            newOptions.push({insert: str.slice(lastMatchEndIndex, match.index)});
          }
          let atrLink = match[0];
          if (atrLink.charAt(0) === 'w' || atrLink.charAt(0) === 'W') {
            atrLink = 'http://' + match[0];
          }
          if (option.attributes !== undefined) {
            option.attributes.link = atrLink;
            newOptions.push({insert: match[0], attributes: option.attributes});
          } else {
            newOptions.push({insert: match[0], attributes: {link: atrLink}});
          }
          lastMatchEndIndex = match.index + match[0].length;
          match = combinedRegexp.exec(str);
        }
      } else {
        newOptions.push(option);
      }
    }
    this.quillEditorRef.setContents(newOptions);
    this.globals.newNoteContent = this.quillEditorRef.root.innerHTML;
  }

  async selectImage() {
    if (this.platform.is('cordova')) {
      const actionSheet = await this.actionSheetController.create({
        header: 'Select Image source',
        buttons: [{
          text: 'Load from Library',
          handler: () => {
            this.imageService.pickImage('lib').then(img => {
              this.imageProcessing(img);
            });
          }
        },
          {
            text: 'Use Camera',
            handler: () => {
              this.imageService.pickImage('cam').then(img => {
                this.imageProcessing(img);
              });
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      });
      await actionSheet.present();
    } else {

      const selectDialogueLink = $('<a href=""></a>');
      const input = '#fileInputField';
      const fileSelector = $(input);

      selectDialogueLink.click(() => {
        fileSelector.click();
        return false;
      });
      selectDialogueLink.click();
    }
  }

  async processFile(event) {
    const target = event.target as HTMLInputElement;
    const selectedFile: any = target.files[0];
    await this.imageProcessing(selectedFile);
  }

  async imageProcessing(file) {
    if (this.platform.is('cordova')) {
      const imgUrl = 'data:image/jpeg;base64, ' + file;
      const block = imgUrl.split(';');
      const contentType = block[0].split(':')[1];
      const realData = block[1].split(',')[1];
      file = this.utilsService.b64toBlob(realData, contentType, 512);
    }

    this.uploading = await this.loadingController.create({
      message: this.translationText['Uploading...'],
    });
    if (/^image\//.test(file.type)) {
      if (file.size > 6000000) {
        await this.uiAlertService.createAlert(this.translationText['Error'], this.translationText['Image needs to be less than 6MB']);
      } else {
        await this.uploading.present();
        const formData = new FormData();
        let uploadHandler: Observable<any>;
        formData.append('file', file, `${new Date().toISOString()}.jpeg`);
        uploadHandler = this.attachmentService.uploadImage(formData);
        uploadHandler.subscribe((data: any) => {
          const attachment = JSON.parse(data);
          this.globals.newAttachments.push(attachment);
          this.quillEditorRef.focus();
          const range = this.quillEditorRef.getSelection(true);
          this.quillEditorRef.insertEmbed(range.index + 1, 'image', attachment.url, 'user');
          this.uploading.dismiss();
        });
      }
    } else {
      await this.uiAlertService.createAlert(this.translationText['Error'], this.translationText['You could only upload images']);
    }
  }

}

export const Parchment = Quill.import('parchment');
const pixelLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9]; //Levels of indent
const TAB_MULTIPLIER = 30; //Indent size modifier

export class IndentAttributor extends (Parchment.Attributor.Style as {
  new(formatName: any, styleProperty: any, attributorOptions: any): any;
}) {
  constructor(formatName: any, styleProperty: any, attributorOptions: any) {
    super(formatName, styleProperty, attributorOptions);
  }

  public add(node: HTMLElement, value: string): boolean {
    return super.add(node, `${+value * TAB_MULTIPLIER}px`);
  }

  public value(node: HTMLElement): number | undefined {
    return parseFloat(super.value(node)) / TAB_MULTIPLIER || undefined; // Don't return NaN
  }
}

export const IndentStyle = new IndentAttributor('indent', 'margin-left', {
  scope: Parchment.Scope.BLOCK,
  whitelist: pixelLevels.map(value => `${value * TAB_MULTIPLIER}px`),
});
