import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../globals';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
export class UserRelationService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    refreshRequestsList() {
        this.getFriendRequests();
    }
    refreshOutgoingRequests() {
        this.getOutgoingRequests();
    }
    getAllUserFriends(userId, page) {
        const params = new HttpParams().append('page', page.toString());
        return this.http.get(this.globals.apiUrl + '/relations/friends/users/' + userId, {
            params
        });
    }
    getFriendRequests() {
        this.http.get(this.globals.apiUrl + '/relations/incoming-requests').subscribe((data) => {
            this.globals.requestList = data;
            this.globals.notificationCount = this.globals.unreadMessagesCount + this.globals.requestList.length;
        });
    }
    getOutgoingRequests() {
        this.http.get(this.globals.apiUrl + '/relations/outgoing-requests').subscribe((data) => {
            this.globals.outgoingRequests = data;
        });
    }
    addUserRelation(friend, callback) {
        let params = new HttpParams();
        params = params.append('friendId', friend.id.toString());
        this.http.post(this.globals.apiUrl + '/relations', null, {
            params: params,
            observe: 'response'
        }).subscribe(() => {
            return callback && callback();
        });
    }
    deleteUserRelation(friend, callback) {
        this.http.delete(this.globals.apiUrl + '/relations/' + friend.id).subscribe(() => {
            return callback && callback();
        });
    }
    editUserRelation(friend, relationType, callback) {
        let params = new HttpParams();
        params = params.append('friendId', friend.id.toString());
        params = params.append('relation', relationType.toString());
        this.http.put(this.globals.apiUrl + '/relations', null, {
            params: params,
            observe: 'response'
        }).subscribe(() => {
            return callback && callback();
        });
    }
}
UserRelationService.ɵfac = function UserRelationService_Factory(t) { return new (t || UserRelationService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
UserRelationService.ɵprov = i0.ɵɵdefineInjectable({ token: UserRelationService, factory: UserRelationService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UserRelationService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
