import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AlertController } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';


export interface IAppVersion {
  version: string;
  androidUri: string;
  enableFeature: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CheckUpdateService {

  constructor(
    public http: HttpClient,
    public globals: Globals,
    private alertController: AlertController,
    private appVersion: AppVersion,
    private market: Market) {
  }

  async isNeedUpdate() {
    const appVersionObj: IAppVersion = await this.getInfo();
    const currentAppVersionCode = await this.appVersion.getVersionCode();
    if (appVersionObj && appVersionObj.enableFeature && +appVersionObj.version.split('.').join('') > +currentAppVersionCode) {
      this.presentAlertConfirm(appVersionObj);
    }
  }

  async presentAlertConfirm(appVersionObj: IAppVersion) {
    const alert = await this.alertController.create({
      header: 'Update info',
      message: `New version <strong>${appVersionObj.version}</strong> is available. Please update application`,
      buttons: [
        {
          text: 'Later',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        }, {
          text: 'Update',
          handler: () => {
            this.market.open(appVersionObj.androidUri);
          }
        }
      ]
    });

    await alert.present();
}

  getInfo(): Promise<any> {
    return this.http.get(this.globals.apiUrl + '/api/version/android').toPromise();
  }

}

