import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {Category} from '@app/modules/category/category';
import {EditorsService} from '../../../shared/services/editors.service';
import {Globals} from '../../../shared/globals';
import {ModalController} from '@ionic/angular';
import {EditorsDiscussComponent} from '@app/modules/editors-page/editors-discuss/editors-discuss.component';
import {CategoryService} from '@app/shared/services/category.service';
import {CategoryDiscussion} from '@app/modules/category/category-discussion';

@Component({
  selector: 'app-editors-row',
  templateUrl: './editors-row.component.html',
  styleUrls: ['./editors-row.component.scss']
})
export class EditorsRowComponent implements OnInit {
  @Input() category: Category;
  @Input() discuss: CategoryDiscussion;
  @Output() categoryApproved = new EventEmitter<any>();

  public items: Array<string>;
  public truncatedItems: Array<string>;

  public ul: HTMLElement;
  public liElements: Array<HTMLElement> = [];

  public langs: Map<string, string> = new Map<string, string>();

  public selectedLang: string;
  public langTranslation: string;

  public lansInObj = {};

  @ViewChildren('ulContainer') set ulCont(container: QueryList<ElementRef>) {
    if (typeof container.first !== 'undefined') {
      this.ul = container.first.nativeElement;
      this.liElements = Array.from(container.first.nativeElement.children);
    }
  }

  constructor(public editorsService: EditorsService,
              public categoryService: CategoryService,
              public globals: Globals,
              public modalController: ModalController) {
  }

  ngOnInit() {
    this.initializeCategoryParentTree();

    this.initializeLangs();

    this.truncateRow();

    this.langs.forEach((key, value) => {
      this.lansInObj[value] = true;
    });
  }

  private truncateRow() {
    if (this.truncatedItems.length > 4) {
      const filterEllipsis = [];
      filterEllipsis.push(this.truncatedItems[0]);
      filterEllipsis.push('...');
      filterEllipsis.push(this.truncatedItems[this.truncatedItems.length - 3]);
      filterEllipsis.push(this.truncatedItems[this.truncatedItems.length - 2]);
      filterEllipsis.push(this.truncatedItems[this.truncatedItems.length - 1]);
      this.truncatedItems = filterEllipsis;
    }
  }

  visibleLang(lang: string, state: boolean) {
    for (const key in this.lansInObj) {
      if (key !== lang) {
        this.lansInObj[key] = state;
      }
    }
  }

  private changeSelectedLang(lang: string) {
    this.selectedLang = lang;
    this.visibleLang(lang, false);
  }

  initializeCategoryParentTree() {
    this.truncatedItems = new Array<string>();

    for (const parent of this.category.parentTitles) {
      this.truncatedItems.push(parent.title.toString());
    }

    const categoryCopy = new Category();
    categoryCopy.translation = this.category.translation;

    this.truncatedItems.push(this.categoryService.getTranslationForTitle(categoryCopy));
    this.items = this.truncatedItems;
  }

  initializeLangs() {
    for (const lang of this.globals.userLanguages) {
      this.langs = this.editorsService.fillTheMap(lang, this.langs, this.category.translation);
    }
  }

  translateLang(event) {
    this.langTranslation = String(event.target.value);
  }

  async presentModal() {
    const modal: any = await this.modalController.create({
      component: EditorsDiscussComponent,
      componentProps: {
        truncatedItems: this.truncatedItems,
        discuss: this.discuss,
        langs: this.langs,
        translate: this.category.translation,
        currentCategory: this.category,
      },
      cssClass: 'editors-category__custom-modal'
    });
    modal.onWillDismiss().then(response => {
      this.categoryApproved.emit({
        approved: response.data.approved,
        currentCategory: response.data.currentCategory,
        currentDiscussion: response.data.currentDiscussion,
      });
    });
    return await modal.present();
  }

  hideTranslate(lang) {
    this.selectedLang = null;
    this.visibleLang(lang, true);
  }

  applyTranslate(lang: string) {
    this.selectedLang = lang;

    if (this.langTranslation && this.langTranslation !== '') {
      this.langTranslation = this.langTranslation.trim();

      this.editorsService.editCategoryTranslation(this.langTranslation, this.selectedLang, this.category.id)
        .subscribe((data: Category) => {

          this.category = data;

          if (lang === this.globals.currentLanguage) {
            this.truncatedItems[this.truncatedItems.length - 1] = this.langTranslation;
          }
          this.langs = new Map<string, string>();
          this.initializeLangs();
          this.langTranslation = '';
        });
    }
    this.visibleLang(lang, true);
    this.hideTranslate(lang);
  }
}
