export enum Languages {
  AR = 'ar',
  BN = 'bn',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  HI = 'hi',
  ID = 'id',
  IT = 'it',
  JA = 'ja',
  PT = 'pt',
  RU = 'ru',
  ZH = 'zh'
}

export const LanguagesList = [
  {
    en: 'ar',
    name: 'Arabic'
  },
  {
    en: 'bn',
    name: 'Bengali'
  },
  {
    en: 'de',
    name: 'Germany'
  },
  {
    en: 'en',
    name: 'English'
  },
  {
    en: 'es',
    name: 'Spanish'
  },
  {
    en: 'fr',
    name: 'French'
  },
  {
    en: 'hi',
    name: 'Hindi'
  },
  {
    en: 'id',
    name: 'Indonesian'
  },
  {
    en: 'it',
    name: 'Italian'
  },
  {
    en: 'ja',
    name: 'Japonese'
  },
  {
    en: 'pt',
    name: 'Portuguese'
  },
  {
    en: 'ru',
    name: 'Russian'
  },
  {
    en: 'zh',
    name: 'Chinese'
  }
];
