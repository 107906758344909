<ion-content>
  <div class="profile">
    <app-profile-header
            #profileHeader
            [isEdit]="isEditingMode"
    ></app-profile-header>
    <form style="margin-bottom: 100px" class="profile-form mt-m"
            [formGroup]="profileForm">
      <ion-list class="list-inputs">
        <ion-item class="list-inputs__location">
          <ion-label
            class="list-inputs__label" translate>
            Location
          </ion-label>
          <ion-text
            class="form-item-ion"
            slot="end"
            *ngIf="!isEditingMode">
            <p translate>{{!profileForm.value.location ? 'City...' : profileForm.value.location }}</p>
          </ion-text>
          <ion-input
            *ngIf="isEditingMode"
            [ngStyle]="{'text-decoration' : 'underline'}"
            class="ion-text-right form-item-ion"
            type="text"
            [disabled]="!isEditingMode"
            formControlName="location">
          </ion-input>
        </ion-item>

        <ion-item class="list-inputs__dob">
          <ion-label
            class="list-inputs__label" translate>
            Date of Birth
          </ion-label>
          <ion-text
            class="form-item-ion"
            slot="end"
            *ngIf="!isEditingMode">
            <p>{{profileForm.value.dateOfBirth | dateFormatPipe }}</p>
          </ion-text>
          <ion-datetime
            *ngIf="isEditingMode"
            pickerFormat="MM DD YYYY"
            [ngStyle]="{'text-decoration' : 'underline'}"
            class="ion-text-right form-item-ion"
            displayFormat="MM DD YYYY"
            formControlName="dateOfBirth">
          </ion-datetime>
        </ion-item>

        <ion-item class="list-inputs__email">
          <ion-label
            class="list-inputs__label" translate>
            E-mail
          </ion-label>
          <ion-text
            class="form-item-ion"
            slot="end"
            *ngIf="true">
            <p>{{profileForm.value.email}}</p>
          </ion-text>
          <ion-input
            *ngIf="false"
            [ngStyle]="{'text-decoration' : 'underline'}"
            class="ion-text-right form-item-ion"
            type="email"
            formControlName="email">
          </ion-input>
        </ion-item>

        <ion-item class="list-inputs__username">
          <ion-label
            class="list-inputs__label" translate>
            Username
          </ion-label>
          <ion-text
            class="form-item-ion"
            slot="end"
            *ngIf="true">
            <p>{{profileForm.value.ssiod}}</p>
          </ion-text>
          <img
            class="copy_icon"
            *ngIf="true"
            slot="end"
            (click)="copyUsername()"
            [src]="'assets/icons/design_v4/file_copy-18.png'"
            alt="">
          <ion-input
            *ngIf="false"
            [ngStyle]="{'text-decoration' : 'underline'}"
            class="ion-text-right form-item-ion"
            type="text"
            formControlName="ssiod">
          </ion-input>
        </ion-item>

        <ion-item
          lines="none"
          class="list-inputs__language">
          <ion-label
            class="list-inputs__label" translate>
            Language
          </ion-label>

          <ion-button
            class="add-button"
            slot="end"
            *ngIf="isEditingMode"
            (click)="addLanguageRow()"
            shape="round"
            fill="outline">
            <img style="height: 14px; width: 17px" [src]="'assets/icons/design_v4/plus_btn.svg'">
          </ion-button>
        </ion-item>
        <ion-item
          class="list-sub-item"
          lines="none"
          *ngFor="let langrow of profileLanguages; let i = index">

          <img
            [src]="'assets/icons/design_v4/rm_icon.svg'"
            *ngIf="isEditingMode"
            slot="start"
            (click)="removeLanguageRow(i)">

          <ion-select
            slot="start"
            placeholder="{{'Select Language'| translate}}"
            [disabled]="!isEditingMode"
            (ionChange)="languageUpdateHandler($event.detail.value, i)"
            okText="Select"
            [value]="profileLanguages[i].language.id"
            cancelText="Cancel">
            <ion-select-option
              *ngFor="let lang of globals.profileLanguageList"
              [value]="lang.id" disabled="{{isDisabled(lang)}}">
              {{lang.languageTitle}}
            </ion-select-option>

          </ion-select>
          <ion-select
            slot="end"
            class="list-sub-item__select"
            placeholder="{{'Select level' | translate}}"
            [disabled]="!isEditingMode"
            (ionChange)="levelUpdateHandler($event.detail.value, i)"
            [value]="profileLanguages[i].proficiency.id"
            okText="Select"
            cancelText="Cancel">
            <ion-select-option
              *ngFor="let lvl of globals.proficiencyList"
              [value]="lvl.id">
              {{lvl.level}}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item
          lines="none"
          style="border-top: 1px solid #98989852"
          class="list-inputs__social">
          <ion-label
            class="list-inputs__label" translate>
            Social Network
          </ion-label>

          <ion-button
            class="add-button"
            slot="end"
            *ngIf="isEditingMode"
            (click)="addSocialLink()"
            shape="round"
            fill="outline">
            <img style="height: 14px; width: 17px" [src]="'assets/icons/design_v4/plus_btn.svg'">
          </ion-button>
        </ion-item>
        <ion-item
          lines="none"
          *ngFor="let sn of socialNetworks; let i = index">
          <img
            style="z-index: 10"
            *ngIf="isEditingMode"
            slot="start"
            (click)="removeSocialLink(i)"
            [src]="'assets/icons/design_v4/rm_icon.svg'">

          <ion-text
            class="form-item-ion"
            slot="end"
            *ngIf="!isEditingMode">
            <a href="{{sn.link}}" target="_blank">{{sn.link}}</a>
          </ion-text>

          <ion-select
            slot="start"
            placeholder="{{'Select Network' | translate}}"
            [disabled]="!isEditingMode"
            okText="{{'Select' | translate}}"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="sn.network"
            cancelText="{{'Cancel' | translate}}">
            <ion-select-option
              *ngFor="let network of globals.profileSocialLink"
              [value]="network">
              {{network | translate}}
            </ion-select-option>
          </ion-select>

          <ion-input
            *ngIf="isEditingMode"
            [ngStyle]="{'text-decoration' : 'underline'}"
            class="ion-text-right form-item-ion"
            type="text"
            placeholder="{{'Link'| translate}}"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="sn.link">
          </ion-input>
        </ion-item>
      </ion-list>
    </form>

  </div>
</ion-content>

