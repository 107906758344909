import {EventEmitter, Output, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '@app/shared/globals';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteUserService} from '@app/shared/services/site-user.service';
import {NoteService} from '@app/shared/services/note.service';
import {Page} from '@app/core/page';
import {EditorsService} from '@app/shared/services/editors.service';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {ADD_NEW_NOT_ACTION} from '@app/shared/actions';
import {Note} from '@app/modules/note/note';
import {LangPickerDropDownComponent} from '@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component';
import {LanguagesList} from '@app/core/languages';
import {Events, IonContent, IonSlides, PopoverController} from '@ionic/angular';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {Paginator} from '@app/shared/models/Paginator';
import {Observable, Subscription} from 'rxjs';
import {SubscriptionService} from '@app/shared/services/subscription.service';
import {NewFeed} from "@app/modules/home/NewFeed";
import {FeedType} from "@app/modules/home/FeedType";
import {BdcWalkService} from "bdc-walkthrough";

export class NoteListPage implements UpdateNavigation {

  @ViewChild(IonSlides, {static: true}) slider: IonSlides;
  @ViewChild('profileHeader', {static: false}) profileHeader;
  @ViewChild(IonContent, {static: false}) content: IonContent;
  @Output() noteEvent = new EventEmitter<string>();

  readonly slideOpts = {
    autoHeight: true,
    initialSlide: 1,
  };

  idNewNote = 'addNoteGuideShowWalkthrough';
  walkThroughSubscription: Subscription;

  popover;
  languageList = LanguagesList;
  popoverOpened = false;

  sliderIndex = 0;
  paginationEffect;
  refresherEffect;

  readonly headerActions: MenuItem[] = [
    {
      title: 'Category tree',
      icon: 'assets/icons/design_v4/category_path.svg',
      action: () => {
        this.routeCategories();
      }
    },
    {
      title: 'Notifications',
      path: '/notifications',
      icon: 'assets/icons/design_v4/notifications-outline.svg',
      action: () => {
        this.globals.feed.closeFeed();
      },
    },
    {
      icon: 'assets/icons/design_v4/filter.svg',
      title: 'Filter',
      action: this.onToggleNoteFilters.bind(this),
    }
  ];

  public homeFeed: NewFeed;
  public friendFeed: NewFeed;
  public worldFeed: NewFeed;
  public userFeed: NewFeed;
  public feeds: Observable<NewFeed[]>;
  areNotesLoaded;

  constructor(
    public http: HttpClient,
    public globals: Globals,
    public activeRoute: ActivatedRoute,
    public userService: SiteUserService,
    public noteService: NoteService,
    public editorsService: EditorsService,
    public router: Router,
    public popoverController: PopoverController,
    public toolbarService: ToolbarService,
    public subscriptionService: SubscriptionService,
    public events: Events,
    public bdcWalkService: BdcWalkService
  ) {
    this.homeFeed = new NewFeed(FeedType.HOME);
    this.friendFeed = new NewFeed(FeedType.FRIEND);
    this.worldFeed = new NewFeed(FeedType.WORLD);
    this.userFeed = new NewFeed(FeedType.USER);
  }

  doRefreshContent(event?) {
    this.refresherEffect = event;
    console.log(" doRefreshContent ");
    this.globals.activeFilters.resetAllFilters();
    this.updateNotesAndHeader(false, this.globals.selectedUser.id, event);
  }

  updateNotesAndHeader(b: boolean, id: number, event?) {

  }


  setPaginatorFields(data: Page<Note>, paginator: Paginator): Paginator {
    console.log(" setPaginatorFields ");

    paginator.items = data.content;
    paginator.lastPage = data.maxPages || 0;
    paginator.countItems = data.maxElements;
    return paginator;
  }


  async presentLanguageMenu(env) {
    console.log("presentLanguageMenu");
    this.popover = await this.popoverController.create({
      component: LangPickerDropDownComponent,
      event: env,
      translucent: false,
      showBackdrop: false,
    });

    await this.popover.present();
    this.popoverOpened = true;
    this.popover.onDidDismiss().then(
      e => {
        if (e.data) {
          this.globals.currentLanguage = e.data.en;
          this.updateNavigation();
        }
        this.popoverOpened = false;
      }
    );
  }

  onToggleNoteFilters(): void {
    this.router.navigate(['note-filters']);
  }

  showCategoriesPage() {
    if (this.globals.isFirstTimeNote) {
      this.walkThroughSubscription = this.bdcWalkService.changes.subscribe(() => this.userService.walkThroughTasks.forEach(task =>
        task.done = this.bdcWalkService.getTaskCompleted(task.name)));
      this.bdcWalkService.reset('addNoteGuide');
      this.bdcWalkService.setTaskCompleted(this.idNewNote, true);

    }
    this.globals.feed.closeFeed();
    this.globals.action = ADD_NEW_NOT_ACTION;
    this.routeCategories();
  }

  updateNavigation() {
    console.log(" updateNavigation ");

    const defaultActions = [...this.headerActions];

    defaultActions.splice(1, 1, {
        title: 'Notifications',
        icon: this.globals.unreadNotificationCount > 0 && !this.popoverOpened ?
          'assets/icons/design_v4/notifications-sharp-unread.svg' : 'assets/icons/design_v4/notifications-sharp.svg',
        action: () => {
          this.router.navigateByUrl('notifications').then(r => console.log(r));
          this.globals.feed.closeFeed();
        },
      },
    );
    defaultActions.splice(2, 2, {
        title: 'Filter',
        icon: this.globals.activeFilters.isEmpty() ?
          'assets/icons/design_v4/filter.svg' : 'assets/icons/design_v4/filter-active.svg',
        action: this.onToggleNoteFilters.bind(this),
      },
    );

    const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;

    defaultActions.push(
      {
        icon: `assets/icons/design_v4/langs/${lang.toLocaleLowerCase()}.svg`,
        title: lang,
        action: env => this.presentLanguageMenu(env)
      }
    );

    this.toolbarService.setMenuItems(defaultActions);
  }

  loadDataForPagination(event) {
  }

  loadData($event: CustomEvent) {
    this.paginationEffect = $event;
    this.loadDataForPagination(this.paginationEffect);
  }

  routeCategories() {
    const path = this.globals.currentCategoryId > 0 ? `/categories/${this.globals.currentCategoryId}/tree` : `/categories`;
    this.router.navigateByUrl(path).then(r => console.log(r));
  }


  clearFilters() {
    this.globals.activeFilters.resetAllFilters();
    this.updateNavigation();
    this.updateNotesAndHeader(true, this.globals.selectedUser.id);
  }

  public setCurrentFeed() {
    if (this.globals.feed) {
      if (this.globals.feed.feedType === FeedType.HOME) {
        this.globals.feed = this.homeFeed;
        this.slideTo(0);
      } else if (this.globals.feed.feedType === FeedType.WORLD) {
        this.globals.feed = this.worldFeed;
        this.slideTo(1);
      } else if (this.globals.feed.feedType === FeedType.FRIEND) {
        this.globals.feed = this.friendFeed;
        this.slideTo(2);
      } else if (this.globals.feed.feedType === FeedType.USER) {
        this.globals.feed = this.userFeed;
        this.slideTo(0);
      }
    } else {
      this.globals.feed = this.worldFeed;
    }
    this.globals.feed.openFeed();
  }

  completeEffects() {
    if (this.refresherEffect) {
      this.refresherEffect.target.complete();
    }
    if (this.paginationEffect) {
      this.paginationEffect.target.complete();
    }
  }

  slideTo(index: number) {
    this.sliderIndex = index;
    this.slider.slideTo(this.sliderIndex);
    this.completeEffects();
  }

}
