import {Injectable} from '@angular/core';
import {Globals} from '@app/shared/globals';
import {AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class UiAlertService {

  constructor(private globals: Globals, private alertController: AlertController) {
  }

  async createAlert(headerText: string, messageText: string) {
    const alert = await this.alertController.create({
      header: headerText,
      message: messageText,
    });
    await alert.present();
  }

  async createAlertWithButton(headerText: string, messageText: string, buttonText: string, callback?: any) {
    const alert = await this.alertController.create({
      header: headerText,
      message: messageText,
      buttons: [
        {
          text: buttonText,
          handler: async () => {
            await callback();
          }
        }
      ]
    });
    await alert.present();
  }

  async createConfirm(headerText: string, messageText: string, declineBtnText: string, applyBtnText: string, callback?: any) {
    const alert = await this.alertController.create({
      header: headerText,
      message: messageText,
      buttons: [
        {
          text: declineBtnText,
          role: 'cancel',
        }, {
          text: applyBtnText,
          handler: async () => {
            await callback();
          }
        }
      ]
    });
    await alert.present();
  }
}
