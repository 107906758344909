import {Injectable} from '@angular/core';
import {Note} from '../../modules/note/note';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Globals} from '../globals';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  private eventSource = new Subject<string>();
  public event$ = this.eventSource.asObservable();
  constructor(public http: HttpClient, public globals: Globals) {
  }
  newEvent(event) {
    this.eventSource.next(event);
  }

  getUserAllNotes(page: number): Observable<any> {
    let params = this.applyNoteFilters();
    params = params.append('page', page.toString());
    params = params.append('currentLang', this.globals.currentLanguage);
    return this.http.get(this.globals.apiUrl + '/notes/users/' + this.globals.selectedUser.id, {
      params: params,
      responseType: 'json'
    });
  }

  refreshNote() {
    this.getNoteByNoteId().subscribe((data: Note) => {
      this.globals.currentNote = data;
    });
  }

  refreshNoteById(id: number, callback) {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get(this.globals.apiUrl + '/notes/' + id, {
      params: params
    }).subscribe((data: Note) => {
      this.globals.currentNote = data;
      return callback && callback(data);
    });
  }

  getUserNoteListByCategory(catId: number, page: number): Observable<any> {
    let params = this.applyNoteFilters();
    params = params.append('page', page.toString());
    params = params.append('currentLang', this.globals.currentLanguage);
    return this.http.get(this.globals.apiUrl + '/notes/categories/' + catId + '/users/' + this.globals.selectedUser.id, {
      params: params,
      responseType: 'json'
    });
  }

  getNoteByNoteId() {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get(this.globals.apiUrl + '/notes/' + this.globals.currentNote.id, {
      params: params,
    });
  }

  getNoteById(id: number) {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get(`${this.globals.apiUrl}/notes/${id}`, {
      params: params,
    });
  }

  addNote(newNote: Note, callback) {
    this.http.post(this.globals.apiUrl + '/notes/', newNote).subscribe(() => {
      return callback && callback();
    });
  }

  deleteNote(currentNote: Note, callback) {
    this.http.delete(this.globals.apiUrl + '/notes/' + currentNote.id).subscribe(() => {
      return callback && callback();
    });
  }

  editNote(currentNote: Note, callback) {
    this.http.put(this.globals.apiUrl + '/notes/', currentNote).subscribe(() => {
      return callback && callback();
    });
  }

  applyNoteFilters(): HttpParams {
    let params = new HttpParams();
    if (!this.globals.activeFilters.isEmpty()) {
      params = this.applyNoteFiltersForFeed();
      if (this.globals.owner.id === this.globals.selectedUser.id && !this.globals.activeFilters.accessFilter.isEmpty()) {
        params = params.append('access-filter', this.globals.activeFilters.accessFilter.getNumberValue().toString());
      }
    }
    return params;
  }

  applyNoteFiltersForFeed(): HttpParams {
    let params = new HttpParams();
    if (!this.globals.activeFilters.isEmpty()) {
      if (!this.globals.activeFilters.dateFilter.isEmpty()) {
        params = params.append('date-to-filter', new Date(this.globals.activeFilters.dateFilter.dateTo).toISOString());
        params = params.append('date-from-filter', new Date(this.globals.activeFilters.dateFilter.dateFrom).toISOString());
      }
      if (!this.globals.activeFilters.weightFilter.isEmpty()) {
        params = params.append('weight-filter', this.globals.activeFilters.weightFilter.weight.toString());
      }
    }
    return params;
  }

  canCurrentUserEditNote(note: Note) {
    return this.globals.isAuthenticated && note.owner.id === this.globals.owner.id;
  }

  getFriendsNotes(page: number = 0): Observable<any> {
    let params = this.applyNoteFiltersForFeed();
    params = params.append('currentLang', this.globals.currentLanguage);
    params = params.append('page', page.toString());
    return this.http.get(`${this.globals.apiUrl}/notes/feed/friends`, {params});
  }

  getNotes(page: number = 0): Observable<any> {
    let params = this.applyNoteFiltersForFeed();
    params = params.append('currentLang', this.globals.currentLanguage);
    params = params.append('page', page.toString());
    return this.http.get(`${this.globals.apiUrl}/notes/feed/world`, {params});
  }

  getFriendNoteListByCategory(catId: number, page: number): Observable<any> {
    let params = this.applyNoteFiltersForFeed();
    params = params.append('page', page.toString());
    params = params.append('currentUserId', this.globals.owner.id.toString());
    params = params.append('currentLang', this.globals.currentLanguage);

    return this.http.get(`${this.globals.apiUrl}/notes/feed/friends/categories/${this.globals.currentCategoryId.toString()}`, {
      params,
      responseType: 'json'
    });
  }

  getNoteListByCategory(catId: number, page: number): Observable<any> {
    let params = this.applyNoteFiltersForFeed();
    params = params.append('page', page.toString());
    params = params.append('currentLang', this.globals.currentLanguage);

    return this.http.get(`${this.globals.apiUrl}/notes/feed/world/categories/${this.globals.currentCategoryId.toString()}`, {
      params,
      responseType: 'json'
    });
  }
}
