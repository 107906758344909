import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
	selector: '[appCustomSelect]'
})

export class CustomSelectDirective implements AfterViewInit {
	constructor(private el: ElementRef) {}

	ngAfterViewInit() {
        const style = document.createElement('style');
        style.innerHTML = '' +
            'div.item-native, button, div.item-inner { padding: 0; min-height: auto; border: none;}' +
            ':host {--min-height: 22px;}' +
            'div.select-icon {width: 20px}' +
            '.select-icon {transform: rotate(180deg);}' +
            'div.select-placeholder {opacity: 1}' +
            'item-inner-highlight { height: auto}';
        this.el.nativeElement.shadowRoot.appendChild(style);
	}
}
