import {Component, Input} from '@angular/core';
import {CategoryService} from 'src/app/shared/services/category.service';
import {Router} from '@angular/router';
import {Globals} from '@app/shared/globals';
import {Category} from '@app/modules/category/category';

@Component({
  selector: 'app-category-breadcrumbs',
  templateUrl: './category-breadcrumbs.component.html',
  styleUrls: ['./category-breadcrumbs.component.css']
})
export class CategoryBreadcrumbsComponent {

  public breadcrumbs = [];

  @Input() mod: string;

  @Input() set categories(value) {
    if (value) {
      this.updateBreadcrumbs(value);
    }
  }

  constructor(
    public categoryService: CategoryService,
    private router: Router,
    public globals: Globals
  ) {
  }

  redirectToCategoryTreeById(id) {
    switch (this.mod) {
      case 'NEW':
        this.router.navigateByUrl(`/categories/${id}/new-note`).then(r => console.log(r));
        break;
      case 'EDIT':
        const position = this.breadcrumbs.findIndex((item) => item.id === id);
        this.breadcrumbs.splice(position + 1, this.breadcrumbs.length - position);
        break;
      case 'FILTER':
        this.categoryService.filterByCategory(id);
        break;
    }
  }

  getCurrentBreadCrumb() {
    return this.breadcrumbs;
  }

  updateBreadcrumbs(currentCategory: Category) {
    this.breadcrumbs = this.getCategoryTitles(currentCategory);
  }

  getCategoryTitles(category): string[] {
    const result = [];
    const idArr: number[] = [];

    if (category.parentTitles) {
      for (const parent of category.parentTitles) {
        idArr.push(parent.id);
      }
      idArr.push(category.id);
      const translationSub = this.categoryService.getTranslationForTitleById(idArr).subscribe((res: any) => {
        for (const r of res) {
          result.push({
            id: r.trans_id,
            title: this.categoryService.getCurrentTitleTranslate(r)
          });
        }
        translationSub.unsubscribe();
      });
    }
    return result;
  }
}
