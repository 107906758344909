var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injectable } from '@angular/core';
import { AuthService, AuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '@app/shared/globals';
import { SiteUser } from '@app/modules/site-user/site-user';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { LoadingController, Platform } from '@ionic/angular';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Storage } from '@ionic/storage';
import { ToolbarService } from '@app/shared/services/toolbar/toolbar.service';
import { MenuService } from '@app/shared/services/menu/menu.service';
import { TokensService } from '@app/shared/services/tokens.service';
import { ProfileService } from '@app/shared/services/profile.service';
import { UiAlertService } from '@app/shared/services/ui-alert-service';
import { OneSignalService } from '@app/shared/services/one-signal.service';
import * as i0 from "@angular/core";
import * as i1 from "angularx-social-login";
import * as i2 from "@angular/common/http";
import * as i3 from "@app/shared/globals";
import * as i4 from "@angular/router";
import * as i5 from "@ionic-native/google-plus/ngx";
import * as i6 from "@ionic/angular";
import * as i7 from "@ionic/storage";
import * as i8 from "@app/shared/services/toolbar/toolbar.service";
import * as i9 from "@app/shared/services/menu/menu.service";
import * as i10 from "@app/shared/services/ui-alert-service";
import * as i11 from "./authentication.service";
import * as i12 from "@app/shared/services/tokens.service";
import * as i13 from "@app/shared/services/profile.service";
import * as i14 from "@app/shared/services/one-signal.service";
export class GoogleAuthService extends AuthService {
    constructor(config, http, globals, router, gPlus, platform, storage, toolbarService, menuService, uiAlertService, authenticationService, tokensService, profileService, loadingController, oneSignalService) {
        super(config);
        this.http = http;
        this.globals = globals;
        this.router = router;
        this.gPlus = gPlus;
        this.platform = platform;
        this.storage = storage;
        this.toolbarService = toolbarService;
        this.menuService = menuService;
        this.uiAlertService = uiAlertService;
        this.authenticationService = authenticationService;
        this.tokensService = tokensService;
        this.profileService = profileService;
        this.loadingController = loadingController;
        this.oneSignalService = oneSignalService;
    }
    authByGoogle(siteUser, needRegister) {
        return __awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingController.create({});
            yield loading.present();
            this.globals.owner = siteUser;
            let params = new HttpParams();
            params = params.append('currentLanguage', this.globals.currentLanguage);
            params = params.append('needRegister', needRegister);
            params = params.append('deviceId', this.oneSignalService.getDeviceId());
            return this.http.post(this.globals.apiUrl + '/accounts/login/social', siteUser, { params })
                .toPromise()
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                yield this.loadingController.dismiss();
                if (response !== null) {
                    this.tokensService.setTokens(response.witaAuthToken);
                    localStorage.setItem('wita-user', JSON.stringify(response));
                    this.storage.set('wita-user', response);
                    this.globals.owner = response;
                    this.authenticationService.afterLoginInitiation();
                }
            })).catch((er) => __awaiter(this, void 0, void 0, function* () {
                console.log('throw error after social login request inside google auth service');
                yield this.loadingController.dismiss();
                throw er;
            }));
        });
    }
    accessWithGoogle(referrer, needRegister) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.platform.is('cordova')) {
                const loading = yield this.loadingController.create({});
                yield loading.present();
                this.gPlus.login({
                    'webClientId': '179659201381-kma03s41hgtc2b4oio8fotd5faolc2r0.apps.googleusercontent.com',
                    'offline': true,
                    'scopes': 'profile email'
                }).then((res) => {
                    const siteUser = new SiteUser({
                        firstName: res.givenName,
                        lastName: res.familyName,
                        ssoId: res.userId,
                        email: res.email,
                        userPhotoName: res.imageUrl
                    });
                    loading.dismiss();
                    this.authByGoogle(siteUser, needRegister).then(() => {
                        this.profileService.setReferrer(this.globals.owner, referrer);
                        this.router.navigate(['/']);
                        this.toolbarService.setHeaderVisibility(true);
                        this.menuService.activeMainMenu();
                    }).catch((er) => __awaiter(this, void 0, void 0, function* () {
                        yield this.authenticationService.checkConfirmationRequired(er);
                    }));
                }).catch((er) => __awaiter(this, void 0, void 0, function* () {
                    yield this.authenticationService.checkConfirmationRequired(er);
                }));
            }
            else {
                this.signIn(GoogleLoginProvider.PROVIDER_ID, {
                    prompt: 'select_account'
                }).then((user) => {
                    if (user !== null) {
                        const siteUser = new SiteUser({
                            firstName: user.firstName,
                            lastName: user.lastName,
                            ssoId: user.id,
                            email: user.email,
                            userPhotoName: user.photoUrl
                        });
                        this.authByGoogle(siteUser, needRegister)
                            .then(() => {
                            console.log("Referrer is " + referrer);
                            this.profileService.setReferrer(this.globals.owner, referrer);
                            this.router.navigate(['/']);
                            this.toolbarService.setHeaderVisibility(true);
                            this.menuService.activeMainMenu();
                        })
                            .catch((er) => __awaiter(this, void 0, void 0, function* () {
                            yield this.authenticationService.checkConfirmationRequired(er);
                        }));
                    }
                });
            }
        });
    }
}
GoogleAuthService.ɵfac = function GoogleAuthService_Factory(t) { return new (t || GoogleAuthService)(i0.ɵɵinject(i1.AuthServiceConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Globals), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.GooglePlus), i0.ɵɵinject(i6.Platform), i0.ɵɵinject(i7.Storage), i0.ɵɵinject(i8.ToolbarService), i0.ɵɵinject(i9.MenuService), i0.ɵɵinject(i10.UiAlertService), i0.ɵɵinject(i11.AuthenticationService), i0.ɵɵinject(i12.TokensService), i0.ɵɵinject(i13.ProfileService), i0.ɵɵinject(i6.LoadingController), i0.ɵɵinject(i14.OneSignalService)); };
GoogleAuthService.ɵprov = i0.ɵɵdefineInjectable({ token: GoogleAuthService, factory: GoogleAuthService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(GoogleAuthService, [{
        type: Injectable
    }], function () { return [{ type: i1.AuthServiceConfig }, { type: i2.HttpClient }, { type: i3.Globals }, { type: i4.Router }, { type: i5.GooglePlus }, { type: i6.Platform }, { type: i7.Storage }, { type: i8.ToolbarService }, { type: i9.MenuService }, { type: i10.UiAlertService }, { type: i11.AuthenticationService }, { type: i12.TokensService }, { type: i13.ProfileService }, { type: i6.LoadingController }, { type: i14.OneSignalService }]; }, null); })();
