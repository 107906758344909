<ion-content>
  <div class="hero-image"></div>
<div class="content">
  <div class="content__logo">
    <img
      class="fadein-animation auth__logo"
      [src]="'assets/icons/design_v4/logo.svg'"
      alt="">
  </div>
  <div class="content__header">
    <div class="content__header__sbs">
      Social Bookmarking Service
    </div>
    <div class="content__header__sbs__s">
      Let others organize it for you ;)
    </div>
  </div>
  <div class="content__actions">
    <ion-button
      color="primary"
      (click)="navigateTo('/login')"
      class="content__actions__btn mb-s" translate>
      Sign in
    </ion-button>
    <ion-button
      fill="outline"
      color="primary"
      (click)="navigateTo('/sign-up')"
      class="content__actions__btn" translate>
      Sign up
    </ion-button>
  </div>
  <div class="content__order">
    <img
      class="fadein-animation auth__order"
      [src]="'assets/img/order.png'"
      alt="">
  </div>


</div>
</ion-content>
