import {AfterViewChecked, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DateConverterService} from 'src/app/shared/services/date-converter.service';
import {SiteUser} from '../../site-user/site-user';
import {Globals} from '../../../shared/globals';
import {Message} from './message';
import {MessageService} from '../../../shared/services/message.service';
import {Page} from '../../../core/page';
import * as moment from 'moment';
import {fromEvent} from 'rxjs';
import {ChatService} from '@app/shared/services/chat.service';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.css']
})
export class ChatMessagesComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() data: any;
  @Input() isMobile = false;
  @Output() dialogueClosed = new EventEmitter();
  @Output() lastMessageUpdated = new EventEmitter();
  @ViewChild('chatWindow', {static: false}) chatWindow;

  dialoguePartner: SiteUser;
  public messagesLoaded: boolean = false;
  messagePage: number = 0;
  private currentMessagePage: Page<Message>;
  maxPages: number;
  needToScroll: boolean;

  @ViewChild('chatList', {read: false, static: false}) content;
  @ViewChild('updater', {read: false, static: false}) updater;
  @ViewChild('chatBlockOwner', {static: false}) chatBlockOwner;
  chatBlockContent = 0;


  scrollEvent$: any;
  newMessageReceivedSubscription$: any;
  isUpdating = false;
  contentHeight = null;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.chatBlockContent = this.chatBlockOwner.nativeElement.clientWidth;
  }

  constructor(
    public dateService: DateConverterService,
    public toolbarService: ToolbarService,
    public globals: Globals,
    public router: Router,
    private modalCtrl: ModalController,
    public messageService: MessageService,
    public chatService: ChatService) {
  }

  ngOnInit() {
    this.messageService.getAnswer().subscribe(answer => this.messageReceived(answer));

    if (this.data.subscribe) {
      this.data.subscribe(res => this.chatDataHandler(res));
    } else {
      this.chatDataHandler(this.data);
    }


    this.newMessageReceivedSubscription$ = this.messageService.newMessageReceived.subscribe(() => {
      this.scrollToBottom();
    });
  }

  ngOnDestroy() {
    if(this.scrollEvent$) {
      this.scrollEvent$.unsubscribe();
    }
    this.newMessageReceivedSubscription$.unsubscribe();
    this.closeDialogue();
  }

  chatDataHandler(res) {
    this.messageService._disconnect();
    this.messageService._connect();
    this.messagesLoaded = false;
    this.globals.isFriendRequestsOpen = false;
    this.chatService.getMessageListForChat(0).subscribe((data: Page<Message>) => {
      this.messagePage = 0;
      this.currentMessagePage = data;
      this.needToScroll = true;
      this.globals.messageList = this.currentMessagePage.content.reverse();

      this.lastMessageUpdated.emit(this.currentMessagePage.content[this.currentMessagePage.content.length - 1]);
      this.maxPages = this.currentMessagePage.maxPages;
      this.messagesLoaded = true;
      this.chatService.refreshUnreadMessagesCount();

      this.scrollToBottom();

      setTimeout(() => {
        if (this.content && this.updater) {
          this.contentHeight = this.content.nativeElement.scrollHeight - this.updater.nativeElement.clientHeight;
        }

        this.scrollEvent$ = fromEvent(this.content.nativeElement, 'scroll').subscribe((e: any) => {

          if (this.updater && this.updater.nativeElement.clientHeight >= e.target.scrollTop) {
            if (!this.isUpdating && this.messagePage <= this.maxPages) {
              this.getPage();
            }
          }
        });
      }, 5);

    });
    if (res) {
      this.dialoguePartner =
        res.firstUser.id === this.globals.owner.id
          ? res.secondUser
          : res.firstUser;
      this.globals.receiver = this.dialoguePartner;
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.content && this.chatBlockOwner) {
        this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
        this.chatBlockContent = this.chatBlockOwner.nativeElement.clientWidth;
      }
    }, 5);
  }

  canViewDivider(i) {
    try {
      if (i === 0) {
        return true;
      } else {
        return !this.dateService.isSameDay(
          this.globals.messageList[i].updateDate,
          this.globals.messageList[i - 1].updateDate);
      }
    } catch (e) {
      console.log('can', e);
      return true;
    }

  }

  canViewNextImg(i) {
    if (i !== 0) {
      if (this.dateService.isSameDay(this.globals.messageList[i - 1].updateDate, this.globals.messageList[i].updateDate)) {
        return !(this.globals.messageList[i - 1].sender.id === this.globals.messageList[i].sender.id);
      }
      return true;
    }
    return true;
  }

  dividerText(i) {
    try {
      if (this.globals.messageList[i].updateDate) {
        if (i === 0) {
          if (moment(this.globals.messageList[i].updateDate).format('MMMM DD') === moment(new Date()).format('MMMM DD')) {
            return 'Today';
          } else {
            return moment(this.globals.messageList[i].updateDate).format('MMMM DD');
          }
        } else {
          return moment(this.globals.messageList[i].updateDate).format('MMMM DD');
        }
      }
    } catch (e) {
      console.log('divider', e);
      return true;
    }

  }

  closeDialogue() {
    this.messageService._disconnect();
    this.dialogueClosed.emit();
  }

  messageReceived(msgList: Array<Message>) {
    this.needToScroll = true;
    if (msgList !== undefined && msgList !== null) {
      this.lastMessageUpdated.emit(msgList[msgList.length - 1]);
    }
  }

  getPage() {
    this.messagePage++;
    this.isUpdating = true;
    this.chatService.getMessageListForChat(this.messagePage).subscribe((data: Page<Message>) => {
      this.currentMessagePage = data;
      this.maxPages = this.currentMessagePage.maxPages;
      const messages = this.currentMessagePage.content.reverse();
      this.globals.messageList = messages.concat(this.globals.messageList);
      this.chatService.refreshUnreadMessagesCount();
      this.isUpdating = false;
      setTimeout(() => {
        if (this.updater) {
          this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight - this.contentHeight - this.updater.nativeElement.clientHeight;
          this.contentHeight = this.content.nativeElement.scrollHeight - this.updater.nativeElement.clientHeight;
        }
      }, 5);

    });
  }

  ngAfterViewChecked(): void {
    if (this.chatWindow !== undefined && this.needToScroll) {
      this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
      this.needToScroll = false;
    }
  }

  redirectToFriendsPage(id: number, isMobile: boolean) {
    if (this.dialoguePartner.id === id && this.globals.isFriendRequestsOpen === false) {
      this.globals.needPageRefresh = true;
      this.router.navigate(['/users/' + id + '/notes']);
      if (isMobile) {
        this.modalCtrl.dismiss();
      }
    }
  }
}
