<app-auth-header title="Confirm account" [endBtns]="endActions"></app-auth-header>

<ion-grid class="container__profile__accept__block">
  <ion-col class="container__profile__accept" size="10">
    <ion-row class="new__profile__logo">
      <img [src]="currentUser.userPhotoName" alt="">
      <label class="new__profile__name"> {{ currentUser.firstName + ' ' + currentUser.lastName }} </label>
      <label class="new__profile__email"> {{ currentUser.email }} </label>
    </ion-row>

    <ion-row class="form__profile__accept" size="auto">
      <ion-item class="form__referrer__input">
        <img class="form__item__icon" [src]="'assets/icons/design_v4/user_icon.svg'" alt="">
        <ion-input
          type="text"
          placeholder="{{'Referrer (optional)' | translate}}"
          (ionInput)="onReferrerInput()"
          [(ngModel)]="referrerToCheck">
        </ion-input>
      </ion-item>

      <div class="privacy__policy__block">
        <ion-checkbox
          class="privacy__policy__checkbox"
          [(ngModel)]="acceptedTerms"
        ></ion-checkbox>
        <div class="policy__agree">
          <span translate>By clicking Sign Up, Google or Facebook buttons, you agree to our </span>
          <a href="/terms" translate>Terms and Conditions</a>,
          <span translate></span>and <a href="/policy" translate>Privacy Policy</a>.
        </div>
      </div>
    </ion-row>

    <ion-button
      class="form__accept__profile__button"
      color="primary"
      [disabled]="!acceptedTerms"
      (click)="confirmAccount()">
        <span
          translate
          class="form__button__text">
          Accept and continue
        </span>
    </ion-button>
  </ion-col>
</ion-grid>
