import {SiteUser} from '../site-user/site-user';

export class NoteComment {
  id: number;
  content: string;
  childComments: [];
  note: number;
  owner: SiteUser;
  parentComment: number;
  updateDate: Date;
  creationDate: Date;
}
