import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {DateConverterService} from '../../../shared/services/date-converter.service';
import {ChatService} from '../../../shared/services/chat.service';
import {UserRelationService} from '../../../shared/services/user-relation.service';
import {Chat} from '../chat';
import {Globals} from '../../../shared/globals';
import {MessageService} from '../../../shared/services/message.service';
import {SiteUser} from '../../site-user/site-user';
import {Subscription} from 'rxjs/index';
import {SharedService} from '@app/shared/services/shared.service';
import {Router} from '@angular/router';
import {SiteUserService} from '@app/shared/services/site-user.service';
import {ReceivedFromModalComponent} from '@app/modules/chat/received-from-modal/received-from-modal.component';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-friends-list',
  templateUrl: './friends-list.component.html',
  styleUrls: ['./friends-list.component.css', './../chat.component.css']
})
export class FriendsListComponent implements OnInit, OnDestroy {

  routeSubscription: Subscription;

  filterBy: Array<string> = ['recent', 'unread', 'popular'];
  friendsList: Array<SiteUser>;
  filters: any = {};
  public chatsByContent: Array<Chat>;
  public chatsByFriend: Array<Chat>;
  public activeDialogue: number = null;
  public chatList: Array<Chat> = [];
  public filteredChatList: Array<Chat>;
  public friendChatId: number;
  currentFilterType = null;
  innerWidth = 0;

  @Input() showFilter = false;
  @Output() setNewActiveDialogue = new EventEmitter();

  isInitLoading = false;
  public friend: SiteUser = null;

  constructor(
    private dateService: DateConverterService,
    public messageService: MessageService,
    public chatService: ChatService,
    public globals: Globals,
    public router: Router,
    private sharedService: SharedService,
    public userRelationService: UserRelationService,
    private userService: SiteUserService,
    private modalController: ModalController
  ) {
      router.events.subscribe((val: any) => {
        if (val.url === '/chat' && !this.isInitLoading) {
          this.init();
        }
      });
    }

  ngOnInit() {
    this.init();

    this.routeSubscription = this.sharedService.changeEmitted.subscribe(res => {
      if (res && res.action === 'open-chat') {

        if (this.chatList.filter(l => l.id === res.data.id).length === 0) {
          this.chatList = [res.data].concat(this.chatList);
        }
        this.chatService.sortByLastMsg(this.chatList);
        this.updateChatListByFilters();
      }
    });
  }

  init() {
      this.isInitLoading = true;
      this.chatService.getAllChats().subscribe((data: Chat[]) => {
        this.chatList = data;
        this.chatService.refreshUnreadMessagesCount();
        this.chatService.sortByLastMsg(this.chatList);
        this.updateChatListByFilters();
        this.isInitLoading = false;
      });
  }

  ngOnDestroy(): void {
      this.friend = null;
      this.messageService.readMessages();
      this.messageService._disconnect();
  }

  canViewDivider(i) {
      if (this.filteredChatList[i - 1] &&
        this.filteredChatList[i - 1].lastMessageDto !== null &&
        this.filteredChatList[i - 1].lastMessageDto.updateDate &&
        this.filteredChatList[i] &&
        this.filteredChatList[i].lastMessageDto !== null &&
        this.filteredChatList[i].lastMessageDto.updateDate) {
        if (i === 0 ) {
          return true;
        } else {
          return !this.dateService.isSameDay(
            this.filteredChatList[i].lastMessageDto.updateDate,
            this.filteredChatList[i - 1].lastMessageDto.updateDate);
        }
      } else {
        return false;
      }
  }

  dividerText(i) {
    if (this.filteredChatList[i].lastMessageDto.updateDate) {
      if (i === 0) {
        if (moment(this.filteredChatList[i].lastMessageDto.updateDate).format('MMMM DD') === moment(new Date()).format('MMMM DD')) {
          return 'Today';
        } else {
          return moment(this.filteredChatList[i].lastMessageDto.updateDate).format('MMMM DD');
        }
      } else {
        return moment(this.filteredChatList[i].lastMessageDto.updateDate).format('MMMM DD');
      }
    }
  }

  getChat() {
    let i = 0;
    let exist = false;
    for (let chat of this.chatList) {
      if (chat.firstUser.id === this.globals.friend.id || chat.secondUser.id === this.globals.friend.id) {
        this.friendChatId = chat.id;
        this.setActiveDialogue(i);
        exist = true;
      }
      i++;
    }
    if (!exist) {
      this.chatList.push(this.globals.currentChat);
      this.friendChatId = this.globals.currentChat.id;
      this.globals.isFriendRequestsOpen = false;
      this.activeDialogue = this.chatList.length - 1;
    }
  }

  private setActiveDialogue(dialogueIndex) {
    this.globals.isFriendRequestsOpen = false;
    this.activeDialogue = dialogueIndex;
    this.chatList[dialogueIndex].unreadCount = 0;
    this.globals.currentChat = this.chatList[dialogueIndex];

    // this.setNewActiveDialogue.emit({
    //   chatList: this.chatList[dialogueIndex],
    //   activeDialogue: this.activeDialogue
    // });

    this.sharedService.emitChange({
      data: this.chatList[dialogueIndex],
      action: 'open-chat'
    });
  }

  filterByFriend(id = null): void {
    this.filters.friendId = id;
    if (id) {
      this.chatService.getChat(id).subscribe(res => {
        this.chatsByFriend = res.totalCount ? [res] : [];
        this.updateChatListByFilters();
      });
    } else {
      this.chatsByFriend = [];
      this.updateChatListByFilters();
    }
  }

  filterByKeyword(keyword: string): void {
    keyword = keyword.length ? keyword : null;
    this.filters.keyword = keyword;
    if (keyword) {
      this.chatService.getMessagesByContent(keyword).subscribe(messages => {
        this.chatsByContent = this.chatList.filter(chatMessage => {
          const messageSenderIds = messages.map(el => el.sender.id);
          if (messageSenderIds.includes(chatMessage.firstUser.id) || messageSenderIds.includes(chatMessage.secondUser.id)) {
            return chatMessage;
          }
        });
        this.updateChatListByFilters();
      });
    } else {
      this.chatsByContent = [];
      this.updateChatListByFilters();
    }
  }

  updateChatListByFilters(): void {
    let result = this.chatList;

    if (this.filters.friendId) {
      result = this.chatsByFriend;
    }

    if (this.filters.keyword) {
      result = result.filter(message => this.chatsByContent.map(el => el.id).includes(message.id));
    }

    this.filteredChatList = result;
  }

  sortBy(filter: 'recent' | 'unread' | 'popular') {
    const currentActiveDialogue = this.chatList[this.activeDialogue];

    switch (filter) {
      case 'recent':
        this.chatList = [
          ...this.chatList
            .sort(
              (a, b) =>
                a.lastMessageDto.creationDate.valueOf() - b.lastMessageDto.creationDate.valueOf()
            )
            .reverse()
        ];
        break;
      case 'unread':
        this.chatList = [
          ...this.chatList
            .sort((a, b) => {
              return a.unreadCount - b.unreadCount;
            })
            .reverse()
        ];
        break;
      case 'popular':
        this.chatList = [
          ...this.chatList
            .sort((a, b) => {
              return a.totalCount - b.totalCount;
            })
        ];
        break;
    }

    currentActiveDialogue &&
    this.setActiveDialogue(this.chatList.indexOf(currentActiveDialogue));
    this.updateChatListByFilters();
  }

  async presentReceivedFrom() {
    const modal: any = await this.modalController.create({
      component: ReceivedFromModalComponent
    });
    modal.onWillDismiss().then(response => {
      this.friend = response.data.friend;
      let userId = null;
      if (this.friend !== null) {
        userId = this.friend.id;
      }
      this.filterByFriend(userId);
    });
    return await modal.present();
  }

  chatClosed() {
    this.friend = null;
    this.filters = [];
    this.updateChatListByFilters();
  }
}
