<ion-content>
    <div class="container" *ngIf="editorsLoaded">
        <div class="editors">
            <div *ngFor="let item of this.objDateToArr; let k = index">
                <div class="editors-category__timestamp">
                    <span>{{ item[0] }}</span>
                </div>
                <div>
                    <app-editors-row *ngFor="let subItem of item[1]; let i = index"
                                     [discuss]="discuss[i + k]"
                                     [category]="subItem"
                                     (categoryApproved)="categoryApproved($event)"
                                     class="editors-category__list"></app-editors-row>
                </div>
            </div>
        </div>
    </div>
</ion-content>
