import { Injectable } from '@angular/core';
import {ChangeModel} from "../models/change.model";
import {BehaviorSubject} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  private emitChangeSource = new BehaviorSubject<ChangeModel>(null);
  changeEmitted = this.emitChangeSource.asObservable();

  emitChange(change: ChangeModel) {
    this.emitChangeSource.next(change);
  }
}
