import {Component, OnInit, ViewChild} from '@angular/core';
import {SiteUser} from '../site-user/site-user';
import {Globals} from '../../shared/globals';
import {SiteUserService} from '../../shared/services/site-user.service';
import {Page} from '@app/core/page';
import {IonSearchbar} from '@ionic/angular';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  searchValue = '';
  pageData: Page<SiteUser>;
  @ViewChild('userSearchBar', {static: false}) userSearchBar: IonSearchbar;

  constructor(public globals: Globals, public userService: SiteUserService) {
  }

  ngOnInit() {
  }

  focusOnInput() {
    setTimeout(() => {
      this.userSearchBar.setFocus().then();
    }, 300);
  }

  ionViewDidLeave(): void {
    this.searchInputHandler('');
  }

  searchUser(value: string, page: number, callback?: any) {
    this.userService.getUsersBy(value, page).subscribe((data: Page<SiteUser>) => {
      this.pageData = data;
      this.globals.people = page === 0 ? this.pageData.content :    this.globals.people.concat(this.pageData.content);
      this.globals.isSearchingPeople = true;
      if (callback) {
        callback();
      }
    });
  }

  searchInputHandler(value: string) {
    this.searchValue = value;
    if (this.searchValue === '') {
      this.globals.isSearchingPeople = false;
      this.globals.people = this.globals.friendList;
    } else {
      this.searchUser(this.searchValue, 0);
    }
  }

  /**
   * this method is used from ViewChild in friendsComponent
   */
  nextPage(event?) {
    if (!this.pageData.last) {
      this.searchUser(this.searchValue, this.pageData.number + 1, () => {
        event.target.complete();
      });
    } else {
      if (event) {
        event.target.complete();
      }
    }
  }
}
