import {Component} from '@angular/core';
import {EditorsService} from '@app/shared/services/editors.service';
import {Page} from '@app/core/page';
import {CategoryDiscussion} from '../category/category-discussion';
import {Globals} from '@app/shared/globals';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {CanActivate, Router} from '@angular/router';
import {VotingService} from "@app/shared/services/voting-service";
import {Category} from '@app/modules/category/category';

@Component({
  selector: 'app-editors-page',
  templateUrl: './editors-page.component.html',
  styleUrls: ['./editors-page.component.scss']
})
export class EditorsPageComponent implements UpdateNavigation, CanActivate {
  recentCategories: Page<Category>;
  recentDiscussions: Page<CategoryDiscussion>;

  public currentPage = 0;

  public rows: Array<Category>;
  public discuss: Array<CategoryDiscussion>;

  public filtredRowsByDate = {};
  public objDateToArr = [];

  readonly headerActions: MenuItem[] = [{
    icon: 'assets/icons/design_v4/filter.svg',
    title: 'Filter',
    action: this.onToggleNoteFilters.bind(this),
  }];
  public editorsLoaded: boolean = false;

  constructor(public editorsService: EditorsService,
              public globals: Globals,
              private router: Router,
              private toolbarService: ToolbarService,
              private votingService: VotingService) {
  }

  ionViewWillEnter() {
    this.editorsService.getRecentPrivateCategories(0).subscribe((response: Page<Category>) => {
      this.recentCategories = response as Page<Category>;
      this.rows = this.recentCategories.content;

      this.filterRows(this.rows);
    });

    this.editorsService.getRecentPrivateCategoriesDiscussions(0).subscribe((discuss: Page<CategoryDiscussion>) => {
      this.recentDiscussions = discuss as Page<CategoryDiscussion>;
      this.discuss = this.recentDiscussions.content;
    });

    this.votingService.updateQuestionsAndAnswers();

    this.editorsService.initializeCategoryLangs().then(() => this.editorsLoaded = true);
  }

  updateNavigation(): void {
    this.toolbarService.setMenuItems(this.headerActions);
  }

  onToggleNoteFilters() {
    this.router.navigate(['note-filters']);
  }

  getPage() {
    this.currentPage++;
    this.editorsService.getRecentPrivateCategories(this.currentPage).subscribe((categories: Page<Category>) => {
      this.editorsService.getRecentPrivateCategoriesDiscussions(this.currentPage).subscribe((discuss: Page<CategoryDiscussion>) => {
        this.recentCategories = categories as Page<Category>;
        this.recentDiscussions = discuss as Page<CategoryDiscussion>;
        this.rows = this.rows.concat(this.recentCategories.content);
        this.discuss = this.discuss.concat(this.recentDiscussions.content);
      });
    });
  }

  filterRows(arr: Category[]) {
    console.log(arr, 'this.filtredRowsByDate');

    this.filtredRowsByDate = [];
    this.objDateToArr = [];
    let date;

    arr.map((x, key) => {
      date = new Date(new Date(arr[key].creationDate));
      let strDate = date.toString();
      let name = strDate.slice(4, 15).trim();

      if (!this.filtredRowsByDate.hasOwnProperty(name)) {
        this.filtredRowsByDate[name] = [x];
      } else {
        this.filtredRowsByDate[name].push(x);
      }
    });

    console.log(name, this.filtredRowsByDate);

    for (let key in this.filtredRowsByDate) {
      this.objDateToArr.push([key, this.filtredRowsByDate[key]]);
    }
    console.log(this.objDateToArr);
  }

  categoryApproved(event) {
    if (event.approved) {
      this.rows.splice(this.rows.findIndex((item: Category) => item.id === event.currentCategory), 1);
      this.discuss.splice(this.discuss.findIndex((item: CategoryDiscussion) => item.id === event.currentDiscussion), 1);
      this.filterRows(this.rows);
    }
  }

  canActivate(): boolean {
    let isActive: boolean = true;
    if (!this.globals.isAdmin && !this.globals.isEditor) {
      this.router.navigate(['/']);
      isActive = false;
    }
    return isActive;
  }
}
