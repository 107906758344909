<ng-container>
  <ng-container *ngIf="noteEntity">
    <app-auth-header title="Note" *ngIf="!globals.isAuthenticated" [endBtns]="endActions"></app-auth-header>
    <ion-content>
      <div class="post">
        <div class="post__wrap">
          <header class="post__header header">
            <div class="post__avatar"
                 (click)="navigateToNotes(true)">
              <img src="{{noteEntity.owner.userPhotoName}}" alt="">
            </div>
            <div class="header__about-author">
              <h3 class="header__about-author-name"
                  (click)="navigateToNotes(true)"
              >{{ noteEntity.owner | userName }}</h3>
              <app-category-breadcrumbs
                [categories]="noteEntity.parentCategory"
                [mod]="getMod()"
                #catBreadcrumbs
              >
              </app-category-breadcrumbs>
              <time>{{ noteEntity.creationDate | date:'d.M.yyyy'}}</time>
            </div>
            <div class="post__note-header">
              <img *ngIf="isEdit" class="category-tree-btn" (click)="editNoteCategory()" src="../../../assets/icons/design_v4/category-path-inverted.svg"/>
              <img *ngIf="!isEdit" class="color-tag" [src]="getTagIcon(noteEntity.weight)"/>
            </div>
          </header>
        </div>

        <quill-view-html appImageOpenerCust
                         class="content select-text"
          [content]="noteEntity.content"
          *ngIf="!isEdit"
        ></quill-view-html>

        <app-quill #quillComponent *ngIf="isEdit"></app-quill>
        <div class="weight-and-privacy" *ngIf="isEdit">
          <div class="significance_range_container">
            <input
              type="range"
              min="1"
              max="5"
              [(ngModel)]="editedWeightLevel"
            />
            <div class="tickmarks_container">
              <div class="tickmark"></div>
              <div class="tickmark"></div>
              <div class="tickmark"></div>
              <div class="tickmark"></div>
              <div class="tickmark"></div>
            </div>
          </div>
          <div class="button-group">
            <ng-container *ngFor="let accessType of privacy">
              <button *ngIf="accessType !== friendAccessType"
                      class="button-group__item"
                      [class.button-group__item--active]="editedAccessType === accessType"
                      (click)="changeAccessType(accessType)">
                {{ accessType| translate }}
              </button>

              <button class="button-group__item"
                      *ngIf="accessType === friendAccessType"
                      [class.button-group__item--active]="editedAccessType === accessType"
                      (click)="presentAccessTypeMenu($event)">
                {{ group| translate }}
              </button>
            </ng-container>
          </div>
        </div>


        <div class="post__info">
          <div class="post__info-item">
            <div class="icon-square view-count"><i class="far fa-angry"></i></div>
            <div class="count">{{noteEntity.views}}</div>
          </div>
          <div class="post__info-item">
            <div class="icon-square comment-count"><i class="far fa-angry"></i></div>
            <div class="count">{{commentService.getCommentCounts(noteEntity)}}</div>
          </div>
          <div class="post__info-item copy_icon" (click)="copyUsername()">
            <div class="count">
            Copy Web Link
            </div>

          </div>

        </div>

        <div class="note-list__wrapper">
          <app-note-comment-list
            [comments]="noteEntity.comments"
            [translationsText]="translationText"
            [noteEntity]="noteEntity"
            (haveAddedNewComment)="refreshComments()"
          >

          </app-note-comment-list>
        </div>


      </div>
    </ion-content>
    <app-new-note-comment *ngIf="!isEdit"
                          [noteEntity]="noteEntity"
                          (haveAddedNewComment)="refreshComments()"
    ></app-new-note-comment>
  </ng-container>

  <ion-spinner *ngIf="!noteEntity"
               class="main-loader"
               name="crescent">
  </ion-spinner>

  <ion-fab   class="add_note_small"  *ngIf="!isEdit && showButton">
    <ion-fab-button size="small" class="new_note_button_small" appButtonTouch id="new-note-button" color="light" (click)="redirectToNewNote()" >
      <img [src]="'assets/icons/design_v4/plus_btn.svg'">
    </ion-fab-button>
  </ion-fab>

</ng-container>
