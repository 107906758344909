import {Component, Inject, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActionSheetController, LoadingController, Platform, PopoverController} from '@ionic/angular';
import {ImageService} from '@app/shared/services/image.service';
import {Globals} from '@app/shared/globals';
import {ProfileService} from '@app/shared/services/profile.service';
import {DOCUMENT} from '@angular/common';
import * as $ from '../../../../assets/js/jquery-3.3.1.min.js';
import {Storage} from '@ionic/storage';
import {NavigationExtras, Router} from '@angular/router';
import {SiteUserInfo} from '@app/modules/site-user/site-user-info';
import {ImageCropComponent} from '@app/shared/components/image-crop/image-crop.component';
import {UserLanguage} from '@app/modules/site-user/user-language';
import {UserSocialLink} from '@app/modules/site-user/user-social-link';
import {TranslateService} from '@ngx-translate/core';
import {SubscriptionService} from '@app/shared/services/subscription.service';


@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.css', '../profile.component.css', '../../settings/settings.component.css']
})
export class ProfileHeaderComponent implements OnInit {
  public profileFriendsCount: number;
  public isEditUserProfileInfo = false;
  public profileNotesCount: number;
  public profileReferralCount: number;
  public selectedUserInfo: SiteUserInfo = null;
  public profileHeaderIsLoaded = false;
  userId: number;

  @Input() isEdit: boolean;

  popover: any;
  loading: any;

  profileHeaderForm: FormGroup;
  public translationText: string[] = [];

  constructor(
    private loadingController: LoadingController,
    public popoverController: PopoverController,
    private platform: Platform,
    public actionSheetController: ActionSheetController,
    private imageService: ImageService,
    public globals: Globals,
    public profileService: ProfileService,
    private storage: Storage,
    @Inject(DOCUMENT) public document: Document,
    private formBuilder: FormBuilder,
    public router: Router,
    private translationService: TranslateService,
    private subscriptionService: SubscriptionService
  ) {
    this.profileHeaderForm = this.formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      status: [null, [Validators.required]],
      profession: [null, [Validators.required]],
    });
    this.updateTranslations();
    this.translationService.onLangChange.subscribe((event) => {
      this.updateTranslations();
    });
  }

  init(userId: number, userInfo?: SiteUserInfo) {
    this.userId = userId;
    if (userInfo === undefined) {
      this.profileService.getUserInfoById(this.userId).subscribe((data: SiteUserInfo) => {
        this.selectedUserInfo = data;
        this.initializeFields();
        this.profileHeaderIsLoaded = true;
      });
    } else {
      this.selectedUserInfo = userInfo;
      this.initializeFields();
      this.profileHeaderIsLoaded = true;
    }
  }

  ngOnInit() {
  }

  async saveInfo(profileForm: FormGroup, profileLanguages: Array<UserLanguage>, socialNetworks: UserSocialLink[]) {
    this.loading = await this.loadingController.create({
      message: 'Updating...',
    });

    await this.loading.present();

    if (this.globals.owner.firstName !== this.profileHeaderForm.value.firstName ||
      this.globals.owner.lastName !== this.profileHeaderForm.value.lastName) {
      this.globals.owner.firstName = this.profileHeaderForm.value.firstName;
      this.globals.owner.lastName = this.profileHeaderForm.value.lastName;
      this.profileService.editSiteUserName();
      this.selectedUserInfo.owner = this.globals.owner;
      await this.storage.set('wita-user', this.globals.owner);
    }
    this.selectedUserInfo.status = this.profileHeaderForm.value.status;
    this.selectedUserInfo.profession = this.profileHeaderForm.value.profession;
    this.selectedUserInfo.city = profileForm.value.location;
    this.selectedUserInfo.country = profileForm.value.country;
    this.selectedUserInfo.birthDate = profileForm.value.dateOfBirth;
    this.selectedUserInfo.owner.ssoId = profileForm.value.ssiod;

    this.checkEmptyLanguagesInList(profileLanguages, socialNetworks);
    this.selectedUserInfo.userLanguageDtos = profileLanguages;
    this.selectedUserInfo.userSocialLinkDtos = socialNetworks;

    this.profileService.editUserProfileInfo(this.selectedUserInfo, () => {
      this.loading.dismiss();
      this.isEditUserProfileInfo = false;
    });
  }

  checkEmptyLanguagesInList(profileLanguages: Array<UserLanguage>, socialNetworks: UserSocialLink[]) {
    let i = 0;
    for (const lang of profileLanguages) {
      if (lang.language == null || lang.proficiency == null || lang.language.id == undefined || lang.proficiency.id == undefined) {
        profileLanguages.splice(i, 1);
      }
      i++;
    }

    i = 0;
    for (const e of socialNetworks) {
      delete e.isEditing;
      if (e.network === null || e.link === '') {
        socialNetworks.splice(i, 1);
      }
      i++;
    }
  }

  async selectImage(imgFor) {
    if (this.platform.is('cordova')) {
      const actionSheet = await this.actionSheetController.create({
        header: 'Select Image source',
        buttons: [{
          text: 'Load from Library',
          handler: () => {
            this.imageService.pickImage('lib').then(img => {
              this.imageProcessing(img, imgFor);
            });
          }
        },
          {
            text: 'Use Camera',
            handler: () => {
              this.imageService.pickImage('cam').then(img => {
                this.imageProcessing(img, imgFor);
              });
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      });
      await actionSheet.present();
    } else {

      const selectDialogueLink = $('<a href=""></a>');
      const input = imgFor === 'profile' ? '#userPhotoUploader' : '#userBannerUploader';
      const fileSelector = $(input);

      selectDialogueLink.click(() => {
        fileSelector.click();
        return false;
      });

      selectDialogueLink.click();
    }
  }

  async processFile(event, imgFor) {
    const target = event.target as HTMLInputElement;
    const selectedFile: any = target.files[0];
    const reader = new FileReader();

    reader.onloadend = async () => {
      await this.imageProcessing(reader.result, imgFor);
    };

    reader.readAsDataURL(selectedFile);
  }

  async imageProcessing(img, imgFor) {

    const callback = async (file: any) => {

      this.loading = await this.loadingController.create({
        message: 'Uploading...',
      });
      await this.loading.present();

      const formData = new FormData();
      let uploadHandler: Observable<any>;

      formData.append('file', file, `${new Date().toISOString()}.jpeg`);

      if (imgFor === 'profile') {
        const userBlob = new Blob([JSON.stringify(this.globals.owner)], {type: 'application/json'});
        formData.append('user', userBlob);

        uploadHandler = this.profileService.editUserPhoto(formData);
      } else {
        const userBlob = new Blob([JSON.stringify(this.globals.selectedUserInfo)], {type: 'application/json'});
        formData.append('info', userBlob);

        uploadHandler = this.profileService.editUserBanner(formData);
      }

      uploadHandler.subscribe((data: any) => {

        if (imgFor === 'profile') {
          this.globals.owner.userPhotoName = data.userPhotoName;
          this.selectedUserInfo.owner.userPhotoName = this.globals.owner.userPhotoName;
          this.storage.set('wita-user', this.globals.owner);
        } else {
          this.globals.selectedUserInfo.userBannerName = data.userBannerName;
          this.selectedUserInfo.userBannerName = this.globals.selectedUserInfo.userBannerName;
        }
        this.loading.dismiss();
        this.popover.dismiss();
      });
    };

    this.popover = await this.popoverController.create({
      component: ImageCropComponent,
      cssClass: 'center-popover',
      backdropDismiss: false,
      componentProps: {
        img,
        cantResize: imgFor === 'profile',
        callback,
        dismiss: () => {
          this.popover.dismiss();
        }
      },
      translucent: true
    });

    await this.popover.present();
  }

  initializeFields() {
    this.profileHeaderForm.controls['status'].setValue(this.selectedUserInfo.status);
    this.profileHeaderForm.controls['firstName'].setValue(this.selectedUserInfo.owner.firstName);
    this.profileHeaderForm.controls['lastName'].setValue(this.selectedUserInfo.owner.lastName);
    this.profileHeaderForm.controls['profession'].setValue(this.selectedUserInfo.profession);
    this.profileFriendsCount = this.selectedUserInfo.friendsCount;
    this.profileReferralCount = this.selectedUserInfo.referralCount;
    this.profileNotesCount = this.selectedUserInfo.totalNotesCount === null ? 0 : this.selectedUserInfo.totalNotesCount;
  }

  deleteUserBanner() {
    this.selectedUserInfo.userBannerName = null;
    this.profileService.editUserProfileInfo(this.selectedUserInfo, () => {
    });
  }

  navigateToFriends() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: this.globals.selectedUser.id
      }
    };
    this.router.navigate(['/friends'], navigationExtras);
  }

  navigateToNotes() {
    if (this.globals.selectedUser.id === this.globals.owner.id) {
      this.router.navigate(['/']);
    }
  }

  navigateToProfile() {
    if (this.globals.selectedUser.id === this.globals.owner.id) {
      this.router.navigate(['/profile']);
    }
  }

  private updateTranslations() {
    const translationsSub = this.translationService.get([
      'Status',
      'First name',
      'Profession',
      'Last name',
      'Friends',
      'Notes',
      'Referrals',
    ]).subscribe(tr => this.translationText = tr);
    this.subscriptionService.unSubscriber(translationsSub);
  }
}
