import {SiteUser} from '../site-user/site-user';

export class CategoryComment {
  id: number;
  content: string;
  categoryDiscussion: number;
  owner: SiteUser;
  updateDate: Date;
  creationDate: Date;
  childComments: Array<CategoryComment>;
  parentComment: number;
}
