import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LevelDirective} from '@app/directives/level.directive';
import {Level} from './Level';
import {LevelComponent} from './levelComponent';
import {CategoryChanged} from '@app/modules/category-slider/CategoryChanged';

@Component({
  selector: 'app-category-level',
  templateUrl: './category-level.component.html',
  styleUrls: ['./category-level.component.scss']
})
export class CategoryLevelComponent implements OnInit {
  @Input() level: Level;
  @Input() activeCategoryId: number;

  @Output() selectedCategory = new EventEmitter<number>();
  @Output() showAddNewCategoryBlock = new EventEmitter<null>();

  @Output() categoryChanged = new EventEmitter<CategoryChanged>();

  @ViewChild(LevelDirective, {static: true}) directive: LevelDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.level.component);

    const viewContainerRef = this.directive.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as LevelComponent).levelData = this.level.levelData;

    if (componentRef.instance.hasOwnProperty('selectedCategory')) {
      componentRef.instance.selectedCategory.subscribe((id: number) => {
        this.selectedCategory.emit(id);
      });
    }

    if (componentRef.instance.hasOwnProperty('showAddNewCategoryBlock')) {
      componentRef.instance.showAddNewCategoryBlock.subscribe(() => {
          this.showAddNewCategoryBlock.emit();
      });
    }

    if (componentRef.instance.hasOwnProperty('categoryChanged')) {
      componentRef.instance.categoryChanged.subscribe((obj) => {
        this.categoryChanged.emit(obj);
      });
    }
  }
}
