import { Component, Input } from '@angular/core';
import { NoteComment } from '../note-comment/note-comment';
import { Note } from "@app/modules/note/note";
import * as i0 from "@angular/core";
function ChildNoteCommentListComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "app-child-note-comment", 3);
    i0.ɵɵlistener("haveDeletedComment", function ChildNoteCommentListComponent_div_0_Template_app_child_note_comment_haveDeletedComment_2_listener($event) { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.deletedComment($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const child_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("currentCommentFromChildList", child_r1)("noteEntity", ctx_r0.noteEntity)("translationsText", ctx_r0.translationsText);
} }
export class ChildNoteCommentListComponent {
    constructor() {
    }
    ngOnInit() {
    }
    deletedComment(comment) {
        this.passCurrentParentComment.childComments.splice(this.passCurrentParentComment.childComments.findIndex((item) => item.id === comment.id), 1);
    }
}
ChildNoteCommentListComponent.ɵfac = function ChildNoteCommentListComponent_Factory(t) { return new (t || ChildNoteCommentListComponent)(); };
ChildNoteCommentListComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ChildNoteCommentListComponent, selectors: [["app-child-note-comment-list"]], inputs: { passCurrentParentComment: "passCurrentParentComment", noteEntity: "noteEntity", translationsText: "translationsText" }, decls: 1, vars: 1, consts: [["class", "sub_comments", 4, "ngFor", "ngForOf"], [1, "sub_comments"], [1, "comment_wrapper"], [3, "currentCommentFromChildList", "noteEntity", "translationsText", "haveDeletedComment"]], template: function ChildNoteCommentListComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, ChildNoteCommentListComponent_div_0_Template, 3, 3, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngForOf", ctx.passCurrentParentComment.childComments);
    } }, styles: [""] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ChildNoteCommentListComponent, [{
        type: Component,
        args: [{
                selector: 'app-child-note-comment-list',
                templateUrl: './child-note-comment-list.component.html',
                styleUrls: ['./child-note-comment-list.component.css']
            }]
    }], function () { return []; }, { passCurrentParentComment: [{
            type: Input
        }], noteEntity: [{
            type: Input
        }], translationsText: [{
            type: Input
        }] }); })();
