<div class="comments" *ngFor="let comment of comments">
  <app-note-comment
    [currentParentComment]="comment"
    [noteEntity]="noteEntity"
    [translationsText]="translationsText"
    (haveDeletedComment)="deletedComment($event)"
    (haveAddedNewComment)="addedNewComment($event)"
  ></app-note-comment>
</div>


