import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
export class PasswordResetService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    validateToken(id, token) {
        return this.http.get(this.globals.apiUrl + '/accounts/reset-password/token/' + token + '/users/' + id, { observe: 'response' });
    }
    sendResetMessage(params) {
        return this.http.post(this.globals.apiUrl + '/accounts/reset-password/request', null, { params });
    }
    changeUserPassword(password, userId) {
        return this.http.put(this.globals.apiUrl + '/accounts/reset-password/save/users/' + userId, password);
    }
    checkRegisterEmail(email) {
        return this.http.get(`${this.globals.apiUrl}/accounts/email/check?email=${email}`);
    }
    sendResetEmail(email) {
        return this.http.post(`${this.globals.apiUrl}/accounts/reset-password/request?email=${email}`, null);
    }
}
PasswordResetService.ɵfac = function PasswordResetService_Factory(t) { return new (t || PasswordResetService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
PasswordResetService.ɵprov = i0.ɵɵdefineInjectable({ token: PasswordResetService, factory: PasswordResetService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(PasswordResetService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
