import {Component, OnInit} from '@angular/core';
import {Globals} from '@app/shared/globals';
import {SiteUser} from '@app/modules/site-user/site-user';
import {Chat} from '@app/modules/chat/chat';
import {ChatService} from '@app/shared/services/chat.service';
import {SharedService} from '@app/shared/services/shared.service';
import {ModalController} from '@ionic/angular';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {UserRelationService} from '@app/shared/services/user-relation.service';
import {Page} from '@app/core/page';
import {SiteUserService} from '@app/shared/services/site-user.service';

@Component({
  selector: 'app-new-chat-modal',
  templateUrl: './new-chat-modal.component.html',
  styleUrls: ['./new-chat-modal.component.css']
})
export class NewChatModalComponent implements OnInit {

  friends: SiteUser[] = [];
  filteredFriends: SiteUser[] = [];
  disabledSearch: boolean;
  isLoading = true;
  private pageData: Page<SiteUser>;
  searchValue = '';

  constructor(
    public globals: Globals,
    public relationsService: UserRelationService,
    public toolbarService: ToolbarService,
    public chatService: ChatService,
    public sharedService: SharedService,
    public modalCtrl: ModalController,
    private userService: SiteUserService,
  ) {
  }

  ngOnInit() {
    this.searchUser('', 0);
  }

  searchUser(value: string, page: number, callback?: any) {
    this.isLoading = true;
    this.userService.getUsersBy(value, page, 2).subscribe((data: Page<SiteUser>) => {
      this.pageData = data;
      this.friends = page === 0 ? this.pageData.content : this.friends.concat(this.pageData.content);
      this.isLoading = false;
      if (callback) {
        callback();
      }
    });
  }

  doRefreshContent(event) {
    this.searchUser('', 0, () => {
      event.target.complete();
    });
  }

  nextPage(event?) {
    if (!this.pageData.last) {
      this.searchUser(this.searchValue, this.pageData.number + 1, () => {
        event.target.complete();
      });
    } else {
      if (event) {
        event.target.complete();
      }
    }
  }

  searchInputHandler(event) {
    this.searchValue = event;
    this.searchUser(this.searchValue, 0);
  }

  onItemClickHandler(friend) {
    this.globals.friend = friend;
    this.chatService.getChat(friend.id).subscribe((data: Chat) => {
      this.globals.currentChat = data;
      this.sharedService.emitChange({
        data: data,
        action: 'open-chat'
      });
      this.modalCtrl.dismiss();
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
