import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {

  constructor(
    private toolbarService: ToolbarService,
    public router: Router) {
    this.toolbarService.setHeaderVisibility(false);
  }

  navigateTo = async (url) => await this.router.navigateByUrl(url);

  ngOnInit() {
  }
}
