import {Injectable} from '@angular/core';
import {AuthService, AuthServiceConfig, GoogleLoginProvider, SocialUser} from 'angularx-social-login';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '@app/shared/globals';
import {SiteUser} from '@app/modules/site-user/site-user';
import {Router} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {LoadingController, Platform} from '@ionic/angular';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {Storage} from '@ionic/storage';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuService} from '@app/shared/services/menu/menu.service';
import {TokensService} from '@app/shared/services/tokens.service';
import {ProfileService} from '@app/shared/services/profile.service';
import {UiAlertService} from '@app/shared/services/ui-alert-service';
import {OneSignalService} from '@app/shared/services/one-signal.service';

@Injectable()
export class GoogleAuthService extends AuthService {

  constructor(
    config: AuthServiceConfig,
    public http: HttpClient,
    public globals: Globals,
    public router: Router,
    public gPlus: GooglePlus,
    public platform: Platform,
    public storage: Storage,
    private toolbarService: ToolbarService,
    private menuService: MenuService,
    private uiAlertService: UiAlertService,
    public authenticationService: AuthenticationService,
    private tokensService: TokensService,
    private profileService: ProfileService,
    public loadingController: LoadingController,
    private oneSignalService: OneSignalService) {
    super(config);
  }

  async authByGoogle(siteUser, needRegister): Promise<any> {
    const loading = await this.loadingController.create({});
    await loading.present();
    this.globals.owner = siteUser;

    let params = new HttpParams();
    params = params.append('currentLanguage', this.globals.currentLanguage);
    params = params.append('needRegister', needRegister);
    params = params.append('deviceId', this.oneSignalService.getDeviceId());

    return this.http.post(this.globals.apiUrl + '/accounts/login/social', siteUser, {params})
      .toPromise()
      .then(async (response: SiteUser) => {
        await this.loadingController.dismiss();
        if (response !== null) {
          this.tokensService.setTokens(response.witaAuthToken);
          localStorage.setItem('wita-user', JSON.stringify(response));
          this.storage.set('wita-user', response);
          this.globals.owner = response as SiteUser;
          this.authenticationService.afterLoginInitiation();
        }
      }).catch(async (er) => {
        console.log('throw error after social login request inside google auth service');
        await this.loadingController.dismiss();
        throw er;
      });
  }

  async accessWithGoogle(referrer: string, needRegister: boolean) {
    if (this.platform.is('cordova')) {
      const loading = await this.loadingController.create({});
      await loading.present();
      this.gPlus.login({
        'webClientId': '179659201381-kma03s41hgtc2b4oio8fotd5faolc2r0.apps.googleusercontent.com',
        'offline': true,
        'scopes': 'profile email'
      }).then((res: any) => {
        const siteUser = new SiteUser({
          firstName: res.givenName,
          lastName: res.familyName,
          ssoId: res.userId,
          email: res.email,
          userPhotoName: res.imageUrl
        });
        loading.dismiss();
        this.authByGoogle(siteUser, needRegister).then(() => {
          this.profileService.setReferrer(this.globals.owner, referrer);
          this.router.navigate(['/']);
          this.toolbarService.setHeaderVisibility(true);
          this.menuService.activeMainMenu();
        }).catch(async (er) => {
          await this.authenticationService.checkConfirmationRequired(er);
        });
      }).catch(async (er) => {
        await this.authenticationService.checkConfirmationRequired(er);
      });
    } else {
      this.signIn(GoogleLoginProvider.PROVIDER_ID, {
        prompt: 'select_account'
      }).then((user: SocialUser) => {
        if (user !== null) {

          const siteUser = new SiteUser({
            firstName: user.firstName,
            lastName: user.lastName,
            ssoId: user.id,
            email: user.email,
            userPhotoName: user.photoUrl
          });

          this.authByGoogle(siteUser, needRegister)
            .then(() => {
              console.log("Referrer is " + referrer);
              this.profileService.setReferrer(this.globals.owner, referrer);
              this.router.navigate(['/']);
              this.toolbarService.setHeaderVisibility(true);
              this.menuService.activeMainMenu();
            })
            .catch(async (er) => {
              await this.authenticationService.checkConfirmationRequired(er);
            });
        }
      });
    }
  }
}
