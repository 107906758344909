import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NoteCommentService} from '../../shared/services/note-comment.service';
import {Globals} from '../../shared/globals';
import {TranslateService} from '@ngx-translate/core';
import {NoteComment} from '../note-comment/note-comment';
import {Router} from '@angular/router';
import {Note} from '@app/modules/note/note';
import {Platform} from '@ionic/angular';
import {NoteComponent} from '@app/modules/note/note.component';

@Component({
  selector: 'app-new-note-comment',
  templateUrl: './new-note-comment.component.html',
  styleUrls: ['./new-note-comment.component.scss']
})
export class NewNoteCommentComponent implements OnInit {
  @Input() passParentComment;
  @Input() noteEntity: Note;

  @Output() haveAddedNewComment = new EventEmitter<boolean>();

  @ViewChild('comment', {static: false}) commentInput: ElementRef;

  public commentText = '';
  private disabledNewButton = false;

  showEmojiPicker = false;
  selectionStart = 0;

  constructor(public translate: TranslateService,
              public noteCommentService: NoteCommentService,
              public platform: Platform,
              public globals: Globals,
              public router: Router,
              public noteComponent: NoteComponent) {
  }

  ngOnInit() {
  }

  focusOnInput() {
    this.commentInput.nativeElement.focus();
  }

  addNewComment() {
    if (!this.globals.isAuthenticated) {
      this.router.navigateByUrl('/auth').then(r => console.log(r));
      return;
    }
    if (!this.disabledNewButton) {
      const newComment = this.prepareNewComment();
      this.disabledNewButton = true;
      this.noteCommentService.addComment(newComment, response => {
        this.haveAddedNewComment.emit(true);
        this.disabledNewButton = false;
        this.commentText = ' ';
        this.focusInput(false);
      });
      this.showEmojiPicker = false;
    }
  }

  inputMessageHandler(e) {
    this.selectionStart = e.target.selectionStart;
  }

  prepareNewComment() {
    const value: NoteComment = new NoteComment();
    value.content = this.commentText;
    value.note = this.noteEntity.id;
    value.owner = this.globals.owner;

    if (this.passParentComment !== undefined) {
      value.parentComment = this.passParentComment.id;
    }

    return value;
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
    this.noteComponent.emojiWasClicked = !this.noteComponent.emojiWasClicked;
    this.noteComponent.showPlusButton();
  }

  emojiHandler(event: any) {
    this.commentText += event[0];
  }

  focusInput(isOn: boolean) {
    this.noteComponent.inputOnFocus = isOn;
    this.noteComponent.showPlusButton();
  }
}
