import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../globals';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {
  constructor(public http: HttpClient, public globals: Globals) {
  }

  public validateToken(id: string, token: string): Observable<any> {
    return this.http.get(this.globals.apiUrl + '/accounts/reset-password/token/' + token + '/users/' + id, {observe: 'response'});
  }

  public sendResetMessage(params: HttpParams): Observable<any> {
    return this.http.post(this.globals.apiUrl + '/accounts/reset-password/request', null, {params});
  }

  public changeUserPassword(password: string, userId: string) {
    return this.http.put(this.globals.apiUrl + '/accounts/reset-password/save/users/' + userId, password);
  }

  checkRegisterEmail(email: string): Observable<any> {
    return this.http.get(`${this.globals.apiUrl}/accounts/email/check?email=${email}`);
  }

  public sendResetEmail(email: string): Observable<any> {
    return this.http.post(`${this.globals.apiUrl}/accounts/reset-password/request?email=${email}`, null);
  }

}
