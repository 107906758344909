import { Directive, ViewContainerRef } from '@angular/core';
import * as i0 from "@angular/core";
export class LevelDirective {
    constructor(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
}
LevelDirective.ɵfac = function LevelDirective_Factory(t) { return new (t || LevelDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef)); };
LevelDirective.ɵdir = i0.ɵɵdefineDirective({ type: LevelDirective, selectors: [["", "appLevel", ""]] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LevelDirective, [{
        type: Directive,
        args: [{
                selector: '[appLevel]'
            }]
    }], function () { return [{ type: i0.ViewContainerRef }]; }, null); })();
