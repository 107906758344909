import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Globals } from '@app/shared/globals';
import * as i0 from "@angular/core";
import * as i1 from "@app/shared/globals";
export class ToolbarService {
    constructor(globals) {
        this.globals = globals;
        this.itemsSource = new Subject();
        this.mainBtnSource = new Subject();
        this.titleSource = new Subject();
        this.headerVisibilitySource = new Subject();
        this.searchbarSource = new Subject();
        this.items$ = this.itemsSource.asObservable();
        this.title$ = this.titleSource.asObservable();
        this.mainBtn$ = this.mainBtnSource.asObservable();
        this.headerVisibility$ = this.headerVisibilitySource.asObservable();
        this.searchbar$ = this.searchbarSource.asObservable();
        this.isNewIphone = false;
        this.newIphone = ['828x1792', '1242x2688', '828x1792', '1125x2436', '1242x2688', '1125x2436'];
        const w = window;
        const physicalScreenWidth = w.screen.width * w.devicePixelRatio;
        const physicalScreenHeight = w.screen.height * w.devicePixelRatio;
        const currentDevice = physicalScreenWidth + "x" + physicalScreenHeight;
        this.isNewIphone = this.newIphone.filter(i => i === currentDevice).length !== 0;
        if (physicalScreenWidth > 1242 || physicalScreenWidth < 750) {
            this.isNewIphone = false;
        }
    }
    setTitle(title) {
        this.titleSource.next(title);
    }
    setMenuItems(items) {
        this.itemsSource.next(items);
    }
    setHeaderVisibility(isVisible) {
        this.headerVisibilitySource.next(isVisible);
    }
    setMainHeaderBtn(item) {
        this.mainBtnSource.next(item);
    }
    setSearchbar(item) {
        this.searchbarSource.next(item);
    }
    refreshMenuItems() {
        for (const item of this.globals.currentHeader) {
            if (item.title === 'Notifications') {
                item.icon = this.globals.unreadNotificationCount > 0 ?
                    'assets/icons/design_v4/notifications-sharp-unread.svg' : 'assets/icons/design_v4/notifications-sharp.svg';
            }
            if (item.title === 'Messages') {
                item.icon = this.globals.notificationCount > 0 ?
                    'assets/icons/design_v4/messages-unread.svg' : 'assets/icons/design_v4/messages.svg';
            }
        }
        this.setMenuItems(this.globals.currentHeader);
    }
}
ToolbarService.ɵfac = function ToolbarService_Factory(t) { return new (t || ToolbarService)(i0.ɵɵinject(i1.Globals)); };
ToolbarService.ɵprov = i0.ɵɵdefineInjectable({ token: ToolbarService, factory: ToolbarService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ToolbarService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.Globals }]; }, null); })();
