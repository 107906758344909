<app-auth-header title="Sign in" [endBtns]="endActions"></app-auth-header>
<ion-content>

<div class="login">
  <div class="login__logo">
    <img
      [src]="'assets/icons/design_v4/logo.svg'"
      alt="">
  </div>

  <div class="login__form">

    <form
      class="form"
      [formGroup]="loginForm"
      role="form">

      <div class="form__item">
        <img
          class="form__item__icon"
          [src]="'assets/icons/design_v4/user_icon.svg'"
          alt="">
        <input
          class="form__item__input"
          type="username"
          placeholder="{{'Username' | translate}}"
          id="username"
          name="username"
          formControlName="username"/>
      </div>

      <div class="form__item">
        <img
          class="form__item__icon"
          [src]="'assets/icons/design_v4/lock_icon.svg'"
          alt="">
        <input
          class="form__item__input"
          formControlName="password"
          type="password"
          placeholder="{{'Password' | translate}}"
          id="password"
          name="password"/>
      </div>

      <div *ngIf="app.isWrongPassword" class="form__error" translate>Username or password is wrong</div>

      <ion-button
        class="form__button"
        [disabled]="loginForm.invalid"
        color="primary"
        (click)="login()">
        <span
          class="form__button__text"
          translate>
          Sign in
        </span>
      </ion-button>

      <a
        routerLink="/forgot-password"
        class="form__forgot-password"
        translate>
        Forgot password?
      </a>

    </form>


  </div>

  <div class="social-login">

    <ion-fab class="social-login__actions"  vertical="bottom" horizontal="center">
      <ion-fab-button (click)="loginWithGoogle()">
        <i class="fa fa-google-plus fa-sm social-login__icon"></i>
      </ion-fab-button>
      <ion-fab-button (click)="loginWithFb()">
        <i class="fa fa-facebook fa-sm social-login__icon"></i>
      </ion-fab-button>
      <!--<ion-fab-button (click)="loginWithAppleID()">-->
        <!--<i class="fa fa-apple fa-sm social-login__icon"></i>-->
      <!--</ion-fab-button>-->
    </ion-fab>


  </div>


</div>

</ion-content>
