import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '@app/shared/globals';
import {Tokens} from '@app/shared/models/tokens';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TokensService {

  constructor(public http: HttpClient, public globals: Globals) {
  }

  setTokens(data) {
    const tokens = new Tokens({
      jwtToken: data.jwtToken,
      refreshToken: data.refreshToken,
      liveTimeSec: data.liveTimeSec,
      ssoId: data.ssoId,
      createdDate: new Date().toISOString(),
    });
    localStorage.setItem('tokens', JSON.stringify(tokens));
  }

  isExpired() {
    const tmp = JSON.parse(localStorage.getItem('tokens'));
    if (!tmp) {
      return false;
    }
    const tokens = new Tokens(tmp);
    const a = moment(new Date(tokens.createdDate));
    const b = moment(new Date());
    return b.diff(a, 'seconds') > tokens.liveTimeSec;
  }
}
