import { Component, OnInit } from '@angular/core';
import {VotingQuestion} from "@app/modules/voting-question/voting-question";
import {HttpClient} from "@angular/common/http";
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";
import {VotingService} from "@app/shared/services/voting-service";
import {MenuItem} from "@app/shared/models/menu/MenuItem";
import {Globals} from "@app/shared/globals";
import {CanActivate, Router} from "@angular/router";

@Component({
  selector: 'app-voting-panel',
  templateUrl: './voting-panel.component.html',
  styleUrls: ['./voting-panel.component.scss']
})
export class VotingPanelComponent implements OnInit, CanActivate {
  public questionsList: VotingQuestion[];
  public question: string = "";
  private idList: number[] = [];
  private questionText: string = "";
  private id: number;
  private isEditing: boolean = false;

  headerActions: MenuItem[] = [
    {
      title: 'Back',
      path: '/admin',
      icon: 'assets/icons/design_v4/back_icon.svg',
    },
    {
      title: 'Messages',
      path: '/chat',
      icon: 'assets/icons/design_v4/messages.svg'
    }
  ];

  constructor(public http: HttpClient,
              private toolbarService: ToolbarService,
              private votingService: VotingService,
              public globals: Globals,
              public router: Router
  ) { }

  ngOnInit() {
    this.updateQuestions();
  }

  updateQuestions() {
    this.idList = [];
    this.questionsList = [];
    this.votingService.getAllQuestions().subscribe((res: VotingQuestion[]) => {
      this.questionsList = res.sort((a: VotingQuestion, b: VotingQuestion) => {
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });
      for (let obj of res) {
        this.idList.push(obj.id);
      }
    });

    this.question = "";
    this.id = null;
  }

  updateNavigation(): void {
    this.toolbarService.setMenuItems(this.headerActions);
  }

  addQuestionToVotingQuestions() {
    let votingQuestion: VotingQuestion = new VotingQuestion();

    votingQuestion.questionText = this.question;
    votingQuestion.important = false;

    this.votingService.sendQuestionToVoting(votingQuestion).subscribe(() => {
      this.updateQuestions();
    });
    this.question = "";
  }

  deleteVotingQuestion(id) {
    this.votingService.deleteQuestionById(id).subscribe(() => {
      this.updateQuestions();
    });
  }

  changeData(item) {
    this.isEditing = true;
    this.id = item.id;
    this.questionText = item.questionText;
  }

  updateVotingQuestion(question) {
    question.questionText = this.questionText;

    this.votingService.editQuestion(question).subscribe(() => {
      this.updateQuestions();
    });

    this.id = null;
    this.questionText = "";
    this.isEditing = false;
  }

  importantQuestion(question: VotingQuestion) {
    if (question.important) {
      return 'warning';
    }
    return 'white';
  }

  makeImportant(question: VotingQuestion) {
    question.important = !question.important;
    this.votingService.editQuestion(question).subscribe(() => {
      this.updateQuestions();
    });
  }

  canActivate(): boolean {
    let isActive: boolean = true;
    if (!this.globals.isAdmin) {
      this.router.navigate(['/']);
      isActive = false;
    }
    return isActive;
  }

}
