<ion-header [ngStyle]="{'height': toolbarService.isNewIphone ? '74px' : '54px'}">
  <ion-toolbar [ngStyle]="{'padding-top': toolbarService.isNewIphone ? '30px' : '10px', 'height': toolbarService.isNewIphone ? '74px' : '54px'}" color="primary">
    <ion-buttons class="left-actions ml-3" slot="start">
      <ion-button shape="round" (click)="dismiss()">
        <img [src]="'assets/icons/design_v4/back_icon.svg'"/>
      </ion-button>
    </ion-buttons>

    <ion-title class="ion-text-center" style="color: white" translate>Editors</ion-title>
    <ng-container *ngIf="!globals.isAdmin">
      <ion-buttons slot="end"
                   *ngFor="let navItem of editorModeNav"
                   (click)="navItem.action()">
        <img class="action-button mx-2"
             [src]="navItem.icon"/>
      </ion-buttons>
    </ng-container>
    <ng-container *ngIf="globals.isAdmin">
      <ion-buttons slot="end"
                   *ngFor="let navItem of adminModeNav"
                   (click)="navItem.action()">
        <img class="action-button mx-2"
             [src]="navItem.icon"/>
      </ion-buttons>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content class="editors-category-item">
    <ion-spinner *ngIf="!discuss"
                 class="main-loader"
                 name="crescent"
    ></ion-spinner>


    <div class="editors-category__edit-mode" *ngIf="false">
        <div>
            <div *ngFor="let item of keys()">
                <ul class="editors-category__item">
<!--                    *ngIf="objIterable[item] === truncatedItems">-->
                    <li class="editors-category__link"
                        *ngFor="let name of objIterable[item]">
                        <span>{{name}}</span>

                        <div *ngIf="this.globals.isEditor" class="editors-category__edit">
                            <i class="icon-edit"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="editors-category__posts-of-category">

            <div class="edit-translate__list">
                <div *ngFor="let lang of langs.keys()" class="edit-translate__item">
                    <span class="editors-category__lang">{{ lang }}</span>

                    <div class="editors-category__translation"
                         [class.editors-category__translation-edit]="lang === selectedLang">
                        <div class="editors-category__translation-base-mode"
                             *ngIf="lang !== selectedLang">
                            <div class="editors-category__name-item">
                                <!--{{this.objIterable.truncatedItems[objIterable.truncatedItems.length - 1]}}-->

                                {{this.objIterable.translate[lang]}}
                            </div>

                            <div *ngIf="this.globals.isEditor"
                                 (click)="changeSelectedLang(lang)"
                                 class="editors-category__edit">
                                <i class="icon-edit"></i>
                            </div>
                        </div>

                        <div *ngIf="lang === selectedLang">
                            <input type="text"
                                   placeholder="Translate"
                                   appCustomAutofocus
                                   (input)="translateLang($event)">

                            <button type="button"
                                    class="btn btn--close"
                                    (click)="hideTranslate(lang)">
                                <img class="icon-close" [src]="'assets/icons/design_v4/close.png'" alt="">
                            </button>
                            <!--<button type="button"
                                    class="btn btn&#45;&#45;apply"
                                    (click)="updateTranslate(lang)">
                                <img [src]="'assets/icons/design_v4/check-aqua.svg'" alt="">
                            </button>-->
                        </div>
                    </div>
                </div>
            </div>

            <div class="editors-category__translate">
                <i class="icon-translate"></i>
                <span>Translate</span>
            </div>
        </div>



        <!-- -------- like/dislike block ----------- -->
        <div class="editors-category__likes" *ngIf="true">
            <div [ngClass]="vote === 1 ? 'active' : ''"
                 class="vote upvote editors-category__likes-item"
                 (click)="toggleVote(1)">
                <img [src]="'assets/icons/design_v4/icon-like.svg'"
                     alt="vote icon"/>
                {{ upvotes }}
            </div>
            <div (click)="toggleVote(-1)"
                 [ngClass]="vote === -1 ? 'active' : ''"
                 class="vote downvote editors-category__likes-item">
                <img [src]="'assets/icons/design_v4/icon-dislike.svg'"
                     alt="vote icon"/>
                {{ downvotes }}
            </div>
        </div>
    </div>


    <div>
        <div *ngFor="let item of keys()">
            <ul class="editors-category__item"
                *ngIf="objIterable[item] === this.navParams.get('truncatedItems')">
                <li class="editors-category__link"
                    *ngFor="let name of objIterable[item]">
                    <span [class.truncate]="name === '...'">{{name}}</span>
                </li>
            </ul>
        </div>
    </div>

    <div class="editors-category__posts-of-category">
        <p>{{this.objIterable.truncatedItems[objIterable.truncatedItems.length - 1]}}</p>
    </div>




    <div class="editors-category__notification-block" *ngIf="canAsk">
        <div class="editors-category__skip-link" (click)="canAsk=false; randomize()" translate>
            Skip
            <i class="icon-arrow"></i>
        </div>
        <p class="editors-category__question-text">
          {{ this.question.questionText }}
        </p>
        <div class="editors-category__btn-list">
            <button class="btn btn--apply" (click)="sendAnswer(1)" translate>Yes</button>
            <button class="btn btn--cancel" (click)="sendAnswer(0)" translate>No</button>
        </div>
    </div>




    <div>
        <div class="editors-comments" *ngIf="discuss.categoryComments.length > 0; else noComments">
            <div *ngFor="let comment of discuss.categoryComments">
                <div class="row comment_wrapper comment__row">
                    <div class="col comment__user flex-grow-0">
                        <div class="comment_img"
                             [ngStyle]="{backgroundImage: 'url(' + comment.owner.userPhotoName + ')'}"
                             style="background-size: contain;"></div>
                    </div>
                    <div class="col comment__content comment_text_container">
                        <div class="article_text_header">
                            <div class="article_text_autor">
                                {{ comment.owner.firstName }} {{ comment.owner.lastName }}
                            </div>
                        </div>

                        <div class="article_text_main">
                            <div class="article_text_main_wrapper">
                                <p class="article_text_text select-text">
                                    {{comment.content}}
                                </p>
                            </div>
                        </div>

                        <div class="article__footer">
                            <div class="article_text_date">
                                {{dateService.dateConverter(comment.creationDate) | date:'d.M.yyyy'}}
                            </div>

                           <div class="article-answer"
                                 (click)="addChildComment(comment.id)"
                                 *ngIf="globals.isAuthenticated"
                                 translate>
                                <i class="fa fa-reply"></i>
                             Reply
                            </div>
                        </div>


                        <div *ngIf="globals.isAuthenticated">
                            <div class="row editors-category__subcomment" *ngFor="let child of comment.childComments">
                                <div class="col comment__user flex-grow-0">
                                    <div class="comment_img"
                                         [ngStyle]="{backgroundImage: 'url(' + child.owner.userPhotoName + ')'}"
                                         style="background-size: contain;"></div>
                                </div>
                                <div class="col comment__content comment_text_container">
                                    <div class="article_text_header">
                                        <div class="article_text_autor">
                                            {{child.owner | userName}}
                                        </div>
                                    </div>
                                    <div class="article_text_main">
                                        <div class="article_text_main_wrapper">
                                            <p class="article_text_text select-text">
                                                {{child.content}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="article__footer">
                                        <div class="article_text_date">
                                            {{dateService.dateConverter(child.creationDate) | date:'d.M.yyyy'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="add-comment__row" *ngIf="isNewChild && comment.id === parentCommentId">
                                <div class="add-comment__row-avatar">
                                    <img src="{{ globals.owner.userPhotoName }}" alt="">
                                </div>

                                <div class="add-comment__row-group">
                                    <div class="add-comment__group-control">
                                        <input type="text"
                                               appCustomAutofocus
                                               (keyup)="inputMessageHandler($event)"
                                               (click)="inputMessageHandler($event)"
                                               [(ngModel)]="childCommentText"
                                               placeholder="{{'Type here...' | translate}}">

                                        <button class="add-comment__emoji-button" (click)="toggleEmojiPicker()">
                                            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.0856934C4.70914 0.0856934 0 4.96605 0 10.9674C0 16.9688 4.70914 21.8491 10.5 21.8491C16.2909 21.8491 21 16.9688 21 10.9674C21 4.96605 16.2909 0.0856934 10.5 0.0856934ZM10.5 1.50505C15.5507 1.50505 19.6304 5.73312 19.6304 10.9674C19.6304 16.2017 15.5507 20.4298 10.5 20.4298C5.4493 20.4298 1.36957 16.2017 1.36957 10.9674C1.36957 5.73312 5.4493 1.50505 10.5 1.50505ZM5.02174 8.1287C5.02174 7.21417 5.73709 6.47279 6.61957 6.47279C7.50202 6.47279 8.21739 7.21417 8.21739 8.1287C8.21739 9.04324 7.50202 9.78462 6.61957 9.78462C5.73709 9.78462 5.02174 9.04324 5.02174 8.1287ZM14.3804 6.47279C13.498 6.47279 12.7826 7.21417 12.7826 8.1287C12.7826 9.04324 13.498 9.78462 14.3804 9.78462C15.2629 9.78462 15.9783 9.04324 15.9783 8.1287C15.9783 7.21417 15.2629 6.47279 14.3804 6.47279ZM4.86481 13.4661C4.98604 13.2385 5.18691 13.1097 5.40693 13.0964C5.62695 13.0831 5.86352 13.1815 6.04891 13.4069C7.03756 14.9396 8.65832 15.9352 10.5 15.9352C12.3417 15.9352 13.9624 14.9396 14.9511 13.4069C15.1509 13.0973 15.6081 13.0076 15.9069 13.2147C16.2057 13.4218 16.2922 13.8883 16.0924 14.1979C14.8683 16.0955 12.8176 17.3545 10.5 17.3545C8.18235 17.3545 6.13168 16.0955 4.90761 14.1979C4.76483 13.9862 4.74924 13.695 4.86481 13.4661Z" fill="#48CDC4"/>
                                            </svg>
                                        </button>
                                    </div>

                                    <div class="add-comment__group-button">
                                        <button class="add-comment__button" (click)="addNewComment();">
                                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.9181 11.7303L2.52827 12.6273L0.0568994 20.427C-0.0906259 20.8881 0.0544377 21.3916 0.42571 21.7072C0.796983 22.0227 1.32304 22.0895 1.76315 21.877L22.3424 12.0423C22.7447 11.8486 23 11.4454 23 11.0035C23 10.5617 22.7447 10.1585 22.3424 9.96477L1.77752 0.122961C1.3374 -0.0895013 0.811352 -0.0226845 0.440079 0.292836C0.0688062 0.608357 -0.0762574 1.11188 0.0712679 1.573L2.54264 9.3727L11.9145 10.2697C12.2931 10.3066 12.5816 10.6209 12.5816 10.9965C12.5816 11.372 12.2931 11.6863 11.9145 11.7232L11.9181 11.7303Z" fill="#48CDC4"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <ng-template class="editors-comments"
                     #noComments>
            <div class="editors-comments__center-txt">
                <p translate>There are no comments yet :(</p>
            </div>
        </ng-template>
    </div>

    <div class="editors-comments__add-new">
        <div class="add-comment" *ngIf="globals.isAuthenticated">
            <div class="container">
                <div class="add-comment__row">
                    <div class="add-comment__row-avatar">
                        <img src="{{ globals.owner.userPhotoName }}" alt="">
                    </div>

                    <div class="add-comment__row-group">
                        <div class="add-comment__group-control">
                            <input type="text"
                                   appCustomAutofocus
                                   (keyup)="inputMessageHandler($event)"
                                   (click)="inputMessageHandler($event)"
                                   [(ngModel)]="commentText"
                                   placeholder="{{'Type here...'| translate}}">

                            <button class="add-comment__emoji-button" (click)="toggleEmojiPicker()">
                                <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.0856934C4.70914 0.0856934 0 4.96605 0 10.9674C0 16.9688 4.70914 21.8491 10.5 21.8491C16.2909 21.8491 21 16.9688 21 10.9674C21 4.96605 16.2909 0.0856934 10.5 0.0856934ZM10.5 1.50505C15.5507 1.50505 19.6304 5.73312 19.6304 10.9674C19.6304 16.2017 15.5507 20.4298 10.5 20.4298C5.4493 20.4298 1.36957 16.2017 1.36957 10.9674C1.36957 5.73312 5.4493 1.50505 10.5 1.50505ZM5.02174 8.1287C5.02174 7.21417 5.73709 6.47279 6.61957 6.47279C7.50202 6.47279 8.21739 7.21417 8.21739 8.1287C8.21739 9.04324 7.50202 9.78462 6.61957 9.78462C5.73709 9.78462 5.02174 9.04324 5.02174 8.1287ZM14.3804 6.47279C13.498 6.47279 12.7826 7.21417 12.7826 8.1287C12.7826 9.04324 13.498 9.78462 14.3804 9.78462C15.2629 9.78462 15.9783 9.04324 15.9783 8.1287C15.9783 7.21417 15.2629 6.47279 14.3804 6.47279ZM4.86481 13.4661C4.98604 13.2385 5.18691 13.1097 5.40693 13.0964C5.62695 13.0831 5.86352 13.1815 6.04891 13.4069C7.03756 14.9396 8.65832 15.9352 10.5 15.9352C12.3417 15.9352 13.9624 14.9396 14.9511 13.4069C15.1509 13.0973 15.6081 13.0076 15.9069 13.2147C16.2057 13.4218 16.2922 13.8883 16.0924 14.1979C14.8683 16.0955 12.8176 17.3545 10.5 17.3545C8.18235 17.3545 6.13168 16.0955 4.90761 14.1979C4.76483 13.9862 4.74924 13.695 4.86481 13.4661Z" fill="#48CDC4"/>
                                </svg>
                            </button>
                        </div>

                        <div class="add-comment__group-button">
                            <button class="add-comment__button" (click)="addNewComment();">
                                <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9181 11.7303L2.52827 12.6273L0.0568994 20.427C-0.0906259 20.8881 0.0544377 21.3916 0.42571 21.7072C0.796983 22.0227 1.32304 22.0895 1.76315 21.877L22.3424 12.0423C22.7447 11.8486 23 11.4454 23 11.0035C23 10.5617 22.7447 10.1585 22.3424 9.96477L1.77752 0.122961C1.3374 -0.0895013 0.811352 -0.0226845 0.440079 0.292836C0.0688062 0.608357 -0.0762574 1.11188 0.0712679 1.573L2.54264 9.3727L11.9145 10.2697C12.2931 10.3066 12.5816 10.6209 12.5816 10.9965C12.5816 11.372 12.2931 11.6863 11.9145 11.7232L11.9181 11.7303Z" fill="#48CDC4"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <emoji-content class="toolbar-emoji-picker" *ngIf="showEmojiPicker" (emoji-selection)="emojiHandler($event)" >
            </emoji-content>
        </div>
    </div>
</ion-content>
