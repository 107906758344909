import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../globals';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
export class SupportService {
    constructor(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    sendSupportRequest(name, email, theme, issue, callback) {
        let params = new HttpParams();
        params = params.append('name', name);
        params = params.append('email', email);
        params = params.append('theme', theme);
        params = params.append('issue', issue);
        this.http.post(this.globals.apiUrl + '/support', null, {
            params: params
        }).subscribe(response => {
            return callback && callback(response);
        });
    }
}
SupportService.ɵfac = function SupportService_Factory(t) { return new (t || SupportService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals)); };
SupportService.ɵprov = i0.ɵɵdefineInjectable({ token: SupportService, factory: SupportService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SupportService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }]; }, null); })();
