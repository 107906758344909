<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefreshContent($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-list class="friends-list" style="margin-top: 10px;">
    <app-search #searchComponent *ngIf="globals.isSearching"></app-search>

    <ion-item-sliding
      class="custom-line cursor-pointer cursor-drag"
      *ngFor="let siteUser of globals.people">

      <ion-item-options
        *ngIf="!canAddFriend(siteUser.id)"
        side="start" >
        <ion-item-option
          *ngFor="let network of siteUser.socialNetworks; let i = index;"
          color="white">
          <a
            style="font-size: 24px;"
            href="{{checkLink(network.link)}}"
            target="_blank">
            <i class="fa fa-{{network.network.toLocaleLowerCase() }}" aria-hidden="true"></i>
          </a>
        </ion-item-option>
      </ion-item-options>


      <ion-item
        class="on-left-line"
        lines="none"
        style="margin-right: 2px">
        <ion-avatar
          slot="start"
          class="avatar-img">
          <img (click)="onItemClickHandler(siteUser)" src="{{siteUser.userPhotoName}}">
        </ion-avatar>
        <ion-label (click)="onItemClickHandler(siteUser)">
          <h2 class="user-name">{{siteUser | userName}}</h2>
          <p class="user-sub">{{siteUser.ssoId}}</p>
        </ion-label>
        <ion-icon
          *ngIf="canAddFriend(siteUser.relationType)"
          (click)="onPlusClickHandler(siteUser)"
          slot="end"
          name="add">
        </ion-icon>
      </ion-item>


      <ion-item-options side="end"
                        *ngIf="!canAddFriend(siteUser.relationType)">
        <ion-item-option
          (click)="chatService.startChat(siteUser.id)"
          class="on-left-line sub-item"
          color="white">
          <img
            style="width: 30px"
            [src]="'assets/icons/design_v4/comment.svg'" alt="">
        </ion-item-option>
        <ion-item-option
          (click)="confirmRemove(siteUser)"
          class="sub-item"
          color="white">
          <img
            style="width: 30px"
            [src]="'assets/icons/design_v4/remove_friend.svg'" alt="">
        </ion-item-option>
      </ion-item-options>

    </ion-item-sliding>

  </ion-list>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>

