import {CategoryTranslation} from '@app/core/categoryTranslation';
import {ParentTitle} from '@app/modules/category/parent-title';

export class Category {
  id: number;
  title: string;
  scope: number;
  owner: number;
  translation: CategoryTranslation;
  parentTitles: Array<ParentTitle>;
  accessType: number;
  nativeLanguage: string;
  label: number;

  children?: Array<Category> = new Array<Category>();
  numberOfNotes?: number;
  creationDate?: Date;
  parentCategoryId?: number;
  parentCategory?: Category;
}
