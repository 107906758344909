<div class="row comment_wrapper comment__row">
  <div class="col comment__user flex-grow-0">
    <div class="comment_img"
         [ngStyle]="{backgroundImage: 'url(' + currentCommentFromChildList.owner.userPhotoName + ')'}"
         style="background-size: contain;"></div>
  </div>
  <div class="col comment__content comment_text_container">
    <div (click)="openCommentPopover($event, currentCommentFromChildList)">
      <div class="article_text_header">
        <div class="article_text_autor">
          {{currentCommentFromChildList.owner | userName}}
        </div>
      </div>
      <div class="article_text_main">
        <div class="article_text_main_wrapper">
          <p class="article_text_text select-text">
            {{currentCommentFromChildList.content}}
          </p>
        </div>
      </div>
    </div>
    <div class="add_comment_place_wrapper" *ngIf="isEdit">
      <div class="row flex-column">
        <div class="add_comment_text" id="parentCommentEdit" contenteditable="true"
             (input)="editedCommentContent = $event.target.innerText">
          {{currentCommentFromChildList.content}}
        </div>
      </div>
      <div class="add_comment_send" (click)="updateComment(currentCommentFromChildList)">
      </div>
    </div>

    <div class="article__footer">
      <div class="article_text_date">
        {{dateService.dateConverter(currentCommentFromChildList.creationDate) | date:'d.M.yyyy'}}
      </div>
    </div>
  </div>
</div>
