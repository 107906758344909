import { Injectable } from '@angular/core';
import {Template} from "@app/shared/models/Template";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Globals} from "@app/shared/globals";

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor( public http: HttpClient, public globals: Globals) { }

  getAllTemplates() {
    return this.http.get(this.globals.apiUrl + '/template/list');
  }

  getTemplateById(id: number) {
    const params = new HttpParams().append('id', id.toString());
    return this.http.get(this.globals.apiUrl + '/template', {params});
  }

  getTemplateByPurpose(purpose: string) {
    const params = new HttpParams().append('purpose', purpose);
    return this.http.get(this.globals.apiUrl + '/template/purpose', {params});
  }

  addNewTemplate(template: Template) {
    return this.http.post(this.globals.apiUrl + '/template/new', template);
  }

  updateTemplate(template: Template) {
    return this.http.put(this.globals.apiUrl + '/template', template);
  }

  deleteTemplateById(id: number) {
    const params = new HttpParams().append('id', id.toString());
    return this.http.delete(this.globals.apiUrl + '/template', {params});
  }
}
