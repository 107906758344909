import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {HomeComponent} from './modules/home/home.component';
import {HeaderComponent} from './modules/header/header.component';
import {AuthenticationComponent} from './core/auth/authentication.component';
import {ProfileComponent} from './modules/profile/profile.component';
import {SettingsComponent} from './modules/settings/settings.component';
import {NoteComponent} from './modules/note/note.component';
import {NoteService} from './shared/services/note.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ScrollDispatcher, ScrollingModule} from '@angular/cdk/scrolling';
import {ScrollingModule as ExperimentalScrollingModule} from '@angular/cdk-experimental/scrolling';
import {NoteListComponent} from './modules/note-list/note-list.component';
import {Globals} from './shared/globals';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthenticationService} from './core/auth/authentication.service';
import {CategoryTreeComponent} from './modules/category-tree/category-tree.component';
import {CategoryLevelComponent} from './modules/category-level/category-level.component';
import {NewNoteComponent} from './modules/new-note/new-note.component';
import {CategoryService} from './shared/services/category.service';
import {FriendsComponent} from './modules/friends/friends.component';
import {SearchComponent} from './modules/search/search.component';
import {MatAutocompleteModule, MatIconModule} from '@angular/material';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NoteCommentService} from './shared/services/note-comment.service';
import {NewMessageComponent} from './modules/chat/chat-messages/new-message/new-message.component';
import {ChatService} from './shared/services/chat.service';
import {MessageService} from './shared/services/message.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule} from 'angularx-social-login';
import {PasswordResetComponent} from './core/auth/password-reset/password-reset.component';
import {FriendsRequestsComponent} from './modules/friend-requests/friends-requests.component';
import {FacebookAuthService} from './core/auth/facebook-auth.service';
import {QuillModule} from 'ngx-quill';
import {EditorsPageComponent} from './modules/editors-page/editors-page.component';
import {EditorsRowComponent} from './modules/editors-page/editors-row/editors-row.component';
import {NoteCommentComponent} from './modules/note-comment/note-comment.component';
import {NoteCommentListComponent} from './modules/note-comment-list/note-comment-list.component';
import {ChildNoteCommentListComponent} from './modules/child-note-comment-list/child-note-comment-list.component';
import {ChildNoteCommentComponent} from './modules/child-note-comment/child-note-comment.component';
import {NewNoteCommentComponent} from './modules/new-note-comment/new-note-comment.component';
import {CategoryBreadcrumbsComponent} from './modules/category-breadcrumbs/category-breadcrumbs.component';
import {GoogleAuthService} from './core/auth/google-auth.service';
import {ChatComponent} from './modules/chat/chat.component';
import {ChatMessagesComponent} from './modules/chat/chat-messages/chat-messages.component';
import {IonicModule} from '@ionic/angular';
import {DropDownListComponent} from './shared/components/drop-down-list/drop-down-list.component';
import {Camera} from '@ionic-native/camera/ngx';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {IonicStorageModule} from '@ionic/storage';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ImageCropComponent} from './shared/components/image-crop/image-crop.component';
import {OutsideClickDirective} from './shared/directive/outside-click.directive';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';
import {QuillComponent} from './modules/quill/quill.component';
import {EditorsService} from './shared/services/editors.service';
import {CategoryTranslation} from './core/categoryTranslation';
import {MenuComponent} from './modules/menu/menu.component';
import {LoginComponent} from './core/auth/login/login.component';
import {SignUpComponent} from './core/auth/sign-up/sign-up.component';
import {AuthHeaderComponent} from './shared/components/auth-header/auth-header.component';
import {ForgotPasswordComponent} from './core/auth/forgot-password/forgot-password.component';
import {DateFormatPipePipe} from './shared/pipes/date-format-pipe.pipe';
import {LevelDirective} from './directives/level.directive';
import {CategorySliderComponent} from './modules/category-slider/category-slider.component';
import {CategoryListComponent} from './modules/category-list/category-list.component';
import {CategoryFlowerComponent} from './modules/category-flower/category-flower.component';
import {TranslateCategoryPipe} from './pipes/translate-category.pipe';
import {CategoryIconPipe} from './pipes/category-icon.pipe';
import {NotFoundComponent} from './modules/not-found/not-found.component';
import {EmojiPickerComponent} from './shared/components/emoji-picker/emoji-picker.component';
import {FriendsListComponent} from './modules/chat/friends-list/friends-list.component';
import {MobileChatModalComponent} from './modules/chat/mobile-chat-modal/mobile-chat-modal.component';
import {NoteListItemComponent} from '@app/modules/note-list-item/note-list-item.component';
import {UserNamePipe} from '@app/shared/pipes/user-name.pipe';
import {NoteFiltersComponent} from '@app/modules/note-filters/note-filters.component';
import {NewChatModalComponent} from './modules/chat/new-chat-modal/new-chat-modal.component';
import {LangPickerDropDownComponent} from './shared/components/lang-picker-drop-down/lang-picker-drop-down.component';
import {AutofocusDirectiveModule} from '@app/shared/directive/autofocus-directive/autofocus.directive.module';
import {ResetSendComponent} from '@app/core/auth/reset-send/reset-send.component';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {NgxEmojiPickerModule} from 'ngx-emoji-picker';
import {CustomSelectDirectiveModule} from '@app/shared/directive/customSelect-directive/customSelect.directive.module';
import {EditorsDiscussComponent} from '@app/modules/editors-page/editors-discuss/editors-discuss.component';
import {PolicyComponent} from './modules/terms-and-policy/policy/policy.component';
import {TermsComponent} from './modules/terms-and-policy/terms/terms.component';
import {SupportComponent} from '@app/core/support/support.component';
import {ButtonTouchDirective} from '@app/shared/directive/button-touch.directive';
import {AdminPageComponent} from './modules/admin-page/admin-page.component';
import {VotingPanelComponent} from './modules/admin-page/voting-panel/voting-panel.component';
import {UsersPanelComponent} from './modules/admin-page/users-panel/users-panel.component';
import {TemplatesPanelComponent} from './modules/admin-page/templates-panel/templates-panel.component';
import {EditorsTermsComponent} from '@app/modules/editors-page/editors-terms/editors-terms.component';
import {TokenInterceptor} from '@app/core/auth/token.interceptor';
import {NewNoteDataResolve} from '@app/shared/resolvers/new-note-data.resolver';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgxLinkifyjsModule} from 'ngx-linkifyjs';
import {ConfirmAccountComponent} from './core/auth/confirm-account/confirm-account.component';
import {AlertsPageComponent} from '@app/modules/alerts-page/alerts-page.component';
import {ClipboardDirective} from 'ngx-clipboard';
import {TemplateService} from '@app/shared/services/template.service';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Market} from '@ionic-native/market/ngx';
import {CheckUpdateService} from './shared/services/check-update.service';
import {ProfileHeaderComponent} from '@app/modules/profile/profile-header/profile-header.component';
import {ReceivedFromModalComponent} from '@app/modules/chat/received-from-modal/received-from-modal.component';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {NotificationsPageComponent} from '@app/modules/notifications-page/notifications-page.component';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {ImageOpenerDirective} from '@app/shared/directive/image-opener.directive';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import {BdcWalkModule} from "bdc-walkthrough";

import { MatCheckboxModule } from '@angular/material/checkbox';
// ngrx
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {RootStoreModule} from './root-store/root-store.module';
import {CategoryContainerComponent} from '@app/modules/category-ngrx/category-container/category-container.component';
import {CategoryTreeNgrxComponent} from '@app/modules/category-ngrx/category-tree/category-tree.component';
import {CategorySliderCoverflowComponent} from '@app/modules/category-ngrx/category-slider/category-slider.component';
import {CategoryApiService} from '@app/modules/category-ngrx/category-api.service';
import { LabelComponent } from './modules/label/label.component';
import {LabelService} from "@app/shared/services/label.service";
import { PlaceComponent } from './modules/place/place.component';
import { SiteUserFeedComponent } from './modules/site-user-feed/site-user-feed.component';
import {CastomIonImgViewerDirective} from "@app/shared/directive/castom-ion-img-viewer.directive";



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('3029693810438306')
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      '179659201381-kma03s41hgtc2b4oio8fotd5faolc2r0.apps.googleusercontent.com'
    )
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  entryComponents: [
    DropDownListComponent,
    ImageCropComponent,
    MobileChatModalComponent,
    CategorySliderComponent,
    CategoryListComponent,
    NewChatModalComponent,
    EditorsDiscussComponent,
    LangPickerDropDownComponent,
    EditorsTermsComponent,
    ReceivedFromModalComponent,
    LabelComponent,
    PlaceComponent
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    AuthenticationComponent,
    ProfileComponent,
    SettingsComponent,
    NoteComponent,
    NoteCommentComponent,
    NewNoteCommentComponent,
    NoteCommentListComponent,
    ChildNoteCommentListComponent,
    ChildNoteCommentComponent,
    NoteListComponent,
    CategoryTreeComponent,
    CategoryLevelComponent,
    NewNoteComponent,
    FriendsComponent,
    SearchComponent,
    NewMessageComponent,
    PasswordResetComponent,
    FriendsRequestsComponent,
    EditorsPageComponent,
    EditorsRowComponent,
    EditorsRowComponent,
    ChatComponent,
    ChatMessagesComponent,
    DropDownListComponent,
    ImageCropComponent,
    QuillComponent,
    CategoryBreadcrumbsComponent,
    OutsideClickDirective,
    MenuComponent,
    LoginComponent,
    SignUpComponent,
    AuthHeaderComponent,
    ForgotPasswordComponent,
    DateFormatPipePipe,
    LevelDirective,
    CategorySliderComponent,
    CategoryListComponent,
    CategoryFlowerComponent,
    TranslateCategoryPipe,
    CategoryIconPipe,
    NotFoundComponent,
    EmojiPickerComponent,
    FriendsListComponent,
    MobileChatModalComponent,
    NoteListItemComponent,
    UserNamePipe,
    NoteFiltersComponent,
    NewChatModalComponent,
    LangPickerDropDownComponent,
    ResetSendComponent,
    EditorsDiscussComponent,
    PolicyComponent,
    TermsComponent,
    EditorsTermsComponent,
    SupportComponent,
    ButtonTouchDirective,
    ImageOpenerDirective,
    CastomIonImgViewerDirective,
    AdminPageComponent,
    VotingPanelComponent,
    UsersPanelComponent,
    AlertsPageComponent,
    TemplatesPanelComponent,
    ConfirmAccountComponent,
    ProfileHeaderComponent,
    ReceivedFromModalComponent,
    CategoryContainerComponent,
    CategoryTreeNgrxComponent,
    CategorySliderCoverflowComponent,
    NotificationsPageComponent,
    LabelComponent,
    PlaceComponent,
    SiteUserFeedComponent,
  ],
  imports: [
    ImageCropperModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    QuillModule.forRoot(),
    MatAutocompleteModule,
    SocialLoginModule,
    NgxIonicImageViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    FontAwesomeModule,
    InfiniteScrollModule,
    AutofocusDirectiveModule,
    CustomSelectDirectiveModule,
    NgxEmojiPickerModule.forRoot(),
    NgxLinkifyjsModule.forRoot(),
    // ngrx
    !environment.production
      ? StoreDevtoolsModule.instrument({maxAge: 25, logOnly: false})
      : [],
    RootStoreModule,
    BdcWalkModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
  ],
  providers: [
    AppVersion,
    Market,
    ClipboardDirective,
    CategoryService,
    CategoryApiService,
    CategoryTranslation,
    EditorsPageComponent,
    EditorsService,
    NoteService,
    ScrollDispatcher,
    Globals,
    AuthenticationService,
    NoteCommentService,
    ChatService,
    MessageService,
    FacebookAuthService,
    GoogleAuthService,
    CheckUpdateService,
    Camera,
    GooglePlus,
    Facebook,
    StatusBar,
    SplashScreen,
    MenuComponent,
    NewNoteDataResolve,
    AdminPageComponent,
    TemplatesPanelComponent,
    UsersPanelComponent,
    VotingPanelComponent,
    TemplateService,
    AlertsPageComponent,
    ConfirmAccountComponent,
    UserNamePipe,
    OneSignal,
    PhotoViewer,
    LabelService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
