import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";
import {Globals} from "@app/shared/globals";
import {MenuItem} from "@app/shared/models/menu/MenuItem";
import {LanguagesList} from "@app/core/languages";
import {LangPickerDropDownComponent} from "@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component";
import {CanActivate, Router} from "@angular/router";

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})

export class AdminPageComponent implements OnInit, CanActivate {
  panels = [
    {
      title: 'Users',
      icon: 'assets/icons/design_v4/person.svg',
      url: 'users'
    },
    {
      title: 'Templates',
      icon: 'assets/icons/design_v4/notes.svg',
      url: 'templates'
    },
    {
      title: 'Categories',
      icon: 'assets/icons/design_v4/category-path-white.svg',
      url: 'categories/127'
    },
    {
      title: 'Voter',
      icon: 'assets/icons/design_v4/stats.svg',
      url: 'voter'
    }
  ];

  headerActions: MenuItem[] = [
    {
      title: 'Home',
      path: '/',
      icon: 'assets/icons/design_v4/home_white.svg',
    },
    {
      title: 'Notifications',
      icon: 'assets/icons/design_v4/notifications-sharp.svg',
      path: '/notifications',
    },
  ];

  constructor(public http: HttpClient,
              private toolbarService: ToolbarService,
              public globals: Globals,
              public router: Router
  ) {
  }

  ngOnInit() {
  }

  updateNavigation(): void {
    this.toolbarService.setMenuItems(this.headerActions);
  }

  canActivate(): boolean {
    let isActive: boolean = true;
    if (!this.globals.isAdmin) {
      this.router.navigate(['/']);
      isActive = false;
    }
    return isActive;
  }
}
