import { Directive, HostListener } from '@angular/core';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/photo-viewer/ngx";
export class ImageOpenerDirective {
    constructor(photoViewer) {
        this.photoViewer = photoViewer;
        this.options = {
            share: true,
            closeButton: true,
            copyToReference: true,
            headers: "",
            piccasoOptions: {} // If it is not provided, it will trigger an exception
        };
    }
    mouseDown(targetElement) {
        this.openPhoto(targetElement);
    }
    openPhoto(targetElement) {
        if (targetElement.src && targetElement.tagName === 'IMG') {
            console.log(targetElement.src);
            this.photoViewer.show(targetElement.src, '', this.options);
        }
    }
}
ImageOpenerDirective.ɵfac = function ImageOpenerDirective_Factory(t) { return new (t || ImageOpenerDirective)(i0.ɵɵdirectiveInject(i1.PhotoViewer)); };
ImageOpenerDirective.ɵdir = i0.ɵɵdefineDirective({ type: ImageOpenerDirective, selectors: [["", "appImageOpener", ""]], hostBindings: function ImageOpenerDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function ImageOpenerDirective_click_HostBindingHandler($event) { return ctx.mouseDown($event.target); });
    } } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ImageOpenerDirective, [{
        type: Directive,
        args: [{
                selector: '[appImageOpener]'
            }]
    }], function () { return [{ type: i1.PhotoViewer }]; }, { mouseDown: [{
            type: HostListener,
            args: ['click', ['$event.target']]
        }] }); })();
