import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Globals } from "@app/shared/globals";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { Continent } from "@app/modules/place/continent";
import { Place } from "@app/modules/place/place";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@app/shared/globals";
import * as i3 from "@angular/router";
export class GeoService {
    constructor(http, globals, router) {
        this.http = http;
        this.globals = globals;
        this.router = router;
        this.continentsSource = new BehaviorSubject([]);
        this.continents = this.continentsSource.asObservable();
        this.placeSource = new BehaviorSubject(new Place());
        this.selectedPlace = this.placeSource.asObservable();
    }
    changeContinents(continents) {
        this.continentsSource.next(continents);
    }
    changeSelectedPlace(place) {
        this.placeSource.next(place);
    }
    getWorld(continents) {
        let result = new Continent();
        for (const continent of continents) {
            if (continent.title === "World") {
                result = continent;
                break;
            }
        }
        return result;
    }
    isPlaceEmpty(place) {
        return place.city === undefined && place.region === undefined && place.country === undefined && place.continent === undefined;
    }
    getAllContinents() {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/geo/continent', {
            params
        });
    }
    getAllCountriesForContinent(continentId) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/geo/continent/' + continentId + '/country', {
            params
        });
    }
    getAllRegionsForCountry(countryId) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/geo/country/' + countryId + '/region', {
            params
        });
    }
    getAllCitiesForRegion(regionId) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/geo/region/' + regionId + '/city', {
            params
        });
    }
    createPlace(selectedPlace) {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.post(this.globals.apiUrl + '/geo/place', selectedPlace, { params });
    }
    getDefaultPlace() {
        const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
        return this.http.get(this.globals.apiUrl + '/geo/place/default', {
            params
        });
    }
}
GeoService.ɵfac = function GeoService_Factory(t) { return new (t || GeoService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.Router)); };
GeoService.ɵprov = i0.ɵɵdefineInjectable({ token: GeoService, factory: GeoService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(GeoService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.Router }]; }, null); })();
