import { Category } from "@app/modules/category/category";

export interface NavigateLevel {
  id: number;
  scope: number;
  active: boolean;
  categories: Category[];
  childs: [];
}

export interface State {
  categories: Category[] | null;
  currentSelectCategory: Category | null;
  currentCategoryChilds: Category[] | null;
  flatTreeNavigate: NavigateLevel[] | null;
  isLoading: boolean;
  error: string;
  rootCategories: Category[] | null;
}

export const initialState: State = {
  rootCategories: [],
  categories: null,
  currentSelectCategory: null,
  currentCategoryChilds: null,
  flatTreeNavigate: [],
  isLoading: false,
  error: null
};
