import { Pipe } from '@angular/core';
import { CategoryService } from '../shared/services/category.service';
import * as i0 from "@angular/core";
import * as i1 from "../shared/services/category.service";
export class TranslateCategoryPipe {
    constructor(categoryService) {
        this.categoryService = categoryService;
    }
    transform(category, args) {
        return this.categoryService.getTranslationForTitle(category);
    }
}
TranslateCategoryPipe.ɵfac = function TranslateCategoryPipe_Factory(t) { return new (t || TranslateCategoryPipe)(i0.ɵɵdirectiveInject(i1.CategoryService)); };
TranslateCategoryPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "TranslateCategory", type: TranslateCategoryPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TranslateCategoryPipe, [{
        type: Pipe,
        args: [{
                name: 'TranslateCategory'
            }]
    }], function () { return [{ type: i1.CategoryService }]; }, null); })();
