var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Directive, HostListener } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ViewerModalComponent } from "ngx-ionic-image-viewer";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class CastomIonImgViewerDirective {
    constructor(modalController) {
        this.modalController = modalController;
    }
    mouseDown(targetElement) {
        if (targetElement.nodeName === "IMG") {
            this.openViewer(targetElement);
        }
    }
    openViewer(targetElement) {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: ViewerModalComponent,
                componentProps: {
                    src: targetElement.src
                },
                cssClass: 'ion-img-viewer',
                keyboardClose: true,
                showBackdrop: true
            });
            return yield modal.present();
        });
    }
}
CastomIonImgViewerDirective.ɵfac = function CastomIonImgViewerDirective_Factory(t) { return new (t || CastomIonImgViewerDirective)(i0.ɵɵdirectiveInject(i1.ModalController)); };
CastomIonImgViewerDirective.ɵdir = i0.ɵɵdefineDirective({ type: CastomIonImgViewerDirective, selectors: [["", "appImageOpenerCust", ""]], hostBindings: function CastomIonImgViewerDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function CastomIonImgViewerDirective_click_HostBindingHandler($event) { return ctx.mouseDown($event.target); });
    } } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CastomIonImgViewerDirective, [{
        type: Directive,
        args: [{
                selector: '[appImageOpenerCust]'
            }]
    }], function () { return [{ type: i1.ModalController }]; }, { mouseDown: [{
            type: HostListener,
            args: ['click', ['$event.target']]
        }] }); })();
