<ng-container *ngIf="profileHeaderIsLoaded">
    <div class="profile__header">
        <div
                class="backdrop">
            <div
                    *ngIf="isEdit"
                    (click)="selectImage('banner')"
                    class="image-update image-update--banner">
                <input
                        type="file"
                        (change)="processFile($event, 'banner')"
                        hidden
                        id="userBannerUploader"
                        name="uploadUserBanner">
                <img
                        class="image-update__icon"
                        [src]="'assets/icons/design_v4/camera.svg'"
                        alt="Edit pencil icon"
                />
            </div>

            <img
                    src="{{selectedUserInfo && selectedUserInfo.userBannerName ? selectedUserInfo.userBannerName : 'assets/icons/design_v4/emptybackdrop.svg'}}"
                    alt=""
                    class="backdrop__image">
        </div>

        <div class="avatar">
            <div
                    *ngIf="isEdit"
                    (click)="selectImage('profile')"
                    class="image-update image-update--profile">
                <input
                        type="file"
                        (change)="processFile($event, 'profile')"
                        hidden
                        id="userPhotoUploader"
                        name="uploadUserPhoto">
                <img
                        class="image-update__icon"
                        [src]="'assets/icons/design_v4/camera.svg'"
                        alt="Edit pencil icon"
                />
            </div>
            <img
              (click)=navigateToProfile()
                    class="avatar__image"
                    [src]="selectedUserInfo.owner.userPhotoName"
                    alt="">
        </div>
    </div>
    <form
            class="profile-header-form mt-m"
            [formGroup]="profileHeaderForm">

        <div class="profile__info">
            <div class="username">
                <h1
                  (click)=navigateToProfile()
                        class="username__text form__text"
                        *ngIf="!isEdit">
                    {{!profileHeaderForm.value.firstName ? translationText['First name'] : profileHeaderForm.value.firstName}} {{!profileHeaderForm.value.lastName ? translationText['Last name']: profileHeaderForm.value.lastName}}
                </h1>
                <input
                        *ngIf="isEdit"
                        class="username__input form__input"
                        type="text"
                        placeholder="{{translationText['First name']}}"
                        id="firstName"
                        name="firstName"
                        formControlName="firstName"/>
                <input
                        *ngIf="isEdit"
                        class="username__input form__input"
                        type="text"
                        placeholder="{{translationText['Last name']}}"
                        id="lastName"
                        name="lastName"
                        formControlName="lastName"/>
            </div>
            <div class="status">
                <h2
                        class="status__text form__text"
                        *ngIf="!isEdit">
                    {{!profileHeaderForm.value.status ? translationText['Status'] : profileHeaderForm.value.status}}
                </h2>
                <input
                        *ngIf="isEdit"
                        class="status__input form__input"
                        type="text"
                        placeholder="{{translationText['Status']}}"
                        id="status"
                        name="status"
                        formControlName="status"/>

            </div>

            <div class="profession">
                <h2
                        class="status__text form__text"
                        *ngIf="!isEdit">
                    {{!profileHeaderForm.value.profession ? translationText['Profession'] : profileHeaderForm.value.profession}}
                </h2>

                <input
                        *ngIf="isEdit"
                        class="status__input form__input"
                        type="text"
                        placeholder="{{translationText['Profession']}}"
                        id="profession"
                        name="profession"
                        formControlName="profession"/>
            </div>

            <div
                    *ngIf="!isEdit"
                    class="counters">
                <p class="counters__friends">
            <span class="counters__count" (click)="navigateToFriends()">
              {{profileFriendsCount}}
            </span>
                    {{translationText['Friends']}}
                </p>
                <p class="counters__posts">
            <span class="counters__count" (click)="navigateToNotes()">
              {{profileNotesCount}}
            </span>
                    {{translationText['Notes']}}
                </p>
                <p class="counters__referral">
            <span class="counters__count" (click)="navigateToFriends()">
              {{profileReferralCount}}
            </span>
                    {{translationText['Referrals']}}
                </p>
            </div>
        </div>
    </form>
</ng-container>
