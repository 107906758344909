import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CategoryStoreModule } from "./category/category.module";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ngrx/effects";
export class RootStoreModule {
}
RootStoreModule.ɵmod = i0.ɵɵdefineNgModule({ type: RootStoreModule });
RootStoreModule.ɵinj = i0.ɵɵdefineInjector({ factory: function RootStoreModule_Factory(t) { return new (t || RootStoreModule)(); }, imports: [[
            CommonModule,
            CategoryStoreModule,
            StoreModule.forRoot({}),
            EffectsModule.forRoot([])
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(RootStoreModule, { imports: [CommonModule,
        CategoryStoreModule, i1.StoreRootModule, i2.EffectsRootModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(RootStoreModule, [{
        type: NgModule,
        args: [{
                declarations: [],
                imports: [
                    CommonModule,
                    CategoryStoreModule,
                    StoreModule.forRoot({}),
                    EffectsModule.forRoot([])
                ]
            }]
    }], null, null); })();
