export class Template {

    tempId: number;
    purpose: string;

    ar: string;
    bn: string;
    de: string;
    en: string;
    es: string;
    fr: string;
    hi: string;
    id: string;
    it: string;
    ja: string;
    pt: string;
    ru: string;
    zh: string;
}
