import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from "@app/shared/models/menu/MenuItem";
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";
import {CanActivate, Router} from "@angular/router";
import {Globals} from "@app/shared/globals";
import {UpdateNavigation} from "@app/shared/models/UpdateNavigation";
import {Template} from "@app/shared/models/Template";
import {TemplateService} from "@app/shared/services/template.service";
import {CategoryService} from "@app/shared/services/category.service";
import {LangPickerDropDownComponent} from "@app/shared/components/lang-picker-drop-down/lang-picker-drop-down.component";
import {PopoverController} from "@ionic/angular";
import {LanguagesList} from "@app/core/languages";

@Component({
  selector: 'app-notes-panel',
  templateUrl: './templates-panel.component.html',
  styleUrls: ['./templates-panel.component.scss']
})
export class TemplatesPanelComponent implements OnInit, CanActivate, UpdateNavigation, OnDestroy {
  headerActions: MenuItem[] = [
    {
      title: 'Back',
      path: '/admin',
      icon: 'assets/icons/design_v4/back_icon.svg',
    },
    {
      title: 'Category tree',
      path: '/categories',
      icon: 'assets/icons/design_v4/category_path.svg',
    },
    {
      title: 'Messages',
      path: '/chat',
      icon: 'assets/icons/design_v4/messages.svg'
    }
  ];

  previewButtons = [
      {
          title: 'Refresh',
          action: () => this.updateTemplates()
      },
      {
          title: 'Add template',
          action: () => { this.isEditor = true; this.isNewTemplate = true; }
      }
  ];

  editorButtons = [
      {
          title: 'Cancel',
          action: () => { this.isEditor = false; this.currentTemplate = null; this.globals.newNoteContent = ""; }
      },
      {
          title: 'Apply',
          action: () => this.saveTemplate()
      }
  ];

  templatesList: Template[];
  currentTemplate: Template;
  previewId: number;
  templatePurpose: string;

  isPreview = false;
  isEditor = false;
  isNewTemplate = false;
  isRefreshing = true;

  popover;
  languageList = LanguagesList;
  popoverOpened = false;

  constructor(
      public toolbarService: ToolbarService,
      public router: Router,
      public globals: Globals,
      public templateService: TemplateService,
      public categoryService: CategoryService,
      private popoverController: PopoverController,
  ) {
  }

  ngOnInit() {
    this.updateTemplates();
    this.globals.isDeletable = false;
  }

  ngOnDestroy(): void {
    this.globals.isDeletable = true;
  }

  updateNavigation(): void {
    const defaultActions = [...this.headerActions];

    defaultActions.splice(2, 2, {
        title: 'Messages',
        icon: this.globals.notificationCount > 0 && !this.popoverOpened ?
          'assets/icons/design_v4/messages-unread.svg' : 'assets/icons/design_v4/messages.svg',
        action: () => {
          this.router.navigateByUrl('chat').then(r => console.log(r));
        },
      },
    );

    const lang = this.languageList.filter(l => l.en === this.globals.currentLanguage)[0].name;

    defaultActions.push(
      {
        icon: `assets/icons/design_v4/langs/${lang.toLocaleLowerCase()}.svg`,
        title: lang,
        action: env => this.presentLanguageMenu(env)
      }
    );

    this.toolbarService.setMenuItems(defaultActions);
  }

  updateTemplates() {
    this.isRefreshing = true;
    this.templateService.getAllTemplates().subscribe((data: Template[]) => {
      this.templatesList = data;

      this.isRefreshing = false;
      this.isEditor = false;
      this.isNewTemplate = false;
      this.globals.newNoteContent = "";
      this.templatePurpose = "";
      this.currentTemplate = null;
    });
  }

  deleteTemplate() {
      this.templateService.deleteTemplateById(this.currentTemplate.tempId).subscribe((data) => {
        this.isEditor = false;
        this.isNewTemplate = false;
        this.globals.newNoteContent = "";
        this.currentTemplate = null;
        this.updateTemplates();
      });
  }

  saveTemplate() {
      if (this.isNewTemplate) {
          const template: Template = new Template();
          template.purpose = this.templatePurpose.toLowerCase();
          this.setText(template, this.globals.currentLanguage, this.globals.newNoteContent);
          this.templateService.addNewTemplate(template).subscribe((data: any) => {
            this.updateTemplates();
          });
      } else {
          const template = this.currentTemplate;
          this.setText(template, this.globals.currentLanguage, this.globals.newNoteContent);
          this.templateService.updateTemplate(template).subscribe((data: any) => {
            this.updateTemplates();
          });
      }
  }

  setText(template, lang, text) {
      switch (lang) {
          case "ar":
              template.en = text;
              break;
          case "bn":
              template.bn = text;
              break;
          case "de":
              template.de = text;
              break;
          case "en":
              template.en = text;
              break;
          case "es":
              template.es = text;
              break;
          case "fr":
              template.fr = text;
              break;
          case "hi":
              template.hi = text;
              break;
          case "id":
              template.id = text;
              break;
          case "it":
              template.it = text;
              break;
          case "ja":
              template.ja = text;
              break;
          case "pt":
              template.pt = text;
              break;
          case "ru":
              template.ru = text;
              break;
          case "zh":
              template.zh = text;
              break;
          default:
              template.en = text;
      }
  }

  getExampleTagIcon() {
      return `assets/icons/design_v4/tag_3.svg`;
  }

  chopTemplate(content: string) {
      let templatePreview = content.substring(0, 200);
      if (content.length > 200) {
          const chunks = content.split('</a>', 1);
          const subS = chunks[0] + '</a>';
          if (subS.length >= templatePreview.length && content.indexOf('</a>') > 0) {
              templatePreview = subS;
          }
      }
      return templatePreview;
  }

  togglePreview(template: Template) {
      if (this.isPreview) {
          this.isPreview = false;
          this.previewId = 0;
      } else {
          this.isPreview = true;
          this.previewId = template.tempId;
      }
  }

  toggleEditor(template) {
      this.currentTemplate = template;
      this.globals.newNoteContent = this.getTemplateContent(template);
      this.isEditor = true;
      this.isNewTemplate = false;
  }

  getTemplateContent(template: Template) {
    let translate;
    switch (this.globals.currentLanguage) {
      case "ar":
        translate = template.en;
        break;
      case "bn":
        translate = template.bn;
        break;
      case "de":
        translate = template.de;
        break;
      case "en":
        translate = template.en;
        break;
      case "es":
        translate = template.es;
        break;
      case "fr":
        translate = template.fr;
        break;
      case "hi":
        translate = template.hi;
        break;
      case "id":
        translate = template.id;
        break;
      case "it":
        translate = template.it;
        break;
      case "ja":
        translate = template.ja;
        break;
      case "pt":
        translate = template.pt;
        break;
      case "ru":
        translate = template.ru;
        break;
      case "zh":
        translate = template.zh;
        break;
      default:
        translate = template.en;
    }
    if (translate == null || translate === '') {
      if (template.en == null || template.en === '') {
        return 'Sorry. Cannot find translated content for current and default language.';
      } else {
        return template.en;
      }
    } else {
      return translate;
    }
  }

  async presentLanguageMenu(env) {
    this.popover = await this.popoverController.create({
      component: LangPickerDropDownComponent,
      event: env,
      translucent: false,
      showBackdrop: false,
    });

    await this.popover.present();
    this.popoverOpened = true;
    this.popover.onDidDismiss().then(
      e => {
        if (e.data) {
          this.globals.currentLanguage = e.data.en;
          this.updateNavigation();
        }
        this.popoverOpened = false;
      }
    );
  }

  canActivate(): boolean {
    let isActive = true;
    if (!this.globals.isAdmin) {
      this.router.navigate(['/']);
      isActive = false;
    }
    return isActive;
  }
}
