import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../globals';
import { SharedService } from '@app/shared/services/shared.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../globals";
import * as i3 from "@app/shared/services/shared.service";
import * as i4 from "@angular/router";
export class ChatService {
    constructor(http, globals, sharedService, router) {
        this.http = http;
        this.globals = globals;
        this.sharedService = sharedService;
        this.router = router;
    }
    getAllChats() {
        return this.http.get(this.globals.apiUrl + '/chats');
    }
    getChat(friendId) {
        return this.http.get(this.globals.apiUrl + '/chats/users/' + friendId);
    }
    getMessagesByContent(content) {
        return this.http.get(this.globals.apiUrl + '/chats/messages?content=' + content);
    }
    getMessageListForChat(page) {
        const params = new HttpParams().append('page', page.toString());
        return this.http.get(this.globals.apiUrl + '/chats/' + this.globals.currentChat.id + '/messages', {
            params: params,
            responseType: 'json'
        });
    }
    getUnreadMessagesCount() {
        return this.http.get(this.globals.apiUrl + '/chats/messages/count');
    }
    refreshUnreadMessagesCount() {
        this.getUnreadMessagesCount().subscribe((data) => {
            this.globals.unreadMessagesCount = data;
            this.globals.notificationCount = this.globals.unreadMessagesCount + this.globals.requestList.length;
        });
    }
    sortByLastMsg(chatList) {
        chatList.sort((a, b) => {
            if (a.lastMessageDto != null && b.lastMessageDto != null) {
                if (a.lastMessageDto.creationDate < b.lastMessageDto.creationDate) {
                    return 1;
                }
                if (a.lastMessageDto.creationDate === b.lastMessageDto.creationDate) {
                    return 0;
                }
                if (a.lastMessageDto.creationDate > b.lastMessageDto.creationDate) {
                    return -1;
                }
            }
        });
    }
    startChat(selectedUserId) {
        this.getChat(selectedUserId).subscribe((data) => {
            this.globals.currentChat = data;
            this.sharedService.emitChange({
                data,
                action: 'open-chat'
            });
            this.router.navigateByUrl('/chat');
        });
    }
}
ChatService.ɵfac = function ChatService_Factory(t) { return new (t || ChatService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.SharedService), i0.ɵɵinject(i4.Router)); };
ChatService.ɵprov = i0.ɵɵdefineInjectable({ token: ChatService, factory: ChatService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ChatService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Globals }, { type: i3.SharedService }, { type: i4.Router }]; }, null); })();
