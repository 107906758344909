import {Directive, HostListener} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
  selector: '[appButtonTouch]'
})
export class ButtonTouchDirective {

  timeout;

  constructor(private router: Router) {
  }

  @HostListener('touchstart')
  tapped() {
    this.down();
  }

  @HostListener('touchend')
  untapped() {
    this.up();
  }

  @HostListener('mousedown')
  mouseDown() {
    this.down();
  }

  @HostListener('mouseup')
  mouseUp() {
    this.up();
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this.up();
  }

  down() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.router.navigateByUrl('categories/437/new-note').then(r => console.log(r));
    }, 500);
  }

  up() {
    clearTimeout(this.timeout);
  }
}
