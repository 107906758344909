import {Component, OnInit, ViewChild} from '@angular/core';
import {Globals} from '../../../../shared/globals';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from '../../../../shared/services/message.service';
import {Message} from '../message';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.css', './../../../../core/auth/authentication.component.css']
})
export class NewMessageComponent implements OnInit {

  public newMessageContent = '';
  @ViewChild('message', {static: false}) messageInput;

  showEmojiPicker = false;
  selectionStart = 0;

  constructor(
    public translate: TranslateService,
    public messageService: MessageService,
    public platform: Platform,
    public globals: Globals) {
  }

  ngOnInit() {
  }

  addNewMessage() {
    const newMessage = this.prepareNewMessage();
    this.messageService.sendMsg(newMessage);
    this.newMessageContent = '';
    this.messageInput.nativeElement.style.height = '36px';
    this.showEmojiPicker = false;
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  inputMessageHandler(e) {
    this.selectionStart = e.target.selectionStart;

    if (this.messageInput.nativeElement.value === '') {
      this.messageInput.nativeElement.style.height = `40px`;
    }

    if (this.messageInput.nativeElement.scrollHeight <= 100) {
      this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight - 1}px`;
    } else {
      this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;
    }
  }

  prepareNewMessage() {
    const value: Message = new Message();
    value.content = this.newMessageContent;
    value.receiver = this.globals.receiver;
    value.sender = this.globals.owner;
    value.chat = this.globals.currentChat.id;
    value.unread = true;
    return value;
  }

  emojiHandler(event) {
    this.newMessageContent += event[0];
  }
}
