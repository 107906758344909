import {Component, OnInit} from '@angular/core';
import {NotificationService} from '@app/shared/services/notification.service';
import {Notification} from '@app/modules/notifications-page/notification';
import {Page} from '@app/core/page';
import {Globals} from '@app/shared/globals';
import {TranslateService} from '@ngx-translate/core';
import {ChatService} from '@app/shared/services/chat.service';
import {Router} from '@angular/router';
import {DateConverterService} from '@app/shared/services/date-converter.service';
import * as moment from 'moment';
import {UpdateNavigation} from '@app/shared/models/UpdateNavigation';
import {ToolbarService} from '@app/shared/services/toolbar/toolbar.service';
import {MenuItem} from '@app/shared/models/menu/MenuItem';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss']
})
export class NotificationsPageComponent implements OnInit, UpdateNavigation {

  translationText: any;
  notificationPage: Page<Notification>;

  constructor(private notificationService: NotificationService,
              public globals: Globals,
              private translationService: TranslateService,
              private chatService: ChatService,
              private router: Router,
              public dateService: DateConverterService,
              public toolbarService: ToolbarService,
              public alertController: AlertController) {
  }

  menuButtonUnreadNotifications: MenuItem[] = [
    {
      title: 'Category tree',
      icon: 'assets/icons/design_v4/category_path.svg',
      action: () => {
        this.routeCategories();
      }
    }
  ];
  menuButtonWithoutNotifications: MenuItem[] = [
    {
      title: 'Category tree',
      icon: 'assets/icons/design_v4/category_path.svg',
      action: () => {
        this.routeCategories();
      }
    }
  ];

  private routeCategories() {
    const path = this.globals.currentCategoryId > 0 ? `/categories/${this.globals.currentCategoryId}/tree` : `/categories`;
    this.router.navigateByUrl(path).then(r => console.log(r));
  }

  ngOnInit() {
    this.updatePopUpTranslations();
    this.translationService.onLangChange.subscribe((event) => {
      this.updatePopUpTranslations();
    });
  }

  ionViewWillEnter() {
    this.notificationService.readNotifications();
    if (this.globals.userNotifications === undefined) {
      this.getNotifications(0);
    }
  }

  ionViewWillLeave() {
    this.notificationService.readNotifications();
    this.notificationService.checkLocalUnreadNotifications();
  }

  doRefreshContent(event) {
    this.getNotifications(0, () => {
      event.target.complete();
    });
  }

  private nextPage(event?) {
    if (!this.notificationPage.last) {
      this.getNotifications(this.notificationPage.number + 1, () => {
        event.target.complete();
      });
    } else {
      if (event) {
        event.target.complete();
      }
    }
  }

  private getNotifications(page: number, callback?: any) {
    this.notificationService.getNotifications(page).subscribe((data: Page<Notification>) => {
        this.notificationPage = data;
        this.globals.userNotifications = page === 0 ? this.notificationPage.content : this.globals.userNotifications.concat(this.notificationPage.content);
        this.notificationService.addToReceivedNotifications(this.notificationPage.content);
        if (callback) {
          callback();
        }
      }
    );
  }

  loadData($event: CustomEvent) {
    this.nextPage($event);
  }

  onItemClickHandler(notification: Notification) {
    switch (notification.type) {
      case '1':
        this.chatService.startChat(notification.sender.id);
        break;
      case '2':
        this.router.navigateByUrl('/notes/' + notification.actionData);
        break;
      case '3':
        this.router.navigateByUrl('/notes/' + notification.actionData);
        break;
      case '4':
        this.router.navigateByUrl('/chat');
        break;
      case '5':
        this.router.navigateByUrl('/friends');
        break;
    }
  }

  async confirmRemove(notification: Notification) {
    const alert = await this.alertController.create({
      header: this.translationText['Confirm!'],
      message: this.translationText['Remove notification?'],
      buttons: [
        {
          text: this.translationText['Cancel'],
          role: 'cancel',
        }, {
          text: this.translationText['Remove'],
          handler: () => {
            this.notificationService.removeNotification(notification.id, () => {
              this.globals.userNotifications.splice(this.globals.userNotifications.indexOf(notification), 1);
            });
          }
        }
      ]
    });
    await alert.present();
  }

  getNotificationIcon(notification: Notification) {
    switch (notification.type) {
      case '1':
        return '../../../assets/icons/design_v4/mail-outline.svg';
      case '2':
        return '../../../assets/icons/design_v4/chatbubble-ellipses-outline.svg';
      case '3':
        return '../../../assets/icons/design_v4/chatbubble-ellipses-outline.svg';
      case '4':
        return '../../../assets/icons/design_v4/people-outline.svg';
      case '5':
        return '../../../assets/icons/design_v4/people-outline.svg';
    }
  }

  getNotificationText(notification: Notification) {
    switch (notification.type) {
      case '1':
        return this.translationText['send you a new message'];
      case '2':
        return this.translationText['comment your note'];
      case '3':
        return this.translationText['reply to your comment'];
      case '4':
        return this.translationText['send you a friend request'];
      case '5':
        return this.translationText['accept your friend request'];
    }
  }

  canViewDivider(i) {
    if (this.globals.userNotifications[i - 1] &&
      this.globals.userNotifications[i - 1] !== null &&
      this.globals.userNotifications[i - 1].creationDate &&
      this.globals.userNotifications[i] &&
      this.globals.userNotifications[i] !== null &&
      this.globals.userNotifications[i].creationDate) {
      if (i === 0) {
        return true;
      } else {
        return !this.dateService.isSameDay(
          this.globals.userNotifications[i].creationDate,
          this.globals.userNotifications[i - 1].creationDate);
      }
    } else {
      return false;
    }
  }

  dividerText(i) {
    if (this.globals.userNotifications[i].creationDate) {
      if (i === 0) {
        if (moment(this.globals.userNotifications[i].creationDate).format('MMMM DD') === moment(new Date()).format('MMMM DD')) {
          return 'Today';
        } else {
          return moment(this.globals.userNotifications[i].creationDate).format('MMMM DD');
        }
      } else {
        return moment(this.globals.userNotifications[i].creationDate).format('MMMM DD');
      }
    }
  }

  updateNavigation(): void {
    if (this.globals.notificationCount > 0) {
      this.toolbarService.setMenuItems(this.menuButtonUnreadNotifications);
    } else {
      this.toolbarService.setMenuItems(this.menuButtonWithoutNotifications);
    }
  }

  private updatePopUpTranslations() {
    this.translationService.get([
      'send you a new message',
      'comment your note',
      'reply to your comment',
      'send you a friend request',
      'accept your friend request',
      'Confirm!',
      'Remove notification?',
      'Cancel',
      'Remove'
    ]).subscribe(tr => this.translationText = tr);
  }
}
