import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '@app/shared/globals';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(public http: HttpClient, public globals: Globals) {
  }

  uploadImage(uploadData) {
    return this.http.post(this.globals.apiUrl + '/attachments/image/deletable=' + this.globals.isDeletable, uploadData, {
      reportProgress: true,
      responseType: 'text'
    });
  }

  uploadVideo(uploadData) {
    return this.http.post(this.globals.apiUrl + '/attachments/video/deletable=' + this.globals.isDeletable, uploadData, {
      reportProgress: true,
      responseType: 'text'
    });
  }
}
