import {Injectable} from '@angular/core';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {OSNotification, OSNotificationOpenedResult} from '@ionic-native/onesignal';
import {SiteUserService} from '@app/shared/services/site-user.service';
import {ChatService} from '@app/shared/services/chat.service';
import {ModalController, Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {Globals} from '@app/shared/globals';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  private deviceId: string = '';

  constructor(private oneSignal: OneSignal,
              private userService: SiteUserService,
              private chatService: ChatService,
              private platform: Platform,
              private router: Router,
              private globals: Globals,
              private modalController: ModalController) {
  }

  initOneSignal() {
    if (this.platform.is('android')) {
      this.oneSignal.startInit('0a31ee6d-c505-4832-8160-6f11b8522c9d', '682297175404');
    }
    if (this.platform.is('ios')) {
      this.oneSignal.startInit('0a31ee6d-c505-4832-8160-6f11b8522c9d');
    }

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

    this.oneSignal.handleNotificationReceived().subscribe((message: OSNotification) => {
    });

    this.oneSignal.handleNotificationOpened().subscribe((messageOpen: OSNotificationOpenedResult) => {
      this.onOpenActions();
    });

    this.oneSignal.getIds().then(identity => {
      this.deviceId = identity.userId;
    });

    this.oneSignal.endInit();
  }

  private onOpenActions() {
    this.modalController.dismiss();
    this.router.navigateByUrl('/notifications');
  }

  getDeviceId(): string {
    return this.deviceId;
  }
}
