<div class="sub_comments" *ngFor="let child of passCurrentParentComment.childComments">
  <div class="comment_wrapper">
    <app-child-note-comment
      [currentCommentFromChildList]="child"
      [noteEntity]="noteEntity"
      [translationsText]="translationsText"
      (haveDeletedComment)="deletedComment($event)"
    ></app-child-note-comment>
  </div>
</div>

