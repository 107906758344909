<div class="message">
  <div class="add-message">
    <div class="form__item">
      <textarea
        class="form__item__input"
        type="text"
        (keyup)="inputMessageHandler($event)"
        (click)="inputMessageHandler($event)"
        [(ngModel)]="newMessageContent"
        placeholder="{{'Your Message'| translate}}"
        id="message"
        #message
        name="message"></textarea>
      <button
        *ngIf="!platform.is('cordova')"
        (click)="toggleEmojiPicker()">
        <img [src]="'assets/icons/design_v4/emoji.svg'">
      </button>

    </div>
  </div>

  <div class="send-message cursor-pointer">
    <img
      (click)="addNewMessage()"
      [src]="'assets/icons/design_v4/send-message.svg'" alt="">
  </div>
</div>

<emoji-content class="toolbar-emoji-picker" *ngIf="showEmojiPicker && !platform.is('cordova')" (emoji-selection)="emojiHandler($event)">
</emoji-content>
