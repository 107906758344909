import {Injectable} from '@angular/core';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private camera: Camera) { }

  async pickImage(sourceType: string): Promise<string> {

    const options: CameraOptions = {
      quality: 60,
      sourceType: sourceType === 'lib' ? this.camera.PictureSourceType.PHOTOLIBRARY : this.camera.PictureSourceType.CAMERA,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true
    };

    return this.camera.getPicture(options);
  }
}
