import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './modules/home/home.component';
import {AuthenticationComponent} from './core/auth/authentication.component';
import {ProfileComponent} from './modules/profile/profile.component';
import {SettingsComponent} from './modules/settings/settings.component';
import {AuthenticationService} from './core/auth/authentication.service';
import {PasswordResetComponent} from './core/auth/password-reset/password-reset.component';
import {NoteComponent} from './modules/note/note.component';
import {FriendsComponent} from './modules/friends/friends.component';
import {EditorsPageComponent} from './modules/editors-page/editors-page.component';
import {ChatComponent} from './modules/chat/chat.component';
import {SignUpComponent} from './core/auth/sign-up/sign-up.component';
import {LoginComponent} from './core/auth/login/login.component';
import {ForgotPasswordComponent} from './core/auth/forgot-password/forgot-password.component';
import {CategoryTreeComponent} from './modules/category-tree/category-tree.component';
import {CategoryFlowerComponent} from './modules/category-flower/category-flower.component';
import {NotFoundComponent} from './modules/not-found/not-found.component';
import {NewNoteComponent} from '@app/modules/new-note/new-note.component';
import {NoteFiltersComponent} from '@app/modules/note-filters/note-filters.component';
import {ResetSendComponent} from '@app/core/auth/reset-send/reset-send.component';
import {PolicyComponent} from '@app/modules/terms-and-policy/policy/policy.component';
import {TermsComponent} from '@app/modules/terms-and-policy/terms/terms.component';
import {SupportComponent} from '@app/core/support/support.component';
import {AdminPageComponent} from '@app/modules/admin-page/admin-page.component';
import {VotingPanelComponent} from '@app/modules/admin-page/voting-panel/voting-panel.component';
import {TemplatesPanelComponent} from '@app/modules/admin-page/templates-panel/templates-panel.component';
import {UsersPanelComponent} from '@app/modules/admin-page/users-panel/users-panel.component';
import {NewNoteDataResolve} from '@app/shared/resolvers/new-note-data.resolver';
import {AlertsPageComponent} from '@app/modules/alerts-page/alerts-page.component';
import {ConfirmAccountComponent} from '@app/core/auth/confirm-account/confirm-account.component';
import {CategoryContainerComponent} from '@app/modules/category-ngrx/category-container/category-container.component';
import {NotificationsPageComponent} from '@app/modules/notifications-page/notifications-page.component';
import {SiteUserFeedComponent} from "@app/modules/site-user-feed/site-user-feed.component";


const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [AuthenticationService]},
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'auth',
    component: AuthenticationComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-send',
    component: ResetSendComponent
  },
  {
    path: 'support',
    component: SupportComponent
  },
  {path: 'reset-password', component: PasswordResetComponent},
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'friends',
    component: FriendsComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'users/:id/notes',
    component: SiteUserFeedComponent,
    canActivate: [AuthenticationService]
  },
  {path: 'notes/:id', component: NoteComponent},
  {
    path: 'categories/:id/new-note',
    component: NewNoteComponent,
    canActivate: [AuthenticationService],
    resolve: {category: NewNoteDataResolve}
  },
  {
    path: 'editors',
    component: EditorsPageComponent,
    canActivate: [AuthenticationService] && [EditorsPageComponent]
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'categories/:id/tree',
    component: CategoryTreeComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'categories/:id',
    component: CategoryContainerComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'categories',
    component: CategoryFlowerComponent,
    canActivate: [AuthenticationService]
  },
  {path: 'note-filters', component: NoteFiltersComponent},
  {path: 'policy', component: PolicyComponent},
  {path: 'terms', component: TermsComponent},
  {
    path: 'admin',
    component: AdminPageComponent,
    canActivate: [AuthenticationService] && [AdminPageComponent]
  },
  {
    path: 'admin/users',
    component: UsersPanelComponent,
    canActivate: [AuthenticationService] && [UsersPanelComponent]
  },
  {
    path: 'admin/templates',
    component: TemplatesPanelComponent,
    canActivate: [AuthenticationService] && [TemplatesPanelComponent]
  },
  {
    path: 'admin/voter',
    component: VotingPanelComponent,
    canActivate: [AuthenticationService] && [VotingPanelComponent]
  },
  {
    path: 'alerts',
    component: AlertsPageComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'sign-up/confirm',
    component: ConfirmAccountComponent,
    canActivate: [ConfirmAccountComponent]
  },
  {path: 'notifications', component: NotificationsPageComponent, canActivate: [AuthenticationService]},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
