import {SiteUser} from '../../modules/site-user/site-user';
import {Attachment} from '../../core/attachment';
import {NoteComment} from '../note-comment/note-comment';
import {Category} from '@app/modules/category/category';

export class Note {
  id: number;
  content: string;
  parentCategory: Category;
  comments: Array<NoteComment>;
  owner: SiteUser;
  accessType: number;
  weight: number;
  updateDate: Date;
  creationDate: Date;
  views: number;
  attachments: Array<Attachment>;
  placeId: number;
}
