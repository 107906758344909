<app-auth-header title="Reset Password"></app-auth-header>

<div *ngIf="isLoading">
  <ion-spinner class="main-loader" name="crescent"></ion-spinner>
</div>

<div *ngIf="!isLoading" class="reset-password__form">
  <div class="reset-password__logo">
    <img
      [src]="'assets/icons/design_v4/logo.svg'"
      alt="">
  </div>
  <form
    class="form"
    [formGroup]="resetForm"
    role="form">
    <div class="form__item">
      <img
        class="form__item__icon"
        [src]="'assets/icons/design_v4/lock_icon.svg'"
        alt="">
      <input
        class="form__item__input"
        formControlName="password"
        type="password"
        placeholder="{{'Password' | translate}}"
        id="password"
        name="password"/>
    </div>
    <div class="form__item">
      <img
        class="form__item__icon"
        [src]="'assets/icons/design_v4/lock_icon.svg'"
        alt="">
      <input
        class="form__item__input"
        formControlName="confirmPassword"
        type="password"
        placeholder="{{'Password' | translate}}"
        id="confirmPassword"
        name="confirmPassword"/>
    </div>
    <p
      class="form__error"
      *ngIf="resetForm.get('password').invalid && resetForm.get('password').touched && resetForm.get('password')['errors']['pattern']"
      translate>
      Password must contain one uppercase letter, one lowercase, one digit, one character and be longer then 8 symbols.
    </p>
    <p
      class="form__error"
      *ngIf="resetForm.get('password').invalid && resetForm.get('password').touched && resetForm.get('password')['errors']['hasCyrillic']"
      translate>
      Password can only contain latin characters, numbers
    </p>
    <p
      class="form__error"
      *ngIf="resetForm.get('confirmPassword').invalid && resetForm.get('confirmPassword').touched && resetForm.get('confirmPassword')['errors']['matchOther']"
      translate>
      Your password and confirmation password do notmatch.
    </p>
    <ion-button
      class="form__button"
      [disabled]="resetForm.invalid"
      color="primary"
      (click)="resetPassword()">
        <span
          translate
          class="form__button__text">
          Reset Password
        </span>
    </ion-button>
  </form>
</div>

