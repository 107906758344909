import {Place} from "@app/modules/place/place";

export class Label {
  id: number;
  places: Place[];

  constructor(selectedPlace?: Place[]) {
    this.places = selectedPlace;
  }
}
