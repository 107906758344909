import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { featureReducer } from "./reducer";
import { CategoryStoreEffects } from "./effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature("categoryStore", featureReducer),
    EffectsModule.forFeature([CategoryStoreEffects])
  ],
  providers: [CategoryStoreEffects]
})
export class CategoryStoreModule {}
