import { Component, OnInit } from '@angular/core';
import {MenuItem} from "@app/shared/models/menu/MenuItem";
import {ToolbarService} from "@app/shared/services/toolbar/toolbar.service";
import {Globals} from "@app/shared/globals";
import {CanActivate, Router} from "@angular/router";

@Component({
  selector: 'app-users-panel',
  templateUrl: './users-panel.component.html',
  styleUrls: ['./users-panel.component.css']
})
export class UsersPanelComponent implements OnInit, CanActivate {
  headerActions: MenuItem[] = [
    {
      title: 'Back',
      path: '/admin',
      icon: 'assets/icons/design_v4/back_icon.svg',
    },

  ];

  constructor(
      private toolbarService: ToolbarService,
      public globals: Globals,
      public router: Router
  ) {
  }

  ngOnInit() {
  }

  updateNavigation(): void {
    this.toolbarService.setMenuItems(this.headerActions);
  }

  canActivate(): boolean {
    let isActive: boolean = true;
    if (!this.globals.isAdmin) {
      this.router.navigate(['/']);
      isActive = false;
    }
    return isActive;
  }
}
