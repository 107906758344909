import { Component, OnInit } from '@angular/core';
import { PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-drop-down-component',
  template: `
    <ion-list style="max-height: 240px; overflow-x: auto;">
      <ion-item
        *ngFor="let menu of popoverData"
        (click)="menu.callback(); popoverCtrl.dismiss()"
        button>
        {{menu.name | translate}}
      </ion-item>
    </ion-list>
  `,
  styleUrls: ['drop-down-list.component.css']
})
export class DropDownListComponent implements OnInit{

  popoverData: any[] = [];

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {
    const component: any = this;

    this.popoverData = component.data;
  }
}
