import { Component } from "@angular/core";
import { Globals } from "../../../shared/globals";
// store
import { Store, select } from "@ngrx/store";
import { CategoryStoreActions, CategoryStoreSelectors } from "@app/root-store/category";
import { CategoryApiService } from "../category-api.service";
//
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
import * as i3 from "../../../shared/globals";
import * as i4 from "../category-api.service";
import * as i5 from "@ionic/angular";
import * as i6 from "@app/modules/category-ngrx/category-slider/category-slider.component";
import * as i7 from "@angular/common";
function CategoryTreeNgrxComponent_div_8_p_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "list");
    i0.ɵɵelementEnd();
} }
const _c0 = function () { return { showIconsInSlider: false }; };
function CategoryTreeNgrxComponent_div_8_app_category_slider_coverflow_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "app-category-slider-coverflow", 4);
} if (rf & 2) {
    const level_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("categories", level_r1.categories)("selectCategoryId", level_r1.id)("config", i0.ɵɵpureFunction0(3, _c0));
} }
function CategoryTreeNgrxComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, CategoryTreeNgrxComponent_div_8_p_1_Template, 2, 0, "p", 7);
    i0.ɵɵtemplate(2, CategoryTreeNgrxComponent_div_8_app_category_slider_coverflow_2_Template, 1, 4, "app-category-slider-coverflow", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const level_r1 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !level_r1.active);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", level_r1.active);
} }
const _c1 = function () { return { showIconsInSlider: true }; };
export class CategoryTreeNgrxComponent {
    constructor(store$, route, globals, categoryService) {
        this.store$ = store$;
        this.route = route;
        this.globals = globals;
        this.categoryService = categoryService;
        this.categoryIdParam$ = route.params.pipe(map(p => p.id));
        this.store$.dispatch(new CategoryStoreActions.LoadRequestAction({
            userId: this.globals.selectedUser.id
        }));
        this.store$.dispatch(new CategoryStoreActions.LoadRootCategoriesAction({
            userId: this.globals.selectedUser.id
        }));
        this.categoryIdParam$.subscribe(id => {
            this.categoryIdParam = id;
            this.store$.dispatch(new CategoryStoreActions.LoadCategoryByIdAction({
                categoryId: id
            }));
        });
    }
    ngOnInit() {
        this.categories$ = this.store$.pipe(select(CategoryStoreSelectors.selectCategoriesItems));
        this.currentSelectCategory$ = this.store$.pipe(select(CategoryStoreSelectors.selectCurrentCategory));
        this.rootCategories$ = this.categories$.pipe(map((categories) => this.categoryService.filterByScopeScope(categories, 11)));
        this.levels$ = this.store$.pipe(select(CategoryStoreSelectors.selectLevels));
        this.rootCategories$.subscribe(data => {
            this.rootCategories = data;
        });
    }
    doRefresh() { }
}
CategoryTreeNgrxComponent.ɵfac = function CategoryTreeNgrxComponent_Factory(t) { return new (t || CategoryTreeNgrxComponent)(i0.ɵɵdirectiveInject(i1.Store), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(i3.Globals), i0.ɵɵdirectiveInject(i4.CategoryApiService)); };
CategoryTreeNgrxComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CategoryTreeNgrxComponent, selectors: [["app-category-tree-ngrx"]], decls: 10, vars: 7, consts: [["slot", "fixed", 3, "ionRefresh"], [1, "categories-container"], [1, "main-categories-block"], [1, "category-row"], [3, "categories", "selectCategoryId", "config"], [1, "categories__txt-slider"], [4, "ngFor", "ngForOf"], [4, "ngIf"], [3, "categories", "selectCategoryId", "config", 4, "ngIf"]], template: function CategoryTreeNgrxComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ion-content");
        i0.ɵɵelementStart(1, "ion-refresher", 0);
        i0.ɵɵlistener("ionRefresh", function CategoryTreeNgrxComponent_Template_ion_refresher_ionRefresh_1_listener() { return ctx.doRefresh(); });
        i0.ɵɵelement(2, "ion-refresher-content");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "div", 1);
        i0.ɵɵelementStart(4, "div", 2);
        i0.ɵɵelementStart(5, "div", 3);
        i0.ɵɵelement(6, "app-category-slider-coverflow", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 5);
        i0.ɵɵtemplate(8, CategoryTreeNgrxComponent_div_8_Template, 3, 2, "div", 6);
        i0.ɵɵpipe(9, "async");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("categories", ctx.rootCategories)("selectCategoryId", ctx.categoryIdParam)("config", i0.ɵɵpureFunction0(6, _c1));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(9, 4, ctx.levels$));
    } }, directives: [i5.IonContent, i5.IonRefresher, i5.IonRefresherContent, i6.CategorySliderCoverflowComponent, i7.NgForOf, i7.NgIf], pipes: [i7.AsyncPipe], styles: [".categories-container[_ngcontent-%COMP%]{padding:15px;max-width:600px;margin:0 auto}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CategoryTreeNgrxComponent, [{
        type: Component,
        args: [{
                selector: "app-category-tree-ngrx",
                templateUrl: "./category-tree.component.html",
                styleUrls: ["./category-tree.component.scss"]
            }]
    }], function () { return [{ type: i1.Store }, { type: i2.ActivatedRoute }, { type: i3.Globals }, { type: i4.CategoryApiService }]; }, null); })();
