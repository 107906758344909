import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Globals} from "@app/shared/globals";
import {Router} from "@angular/router";
import {BehaviorSubject, Observable} from "rxjs";
import {Continent} from "@app/modules/place/continent";
import {Country} from "@app/modules/place/country";
import {Region} from "@app/modules/place/region";
import {City} from "@app/modules/place/city";
import {Place} from "@app/modules/place/place";

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  public continentsSource = new BehaviorSubject<Continent[]>([]);
  public continents = this.continentsSource.asObservable();

  public placeSource = new BehaviorSubject<Place>(new Place());
  public selectedPlace = this.placeSource.asObservable();


  constructor(public http: HttpClient, public globals: Globals, public router: Router) {
  }

  public changeContinents(continents: Continent[]) {
    this.continentsSource.next(continents);
  }

  public changeSelectedPlace(place: Place) {
    this.placeSource.next(place);
  }

  public getWorld(continents: Continent[]) {
    let result = new Continent();
    for (const continent of continents) {
      if (continent.title === "World") {
        result = continent;
        break;
      }
    }
    return result;
  }

  isPlaceEmpty(place: Place) {
    return place.city === undefined && place.region === undefined && place.country === undefined && place.continent === undefined;
  }

  getAllContinents(): Observable<Continent[]> {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get<Continent[]>(this.globals.apiUrl + '/geo/continent', {
      params
    });
  }

  getAllCountriesForContinent(continentId: number): Observable<Country[]> {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get<Country[]>(this.globals.apiUrl + '/geo/continent/' + continentId + '/country', {
      params
    });
  }

  getAllRegionsForCountry(countryId: number): Observable<Region[]> {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get<Region[]>(this.globals.apiUrl + '/geo/country/' + countryId + '/region', {
      params
    });
  }

  getAllCitiesForRegion(regionId: number): Observable<City[]> {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get<City[]>(this.globals.apiUrl + '/geo/region/' + regionId + '/city', {
      params
    });
  }

  createPlace(selectedPlace: Place) {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.post(this.globals.apiUrl + '/geo/place', selectedPlace, {params});
  }

  getDefaultPlace() {
    const params = new HttpParams().append('currentLang', this.globals.currentLanguage);
    return this.http.get<Place>(this.globals.apiUrl + '/geo/place/default', {
      params
    });
  }
}
