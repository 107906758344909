<app-auth-header title="Reset password"></app-auth-header>

<ion-content>

<div class="forgot-password">
  <div class="forgot-password__logo">
    <img
      src="../../../../assets/icons/design_v4/mail-send.svg"
      alt="">
  </div>

  <div class="forgot-password__form">



      <div class="text-center">
        <p class="message-send-text" translate>Message was send. Check your email.</p>
      </div>


      <ion-button
        class="done-btn"
        color="primary"
        style="text-transform: capitalize"
        routerLink="/login">
        <span
          translate
          class="form__button__text reset-password-text">
          Done
        </span>
      </ion-button>
  </div>

  <div class="social-login">

    <ion-fab class="social-login__actions"  vertical="bottom" horizontal="center">
      <ion-fab-button (click)="loginWithGoogle()">
        <i class="fa fa-google-plus fa-sm social-login__icon"></i>
      </ion-fab-button>
      <ion-fab-button (click)="loginWithFb()">
        <i class="fa fa-facebook fa-sm social-login__icon"></i>
      </ion-fab-button>
      <!--<ion-fab-button (click)="loginWithAppleID()">-->
        <!--<i class="fa fa-apple fa-sm social-login__icon"></i>-->
      <!--</ion-fab-button>-->
    </ion-fab>


  </div>
</div>
</ion-content>
