import { NgModule } from "@angular/core";
import { CustomSelectDirective } from "@app/shared/directive/customSelect-directive/customSelect.directive";
import * as i0 from "@angular/core";
export class CustomSelectDirectiveModule {
}
CustomSelectDirectiveModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomSelectDirectiveModule });
CustomSelectDirectiveModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomSelectDirectiveModule_Factory(t) { return new (t || CustomSelectDirectiveModule)(); }, imports: [[]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomSelectDirectiveModule, { declarations: [CustomSelectDirective], exports: [CustomSelectDirective] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomSelectDirectiveModule, [{
        type: NgModule,
        args: [{
                imports: [],
                declarations: [
                    CustomSelectDirective
                ],
                exports: [
                    CustomSelectDirective
                ],
            }]
    }], null, null); })();
