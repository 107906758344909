export class CategoryTranslation {
  ar: string;
  bn: string;
  de: string;
  en: string;
  es: string;
  fr: string;
  hi: string;
  id: string;
  it: string;
  ja: string;
  pt: string;
  ru: string;
  zh: string;

  constructor() {
    this.ar = 'no translation';
    this.bn = 'no translation';
    this.de = 'no translation';
    this.en = 'no translation';
    this.es = 'no translation';
    this.fr = 'no translation';
    this.hi = 'no translation';
    this.id = 'no translation';
    this.it = 'no translation';
    this.ja = 'no translation';
    this.pt = 'no translation';
    this.ru = 'no translation';
    this.zh = 'no translation';
  }
}
